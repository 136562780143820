import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { PdfNotAvailableModalComponent } from '@/utils/pdf-not-available-modal/pdf-not-available-modal.component';
import { EnvService } from '@/services/env.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import './orderDescriptionComparasionInterface';
import { TextEditorComponent } from '@/utils/text-editor/text-editor.component';
import { ActionsArray, ClassMap, IAnatomyPart, IFacilityOrderDetails, IMFExamStatus } from '@/interfaces';
import {
  DraftSenderService,
  OdataService,
  OrderActionsService,
  OrderDescriptionService,
  OrdersService,
  UsersService
} from '@/services';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { RejectModalComponent } from '@/utils/reject-modal/reject-modal.component';
import { AssignPhysicianModalComponent } from '@/utils/assign-physician-modal/assign-physician-modal.component';
import { AuthorizeDescriptionModuleComponent } from '@/utils/authorize-description-module/authorize-description-module.component';
import { ReopenExamToastComponent } from '@/utils/reopen-exam-toast/reopen-exam-toast.component';
import { TakingDescriptionComponent } from '@/utils/taking-description/taking-description.component';
import { TimerLabelService } from '@/services/timer-label.service';
import { SendAttentionModalComponent } from '@/utils/send-attention-modal/send-attention-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { OrderToCorrectModalComponent } from '@/utils/order-to-correct-modal/order-to-correct-modal.component';
import { CovidAIStatistics, initCovidStats, setCovidStatistics } from '@/interfaces/AIConsultation';
import { AttachmentViewModalComponent } from '@/utils/attachment-view-modal/attachment-view-modal.component';
import { OrderInfoModalComponent } from '@/utils/order-info-modal/order-info-modal.component';

interface IOtherExam {
  anatomy_part: IAnatomyPart;
  birth_date: string;
  evo_accession_number: string;
  exam_accession_number: string;
  exam_date: string;
  first_name: string;
  his_exam_id: string;
  his_patient_id: string;
  id_exam: number;
  id_medical_facility: number;
  id_patient: number;
  last_name: string;
  mf_exam_status: IMFExamStatus;
  order_exam_identifier: string;
  order_exam_modality: string;
  order_exam_name: string;
  pesel: number;
  pm_info: string;
  sex: string;
  other_exam_transfer_status: {
    transfer_status: number;
  };
  exam_orders: IFacilityOrderDetails[];
}

@Component({
    selector: 'app-order-description-comparison',
    templateUrl: './order-description-comparison.component.html',
    styleUrls: ['./order-description-comparison.component.scss']
})
export class OrderDescriptionComparisonComponent implements OnInit, OnDestroy {
    @ViewChild('text_editor') editor: TextEditorComponent;
    @ViewChild('rejectModal') rejectModal: RejectModalComponent;
    @ViewChild('pdfModal') pdfModal: PdfNotAvailableModalComponent;
    @ViewChild('assignPhysicianModal') assignPhysicianModal: AssignPhysicianModalComponent;
    @ViewChild('saveAndSendAttentionExamModal') saveAndSendAttentionExamModal: SendAttentionModalComponent;
    @ViewChild('savedToast') savedToast: UniversalToastComponent;
    @ViewChild('reopenToast') reopenToast: ReopenExamToastComponent;
    @ViewChild('authorizeDescriptionModal') authorizeDescriptionModal: AuthorizeDescriptionModuleComponent;
    @ViewChild('takeoverModal') takeoverModal: TakingDescriptionComponent;
    @ViewChild('toCorrectModal') toCorrectModal: OrderToCorrectModalComponent;
    @ViewChild('attachmentViewModalComponent') attachmentModal: AttachmentViewModalComponent;
    @ViewChild('orderInfoModal') orderInfoModal: OrderInfoModalComponent;

    otherExams: IOtherExam[] = [];
  private statusTimeout: any;
  covidStatistics: CovidAIStatistics = initCovidStats;
    copiedText: string = '';
    currentlyChosenExam: number = null;
    currentlyChosenDesc: number = 0;
    currentlyChosenDescStatus: string = '';
    descriptionText: string = '';
    emptyDescription: boolean = false;
    btnColors: ClassMap = {
      'red': ['REJECT', 'REJECT_BY_PHYSICIAN', 'CANCEL_ASSIGMENT'],
      'grey': ['VIEW_EXAM_ORDER', 'VIEW_DESCRIPTION_PDF', 'CORRECT_DESCRIPTION', 'ASSIGN_PHYSICIAN',
        'ASSIGN_MYSELF', 'SAVE_DESCRIPTION_DRAFT', 'CHANGE_ASSIGMENT', 'REOPEN_DESCRIPTION'],
      'green': ['ASSIGN_AND_START_DESCRIPTION', 'SAVE_EXAM_DESCRIPTION_IN_TIME', 'SAVE_EXAM_DESCRIPTION', 'START_DESCRIPTION'],
    };
    ordersService: OrdersService;
    timerActive: boolean = false;
    descriptionService: OrderDescriptionService;
    isSaved = false;

    coExamActionReject: Function = () => {
        this.rejectModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionReject(this.descriptionService.examId, value).then(
                    res => {
                        if (res) {
                            this.rejectModal.hide();
                            this.descriptionService.getExamInfo().then(
                                () => {
                                    this.getData();
                                    this.descriptionService.getExamActions();
                                }
                            );
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    getCoExamActionViewExam: Function = () => {
        this.orderActions.viewExamOrder(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.getData();
                }
            }
        );
    }

    coExamActionAssignToPhysian: Function = () => {
        this.assignPhysicianModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        let retPromise = new Promise<string>((resolve) => {
            promise.then(
                (value) => {
                    this.orderActions.coExamActionAssignToPhysian(this.descriptionService.examId, value).then(
                        res => {
                            if (res) {
                                this.getData();
                                this.descriptionService.getExamInfo();
                                resolve('reload');
                            }
                        }
                    );
                }, () => {
                    // TODO
                });
        });

        retPromise.then(value => {
            if (value == 'reload') {
                this.descriptionService.getExamActions();
            }
        });
    }

    coExamActionAssignAndStartDescription: Function = () => {
        this.orderActions.coExamActionAssignAndStartDescription(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.switchToDescriptionView();
                    this.getData();
                    this.descriptionService.getExamActions();
                }
            }
        );
    }

    coExamActionAssignToMe: Function = () => {
        let retPromise = new Promise<string>((resolve) => {
            this.orderActions.coExamActionAssignToMe(this.descriptionService.examId).then(
                res => {
                    if (res) {
                        this.getData();
                        this.descriptionService.getExamInfo();
                        resolve('reload');
                    }
                }
            );
        });

        retPromise.then((value) => {
            if (value == 'reload') {
                this.descriptionService.getExamActions();
            }
        });
    }

    submitExamDescription: Function = () => {
        this.unsubscribeSaveAndSend();
        if (this.descriptionService.isCheckedAttention) {
            this.saveAndSendAttentionExamModal.show();
            this.isSaveSubscription = this.saveAndSendAttentionExamModal.isSave.subscribe((res) => {
                if (res) {
                    this.isSendSubscription = this.saveAndSendAttentionExamModal.isSend.subscribe((send) => {
                        this.saveAndSendAttentionExamModal.hide();
                        if (send) {
                            this.showAuthorizeModal(true);
                        } else this.showAuthorizeModal(false);
                    });
                } else this.saveAndSendAttentionExamModal.hide();
            });
        } else {
            this.showAuthorizeModal(false);
        }
    }

    submitExamDescriptionInTime: Function = () => {
        this.unsubscribeSaveAndSend();
        if (this.descriptionService.isCheckedAttention) {
            this.saveAndSendAttentionExamModal.show();
            this.isSaveSubscription = this.saveAndSendAttentionExamModal.isSave.subscribe((res) => {
                if (res) {
                    this.isSendSubscription = this.saveAndSendAttentionExamModal.isSend.subscribe((send) => {
                        this.saveAndSendAttentionExamModal.hide();
                        if (send) {
                            this.showAuthorizeModalInTime(true);
                        } else this.showAuthorizeModalInTime(false);
                    });
                } else this.saveAndSendAttentionExamModal.hide();
            });
        } else {
            this.showAuthorizeModalInTime(false);
        }
    }

    coExamActionRejectByPhysician: Function = () => {
        this.rejectModal.show(this.descriptionService.getOrderDataForModal(), undefined, true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionRejectByPhysician(this.descriptionService.examId, value).then(
                    res => {
                        if (res) {
                            this.rejectModal.hide();
                            this.descriptionService.getExamInfo();
                            this.router.navigate(['/' + this.orderActions.cameFrom]);
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    saveExamDraft: Function = () => {
        if (!this.draftSavedSubscription) {
            this.draftSavedSubscription = this.descriptionService.draftSavedEmitter.subscribe((res) => {
                if (res.exam_id == this.descriptionService.examId) {
                    if (res.success == true) {
                        this.savedToast.success('DraftSaved');
                        this.descriptionService.descriptionText = this.editor.getText();
                    } else {
                        this.savedToast.error('DraftNotSaved');
                    }
                    this.savedToast.show();
                    setTimeout(() => {
                        this.savedToast.hide();
                    }, 1500);
                }
            });
        }
        if (this.draftSender.isChanged) this.descriptionService.saveExamDraft(this.editor.getText());
    }

    coExamActionStartDescrption: Function = () => {
        let lockPromise = this.getExamLock();
        lockPromise.then((res) => {
            if (res) {
                this.orderActions.coExamActionStartDescrption(this.descriptionService.examId).then(
                    res => {
                        if (res) {
                            this.switchToDescriptionView();
                            this.getData();
                            this.descriptionService.getExamActions();
                        }
                    }
                );
            } else {
                // TODO
            }
        });
    }

    getExamLock(): Promise<boolean> {
        let lockPromise: Promise<boolean>;
        lockPromise = this.descriptionService.getExamLock()
            .then((isLock) => {
                if (!isLock) {
                    return this.takeoverModal.handleLockTakeover().then((isStillLock) => {
                        if (!isStillLock) {
                            this.descriptionService.mode = {
                                mode: 'view',
                                code: 0
                            };
                            sessionStorage.setItem('mode', 'view');
                            this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/orderDescriptionComparison'], { queryParams: { mode: 'view' } });
                        }
                        return isStillLock;
                    });
                } else {
                    return true;
                }
        });
        return lockPromise;
    }

    reopenExamDesc: Function = () => {
        this.orderActions.coExamReopenDescription(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.switchToDescriptionView();
                    this.getData();
                    this.descriptionService.getExamActions();
                } else {
                    this.reopenToast.show();
                    setTimeout(() => {
                        this.reopenToast.hide();
                        this.router.navigate(['/dashboard']);
                    }, 1500);
                }
            }
        );
    }

    coExamCancelAssigment: Function = () => {
        this.rejectModal.show(this.descriptionService.getOrderDataForModal(), true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        let assignedId: number = this.descriptionService.orderDescription.physicianAssigned.id;
        promise.then(
            (value) => {
                this.orderActions.coExamActionCancelAssigment(this.descriptionService.examId, value).then(
                    res => {
                        if (res) {
                            this.rejectModal.hide();
                            this.descriptionService.getExamInfo();
                            if (assignedId == this.userService.userId) this.router.navigate(['/' + this.orderActions.cameFrom]);
                            else {
                                this.descriptionService.getExamInfo();
                                this.descriptionService.getExamActions();
                            }
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    coExamChangeAssigment: Function = () => {
        this.assignPhysicianModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        let retPromise = new Promise<string>((resolve) => {
            promise.then(
                (value) => {
                    this.orderActions.coExamActionChangeAssigment(this.descriptionService.examId, value).then(
                        res => {
                            if (res) {
                                this.getData();
                                this.descriptionService.getExamInfo();
                                resolve('reload');
                            }
                        }
                    );
                }, () => {
                    // TODO
                });
        });

        retPromise.then(value => {
            if (value == 'reload') {
                this.descriptionService.getExamActions();
            }
        });
    }

    getExamDescriptionPdf: Function = () => {
        this.ordersService.getExamDescriptionPdf(this.descriptionService.examId).then(
            (res) => {
                if (res == 'OK') {
                    if (this.ordersService.examDescriptionUuid == null) {
                        this.pdfModal.show();
                    } else {
                        this.ordersService.downloadExamFile();
                    }
                }
            }
        );
    }

    coExamDescriptionToCorrect: Function = () => {
        this.toCorrectModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.toCorrectModal.setPromiseResolve(resolve);
            this.toCorrectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionOrderToCorrect(this.descriptionService.examId, value).then(
                    res => {
                        this.toCorrectModal.hide();
                        if (res) {
                            this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/orderDescriptionComparison'], { queryParams: { mode: 'view' } });
                        } else {
                            this.reopenToast.show();
                            setTimeout(() => {
                                this.reopenToast.hide();
                                this.router.navigate(['/dashboard']);
                            }, 1500);
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    actionsArray: ActionsArray = {
        'REJECT': this.coExamActionReject,
        'REJECT_BY_PHYSICIAN': this.coExamActionRejectByPhysician,
        'START_DESCRIPTION': this.coExamActionStartDescrption,
        'VIEW_EXAM_ORDER': this.getCoExamActionViewExam,
        'ASSIGN_PHYSICIAN': this.coExamActionAssignToPhysian,
        'ASSIGN_AND_START_DESCRIPTION': this.coExamActionAssignAndStartDescription,
        'ASSIGN_MYSELF': this.coExamActionAssignToMe,
        'SAVE_EXAM_DESCRIPTION': this.submitExamDescription,
        'SAVE_EXAM_DESCRIPTION_IN_TIME': this.submitExamDescriptionInTime,
        'SAVE_DESCRIPTION_DRAFT': this.saveExamDraft,
        'REOPEN_DESCRIPTION': this.reopenExamDesc,
        'CANCEL_ASSIGMENT': this.coExamCancelAssigment,
        'CHANGE_ASSIGMENT': this.coExamChangeAssigment,
        'VIEW_DESCRIPTION_PDF':  this.getExamDescriptionPdf,
        'CORRECT_DESCRIPTION': this.coExamDescriptionToCorrect
    };

    authorizeSubscription: Subscription;
    draftSavedSubscription: Subscription = null;
    hideSubscription: Subscription;
    isSendSubscription: Subscription;
    isSaveSubscription: Subscription;
    timeOutEvent: Subscription;

    constructor(private odataService: OdataService, private userService: UsersService,
                descriptionService: OrderDescriptionService, private route: ActivatedRoute,
                ordersService: OrdersService, private orderActions: OrderActionsService, private router: Router,
                public timerLabel: TimerLabelService, public draftSender: DraftSenderService, private translate: TranslateService,
                public envService: EnvService) {
        this.ordersService = ordersService;
        this.descriptionService = descriptionService;
    }

    ngOnInit() {
        this.descriptionService.examId = parseInt(this.route.parent.snapshot.paramMap.get('orderId'));
        this.descriptionService.mode.mode = sessionStorage.getItem('mode');
        if (sessionStorage.getItem('tmpDescText')) {
            this.editor.text = sessionStorage.getItem('tmpDescText');
            sessionStorage.removeItem('tmpDescText');
            this.isSaved = true;
        }
        this.descriptionService.getExamInfo().then(() => {
            if (this.descriptionService.orderDescription.status.codename == 'IN_PROGRESS') {
                this.timerLabel.endCountdown();
                this.timerActive = false;
            } else if (this.descriptionService.orderDescription.status.codename == 'TO_APPOVE_BY_TIME') {
                let seconds = moment(moment()).diff(this.descriptionService.orderDescription.descriptionDate, 'seconds');
                this.showTimer(seconds);
            }
            this.getExamOrderForPatient();
              /*.then(() => {
                if (this.examOrders[0].ordersInfo[0] != null) {
                    setTimeout(() => {
                        this.setDefaultDescriptions();
                    }, 0);
                }
            });*/
            // this.getData();
        });
        // this.descriptionService.getExamActions();
        // this.assignPhysicianModal.get_co_describing_physicians(this.descriptionService.examId);

        /*this.hideSubscription = this.editor.textEmitter.subscribe((e) => {
            this.descriptionService.text = e;
            if (this.descriptionService.descriptionText != e) {
                this.draftSender.isSaved = false;
                this.draftSender.isChanged = true;
            } else {
                this.draftSender.isSaved = true;
                this.draftSender.isChanged = false;
            }
        });*/
      if (this.descriptionService.orderDescription.exam_info.consultation) this.covidStatistics = setCovidStatistics(undefined, this.descriptionService.orderDescription);
    }

    ngOnDestroy() {
        if (this.draftSavedSubscription != null) this.draftSavedSubscription.unsubscribe();
        if (((this.draftSender.isChanged && this.editor.getText() != '') || this.isSaved)
            && this.router.url == '/orderDescription/' + this.descriptionService.examId + '/currentDescription?mode=description') {
            sessionStorage.setItem('tmpDescText', this.editor.getText());
        }
        // this.hideSubscription.unsubscribe();
        if (this.timeOutEvent != undefined) {
            this.timeOutEvent.unsubscribe();
        }
        this.unsubscribeSaveAndSend();
        clearTimeout(this.statusTimeout);
    }

    showAuthorizeModal(isSendAttention: boolean) {
        if (this.authorizeSubscription) {
            this.authorizeSubscription.unsubscribe();
        }
        this.descriptionService.isCheckedAttention = isSendAttention;
        this.authorizeDescriptionModal.show();
        this.descriptionService.saveExamDraft(this.editor.getText());
        this.cancelDraftSavedSubscription();
        this.authorizeSubscription = this.authorizeDescriptionModal.isAuthorize.subscribe((e) => {
            if (e) {
                this.authorizeDescriptionModal.hide();
                this.savedToast.success('SendingInProgress', 'AutoRedirecting');
                this.savedToast.show();
                this.descriptionService.submitExamDescription(this.editor.getText()).then((res) => {
                    if (res) {
                        this.savedToast.hide();
                        this.router.navigate([this.orderActions.cameFrom]);
                    } else {
                        this.savedToast.error('SaveExamError');
                        setTimeout(() => this.savedToast.hide(), 2000);
                    }
                });
            }
        });
    }

    showAuthorizeModalInTime(isSendAttention: boolean) {
        if (this.authorizeSubscription) {
            this.authorizeSubscription.unsubscribe();
        }
        this.descriptionService.isCheckedAttention = isSendAttention;
        this.authorizeDescriptionModal.show();
        this.descriptionService.saveExamDraft(this.editor.getText());
        this.cancelDraftSavedSubscription();
        this.authorizeSubscription = this.authorizeDescriptionModal.isAuthorize.subscribe((e) => {
            if (e) {
                this.authorizeDescriptionModal.hide();
                this.savedToast.success('QueuingExam', 'SendingInProgressInTime');
                this.savedToast.show();
                this.descriptionService.submitExamDescriptionInTime(this.editor.getText()).then((res) => {
                    if (res) {
                        this.showTimer(600);
                        this.savedToast.hide();
                        this.router.navigate([this.orderActions.cameFrom]);
                    } else {
                        this.savedToast.error('SaveExamInTimeError');
                        setTimeout(() => this.savedToast.hide(), 2000);
                    }
                });
            }
        });
    }

    getData() {
        if (this.descriptionService.orderDescription.status.codename == 'REJECTED') {
            this.descriptionService.descriptionText = this.descriptionService.orderDescription.rejectInfo.rejectReason;
            this.descriptionService.isRejected = true;
        } else {
            this.descriptionService.getExamDescriptionOrDraft().then(() => {
                 if (!this.isSaved) this.editor.setText(this.descriptionService.descriptionText);
                 this.isSaved = false;
            });
        }
    }

    unsubscribeSaveAndSend() {
        if (this.isSendSubscription) {
            this.isSendSubscription.unsubscribe();
        }
        if (this.isSaveSubscription) {
            this.isSaveSubscription.unsubscribe();
        }
    }

    saveWithAttention() {
        this.descriptionService.saveExamDraft(this.editor.getText());
    }

    getExamOrderForPatient(): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: this.descriptionService.examId,
                patient_uuid: this.descriptionService.orderDescription.patientUuid,
                offset: 0,
                limit: 10000
            }
        );
       return this.odataService.postPrivate('co_get_other_patient_exams', this.odataService.paramsTokenAndLang({
          id_exam_order: this.descriptionService.examId,
          other_pm: false,
          offset: 0,
          limit: 10000
        }), res => {
          let returnedJSON = JSON.stringify(res);
          let result = JSON.parse(returnedJSON);
          this.otherExams = JSON.parse(result.Value || '[]');
          this.otherExams.forEach(e => {
            e.exam_date = this.ordersService.getDateString(e.exam_date);
            e.other_exam_transfer_status = { transfer_status: 0 };
            e.exam_orders = [];
          });
          return true;
        });
    }

    cancelDraftSavedSubscription(): void {
        if (!this.draftSavedSubscription) return;
        this.draftSavedSubscription.unsubscribe();
        this.draftSavedSubscription = null;
    }

    formatDate(order) {
        order.ordersInfo.forEach(order => {
            if (order.status_info.status_name != 'REJECTED') {
                order.exam_description_date = moment(order.exam_description_date).format('YYYY-MM-DD, HH:MM');
            } else order.reject_info.reject_time = moment(order.reject_info.reject_time).format('YYYY-MM-DD, HH:MM');
        });
    }

    sortOrdersByDate(order) {
        order.ordersInfo.sort((val1, val2) =>
            <any>new Date(val2.status_info.status_name != 'REJECTED'
            ? val2.exam_description_date
            : val2.reject_info.reject_time) -
            <any>new Date(val1.status_info.status_name != 'REJECTED'
            ? val1.exam_description_date
            : val1.reject_info.reject_time));
    }

    clearDescriptionText() {
        this.emptyDescription = false;
        this.descriptionText = '';
    }

    copyTextToCurrentDescription() {
        this.editor.text += this.descriptionText;
        this.draftSender.isSaved = false;
        this.draftSender.isChanged = true;
    }

    /*changeChosenDesc(examId, descId): void {
        this.currentlyChosenDesc = descId;
        this.currentlyChosenExam = examId;

        this.currentlyChosenDescStatus = this.examOrders[this.currentlyChosenExam].ordersInfo[this.currentlyChosenDesc].status_info.status_name;
        if (this.currentlyChosenDescStatus == 'REJECTED') {
            this.descriptionText = this.examOrders[this.currentlyChosenExam].ordersInfo[this.currentlyChosenDesc].reject_info.reject_reason;
        } else this.descriptionText = this.examOrders[this.currentlyChosenExam].ordersInfo[this.currentlyChosenDesc].exam_description;

        if (this.descriptionText == '') {
            this.emptyDescription = true;
            this.translate.get('OrderDescriptionScreens.Comparasion.EmptyDescription').subscribe((res) => {
                this.descriptionText = res;
            });
        }
    }*/

    showExamOrders(index): void {
      if (this.currentlyChosenExam == index) { this.currentlyChosenExam = -1; } else {
        this.currentlyChosenExam = index;
        this.updateExamTransferStatus(index).then(() => this.updateTransferStatusTimer(index));
        if (this.otherExams[index].exam_orders != []) this.getExamOrders(index);
      }
    }

    getExamOrders(index): void {
      this.odataService.postPrivate('co_get_other_patient_exams_exam_info', this.odataService.paramsTokenAndLang({
        id_exam_order: this.descriptionService.examId,
        id_exam: this.otherExams[index].id_exam
      }), res => {
        const result = this.odataService.parseResponse(res);
        if (result.Status == 'OK') {
          this.otherExams[index].exam_orders = JSON.parse(result.Value);
        } else console.log(result.Status);
      });
    }

    updateExamTransferStatus(index: number): Promise<any> {
      return this.odataService.postPrivate('co_get_other_patient_exams_exam_transfer_status', this.odataService.paramsTokenAndLang({
        id_exam_order: this.descriptionService.examId,
        id_exam: this.otherExams[index].id_exam
      }), res => {
        const returnedJSON = JSON.stringify(res);
        const parsedJSON = JSON.parse(returnedJSON);
        if (parsedJSON.Status === 'OK') {
          this.otherExams[index].other_exam_transfer_status = JSON.parse(parsedJSON.Value).other_exam_transfer_status;
        } else console.log(parsedJSON.Status);
      });
    }

    downloadExamToDC(index: number): void {
      this.odataService.postPrivate('co_get_other_patient_exams_exam_transfer_to_cu', this.odataService.paramsTokenAndLang({
        id_exam_order: this.descriptionService.examId,
        id_exam: this.otherExams[index].id_exam,
        transfer_again: this.otherExams[index].other_exam_transfer_status.transfer_status == 2 || this.otherExams[index].other_exam_transfer_status.transfer_status == 3
      }), res => {
        const response = this.odataService.parseResponse(res);
        if (response.Status !== 'OK') {
          console.log(response.Status);
        }
      }).then( e => {
        this.updateExamTransferStatus(index).then(res => this.updateTransferStatusTimer(index));
      });
    }

    updateTransferStatusTimer(index) {
      clearTimeout(this.statusTimeout);
      if (this.currentlyChosenExam == index && this.otherExams[index].other_exam_transfer_status.transfer_status == 1) {
        this.statusTimeout = setTimeout(() => {
          this.updateExamTransferStatus(index).then(() => this.updateTransferStatusTimer(index));
        }, 10000);
      }
    }

    /*setDefaultDescriptions(): void {
        if (this.examOrders.length > 1) {
            for (let i = 1; i < this.examOrders.length; i++) {
                this.hideDescriptionList(i);
            }
        }
        this.showDescriptions(0);
    }*/

    showTimer(second: number): void {
        second = 600 - second;
        let minutes = Math.floor(second / 60);
        let seconds = second - (minutes * 60);
        this.timerActive = true;
        this.timerLabel.doneLabel = 'Wysłano';
        this.timerLabel.startCountdown(minutes, seconds);
        this.timeOutEvent = this.timerLabel.timeOutEmitter.subscribe((e) => {
            if (e) {
                this.checkIfStillSending();
            }
        });
    }

   /* showDescriptionList(index) {
        if (this.currentlyChosenExam != null) {
            this.hideDescriptionList(this.currentlyChosenExam);
        }
        this.currentlyChosenExam = index;
        this.currentlyChosenDesc = 0;
        this.currentlyChosenDescStatus = this.examOrders[index].ordersInfo[0].status_info.status_name;
        if (this.currentlyChosenDescStatus == 'REJECTED') {
                this.descriptionText = this.examOrders[index].ordersInfo[0].reject_info.reject_reason;
        } else this.descriptionText = this.examOrders[index].ordersInfo[0].exam_description;
        if (this.descriptionText == '') {
            this.emptyDescription = true;
            this.translate.get('OrderDescriptionScreens.Comparasion.EmptyDescription').subscribe((res) => {
                this.descriptionText = res;
            });
        }

        let rowsToShow = $('#mainTable' + index).show().children('td').animate({ paddingTop: 8, paddingBottom: 8 }, 500).children('div:hidden');
        rowsToShow.slideDown(550, function() {
            rowsToShow.closest('tr').show();
        });
    }*/

    hideDescriptionList(index) {
        this.currentlyChosenExam = null;
        this.currentlyChosenDescStatus = '';

        $('#mainTable' + index).children('td').animate({paddingTop: 0, paddingBottom: 0 }, 500)
        .wrapInner('<div />').children().slideUp(450, function() { $('#mainTable' + index).hide(); });
    }

    checkIfStillSending() {
        this.descriptionService.getExamInfo().then(() => {
            if (this.descriptionService.orderDescription.status.codename == 'TO_APPOVE_BY_TIME') {
                this.setIntervalForRefresh();
            } else {
                this.getData();
                this.descriptionService.getExamActions();
            }
        });
    }

    setIntervalForRefresh() {
        let interval = setInterval(() => {
            this.descriptionService.getExamInfo().then(() => {
                if (this.descriptionService.orderDescription.status.codename != 'TO_APPOVE_BY_TIME') {
                    this.getData();
                    this.descriptionService.getExamActions();
                    clearInterval(interval);
                }
            });
        }, 10000);
    }

    /*printOrder() {
        this.ordersService.getExamDescriptionPdf(this.examOrders[this.currentlyChosenExam].ordersInfo[this.currentlyChosenDesc].id_exam_order).then(
            (res) => {
                if (res == 'OK') {
                    if (this.ordersService.examDescriptionUuid == null) {
                        this.pdfModal.show();
                    } else {
                        this.ordersService.downloadExamFile();
                    }
                }
            }
        );
    }*/

    downloadOrder() {
        let now = new Date().toString();
        document.getElementById('download').setAttribute('href', 'data:text/plain;charset=UTF-8,' + encodeURIComponent(now));
    }

    switchToDescriptionView(): void {
        sessionStorage.setItem('mode', 'description');
        this.descriptionService.mode = {
            mode: 'description',
            code: 1
        };
        this.descriptionService.getExamInfo();
        this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/orderDescriptionComparison'],
            { queryParams: { mode: this.descriptionService.mode.mode } });
    }

  makeAction(actionName: string, examIndex: number, orderIndex: number) {
    if (actionName == 'VIEW_EXAM_ORDER') {
        this.orderInfoModal.show(this.otherExams[examIndex].exam_orders[orderIndex]);
      }

  }
}
