import { EventEmitter } from '@angular/core';
import { UsersService } from './users.service';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./users.service";
import * as i2 from "./odata.service";
import * as i3 from "./session.service";
var NotificationsService = /** @class */ (function () {
    function NotificationsService(userService, odataService, sessionService) {
        this.userService = userService;
        this.odataService = odataService;
        this.sessionService = sessionService;
        this.notificationsCountEvent = new EventEmitter();
        this.notificationsMainEvent = new EventEmitter();
        this.notificationsShortEvent = new EventEmitter();
    }
    NotificationsService.prototype.updateNotificationsCount = function () {
        var _this = this;
        if (sessionStorage.getItem('ACCOUNT_ACTIVE') == null) {
            return;
        }
        this.odataService.postPrivate('get_unread_notifications_count', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        }, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                _this.notificationsCountEvent.emit(+result.Value);
            }
            else {
                console.log('get_unread_notifications_count:', result.Status);
            }
        });
    };
    NotificationsService.prototype.updateCount = function (value) {
        this.notificationsCountEvent.emit(value);
    };
    NotificationsService.prototype.mainRemoved = function (id_notification) {
        var emiter = { id_notification: id_notification, action: 'remove' };
        this.notificationsShortEvent.emit(emiter);
    };
    NotificationsService.prototype.shortRemoved = function (id_notification) {
        var emiter = { id_notification: id_notification, action: 'remove' };
        this.notificationsMainEvent.emit(emiter);
    };
    NotificationsService.prototype.mainReadState = function (id_notification, state) {
        var emiter = { id_notification: id_notification, action: state ? 'read' : 'unread' };
        this.notificationsShortEvent.emit(emiter);
    };
    NotificationsService.prototype.shortReaded = function (id_notification) {
        var emiter = { id_notification: id_notification, action: 'read' };
        this.notificationsMainEvent.emit(emiter);
    };
    NotificationsService.prototype.realoadList = function () {
        this.restarTimer();
        var emiter = { id_notification: 0, action: 'reload' };
        this.updateNotificationsCount();
        this.notificationsShortEvent.emit(emiter);
        this.notificationsMainEvent.emit(emiter);
    };
    NotificationsService.prototype.starTimer = function () {
        this.restarTimer();
    };
    NotificationsService.prototype.stopTimer = function () {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    };
    NotificationsService.prototype.restarTimer = function () {
        var _this = this;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(function () {
            _this.realoadList();
        }, 60000);
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.inject(i1.UsersService), i0.inject(i2.OdataService), i0.inject(i3.SessionService)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
