import { AfterViewInit, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FiltersService } from '@/services/filters.service';
import moment from 'moment';
import { SessionService } from '@/services';
import { FiltersComponent } from '@/utils/filters/filters.component';
var DashboardFiltersComponent = /** @class */ (function () {
    function DashboardFiltersComponent(filterService, sessionService, renderer) {
        this.filterService = filterService;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.chosenFilter = {
            id: 0,
            value: FiltersComponent.createInitialIFiltrDashboard(),
            name: '',
            type: 'dashboard',
            isFavourite: false
        };
        this.statusTypesForDashboard = [];
        this.modalityTypes = [];
        this.anatomyParts = [];
        this.priorityTypes = [];
        this.assignedToList = [];
        this.selectpickers = {
            statusPickerForDashboard: null,
            modalityPicker: null,
            anatomyPicker: null,
            assignedToPicker: null,
            priorityPicker: null
        };
        this.daterange = {};
        this.isActiveDateSort = false;
        this.enOptions = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Current Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: [
                    'Mo',
                    'Tu',
                    'We',
                    'Th',
                    'Fr',
                    'Sa',
                    'Su'
                ],
                monthNames: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                'firstDay': 0
            },
            alwaysShowCalendars: false,
        };
        this.plOptions = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment()],
                'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment()],
                'Ten Miesiąc': [moment().startOf('month'), moment().endOf('month')],
                'Ostatni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD',
                applyLabel: 'Zatwierdź',
                cancelLabel: 'Anuluj',
                fromLabel: 'Z',
                toLabel: 'Do',
                customRangeLabel: 'Własny przedział',
                daysOfWeek: [
                    'Po',
                    'Wt',
                    'Śr',
                    'Cz',
                    'P',
                    'So',
                    'N'
                ],
                'monthNames': [
                    'Styczeń',
                    'Luty',
                    'Marzec',
                    'Kwiecień',
                    'Maj',
                    'Czerwiec',
                    'Lipiec',
                    'Sierpień',
                    'Wrzesień',
                    'Październik',
                    'Listopad',
                    'Grudzień'
                ],
                'firstDay': 0
            },
            alwaysShowCalendars: false,
        };
        this.currentDateValue = '';
        this.options = this.setLangForDatepicker(this.sessionService.language);
    }
    Object.defineProperty(DashboardFiltersComponent.prototype, "PickerSetter", {
        set: function (content) {
            this.picker = content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardFiltersComponent.prototype, "pickerPlaceholderSetter", {
        set: function (content) {
            this.picker_placeholder = content;
        },
        enumerable: true,
        configurable: true
    });
    DashboardFiltersComponent.prototype.ngOnInit = function () {
    };
    DashboardFiltersComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', function () {
            _this.activateDatepicker();
        });
    };
    DashboardFiltersComponent.prototype.ngOnDestroy = function () {
        this.globalPickerListener();
    };
    DashboardFiltersComponent.prototype.activateDatepicker = function () {
        this.picker.nativeElement.click();
    };
    DashboardFiltersComponent.prototype.setCurrentFilter = function (filter) {
        this.daterange.start = moment().subtract(29, 'days');
        this.daterange.end = moment();
        this.chosenFilter = filter;
        if (this.chosenFilter.value.date_range.date_from != '') {
            if (!this.isActiveDateSort) {
                this.isActiveDateSort = true;
            }
            this.daterange.start = this.chosenFilter.value.date_range.date_from;
            this.daterange.end = this.chosenFilter.value.date_range.date_to;
            this.updatePickerPlaceholder(new Date(this.chosenFilter.value.date_range.date_from), new Date(this.chosenFilter.value.date_range.date_to));
        }
        else
            this.clearDaterangeFilter();
    };
    DashboardFiltersComponent.prototype.saveChanges = function (name) {
        var _this = this;
        this.chosenFilter.value = FiltersComponent.createInitialIFiltrDashboard();
        this.selectpickers.statusPickerForDashboard.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.status.push(parseInt(value));
        });
        this.selectpickers.priorityPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.priority.push(parseInt(value));
        });
        this.selectpickers.assignedToPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.assign_to.push(parseInt(value));
        });
        this.selectpickers.anatomyPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.anatomy.push(parseInt(value));
        });
        this.selectpickers.modalityPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.modality.push(parseInt(value));
        });
        if (this.getDate()) {
            return this.filterService.saveFilter(this.chosenFilter.value, this.chosenFilter.type, name, this.chosenFilter.isFavourite);
        }
    };
    DashboardFiltersComponent.prototype.setFilterData = function () {
        var _this = this;
        var promises = [];
        promises.push(this.getStatusTypesForDashboard());
        promises.push(this.getModalityTypes());
        promises.push(this.getAnatomyParts());
        promises.push(this.getPriorityTypes());
        promises.push(this.getAssignedToList());
        Promise.all(promises).then(function () { return _this.setFilter(); });
    };
    DashboardFiltersComponent.prototype.getStatusTypesForDashboard = function () {
        var _this = this;
        return this.filterService.getStatusTypesForExamOrders().then(function (res) {
            res.forEach(function (v) {
                _this.statusTypesForDashboard.push({
                    value: v,
                    selected: false
                });
            });
            _this.selectpickers.statusPickerForDashboard = $('#statusDashboard')['selectpicker']();
        });
    };
    DashboardFiltersComponent.prototype.getModalityTypes = function () {
        var _this = this;
        return this.filterService.getModalitiesGroups().then(function (res) {
            res.forEach(function (v) {
                _this.modalityTypes.push({ value: v, selected: false });
            });
            _this.selectpickers.modalityPicker = $('#modality')['selectpicker']();
        });
    };
    DashboardFiltersComponent.prototype.getAnatomyParts = function () {
        var _this = this;
        return this.filterService.getAnatomyParts().then(function (res) {
            res.forEach(function (v) {
                _this.anatomyParts.push({ value: v, selected: false });
            });
            _this.selectpickers.anatomyPicker = $('#anatomy')['selectpicker']();
        });
    };
    DashboardFiltersComponent.prototype.getPriorityTypes = function () {
        var _this = this;
        return this.filterService.getPriorityTypes().then(function (res) {
            res.forEach(function (v) {
                _this.priorityTypes.push({
                    value: {
                        priority_text: v.priority_text,
                        id_priority: v.id_priority,
                        priority_value: v.priority_value
                    }, selected: false
                });
            });
            _this.selectpickers.priorityPicker = $('#priority')['selectpicker']();
        });
    };
    DashboardFiltersComponent.prototype.getAssignedToList = function () {
        var _this = this;
        return this.filterService.getPhysiciansByCenter('dashboard').then(function (res) {
            res.forEach(function (v) {
                _this.assignedToList.push({
                    value: {
                        name: v.physician_name,
                        id_physician: v.id_physician,
                        profile_image_uuid: v.profile_image_uuid
                    }, selected: false
                });
            });
            _this.selectpickers.assignedToPicker = $('#assignedTo')['selectpicker']();
        });
    };
    DashboardFiltersComponent.prototype.setFilter = function () {
        var _this = this;
        // Status for dashboard
        this.statusTypesForDashboard.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.status.forEach(function (status) {
            _this.statusTypesForDashboard.forEach(function (o) {
                if (status == o.value.id_status) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.statusPickerForDashboard.selectpicker('val', _this.chosenFilter.value.status); }, 0);
        // Anatomy
        this.anatomyParts.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.anatomy.forEach(function (anatomy) {
            _this.anatomyParts.forEach(function (o) {
                if (anatomy == o.value.id_part) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.anatomyPicker.selectpicker('val', _this.chosenFilter.value.anatomy); }, 0);
        // Modality
        this.modalityTypes.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.modality.forEach(function (modality) {
            _this.modalityTypes.forEach(function (o) {
                if (modality == o.value.id_group) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.modalityPicker.selectpicker('val', _this.chosenFilter.value.modality); }, 0);
        // Priority
        this.priorityTypes.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.priority.forEach(function (priority) {
            _this.priorityTypes.forEach(function (o) {
                if (priority == o.value.id_priority) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.priorityPicker.selectpicker('val', _this.chosenFilter.value.priority); }, 0);
        // Assigned to
        this.assignedToList.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.assign_to.forEach(function (assign_to) {
            _this.assignedToList.forEach(function (o) {
                if (assign_to == o.value.id_physician) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.assignedToPicker.selectpicker('val', _this.chosenFilter.value.assign_to); }, 0);
    };
    DashboardFiltersComponent.prototype.setLangForDatepicker = function (lang) {
        if (lang == 'en') {
            return this.enOptions;
        }
        else
            return this.plOptions;
    };
    DashboardFiltersComponent.prototype.selectedDate = function (value, datepicker) {
        // this is the date the user selected
        if (!this.isActiveDateSort) {
            this.isActiveDateSort = true;
        }
        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;
        // or manupulat your own internal property
        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
        this.updatePickerPlaceholder(value.start._d, value.end._d);
    };
    DashboardFiltersComponent.prototype.updatePickerPlaceholder = function (start_date, end_date) {
        var start_date_str = moment(start_date).format('YYYY-MM-DD').toString();
        var end_date_str = moment(end_date).format('YYYY-MM-DD').toString();
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content);
        }
    };
    DashboardFiltersComponent.prototype.updatePickerPlaceholderContent = function (content) {
        this.currentDateValue = content;
    };
    DashboardFiltersComponent.prototype.getDateRange = function () {
        return {
            date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
            date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
        };
    };
    DashboardFiltersComponent.prototype.getDate = function () {
        var tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            }
            else if (this.currentDateValue == '') {
                this.clearDaterangeFilter();
            }
            else {
                this.chosenFilter.value.date_range = this.getDateRange();
            }
        }
        else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        this.chosenFilter.value.date_range.date_from = this.currentDateValue.substring(0, 10);
                        this.chosenFilter.value.date_range.date_to = this.currentDateValue.substring(13, 23);
                    }
                    else {
                        this.chosenFilter.value.date_range.date_from = this.currentDateValue;
                        this.chosenFilter.value.date_range.date_to = this.currentDateValue;
                    }
                    this.daterange.start = this.chosenFilter.value.date_range.date_from;
                    this.daterange.end = this.chosenFilter.value.date_range.date_to;
                }
                else {
                    document.getElementById('placeholder-input').setAttribute('class', 'error');
                    tmp = false;
                }
            }
            else {
                this.clearDaterangeFilter();
            }
        }
        return tmp;
    };
    DashboardFiltersComponent.prototype.clearDaterangeFilter = function () {
        this.isActiveDateSort = false;
        this.updatePickerPlaceholderContent('');
        this.chosenFilter.value.date_range.date_from = '';
        this.chosenFilter.value.date_range.date_to = '';
    };
    DashboardFiltersComponent.prototype.isValidDateRange = function (dateRange) {
        if (dateRange.length != 23 && dateRange.length != 10) {
            return false;
        }
        else {
            if (dateRange.length == 23) {
                var from = dateRange.substring(0, 10);
                var to = dateRange.substring(13, 23);
                if (!moment(from, 'YYYY-MM-DD').isValid() || !moment(to, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
            else {
                if (!moment(dateRange, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
        }
        return true;
    };
    return DashboardFiltersComponent;
}());
export { DashboardFiltersComponent };
