import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-operation-modal',
  templateUrl: './confirm-operation-modal.component.html',
  styleUrls: ['./confirm-operation-modal.component.scss']
})
export class ConfirmOperationModalComponent implements OnInit {
  @Output() confirmed: EventEmitter<{isConfirmed: boolean, idConfirm: string}> = new EventEmitter<{isConfirmed: boolean; idConfirm: string}>();

  title: string;
  content: string;
  rejectBtnTxt: string;
  confirmBtnTxt: string;
  confirmId: string;

  constructor() { }

  ngOnInit() {
  }

  show( title: string, confirmBtnTxt: string, rejectBtnTxt: string, id?: string,  contentTxt?: string) {
    this.title = title;
    this.content = contentTxt ? contentTxt : '';
    this.rejectBtnTxt = rejectBtnTxt;
    this.confirmBtnTxt = confirmBtnTxt;
    this.confirmId = id ? id : '';
    $('#confirmOperationModal').modal('show');
  }
  confirmOperation(result: boolean) {
    $('#confirmOperationModal').modal('hide');
    let emitted = {isConfirmed: result, idConfirm: this.confirmId};
    this.confirmed.emit(emitted);
  }

}
