import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SessionService } from '@/services';
import { NavigationService } from '../../services/navigation.service';

@Injectable()
export class UnauthGuardService implements CanActivate {

    constructor(private session: SessionService, private navigationService: NavigationService) {
    }

    canActivate(): boolean {
        if (this.session.tokenExist() && !this.session.isLocked()) {
            this.navigationService.navigateToDefault();
            return false;
        }
        return true;
    }

}
