import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import {
    OrderDescriptionService,
    OrdersService,
    DraftSenderService,
    OdataService,
    UsersService,
    OrderActionsService
} from '@/services';
import { SaveExamModalComponent } from '@/utils/save-exam-modal/save-exam-modal.component';
import { PageService } from '@/services/page.service';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { IAttachment, OrderDescription } from '@/interfaces';
import { AttachmentViewModalComponent } from '@/utils/attachment-view-modal/attachment-view-modal.component';
import { CovidAIStatistics, initCovidStats, setCovidStatistics } from '@/interfaces/AIConsultation';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-order-description',
    templateUrl: './order-description.component.html',
    styleUrls: ['./order-description.component.css']
})
export class OrderDescriptionComponent implements OnInit, OnDestroy {
    @ViewChild('saveExamModal') saveExamModal: SaveExamModalComponent;
    @ViewChild('attachmentViewModalComponent') attachmentView: AttachmentViewModalComponent;

    descriptionService: OrderDescriptionService;
    ordersCount = 0;

    currentRoute: string;
    orderInfoCardInput: OrderDescription;
    covidStatistics: CovidAIStatistics = initCovidStats;
    copiedText: string = '';

    constructor(private route: ActivatedRoute, descriptionService: OrderDescriptionService,
        private router: Router, private ordersActions: OrderActionsService,
        public ordersService: OrdersService, private pageService: PageService, private draftSender: DraftSenderService,
        private cdRef: ChangeDetectorRef, private odataService: OdataService, private userService: UsersService,
                private env: EnvService

    ) {
        this.descriptionService = descriptionService;
    }

    ngOnInit() {
        this.setCurrentRoute();
        this.descriptionService.isCheckedAttention = false;
        this.pageService.setTranslatedPageTitle('OrderDescriptionScreens.ExamDescription', 0);
        this.route.paramMap.subscribe(async params => {
            this.route.queryParams
                .subscribe(par => {
                    sessionStorage.setItem('mode', par.mode);
                    this.descriptionService.mode.mode = par.mode;
                    this.descriptionService.mode.code = par.mode == 'view' ? 0 : 1;
                });
            this.descriptionService.examId = +params.get('orderId');
            this.descriptionService.isViewDraft = false;
            this.descriptionService.isRejected = false;
            this.loadExamInfo().then(
                () => {
                    if (this.descriptionService.orderDescription.exam_info.consultation) this.covidStatistics = setCovidStatistics(undefined, this.descriptionService.orderDescription);
                    if (this.descriptionService.mode.mode == 'description') {
                      this.checkIfDescriptionModeAvailable();
                    }
                }
            );
        });
        this.cdRef.detectChanges();
    }

    goToDesc() {
        this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/currentDescription'],
                             { queryParams: { mode: sessionStorage.getItem('mode') } });
    }

    checkIfDescriptionModeAvailable() {
        if (this.descriptionService.orderDescription.status.codename != 'IN_PROGRESS' ||
            this.descriptionService.orderDescription.physicianAssigned.id != this.userService.userId) {
            sessionStorage.setItem('mode', 'view');
            if (this.router.url.includes('currentDescription')) this.goToDesc();
            else this.navigateToComparasion();
        }
    }

    loadExamInfo() {
        return this.descriptionService.getExamInfo()
            .then(() => {
                this.pageService.setPageTitle(this.descriptionService.orderDescription.patient.name + ': ' +
                    this.descriptionService.orderDescription.patient.documentId, 0);
                this.countOrdersForPatient();
                this.orderInfoCardInput = this.descriptionService.orderDescription;
            });
    }

    countOrdersForPatient() {
        let filter = FiltersComponent.createInitialIFiltrDashboard();
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: this.descriptionService.examId,
                patient_uuid: this.descriptionService.orderDescription.patientUuid
            }
        );

        this.odataService.postPrivate('co_count_other_patient_described_exams', params, res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                this.ordersCount = parseInt(result.Value);
            }
        });
    }

    ngOnDestroy() {
        $('div').remove('.modal-backdrop, .fade, .show');
        $('body').removeAttr('style');
        let body = document.getElementsByClassName('modal-open');
        if (body.item(0) != null) {
            body.item(0).setAttribute('class', 'h-100');
        }
        this.pageService.resetPageTitle();
    }

    canDeactivate() {
        if (this.descriptionService.mode.mode == 'description' &&
            !this.draftSender.isSaved && this.draftSender.isChanged) {
            this.saveExamModal.show();
            return this.saveExamModal.navigateAwaySelection;
        } else {
            return true;
        }
    }

    setCurrentRoute() {
        this.currentRoute = this.router.url.split('/').slice(-1)[0];
        this.router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.currentRoute = event.url.split('/').slice(-1)[0];
            }
        });
    }

    back() {
        if (this.descriptionService.mode.mode == 'description' &&
            !this.draftSender.isSaved && this.draftSender.isChanged) {
            this.saveExamModal.show();
            this.router.navigate([this.ordersActions.cameFrom]);
        } else {
            this.router.navigate([this.ordersActions.cameFrom]);
            this.draftSender.isSaved = false;
        }
    }

    navigateToComparasion(): void {
        this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/orderDescriptionComparison'],
            { queryParams: { mode: sessionStorage.getItem('mode') } });
    }

  showAttachment(attach: IAttachment) {
    if (attach.content_type.includes('pdf')) {
      window.open(this.env.examOrderAttUrl + attach.attachment_uuid, '_blank');
    } else {
      this.attachmentView.show(attach);
    }
  }

  cssCardColor(): string {
    const cardTypeClass = {
      ['APPROVED']: 'green',
      ['TO_APPOVE_IN_TIME']: 'green',
      ['CITO']: 'orange',
      ['EMERGENCY']: 'red'
    };
    return cardTypeClass[this.descriptionService.orderDescription.priority.name] || 'green';
  }
}
