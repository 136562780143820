<div aria-hidden="true" aria-labelledby="alertModalLabel" class="modal fade" id="alertModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" (click)="close()" >&times;</button>-->
        <button type="button" class="btn btn-light close" (click)="close()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title mb-4" id="alertModalLabel">{{'AlertModals.'+modalConfig.modalTitle|translate}}</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="modalConfig.modalText && modalConfig.separateModalText"><div [innerHTML]="modalConfig.modalText"></div></ng-container>
        <ng-container *ngIf="modalConfig.modalText && !modalConfig.separateModalText"> <p>{{'AlertModals.'+modalConfig.modalText|translate}}</p></ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="close()">
          <ng-container *ngIf="modalConfig.closeButtonLabel"> {{'AlertModals.'+modalConfig.closeButtonLabel|translate}}</ng-container>
          <ng-container *ngIf="!modalConfig.closeButtonLabel">OK</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
