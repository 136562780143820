export class Toast {
  type: ToastType;
  message: string;
  autoClose: boolean;
  fade: boolean;
  shrinkSameToast: boolean;
  sameToastCount: number;

  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
    this.sameToastCount = 1;
  }
}

export enum ToastType {
  Info,
  Success,
  Error,
  Warning
}

export const ToastTypeStringTable = {
  'success': ToastType.Success,
  'error': ToastType.Error,
  'info': ToastType.Info,
  'warning': ToastType.Warning
};

