<div class="row">
  <div class="col-md-12 page-back">
      <h3>
          <a style="cursor:pointer;" routerLink="/dcProfiles"></a>
          Profil Centrum Usługowego
      </h3>
  </div>
</div>

<div class="row mb-4">
  <div class="col-12">
    <div class="card">
      <div class="card-body p-4">
        <svg-icon class="svg mr-3 ml-2" alt="" src="../../../../assets/images/icons/icon__invite_info.svg"></svg-icon>
        <span class="my-auto">To Centrum Usługowe szuka obecnie lekarzy Twojej specjalizacji.</span>
      </div>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-12">
    <div class="card">
      <div class="card-body p-4">
        <div class="row">
          <div class="col-3 my-auto">
            <img alt="" src="../../../assets/images/dummy_dc_logo.png">
          </div>
          <div class="col-9">
            <h2>Centrum Opisowe Sonar</h2>
            <p class="mb-4">Warszawa, ul. Komandytowa 1</p>
            <div class="stats-numbers" style="display: none;">
              <dl class="col-dl">
                  <dt class="text-right"><span
                          class="blue">6
                          {{'Profile.HoursShortcut' | translate}}</span></dt>
                  <dd>{{'Profile.AverageOrderReactionTime' | translate}}</dd>
                  <dt class="text-right"><span
                          class="blue">30
                          {{'Profile.MinutesShortcut' | translate}}</span></dt>
                  <dd>{{'Profile.AverageOrderRealizationTime' | translate}}</dd>
                  <dt class="text-right"><span
                          class="blue">120</span>
                  </dt>
                  <dd>{{'Profile.OrdersCompleted' | translate}}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-8">
    <div class="card big-card h-100">
      <h3 class="card-title pl-5 mb-0">Godziny pracy</h3>
      <div class="card-body pl-5 pt-4 pb-0">
        <div class="row">
          <div class="col-md-6">
            <dl class="col-dl">
                <dt>Poniedziałek</dt>
                <dd>08:00 - 16:00</dd>
            </dl>
            <dl class="col-dl">
              <dt>Wtorek</dt>
              <dd>08:00 - 16:00</dd>
            </dl>
            <dl class="col-dl">
              <dt>Środa</dt>
              <dd>08:00 - 16:00</dd>
            </dl>
            <dl class="col-dl">
              <dt>Czwartek</dt>
              <dd>08:00 - 16:00</dd>
            </dl>
          </div>

          <div class="col-md-6">
            <dl class="col-dl">
              <dt>Piątek</dt>
              <dd>08:00 - 16:00</dd>
            </dl>
            <dl class="col-dl">
              <dt>Sobota</dt>
              <dd>nieczynne</dd>
            </dl>
            <dl class="col-dl">
              <dt>Niedziela</dt>
              <dd>0nieczynne</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="card big-card h-100">
      <h3 class="card-title pl-5 mb-0">Dane kontaktowe</h3>
      <div class="card-body pl-5 pt-5 pb-0">
        <p class="mb-5">
          <svg-icon class="svg mr-3 ml-1" alt="" src="../../../../assets/images/icons/icon__marker.svg"></svg-icon>
          Warszawa, ul. Komandytowa 1
        </p>
        <p class="mb-5">
          <svg-icon class="svg mr-3" alt="" src="../../../../assets/images/icons/icon__phone.svg"></svg-icon>
          Rejestracja: +48 100 200 300
        </p>
        <p class="mb-0">
          <svg-icon class="svg mr-3" alt="" src="../../../../assets/images/icons/icon__mail.svg"></svg-icon>
          kontakt@sonarcu.pl
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card big-card">
      <h3 class="card-title pl-5 mb-0">O centrum</h3>
      <div class="card-body pl-5 pt-4">
        <p class="mb-4">
          Jestem absolwentem Śląskiego Uniwersytetu Medycznego, odbyłam staż w Akademickim Centrum Stomatologii i Medycyny Specjalistycznej. Doświadczenie zdobywałam między innymi we Włoszech oraz w Stanach Zjednoczonych. Przez lata pracy nieustannie poszerzam swoją wiedzę w dziedzinie protetyki, implantów, implantoprotetyki, endodoncji oraz stomatologii zachowawczej, ucząc się od najlepszych dentystów na świecie.
        </p>
        <p class="mb-4">
          Moim celem jest dostarczenie Państwu przewidywalnego, pięknego i funkcjonalnego uśmiechu, który będzie również spełniał wszystkie Państwa marzenia. Uważam, że kluczem leczenia jest zrozumienie Państwa potrzeb, dlatego dla lepszej komunikacji ukończyłam również podyplomowe studia z dziedziny coachingu zdrowia.
        </p>
        <p>
          Więcej na www.sonar.pl
        </p>
      </div>
    </div>
  </div>
</div>