<div id="top-bar" style="z-index: 50;">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'DashboardScreen.Orders' | translate}}</h3>

            <ul [hidden]="!contextService.checkPermission('ordersTopbarNav')" id="top-bar-nav">
                <li [ngClass]="{'active': currentId == 'Wszystkie'}" id="Wszystkie">
                    <a (click)="getOrders('Wszystkie')" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                    style="cursor: pointer;">{{'DashboardScreen.All' | translate}}</a>
                </li>
                <li [ngClass]="{'active': currentId == 'Pilne'}" id="Pilne">
                    <a (click)="getOrders('Pilne')" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                    style="cursor: pointer;">{{'DashboardScreen.Urgent' | translate}}</a>
                </li>
                <li [ngClass]="{'active': currentId == 'Moje zlecenia'}" id="Moje zlecenia">
                    <a (click)="getOrders('Moje zlecenia')" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                    style="cursor: pointer;">{{'DashboardScreen.MyOrders' | translate}}</a>
                </li>
                <ng-container *ngFor="let filter of filterService.getMyFilters('dashboard'); let i = index">
                    <li *ngIf="filter.isFavourite"  id="myFilter{{i}}" [ngClass]="{'active': currentId == i.toString()}">
                        <a (click)="getOrders(i.toString())" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                        style="cursor: pointer;">{{filter.name}}</a>
                    </li>
                </ng-container>
                <li class="dropdown">
                    <a style="cursor: pointer;" id="otherFilters" data-toggle="dropdown" aria-haspopup="true"
                       aria-expanded="false" [style.background]="isActiveOtherFilter ? 'rgba(8, 72, 255, 0.1)' : 'none'"
                       [style.color]="isActiveOtherFilter ? '#0848FF' : '#444444'" (click)="hideFilter()">
                        {{moreFilters}}
                        <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="otherFilters">
                        <ng-container *ngFor="let filter of filterService.getMyFilters('dashboard'); let i = index">
                            <a *ngIf="!filter.isFavourite" class="dropdown-item" id="myFilter{{i}}" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                            (click)="getOrders(i.toString())" style="cursor: pointer">{{filter.name}}</a>
                        </ng-container>
                        <a routerLink="/manageMyFilters/dashboard" class="dropdown-item" style="cursor: pointer">
                          {{'DashboardScreen.Filters.ManageYourFilters' | translate}}
                        </a>
                    </div>
                </li>
            </ul>

            <form [hidden]="!contextService.checkPermission('ordersTopbarNav')">
                <ul class="top-bar-nav">
                    <li (click)="dropdown()" class="filter_dropdown" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'">
                        <a><span *ngIf="filters.filtersActiveDashboard" class="dot"></span>
                        {{'DashboardScreen.Filters.FilterSort' | translate}}
                            <svg-icon alt="" class="svg"
                                    src="../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
                        </a>
                    </li>
                </ul>
                <app-search [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'" #search placeholder="SearchPlaceholder"></app-search>
            </form>

        </div>
    </div>
</div>

<app-filters (filter)="filterOrders($event);" #filters type="DASHBOARD"></app-filters>

<app-loader #loader></app-loader>
<div [hidden]="!contextService.checkPermission('ordersList')">

    <app-orders-list #ordersList [orders]="ordersService.orders" [hours]="ordersService.hours" [dates]="ordersService.dates" [age]="ordersService.age" type="Dashboard"></app-orders-list>
    <app-pagination #pagination componentName="Dashboard" [hidden]="ordersService.orders.length == 0"></app-pagination>

    <app-empty-orders-list [mode]="'DC'" [page]="'dashboard'" [ordersCount]="ordersService.orders.length" [isLoaderShow]="loader.show" [isSearched]="search.isSearch" [isFiltered]="filters.filtersActiveDashboard || currentId != ''"></app-empty-orders-list>

    <app-assign-physician-modal #assignPhysicianModal></app-assign-physician-modal>
    <app-reject-modal #rejectModal></app-reject-modal>
    <app-consultation-view-modal #consultationViewModal></app-consultation-view-modal>
    <app-order-to-correct-modal #toCorrectModal></app-order-to-correct-modal>
    <app-pdf-not-available-modal #pdfModal></app-pdf-not-available-modal>
</div>

<div [hidden]="contextService.checkPermission('ordersList')" class="row">
    <div class="col-3"></div>
    <div class="col-6 px-5">
        <div class="row empty-list mx-3">
            <div class="card">
                <div class="card-body ml-2 mr-5">
                <h5 class="card-title mt-2" style="color: black;" [innerHTML]="'DashboardScreen.EmptyCOTitle' | translate"></h5>
                <p class="card-text mb-5">
                    {{'DashboardScreen.EmptyCOText' | translate}}
                </p>
                </div>
            </div>
        </div>
        <div class="row mt-4 empty-list-actions mx-3">
            <div class="col pl-0">
                <a routerLink="/findCO">
                    <div class="card pt-4">
                        <svg-icon alt=""
                                src="../../../../assets/images/icons/menu_item_profile.svg"
                                id="no-profile-pic-icon" [applyCss]="true"></svg-icon>
                        <div class="card-body">
                        <p class="card-text" [innerHTML]="'DashboardScreen.SendInvitingToCO' | translate"></p>
                        </div>
                    </div>
                    </a>
            </div>
            <div class="col pr-0 pl-2">
                <a routerLink="/registrationCo1">
                    <div class="card pt-4">
                        <svg-icon alt=""
                                src="../../../../assets/images/icons/menu_item_profile.svg"
                                id="no-profile-pic-icon" [applyCss]="true" style="height: 90px;"></svg-icon>
                        <div class="card-body">
                        <p class="card-text" [innerHTML]="'DashboardScreen.RegisterCO' | translate"></p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
