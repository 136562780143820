import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ToastService } from '@/core/components/toast/toast.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Server is not responding', error);
            this.toastService.toast(error.status == 500 ? 'Errors.Http.NoResponse' : 'Errors.Http.GenericError', 'error', false);
            return of();
        })
    ) as Observable<HttpEvent<any>>;
  }
}
