import { OdataService } from './odata.service';
import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "@angular/router";
var AdminService = /** @class */ (function () {
    function AdminService(odataService, route) {
        this.odataService = odataService;
        this.route = route;
        this.users = [];
    }
    AdminService.prototype.getUsers = function (params, callback) {
        this.odataService.getPrivate('Users', params, callback);
    };
    AdminService.prototype.getUserData = function (id, params, callback) {
        this.odataService.getPrivate('Users(' + id + ')', params, callback);
    };
    AdminService.prototype.editUser = function (id, body, params, callback) {
        this.odataService.patchPrivate('Users(' + id + ')', body, params, callback);
    };
    AdminService.prototype.addUser = function (params, callback) {
        this.odataService.postPrivate('add_user', params, callback);
    };
    AdminService.prototype.getCenters = function (params, callback) {
        this.odataService.postPrivate('get_centers', params, callback);
    };
    AdminService.prototype.getCenterData = function (id, callback) {
        this.odataService.postPrivate('get_center_data', this.odataService.paramsTokenAndLang({ id_describing_center: id }), callback);
    };
    AdminService.prototype.editCenter = function (params, callback) {
        this.odataService.postPrivate('edit_center', params, callback);
    };
    AdminService.prototype.addCenter = function (params, callback) {
        this.odataService.postPrivate('add_center', params, callback);
    };
    AdminService.prototype.getOrdersForAdmin = function (params, callback) {
        this.odataService.postPrivate('admin_get_co_exam_orders_ext', params, callback);
    };
    AdminService.prototype.getFacilities = function (params, callback) {
        this.odataService.postPrivate('get_medical_facilities', params, callback);
    };
    AdminService.prototype.getExams = function (params, callback) {
        this.odataService.postPrivate('get_medical_facilities_exams', params, callback);
    };
    AdminService.prototype.getCOTransfers = function (params, callback) {
        this.odataService.postPrivate('admin_co_transfer_list', params, callback);
    };
    AdminService.prototype.getUsers2 = function () {
        var _this = this;
        this.usersData = this.odataService.postPrivate('get_users', this.odataService.paramsTokenAndLang({
            offset: 0,
            limit: 10
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                _this.setUsersData(value);
            }
            else {
                // TODO
            }
        });
        return this.usersData;
    };
    AdminService.prototype.setUsersData = function (data) {
        var _this = this;
        var usersData = data;
        usersData.forEach(function (singleUserData) {
            var user = _this.parseIntoUser(singleUserData);
            _this.users.push(user);
        });
    };
    AdminService.prototype.parseIntoUser = function (obj) {
        var user = {
            UserID: obj['id_user'],
            LoginName: obj['login_name'],
            FirstName: obj['first_name'],
            LastName: obj['last_name'],
            DocumentType: obj['id_document_type'],
            ValueId: obj['id_value'],
            Active: obj['active'],
            Email: obj['email'],
            Citizenship: obj['citizenship'],
            PhoneNumber: obj['phone_number'],
            Birthday: obj['birthday'],
            Verified: obj['account_verified'],
            AccountBlockedPermamently: obj['account_blocked_permamently'],
            FailedAttempts: obj['login_failed_attemps'],
            BlockedTimestamp: obj['account_blocked_timestamp'],
            PhoneNumberVerified: obj['phone_number_verified'],
            EmailVerified: obj['email_verified'],
            AccountDeleted: obj['account_deleted'],
        };
        return user;
    };
    AdminService.prototype.getCOStaff = function (params, callback) {
        this.odataService.postPrivate('admin_get_co_staff', params, callback);
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.OdataService), i0.inject(i2.ActivatedRoute)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
