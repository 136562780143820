<div aria-hidden="true" aria-labelledby="rejectModalLabel" class="modal fade" id="rejectModal" role="dialog"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-4" id="odrzucModalLabel">
                    <ng-container *ngIf="!isCancelAssigment && !isRejectedByPhysician">{{'RejectModal.Title' | translate}}</ng-container>
                    <ng-container *ngIf="!isCancelAssigment && isRejectedByPhysician">{{'RejectModal.RejectByMDTitle' | translate}}</ng-container>
                    <ng-container *ngIf="isCancelAssigment">{{'RejectModal.CancelAssigmentTitle' | translate}}</ng-container>
                </h2>
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-alert #alert title="Rejection"></app-alert>
                {{'AssignPhysicianModal.Patient' | translate}}: <strong style="color: var(--blue);">{{patientName}}</strong><br>
                {{'AssignPhysicianModal.Date' | translate}}: <strong style="color: var(--blue);">{{orderDate + ', ' + orderName}}</strong><br>
                <p>{{'AssignPhysicianModal.Anatomy' | translate}}: <strong style="color: var(--blue);">{{anatomyPart}}</strong></p><br>
                <form>
                    <select name="powod-lista" id="powod-lista" class="custom-select mb-4">
                        <option value="powod-0">{{'RejectModal.ChooseCategory' | translate}}</option>
                        <option value="powod-1">{{'RejectModal.Illegible' | translate}}</option>
                        <option value="powod-2">{{'RejectModal.Errors' | translate}}</option>
                        <option value="powod-3">{{'RejectModal.Other' | translate}}</option>
                    </select>
                    <textarea *ngIf="!isCancelAssigment && !isRejectedByPhysician" placeholder="{{'RejectModal.Reason' | translate}}" class="form-control mb-5" name="powod-odrzucenia"
                        id="rejection" rows="10"></textarea>
                    <textarea *ngIf="!isCancelAssigment && isRejectedByPhysician" placeholder="{{'RejectModal.RejectByMD' | translate}}" class="form-control mb-5" name="powod-odrzucenia"
                            id="rejection" rows="10"></textarea>
                    <textarea *ngIf="isCancelAssigment" placeholder="{{'RejectModal.CancelAssigmentReason' | translate}}" class="form-control mb-5" name="powod-odrzucenia"
                        id="rejection" rows="10"></textarea>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" (click)="resolvePromise()" class="btn btn-lg btn-danger">
                    <ng-container *ngIf="!isCancelAssigment && !isRejectedByPhysician">{{'RejectModal.Submit' | translate}}</ng-container>
                    <ng-container *ngIf="!isCancelAssigment && isRejectedByPhysician">{{'RejectModal.RejectByMDBtn' | translate}}</ng-container>
                    <ng-container *ngIf="isCancelAssigment">{{'RejectModal.CancelAssigmentBtn' | translate}}</ng-container>
                </button>
            </div>
        </div>
    </div>
</div>
