/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./toast.component";
import * as i8 from "./toast.service";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ")"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.sameToastCount; _ck(_v, 1, 0, currVal_0); }); }
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "global-toast"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeToast(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(5, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "global-toast"; var currVal_1 = _co.cssClass(_v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "close"; var currVal_3 = _co.faColor(_v.context.$implicit); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = _co.faClose; _ck(_v, 5, 0, currVal_5); var currVal_7 = (_v.context.$implicit.sameToastCount != 1); _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_4); var currVal_6 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.message)); _ck(_v, 6, 0, currVal_6); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "toast-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toasts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 245760, null, 0, i7.ToastComponent, [i8.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastComponentNgFactory = i1.ɵccf("app-toast", i7.ToastComponent, View_ToastComponent_Host_0, {}, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
