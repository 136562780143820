import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from '@/core/components/toast/toast.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
  ],
  declarations: [ToastComponent],
  exports: [ToastComponent]
})
export class ToastModule { }
