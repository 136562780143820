import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '@/services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('ApiService') && req.headers.get('ApiService') === 'ApiService' && req.headers.get('private') === 'true') {
      let token = this.sessionService.getToken();
      const request = req.clone({
        headers: !!token ? req.headers.set('Authorization', 'Bearer ' + token) : req.headers,
        body: !!token ? {
          token: token,
          language: this.sessionService.language,
          ...req.body
        } : {
          language: this.sessionService.language,
          ...req.body
        },
        params: req.method === 'GET' ?
          req.params
            .append('token', token)
            .append('language', this.sessionService.language)
          : req.params
      });
      return next.handle(request);
    }
    if (req.headers.has('ApiService') && req.headers.get('ApiService') === 'ApiService') {
      const requestPublic = req.clone({body: {
          language: this.sessionService.language,
          ...req.body
        }});

      return next.handle(requestPublic);
    }

    return next.handle(req);
  }
}
