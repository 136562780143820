import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from '@/services';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, private session: SessionService) {
    }

    canActivate(): boolean {
        if (!this.session.tokenExist()) {
            this.router.navigate(['loginFirst']);
            return false;
        } else if (this.session.isLocked()) {
            this.router.navigate(['unlockScreen']);
            return false;
        }
        return true;
    }
}
