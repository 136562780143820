import { EnvService } from '@/services/env.service';
import { PdfNotAvailableModalComponent } from '@/utils/pdf-not-available-modal/pdf-not-available-modal.component';
import { SearchComponent } from '@/utils/search/search.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { OrdersService, UsersService, OrderActionsService } from '@/services';
import { Router, ActivatedRoute } from '@angular/router';
import { IFiltrDashboard } from '@/interfaces';
import { Subscription } from 'rxjs';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { PageService } from '@/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@/services';
import moment from 'moment';
import { dateFilterTypes } from './enum';
import { FiltersService } from '@/services/filters.service';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { OrdersListComponent } from '@/utils/orders-list/orders-list.component';

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.css']
})
export class ArchiveComponent implements OnInit, OnDestroy {
    @ViewChild('filters') filters: FiltersComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('loader') loader: LoaderComponent;
    @ViewChild('pagination') pagination: PaginationComponent;
    @ViewChild('ordersList') ordersList: OrdersListComponent;
    @ViewChild('pdfModal') pdfModal: PdfNotAvailableModalComponent;

    currentId: string = '';
    searchSubscription: Subscription;
    pagesSubscription: Subscription;
    actionsSubscription: Subscription;
    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    dateFilter: string = '';
    currentDateFilterType: string = '';
    ordersService: OrdersService;

    getExamDescriptionPdf: Function = (examId: number) => {
        this.ordersService.getExamDescriptionPdf(examId).then(
            (res) => {
                if (res == 'OK') {
                    if (this.ordersService.examDescriptionUuid == null) {
                        this.pdfModal.show();
                    } else {
                        this.ordersService.downloadExamFile();
                    }
                }
            }
        );
    }

    actionsList = {
        'VIEW_EXAM_ORDER': (examId: number): Promise<boolean> => {
            this.filterService.searchInputArchive = this.search.filterSearch;
            return this.orderActions.viewExamOrder(examId);
        },
        'VIEW_DESCRIPTION_PDF':  this.getExamDescriptionPdf
    };

    subscription: Subscription;
    centerSubscription: Subscription;
    filtrDashboard: IFiltrDashboard;
    filterService: FiltersService;
    moreFilters: string = '';
    isActiveOtherFilter = false;
    isEmptyDashboard = false;

    constructor(private userService: UsersService, ordersService: OrdersService, private orderActions: OrderActionsService,
        private router: Router, private route: ActivatedRoute, private pageService: PageService,
        private translate: TranslateService, private sessionService: SessionService, filterService: FiltersService,
        private env: EnvService) {
        this.filterService = filterService;
        this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
        this.ordersService = ordersService;
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (sessionStorage.getItem('filtersArchive') != null) {
                this.filtrDashboard = JSON.parse(sessionStorage.getItem('filtersArchive'));
                window.history.pushState({}, '', '/archive/' + JSON.stringify(this.filtrDashboard));
            } else if (params.get('filter') == null) {
                this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
                this.currentId = 'defaultFilter';
            } else if (params.get('filter') != null) {
                this.filtrDashboard = JSON.parse(decodeURI(params.get('filter')));
            }
            this.filters.filtrDashboard = this.filtrDashboard;
        });

        this.filterService.getFilters('archive').then(() => {
          let myFilterId = sessionStorage.getItem('archiveMyFilter');
          if (myFilterId && myFilterId != '' && myFilterId != 'defaultFilter') {
            this.currentId = myFilterId;
            this.checkMyFiltersForFavorite(myFilterId);
          }
        });
        this.translate.get('DashboardScreen.More')
          .subscribe((res: string) => {
            this.moreFilters = res;
          });
        this.checkDateFilterType();
        if (this.filterService.searchInputArchive != '') {
            this.search.filterSearch = this.filterService.searchInputArchive;
            document.getElementById('clearSearchButton').style.display = 'block';
        }

        this.checkCurrentPage();
        this.makeActionEvent();

        this.searchEvent();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();
        this.setNumberOfPages();

        let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);

        this.centerSubscription = this.userService.centerChanged.subscribe((e) => {
            this.ordersService.getExamOrdersArchive(this.filtrDashboard, offset, parseInt(this.pagination.itemsOnPage), this.search.filterSearch).then(() => {
                let filterTmp = FiltersComponent.createInitialIFiltrArchive();
                filterTmp.date_range.date_to = this.filtrDashboard.date_range.date_to;
                if (JSON.stringify(this.filtrDashboard) === JSON.stringify(filterTmp)) {
                    if (this.ordersService.ordersArchive.length == 0) {
                        this.isEmptyDashboard = true;
                        this.currentId = '';
                    } else {
                        this.currentId = 'defaultFilter';
                        this.isEmptyDashboard = false;
                        this.filtrDashboard.date_range.date_to = '';
                        if (sessionStorage.getItem('timeIsChanged') == null) this.initDateFilterByLast30();
                        this.ordersService.getExamOrdersArchive(this.filtrDashboard, offset, parseInt(this.pagination.itemsOnPage), this.search.filterSearch);
                    }
                }
                let min = moment();
                for (let i = 0; i < this.ordersService.ordersArchive.length; i++) {
                    if (min > moment(this.ordersService.ordersArchive[i].order_date)) {
                        min = moment(this.ordersService.ordersArchive[i].order_date);
                    }
                }
                this.filters.daterange.start = min;
                this.filters.plOptions.startDate = min;
                this.filters.enOptions.startDate = min;
            });
        });

        this.subscription = this.sessionService.emitter.subscribe((event) => {
            this.setDateFilter();
        });

        this.ordersService.archiveLoader = this.loader;

        this.pageService.setTranslatedPageTitle('ArchiveScreen.Title', 0);
        document.getElementById('archiveLink').setAttribute('class', 'active');
    }

    ngOnDestroy(): void {
        document.getElementById('archiveLink').setAttribute('class', '');
        if (JSON.stringify(this.filtrDashboard) != JSON.stringify(FiltersComponent.createInitialIFiltrArchive())) {
            sessionStorage.setItem('filtersArchive', JSON.stringify(this.filtrDashboard));
        } else sessionStorage.removeItem('filtersArchive');
        this.searchSubscription.unsubscribe();
        this.pagesSubscription.unsubscribe();
        this.pageChangeSubscription.unsubscribe();
        this.actionsSubscription.unsubscribe();
        this.itemsOnPageChangeSubscription.unsubscribe();
        this.centerSubscription.unsubscribe();
        this.subscription.unsubscribe();
        this.pageService.resetPageTitle();
    }

    updateArchiveOrders() {
        this.filterService.searchInputArchive = this.search.filterSearch; // updating saved searched input
        if (!this.search.isSearch) {
            this.ordersService.getExamOrdersArchive(
                this.filtrDashboard,
                (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                parseInt(this.pagination.itemsOnPage)).then(
                () => {
                    this.pagination.inputPage = this.pagination.activePage + '';
                }
            );
        } else {
            this.ordersService.getExamOrdersArchive(
                this.filtrDashboard,
                (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                parseInt(this.pagination.itemsOnPage), this.search.filterSearch).then(
                () => {
                    this.pagination.inputPage = this.pagination.activePage + '';
                }
            );
        }
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('Archive'))
            this.pagination.activePage = this.sessionService.getCurrentPage('Archive');
        if (this.sessionService.getItemsOnPage('Archive'))
            this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('Archive');
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.pagination.changePage(page));
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            () => {
                this.updateArchiveOrders();
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            () => {
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
                this.updateArchiveOrders();
            }
        );
    }

    setDateFilter() {
        this.translate.get('ArchiveScreen.' + this.currentDateFilterType)
                .subscribe((res: string) => {
                    this.dateFilter = res;
                });
    }


    setNumberOfPages() {
        this.pagesSubscription = this.ordersService.numberOfArchiveOrders.subscribe(
            res => {
                this.pagination.pages = [];
                this.pagination.numberOfPages = res / parseInt(this.pagination.itemsOnPage);
                Math.floor(this.pagination.numberOfPages);
                for (let i = 0; i < this.pagination.numberOfPages; i++) {
                    this.pagination.pages.push(i + 1);
                }
            }
        );
    }

    dropdown() {
        this.filters.dropdown();
    }

    makeActionEvent(): void {
        this.actionsSubscription = this.ordersList.actionEmitter.subscribe(
            (res) => {
                if (res.action == 'VIEW_EXAM_ORDER') {
                    this.actionsList[res.action](this.ordersService.ordersArchive[res.index].id_exam_order).then(result => {
                        if (result) {
                            if (res.action == 'VIEW_EXAM_ORDER') {
                                this.router.navigate(['/orderDescription/' + this.ordersService.ordersArchive[res.index].id_exam_order + '/currentDescription'], {queryParams: {mode: 'view'}});
                                this.orderActions.cameFrom = 'archive/' + JSON.stringify(this.filtrDashboard);
                            }
                        }
                    });
                } else {
                    this.actionsList[res.action](this.ordersService.ordersArchive[res.index].id_exam_order);
                }
            }
        );
    }

    hideFilter() {
        sessionStorage.setItem('activeFilter', 'true');
        this.dropdown();
    }

    filterOrders(filter: IFiltrDashboard): void {
        this.filtrDashboard = filter;
        sessionStorage.removeItem('filtersArchive');
        sessionStorage.removeItem('archiveMyFilter');
        this.sessionService.removeCurrentPage('Archive');
        window.history.pushState({}, '', '/archive/' + JSON.stringify(filter));
        this.ordersService.getExamOrdersArchive(filter, 0, parseInt(this.pagination.itemsOnPage), this.search.filterSearch).then(
            () => {
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
            }
        );
        this.checkDateFilterType();
        this.translate.get('DashboardScreen.More')
          .subscribe((res: string) => {
            this.moreFilters = res;
          });
        this.isActiveOtherFilter = false;
        this.currentId = '';
        this.filterService.getFilters('archive'); // refreshing my filter list - so deleting etc doesnt change those
    }

    getOrders(id) {
      this.hideFilter();
      this.search.filterSearch = '';
      this.search.isSearch = false;
      $('#searchInput').val('');
      document.getElementById('clearSearchButton').style.display = 'none';
      this.filters.clearDaterangeFilter();
      this.filtrDashboard = this.filterService.getMyFilters('archive')[id].value;
      this.checkMyFiltersForFavorite(id);
      this.filters.filtrDashboard = this.filtrDashboard;
      this.filters.setFilterDashboard();
      sessionStorage.removeItem('filtersArchive');
      sessionStorage.setItem('archiveMyFilter', id);
      this.sessionService.removeCurrentPage('Archive');
      window.history.pushState({}, '', '/archive/' + JSON.stringify(this.filtrDashboard));
      this.ordersService.getExamOrdersArchive(this.filtrDashboard, 0, parseInt(this.pagination.itemsOnPage));
      this.checkDateFilterType();
      this.currentId = id;
    }

    filterOrdersByTime(id: string): void {
        this.dateFilter = document.getElementById(id).innerHTML;
        this.translate.get('DashboardScreen.More')
          .subscribe((res: string) => {
            this.moreFilters = res;
          });
        if (id == 'today') {
            this.filtrDashboard.date_range.date_from = moment().format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.today;
        } else if (id == 'yesterday') {
            this.filtrDashboard.date_range.date_from = moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.yesterday;
        } else if (id == 'last7') {
            this.filtrDashboard.date_range.date_from = moment().subtract(6, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.last7;
        } else if (id == 'last30') {
            this.filtrDashboard.date_range.date_from = moment().subtract(29, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.last30;
        } else if (id == 'thisMonth') {
            this.filtrDashboard.date_range.date_from = moment().startOf('month').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().endOf('month').format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.thisMonth;
        } else if (id == 'lastMonth') {
            this.filtrDashboard.date_range.date_from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.lastMonth;
        } else if ('all') {
            this.filtrDashboard.date_range.date_from = '';
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.all;
        }

        // different date range -> none of "my filters" active
        sessionStorage.removeItem('filtersArchive');
        sessionStorage.removeItem('archiveMyFilter');
        this.isActiveOtherFilter = false;
        this.currentId = 'defaultFilter';

        sessionStorage.setItem('timeIsChanged', 'true');
        window.history.pushState({}, '', '/archive/' + JSON.stringify(this.filtrDashboard));

         // setting  date filter and refreshing - last steps from filtering in filter component
        this.filters.filtrDashboard.date_range.date_from = this.filtrDashboard.date_range.date_from;
        this.filters.filtrDashboard.date_range.date_to = this.filtrDashboard.date_range.date_to;
        if (id == 'all') this.filters.clearDaterangeFilter();
        else this.filters.setFilterDashboard();
        this.filters.filter.emit(this.filters.filtrDashboard);
    }

    clearFilter(): void {
        this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
    }

    checkDateFilterType(): void {
        let dateFrom = this.filtrDashboard.date_range.date_from;
        let dateTo = this.filtrDashboard.date_range.date_to;

        switch (dateTo) {
            case moment().format('YYYY-MM-DD'): {
                switch (dateFrom) {
                    case '': {
                        this.currentDateFilterType = dateFilterTypes.all;
                        break;
                    }
                    case moment().format('YYYY-MM-DD'): {
                        this.currentDateFilterType = dateFilterTypes.today;
                        break;
                    }
                    case moment().subtract(6, 'days').format('YYYY-MM-DD'): {
                        this.currentDateFilterType = dateFilterTypes.last7;
                        break;
                    }
                    case moment().subtract(29, 'days').format('YYYY-MM-DD'): {
                        this.currentDateFilterType = dateFilterTypes.last30;
                        break;
                    }
                }
                break;
            }
            case moment().subtract(1, 'days').format('YYYY-MM-DD'): {
                if (dateFrom == moment().subtract(1, 'days').format('YYYY-MM-DD')) this.currentDateFilterType = dateFilterTypes.yesterday;
                break;
            }
            case moment().endOf('month').format('YYYY-MM-DD'): {
                if (dateFrom == moment().startOf('month').format('YYYY-MM-DD')) this.currentDateFilterType = dateFilterTypes.thisMonth;
                break;
            }
            case moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'): {
                if (dateFrom == moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')) this.currentDateFilterType = dateFilterTypes.lastMonth;
                break;
            }
            case '': {
                if (dateFrom == '') {
                    this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
                    this.currentDateFilterType = dateFilterTypes.all;
                    window.history.pushState({}, '', '/archive/' + JSON.stringify(this.filtrDashboard));
                }
                break;
            }
            default: {
                this.currentDateFilterType = dateFilterTypes.ownRange;
            }
        }
        this.setDateFilter();
    }

    initDateFilterByLast30(): void {
        if (this.filters.filtrDashboard.date_range.date_from == '' && this.filters.filtrDashboard.date_range.date_to == '') {
            this.filters.filtrDashboard.date_range.date_from = moment().subtract(30, 'days').format('YYYY-MM-DD');
            this.filters.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_from = moment().subtract(29, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.last30;
            this.setDateFilter();
            window.history.pushState({}, '', '/archive/' + JSON.stringify(this.filtrDashboard));
        }
    }

   checkMyFiltersForFavorite(myFilterId: string) {
      if (!this.filterService.getMyFilters('archive')[parseInt(myFilterId)].isFavourite) {
       this.moreFilters = this.filterService.getMyFilters('archive')[parseInt(myFilterId)].name;
       this.isActiveOtherFilter = true;
     } else {
       this.translate.get('DashboardScreen.More')
         .subscribe((res: string) => {
           this.moreFilters = res;
         });
       this.isActiveOtherFilter = false;
     }
  }
}
