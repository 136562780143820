import { Injectable } from '@angular/core';
import { OdataService } from '@/services/odata.service';
import { UsersService } from '@/services/users.service';
import { SessionService } from '@/services/session.service';

interface IModalities {
    value: string;
    id_group: number;
    language: string;
}

interface IAnatomyParts {
    value: string;
    id_part: number;
    language: string;
}

interface IPortfolio {
    enable: boolean;
    id_part: number;
    id_group: number;
}

interface ICOPortfolio {
    price: number;
    id_part: number;
    id_group: number;
}

interface IPrice {
    price: number;
    id_part: number;
    id_group: number;
}

interface IPriceList {
    prices: IPrice[];
    price_list_name: string;
    id_price_list_main: number;
}

@Injectable({
    providedIn: 'root'
})
export class PortfolioService {
    modalities: IModalities[] = [];
    anatomyParts: IAnatomyParts[] = [];
    doctorsPortfolio: IPortfolio[] = [];
    finalDoctorsPortfolio: IPortfolio[][] = [];
    finalDefaultCoPortfolio: ICOPortfolio[][] = [];
    listCOPortfolios: ICOPortfolio[][][] = [];

    priceList: IPriceList = {
        prices: [],
        price_list_name: '',
        id_price_list_main: 0
    };
    priceLists: IPriceList[] = [];
    id_default_portfolio = 0;
    isRowsChecked: boolean[] = [];
    isColsChecked: boolean[] = [];
    showEmptyPriceListAlert: boolean = false;

    constructor(private odataService: OdataService, private userService: UsersService, private session: SessionService) {
    }

    setDoctorsPortfolio(portfolio) {
        Promise.all([new Promise((resolve) => {
                this.get_anatomy_parts(resolve);
            }),
                new Promise((resolve) => {
                    this.get_modalities_groups(resolve);
                })])
            .then(() => {
                this.doctorsPortfolio = [];
                this.finalDoctorsPortfolio = [];
                let count = 0;
                this.isColsChecked = new Array(this.modalities.length).fill(true);
                this.isRowsChecked = new Array(this.anatomyParts.length).fill(true);
                for (let i = 0; i < this.anatomyParts.length; i++) {
                    this.isRowsChecked.push(false);
                    for (let j = 0; j < this.modalities.length; j++) {
                        this.doctorsPortfolio.push({
                            enable: false,
                            id_part: i + 1,
                            id_group: j + 1
                        });
                    }
                }
                for (let i = 0; i < portfolio.length; i++) {
                    let index = portfolio[i].id_part *
                        this.modalities.length - this.modalities.length +
                        portfolio[i].id_group;
                    this.doctorsPortfolio[index - 1].enable = portfolio[i].enable;
                }
                for (let i = 0; i < this.anatomyParts.length; i++) {
                    this.finalDoctorsPortfolio.push([]);
                    for (let j = 0; j < this.modalities.length; j++) {
                        this.finalDoctorsPortfolio[i].push({
                            enable: this.doctorsPortfolio[count].enable,
                            id_part: this.doctorsPortfolio[count].id_part,
                            id_group: this.doctorsPortfolio[count].id_group
                        });
                        if (!this.finalDoctorsPortfolio[i][j].enable) {
                            this.isRowsChecked[i] = false;
                            this.isColsChecked[j] = false;
                        }
                        count++;
                    }
                }
            });
    }

    setDefaultCOPortfolio(id_center, id_list) {
        Promise.all([new Promise((resolve) => {
                this.get_anatomy_parts(resolve);
            }),
                new Promise((resolve) => {
                    this.get_modalities_groups(resolve);
                    this.id_default_portfolio = id_list;
                }),
                new Promise((resolve) => {
                    this.get_describing_center_price_list(id_center, id_list, resolve);
                })])
            .then(() => {
                this.finalDefaultCoPortfolio = [];
                for (let i = 0; i < this.anatomyParts.length; i++) {
                    this.finalDefaultCoPortfolio.push(new Array());
                    for (let j = 0; j < this.modalities.length; j++) {
                        if (this.priceList.prices.find(x => x.id_part == i + 1 && x.id_group == j + 1) != null) {
                            this.finalDefaultCoPortfolio[i].push({
                                price: this.priceList.prices.find(x => x.id_part == i + 1 && x.id_group == j + 1).price,
                                id_part: this.priceList.prices.find(x => x.id_part == i + 1 && x.id_group == j + 1).id_part,
                                id_group: this.priceList.prices.find(x => x.id_part == i + 1 && x.id_group == j + 1).id_group
                            });
                        } else {
                            this.finalDefaultCoPortfolio[i].push({
                                price: 0,
                                id_part: i + 1,
                                id_group: j + 1
                            });
                        }
                    }
                }
            });
    }

    setPortfolioList(id_center, id_list: number[]) {
        Promise.all([new Promise((resolve) => {
            this.get_anatomy_parts(resolve);
        }),
            new Promise((resolve) => {
                this.get_modalities_groups(resolve);
            }),
            new Promise(() => {
                this.listCOPortfolios = [];
                for (let k = 0; k < id_list.length; k++) {
                    Promise.all([new Promise(((resolve1) => {
                        this.get_describing_center_price_list(id_center, id_list[k], resolve1);
                    }))]).then(
                        () => {
                            let portfolio: ICOPortfolio[][] = [];
                            for (let i = 1; i <= this.anatomyParts.length; i++) {
                                portfolio.push([]);
                                for (let j = 1; j <= this.modalities.length; j++) {
                                    if (this.priceList.prices.find(x => x.id_group == j && x.id_part == i) != null) {
                                        portfolio[i - 1].push({
                                            price: this.priceList.prices.find(x => x.id_group == j && x.id_part == i).price,
                                            id_group: j,
                                            id_part: i
                                        });
                                    } else
                                        portfolio[i - 1].push({
                                            price: 0,
                                            id_group: j,
                                            id_part: i
                                        });
                                }
                            }
                            this.listCOPortfolios.push(portfolio);
                        }
                    );
                }
            })]);

    }

    get_modalities_groups(resolve) {
        this.odataService.postPrivate('get_modalities_groups',
            this.odataService.paramsTokenAndLang({}),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson).Value;
                this.modalities = JSON.parse(result);
                resolve('OK');
            }
        );
    }

    get_anatomy_parts(resolve) {
        this.odataService.postPrivate('get_anatomy_parts',
            this.odataService.paramsTokenAndLang({}),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson).Value;
                this.anatomyParts = JSON.parse(result);
                resolve('OK');

            }
        );
    }

    get_describing_center_price_list(id_center, id_list, resolve) {
        this.odataService.postPrivate('get_describing_center_price_list',
            {
                token: this.session.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: id_center,
                id_price_list: id_list
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    this.priceList = JSON.parse(result.Value);
                    if (id_list != this.id_default_portfolio) {
                        this.priceLists.push(this.priceList);
                    }
                    this.priceLists.length == 0 ? this.showEmptyPriceListAlert = true : this.showEmptyPriceListAlert = false;
                    resolve('OK');
                }
            });
    }

    clearPortfolioData() {
        this.modalities = [];
        this.anatomyParts = [];
        this.doctorsPortfolio = [];
        this.finalDoctorsPortfolio = [];
        this.finalDefaultCoPortfolio = [];
        this.listCOPortfolios = [];

        this.priceList = {
          prices: [],
          price_list_name: '',
          id_price_list_main: 0
        };
        this.priceLists = [];
        this.id_default_portfolio = 0;
        this.isRowsChecked = [];
        this.isColsChecked = [];
        this.showEmptyPriceListAlert = false;
    }
}
