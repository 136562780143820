import { Component, OnInit, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.css'],
    selector: 'app-time-picker',
})
export class TimePickerComponent implements OnInit {

    @Input() inputTime: string;
    @Output() time: Date;
    hour: string = '';
    isValid: boolean;
    minute: string = '';
    strTime: string[];
    static pickerNumber = 0;
    isAutoChange = false;

    isMouseWheelAllowed: boolean = false;
    isKeyAllowed: boolean = false;
    showMeridian: boolean = false;

    constructor(public datepipe: DatePipe) { }

    ngOnInit() {
        Promise.all([new Promise((resolve) => {
          if (this.inputTime != null) {
            this.strTime = this.inputTime.split(':');
            this.hour = this.strTime[0];
            this.minute = this.strTime[1];
            this.time = new Date(1970, 0, 1, +this.strTime[0], +this.strTime[1], +this.strTime[2]);
            resolve('OK');
          } else {
            this.time = null;
            resolve('OK');
          }
        })]).then(() => {
          if (TimePickerComponent.pickerNumber <= 13) {
          } else {
            TimePickerComponent.pickerNumber = 0;
          }
          this.addEventsToArrows();
        });
    }

    GetTime() {
      if (this.time != null && moment(this.hour + ':' + this.minute, 'HH:mm', true).isValid()) {
        return this.datepipe.transform(this.time, 'HH:mm');
      } else {
        if ((this.hour == '' && this.minute == '') || (this.hour == '0' && this.minute == '0')) {
          return '';
        } else {
          return this.hour + ':' + this.minute;
        }
      }
    }

    addEventsToArrows() {
      let timepicker = document.getElementsByTagName('timepicker')[TimePickerComponent.pickerNumber];
      let arrows = timepicker.getElementsByClassName('btn-link');
      for (let i = 0; i < arrows.length; i ++) {
        arrows[i].addEventListener('click', () => this.setHour());
      }
      TimePickerComponent.pickerNumber++;
    }

    setHour() {
      this.isAutoChange = false;
      if (moment(this.time).isValid()) {
        this.hour = moment(this.time, 'HH:mm').format('HH').toString();
        this.minute = moment(this.time, 'HH:mm').format('mm').toString();
      } else {
        this.hour = '';
        this.minute = '';
      }
    }

    setTime(event) {
      if (event.srcElement.placeholder == 'HH') {
        if (event.inputType == 'insertText') {
          if (this.hour.length == 2) {
            this.hour = '';
          }
          this.hour += event.data;
        } else if (event.inputType == 'deleteContentBackward') {
          this.hour = this.hour.substring(0, this.hour.length - 1);
        }
      } else if (event.srcElement.placeholder == 'MM') {
        if (event.inputType == 'insertText') {
          if (this.minute.length == 2) {
            this.minute = '';
          }
          this.minute += event.data;
        } else if (event.inputType == 'deleteContentBackward') {
          this.minute = this.minute.substring(0, this.minute.length - 1);
        }
      }
    }
}
