import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service';
import { isObject, isArray } from 'util';
import { Router } from '@angular/router';
import { EnvService } from './env.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./session.service";
import * as i3 from "@angular/router";
import * as i4 from "./env.service";
var OdataService = /** @class */ (function () {
    function OdataService(http, sessionService, router, env) {
        var _this = this;
        this.http = http;
        this.sessionService = sessionService;
        this.router = router;
        this.env = env;
        this.errorsEmitter = new EventEmitter();
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');
        this.ROOT_URL = this.env.publicServerURL;
        this.ROOT_URL_PRIVATE = this.env.privateServerURL;
        this.httpPrivateRequests = new Set();
        this.handleRights = function (res) {
            var status = res && res.Status;
            if (status === 'INSUFFICIENT_RIGHTS') {
                _this.errorsEmitter.emit(status);
            }
            return res;
        };
        this.handleInvalidToken = function (res) {
            var status = res && res.Status;
            if (status === 'INVALID_TOKEN') {
                _this.errorsEmitter.emit(status);
                _this.sessionService.clearAllSessionInfo();
                _this.router.navigate(['/loginFirst']);
                throw status;
            }
            return res;
        };
        this.toCamel = function (s) {
            return s.replace(/([-_][a-z])/ig, function ($1) {
                return $1.toUpperCase().replace('-', '').replace('_', '');
            });
        };
        this.keysToCamel = function (o) {
            var _this = this;
            if (isArray(o)) {
                return o.map(function (i) { return _this.keysToCamel(i); });
            }
            else if (isObject(o)) {
                var n_1 = {};
                Object.keys(o).forEach(function (k) {
                    n_1[_this.toCamel(k)] = _this.keysToCamel(o[k]);
                });
                return n_1;
            }
            return o;
        };
    }
    OdataService.prototype.communicate_error = function (error) {
        console.log(error);
    };
    OdataService.prototype.insert_header_private = function () {
        var token = this.sessionService.getToken();
        this.headers_private = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });
    };
    OdataService.prototype.postPublic = function (serverFunctionName, params, resFunction) {
        var fullURL = this.ROOT_URL + serverFunctionName;
        return this.http.post(fullURL, params, { headers: this.headers }).toPromise()
            .then(resFunction).catch(this.communicate_error);
    };
    OdataService.prototype.postPrivate = function (serverFunctionName, params, resFunction) {
        var _this = this;
        this.insert_header_private();
        var fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;
        var promise = this.http.post(fullURL, params, { headers: this.headers_private }).toPromise()
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return _this.handleInvalidToken(res);
        }
        else {
            return '';
        } })
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return _this.handleRights(res);
        }
        else {
            return '';
        } })
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return resFunction(res);
        }
        else {
            return '';
        } })
            .catch(function (error) { if (_this.httpPrivateRequests.has(promise)) {
            _this.communicate_error(error);
        } })
            .then(function (res) { _this.httpPrivateRequests.delete(promise); return res; });
        this.httpPrivateRequests.add(promise);
        return promise;
    };
    OdataService.prototype.getPrivate = function (serverFunctionName, params, resFunction) {
        var _this = this;
        this.insert_header_private();
        var fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;
        var promise = this.http.get(fullURL, { params: params, headers: this.headers_private }).toPromise()
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return _this.handleInvalidToken(res);
        }
        else {
            return '';
        } })
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return _this.handleRights(res);
        }
        else {
            return '';
        } })
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return resFunction(res);
        }
        else {
            return '';
        } })
            .catch(function (error) { if (_this.httpPrivateRequests.has(promise)) {
            _this.communicate_error(error);
        } })
            .then(function (res) { _this.httpPrivateRequests.delete(promise); return res; });
        this.httpPrivateRequests.add(promise);
        return promise;
    };
    OdataService.prototype.patchPrivate = function (serverFunctionName, body, params, resFunction) {
        var _this = this;
        this.insert_header_private();
        var fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;
        var promise = this.http.patch(fullURL, body, { params: params, headers: this.headers_private }).toPromise()
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return _this.handleInvalidToken(res);
        }
        else {
            return '';
        } })
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return _this.handleRights(res);
        }
        else {
            return '';
        } })
            .then(function (res) { if (_this.httpPrivateRequests.has(promise)) {
            return resFunction(res);
        }
        else {
            return '';
        } })
            .catch(function (error) { if (_this.httpPrivateRequests.has(promise)) {
            _this.communicate_error(error);
        } })
            .then(function (res) { _this.httpPrivateRequests.delete(promise); return res; });
        this.httpPrivateRequests.add(promise);
        return promise;
    };
    OdataService.prototype.postPrivateObservable = function (serverFunctionName, params) {
        this.insert_header_private();
        var fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;
        return this.http.post(fullURL, params, { headers: this.headers_private });
    };
    OdataService.prototype.get_ip = function (resFunction) {
        this.http.get('https://api.ipify.org?format=json').toPromise()
            .then(resFunction).catch(this.communicate_error);
    };
    OdataService.prototype.paramsTokenAndLang = function (otherParams) {
        return tslib_1.__assign({ token: this.sessionService.getToken(), language: this.sessionService.language }, otherParams);
    };
    OdataService.prototype.clearRequests = function () {
        this.httpPrivateRequests.clear();
    };
    OdataService.prototype.parseResponse = function (val) {
        var returnedJSON = JSON.stringify(val);
        return JSON.parse(returnedJSON);
    };
    OdataService.prototype.getFile = function (url) {
        return this.http.get(url, {
            responseType: 'blob',
            headers: { Authorization: "Bearer " + this.sessionService.getToken() },
        }).pipe(map(function (v) { return URL.createObjectURL(v); }));
    };
    OdataService.ngInjectableDef = i0.defineInjectable({ factory: function OdataService_Factory() { return new OdataService(i0.inject(i1.HttpClient), i0.inject(i2.SessionService), i0.inject(i3.Router), i0.inject(i4.EnvService)); }, token: OdataService, providedIn: "root" });
    return OdataService;
}());
export { OdataService };
