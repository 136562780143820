import { Router } from '@angular/router';
import { EnvService } from '@/services/env.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@/services/page.service';
import { NavigationService, DisplayService, UsersService } from '@/services';

@Component({
    selector: 'app-co-step2',
    templateUrl: './co-step2.component.html',
    styleUrls: ['./co-step2.component.css']
})
export class CoStep2Component implements OnInit, OnDestroy {

    navigationService: NavigationService;

    constructor(private pageService: PageService, public env: EnvService, private router: Router,
                navigationService: NavigationService, private userService: UsersService) {
        this.navigationService = navigationService;

    }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('BeforeWorkDoctor.RegisterDCTitle', 0);
        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add('pp-portal');
        }
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
        sessionStorage.removeItem('newDcId');

        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');
    }

    goTo(page: string) {
        this.navigationService.setCurrentContext('DESCRIBING_CENTER');
        document.getElementById('sidebar').style.height = '650px';
        document.getElementById('dmSidebarWrapper').style.minHeight = '650px';
        DisplayService.createStickySidebar('sidebar', 650, 0, 0, null);
        let newCenter = this.userService.unverifiedDescCenters.find(x => x.idDescribingCenter == parseInt(sessionStorage.getItem('newDcId')));
        this.userService.descCenter = newCenter;
        sessionStorage.setItem('currentCenter', JSON.stringify(newCenter.idDescribingCenter));
        sessionStorage.setItem('centerChanged', 'true');
        sessionStorage.removeItem('currentFacility');
        if (page == 'pulpit') this.navigationService.navigateToDefault();
        else if (page == 'profiles') this.router.navigate(['/profiles']);
        else if (page == 'coProfile') this.router.navigate(['/coProfile']);
    }

}
