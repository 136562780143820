<div class="col-col-md-12" id="top-bar" style="z-index: 50;">
  <div class="row">
    <div class="col-md-12">
      <h3 class="page-name">{{'ArchiveScreen.Title' | translate}}</h3>

      <ul id="top-bar-nav">
        <li class="selectRangeFont">{{'ArchiveScreen.SelectDateRange' | translate}}</li>
        <li>
          <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" id="top-bar-wiecej">
            {{dateFilter}}
            <svg-icon alt="" class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg">
            </svg-icon>
          </a>
          <div aria-labelledby="top-bar-wiecej" class="dropdown-menu">
            <a id="today" class="dropdown-item"
               (click)="filterOrdersByTime('today');">{{'ArchiveScreen.Today' | translate}}</a>
            <a id="yesterday" class="dropdown-item"
               (click)="filterOrdersByTime('yesterday');">{{'ArchiveScreen.Yesterday' | translate}}</a>
            <a id="last7" class="dropdown-item"
               (click)="filterOrdersByTime('last7');">{{'ArchiveScreen.Last7' | translate}}</a>
            <a id="last30" class="dropdown-item"
               (click)="filterOrdersByTime('last30');">{{'ArchiveScreen.Last30' | translate}}</a>
            <a id="thisMonth" class="dropdown-item"
               (click)="filterOrdersByTime('thisMonth');">{{'ArchiveScreen.ThisMonth' | translate}}</a>
            <a id="lastMonth" class="dropdown-item"
               (click)="filterOrdersByTime('lastMonth');">{{'ArchiveScreen.LastMonth' | translate}}</a>
            <a id="all" class="dropdown-item"
               (click)="filterOrdersByTime('all');">{{'ArchiveScreen.All' | translate}}</a>
          </div>
        </li>
      </ul>

      <form>
        <ul class="top-bar-nav">
          <li (click)="dropdown()" class="filter_dropdown">
            <a>{{'DashboardScreen.Filters.FilterSort' | translate}}
              <svg-icon alt="" class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg">
              </svg-icon>
            </a>
          </li>
        </ul>
        <app-search #search placeholder="SearchPlaceholder"></app-search>
      </form>

    </div>
  </div>

</div>

<app-filters (filter)="filterOrders($event);" #filters type="ARCHIVE"></app-filters>

<app-loader #loader></app-loader>

<table class="tile-table">
  <tr class="tile" *ngFor="let or of ordersService.ordersArchive; let j = index;">

    <td class="color-status" [ngClass]="{'green':( or.status_info.status_name === 'TO_APPOVE_BY_TIME'
                                                           || or.status_info.status_name === 'APPROVED'),
                                             'red': or.status_info.status_name === 'REJECTED',
                                             'orange': or.status_info.status_name === 'SUSPENDED'}"></td>

    <td class="empty"></td>
    <td><span class="modality">{{or.exam_info.modality.modality || 'modality'}}</span></td>
    <td><span class="dot" *ngIf="or.status_info.status=='Nowe'"></span>{{or.status_info.status || 'status'}}</td>
    <td>
      <strong>{{ordersService.datesArchive[j] || 'date'}} </strong>
      <span class="light">({{ordersService.hoursArchive[j] || 'howlongago'}})</span><br />
      <ng-container *ngIf="or.exam_info.ordering_facility_name">{{or.exam_info.ordering_facility_name}}
      </ng-container>
      <ng-container *ngIf="!or.exam_info.ordering_facility_name"><span class="light">Zbadani.pl</span>
      </ng-container>
    </td>
    <td>
      <ng-container *ngIf="or.exam_info.ordering_facility_name">
        <strong>{{or.exam_info.patient_name || 'patientName'}}</strong><br />
        {{or.exam_info.pesel || 'pesel'}}, {{or.exam_info.birth_date || 'birthDate'}}
        <span class="light">
                        ({{ordersService.ageArchive[j] || 'age'}} {{ordersService.yearsDeclinatedString(ordersService.ageArchive[j])|translate}})</span>
      </ng-container>
      <ng-container *ngIf="!or.exam_info.ordering_facility_name">
        <strong>{{ ordersService.patientsInitials(or.exam_info.patient_name) }}</strong><br />
        •••••••••••, {{or.exam_info.birth_date || 'birthDate'}}
        <span class="light">
                    ({{ordersService.ageArchive[j] || 'age'}} {{ordersService.yearsDeclinatedString(ordersService.ageArchive[j])|translate}})</span>
      </ng-container>
    </td>
    <td>
      <strong>{{or.exam_info.anatomy.part_name || 'examType'}}</strong><br />
      <ng-container *ngIf="or.exam_info.icd10">
        <span>{{or.exam_info.pm_accession_number || 'accession_number'}}, {{or.exam_info.icd10}}</span>
      </ng-container>
      <ng-container *ngIf="!or.exam_info.icd10">
        <span>{{or.exam_info.pm_accession_number || 'accession_number'}}</span>
      </ng-container>
    </td>
    <td class="right text-right">
      <ng-container *ngIf="or.assign_physician_info.physician_assign">
        <strong>{{or.assign_physician_info.physician_assign}}</strong><br />
        <span class="light"
              *ngIf="userService.username==or.assign_physician_info.physician_assign">({{'DashboardScreen.You' | translate}})</span>
      </ng-container>

      <ng-container *ngIf="!or.assign_physician_info.physician_assign">
        <span class="light">({{'DashboardScreen.NotAssigned' | translate}})</span>
      </ng-container>
    </td>
    <td class="actions">
            <span *ngFor="let b of or.actions_info; let i = index;">
                <a *ngIf="i < 2" class="btn btn-light"
                   (click)="makeAction(b.action_name, j)">{{b.button_name}}</a>
            </span>
      <button *ngIf="or.actions_info.length > 2" type="button" class="btn btn-light card-action"
              id="ddAction1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1"
           *ngIf="or.actions_info.length > 2">
        <ng-container *ngFor="let b of or.actions_info; let i = index;">
          <a class="dropdown-item" *ngIf="i >= 2" (click)="makeAction(b.action_name, j)">{{b.button_name}}</a>
        </ng-container>
      </div>
    </td>
  </tr>
</table>

<app-pagination #pagination componentName="MedicalFacilityArchive" [hidden]="ordersService.ordersArchive.length == 0"></app-pagination>
