<ng-template #customItemTemplate let-index="index" let-model="item" let-query="query">
    <div class="tooltip-srac">
        <img [src]="model['profile_image']" class="image-srac">
        <div [caseSensitive]="false" [content]="model.username" [searchTerm]="query" class="d-item-srac" ngxTextHighlight>
            {{model.username}}
        </div>
        <span class="tooltiptext-srac">
            <strong>{{'SettingsScreens.UsersSettings.Login' | translate}}</strong>{{model.loginname}}
            <strong>{{'SettingsScreens.UsersSettings.PwzNr' | translate}}</strong>{{model.pwz}}
        </span>
    </div>
</ng-template>

<div id="top-bar">
    <div class="row">

        <div class="col-md-12">

            <h3 class="page-name">Ustawienia</h3>
            <ul id="top-bar-nav">
                <li class="active" id="SettingsScreensUsers">
                    <a routerLink="/usersSettings" style="cursor: pointer;">{{'SettingsScreens.Users' | translate}}</a>
                </li>
              <!-- Niema jeszcze metod do edycji tego
              <li id="SettingsScreensTeams">
                  <a routerLink="/teamSettings" style="cursor: pointer;">{{'SettingsScreens.Teams' | translate}}</a>
              </li>
              <li id="SettingsScreensPermissions">
                  <a routerLink="/permissionSettings" style="cursor: pointer;">{{'SettingsScreens.Permissions' | translate}}</a>
              </li>-->
                <li id="SettingsScreensRoles">
                    <a routerLink="/roleSettings" style="cursor: pointer;">{{'SettingsScreens.Roles' | translate}}</a>
                </li>
            </ul>

            <form>
                <app-search #search placeholder="SettingsScreens.SearchPlaceholder"></app-search>
            </form>
        </div>

    </div>
</div>

<div class="container-settings">

    <div class="row mt-4">
        <div class="col-md-6">
            <div class="card users-card" style="height: calc(100% - 25px);">
                <h3 style="font-weight: 400;">Zaproś do współpracy</h3>
                <div class="d-flex my-auto" style="align-items: stretch;">
                    <div style="width: 100%;" class="mr-3">

                        <form>
                            <div class="form-row">
                                <div class="col form-group" style="margin-bottom: 0; width: 100%;">
                                    <input class="typeahead-custom" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="asyncSelected" [typeaheadAsync]="true" [typeahead]="dataSource"
                                        (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                        [typeaheadOptionsLimit]="10" typeaheadOptionField="username"
                                        placeholder="{{'SettingsScreens.UsersSettings.SearchForPlaceholder' | translate}}" adaptivePosition="true"
                                        [typeaheadMinLength]="3" [typeaheadItemTemplate]="customItemTemplate" [typeaheadWaitMs]="100"
                                        (input)="onValueChange()"
                                        [ngClass]="{'form-control': true, 'type_text': true, 'invalid': !valid_search}">
                                    <!-- <img [src]="typeaheadIcon" class="select-async-img"> -->
                                    <div *ngIf="typeaheadLoading">{{'DashboardScreen.Loading' | translate}}</div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="ml-auto">
                        <button (click)="onCooperationInvite()"
                            [disabled]="search_value == undefined"
                            class="btn btn-lg btn-success float-right"
                            style="margin-right: 0; min-width: 210px;">{{'SettingsScreens.UsersSettings.CooperationInvite' | translate}}</button>
                    </div>

                </div>
            </div>
        </div>
        <div *ngFor="let item of settings_users" class="col-md-6">
            <div class="card users-card" style="padding: 25px;">
                <div class="row d-flex">
                    <div>
                        <img class="{{item.class}}" src="{{item.profile_image}}" onerror="this.src='assets/images/icons/user.svg'"
                            style="width: 120px !important; height: 120px !important; margin: 0 25px;" alt="">
                    </div>
                    <div class="my-auto">
                        <span [ngClass]="{'badge': true,
                            'badge-custom': true,
                            'badge-warning': item.status_name == 'WAITING',
                            'badge-success': item.status_name == 'ACTIVE',
                            'badge-danger': item.status_name == 'BLOCKED',
                            'badge-applies': item.status_name == 'APPLYING',
                            'badge-removed': item.status_name == 'DELETED'}">
                            {{item.status_text}}</span>
                        <p class="name">{{item.username}}</p>
                        <p>
                            <strong>{{'SettingsScreens.UsersSettings.Login' | translate}}</strong>{{item.loginname}}<br />
                            <span *ngIf="item.pwz != '' && item.pwz != null"><strong>{{'SettingsScreens.UsersSettings.PwzNr' | translate}}</strong>{{item.pwz}}</span>
                        </p>
                    </div>

                    <div class="col-md-5 d-flex ml-auto my-auto justify-content-end">
                        <button type="button" class="btn btn-light mr-2"
                                *ngIf="item.status_name == 'ACTIVE' || item.status_name == 'WAITING'"
                                (click)="onEditRole (item)">
                            {{'SettingsScreens.UsersSettings.EditRoleButton' | translate}}
                        </button>
                        <button type="button" class="btn btn-danger mr-2"
                                *ngIf="item.status_name == 'BLOCKED'"
                                (click)="onUnBlock (item)">
                            {{'SettingsScreens.UsersSettings.UnBlock' | translate}}
                        </button>
                        <button type="button" class="btn btn-danger mr-2"
                                *ngIf="item.status_name == 'APPLYING'"
                                (click)="onDelete (item)">
                            {{'SettingsScreens.Delete' | translate}}
                        </button>
                        <button type="button" class="btn btn-light"
                                *ngIf="item.status_name == 'DELETED'"
                                (click)="onInviteAgain (item)" style="height: 40px;">
                            {{'SettingsScreens.UsersSettings.InviteAgain' | translate}}
                        </button>
                        <ng-container *ngIf="item.status_name != 'DELETED'">
                            <button type="button" class="btn btn-light card-action" id="ddAction3"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
                                <a *ngIf="item.status_name == 'ACTIVE'"
                                    (click)="onBlock (item)"
                                    class="dropdown-item danger">{{'SettingsScreens.UsersSettings.Block' | translate}}</a>
                                <a *ngIf="item.status_name == 'ACTIVE' || item.status_name == 'BLOCKED'"
                                    (click)="onDelete (item)"
                                    class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
                                <a *ngIf="item.status_name == 'WAITING'"
                                    (click)="onCancelInvitation (item)"
                                    class="dropdown-item">{{'SettingsScreens.UsersSettings.CancelInvitation' | translate}}</a>
                                <a *ngIf="item.status_name == 'APPLYING'"
                                    (click)="onAcceptApplication (item)"
                                    class="dropdown-item">{{'SettingsScreens.UsersSettings.AcceptApplication' | translate}}</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-double-select-modal (select)="onSelect($event)" #editRole
    title="{{'SettingsScreens.UsersSettings.EditRole' | translate}}"
    left_title="{{'SettingsScreens.UsersSettings.AvailableRole' | translate}}"
    right_title="{{'SettingsScreens.UsersSettings.AssignedRole' | translate}}"></app-double-select-modal>

<app-invite-to-cooperation-modal #inviteToCooperationModal (invitationSent)="afterInvite($event)"></app-invite-to-cooperation-modal>
<app-universal-toast #actionToast></app-universal-toast>