import { Validators } from '@angular/forms';
import * as i0 from "@angular/core";
var ValidationService = /** @class */ (function () {
    function ValidationService() {
    }
    ValidationService.pwzValidator = function (control) {
        var pwz = control.value;
        if (pwz)
            return ValidationService.validatePWZ(pwz) ? null : { invalidReason: 'invalid combination' };
        else
            return { invalidReason: 'undefined' };
    };
    ValidationService.peselValidator = function (control) {
        var pesel = control.value;
        if (pesel)
            return ValidationService.validatePesel(pesel) ? null : { invalidReason: 'invalid combination' };
        else
            return { invalidReason: 'undefined' };
    };
    ValidationService.validateDate = function (date) {
        return ValidationService.dateRegex.test(date);
    };
    ValidationService.validateLogin = function (login) {
        return ValidationService.loginRegex.test(login);
    };
    ValidationService.validatePrice = function (price) {
        return ValidationService.priceRegex.test(price);
    };
    ValidationService.validateMail = function (mail) {
        return ValidationService.mailRegex.test(mail);
    };
    ValidationService.validatePass = function (pass) {
        return ValidationService.passRegex.test(pass);
    };
    ValidationService.validatePhone = function (number) {
        return ValidationService.numberRegex.test(number);
    };
    ValidationService.validateName = function (name) {
        if (name.length == 0)
            return false;
        return !ValidationService.nameRegex.test(name);
    };
    ValidationService.validatePesel = function (pesel) {
        if (pesel.length != 11)
            return false;
        var sum = Number(pesel[0]) * 9 + Number(pesel[1]) * 7 + Number(pesel[2]) * 3 + Number(pesel[3]) +
            Number(pesel[4]) * 9 + Number(pesel[5]) * 7 + Number(pesel[6]) * 3 + Number(pesel[7]) +
            Number(pesel[8]) * 9 + Number(pesel[9]) * 7;
        sum = sum % 10;
        return sum == Number(pesel[10]);
    };
    ValidationService.validatePWZ = function (pwz) {
        if (pwz.length != 7 || Number(pwz[0]) == 0)
            return false;
        var sum = Number(pwz[1]) + Number(pwz[2]) * 2 + Number(pwz[3]) * 3 + Number(pwz[4]) * 4
            + Number(pwz[5]) * 5 + Number(pwz[6]) * 6;
        sum = sum % 11;
        return sum == Number(pwz[0]);
    };
    ValidationService.validNumber = function (number, numberType) {
        var length = number.length;
        if (length != numberType.length) {
            return false;
        }
        else {
            var sum_1 = 0;
            number.split('').forEach(function (element, index) {
                return sum_1 += numberType.factors[index] * +element;
            });
            return numberType.resFun(sum_1, number[length - 1]);
        }
    };
    ValidationService.matchingPasswordsValidator = function (control) {
        var pass = control.get('pass');
        var retryPass = control.get('retryPass');
        return pass && retryPass && pass.value != retryPass.value ? { 'passwordsMatch': true } : null;
    };
    ValidationService.nameValidator = function (control) {
        var error = ValidationService.nameRegex.test(control.value);
        return error ? { 'imie': true } : null;
    };
    ValidationService.streetValidator = function (control) {
        var error = ValidationService.streetRegex.test(control.value);
        return error ? { 'ulica': true } : null;
    };
    ValidationService.prototype.peselValidator = function (control) {
        var error = !ValidationService.validNumber(control.value, ValidationService.checksums.pesel);
        return error ? { 'pesel': true } : null;
    };
    ValidationService.prototype.regonValidator = function (control) {
        var error = !ValidationService.validNumber(control.value, ValidationService.checksums.regon);
        return error ? { 'regon': true } : null;
    };
    ValidationService.prototype.nipValidator = function (control) {
        var error = !ValidationService.validNumber(control.value, ValidationService.checksums.nip);
        return error ? { 'nip': true } : null;
    };
    ValidationService.streetRegex = RegExp(/[,.!@#$%^&*\[\]\{\}\\\|\(\)_?=+`~:;"<>]/);
    ValidationService.mailRegex = RegExp(/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
    ValidationService.passRegex = RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
    ValidationService.numberRegex = RegExp(/^(\+48)?[0-9]{9,}$/);
    ValidationService.numericRegex = RegExp(/^[0-9]*$/);
    ValidationService.nameRegex = RegExp(/[0-9!@#$%^&*\[\]\{\}\\\|\(\)_?=+`~:;"<>.,]/);
    ValidationService.dateRegex = RegExp(/^[012]{1}[0-9]{1}[/.-]{1}[01]{1}[0-9]{1}[/.-]{1}[0-9]{2,4}$/);
    ValidationService.loginRegex = RegExp(/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>\-_]{9,}$/);
    ValidationService.priceRegex = RegExp(/^[1-9][0-9]*$/);
    ValidationService.numberValidator = Validators.pattern(ValidationService.numericRegex);
    ValidationService.mailValidator = Validators.pattern(ValidationService.mailRegex);
    ValidationService.priceValidator = Validators.pattern(ValidationService.priceRegex);
    ValidationService.passwordValidator = Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
    ValidationService.dateValidator = Validators.pattern(ValidationService.dateRegex);
    ValidationService.phoneNumberValidator = Validators.pattern(ValidationService.numberRegex);
    ValidationService.workingHoursValidator = Validators.pattern(/^([0-1]?[0-9]|2[0-4]):([0-5]?[0-9])(:[0-5][0-9])?$/);
    ValidationService.zipCodeValidator = Validators.pattern(/^[0-9]{2}[-]{1}[0-9]{3}$/);
    ValidationService.localValidator = Validators.pattern(/^[0-9]{1,4}$/);
    ValidationService.loginValidator = Validators.pattern(ValidationService.loginRegex);
    ValidationService.birthdateValidator = Validators.pattern(/^[0-9]{4}[-]{1}[01]{1}[0-9]{1}[-]{1}[0123]{1}[0-9]{1}$/);
    ValidationService.timestampValidator = Validators.pattern(/^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-][01]\d:[0-5]\d)$/);
    ValidationService.checksums = {
        pesel: {
            length: 11,
            factors: [9, 7, 3, 1, 9, 7, 3, 1, 9, 7, 0],
            resFun: function (sum, lastDigit) { return sum % 10 == lastDigit; }
        },
        nip: {
            length: 10,
            factors: [6, 5, 7, 2, 3, 4, 5, 6, 7, 0],
            resFun: function (sum, lastDigit) { return sum % 11 == lastDigit && sum % 11 != 10; }
        },
        regon: {
            length: 9,
            factors: [8, 9, 2, 3, 4, 5, 6, 7, 0],
            resFun: function (sum, lastDigit) { return sum % 11 % 10 == lastDigit; }
        }
    };
    ValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
