import { EventEmitter } from '@angular/core';
import { OdataService } from './odata.service';
import { UsersService } from './users.service';
import { dummyOrderDescription, examInfoToOrderDescription } from './../interfaces/orderDescription';
import { DraftSenderService } from './draft-sender.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "./users.service";
import * as i3 from "@angular/router";
import * as i4 from "./draft-sender.service";
import * as i5 from "./session.service";
var OrderDescriptionService = /** @class */ (function () {
    function OrderDescriptionService(odataService, usersService, router, draftSender, sessionService) {
        var _this = this;
        this.odataService = odataService;
        this.usersService = usersService;
        this.router = router;
        this.draftSender = draftSender;
        this.sessionService = sessionService;
        this.ordersCount = 0;
        this.orderDescription = dummyOrderDescription;
        this.descriptionText = '';
        this.descriptionEmitter = new EventEmitter();
        this.draftSavedEmitter = new EventEmitter();
        this.isViewDraft = false;
        this.isRejected = false;
        this.mode = {
            mode: '',
            code: 0
        };
        this.text = '';
        this.version = 1;
        this.isCheckedAttention = false;
        draftSender.draftSentEmitter.subscribe(function (e) { _this.draftSavedEmitter.emit(e); });
    }
    OrderDescriptionService.prototype.getExamInfo = function () {
        var _this = this;
        this.descriptionPromise = this.odataService.postPrivate('get_exam_order_exam_info', this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            id_describing_center: this.usersService.getCenterId()
        }), function (res) {
            // todo: Ujednolicenie nazewnictwa w funkcjach i interfejsach -> keysToCamel itp.
            var stringJSON = JSON.stringify(res);
            var resForAttachments = JSON.parse(stringJSON);
            var resultForAttachments = JSON.parse(resForAttachments.Value); // passing attachments via different parsing due to interface differences (Camel and _)
            var result = _this.odataService.keysToCamel(JSON.parse(res.Value));
            _this.patientId = result.examInfo.idPatient;
            var info = examInfoToOrderDescription(result, resultForAttachments.attachment_list, resultForAttachments.exam_info.consultation);
            _this.orderDescription = info;
            _this.orderDescription.patient.age = _this.calculateAgeFromDates(new Date(_this.orderDescription.patient.birthdate), new Date());
            return info;
        });
        return this.descriptionPromise;
    };
    OrderDescriptionService.prototype.getExamActions = function () {
        var _this = this;
        this.actionsPromise = this.odataService.postPrivate('get_exam_order_action_list', this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            id_describing_center: this.usersService.getCenterId(),
            mode: this.mode.code
        }), function (res) {
            var cammelRes = _this.odataService.keysToCamel(JSON.parse(res.Value || '{}'));
            _this.actionButtons = cammelRes.actionsInfo || [];
            _this.sortOrderActions(_this.actionButtons);
            return _this.actionButtons;
        });
        return this.actionsPromise;
    };
    OrderDescriptionService.prototype.getExamLock = function () {
        return this.odataService.postPrivate('get_exam_descrption_lock', this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId
        }), function (res) {
            return res.ActionStatus;
        });
    };
    OrderDescriptionService.prototype.takeOverExamLock = function (password) {
        return this.odataService.postPrivate('take_over_exam_descrption_lock', this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            user_password: password
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                return res.ActionStatus;
            }
        });
    };
    OrderDescriptionService.prototype.saveExamDraft = function (text) {
        var params = {
            token: this.sessionService.getToken(),
            language: this.usersService.iLanguage,
            id_exam_order: this.examId,
            description: text,
            attention_required: this.isCheckedAttention,
            version: this.version
        };
        this.draftSender.saveExamDraft(params);
    };
    OrderDescriptionService.prototype.getExamDraft = function () {
        var _this = this;
        this.doctorsDescriptionPromise = this.odataService.postPrivate('get_exam_description_draft', this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            version: null
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                if (result.Value.length > 0) {
                    result = JSON.parse(result.Value);
                    _this.version = result[0].description_version + 1;
                    if (result[0].attention_required != null) {
                        _this.isCheckedAttention = result[0].attention_required;
                    }
                    else
                        _this.isCheckedAttention = false;
                    _this.descriptionText = result[0].description;
                    return result[0].description;
                }
                else {
                    _this.descriptionText = '';
                    return '';
                }
            }
            else {
                return '';
            }
        });
        return this.doctorsDescriptionPromise;
    };
    OrderDescriptionService.prototype.getExamDescriptionOrDraft = function () {
        this.isViewDraft = false;
        switch (this.mode.mode) {
            case 'description': {
                if (this.orderDescription.status.codename == 'IN_PROGRESS')
                    return this.getExamDraft();
                else
                    return this.getExamDescription();
            }
            case 'view': {
                if (this.orderDescription.status.codename == 'IN_PROGRESS') {
                    this.isViewDraft = true;
                    return this.getExamDraft();
                }
                else {
                    return this.getExamDescription();
                }
            }
            default: {
                return this.getExamDescription();
            }
        }
    };
    OrderDescriptionService.prototype.getExamDescription = function () {
        var _this = this;
        this.doctorsDescriptionPromise = this.odataService.postPrivate('get_exam_description_json', this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId
        }), function (res) {
            var result = JSON.parse(res.Value);
            _this.isCheckedAttention = result.attention_required;
            if (result.exam_description != null) {
                _this.descriptionText = result.exam_description;
                return result.exam_description;
            }
            else {
                _this.descriptionText = '';
                return '';
            }
        });
        return this.doctorsDescriptionPromise;
    };
    OrderDescriptionService.prototype.getDescriptionTemplates = function (modalities, anatomyParts, last_used, limit, offset) {
        this.templatePromise = this.odataService.postPrivate('get_description_template', this.odataService.paramsTokenAndLang({
            id_describing_center: this.usersService.getCenterId(),
            template_name: '',
            modality_list: modalities ? modalities : [],
            anatomy_list: anatomyParts ? anatomyParts : [],
            offset: offset ? offset : 0,
            limit: limit ? limit : 10,
            last_used: last_used ? last_used : false
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            var templates = result.Value ? JSON.parse(result.Value) : [];
            return templates;
        });
        return this.templatePromise;
    };
    OrderDescriptionService.prototype.saveDescriptionTemplate = function (name, content, modality_list, anatomy_list, id) {
        return this.odataService.postPrivate('save_description_template', this.odataService.paramsTokenAndLang({
            id_template: id,
            template_name: name,
            content: content,
            modality_list: modality_list,
            anatomy_list: anatomy_list,
            id_describing_center: this.usersService.getCenterId()
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status != 'OK')
                console.log('error');
            return result;
        });
    };
    OrderDescriptionService.prototype.deleteDescriptionTemplate = function (id) {
        return this.odataService.postPrivate('delete_description_template', this.odataService.paramsTokenAndLang({
            id_template: id
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status != 'OK')
                console.log('error');
            return result;
        });
    };
    OrderDescriptionService.prototype.descriptionTemplateUsed = function (id) {
        this.odataService.postPrivate('description_template_used', this.odataService.paramsTokenAndLang({
            id_template: id
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status != 'OK')
                console.log('error');
        });
    };
    OrderDescriptionService.prototype.submitExamDescription = function (desc) {
        var params = this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            id_describing_center: this.usersService.getCenterId(),
            description: desc,
            attention_required: this.isCheckedAttention
        });
        return this.odataService.postPrivate('submit_exam_description', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            return result.Status == 'OK';
        });
    };
    OrderDescriptionService.prototype.submitExamDescriptionInTime = function (desc) {
        var params = this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            id_describing_center: this.usersService.getCenterId(),
            description: desc,
            attention_required: this.isCheckedAttention
        });
        return this.odataService.postPrivate('submit_exam_description_in_time', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            return result.Status == 'OK';
        });
    };
    OrderDescriptionService.prototype.sortOrderActions = function (buttons) {
        buttons.sort(function (a1, a2) {
            if (a1.actionImportance > a2.actionImportance)
                return -1;
            else if (a1.actionImportance == a2.actionImportance)
                return 0;
            else
                return 1;
        });
    };
    OrderDescriptionService.prototype.reloadData = function () {
        this.getExamInfo();
        this.getExamActions();
        if (this.mode.mode == 'description') {
            this.getExamDraft();
        }
        else if (this.mode.mode == 'view') {
            this.getExamDescriptionOrDraft();
        }
    };
    OrderDescriptionService.prototype.reloadExamDescription = function () {
        this.mode = {
            mode: 'description',
            code: 1
        };
        this.router.navigate(['/orderDescription/' + this.examId + '/currentDescription'], { queryParams: { mode: 'description' } });
    };
    OrderDescriptionService.prototype.reloadExamView = function () {
        this.mode = {
            mode: 'view',
            code: 0
        };
        this.router.navigate(['/orderDescription/' + this.examId + '/currentDescription'], { queryParams: { mode: 'view' } });
    };
    OrderDescriptionService.prototype.calculateAgeFromDates = function (bdate, today) {
        var _a = [bdate.getDay(), bdate.getMonth(), bdate.getFullYear()], bday = _a[0], bmon = _a[1], byear = _a[2];
        var _b = [today.getDay(), today.getMonth(), today.getFullYear()], tday = _b[0], tmon = _b[1], tyear = _b[2];
        if (tyear < byear)
            return null;
        var age = tyear - byear - 1;
        if (tmon > bmon)
            age++;
        else if (tmon === bmon) {
            if (tday >= bday)
                age++;
        }
        if (age < 0)
            return null;
        return age;
    };
    OrderDescriptionService.prototype.getOrderDataForModal = function () {
        return {
            id_exam_order: this.examId,
            order_date: this.orderDescription.statusDate,
            exam_info: {
                patient_name: this.orderDescription.patient.name,
                ordering_facility_name: this.orderDescription.client.facilityName,
                anatomy: {
                    part_name: this.orderDescription.info.anatomyPart.name
                }
            },
            assign_physician_info: {
                id_physician_assign: this.orderDescription.physicianAssigned.id
            }
        };
    };
    OrderDescriptionService.prototype.isOrderComparisonViewPermission = function () {
        return ((this.orderDescription.status.codename === 'ASSIGNED' || this.orderDescription.status.codename === 'IN_PROGRESS')
            && this.usersService.userId == this.orderDescription.physicianAssigned.id);
    };
    OrderDescriptionService.ngInjectableDef = i0.defineInjectable({ factory: function OrderDescriptionService_Factory() { return new OrderDescriptionService(i0.inject(i1.OdataService), i0.inject(i2.UsersService), i0.inject(i3.Router), i0.inject(i4.DraftSenderService), i0.inject(i5.SessionService)); }, token: OrderDescriptionService, providedIn: "root" });
    return OrderDescriptionService;
}());
export { OrderDescriptionService };
