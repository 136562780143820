import { MessageSenderService } from './../../../services/message-sender.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { NgForm } from '@angular/forms';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { IConversation, IMessageSender } from '@/dm/messages/imessage';
import { SelectRecipientAsyncComponent } from '@/dm/messages/select-recipient-async/select-recipient-async.component';
import { UsersService, OdataService, SessionService, MessagesService } from '@/services';

import { EnvService } from '@/services/env.service';
import { PageService } from '@/services/page.service';
import { Router } from '@angular/router';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('messagesViewport') viewport: CdkVirtualScrollViewport;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    // batch = 20;
    theEnd = false;

    scrollToBottom: boolean = false;
    active_sender_id: number;
    is_current_interlocutor_physician = false;
    active_last_message_date: Date;
    currentInterlocutor: string = '';
    is_new_mes_btn = false;

    senders: IMessageSender [];
    private sub_action: any;

    @ViewChild('id_user') id_user: SelectRecipientAsyncComponent;

    message_id: number;

    messages_count_all: number = 0;
    messages_list: IConversation [] = [];

    constructor(public userService: UsersService, public odataService: OdataService,
        public messagesService: MessagesService, public messageSender: MessageSenderService,
        private pageService: PageService, public datepipe: DatePipe, public envService: EnvService, private router: Router) { }

    ngOnInit() {
        this.senders = [];

        this.sub_action = this.messagesService.messagesMainEvent.subscribe(
            (value: { id_message?: number, id_user?: number, action: string }) => {
                this.processAction(value);
            });
        this.messagesService.setRefreshTime(15000);
        this.messagesService.realoadList();
        // this.showMessage(this.messagesService.getMessageToShow(), true);
        this.pageService.setTranslatedPageTitle('Messages.Messages', 0);
    }

    ngOnDestroy() {
        this.sub_action.unsubscribe();
        this.pageService.resetPageTitle();
        this.messagesService.setRefreshTime(60000);
    }

    ngAfterViewChecked() {
        if (this.scrollToBottom) {
            this.scrollToBottom = false;
            this.viewport.scrollTo({ bottom: 0, behavior: 'auto' });
        }
    }

    nextBatch(e, data) {
        // console.log('nextBatch', e);
    }

    nextBatchConversation(e, data) {
        // console.log('nextBatchConversation', e);
    }

    trackByFn(index: number, item: any) {
        // console.log('trackByFn', index, item.id_user);
        return item ? item.id_user : null;
    }

    trackByConversationFn(index: number, item: any) {
        // console.log('trackByConversationFn', index, item, item.id_message);
        return item ? item.id_message : null;
    }

    getMessageList() {
        this.odataService.postPrivate('user_get_messages_partner_list', // get_messages_for_user
            this.odataService.paramsTokenAndLang({
                offset: 0,
                limit: 1000
            }),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);
                const dateFormat = 'dd/MM/yyyy HH:mm';
                const dateShort = 'dd/MM HH:mm';

                if (result.Status === 'OK') {
                    let realod_messages = false;
                    let senders: IMessageSender[] = [];

                    if (result.Value && result.Value !== '') {
                        const value = JSON.parse(result.Value);
                        senders = value;
                    }

                    for (let i = 0; i < senders.length; i++) {
                        if (!('image_uuid' in senders[i]) ||
                            !senders[i].image_uuid ||
                            senders[i].image_uuid === '') {
                            senders[i].image_user_from_uuid = '../../assets/images/icons/user.svg';
                        } else {
                            senders[i].image_user_from_uuid = this.envService.miniProfilePicURL + senders[i].image_uuid;
                        }

                        if (senders[i].last_message_time) {
                            let d = new Date(Date.parse(senders[i].last_message_time));
                            let today = moment(new Date()).hours(23).minutes(59);
                            let daysDiff = moment(d).hours(0).minutes(0).diff(today, 'days');

                            senders[i].last_message_date = d;

                            if (this.active_sender_id && this.active_sender_id == senders[i].id_user && this.active_last_message_date < d) {
                                realod_messages = true;
                            }

                            if (daysDiff === 0)
                                senders[i].last_message_time = this.datepipe.transform(d, 'HH:mm');
                            else if (daysDiff === -1)
                                senders[i].last_message_time = this.datepipe.transform(d, dateShort); // Miejsce na wczoraj
                            else {
                                let yerDiff = moment(d).hours(0).minutes(0).diff(today, 'years');

                                if (yerDiff === 0)
                                    senders[i].last_message_time = this.datepipe.transform(d, dateShort);
                                else
                                    senders[i].last_message_time = this.datepipe.transform(d, dateFormat);
                            }
                        }
                    }

                    this.senders = senders; // senders.reverse (); // [...xxx]

                    if (realod_messages) {
                        this.getMessages(this.active_sender_id, 0, 25, false, true);
                    }

                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    getMessages(id_partner: number, offset: number, limit: number, append_mode: boolean = true, go_to_bottom: boolean = false) {
        if (!id_partner)
            return;

        if (!append_mode) {
            this.messages_count_all = 0;
        }

        this.odataService.postPrivate('get_partner_messges', this.odataService.paramsTokenAndLang({
                id_user_to: id_partner,
                offset: offset,
                limit: limit,
            }),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let messages = JSON.parse(result.Value);
                    let dateFormat = 'dd/MM/yyyy HH:mm';
                    let dateShort = 'dd/MM HH:mm';

                    for (let i = 0; i < messages.messages_list.length; i++) {

                        if (messages.messages_list[i].creation_time) {
                            let d = new Date(Date.parse(messages.messages_list[i].creation_time));
                            let today = moment(new Date()).hours(23).minutes(59);
                            let daysDiff = moment(d).hours(0).minutes(0).diff(today, 'days');

                            if (daysDiff === 0)
                                messages.messages_list[i].creation_time = this.datepipe.transform(d, 'HH:mm');
                            else if (daysDiff === -1)
                                messages.messages_list[i].creation_time = this.datepipe.transform(d, dateShort); // Miejsce na wczoraj
                            else {
                                let yerDiff = moment(d).hours(0).minutes(0).diff(today, 'years');

                                if (yerDiff === 0)
                                    messages.messages_list[i].creation_time = this.datepipe.transform(d, dateShort);
                                else
                                    messages.messages_list[i].creation_time = this.datepipe.transform(d, dateFormat);
                            }
                        }
                    }

                    if (append_mode) {
                        this.messages_count_all = messages.messages_count_all;
                        this.messages_list.push(messages.messages_list.reverse());
                    } else {
                        this.messages_count_all = messages.messages_count_all;
                        this.messages_list = messages.messages_list.reverse();
                    }

                    if (go_to_bottom) {
                        this.scrollToBottom = true;
                    }
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }
    clearMain (form?: NgForm) {

        const element = (<HTMLInputElement>document.getElementById('message_text_main'));
        console.log(element);
        if (form) {
          form.controls.message_text_main.setValue('');
        }
        if (element) {
            element.value = '';
            console.log(element.value);
            this.autosize.reset();
        }
        this.messageSender.c_attachments = [];

    }

    clearModal() {
        this.messageSender.setDefaultData();
        this.message_id = undefined;

        if (this.id_user) {
            this.id_user.clear();
        }

        const element = (<HTMLInputElement>document.getElementById('message_text'));
        if (element) {
            element.value = '';
        }
    }

    // onRemove(id_message_value: number) {
    //     this.odataService.postPrivate('message_delete', {
    //         token: this.sessionService.getToken(),
    //         language: this.userService.iLanguage,
    //         id_message: id_message_value
    //     },
    //         res => {
    //             const returnedJson = JSON.stringify(res);
    //             const result = JSON.parse(returnedJson);
    //             if (result.value === 'OK') {
    //                 for (let i = 0; i < this.messages.length; i++) {
    //                     if (this.messages[i].id_message === id_message_value) {
    //                         if (!this.messages[i].message_read && this.messagesCount > 0) {
    //                             this.messagesService.updateCount(this.messagesCount - 1);
    //                         }

    //                         this.messages.splice(i, 1);
    //                         this.messagesService.mainRemoved(id_message_value);
    //                         this.messagesService.realoadList();
    //                         return;
    //                     }
    //                 }
    //             } else {
    //                 // TODO:
    //                 console.log('REASON:', result.value);
    //             }
    //         });
    // }

    messagesAllRead(id_partner: number) {
        this.messagesService.updateMessagesCount();
        this.odataService.postPrivate('set_partner_messages_all_read',
            this.odataService.paramsTokenAndLang({id_partner: id_partner}),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    for (let i = 0; i < this.senders.length; i++) {
                        if (this.senders[i].id_user === id_partner) {
                            if (this.senders[i].message_unread !== 0) {
                                this.senders[i].message_unread = 0;
                            }

                            this.messagesService.mainReadState(id_partner);
                            return;
                        }
                    }
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            }
        );
        this.messagesService.updateMessagesCount();
    }

    onMessageSendClick(form: NgForm) {
        this.messageSender.onSubmit(this.active_sender_id, form.value.message_text_main, this.messageSender.c_attachments).then((res) => {
            if (res) {
                this.clearMain (form);
                this.messagesAllRead (this.active_sender_id);
                this.messagesService.realoadList();
            } else {
                // TODO:
                console.log('FAILED');
            }
        });
    }

    onSubmit(form: NgForm) {
        this.messageSender.onSubmit(form.value.id_user, (<HTMLInputElement>document.getElementById('message_text')).value).then((res) => {
            if (res) {
                $('#wiadomosciSzczegolyModal').modal('hide');
                this.clearModal();
                this.messagesService.realoadList();
            } else {
                // TODO:
                console.log('FAILED');
            }
        });
        this.viewport.scrollTo({ bottom: 0, behavior: 'auto' });
    }

    onClose() {
        $('#wiadomosciSzczegolyModal').modal('hide');
        this.clearModal();
    }

    onNewMessage() {
        this.clearModal();
        $('#wiadomosciSzczegolyModal').modal('show');
    }

    onClearAll() {
        this.odataService.postPrivate('delete_all_messages',
            this.odataService.paramsTokenAndLang({}),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    this.messagesService.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            });
    }

    onMarkAllAsRead() {
        this.odataService.postPrivate('set_all_message_read',
            this.odataService.paramsTokenAndLang({}),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);
                if (result.value === 'OK') {
                    this.messagesService.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            }
        );
    }

    processAction(value: { id_message?: number, id_user?: number, action: string }) {
        if (value.action === 'reload') {
            this.getMessageList();
            return;
        }

        if (value.action === 'read' && 'id_user' in value) {
            for (let i = 0; i < this.senders.length; i++) {
                if (this.senders[i].id_user == value.id_user) {
                    if (this.senders[i].message_unread > 0) {
                        this.senders[i].message_unread--;
                    }

                    break;
                }
            }

            if (this.messagesService.messagesCount > 0) {
                this.messagesService.updateCount(this.messagesService.messagesCount - 1);
            }

            return;
        }

        // for (let i = 0; i < this.messages.length; i++) {
        //     if (this.messages[i].id_message === value.id_message) {
        //         switch (value.action) {
        //             case 'remove':
        //                 this.messages.splice(i, 1);
        //                 break;
        //             case 'read':
        //                 this.messages[i].message_read = true;
        //                 break;
        //             case 'unread':
        //                 this.messages[i].message_read = false;
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // }
    }

    fileInputClick (event: any) {
        // Open file dialog
        if (this.messageSender.c_attachments.length < 3) {
            return true;
        } else {
            // Do not open file dialog
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }

    scroll(id: number): void {
        // DisplayService.scroll('section-' + id);
    }

    // showMessage(id_message: number, outside_call: boolean) {
    //     if (id_message === undefined)
    //         return;

    //     this.message_id = id_message;
    //     this.validUser = true;
    //     this.validText = true;
    //     this.validAttachments = true;

    //     if (outside_call) {
    //         if (this.activePage != 1) {
    //             this.activePage = 1;
    //             this.inputPage = 1;
    //             this.requestPage = 1;
    //             this.messagesService.realoadList();
    //         }
    //         this.scroll(this.message_id);
    //     }

    //     this.getMessage(this.message_id);
    //     $('#wiadomosciSzczegolyModal').modal('show');

    //     for (let i = 0; i < this.messages.length; i++) {
    //         if (this.messages[i].id_message === this.message_id) {
    //             if (!this.messages[i].message_read && this.messagesCount > 0) {
    //                 this.messages[i].message_read = true;
    //                 this.messagesService.updateCount(this.messagesCount - 1);
    //                 this.messagesService.mainReadState(this.message_id, true);
    //             }
    //         }
    //     }
    // }

    onClickSenders(sender: IMessageSender) {

        this.clearMain ();
        if (this.active_sender_id) {
          this.messagesAllRead(this.active_sender_id);
        }
        this.active_sender_id = sender.id_user;
        this.currentInterlocutor = sender.user_name;
        this.active_last_message_date = sender.last_message_date;
        this.getMessages (this.active_sender_id, 0, 25, false, true);
        this.messagesAllRead (this.active_sender_id);
        this.messagesService.realoadList();
        this.is_current_interlocutor_physician = sender.is_physician;
    }

  sortSendersBy(prop: string) {
    return this.senders.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1).reverse();
  }

  viewProfile(id: number) {
    this.router.navigate(['/doctorsProfile/' + id]);
  }
}
