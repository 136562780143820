import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { Subscription } from 'rxjs';
import { OrderActionsService, OrderDescriptionService, OrdersService, SessionService, UsersService } from '@/services';
import { IAction, IFiltrDashboard, IOrder } from '@/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '@/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { dateFilterTypes } from '@/dm/describing-center/archive/enum';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { SearchComponent } from '@/utils/search/search.component';
import { initConsultation } from '@/interfaces/AIConsultation';

@Component({
    selector: 'app-medical-facility-archive',
    templateUrl: './medical-facility-archive.component.html',
    styleUrls: ['./medical-facility-archive.component.scss']
})
export class MedicalFacilityArchiveComponent implements OnInit, OnDestroy {
    @ViewChild('filters') filters: FiltersComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('loader') loader: LoaderComponent;
    @ViewChild('pagination') pagination: PaginationComponent;

    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    searchSubscription: Subscription;
    pagesSubscription: Subscription;
    dateFilter: string = '';
    currentDateFilterType: string = '';
    ordersService: OrdersService;

    actionsList = {
        'VIEW_EXAM_ORDER': (examId: number): Promise<boolean> => {
            return this.orderActions.viewExamOrder(examId);
        }
    };

    subscription: Subscription;
    filtrDashboard: IFiltrDashboard;

    constructor(private userService: UsersService, ordersService: OrdersService, private orderActions: OrderActionsService,
        private router: Router, private route: ActivatedRoute, private pageService: PageService,
        private translate: TranslateService, private sessionService: SessionService,
        private descriptionService: OrderDescriptionService) {
        this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
        this.ordersService = ordersService;
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('filter') == null) {
                this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
            } else {
                this.filtrDashboard = JSON.parse(decodeURI(params.get('filter')));
            }
            this.filters.filtrDashboard = this.filtrDashboard;
        });
        this.checkDateFilterType();
        this.checkCurrentPage();
        this.searchEvent();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();
        this.setNumberOfPages();

        this.ordersService.ordersArchive = [
            {
                actions_info: [
                    {
                        id_action: 3005,
                        action_name: 'VIEW_EXAM_ORDER',
                        button_name: 'Podgląd',
                        action_importance: 5
                    }
                ],
                assign_physician_info: { physician_assign: 'Piotr Filipczuk' },
                exam_info: {
                  consultation: initConsultation,
                    anatomy: {
                        id_part: 3,
                        part_name: 'Klatka piersiowa',
                      anatomy_part_text: 'tekst'
                    },
                    birth_date: '2018-01-30',
                    evo_accession_number: '000A000000000087',
                    exam_date: null,
                    exam_order_notes: 'Zlecenie automatyczne z Placówka Medyczna 1',
                    his_exam_id: '1/123476',
                    his_patient_id: '1/123476',
                    icd10: '',
                    modality: {
                        id_group: 1,
                        modality: 'RTG'
                    },
                    order_exam_type: 'KLP',
                    ordering_facility_name: 'Placówka Medyczna 1',
                    describing_facility_name: 'Opis1',
                    patient_name: 'KACPER KUCHARSKI',
                    pesel: '18213001239',
                    priority: {
                      priority_text: '', priority_value: 0,
                        priority: 'EMERGENCY',
                        id_priority: 3
                    },
                    transfer_progress: 100,
                    pm_accession_number: '025-544'
                },
                id_exam_order: 8,
                exam_reject_reason: '',
                order_date: '2019-06-13T16:37:18.018+02:00',
                status_info: {
                    status: 'Zatwierdzone',
                    id_status: 9,
                    status_name: 'APPROVED'
                },
                reject_info: {
                    reject_time: '',
                    reject_reason: ''
                  }
            },
            {
                actions_info: [
                    {
                        id_action: 3005,
                        action_name: 'VIEW_EXAM_ORDER',
                        button_name: 'Podgląd',
                        action_importance: 5
                    }
                ],
                assign_physician_info: {
                    physician_assign: 'Piotr Filipczuk'
                },
                exam_info: {
                  consultation: initConsultation,
                    anatomy: {
                      anatomy_part_text: 'tekst',
                        id_part: 5,
                        part_name: 'Jama brzuszna'
                    },
                    birth_date: '1981-04-27',
                    evo_accession_number: '000A000000000059',
                    exam_date: null,
                    exam_order_notes: 'Zlecenie automatyczne z Placówka Medyczna 1',
                    his_exam_id: '1/16502',
                    his_patient_id: '1/16502',
                    icd10: '',
                    modality: {
                        id_group: 1,
                        modality: 'RTG'
                    },
                    order_exam_type: 'JAMA BRZUSZNA',
                    ordering_facility_name: 'Placówka Medyczna 1',
                    describing_facility_name: 'oPIS 1',
                    patient_name: 'MARZENA GIEŁDA',
                    pesel: '81042703683',
                    priority: {
                        priority: 'EMERGENCY',
                        id_priority: 3, priority_text: '', priority_value: 0
                    },
                    transfer_progress: 100,
                    pm_accession_number: '111-000'
                },
                id_exam_order: 10,
                exam_reject_reason: '',
                order_date: '2019-06-13T16:37:18.018+02:00',
                status_info: {
                    status: 'Odrzucone',
                    id_status: 3,
                    status_name: 'REJECTED'
                },
                reject_info: {
                    reject_time: '',
                    reject_reason: ''
                  }
            }
        ];

        this.ordersService.numberOfArchiveOrders.emit(2);
        this.ordersService.ageArchive = [];
        this.ordersService.hoursArchive = [];
        this.ordersService.datesArchive = [];

        for (let i = 0; i < this.ordersService.ordersArchive.length; i++) {
            moment.locale(this.userService.iLanguage);
            this.ordersService.ageArchive.push(this.descriptionService.calculateAgeFromDates(new Date(this.ordersService.ordersArchive[i].exam_info.birth_date), new Date()));
            let date = this.ordersService.getDateString(this.ordersService.ordersArchive[i].order_date);
            let hour = moment(this.ordersService.ordersArchive[i].order_date).fromNow();
            this.ordersService.hoursArchive.push(hour);
            this.ordersService.datesArchive.push(date);
            this.ordersService.ordersArchive[i].actions_info.sort((a1: IAction, a2: IAction) => {
                if (a1.action_importance > a2.action_importance)
                    return -1;
                else if (a1.action_importance == a2.action_importance)
                    return 0;
                else
                    return 1;
            });
        }

        let min = moment();
        for (let i = 0; i < this.ordersService.ordersArchive.length; i++) {
            if (min > moment(this.ordersService.ordersArchive[i].order_date)) {
                min = moment(this.ordersService.ordersArchive[i].order_date);
            }
        }
        this.filters.daterange.start = min;
        this.filters.plOptions.startDate = min;
        this.filters.enOptions.startDate = min;

        setTimeout(() => this.loader.show = false, 1000);

        this.subscription = this.sessionService.emitter.subscribe((event) => {
            this.setDateFilter();
        });

        this.pageService.setTranslatedPageTitle('ArchiveScreen.Title', 0);
        document.getElementById('facilityArchiveLink').setAttribute('class', 'active');
    }

    ngOnDestroy(): void {
        document.getElementById('facilityArchiveLink').setAttribute('class', '');
        this.searchSubscription.unsubscribe();
        this.pagesSubscription.unsubscribe();
        this.pageChangeSubscription.unsubscribe();
        this.itemsOnPageChangeSubscription.unsubscribe();
        this.subscription.unsubscribe();
        this.pageService.resetPageTitle();
    }

    getArchiveOrders() {
        this.pagination.activePage = 1;
        this.pagination.inputPage = '1';
    }

    setDateFilter() {
        this.translate.get('ArchiveScreen.' + this.currentDateFilterType)
            .subscribe((res: string) => {
                this.dateFilter = res;
            });
    }


    setNumberOfPages() {
        this.pagesSubscription = this.ordersService.numberOfArchiveOrders.subscribe(
            res => {
                this.pagination.pages = [];
                this.pagination.numberOfPages = res / parseInt(this.pagination.itemsOnPage);
                Math.floor(this.pagination.numberOfPages);
                for (let i = 0; i < this.pagination.numberOfPages; i++) {
                    this.pagination.pages.push(i + 1);
                }
            });
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('MedicalFacilityArchive'))
            this.pagination.activePage = this.sessionService.getCurrentPage('MedicalFacilityArchive');
        if (this.sessionService.getItemsOnPage('MedicalFacilityArchive'))
            this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('MedicalFacilityArchive');
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.pagination.changePage(page));
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            (res) => {
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            (res) => {
                this.getArchiveOrders();
            }
        );
    }

    dropdown() {
        this.filters.dropdown();
    }

    makeAction(action: string, index: number): void {
        if (action == 'VIEW_EXAM_ORDER') {
            this.actionsList[action](this.ordersService.ordersArchive[index].id_exam_order).then(
                res => {
                    if (res) {
                        if (action == 'VIEW_EXAM_ORDER') {
                            this.router.navigate(['/orderDescription/' + this.ordersService.ordersArchive[index].id_exam_order + '/currentDescription'], { queryParams: { mode: 'view' } });
                            this.orderActions.cameFrom = 'medicalFacilityArchive/' + JSON.stringify(this.filtrDashboard);
                        }
                    }
                });
        } else {
            this.actionsList[action](this.ordersService.ordersArchive[index].id_exam_order);
        }
    }

    filterOrders(filter: IFiltrDashboard): void {
        this.filtrDashboard = filter;
        window.history.pushState({}, '', '/medicalFacilityArchive/' + JSON.stringify(filter));
        this.checkDateFilterType();
    }

    filterOrdersByTime(id: string): void {
        this.dateFilter = document.getElementById(id).innerHTML;
        this.clearFilter();
        if (id == 'today') {
            this.filtrDashboard.date_range.date_from = moment().format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.today;
        } else if (id == 'yesterday') {
            this.filtrDashboard.date_range.date_from = moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.yesterday;
        } else if (id == 'last7') {
            this.filtrDashboard.date_range.date_from = moment().subtract(7, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.last7;
        } else if (id == 'last30') {
            this.filtrDashboard.date_range.date_from = moment().subtract(30, 'days').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.last30;
        } else if (id == 'thisMonth') {
            this.filtrDashboard.date_range.date_from = moment().startOf('month').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().endOf('month').format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.thisMonth;
        } else if (id == 'lastMonth') {
            this.filtrDashboard.date_range.date_from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            this.filtrDashboard.date_range.date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.lastMonth;
        } else if ('all') {
            this.filtrDashboard.date_range.date_from = '';
            this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
            this.currentDateFilterType = dateFilterTypes.all;
        }

        window.history.pushState({}, '', '/medicalFacilityArchive/' + JSON.stringify(this.filtrDashboard));
        this.filters.deleteAllFiltersDashboard(false);
    }

    clearFilter(): void {
        this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
    }

    checkDateFilterType(): void {
        let dateFrom = this.filtrDashboard.date_range.date_from;
        let dateTo = this.filtrDashboard.date_range.date_to;

        switch (dateTo) {
            case moment().format('YYYY-MM-DD'):
                switch (dateFrom) {
                    case '':
                        this.currentDateFilterType = dateFilterTypes.all;
                        break;
                    case moment().format('YYYY-MM-DD'):
                        this.currentDateFilterType = dateFilterTypes.today;
                        break;
                    case moment().subtract(7, 'days').format('YYYY-MM-DD'):
                        this.currentDateFilterType = dateFilterTypes.last7;
                        break;
                    case moment().subtract(30, 'days').format('YYYY-MM-DD'):
                        this.currentDateFilterType = dateFilterTypes.last30;
                        break;
                }
                break;
            case moment().subtract(1, 'days').format('YYYY-MM-DD'):
                if (dateFrom == moment().subtract(1, 'days').format('YYYY-MM-DD'))
                    this.currentDateFilterType = dateFilterTypes.yesterday;
                break;
            case moment().endOf('month').format('YYYY-MM-DD'):
                if (dateFrom == moment().startOf('month').format('YYYY-MM-DD'))
                    this.currentDateFilterType = dateFilterTypes.thisMonth;
                break;
            case moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'):
                if (dateFrom == moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
                    this.currentDateFilterType = dateFilterTypes.lastMonth;
                break;
            case '':
                if (dateFrom == '') {
                    this.filtrDashboard.date_range.date_to = moment().format('YYYY-MM-DD');
                    this.currentDateFilterType = dateFilterTypes.all;
                    window.history.pushState({}, '', '/medicalFacilityArchive/' + JSON.stringify(this.filtrDashboard));
                }
                break;
            default:
                this.currentDateFilterType = dateFilterTypes.ownRange;
        }
        this.setDateFilter();
    }
}
