import { EnvService } from '@/services/env.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { UsersService, OdataService, ValidationService, SessionService } from '@/services';
import { Router, ActivatedRoute } from '@angular/router';
import './registrationInterface';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '@/utils';
import { RegistrationService } from '@/services/registration.service';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-registration-first',
    templateUrl: './registration-first.component.html',
    styleUrls: ['./registration-first.component.css']
})
export class RegistrationFirstComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlert') errorAlert: AlertComponent;

    portalTerms: IPortalTerm = {TermID: 0, TermsContents: ''};
    userService: UsersService;
    registrationStep1: IRegisterStep1 = {
        language: '',
        email: '',
        password: '',
        id_term: 0,
        id_agreement_list: [],
        session_id: '',
        client_ip: ''
    };
    agreementList: IAgreementList[] = [];
    termRequired = [];
    uuid: string = '';

    errorField = '';

    registerForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email, Validators.maxLength(200)]],
        pass: ['', [Validators.required, ValidationService.passwordValidator, Validators.maxLength(64)]],
        retryPass: ['', Validators.required],
        checkRegulations: [false, Validators.requiredTrue],
        agreements: this.formBuilder.array([])
    }, {validators: ValidationService.matchingPasswordsValidator});


    constructor(userService: UsersService, private router: Router, private env: EnvService,
                private odataService: OdataService, private formBuilder: FormBuilder,
                private session: SessionService, private registrationService: RegistrationService, private pageService: PageService,
                private route: ActivatedRoute) {
        this.userService = userService;
    }

    get agreements() {
        return this.registerForm.get('agreements') as FormArray;
    }

    addAgreement(required: boolean) {
        if (required) {
            this.agreements.push(this.formBuilder.control(false, Validators.requiredTrue));
        } else {
            this.agreements.push(this.formBuilder.control(false));
        }
    }

    ngOnInit() {
        if (!this.env.registrationDisabled) this.getDefaultData();
        else {
            this.route.queryParams.subscribe(
                param => {
                    if (param.invitation_uuid) {
                        this.uuid = param.invitation_uuid;
                    }
                }
            );
            if (this.uuid != '') {
                this.checkRegistrationUuid().then((res) => {
                    if (res) {
                        this.getDefaultData();
                    } else {
                        this.router.navigate([this.env.registrationDisabledUrl]);
                    }
                });
            } else this.router.navigate([this.env.registrationDisabledUrl]);
        }
        this.pageService.setSignUpPageTitle();
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    getDefaultData() {
        this.registrationService.isLogout = false;
        this.registrationStep1.language = this.userService.iLanguage;
        if (!this.session.getSessionId()) this.session.setNewSessionId();
        this.registrationStep1.session_id = this.session.getSessionId();
        this.get_portal_terms(this.registrationStep1.language);
        this.get_agreement_list_for_registration(this.registrationStep1.language);
    }

    checkRegistrationUuid(): Promise<any> {
        return this.odataService.postPublic('check_invitation_uuid',
            {registration_uuid: this.uuid},
            res => {
                const json = JSON.stringify(res);
                let result = JSON.parse(json);
                return result.value;
            });
    }

    get_portal_terms(language: string) {
        this.odataService.postPublic('get_portal_terms', {language: language},
            res => {
                const returnedJson = JSON.stringify(res);
                this.portalTerms = JSON.parse(returnedJson);
                this.registrationService.iPortalTerms = this.portalTerms;
                this.registrationStep1.id_term = this.portalTerms.TermID;
                this.portalTerms.TermsContents = '* ' + this.portalTerms.TermsContents;
            });
    }

    get_agreement_list_for_registration(language: string) {
        this.odataService.postPublic('get_agreement_list_for_registration', {language: language},
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);
                this.agreementList = result.value;

                this.agreementList.forEach(agreement => {
                    if (agreement.IsRequired) {
                        agreement.AgreementContents = '* ' + agreement.AgreementContents;
                        this.addAgreement(true);
                    } else {
                        this.addAgreement(false);
                    }
                });
            }
        );
    }

    submitDisabled(): boolean {
        return !this.registerForm.get('email').value || !this.registerForm.get('pass').value
            || !this.registerForm.get('retryPass').value;

    }

    validateForm() {
        const errors = {
            'email': 'email',
            'pass': 'pass',
            'confirmPass': 'confirmPass',
            'agreements': 'agreements',
            'TOO_LONG_PASS': 'TOO_LONG_PASS',
            'TOO_LONG_EMAIL': 'TOO_LONG_EMAIL',
            '': ''
        };

        let error = '';
        this.errorField = '';
        this.errorAlert.clear();
        let result = false;
        if (!this.registerForm.get('email').valid) {
            this.errorField = 'email';
            if (this.registerForm.get('email').value.length > 200) {
                error = 'TOO_LONG_EMAIL';
            } else {
                error = 'email';
            }
        } else if (!this.registerForm.get('pass').valid) {
            this.errorField = 'pass';
            if (this.registerForm.get('pass').value.length > 64) {
                error = 'TOO_LONG_PASS';
            } else {
                error = 'pass';
            }
        } else if (this.registerForm.errors && this.registerForm.errors.passwordsMatch) {
            this.errorField = 'confirmPass';
            error = 'confirmPass';
        } else if (!this.agreements.valid || !(<HTMLInputElement>document.getElementById('regulations')).checked) {
            this.errorField = 'agreements';
            error = 'agreements';
        } else {
            result = true;
        }

        if (!result) {
            this.errorAlert.displayError(errors[error]);
        }
        return result;
    }

    handleErrorFromServer(errorMessage: string) {
        const errors = {
            'WRONG_PASSWORD': 'pass',
            'AGREEMENT_MISSING': '',
            'OTHER_REASON': ''
        };

        this.errorField = errors[errorMessage];
        this.errorAlert.displayError(errorMessage);
    }

    fillStep1WithFormData() {
        this.registrationStep1.email = this.registerForm.get('email').value;

        this.registrationStep1.password = this.registerForm.get('pass').value;
        this.registrationStep1.id_agreement_list = this.agreements.value.reduce((array, x, i) => {
            if (x) {
                array.push(this.agreementList[i].AgreementID);
            }
            return array;
        }, []);
    }

    register_user_step1() {

        if (this.validateForm()) {
            this.fillStep1WithFormData();
            this.registrationService.iRegistration1 = this.registrationStep1;
            sessionStorage.setItem('register_mail', this.registrationStep1.email);

            this.odataService.postPublic('register_user_step1', this.registrationStep1,
                res => {
                    const returnedJson = JSON.stringify(res);
                    const registerStep1Status = JSON.parse(returnedJson);
                    this.registrationService.iRegisterStep1Status = registerStep1Status;
                    if (registerStep1Status.RegistrationStatus === 'REGISTRATION_OK') {
                        this.router.navigate(['registrationSecond', registerStep1Status.RegistrationUuid]);
                    } else if (registerStep1Status.RegistrationStatus === 'REGISTRATION_FAILED') {
                        this.handleErrorFromServer(registerStep1Status.RegistrationFailedReason);
                    }
                }
            );
        }
    }
}
