<div class="form-row">
  <div class="col form-group">
    <div>
      <label for="datepicker-range-date">{{'DashboardScreen.Filters.DateRange' | translate}}</label>
      <div>
        <input #placeholderInput class="datepicker-placeholder" id="placeholder-input"
               type="text" name="placeholder_name" [(ngModel)]="currentDateValue"
               placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
               autocomplete="off">
      </div>
      <div id="ghost-div">
        <input #pickerDateRange id="datepicker-range-date" type="text" name="daterangeInput"
               daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
               class="daterangeInputClass"/>
      </div>
    </div>
  </div>
  <div class="col form-group filter">
    <label for="statusDashboard">{{'DashboardScreen.Filters.Status' | translate}}</label>
    <select id="statusDashboard" class="custom-select" multiple>
      <option *ngFor="let status of statusTypesForDashboard" value="{{status.value.id_status}}">
        {{status.value.status}}
      </option>
    </select>
  </div>
</div>
<div class="form-row">
  <div class="col form-group filter">
    <label for="modality">{{'DashboardScreen.Filters.Modality' | translate}}</label>
    <select id="modality" class="custom-select" multiple>
      <option *ngFor="let modality of modalityTypes" value="{{modality.value.id_group}}">
        {{modality.value.value}}
      </option>
    </select>
  </div>
  <div class="col form-group filter">
    <label for="anatomy">{{'DashboardScreen.Filters.AnatomyPart' | translate}}</label>
    <select id="anatomy" class="custom-select" multiple>
      <option *ngFor="let anatomy of anatomyParts" value="{{anatomy.value.id_part}}">
        {{anatomy.value.value}}
      </option>
    </select>
  </div>
</div>
<div class="form-row">
  <div class="col form-group filter">
    <label for="assignedTo">{{'DashboardScreen.Filters.AssignedTo' | translate}}</label>
    <select id="assignedTo" class="custom-select" multiple>
      <option *ngFor="let doctor of assignedToList" value="{{doctor.value.id_physician}}">
        {{doctor.value.name}}
      </option>
    </select>
  </div>
  <div class="col form-group filter">
    <label for="priority">{{'DashboardScreen.Filters.Priority' | translate}}</label>
    <select id="priority" class="custom-select" multiple>
      <option *ngFor="let priority of priorityTypes" value="{{priority.value.id_priority}}">
        {{priority.value.priority_text}}
      </option>
    </select>
  </div>
</div>
