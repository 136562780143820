<div class="patients-portal" id="dashboard">
    <div [hidden]="!contextService.checkPermission('sidebar')" class="dmSidebarWrapper" id="dmSidebarWrapper">
        <div id="secondDMSidebarWrapper">
            <nav id="sidebar" style="height: 890px;" (mouseenter)="hover = true;" (mouseleave)="hover = false;">
                <a id="logo" class="logo-animated">
                    <svg-icon alt="" class="svg" src="../../assets/images/logo-animate.svg"></svg-icon>
                </a>
                <ul id="dashboard-navigation" [hidden]="!contextService.checkPermission('dashboardSidebar')">
                    <li id="dashboardLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" (click)="resetDashboardSettings()" routerLink="/dashboard">
                            <svg-icon *ngIf="!ordersService.refreshOrdersStatus.is_refresh_needed || hover" alt="" class="svg" src="../../assets/images/icons/sidebar/icon__zlecenia.svg">
                            </svg-icon>
                            <svg-icon *ngIf="ordersService.refreshOrdersStatus.is_refresh_needed && !hover" alt="" class="svg" src="../../assets/images/icons/sidebar/icon__refresh.svg">
                            </svg-icon>
                        </a>
                        <div class="number" [ngStyle]="{'width' : ordersService.refreshOrdersStatus.exam_all_count < 100 ? '20px' : '30px'}">
                            <ng-container *ngIf="ordersService.refreshOrdersStatus.exam_all_count < 100 && ordersService.refreshOrdersStatus.exam_all_count > 0">{{ordersService.refreshOrdersStatus.exam_all_count}}</ng-container>
                            <ng-container *ngIf="ordersService.refreshOrdersStatus.exam_all_count >= 100">99+</ng-container>
                            <ng-container *ngIf="ordersService.refreshOrdersStatus.exam_all_count == 0 || ordersService.refreshOrdersStatus.exam_all_count == null">0</ng-container>
                        </div>
                        <a (click)="resetDashboardSettings()" routerLink="/dashboard"><span class="nav-link additional-info">{{'DashboardScreen.Orders' | translate}}</span></a>
                        <span class="order-counter">{{ordersService.refreshOrdersStatus.exam_emergency_count || '0'}} <span>&bull;</span> &nbsp;{{ordersService.refreshOrdersStatus.exam_cito_count || '0'}} <span>&bull;</span>  &nbsp;{{ordersService.refreshOrdersStatus.exam_normal_count || '0'}} <span>&bull;</span></span>
                        <a class="icon additionalRefreshIcon" (click)="resetDashboardSettings()" routerLink="/dashboard" style="position: relative; top: -50px; left: 200px;">
                            <svg-icon *ngIf="ordersService.refreshOrdersStatus.is_refresh_needed" alt="" class="svg" src="../../assets/images/icons/sidebar/icon__refresh.svg">
                            </svg-icon>
                        </a>
                    </li>
                    <li id="archiveLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" (click)="resetArchiveSettings()" routerLink="/archive">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__archiwum.svg">
                            </svg-icon>
                        </a>
                        <a (click)="resetArchiveSettings()" routerLink="/archive"><span class="nav-link">{{'DashboardScreen.Registry' | translate}}</span></a>
                    </li>
                    <li id="patients" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" (click)="resetPatientsSettings()" routerLink="/patients">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__pacjenci.svg">
                            </svg-icon>
                        </a>
                        <a (click)="resetPatientsSettings()" routerLink="/patients"><span class="nav-link">{{'DashboardScreen.Patients' | translate}}</span></a>
                    </li>
                    <li id="doctorsProfileLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" (click)="resetProfilesSettings()" routerLink="/profiles">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__profile.svg">
                            </svg-icon>
                        </a>
                        <a (click)="resetProfilesSettings()" routerLink="/profiles"><span class="nav-link">{{'DashboardScreen.Profiles' | translate}}</span></a>
                    </li>
                    <li *ngIf="!envService.hideUnderConstruction" id="statistics" style="margin-top: 32px; margin-bottom: 32px;"><a routerLink="/statistics">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__statystyki.svg">
                            </svg-icon>
                        </a>
                        <a routerLink="/statistics"><span class="nav-link">{{'DashboardScreen.Statistics' | translate}}</span></a>
                    </li>
                    <li *ngIf="!envService.hideUnderConstruction" id="consultations" style="margin-top: 32px; margin-bottom: 32px;"><a routerLink="/consultations">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__konsultacje.svg">
                            </svg-icon>
                        </a>
                        <a routerLink="/consultations"><span class="nav-link">{{'DashboardScreen.Consultations' | translate}}</span></a>
                    </li>
                    <li *ngIf="!envService.hideUnderConstruction" id="orderHistory" style="margin-top: 32px; margin-bottom: 32px;"><a
                            routerLink="/orderHistoryForCenter">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__history.svg">
                            </svg-icon>
                        </a>
                        <a routerLink="/orderHistoryForCenter"><span class="nav-link">{{'DashboardScreen.OrderHistory' | translate}}</span></a>
                    </li>
                    <li *ngIf="userService.checkDcPermission('DC_MODYFING_PERMISSIONS')" id="settings" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" routerLink="/usersSettings">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__ustawienia.svg">
                            </svg-icon>
                        </a>
                        <a routerLink="/usersSettings"><span class="nav-link">{{'DashboardScreen.Settings' | translate}}</span></a>
                    </li>
                </ul>
                <ul id="dashboard-navigation" [hidden]="!contextService.checkPermission('facilitySidebar')">
                    <li id="generateResultsPair" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" (click)="generateResultPairModal.show()">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__pm_wydawanie.svg"></svg-icon>
                        </a>
                        <a (click)="generateResultPairModal.show()"><span class="nav-link">Wydaj wynik</span></a>
                    </li>
                    <li id="facilityExamsLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" routerLink="/medicalFacilityExams">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__pm_lista_badan.svg"></svg-icon>
                        </a>
                        <a routerLink="/medicalFacilityExams"><span class="nav-link">{{'DashboardScreen.Exams' | translate}}</span></a>
                    </li>
                    <li id="facilityDashboardLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon" routerLink="/medicalFacilityDashboard">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__zlecenia.svg"></svg-icon>
                        </a>
                        <a routerLink="/medicalFacilityDashboard"><span class="nav-link">{{'DashboardScreen.Orders' | translate}}</span></a>
                        <div class="number" [ngStyle]="{'width' : ordersFacilityService.width}" *ngIf="ordersFacilityService.newOrdersString != ''">
                            {{ordersFacilityService.newOrdersString}}
                        </div>
                    </li>
                    <li id="facilityArchiveLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon isDisabled" routerLink="/medicalFacilityArchive">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__archiwum.svg"></svg-icon>
                        </a>
                        <a class="isDisabled" routerLink="/medicalFacilityArchive"><span class="nav-link">{{'DashboardScreen.Registry' | translate}}</span></a>
                    </li>
                    <li id="facilityPatients" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon isDisabled" routerLink="/medicalFacilityPatients">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__pacjenci.svg"></svg-icon>
                        </a>
                        <a class="isDisabled" routerLink="/medicalFacilityPatients"><span class="nav-link">{{'DashboardScreen.Patients' | translate}}</span></a>
                    </li>
                    <li id="facilityDoctorsProfileLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon isDisabled" routerLink="/medicalFacilityProfiles">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__profile.svg"></svg-icon>
                        </a>
                        <a class="isDisabled" routerLink="/medicalFacilityProfiles"><span class="nav-link">{{'DashboardScreen.Profiles' | translate}}</span></a>
                    </li>
                    <li id="facilityInvoicesLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon isDisabled" routerLink="/medicalFacilityInvoices">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__finances.svg"></svg-icon>
                        </a>
                        <a class="isDisabled" routerLink="/medicalFacilityInvoices"><span class="nav-link">{{'DashboardScreen.Payments' | translate}}</span></a>
                    </li>
                    <li id="facilityDownloadsLink" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon isDisabled" routerLink="/siteUnderConstruction">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__download.svg"></svg-icon>
                        </a>
                        <a class="isDisabled" routerLink="/siteUnderConstruction"><span class="nav-link">Pobierane</span></a>
                    </li>
                    <li id="facilitySettings" style="margin-top: 32px; margin-bottom: 32px;">
                        <a class="icon isDisabled" routerLink="/medicalFacilityRoleSettings">
                            <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__ustawienia.svg"></svg-icon>
                        </a>
                        <a class="isDisabled" routerLink="/medicalFacilityRoleSettings"><span class="nav-link">{{'DashboardScreen.Settings' | translate}}</span></a>
                    </li>
                </ul>
                <ul id="dashboard-navigation" [hidden]="!contextService.checkPermission('adminSidebar')" >
                  <li id="adminUsers">
                    <a routerLink="/adminUsers"><span class="nav-link">{{'Admin.AdminUsers' | translate}}</span></a>
                  </li>
                  <li id="adminVerifyPwz">
                                      <a routerLink="/adminVerifyPwz"><span class="nav-link">{{'Admin.VerifyUsers' | translate}}</span></a>
                                    </li>
                  <li id="adminDescribingCenters">
                                      <a routerLink="/adminDescribingCenters"><span class="nav-link">{{'Admin.DescribingCenters' | translate}}</span></a>
                                    </li>
                  <li id="coList" >
                                      <a routerLink="/coList"><span class="nav-link">{{'Admin.COList' | translate}}</span></a>
                                    </li>
                  <li><p style="color: white; margin-left: 75px">
                    --------------------------------------------------------- <br>
                  -------------Stare ekrany admina----------- <br>
                  --------------------------------------------------------- <br>
                  </p></li>
                  <li id="usersList" >
                    <a routerLink="/usersList"><span class="nav-link">{{'Admin.OldUserList' | translate}}</span></a>
                  </li>
                  <li id="acceptPhysicianList" >
                    <a routerLink="/acceptPhysicianList"><span class="nav-link">{{'Admin.OldAcceptPhys' | translate}}</span></a>
                  </li>
                  <li id="acceptUserList" >
                    <a routerLink="/acceptUserList"><span class="nav-link">{{'Admin.OldAcceptUser' | translate}}</span></a>
                  </li>
                  <li id="addCO" >
                    <a routerLink="/addCO"><span class="nav-link">{{'Admin.OldAddCenter' | translate}}</span></a>
                  </li>
                  <li id="adminFacilitiesList" >
                    <a routerLink="/adminFacilitiesList"><span class="nav-link">{{'Admin.OldMFList' | translate}}</span></a>
                  </li>
                </ul>
            </nav>
        </div>
    </div>

    <div [hidden]="true" class="patientSidebarWrapper" id="patientSidebarWrapper">
        <nav id="sidebar" style="height: 650px; position: fixed;">
            <a id="logo" class="ml-3">
                <svg-icon style="display: block; width: 56px; height: 56px;" alt="" class="svg" src="../../assets/images/icons/sidebar/logo.svg"></svg-icon>
            </a>
            <ul id="dashboard-navigation">
                <li id="emptyExamsLink" style="margin-top: 32px; margin-bottom: 32px;">
                    <a class="icon" routerLink="/emptyExams">
                        <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__results.svg">
                        </svg-icon>
                    </a>
                    <a routerLink="/emptyExams"><span class="nav-link">{{'PatientsPortal.YourResults' | translate}}</span></a>
                </li>
                <li id="orderHistoryLink" style="margin-top: 32px; margin-bottom: 32px;">
                    <a class="icon isDisabled" routerLink="/orderHistory">
                        <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__archiwum.svg">
                        </svg-icon>
                    </a>
                    <a class="isDisabled" routerLink="/orderHistory"><span class="nav-link">{{'OrdersHistory' | translate}}</span></a>
                </li>
                <li id="serviceCatalogLink" style="margin-top: 32px; margin-bottom: 32px;">
                    <a class="icon isDisabled" routerLink="/serviceCatalog">
                        <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__service_catalog.svg">
                        </svg-icon>
                    </a>
                    <a class="isDisabled" routerLink="/serviceCatalog"><span class="nav-link">{{'ServiceCatalogForPatient' | translate}}</span></a>
                </li>
                <li id="doctorsLink" style="margin-top: 32px; margin-bottom: 32px;">
                    <a class="icon" (click)="resetDoctorsAndDcProfilesSettings()" routerLink="/doctorsAndCoProfiles">
                        <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__doctors.svg">
                        </svg-icon>
                    </a>
                    <a (click)="resetDoctorsAndDcProfilesSettings()" routerLink="/doctorsAndCoProfiles">
                        <span class="nav-link">{{'PatientsPortal.DoctorsAndFacilities' | translate}}</span>
                    </a>
                </li>
                <li id="getAccessForDcLink"
                    style="margin-top: 32px; margin-bottom: 32px;">
                    <a class="icon" routerLink="/getAccessForDc">
                        <svg-icon alt="" class="svg" src="../../assets/images/icons/sidebar/icon__need_to_exam.svg">
                        </svg-icon>
                    </a>
                    <a routerLink="/getAccessForDc"><span class="nav-link" style="top: 0;" [innerHTML]="'PatientsPortal.ForDoctors' | translate"></span></a>
                </li>
            </ul>
        </nav>
    </div>

    <div class="main">
        <div class="container-fluid">

            <div id="info-bar">
                <div class="d-flex justify-content-between flex-wrap">

                    <div *ngIf="contextService.checkPermission('patientActions')" class="pp-menu">
                        <a routerLink="/portal-pacjenta" class="z-logo">
                            <svg-icon alt="" class="svg" src="../../assets/images/logo.svg" style="display: inline;"></svg-icon>
                        </a>

                        <ul id="patient-bar-nav">
                            <li id="patientDashboardLink">
                                <a routerLink="/portal-pacjenta"><span class="nav-link">{{'PatientsPortal.Dashboard' | translate}}</span></a>
                            </li>
                            <li id="examsLink">
                                <a routerLink="/badania/lista-badan"><span class="nav-link">{{'PatientsPortal.Exams' | translate}}</span></a>
                            </li>
                            <li id="appointmentsLink" [hidden]="true">
                                <a routerLink="/wizyty"><span class="nav-link">{{'PatientsPortal.Appointments' | translate}}</span></a>
                            </li>
                            <li id="serviceLink" [hidden]="true">
                                <a routerLink="/uslugi"><span class="nav-link">{{'PatientsPortal.ServiceCatalog' | translate}}</span></a>
                            </li>
                            <li id="findDoctorsLink" [hidden]="true">
                                <a routerLink="/lekarze"><span class="nav-link">{{'PatientsPortal.FindDoctor' | translate}}</span></a>
                            </li>
                        </ul>

                        <div class="pp-menu-right">

                            <div class="dropdown dropdown-user">
                                <button aria-expanded="false" aria-haspopup="true"
                                    class="btn btn-secondary dropdown-toggle dropdown-toggle-arrow e-standard-dropdown" data-toggle="dropdown"
                                    id="ddUser" type="button">
                                    Twoje konto
                                </button>
                                <div aria-labelledby="ddUser" class="dropdown-menu">
                                    <a class="dropdown-item" routerLink="/accountProperties">{{'AccountProp' | translate}}</a>
                                    <a (click)="logOut(true)" class="dropdown-item" routerLink="/loginFirst">{{'LogOut' | translate}}</a>
                                    <div *ngIf="contextService.checkPermission('dcList') || navigationService.hasContext('DESCRIBING_CENTER')" class="dropdown dropdown-context" [ngClass]="{'mr-0': !contextService.checkPermission('switchMode')}" style="width: 100%;">
                                        <div>
                                            <ng-container *ngIf="userService.descCenters.length > 0">
                                              <hr>
                                                <ng-container *ngFor="let p of userService.descCenters; let i = index">
                                                    <a (click)="changeCenterAndNavigate(i, true)" class="dropdown-item pl-0"
                                                    *ngIf="p.name != userService.getCenterName() || navigationService.getCurrentContext() != 'DESCRIBING_CENTER'">
                                                        {{p.name}}
                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="navigationService.hasContext('MEDICAL_FACILITY')">
                                              <hr>
                                                <ng-container *ngFor="let m of userService.medFacilities; let i = index">
                                                    <a (click)="changeMedicalFacilityAndNavigate(i)" class="dropdown-item pl-0"
                                                    *ngIf="m.medicalFacilityName != userService.getFacilityName() || navigationService.getCurrentContext() != 'MEDICAL_FACILITY'">
                                                        {{m.medicalFacilityName}}
                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="navigationService.getCurrentContext() != 'PATIENT' && navigationService.getCurrentContext() != 'PHYSICIAN'">
                                                <a class="dropdown-item pl-0" (click)="goToPatientPortal()">
                                                    {{'DashboardScreen.MyPatientsPortal' | translate}}
                                                </a>
                                            </ng-container>
                                            <a *ngIf="contextService.checkPermission('dcProfile')" class="dropdown-item"
                                                routerLink="/coProfile">
                                                {{'FirmLink' | translate}}</a>
                                          <hr>
                                          <a class="dropdown-item" (click)="inviteToCoworking()">
                                            {{'DashboardScreen.StartCooperation' | translate}}
                                          </a>
                                          <ng-container *ngIf="navigationService.getCurrentContext() == 'PHYSICIAN'">
                                            <a *ngIf="navigationService.getCurrentContext() == 'PHYSICIAN'" class="dropdown-item" routerLink="/registrationCo1">
                                              {{'DashboardScreen.CreateOwnCO' | translate}}
                                            </a>
                                          </ng-container>
                                          <ng-container *ngIf="userService.unverifiedDescCenters.length > 0 && (userService.unverifiedDescCenters.length > 1 || navigationService.getCurrentContext() != 'DESCRIBING_CENTER' || userService.getCenterStatus())">
                                            <hr>
                                            <h4 *ngIf="userService.unverifiedDescCenters.length > 0 && (userService.unverifiedDescCenters.length > 1 || navigationService.getCurrentContext() != 'DESCRIBING_CENTER' || userService.getCenterStatus())"
                                                style="color: #999999; display: inline-block; padding-left: 10px; font-weight: bold; font-size: 13px; letter-spacing: 1px;">
                                              {{'DashboardScreen.UnderVerification' | translate}}
                                            </h4>
                                            <ng-container *ngIf="userService.unverifiedDescCenters.length > 0">
                                              <ng-container *ngFor="let p of userService.unverifiedDescCenters; let i = index">
                                                <a (click)="changeCenterAndNavigate(i, false)" class="dropdown-item pl-0"
                                                   *ngIf="p.name != userService.getCenterName() || navigationService.getCurrentContext() != 'DESCRIBING_CENTER'">
                                                  {{p.name}}
                                                </a>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <app-dd-notifications-button type="pp"></app-dd-notifications-button>
                        </div>

                    </div>

                    <div class="ml-auto action-bar">

                        <div class="dropdown dropdown-user">
                            <button aria-expanded="false" aria-haspopup="true"
                                class="btn btn-secondary dropdown-toggle dropdown-toggle-arrow e-standard-dropdown"
                                data-toggle="dropdown" id="ddUser" type="button">
                                <img *ngIf="userService.profileImageUuid != null" alt=""
                                    src="{{userService.miniProfileImageUrl}}">
                                <img *ngIf="userService.profileImageUuid == null" alt="" class="empty-avatar"
                                     src="../../assets/images/avatar/user_empty_avatar.svg">
                                {{userService.username}}
                            </button>
                            <div aria-labelledby="ddUser" class="dropdown-menu">
                                <a (click)="navigationService.navigateToDefault()" *ngIf="contextService.checkPermission('getToHome')"
                                   class="dropdown-item">{{'Homepage' | translate}}</a>
                                <a class="dropdown-item"
                                    routerLink="/accountProperties">{{'AccountProp' | translate}}</a>
                                <a *ngIf="navigationService.hasContext('PHYSICIAN')" class="dropdown-item"
                                   routerLink="/myProfile">{{'YourProfileLink' | translate}}</a>
                                <hr />
                                <a (click)="lockScreen(true)" class="dropdown-item"
                                    routerLink="/unlockScreen">{{'LockScreen' | translate}}</a>
                                <a (click)="logOut(true)" class="dropdown-item"
                                    routerLink="/loginFirst">{{'LogOut' | translate}}</a>
                            </div>
                        </div>

                        <div *ngIf="contextService.checkPermission('dcList') || navigationService.hasContext('DESCRIBING_CENTER')" class="dropdown dropdown-context"
                                [ngClass]="{'mr-0': !contextService.checkPermission('switchMode')}">
                            <button aria-expanded="false" aria-haspopup="true" style="line-height: 1.5;"
                                class="btn btn-secondary dropdown-toggle dropdown-toggle-arrow e-standard-dropdown"
                                data-toggle="dropdown" id="ddContext" type="button">
                                <svg-icon alt="" class="svg ml-1 mr-2" src="../../assets/images/icons/infobar/icon__current_context.svg"></svg-icon>
                                <ng-container *ngIf="navigationService.getCurrentContext() == 'PATIENT' || navigationService.getCurrentContext() == 'PHYSICIAN'">
                                    {{'DashboardScreen.MyPatientsPortal' | translate}}
                                </ng-container>
                                <ng-container *ngIf="navigationService.getCurrentContext() == 'DESCRIBING_CENTER'">
                                    {{userService.getCenterName()}}
                                </ng-container>
                                <ng-container *ngIf="navigationService.getCurrentContext() == 'MEDICAL_FACILITY'">
                                    {{userService.getFacilityName()}}
                                </ng-container>
                            </button>
                            <div aria-labelledby="ddContext" class="dropdown-menu">
                                <ng-container *ngIf="userService.descCenters.length > 0">
                                    <ng-container *ngFor="let p of userService.descCenters; let i = index">
                                        <a (click)="changeCenterAndNavigate(i, true)" class="dropdown-item pl-0"
                                        *ngIf="p.name != userService.getCenterName() || navigationService.getCurrentContext() != 'DESCRIBING_CENTER'">
                                            <svg-icon alt="" class="svg ml-1 mr-2" src="../../assets/images/icons/infobar/icon__other_contexts.svg"></svg-icon>
                                            {{p.name}}
                                        </a>
                                    </ng-container>
                                    <hr *ngIf="userService.descCenters.length > 1 || navigationService.getCurrentContext() != 'DESCRIBING_CENTER' || !userService.getCenterStatus()">
                                </ng-container>
                                <ng-container *ngIf="navigationService.hasContext('MEDICAL_FACILITY')">
                                    <ng-container *ngFor="let m of userService.medFacilities; let i = index">
                                        <a (click)="changeMedicalFacilityAndNavigate(i)" class="dropdown-item pl-0"
                                        *ngIf="m.medicalFacilityName != userService.getFacilityName() || navigationService.getCurrentContext() != 'MEDICAL_FACILITY'">
                                            <svg-icon alt="" class="svg ml-1 mr-2" src="../../assets/images/icons/infobar/icon__other_contexts.svg"></svg-icon>
                                            {{m.medicalFacilityName}}
                                        </a>
                                    </ng-container>
                                    <hr *ngIf="userService.medFacilities.length > 1 || navigationService.getCurrentContext() != 'MEDICAL_FACILITY'">
                                </ng-container>
                                <ng-container *ngIf="navigationService.getCurrentContext() != 'PATIENT' && navigationService.getCurrentContext() != 'PHYSICIAN'">
                                    <a class="dropdown-item pl-0" (click)="goToPatientPortal()">
                                        <svg-icon alt="" class="svg ml-1 mr-2" src="../../assets/images/icons/infobar/icon__other_contexts.svg"></svg-icon>
                                        {{'DashboardScreen.MyPatientsPortal' | translate}}
                                    </a>
                                    <hr>
                                </ng-container>
                                <a *ngIf="contextService.checkPermission('dcProfile')" class="dropdown-item"
                                    routerLink="/coProfile">
                                    {{'FirmLink' | translate}}</a>
                                <a class="dropdown-item" (click)="inviteToCoworking()">
                                    {{'DashboardScreen.StartCooperation' | translate}}
                                </a>
                                <a class="dropdown-item" routerLink="/registrationCo1">
                                    {{'DashboardScreen.CreateOwnCO' | translate}}
                                </a>
                                <hr *ngIf="userService.unverifiedDescCenters.length > 0 && (userService.unverifiedDescCenters.length > 1 || navigationService.getCurrentContext() != 'DESCRIBING_CENTER' || userService.getCenterStatus())">
                                <h4 *ngIf="userService.unverifiedDescCenters.length > 0 && (userService.unverifiedDescCenters.length > 1 || navigationService.getCurrentContext() != 'DESCRIBING_CENTER' || userService.getCenterStatus())"
                                    style="color: #999999; display: inline-block; padding-left: 10px; font-weight: bold; font-size: 13px; letter-spacing: 1px;">
                                    {{'DashboardScreen.UnderVerification' | translate}}
                                </h4>
                                <ng-container *ngIf="userService.unverifiedDescCenters.length > 0">
                                    <ng-container *ngFor="let p of userService.unverifiedDescCenters; let i = index">
                                        <a (click)="changeCenterAndNavigate(i, false)" class="dropdown-item pl-0"
                                        *ngIf="p.name != userService.getCenterName() || navigationService.getCurrentContext() != 'DESCRIBING_CENTER'">
                                            <svg-icon alt="" class="svg ml-1 mr-2" src="../../assets/images/icons/infobar/icon__other_contexts.svg"></svg-icon>
                                            {{p.name}}
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <app-dd-notifications-button *ngIf="contextService.checkPermission('notificationsButton')" type="normal"></app-dd-notifications-button>
                        <ng-container *ngIf="contextService.checkPermission('dashboardActions')">

                            <app-dd-message-button *ngIf="contextService.checkPermission('messageButton')" type="normal"></app-dd-message-button>
                        </ng-container>

                        <ng-container *ngIf="contextService.checkPermission('switchMode')">
                            <a (click)="switchMode()" class="dark-mode btn btn-secondary" style="cursor: pointer;">
                                <div class="tooltipContainer" style="top: 50px;">
                                    <span class="tooltip mode">
                                        <ng-container *ngIf="!isDarkMode()">{{'DashboardScreen.DarkMode' | translate}}</ng-container>
                                        <ng-container *ngIf="isDarkMode()">{{'DashboardScreen.LightMode' | translate}}</ng-container>
                                    </span>
                                </div>
                                <svg-icon alt="" class="svg" src="../../assets/images/icons/infobar/icon__dark_mode.svg">
                                </svg-icon>
                            </a>

                            <a (click)="switchToFullScreen()" class="fullscreen btn btn-secondary" style="cursor: pointer;">
                                <div class="tooltipContainer" style="top: 50px;">
                                    <span class="tooltip fullScreen">
                                        {{'DashboardScreen.FullScreen' | translate}}
                                    </span>
                                </div>
                                <svg-icon alt="" class="svg" src="../../assets/images/icons/infobar/icon__fullscreen.svg">
                                </svg-icon>
                            </a>

                            <div class="dropdown dropdown-calendar mr-0">
                                <button
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    id="ddCalendar"
                                    class="btn btn-secondary"
                                    (click)="dp.toggle()"
                                    type="button">
                                    <svg-icon class="svg" src="../../assets/images/icons/infobar/icon__time.svg" alt=""></svg-icon>
                                    <div class="tooltipContainer" style="top: 50px;">
                                        <span class="tooltip calendar">{{'DashboardScreen.Calendar' | translate}}</span>
                                    </div>
                                    {{timer.time}}
                                </button>
                                <span
                                    id="datepickerCalendar"
                                    type="text"
                                    style="visibility: hidden; position: relative; top: 15px"
                                    aria-labelledby="ddCalendar"
                                    placeholder=""
                                    #dp="bsDatepicker"
                                    bsDatepicker
                                    [isDisabled]=false
                                    [daysDisabled]="[0,1,2,3,4,5,6]"
                                    [bsValue]="date"
                                    [bsConfig]="dp_config">
                                </span>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
            <router-outlet id="childComponent"></router-outlet>
        </div>

        <app-footer *ngIf="!contextService.checkPermission('patientActions')" style="margin-top: auto;"></app-footer>
        <pp-footer *ngIf="contextService.checkPermission('patientActions')"></pp-footer>

    </div>
  <app-generate-result-pair-modal #generateResultPairModal></app-generate-result-pair-modal>
    <app-insufficient-rights-modal #insufficientRightsModal></app-insufficient-rights-modal>

    <div class="filter_component_bg h-100" (click)="hideFilter()"></div>
</div>

