import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pesel-input',
    templateUrl: './pesel-input.component.html',
    styleUrls: ['./pesel-input.component.css']
})
export class PeselInputComponent implements OnInit {

    fieldsToCheck: number[];
    isDisable: boolean[];
    filledAray: number[];
    error: boolean = false;

    @Input() docType: string;

    constructor() {
    }

    ngOnInit() {
    }

    fillFields(fieldsAsString) {
        this.fieldsToCheck = fieldsAsString.slice(1, -1).split(',').map(Number);
        this.isDisable = new Array(11);
        this.filledAray = new Array(11);
        for (let i = 0; i < this.isDisable.length; i++) {
            this.isDisable[i] = true;
        }
        for (let i = 0; i < 3; i++) {
            this.isDisable[this.fieldsToCheck[i] - 1] = false;
        }
    }

    moveCursor(i: number, event: any): void {
        if (event.keyCode == 39 || event.keyCode == 9) {
            if (this.filledAray[i]) {
                let next: number = i + 1;
                while (next < this.isDisable.length && this.isDisable[next]) ++next;
                if (next < this.isDisable.length) {
                    setTimeout(function () {
                        document.getElementById('inputID-' + (next + 1)).focus();
                    }, 100);
                }
                if (next == this.isDisable.length) {
                    setTimeout(function () {
                        document.getElementById('CheckPasswordAndPeselBtn').focus();
                    }, 100);
                }
            } else event.preventDefault();
        } else if (event.keyCode == 37) {
            if (i != 0) {
                let previous: number = i - 1;
                while (previous >= 0 && this.isDisable[previous]) --previous;
                if (previous >= 0) {
                    setTimeout(function () {
                        document.getElementById('inputID-' + (previous + 1)).focus();
                    }, 100);
                }
            }
        } else if (event.keyCode == 8) {
            if (!this.filledAray[i] && i != 0) {
                event.preventDefault();
                let previous: number = i - 1;
                while (previous >= 0 && this.isDisable[previous]) --previous;
                if (previous >= 0) {
                    setTimeout(function () {
                        document.getElementById('inputID-' + (previous + 1)).focus();
                    }, 100);
                    this.filledAray[previous] = null;
                }
            }
        } else if ((event.keyCode >= 48 && event.keyCode <= 57)
                    || (event.keyCode >= 65 && event.keyCode <= 90)
                    || (event.keyCode >= 96 && event.keyCode <= 105)) {
            let next: number = i + 1;
            while (next < this.isDisable.length && this.isDisable[next]) ++next;
            if (next < this.isDisable.length) {
                setTimeout(function () {
                    document.getElementById('inputID-' + (next + 1)).focus();
                }, 100);
            }
            if (next == this.isDisable.length) {
                setTimeout(function () {
                    document.getElementById('CheckPasswordAndPeselBtn').focus();
                }, 100);
            }
        }
    }

    calculateFilledFieldsString() {
        let count = 0;
        let filledFields: number[] = new Array(3);
        for (let i = 0; i < this.filledAray.length; i++) {
            if (this.filledAray[i] != null) {
                filledFields[count] = this.filledAray[i];
                count++;
            }
        }
        return '[' + filledFields.toString() + ']';
    }

    communicateError() {
        this.error = true;
    }

    clearError() {
        this.error = false;
    }

}
