import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { OdataService, RegistrationService, SessionService, ValidationService } from '@/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from '@/utils';
import { PageService } from '@/services/page.service';

interface IRegisterStep3 {
    uuid: string;
    imie: string;
    nazwisko: string;
    login_name: string;
    id_typ: string;
    id_value: string;
}

interface IResult {
    RegistrationStatus: string;
    Token: string;
}

@Component({
    selector: 'app-registration-third',
    templateUrl: './registration-third.component.html',
    styleUrls: ['./registration-third.component.css']
})
export class RegistrationThirdComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlert') errorAlert: AlertComponent;

    isShown: boolean = false;
    registrationStep3: IRegisterStep3 = {
        uuid: '',
        imie: '',
        nazwisko: '',
        login_name: '',
        id_typ: '',
        id_value: ''
    };
    result: IResult;
    resultInfo: {
        Language: string,
        Email: string,
        Status: string
    };

    documentType: string = 'Dowód osobisty';
    id_value: string = '';
    id_value1: string = '';
    docType: string[] = ['Dowód osobisty', 'Paszport', 'Prawo jazdy', 'PESEL'];
    alertText: string;
    isPassError: boolean = false;
    sub: any;
    showLabel = true;
  hideLogin: boolean = true;

    constructor(private router: Router, private route: ActivatedRoute,
                private odataService: OdataService, private session: SessionService,
                private registrationService: RegistrationService, private pageService: PageService) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.registrationStep3.uuid = params['uuid'];
        });
        this.errorAlert.clear();
        this.register_suggested_loginname();
        this.register_user_step3_get_info();
        this.pageService.setSignUpPageTitle();
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    changeType(c: boolean) {
        this.isShown = c;
    }

    register_suggested_loginname() {
        this.odataService.postPublic('register_suggested_loginname', {uuid: this.registrationStep3.uuid},
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                this.registrationStep3.login_name = result.value;
            }
        );
    }

    register_user_step3_get_info() {
        this.odataService.postPublic('register_user_step3_get_info', {uuid: this.registrationStep3.uuid},
            res => {
                let returnedJson = JSON.stringify(res);
                this.resultInfo = JSON.parse(returnedJson);
                if (this.resultInfo.Status == 'ALREADY_REGISTERED') {
                    this.router.navigate(['alreadyRegisteredScreen']);
                } else if (this.resultInfo.Status == 'REGISTER_EXPIRED') {
                    this.registrationService.isExpired = true;
                    this.router.navigate(['registrationSecond/' + this.registrationStep3.uuid]);

                } else if (this.resultInfo.Status == 'OTHER_REASON') {
                    this.getErrorMessage(this.resultInfo.Status);
                }
            }
        );
    }

    validateData(): boolean {
        let result: boolean = true;
        let peselValidation: boolean;
        let nameValidation: boolean;
        let surnameValidation: boolean;
        let loginValidation: boolean;
        peselValidation = ValidationService.validatePesel(this.registrationStep3.id_value);
        nameValidation = ValidationService.validateName(this.registrationStep3.imie)
            && this.registrationStep3.imie.length <= 200;
        surnameValidation = ValidationService.validateName(this.registrationStep3.nazwisko)
            && this.registrationStep3.nazwisko.length <= 200;
        loginValidation = (ValidationService.validateLogin(this.registrationStep3.login_name)
            && this.registrationStep3.login_name.length <= 200)
            || this.registrationStep3.login_name == sessionStorage.getItem('register_mail');

        if (!surnameValidation || !nameValidation || !peselValidation || !loginValidation) {
            if (this.registrationStep3.imie.length > 200) this.getErrorMessage('TOO_LONG_NAME');
            else if (this.registrationStep3.nazwisko.length > 200) this.getErrorMessage('TOO_LONG_SURNAME');
            else if (this.registrationStep3.login_name.length > 200) this.getErrorMessage('TOO_LONG_LOGIN');
            else this.getErrorMessage('INCORRECT_DATA');

            if (!surnameValidation) document.getElementById('input2').setAttribute('class', 'form-control is-error');
            if (!peselValidation) document.getElementById('pesel').setAttribute('class', 'form-control is-error');
            if (!nameValidation) document.getElementById('input1').setAttribute('class', 'form-control is-error');
            if (!loginValidation) {
                document.getElementById('login').setAttribute('class', 'form-control is-error');
            }

            result = false;
        }

        if (peselValidation) document.getElementById('pesel').setAttribute('class', 'form-control');
        if (surnameValidation) document.getElementById('input2').setAttribute('class', 'form-control');
        if (nameValidation) document.getElementById('input1').setAttribute('class', 'form-control');
        if (loginValidation) document.getElementById('login').setAttribute('class', 'form-control');
        document.getElementById('label-input-div').setAttribute('class', 'label-input-div');
        return result;
    }

    handleServerResponse(): void {
        if (this.result.RegistrationStatus == 'ACCOUNT_CREATED') {
            this.registrationService.iloginStatus.Token = this.result.Token;
            this.session.setToken(this.result.Token);
            this.session.setNewSessionId();
            this.router.navigate(['/registrationFourth']);
        } else if (this.result.RegistrationStatus == 'ALREADY_REGISTERED') {
            this.getErrorMessage(this.result.RegistrationStatus);
            document.getElementById('label-input-div').setAttribute('class', 'label-input-div-error');
        } else if (this.result.RegistrationStatus == 'ID_VALUE_INCORRECT') {
            if (this.registrationStep3.id_typ == 'pesel')
                this.getErrorMessage(this.result.RegistrationStatus + '_PESEL');
            else
                this.getErrorMessage(this.result.RegistrationStatus + '_OTHER');
        } else if (this.result.RegistrationStatus == 'REGISTRATION_EXPIRED') {
            this.getErrorMessage(this.result.RegistrationStatus);
        } else if (this.result.RegistrationStatus === 'USER_UNDERAGE') {
            this.getErrorMessage(this.result.RegistrationStatus);
        } else if (this.result.RegistrationStatus == 'OTHER_REASON') {
            this.getErrorMessage(this.result.RegistrationStatus);
        }
    }

    register_user_step3() {

        if (this.resultInfo.Status === 'REGISTRATION_OK') {
            if (!this.isShown) {
                this.registrationStep3.id_value = this.id_value;
                this.registrationStep3.id_typ = 'pesel';

            } else if (this.isShown) {
                this.registrationStep3.id_value = this.id_value1;
                this.registrationStep3.id_typ = this.documentType;
            }
            if (this.validateData()) {
                this.odataService.postPublic('register_user_step3', this.registrationStep3,
                    res => {
                        const returnedJson = JSON.stringify(res);
                        this.result = JSON.parse(returnedJson);
                        this.registrationService.iRegisterStatus = this.result;
                        this.handleServerResponse();
                    }
                );
            }
        } else if (this.resultInfo.Status === 'ALREADY_REGISTERED') {
            this.getErrorMessage(this.resultInfo.Status);
            document.getElementById('label-input-div').setAttribute('class', 'label-input-div-error');
        } else if (this.resultInfo.Status === 'REGISTER_EXPIRED') {
            this.getErrorMessage(this.resultInfo.Status);
        }
    }

    remLabel(): void {
        this.showLabel = false;
        document.getElementById('login').style.height = '60px';
    }

    activeDiv(): void {
        document.getElementById('label-input-div').setAttribute('class', 'label-input-div-active');
    }

    inactiveDiv(): void {
        document.getElementById('label-input-div').setAttribute('class', 'label-input-div');
    }

    getErrorMessage(errorType: string): void {
        const errors = {
            'OTHER_REASON': 'OTHER_REASON',
            'REGISTER_EXPIRED': 'REGISTER_EXPIRED',
            'ALREADY_REGISTERED': 'ALREADY_REGISTERED',
            'ID_VALUE_INCORRECT_PESEL': 'ID_VALUE_INCORRECT_PESEL',
            'ID_VALUE_INCORRECT_OTHER': 'ID_VALUE_INCORRECT_OTHER',
            'INCORRECT_DATA': 'INCORRECT_DATA',
            'TOO_LONG_NAME': 'TOO_LONG_NAME',
            'TOO_LONG_SURNAME': 'TOO_LONG_SURNAME',
            'TOO_LONG_LOGIN': 'TOO_LONG_LOGIN',
            'USER_UNDERAGE': 'USER_UNDERAGE'
        };

        this.errorAlert.displayError(errors[errorType]);
    }
}
