<div id="top-bar">
  <div class="row">

    <div class="col-md-12">
      <h3 class="page-name">{{'SettingsScreens.Title' | translate}}</h3>
      <ul id="top-bar-nav">
        <li id="SettingsScreensUsers">
          <a routerLink="/medicalFacilityUsersSettings" style="cursor: pointer;">{{'SettingsScreens.Users' | translate}}</a>
        </li>
        <li class="active" id="SettingsScreensTeams">
          <a routerLink="/medicalFacilityTeamSettings" style="cursor: pointer;">{{'SettingsScreens.Teams' | translate}}</a>
        </li>
        <li id="SettingsScreensPermissions">
          <a routerLink="/medicalFacilityPermissionSettings" style="cursor: pointer;">{{'SettingsScreens.Permissions' | translate}}</a>
        </li>
        <li id="SettingsScreensRoles">
          <a routerLink="/medicalFacilityRoleSettings" style="cursor: pointer;">{{'SettingsScreens.Roles' | translate}}</a>
        </li>
      </ul>

      <form>
        <app-search #search placeholder="SettingsScreens.SearchPlaceholder"></app-search>
      </form>
    </div>

  </div>
</div>

<div class="container-settings">

  <div class="row pb-4">
    <div class="col-md-12">
      <a class="btn btn-lg btn-success" (click)="this.editTeam.show();" style="cursor: pointer; color: white;">
        {{'SettingsScreens.TeamsSettings.AddTeamButton' | translate}}
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="card tiny-card"><span>{{'SettingsScreens.TeamsSettings.Doctors' | translate}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction1"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card"><span>Filia Wrocław</span>
        <button type="button" class="btn btn-light card-action" id="ddAction2"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction2">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card"><span>Centrala</span>
        <button type="button" class="btn btn-light card-action" id="ddAction3"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card"><span>Mammografia</span>
        <button type="button" class="btn btn-light card-action" id="ddAction4"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction4">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div class="card tiny-card"><span>Lekarz uprz.</span>
        <button type="button" class="btn btn-light card-action" id="ddAction1"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card"><span>CT</span>
        <button type="button" class="btn btn-light card-action" id="ddAction2"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction2">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card"><span>USG</span>
        <button type="button" class="btn btn-light card-action" id="ddAction3"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
          <a class="dropdown-item"
             (click)="this.editTeam.show();">{{'SettingsScreens.ChangeNameOrDescription' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editMembers.show();">{{'SettingsScreens.EditMembers' | translate}}</a>
          <a class="dropdown-item"
             (click)="this.editRoles.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
          <a class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

</div>

<app-double-select-modal #editMembers
                         title="Edycja ról"
                         subtitle="{Nazwa uprawnienia}"
                         left_title="Dostępne role"
                         right_title="Przypisane role"></app-double-select-modal>
<app-double-select-modal #editRoles
                         title="Edycja ról"
                         subtitle="{Nazwa uprawnienia}"
                         left_title="Dostępne role"
                         right_title="Przypisane role"></app-double-select-modal>
<app-name-and-description-modal #editTeam></app-name-and-description-modal>
