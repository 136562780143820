import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OdataService, SessionService, UsersService } from '@/services';
import { FiltersService } from '@/services/filters.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

interface IOption {
  value: any;
  id: number;
}

@Component({
    selector: 'app-invite-to-cooperation-modal',
    templateUrl: './invite-to-cooperation-modal.component.html',
    styleUrls: ['./invite-to-cooperation-modal.component.scss']
})

export class InviteToCooperationModalComponent implements OnInit {
    @Output() invitationSent = new EventEmitter();

    available_roles: IOption[] = [];
    user_name_to_invite: string = '';
    id_user: number = undefined;
    center_name: string;
    roles_from_center_id: number = null;
    allRoleIds: number[] = [];

    inviteToCoopForm: FormGroup = this.fb.group({
      roles: [[], Validators.required],
      message: ''
    });

    constructor(private odataService: OdataService, private userService: UsersService, private fb: FormBuilder,
                private sessionService: SessionService, private filtersService: FiltersService) { }

    ngOnInit() {
    }

  show(user_name_to_invite: string,
       id_user: number,
       center_name: string): void {
      this.getAvailableRoles();
      this.inviteToCoopForm.reset();
      this.id_user = id_user;
      this.user_name_to_invite = user_name_to_invite;
      this.center_name = center_name;
      $('#wyslanieZaproszeniaDoWspolpracyModal').modal('show');
    }

    hide(): void {
      $('#wyslanieZaproszeniaDoWspolpracyModal').modal('hide');
    }

    onInvitationSend() {

        this.odataService.postPrivate('co_send_invitation_to_user_with_roles',
            this.odataService.paramsTokenAndLang({
                token: this.sessionService.getToken(),
                id_describing_center: this.userService.getCenterId(),
                id_user_to_invite: this.id_user,
                message: this.inviteToCoopForm.controls.message.value || '',
                roles: this.inviteToCoopForm.controls.roles.value
            }),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    this.hide();
                    let respons = JSON.parse(result.Value);
                    if (!respons.status) {
                        this.invitationSent.emit(false);
                    } else {
                        this.invitationSent.emit(true);
                    }
                } else {
                    this.invitationSent.emit(false);
                }
            });
    }

  getAvailableRoles() {
    let currentCenterId = this.userService.getCenterId();
    if (!this.roles_from_center_id || this.roles_from_center_id != currentCenterId) {
      this.available_roles = [];
      this.filtersService.getAvailableInviteRoles().then(
        (value => {
          value.forEach((v) => {
            this.allRoleIds.push(v.id_role);
            this.available_roles.push({
              value: {
                role_description: v.role_description,
                role_name: v.role_name,
              }, id: v.id_role
            });
            this.available_roles = [...this.available_roles]; // refreshing for ng-select
          });
        })
      );
    }
  }

  toggleCheckAll($event) {
    if ($event.target.checked) this.inviteToCoopForm.patchValue({roles: this.allRoleIds});
    else this.inviteToCoopForm.patchValue({roles: []});
  }
}
