<div class="col-col-md-12" id="top-bar">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'Notifications.Notifications' | translate}} ({{notificationsCount}})</h3>
            <div class="dropdown d-inline-block">
                <button class="btn btn-secondary dropdown-toggle dropdown-toggle-arrow no-shadow small-margin" type="button"
                    id="dropdownWiadomosciKategorie" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    style="min-width: 200px;">
                    {{'Notifications.Categories' | translate}} {{categories_text}} &nbsp;&nbsp;&nbsp;
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownWiadomosciKategorie">
                    <a class="dropdown-item" (click)="onCategoriesSelect (undefined, undefined)" >{{'Notifications.AllCategories' | translate}}</a>
                    <div *ngFor="let categorie of notifications_categories">
                        <a class="dropdown-item" (click)="onCategoriesSelect (categorie.id_notification_type, categorie.notification_title)">{{categorie.notification_title}}</a>
                    </div>
                </div>
            </div>
            <ul class="ml-auto" id="top-bar-nav">
                <a (click)="onSettings ()"><svg-icon src="../../assets/images/icons/icon__settings.svg" class="svg" alt=""></svg-icon></a>
                <a class="btn btn-grey ml-3" (click)="onMarkAllAsRead ()">{{'Notifications.MarkAllAsRead' | translate}}</a>
                <a class="btn btn-danger ml-3" (click)="onClearAll ()">{{'Notifications.ClearAll' | translate}}</a>
            </ul>
        </div>
    </div>
</div>

<div class="container container-notifications" id="main_content">
    <div id="sticky_sidebar">
        <div class="inner_wrapper">
        </div>
    </div>

    <div class="row">
        <div *ngFor="let notification of notifications" class="col-md-12" id="section-{{notification.id_notification}}">
            <div class="card card-message-notification p-25">
                <div class="row h-100">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-12">
                                <p [ngClass]="{'blue': !notification.is_read}">
                                    <strong><span *ngIf="!notification.is_read" class="dot"></span>{{notification.notification_title}}</strong>
                                <p [innerHTML]="notification.notification_text | safeHtml"></p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="notification.action_list?.length > 0" class="col-md-3 my-auto">
                        <div class="actions">
                            <span *ngFor="let item of notification.action_list; let i = index;">
                                <a *ngIf="i < 2" class="btn btn-light float-right" style="padding: 5px;"
                                    (click)=" onTogRead(notification.id_notification, true);
                                    makeAction(notification, item.action_name)">{{item.button_name}}</a>
                            </span>
                            <button *ngIf="notification.action_list?.length > 2"
                                type="button" class="btn btn-light float-right card-action" id="ddAction1"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1" *ngIf="notification.action_list?.length > 2">
                                <ng-container *ngFor="let item of notification.action_list; let i = index;">
                                    <a class="dropdown-item" *ngIf="i >= 2" (click)="makeAction(notification, item.action_name)">{{item.button_name}}</a>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="position-absolute">
                        <a class="card-remove" data-placement="top" data-toggle="tooltip"
                           title="{{'Notifications.Remove' | translate}}" (click)="onRemove (notification.id_notification)"></a>
                        <a *ngIf="!notification.is_read" class="card-read" data-placement="top" data-toggle="tooltip"
                           title="{{'Notifications.MarkAsRead' | translate}}" (click)="onTogRead (notification.id_notification, true)"></a>
                        <a *ngIf="notification.is_read" class="card-read card-unread" data-placement="top"
                           data-toggle="tooltip"
                           title="{{'Notifications.MarkAsUnread' | translate}}"
                           (click)="onTogRead (notification.id_notification, false)"></a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<app-pagination #pagination componentName="Notifications" [hidden]="notifications.length == 0"></app-pagination>

<!-- start: ustawieniaPowiadomien -->
<div class="modal fade" id="ustawieniaPowiadomien" tabindex="-1" role="dialog"
    aria-labelledby="ustawieniaPowiadomienLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="ustawieniaPowiadomienLabel">{{'Notifications.NotificationsSettings' | translate}}</h2>
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <p class="pt-2 pb-2">{{'Notifications.SettingsDescription' | translate}}</p>

                <table width="100%" cellpadding="15" class="table-portfolio">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th width="160">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="settingsColumn0"
                                        (click)="onSettingsColumn(0)">
                                    <label class="custom-control-label"
                                        for="settingsColumn0"><strong>{{'Notifications.ColumnPanel' | translate}}</strong></label>
                                </div>
                            </th>
                            <th width="160">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="settingsColumn1"
                                        (click)="onSettingsColumn(1)">
                                    <label class="custom-control-label"
                                        for="settingsColumn1"><strong>{{'Notifications.ColumnEmail' | translate}}</strong></label>
                                </div>
                            </th>
                            <th width="160">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="settingsColumn2"
                                        (click)="onSettingsColumn(2)">
                                    <label class="custom-control-label"
                                        for="settingsColumn2"><strong>{{'Notifications.ColumnSms' | translate}}</strong></label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tr *ngFor="let row of notifications_settings; let i = index">
                        <td>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="settingsRow{{i}}" (click)="onSettingsRow(i)"
                                [checked]="row.row_checked">
                                <label class="custom-control-label" for="settingsRow{{i}}">{{row.notification_text}}</label>
                            </div>
                        </td>
                        <td *ngFor="let column of SETTINGS_COLUMN; let j = index">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="settingsCell{{i}}{{j}}"
                                    (click)="onSettingsCell (i, j)"
                                    [checked]="row.settings[column]">
                                <label class="custom-control-label pl-0" for="settingsCell{{i}}{{j}}">&nbsp;</label>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-lg btn-grey mt-5 mr-3" (click)="onSettingsRestoreDefaults ()">{{'Notifications.RestoreDefaults' | translate}}</button>
                <button type="button" class="btn btn-lg btn-success mt-5" (click)="onSettingsSave ()">{{'Notifications.Save' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- end: ustawieniaPowiadomien -->

<app-universal-toast #actionToast></app-universal-toast>
