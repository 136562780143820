import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService, OdataService, OrdersService, UsersService } from '@/services';
import { ActivatedRoute } from '@angular/router';
import { PatientPortalService } from '@/services/patient-portal.service';
import {
  EmptyIPatientExamDetailsPP, IPatientExamDetailsPP,
  EmptyIPatientExamDescPP, IPatientExamDescPP,
  EmptyIPatientExamPlaceholderImagesPP, IPatientExamPlaceholderImagesPP,
  IPatientDescPDFPP, EmptyIPatientDescPDFPP, CLOUD_AGREED
} from '@/interfaces/PatientsPortal';
import { EnvService } from '@/services/env.service';
import { PatientShareExamModalComponent } from '@/utils/patient-share-exam-modal/patient-share-exam-modal.component';
import { CookieService } from 'ngx-cookie';
import { CloudAgreementModalComponent } from '@/utils/cloud-agreement-modal/cloud-agreement-modal.component';

@Component({
  selector: 'app-patient-exam-view',
  templateUrl: './patient-exam-view.component.html',
  styleUrls: ['./patient-exam-view.component.scss']
})
export class PatientExamViewComponent implements OnInit {

  @ViewChild('shareExamModalComponent') shareExamModal: PatientShareExamModalComponent;
  @ViewChild('cloudAgreementModalComponent') cloudAgreementModal: CloudAgreementModalComponent;

  readonly emptyDesc: IPatientExamDescPP = EmptyIPatientExamDescPP;
  examType: number;
  examId: number;
  agreementType: number = this.userService.patientSearchDataAgreement;

  examDetails: IPatientExamDetailsPP = EmptyIPatientExamDetailsPP;
  examDescriptions: IPatientExamDescPP[] = [];
  examImages: IPatientExamPlaceholderImagesPP = EmptyIPatientExamPlaceholderImagesPP;

  currentDescription: IPatientExamDescPP;
  descPDF: IPatientDescPDFPP = EmptyIPatientDescPDFPP;



  constructor(private navigationService: NavigationService, private route: ActivatedRoute, private userService: UsersService,
    private portalService: PatientPortalService, public orderService: OrdersService, public envService: EnvService,
              private cookieService: CookieService, private odata: OdataService) {}

  ngOnInit() {

    // Temporary fix for global classes
    let body = document.getElementsByTagName('body')[0];
        body.classList.add('pp-portal');
    let menuActiveElement = document.getElementById('examsLink');
        menuActiveElement.classList.add('active');

    this.examType = parseInt(this.route.snapshot.queryParamMap.get('type'));
    this.examId = parseInt(this.route.snapshot.queryParamMap.get('id'));


    this.getExamDetails();

    this.portalService.getExamDesc(this.examId, this.examType).then(res => {
      if (res) {
        this.examDescriptions = res;

        this.currentDescription = this.examDescriptions[0] ? this.examDescriptions[0] : EmptyIPatientExamDescPP;
        this.portalService.getDescPDF(this.examId, this.examType, this.currentDescription.id_exam_order).then(res => {
          if (res) {
            this.descPDF = res;
          } else {
            this.descPDF = undefined;
          }
        });

      } else {
        this.examDescriptions = [];
      }
    });
    // todo do tego żeby zawsze się wyświetlał, usunąć po testowaniu
   // this.cookieService.remove('cloudModalCookie');

      if (this.userService.getPatientCloudAgreement() != CLOUD_AGREED && !this.cookieService.get('cloudModalCookie')) {
      this.cloudAgreementModal.show();
      let date = new Date();
      const days = 30;
      date.setDate(date.getDate() + days);
      this.cookieService.put('cloudModalCookie', date.toDateString(), {expires: date});
    }
  }

  openSafeHref(url: string): void {
    if (!!url) {
      this.odata.getFile(url).subscribe(v => {
        window.open(v, '_blank');
      });
    }
  }

  navigateBack() {
    this.navigationService.navigateBack();
  }

  getExamDetails(event?) {
    if (event && event.folderSharing) {
      this.portalService.getFolderShareStatus().then( e => this.shareExamModal.shareInfo = this.portalService.folderShareInfo);
    } else this.portalService.getExamDetails(this.examId, this.examType).then(res => {
      if (res == undefined) this.navigationService.navigateToDefault();
      else {
        this.examDetails = res;
        this.examImages.imageClassName = this.portalService.getImageClassName(res);
      }
    });
  }
}
