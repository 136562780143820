import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { IFilter } from '@/interfaces';
import moment from 'moment';
import { FiltersService } from '@/services/filters.service';
import { SessionService } from '@/services';
import { FiltersComponent } from '@/utils/filters/filters.component';

interface IOption {
  value: any;
  selected: boolean;
}

@Component({
  selector: 'app-patient-filters',
  templateUrl: './patient-filters.component.html',
  styleUrls: ['./patient-filters.component.scss']
})
export class PatientFiltersComponent implements OnInit, OnDestroy, AfterViewInit {

  chosenFilter: IFilter = {
    id: 0,
    value: FiltersComponent.createInitialFiltrPatients(),
    name: '',
    type: 'patients',
    isFavourite: false
  };
  statusTypes: IOption[] = [];
  facilityTypes: IOption[] = [];
  doctorsForPatientsList: IOption[] = [];
  selectpickers: {
    sexPicker: any,
    facilityPicker: any,
    doctorsPicker: any,
    statusPicker: any
  } = {
    sexPicker: null,
    facilityPicker: null,
    doctorsPicker: null,
    statusPicker: null
  };

  private picker: ElementRef;

  @ViewChild('pickerDateRange') set PickerSetter(content: ElementRef) {
    this.picker = content;
  }

  private picker_placeholder: ElementRef;

  @ViewChild('placeholderInput') set pickerPlaceholderSetter(content: ElementRef) {
    this.picker_placeholder = content;
  }

  public daterange: any = {};
  isActiveDateSort = false;
  globalPickerListener: any;
  enOptions: any = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'Current Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    locale: {
      format: 'YYYY-MM-DD',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Custom',
      daysOfWeek: [
        'Mo',
        'Tu',
        'We',
        'Th',
        'Fr',
        'Sa',
        'Su'
      ],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      'firstDay': 0
    },
    alwaysShowCalendars: false,
  };
  plOptions: any = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    ranges: {
      'Dzisiaj': [moment(), moment()],
      'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment()],
      'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment()],
      'Ten Miesiąc': [moment().startOf('month'), moment().endOf('month')],
      'Ostatni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    locale: {
      format: 'YYYY-MM-DD',
      applyLabel: 'Zatwierdź',
      cancelLabel: 'Anuluj',
      fromLabel: 'Z',
      toLabel: 'Do',
      customRangeLabel: 'Własny przedział',
      daysOfWeek: [
        'Po',
        'Wt',
        'Śr',
        'Cz',
        'P',
        'So',
        'N'
      ],
      'monthNames': [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
      ],
      'firstDay': 0
    },
    alwaysShowCalendars: false,
  };
  isSelects = false;
  currentDateValue: string = '';
  public options: any = this.setLangForDatepicker(this.sessionService.language);

  constructor(private filterService: FiltersService, private sessionService: SessionService, private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
    this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', () => {
      this.activateDatepicker();
    });
  }

  public ngOnDestroy() {
    this.globalPickerListener();
  }

  private activateDatepicker() {
    this.picker.nativeElement.click();
  }

  setCurrentFilter(filter: IFilter) {
    this.daterange.start = moment().subtract(29, 'days');
    this.daterange.end = moment();
    this.chosenFilter = filter;
    if (this.chosenFilter.value.birth_date.birth_date_from != '') {
      if (!this.isActiveDateSort) {
        this.isActiveDateSort = true;
      }
      this.daterange.start = this.chosenFilter.value.birth_date.birth_date_from;
      this.daterange.end = this.chosenFilter.value.birth_date.birth_date_to;
      this.updatePickerPlaceholder(new Date(this.chosenFilter.value.birth_date.birth_date_from), new Date(this.chosenFilter.value.birth_date.birth_date_to));
    } else this.clearDaterangeFilter();
  }

  saveChanges(name: string): Promise<any> {
    this.chosenFilter.value = FiltersComponent.createInitialFiltrPatients();
    this.selectpickers.statusPicker.selectpicker('val').forEach((value) => {
      this.chosenFilter.value.status.push(parseInt(value));
    });
    this.chosenFilter.value.sex = this.selectpickers.sexPicker.selectpicker('val');
    this.selectpickers.facilityPicker.selectpicker('val').forEach((value) => {
      this.chosenFilter.value.facility.push(parseInt(value));
    });
    this.selectpickers.doctorsPicker.selectpicker('val').forEach((value) => {
      this.chosenFilter.value.physician.push(parseInt(value));
    });

    if (this.getDate()) {
      return this.filterService.saveFilter(this.chosenFilter.value, this.chosenFilter.type,
        name, this.chosenFilter.isFavourite);
    }
  }

  setFilterData() {
    this.isSelects = true;
    this.selectpickers.sexPicker = $('#sex')['selectpicker']();
    let promises: Promise<any>[] = [];
    promises.push(this.getStatusTypes());
    promises.push(this.getFacilityTypes());
    promises.push(this.getDoctorsForPatients());
    Promise.all(promises).then(() => this.setFilter());
  }

  getStatusTypes(): Promise<any> {
    return this.filterService.getStatusTypesForPatients().then(
      (res) => {
        this.statusTypes = [];
        res.forEach((v) => {
          this.statusTypes.push({
            value: v,
            selected: false
          });
        });
        this.selectpickers.statusPicker = $('#status')['selectpicker']();
      }
    );
  }

  getFacilityTypes(): Promise<any> {
    return this.filterService.getFacilityTypes().then(
      (res) => {
        res.forEach((v) => {
          this.facilityTypes.push({value: v, selected: false});
        });
        this.selectpickers.facilityPicker = $('#facility')['selectpicker']();
      }
    );
  }

  getDoctorsForPatients(): Promise<any> {
    return this.filterService.getDoctorsForPatients().then(
      (res) => {
        res.forEach((v) => {
          this.doctorsForPatientsList.push({
            value: {
              name: v.physician_name,
              id_physician: v.id_physician,
              profile_image_uuid: v.profile_image_uuid
            }, selected: false
          });
        });
        this.selectpickers.doctorsPicker = $('#doctors')['selectpicker']();
      }
    );
  }

  setFilter() {
    // Status
    this.statusTypes.forEach((o) => {
      o.selected = false;
    });
    this.chosenFilter.value.status.forEach((status) => {
      this.statusTypes.forEach((o) => {
        if (status == o.value.id_status) {
          o.selected = true;
        }
      });
    });
    setTimeout(() => this.selectpickers.statusPicker.selectpicker('val', this.chosenFilter.value.status), 0);

    // Facility
    this.facilityTypes.forEach((o) => {
      o.selected = false;
    });
    this.chosenFilter.value.facility.forEach((facility) => {
      this.facilityTypes.forEach((o) => {
        if (facility == o.value.id_facility) {
          o.selected = true;
        }
      });
    });
    setTimeout(() => this.selectpickers.facilityPicker.selectpicker('val', this.chosenFilter.value.facility), 0);

    // Doctors
    this.doctorsForPatientsList.forEach((o) => {
      o.selected = false;
    });
    this.chosenFilter.value.physician.forEach((physician) => {
      this.doctorsForPatientsList.forEach((o) => {
        if (physician == o.value.id_physician) {
          o.selected = true;
        }
      });
    });
    setTimeout(() => this.selectpickers.doctorsPicker.selectpicker('val', this.chosenFilter.value.physician), 0);

    // Sex
    setTimeout(() => this.selectpickers.sexPicker.selectpicker('val', this.chosenFilter.value.sex), 0);
  }

  setLangForDatepicker(lang: string): any {
    if (lang == 'en') {
      return this.enOptions;
    } else return this.plOptions;
  }

  public selectedDate(value: any, datepicker?: any) {
    // this is the date the user selected
    if (!this.isActiveDateSort) {
      this.isActiveDateSort = true;
    }
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;

    this.updatePickerPlaceholder(value.start._d, value.end._d);
  }

  private updatePickerPlaceholder(start_date: Date, end_date: Date) {
    let start_date_str = moment(start_date).format('YYYY-MM-DD').toString();
    let end_date_str = moment(end_date).format('YYYY-MM-DD').toString();
    if (start_date_str == end_date_str) {
      this.updatePickerPlaceholderContent(start_date_str);
    } else {
      let new_content: string = start_date_str + ' - ' + end_date_str;
      this.updatePickerPlaceholderContent(new_content);
    }
  }

  public updatePickerPlaceholderContent(content: string) {
    this.currentDateValue = content;
  }

  getDateRange(): any {
    return {
      birth_date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
      birth_date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
    };
  }

  getDate(): boolean {
    let tmp = true;
    if (this.isActiveDateSort) {
      if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
        document.getElementById('placeholder-input-patient').setAttribute('class', 'error');
        tmp = false;
        this.isActiveDateSort = false;
      } else if (this.currentDateValue == '') {
        this.clearDaterangeFilter();
      } else {
        this.chosenFilter.value.birth_date = this.getDateRange();
      }
    } else if (!this.isActiveDateSort) {
      if (this.currentDateValue != '') {
        if (this.isValidDateRange(this.currentDateValue)) {
          if (this.currentDateValue.length == 23) {
            this.chosenFilter.value.birth_date.birth_date_from = this.currentDateValue.substring(0, 10);
            this.chosenFilter.value.birth_date.birth_date_to = this.currentDateValue.substring(13, 23);
          } else {
            this.chosenFilter.value.birth_date.birth_date_from = this.currentDateValue;
            this.chosenFilter.value.birth_date.birth_date_to = this.currentDateValue;
          }
          this.daterange.start = this.chosenFilter.value.birth_date.birth_date_from;
          this.daterange.end = this.chosenFilter.value.dbirth_date.birth_date_to;
        } else {
          document.getElementById('placeholder-input-patient').setAttribute('class', 'error');
          tmp = false;
        }
      } else {
        this.clearDaterangeFilter();
      }
    }

    return tmp;
  }

  clearDaterangeFilter(): void {
    this.isActiveDateSort = false;
    this.updatePickerPlaceholderContent('');
    this.chosenFilter.value.birth_date.birth_date_from = '';
    this.chosenFilter.value.birth_date.birth_date_to = '';
  }

  isValidDateRange(dateRange: string): boolean {
    if (dateRange.length != 23 && dateRange.length != 10) {
      return false;
    } else {
      if (dateRange.length == 23) {
        let from = dateRange.substring(0, 10);
        let to = dateRange.substring(13, 23);
        if (!moment(from, 'YYYY-MM-DD').isValid() || !moment(to, 'YYYY-MM-DD').isValid()) {
          return false;
        }
      } else {
        if (!moment(dateRange, 'YYYY-MM-DD').isValid()) {
          return false;
        }
      }
    }

    return true;
  }

}
