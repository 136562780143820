<ng-template #customItemTemplate let-index="index" let-model="item" let-query="query">
    <div class="tooltip-srac">
        <img [src]="model['profile_image']" class="image-srac">
        <div [caseSensitive]="false"
             [content]="model.name"
             [searchTerm]="query"
             class="d-item-srac"
             ngxTextHighlight>
            {{model.name}}
        </div>
        <span class="tooltiptext-srac">{{model.email}}</span>
    </div>
</ng-template>

<div style="position:relative; padding:0; margin:0;">
    <input [(ngModel)]="asyncSelected" [typeaheadAsync]="true" [typeahead]="dataSource"
           (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
           [typeaheadOptionsLimit]="10" typeaheadOptionField="name"
           placeholder="{{'DashboardScreen.SearchForRecipientPlaceholder' | translate}}"
           adaptivePosition="true" [typeaheadMinLength]="3" [typeaheadItemTemplate]="customItemTemplate"
           [typeaheadWaitMs]="100" (input)="onValueChange()"
           [ngClass]="{'form-control': true, 'select-recipient-async-input': true, 'invalid': !valid}">
    <img [src]="typeaheadIcon" class="select-recipient-async-img">
    <div *ngIf="typeaheadLoading">{{'DashboardScreen.Loading' | translate}}</div>
</div>