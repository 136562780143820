import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { OdataService } from '@/services';
import { IFilterFacilityExams } from '@/interfaces';
import { FacilityOrdersService } from '@/services/facility-orders.service';
import { PageService } from '@/services/page.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchComponent } from '@/utils/search/search.component';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { Subscription } from 'rxjs';
import { OrdersService, SessionService, UsersService } from '@/services';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { IFacilityExams } from '@/interfaces';
import { EnvService } from '@/services/env.service';
import { OrderInfoModalComponent } from '@/utils/order-info-modal/order-info-modal.component';
import { ConsultationModalComponent } from '@/utils/consultation/consultation-modal/consultation-modal.component';
import { ConsultationViewModalComponent } from '@/utils/consultation/consultation-view-modal/consultation-view-modal.component';
import { CovidThreshold } from '@/interfaces/AIConsultation';
import { CreateOrderModalComponent } from '@/utils/create-order-modal/create-order-modal.component';

@Component({
  selector: 'app-medical-facility-exams',
  templateUrl: './medical-facility-exams.component.html',
  styleUrls: ['./medical-facility-exams.component.scss']
})
export class MedicalFacilityExamsComponent implements OnInit, OnDestroy {

    @ViewChild('filters') filters: FiltersComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('loader') loader: LoaderComponent;
    @ViewChild('pagination') pagination: PaginationComponent;
    @ViewChild('toast') toast: UniversalToastComponent;
    @ViewChild('orderInfoModalComponent') orderInfoModal: OrderInfoModalComponent;
    @ViewChild('consultationModalComponent') consultationModal: ConsultationModalComponent;
    @ViewChild('consultationViewModalComponent') consultationViewModal: ConsultationViewModalComponent;
    @ViewChild('createOrderModalComponent') createOrderModal: CreateOrderModalComponent;


    filter: IFilterFacilityExams;
    chosenExam: IFacilityExams = null;
    currentId: string;
    searchSubscription: Subscription;
    pagesSubscription: Subscription;
    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    createOrderFromConsultationModalSubscription: Subscription;
    showToastSubscription: Subscription;
    copiedText: string = '';

    promise: Promise<any>;

    isImportButton: false;
    CovidThreshold: number = CovidThreshold;

    constructor(public userService: UsersService, private router: Router, public envService: EnvService,
                private sessionService: SessionService, public ordersService: OrdersService,
                private route: ActivatedRoute, private pageService: PageService, private facilityOrdersService: FacilityOrdersService,
                public facilityExamsService: FacilityOrdersService, private odataService: OdataService) { }

    setNumberOfPages() {
        this.pagesSubscription = this.facilityExamsService.numberOfExams.subscribe(
            res => {
                this.pagination.pages = [];
                this.pagination.numberOfPages = res / parseInt(this.pagination.itemsOnPage);
                Math.floor(this.pagination.numberOfPages);
                for (let i = 0; i < this.pagination.numberOfPages; i++) {
                    this.pagination.pages.push(i + 1);
                }
            }
        );

    }

    ngOnInit() {
        this.checkCurrentPage();
        this.searchEvent();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();
        this.setNumberOfPages();
        this.createOrderFromModalEvent();
        this.showToastEventSub();



        this.filter = FiltersComponent.createInitialFiltrFacilityExams();
        this.filters.facilityExamsFilter = this.filter;

        let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);

        this.facilityExamsService.examsLoader = this.loader;
        this.userService.mfListPromise.then(() => this.getMfExams(offset, this.ordersService.facilityId));
        this.currentId = 'All';

        this.pageService.setTranslatedPageTitle('DashboardScreen.Exams', 0);
        document.getElementById('facilityExamsLink').setAttribute('class', 'active');
    }

    ngOnDestroy() {
        document.getElementById('facilityExamsLink').setAttribute('class', '');
        this.searchSubscription.unsubscribe();
        this.pagesSubscription.unsubscribe();
        this.pageChangeSubscription.unsubscribe();
        this.itemsOnPageChangeSubscription.unsubscribe();
        this.createOrderFromConsultationModalSubscription.unsubscribe();
        this.showToastSubscription.unsubscribe();
        this.pageService.resetPageTitle();
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('MedicalFacilityExams'))
            this.pagination.activePage = this.sessionService.getCurrentPage('MedicalFacilityExams');
        if (this.sessionService.getItemsOnPage('MedicalFacilityExams'))
            this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('MedicalFacilityExams');
    }

    openCreateOrderModal(idExam: number) {
      this.createOrderModal.show(idExam);
    }

    getMfExams(offset, changedId?: number) {
      this.facilityExamsService.getMfExams(this.filter, parseInt(this.pagination.itemsOnPage), offset, undefined, changedId).then(
        () => {
          this.chosenExam = this.facilityExamsService.exams[0];
          this.createOrderModal.setInfoPromises();
        }
      );
    }

    createOrderFromModalEvent() {
      this.createOrderFromConsultationModalSubscription = this.consultationViewModal.createOrderEvent.subscribe((exam_id) => this.openCreateOrderModal(exam_id));
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.pagination.changePage(page));
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            () => {
                if (!this.search.isSearch) {
                    this.facilityExamsService.getMfExams(
                      this.filter,
                      parseInt(this.pagination.itemsOnPage),
                      (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage)
                      ).then(
                      () => {
                          this.pagination.inputPage = this.pagination.activePage + '';
                      }
                  );
                } else {
                  this.facilityExamsService.getMfExams(
                    this.filter,
                    parseInt(this.pagination.itemsOnPage),
                    (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                    this.search.filterSearch
                    ).then(
                    () => {
                        this.pagination.inputPage = this.pagination.activePage + '';
                    }
                );
                }
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            () => {
                this.getOrders('All');
            }
        );
    }

    checkIsEnable(order: any, actionName: string): boolean {
      return order.order_action_list.find(x => x.action_name == actionName) != null;
    }

    filterExams(filter: IFilterFacilityExams): void {
      this.filter = filter;
      sessionStorage.removeItem('filtersFacilityExams');
      this.sessionService.removeCurrentPage('MedicalFacilityExams');
      window.history.pushState({}, '', '/medicalFacilityExams/' + JSON.stringify(this.filter));
      this.facilityExamsService.getMfExams(this.filter, parseInt(this.pagination.itemsOnPage), 0).then(
          () => {
              this.pagination.activePage = 1;
              this.pagination.inputPage = '1';
          }
      );
      this.currentId = '';
    }

    dropdown() {
        this.filters.dropdown();
    }

    makeAction(action: string, id_exam: number, exam?: IFacilityExams) {
      if (action === 'GOTO_EXAM_ORDERS') {
        this.facilityOrdersService.set_examToOrdersId(id_exam);
        if (this.sessionService.getCurrentPage('MedicalFacilityDashboard')) this.sessionService.removeCurrentPage('MedicalFacilityDashboard');
        this.router.navigate(['/medicalFacilityDashboard']);
      } else if (action === 'VIEW_EXAM_ORDER')  this.openViewOrder(id_exam);
      else if (action === 'CREATE_AI_CONSULTATION') this.consultationModal.show(exam);
      else if (action === 'VIEW_AI_CONSULTATION') this.consultationViewModal.show(exam);
      else console.log('error: action unknown');
    }

  getOrders(id: string, inputOffset?: number): void {
      let offset = inputOffset ? inputOffset : 0;
    this.clearFilter();
    this.search.filterSearch = '';
    this.search.isSearch = false;
    $('#searchInput').val('');

    if (id == 'All') {
        this.filter = FiltersComponent.createInitialFiltrFacilityExams();
    } else if (id == 'NotOrdered') {
        this.filter = FiltersComponent.createInitialFiltrFacilityExams();
        this.filter.with_orders = 1;
    } else if (id == 'Ordered') {
        this.filter = FiltersComponent.createInitialFiltrFacilityExams();
        this.filter.with_orders = 2;
    }
    this.currentId = id;

    sessionStorage.removeItem('filtersFacilityExams');
    this.sessionService.removeCurrentPage('MedicalFacilityExams');
    window.history.pushState({}, '', '/medicalFacilityExams/' + JSON.stringify(this.filter));
    this.facilityExamsService.getMfExams(this.filter, parseInt(this.pagination.itemsOnPage), offset).then(
        () => {
            this.pagination.activePage = 1;
            this.pagination.inputPage = '1';
        }
    );
    this.filters.deleteAllFiltersFacilityExams(false);
  }

  clearFilter(): void { this.filter = FiltersComponent.createInitialFiltrFacilityExams(); }

  openViewOrder(dc_id_exam_order: number) {
    this.facilityOrdersService.getMfOrderDetails(dc_id_exam_order).then((value) => {
      if (value) this.orderInfoModal.show(this.facilityOrdersService.orderDetails);
      else {
        this.toast.error('DataLoadingFailure');
        this.toast.show();
        setTimeout(() => this.toast.hide(), 2000);
      }
    });
  }

  showToastEventSub() {
      this.showToastSubscription = this.createOrderModal.showToastEvent.subscribe(t => {
        if (t.type == 'success') this.toast.success(t.message);
        else this.toast.error(t.message);
        this.toast.show();
        setTimeout(() => this.toast.hide(), t.timeout);
        if (t.refresh) {
          let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);
          this.facilityExamsService.getMfExams(this.filter, parseInt(this.pagination.itemsOnPage), offset);
        }
      });
  }
}

