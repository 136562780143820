<div class="row">
  <div class="col-md-12 page-back">
      <h3>
          <a style="cursor:pointer;" routerLink="/getAccessForDc"></a>
          Znajdź Centrum Usługowe i nawiąż z nim współpracę
      </h3>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="input-group input-group-lg mb-3">
      <input type="text" class="search w-100" placeholder="Wpisz szukane Centrum Usługowe (wg nazwy, miasta lub adresu)">
    </div>
  </div>
</div>

<table class="tile-table mt-3" id="tile-table">
  <tbody>
    <tr class="tile">
      <td class="profiles-user-photo pl-4 py-4">
          <svg-icon [src]="'assets/images/icons/user-card-no-photo.svg'" style="width: 90px !important; height: 90px;" alt=""></svg-icon>
      </td>
      <td class="pr-5" style="width: 425px;">
          <h3>Centrum Onkologii - Instytut  im. Marii Skłodowskiej-Curie</h3>
          <span>Grodzisk Mazowiecki</span></td>
      <td>
      <td class="pl-4">
        Al. Szucha 1, 04-300 Grodzisk Mazowiecki <br>
        +48 100 200 300 <br>
        kontakt@centrum.pl
      </td>
      <td class="actions">
        <a class="btn btn-light mr-1" routerLink="/publicCOProfile/2">Zobacz profil</a>
        <button aria-expanded="false" aria-haspopup="true" class="btn btn-light card-action dotsClass mr-3" data-toggle="dropdown" id="ddAction1" type="button">
            <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
        </button>
        <div aria-labelledby="ddAction1" class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" style="cursor: pointer;">Zaproś</a>
        </div>
      </td>
    </tr>
  </tbody>
</table>