<a (messageInsideClick)="messageInsideClick($event)"
   [ngClass]="message.message_read==false ? 'message new' : extended==false ? 'message' : editReply ? 'message' : 'message extended'"
   appMessageInsideClick style="display: block; overflow: auto;">

    <div class="title">{{message.message_from}}</div>
    <div class="description">{{text}}</div>

    <div *ngIf="extended">
        <div style="padding: 5px;">
            <div *ngFor="let attachment of message.attachments">
                <a class="attachment" download="{{ attachment.attachment_name }}"
                   href="{{ envService.attachmentFileUrl + attachment.attachment_uuid }}"
                   target="_blank"> {{ attachment.attachment_name }} </a>
            </div>
        </div>

        <form #messageForm="ngForm" *ngIf="editReply" class="quick-reply">
      <textarea
              [ngClass]="{'form-control': true, 'invalid': !messageSender.validText}"
              autofocus
              id="message_text"
              name="message_text"
              ngModel
              placeholder="{{'DashboardScreen.ExtendedMessagePlaceholder' | translate}}" rows="4">
      </textarea>

            <br/>

            <div *ngFor="let attachment of messageSender.attachments; let idx = index;">
                <label [ngClass]="{'valid_file': attachment.status == 'ok', 'invalid_file' : attachment.status == 'invalid', 'upload_file': attachment.status == 'upload' }">

                    <svg aria-hidden="true" class="inputfile_svg" data-icon="paperclip" data-prefix="fas" focusable="false"
                         height="24"
                         role="img" viewBox="0 0 448 512" width="20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z"
                              fill="currentColor">
                        </path>
                    </svg>

                    <span>{{ attachment.name }}</span>

                    <div class="position-absolute-attached">
                        <a (click)="messageSender.onRemoveFile (idx, $event)" class="message-remove-attached " data-placement="top"
                           data-toggle="tooltip"
                           title="{{'Messages.Remove' | translate}}"></a>
                    </div>
                </label>
            </div>

            <div *ngIf="messageSender.attachments?.length < 10" class="actions clearfix">
                <input (change)="messageSender.onFileSelected($event)"
                       class="inputfile inputfile-1" data-multiple-caption="{count} files selected" id="file-1" multiple
                       name="file-1[]" type="file"/>
                <label for="file-1">
                    <svg fill="none" height="24" viewBox="0 0 20 24" width="20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.00024 23H3.00024C1.89624 23 1.00024 22.104 1.00024 21V3C1.00024 1.896 1.89624 1 3.00024 1H13.0002L19.0002 7V21C19.0002 22.104 18.1042 23 17.0002 23H15.0002"
                                stroke="#0848FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path d="M10.0002 13L14.0002 17" stroke="#0848FF" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2"/>
                        <path d="M6.00024 17L10.0002 13" stroke="#0848FF" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2"/>
                        <path d="M10.0002 13L10.0002 23" stroke="#0848FF" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2"/>
                    </svg>
                    <span>{{'DashboardScreen.ChooseFile' | translate}}&hellip;</span>
                </label>

                <div *ngIf="!messageSender.validAttachments">
                    <p class="warning">{{'DashboardScreen.InvalidAttachments' | translate}}</p>
                </div>
            </div>

            <div class="actions clearfix">
                <button (click)="onCancel ($event)"
                        class="btn btn-grey float-right">{{'DashboardScreen.ExtendedCancel' | translate}}</button>
                <button (click)="onSubmit ($event, messageForm)"
                        class="btn btn-primary float-right">{{'DashboardScreen.ExtendedSend' | translate}}</button>
            </div>
        </form>

        <div class="actions clearfix">
            <button (click)="onReadMessage ($event)" *ngIf="!editReply"
                    class="btn btn-grey float-right">{{'DashboardScreen.Read' | translate}}</button>
            <button (click)="onReply ($event)" *ngIf="!editReply"
                    class="btn btn-primary float-right">{{'DashboardScreen.ExtendedReply' | translate}}</button>
        </div>
    </div>
</a>
