import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { INotification } from '@/dm/notifications/inotification';
import { UsersService, OdataService, SessionService, NotificationsService } from '@/services';

@Component({
    selector: 'app-dd-notifications-button',
    templateUrl: './dd-notifications-button.component.html',
    styleUrls: ['./dd-notifications-button.component.css']
})

export class DdNotificationsButtonComponent implements OnInit, OnDestroy {
    @Input() type: string;
    notificationsCount: number;
    notifications: INotification[];
    userService: UsersService;
    odataService: OdataService;
    private sub_counter: any;
    private sub_action: any;
    // update_noExtended = true;

    constructor(userService: UsersService, odataService: OdataService,
        private sessionService: SessionService, private notificationsService: NotificationsService) {
        this.odataService = odataService;
        this.userService = userService;
    }

    ngOnInit() {
        this.notificationsCount = 0;
        this.notifications = [];

        this.sub_action = this.notificationsService.notificationsShortEvent.subscribe(
        (value: { id_notification: number, action: string }) => {
            this.processAction(value);
        });

        this.sub_counter = this.notificationsService.notificationsCountEvent
        .subscribe((value: number) => { this.notificationsCount = value; });

        this.notificationsService.realoadList();
        this.notificationsService.starTimer ();
    }

    ngOnDestroy() {
        this.notificationsService.stopTimer ();
        this.sub_counter.unsubscribe();
        this.sub_action.unsubscribe();
    }

    get_short_notifications_list() {
        // if (!this.update_noExtended) {
        //     return;
        // }

        this.odataService.postPrivate('get_short_notifications_list',
        {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        },
        res => {
            const returnedJson = JSON.stringify(res);
            const result = JSON.parse(returnedJson);

            if (result.Status === 'OK') {
                if (result.Value !== '') {
                    const value = JSON.parse(result.Value);

                    if ('jsonValue' in value) {
                        this.notifications = value.jsonValue;
                    }
                }
            }
        });
    }

    onClear(event: any) {
        event.stopPropagation();

        const notifications_list: number[] = [];

        for (let i = 0; i < this.notifications.length; i++) {
            notifications_list.push(this.notifications[i].id_notification);
        }

        this.odataService.postPrivate('delete_all_notifications',
        {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            ids: notifications_list
        },
        res => {
            const returnedJson = JSON.stringify(res);
            const result = JSON.parse(returnedJson);

            if (result.value === 'OK') {
                this.notificationsService.realoadList();
            } else {
                // TODO:
                console.log('REASON:', result.value);
            }
        });
    }

    onHidden () { // Nie tykać bez tego pojawia się błąd z nieskończoną pentlą w vendor.js
        // console.log('onHidden');
    }

    onRemove(notification_id: number) {
        for (let i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].id_notification === notification_id) {
                if (!this.notifications[i].is_read && this.notificationsCount > 0) {
                    this.notificationsService.updateCount(this.notificationsCount - 1);
                }

                this.notifications.splice(i, 1);
                this.notificationsService.shortRemoved(notification_id);
                break;
            }
        }
        this.notificationsService.realoadList();
    }

    onRead(notification_id: number) {
        for (let i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].id_notification === notification_id) {
                if (this.notificationsCount > 0) {
                    this.notificationsService.updateCount(this.notificationsCount - 1);
                }

                this.notificationsService.shortReaded(notification_id);
                return;
            }
        }
    }

    onExtend (extended: boolean) {
        // this.update_noExtended = !extended;
    }

    processAction(value: { id_notification: number, action: string }) {
        if (value.action === 'reload') {
            this.get_short_notifications_list();
            return;
        }

        for (let i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].id_notification === value.id_notification) {
                switch (value.action) {
                    case 'remove':
                        this.notifications.splice(i, 1);
                        break;
                    case 'read':
                        this.notifications[i].is_read = true;
                        break;
                    case 'unread':
                        this.notifications[i].is_read = false;
                        break;
                    default:
                        break;
                }
            }
        }
    }
}
