import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import DateTimeOffset from 'datetime-offset';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertComponent } from 'src/app/utils/alert/alert.component';
import { UsersService, OdataService, SessionService, RegistrationService } from '@/services';
import { PageService } from '@/services/page.service';
import {EnvService} from '@/services/env.service';

interface ILogin {
    uuid: string;
    login_name: string;
}

interface ISessionId {
    session_id: string;
}

interface ILoginDoc {
    Status: string;
    BlockTimestamp: DateTimeOffset;
    DocumentType: string;
    DocumentLength: number;
    FieldsToCheck: string;
}

@Component({
    selector: 'app-login-first',
    templateUrl: './login-first.component.html',
    styleUrls: ['./login-first.component.css']
})
export class LoginFirstComponent implements OnInit, OnDestroy {

    @ViewChild('loginAlert') loginAlert: AlertComponent;
    alertTitle: string;

    faQuestionCircle = faQuestionCircle;
    login: string = '';
    step2: ILogin = {uuid: '', login_name: ''};
    sessionId: ISessionId = {session_id: ''};
    loginDoc: ILoginDoc;
    uuid: string;
    userService: UsersService;
    envService: EnvService;
    registrationService: RegistrationService;


    constructor(
        private odataService: OdataService,
        userService: UsersService,
        private sessionService: SessionService,
        private router: Router,
        envService: EnvService,
        registrationService: RegistrationService,
        private pageService: PageService) {
        this.registrationService = registrationService;
        this.userService = userService;
        this.envService = envService;
    }

    ngOnInit() {
        this.displayInfo();
        this.sessionId.session_id = this.sessionService.getSessionId();
        this.login_attempt();
        this.pageService.setLogInPageTitle();
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    displayInfo() {
        if (this.registrationService.isLogout) {
            if (this.registrationService.sessionExpiredLogout) {
                this.alertTitle = 'SessionExpired';
            } else {
                this.alertTitle = 'Logout';
            }
            this.loginAlert.displayInfo('LogAgain');
        } else if (this.registrationService.isPassChanged) {
            this.alertTitle = 'ChangedPass';
            this.loginAlert.displaySuccess('LogAgain');
        } else {
            this.loginAlert.clear();
        }
    }

    login_attempt() {
        // musi być generowane przy każdej nowej prubie logowania.
        // dla tego samego isession_id dostaniemy to samo uuid.
        // login_attempt_step2 zwruci bląd jeśli będziemy się chcieli zalogować z wylogowanym uuid;
        this.sessionService.setNewSessionId();
        this.sessionId.session_id = this.sessionService.getSessionId();

        this.odataService.postPublic('login_attempt', this.sessionId,
            res => {
                const log_str = JSON.stringify(res);
                const log = JSON.parse(log_str);
                this.uuid = log.value;

                if (this.uuid != null) {
                    this.registrationService.setUuid (this.uuid);
                }
            });
    }

    login_attempt_step2() {
        if (this.uuid != null) {
            this.step2.uuid = this.uuid;
            this.step2.login_name = this.login;
            this.registrationService.setILogin (this.step2);
        }

        this.odataService.postPublic('login_attempt_step2', this.step2,
            res => {
                const newPost = JSON.stringify(res);
                this.loginDoc = JSON.parse(newPost);
                this.registrationService.iloginDoc = this.loginDoc;
                this.registrationService.isLogout = false;
                this.registrationService.isPassChanged = false;
                this.registrationService.isLogoutAutomatically = false;
                this.registrationService.sessionExpiredLogout = false;
                if (this.loginDoc.Status === 'ENABLED') {
                    this.router.navigate(['loginSecond']);
                }
            });
    }
}
