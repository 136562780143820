<div aria-hidden="true" aria-labelledby="copyList" class="modal fade" id="copyList" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Profile.COProfile.AddList.Title' | translate}}</h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="mt-3">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input (change)="changeId($event)" class="custom-control-input" id="copy" name="newList"
                               type="radio"
                               value="{{profile.priceListInfo.price_list_info ? profile.priceListInfo.price_list_info.id_price_list_default : -1}}">
                        <label class="custom-control-label" for="copy">
                            {{'Profile.COProfile.AddList.Copy' | translate}}
                        </label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input (change)="changeId($event)" checked class="custom-control-input" id="new"
                               name="newList"
                               type="radio"
                               value="">
                        <label class="custom-control-label" for="new">
                            {{'Profile.COProfile.AddList.CreateNew' | translate}}
                        </label>
                    </div>
                    <div class="form-group mt-3">
                        <label class="col-form-label"
                               for="nameoflist">{{'Profile.COProfile.AddList.Name' | translate}}</label>
                        <input [(ngModel)]="newListName" [ngModelOptions]="{standalone: true}" id="nameoflist"
                               type="text">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal"
                        type="button">{{'Profile.COProfile.AddList.Cancel' | translate}}</button>
                <button (click)="createDescribingCenterPriceList()" class="btn btn-primary" data-dismiss="modal"
                        type="button">{{'Profile.COProfile.AddList.Add' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>