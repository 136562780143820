<ng-container *ngIf="contextService.checkPermission('filters')">
    <div id="filter_wrapper" class="filter_component_wrapper">
        <div class="filter_component" style="padding-top: 30px;">

            <ng-container *ngIf="type == 'DASHBOARD' || type == 'ARCHIVE'">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="status">{{'DashboardScreen.Filters.Status' | translate}}
                            </label>
                            <select id="status" class="custom-select" multiple>
                                <option *ngFor="let o of statusOptions;" value="{{o.value.id_status}}">{{o.value.status}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="priority">{{'DashboardScreen.Filters.Priority' | translate}}
                            </label>
                            <select id="priority" class="custom-select" multiple>
                                <option *ngFor="let o of priorityOptions;" value="{{o.value.id_priority}}">
                                    {{o.value.priority_text}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <div>
                                <label for="">{{'DashboardScreen.Filters.DateRange' | translate}}
                                    <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'date_range_DESC'}"
                                        (click)="sort = 'date_range_DESC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                            alt=""></svg-icon>
                                    </span>
                                    <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'date_range_ASC'}"
                                        (click)="sort = 'date_range_ASC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                            alt=""></svg-icon>
                                    </span>
                                </label>
                                <div>
                                    <input #placeholderInput class="datepicker-placeholder" id="placeholder-input"
                                        type="text" name="placeholder_name" [(ngModel)]="currentDateValue"
                                        placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
                                        autocomplete="off">
                                </div>
                                <div id="ghost-div">
                                    <input #pickerDateRange id="datepicker-range-date" type="text" name="daterangeInput"
                                        daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
                                        class="daterangeInputClass" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="type != 'ARCHIVE'" class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <div>
                                <label for="">{{'DashboardScreen.Filters.DescriptionDateRange' | translate}}
                                    <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'description_date_range_DESC'}"
                                        (click)="sort = 'description_date_range_DESC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt="">
                                        </svg-icon>
                                    </span>
                                    <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'description_date_range_ASC'}"
                                        (click)="sort = 'description_date_range_ASC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt="">
                                        </svg-icon>
                                    </span>
                                </label>
                                <div>
                                    <input #placeholderInputDesc class="datepicker-placeholder" id="placeholder-input_desc" type="text"
                                        name="placeholder_name" [(ngModel)]="currentDescDateValue"
                                        placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}" autocomplete="off">
                                </div>
                                <div id="ghost-div1">
                                    <input #pickerDateRangeDesc id="datepicker-range-desc-date" type="text" name="daterangeInput"
                                        daterangepicker [options]="options" (selected)="selectedDate($event, daterangeDesc)"
                                        class="daterangeInputClass" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="modality">{{'DashboardScreen.Filters.Modality' | translate}}
                            </label>
                            <select id="modality" class="custom-select" multiple>
                                <option *ngFor="let o of modalityOptions;" value="{{o.value.id_group}}">{{o.value.value}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="anatomy">{{'DashboardScreen.Filters.AnatomyPart' | translate}}
                            </label>
                            <select id="anatomy" class="custom-select" multiple>
                                <option *ngFor="let o of anatomyOptions;" value="{{o.value.id_part}}">{{o.value.value}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="assignedTo">{{'DashboardScreen.Filters.AssignedTo' | translate}}
                                <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'assignedTo_DESC'}"
                                    (click)="sort = 'assignedTo_DESC'">
                                    <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt="">
                                    </svg-icon>
                                </span>
                                <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'assignedTo_ASC'}"
                                    (click)="sort = 'assignedTo_ASC'">
                                    <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt="">
                                    </svg-icon>
                                </span>
                            </label>
                            <select id="assignedTo" class="custom-select" multiple>
                                <option *ngFor="let o of assignedOptions;" value="{{o.value.id_physician}}">{{o.value.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div [hidden]="type != 'ARCHIVE'" class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="facility">{{'DashboardScreen.Filters.FacilityArchive' | translate}}
                            </label>
                            <select id="facility" class="custom-select" multiple>
                                <option *ngFor="let o of facilityOptions;" value="{{o.value.medical_facility.id_medical_facility}}">
                                    {{o.value.medical_facility.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row" style="z-index: 20 !important;">
                    <div style="width: 70%;">
                        <div style="padding-left: 19px;">
                            <p class="text-grey m-0 p-0"><img src="../../../assets/images/icons/icon__information.svg"
                                    class="svg position-relative mr-2" alt="">
                                {{'DashboardScreen.Filters.Info' | translate}}</p>
                        </div>
                    </div>

                    <div style="text-align: right; width: 30%;">
                        <button class="btn btn-primary" (click)="filtrOrders()" id="filtrButton"
                            style="margin-right: 20px;">{{'DashboardScreen.Filters.UseFilters' | translate}}</button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="type == 'HISTORY'">
                <div class="row">

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                            <div>
                                <label for="datepicker-range">{{'DashboardScreen.Filters.DateRange' | translate}}
                                    <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'date_range_DESC'}"
                                        (click)="sort = 'date_range_DESC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                            alt=""></svg-icon>
                                    </span>
                                    <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'date_range_ASC'}"
                                        (click)="sort = 'date_range_ASC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                            alt=""></svg-icon>
                                    </span>
                                </label>
                                <input id="datepicker-range" type="text" name="daterangeInput" daterangepicker
                                    [options]="options" (selected)="selectedDate($event, daterange)"
                                    class="daterangeInputClass" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="physician">{{'Datatable.History.Physician' | translate}}
                            </label>
                            <select id="physician" class="custom-select" multiple>
                                <option *ngFor="let o of physicianOptions;" value="">{{o.value.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="modality">{{'DashboardScreen.Filters.Modality' | translate}}
                            </label>
                            <select id="modality" class="custom-select" multiple>
                                <option *ngFor="let o of modalityOptions;" value="{{o.value.id_group}}">{{o.value.value}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="principal">{{'Datatable.History.Principal' | translate}}
                            </label>
                            <select id="principal" class="custom-select" multiple>
                                <option *ngFor="let o of principalOptions;" value="">{{o.value.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="price">{{'Datatable.History.Price' | translate}}
                            </label>
                            <select id="price" class="custom-select">
                                <option *ngFor="let o of priceOptions;" value="">{{o.value.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="assignedTo">{{'DashboardScreen.Filters.AssignedTo' | translate}}
                                <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'assignedTo_desc'}"
                                    (click)="sort = 'assignedTo_desc'">
                                    <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt="">
                                    </svg-icon>
                                </span>
                                <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'assignedTo_asc'}"
                                    (click)="sort = 'assignedTo_asc'">
                                    <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt="">
                                    </svg-icon>
                                </span>
                            </label>
                            <select id="assignedTo" class="custom-select" multiple>
                                <option *ngFor="let o of assignedOptions;" value="{{o.value.id_physician}}">{{o.value.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="row" style="z-index: 20 !important; padding-top: 10px;">
                    <div style="text-align: right; width: 100%;">
                        <button class="btn btn-primary"
                            style="margin-right: 20px;">{{'DashboardScreen.Filters.UseFilters' | translate}}</button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="type == 'PATIENTS'">
                <div class="row">

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="name">{{'DashboardScreen.Filters.Name' | translate}}
                            </label>
                            <input id="name" type="text" class="datepicker-placeholder">
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="sex">{{'DashboardScreen.Filters.Sex' | translate}}
                            </label>
                            <select id="sex" class="custom-select">
                                <option value="A">{{'DashboardScreen.Filters.A' | translate}}</option>
                                <option value="M">{{'DashboardScreen.Filters.M' | translate}}</option>
                                <option value="F">{{'DashboardScreen.Filters.F' | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="pesel">{{'DashboardScreen.Filters.Pesel' | translate}}
                            </label>
                            <input id="pesel" type="text" class="datepicker-placeholder">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                            <div>
                                <label for="">{{'DashboardScreen.Filters.BirthDate' | translate}}
                                </label>
                                <div>
                                    <input #placeholderInput class="datepicker-placeholder" id="placeholder-input"
                                        type="text" name="placeholder_name" [(ngModel)]="currentDateValue"
                                        placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
                                        autocomplete="off">
                                </div>
                                <div id="ghost-div">
                                    <input #pickerDateRange id="datepicker-range-date" type="text" name="daterangeInput"
                                        daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
                                        class="daterangeInputClass" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                        <label for="statusForPatient">{{'DashboardScreen.Filters.Status' | translate}}
                        </label>
                        <select id="statusForPatient" class="custom-select" multiple>
                            <option *ngFor="let o of statusOptionsForPatient;" value="{{o.value.id_status}}">{{o.value.status}}
                            </option>
                        </select>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="physiciansListForPatient">{{'DashboardScreen.Filters.Physician' | translate}}
                            </label>
                            <select id="physiciansListForPatient" class="custom-select" multiple>
                            <option *ngFor="let o of physiciansListForPatient;" value="{{o.value.id_physician}}">{{o.value.physician_name}}
                            </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="facilitiesListForPatient">{{'DashboardScreen.Filters.Facility' | translate}}
                            </label>
                            <select id="facilitiesListForPatient" class="custom-select" multiple>
                                <option *ngFor="let o of facilitiesListForPatient;" value="{{o.value.id_medical_facility}}">{{o.value.medical_facility_name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="examId">{{'DashboardScreen.Filters.ExamId' | translate}}
                            </label>
                            <input id="examId" type="text" class="datepicker-placeholder">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-12 my-auto">
                        <div style="text-align: right; width: 100%;">
                            <button class="btn btn-primary" (click)="filtrPatients()" id="filtrButton">
                                {{'DashboardScreen.Filters.UseFilters' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="type == 'FACILITY_EXAMS'">
                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <div>
                                <label for="">{{'DashboardScreen.Filters.ExamDateRange' | translate}}
                                    <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'date_range_DESC'}"
                                        (click)="sort = 'date_range_DESC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                            alt=""></svg-icon>
                                    </span>
                                    <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'date_range_ASC'}"
                                        (click)="sort = 'date_range_ASC'">
                                        <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                            alt=""></svg-icon>
                                    </span>
                                </label>
                                <div>
                                    <input #placeholderInputDesc class="datepicker-placeholder" id="placeholder-input_desc"
                                        type="text" name="placeholder_name" [(ngModel)]="currentDescDateValue"
                                        placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
                                        autocomplete="off">
                                </div>
                                <div id="ghost-div1">
                                    <input #pickerDateRangeDesc id="datepicker-range-desc-date" type="text" name="daterangeInput"
                                        daterangepicker [options]="options" (selected)="selectedDate($event, daterangeDesc)"
                                        class="daterangeInputClass" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="modality">{{'DashboardScreen.Filters.Modality' | translate}}
                                <span class="data-sort data-sort-desc" [ngClass]="{'active': sort == 'modality_DESC'}"
                                    (click)="sort = 'modality_DESC'">
                                    <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                        alt=""></svg-icon>
                                </span>
                                <span class="data-sort data-sort-asc" [ngClass]="{'active': sort == 'modality_ASC'}"
                                    (click)="sort = 'modality_ASC'">
                                    <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"
                                        alt=""></svg-icon>
                                </span>
                            </label>
                            <select id="modality" class="custom-select" multiple>
                                <option *ngFor="let o of modalityOptions;" value="{{o.value.id_group}}">{{o.value.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="anatomy">{{'DashboardScreen.Filters.AnatomyPart' | translate}}
                            </label>
                            <select id="anatomy" class="custom-select" multiple>
                                <option *ngFor="let o of anatomyOptions;" value="{{o.value.id_part}}">{{o.value.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="examName">{{'DashboardScreen.Filters.ExamName' | translate}}
                            </label>
                            <input id="examName" type="text" class="datepicker-placeholder">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="risNumber">{{'DashboardScreen.Filters.RisNumber' | translate}}
                            </label>
                            <input id="risNumber" type="text" class="datepicker-placeholder">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="examSource">{{'DashboardScreen.Filters.ExamSource' | translate}}
                            </label>
                            <select id="examSource" class="custom-select">
                                <option value="">{{'DashboardScreen.Filters.A' | translate}}</option>
                                <option value="RIS">RIS</option>
                                <option value="PACS">PACS</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="patientName">{{'DashboardScreen.Filters.PatientName' | translate}}
                            </label>
                            <input id="patientName" type="text" class="datepicker-placeholder">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="examSex">{{'DashboardScreen.Filters.Sex' | translate}}
                            </label>
                            <select id="examSex" class="custom-select">
                                <option value="A">{{'DashboardScreen.Filters.A' | translate}}</option>
                                <option value="M">{{'DashboardScreen.Filters.M' | translate}}</option>
                                <option value="F">{{'DashboardScreen.Filters.F' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="patientId">{{'DashboardScreen.Filters.PatientId' | translate}}
                            </label>
                            <input id="patientId" type="text" class="datepicker-placeholder">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div>
                                <label for="">{{'DashboardScreen.Filters.BirthDate' | translate}}
                                </label>
                                <div>
                                    <input #placeholderInput class="datepicker-placeholder" id="placeholder-input"
                                        type="text" name="placeholder_name" [(ngModel)]="currentDateValue"
                                        placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
                                        autocomplete="off">
                                </div>
                                <div id="ghost-div">
                                    <input #pickerDateRange id="datepicker-range-date" type="text" name="daterangeInput"
                                        daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
                                        class="daterangeInputClass" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="accessibility">{{'DashboardScreen.Filters.Accessibility' | translate}}
                            </label>
                            <select id="accessibility" class="custom-select" multiple>
                                <option *ngFor="let o of examAccessibilityOptions;" value="{{o.value.id_status}}">{{o.value.status_text}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" style="z-index: 20 !important; padding-top: 10px;">
                    <div style="text-align: right; width: 100%;">
                        <button class="btn btn-danger mr-3">{{'DashboardScreen.Filters.SetDefaultFilters' | translate}}</button>
                        <button class="btn btn-primary" (click)="filterFacilityExams()">{{'DashboardScreen.Filters.UseFilters' | translate}}</button>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>

    <ng-container *ngIf="type == 'DASHBOARD' || type == 'ARCHIVE'">
        <div *ngIf="filtersActiveDashboard || (type === 'ARCHIVE' && filterService.searchInputArchive !='')
        || (type === 'DASHBOARD' && filterService.searchInputDashboard !='')">
            <div class="row ml-0 pl-0 mb-3" id="filtersDiv">
                <h6 class="strong" style="position: relative; top: 8px;">
                    {{'DashboardScreen.Filters.ActiveFilters' | translate}}
                </h6>
                <div>
                    <a id="deleteAll" class="btn btn-small btn-danger ml-4"
                        (click)="deleteAllFiltersDashboard(true)">{{'DashboardScreen.Filters.DeleteAll' | translate}}</a>
                    <a id="saveFilter" class="btn btn-small btn-white ml-2"
                        (click)="saveFilter(type)">{{'DashboardScreen.Filters.SaveSet' | translate}}</a>
                </div>
            </div>

            <div class="active-filters mb-5" style="margin-bottom: 0px !important;">
                <div class="active-filter" *ngIf="statusFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Status' | translate}}</strong><br />
                    {{statusFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('statusFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="daterangeFilter != ''">
                    <strong>{{'DashboardScreen.Filters.DateRange' | translate}}</strong><br />
                    {{daterangeFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('daterangeFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="modalityFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Modality' | translate}}</strong><br />
                    {{modalityFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('modalityFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="anatomyFilter != ''">
                    <strong>{{'DashboardScreen.Filters.AnatomyPart' | translate}}</strong><br />
                    {{anatomyFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('anatomyFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="assignedFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Assignee' | translate}}</strong><br />
                    {{assignedFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('assignedFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="priorityFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Priority' | translate}}</strong><br />
                    {{priorityFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('priorityFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="facilityArchiveFilter != ''">
                    <strong>{{'DashboardScreen.Filters.FacilityArchive' | translate}}</strong><br />
                    {{facilityArchiveFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('facilityFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="daterangeDescFilter != ''">
                    <strong>{{'DashboardScreen.Filters.DescriptionDateRange' | translate}}</strong><br />
                    {{daterangeDescFilter}}
                    <i class="remove-filter" (click)="deleteFilterDashboard('daterangeDescFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="(type === 'ARCHIVE' && filterService.searchInputArchive !='')
                 || (type === 'DASHBOARD' && filterService.searchInputDashboard != '')">
                  <strong>{{'DashboardScreen.Filters.SearchedPhrase' | translate}}</strong><br />
                  <ng-container *ngIf="type === 'ARCHIVE'">{{filterService.searchInputArchive}}</ng-container>
                  <ng-container *ngIf="type === 'DASHBOARD'">{{filterService.searchInputDashboard}}</ng-container>
                  <i class="remove-filter" (click)="deleteFilterDashboard('searchFilter')">
                    <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                  </i>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="type == 'PATIENTS'">
        <div *ngIf="filtersActivePatients || filterService.searchInputPatients !=''">
            <div class="row ml-0 pl-0 mb-3" id="filtersDiv">
                <h6 class="strong" style="position: relative; top: 8px;">
                    {{'DashboardScreen.Filters.ActiveFilters' | translate}}
                </h6>
                <div>
                    <a id="deleteAll" class="btn btn-small btn-danger ml-4"
                        (click)="deleteAllFiltersPatients(true)">{{'DashboardScreen.Filters.DeleteAll' | translate}}</a>
                    <a id="saveFilter" class="btn btn-small btn-white ml-2"
                        (click)="saveFilter('patients')">{{'DashboardScreen.Filters.SaveSet' | translate}}</a>
                </div>
            </div>

            <div class="active-filters mb-5" style="margin-bottom: 0px !important;">
                <div class="active-filter" *ngIf="nameFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Name' | translate}}</strong><br />
                    {{nameFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('nameFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="sexFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Sex' | translate}}</strong><br />
                    {{sexFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('sexFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="peselFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Pesel' | translate}}</strong><br />
                    {{peselFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('peselFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="daterangeFilter != ''">
                    <strong>{{'DashboardScreen.Filters.BirthDate' | translate}}</strong><br />
                    {{daterangeFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('daterangeFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="statusFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Status' | translate}}</strong><br />
                    {{statusFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('statusFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="physicianFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Physician' | translate}}</strong><br />
                    {{physicianFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('physicianFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="facilityFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Facility' | translate}}</strong><br />
                    {{facilityFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('facilityFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examIdFilter != ''">
                    <strong>{{'DashboardScreen.Filters.ExamId' | translate}}</strong><br />
                    {{examIdFilter}}
                    <i class="remove-filter" (click)="deleteFilterPatients('examIdFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="filterService.searchInputPatients !=''">
                  <strong>{{'DashboardScreen.Filters.SearchedPhrase' | translate}}</strong><br />
                  {{filterService.searchInputPatients}}
                  <i class="remove-filter" (click)="deleteFilterPatients('searchFilter')">
                    <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                  </i>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="type == 'FACILITY_EXAMS'">
        <div *ngIf="filtersActiveFacilityExams">
            <div class="row ml-0 pl-0 mb-3" id="filtersDiv">
                <h6 class="strong" style="position: relative; top: 8px;">
                    {{'DashboardScreen.Filters.ActiveFilters' | translate}}
                </h6>
                <div>
                    <a id="deleteAll" class="btn btn-small btn-danger ml-4"
                        (click)="deleteAllFiltersFacilityExams(true)">{{'DashboardScreen.Filters.DeleteAll' | translate}}</a>
                    <a id="saveFilter" class="btn btn-small btn-white ml-2">{{'DashboardScreen.Filters.SaveSet' | translate}}</a>
                </div>
            </div>

            <div class="active-filters mb-5" style="margin-bottom: 0px !important;">
                <div class="active-filter" *ngIf="daterangeDescFilter != ''">
                    <strong>{{'DashboardScreen.Filters.ExamDateRange' | translate}}</strong><br />
                    {{daterangeDescFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('daterangeExamFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examTypeFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Modality' | translate}}</strong><br />
                    {{examTypeFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examTypeFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examAnatomyFilter != ''">
                    <strong>{{'DashboardScreen.Filters.AnatomyPart' | translate}}</strong><br />
                    {{examAnatomyFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examAnatomyFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examNameFilter != ''">
                    <strong>{{'DashboardScreen.Filters.ExamName' | translate}}</strong><br />
                    {{examNameFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examNameFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examRisNumberFilter != ''">
                    <strong>{{'DashboardScreen.Filters.RisNumber' | translate}}</strong><br />
                    {{examRisNumberFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examRisNumberFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examSourceFilter != ''">
                    <strong>{{'DashboardScreen.Filters.ExamSource' | translate}}</strong><br />
                    {{examSourceFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examSourceFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examPatientNameFilter != ''">
                    <strong>{{'DashboardScreen.Filters.PatientName' | translate}}</strong><br />
                    {{examPatientNameFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examPatientNameFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examSexFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Sex' | translate}}</strong><br />
                    {{examSexFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examSexFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examPatientIdFilter != ''">
                    <strong>{{'DashboardScreen.Filters.PatientId' | translate}}</strong><br />
                    {{examPatientIdFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examPatientIdFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="daterangeFilter != ''">
                    <strong>{{'DashboardScreen.Filters.BirthDate' | translate}}</strong><br />
                    {{daterangeFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('daterangeFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
                <div class="active-filter" *ngIf="examAccessibilityFilter != ''">
                    <strong>{{'DashboardScreen.Filters.Accessibility' | translate}}</strong><br />
                    {{examAccessibilityFilter}}
                    <i class="remove-filter" (click)="deleteFilterFacilityExams('examAccessibilityFilter')">
                        <svg-icon id="closeSVG" src="../../../assets/images/icons/close_x.svg" class="svg"></svg-icon>
                    </i>
                </div>
            </div>
        </div>
    </ng-container>

    <app-add-filter-modal #addFilterModal></app-add-filter-modal>
    <app-universal-toast #filterSavedToast></app-universal-toast>

</ng-container>
