import { Component, OnInit, OnDestroy } from '@angular/core';
import { Examination, EXAMINATION } from './examinationInterface';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-examination-description',
    templateUrl: './examination-description.component.html',
    styleUrls: ['./examination-description.component.css']
})
export class ExaminationDescriptionComponent implements OnInit, OnDestroy {
    examination: Examination;
    chosenDescription: number = 0;

    constructor(private pageService: PageService) {
    }

    ngOnInit() {
        this.getData();
        this.pageService.setPageTitle(this.pageService.getTranslation('ExaminationScreen.Title') + ' ' + this.examination.examinationId, 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    getData(): void {
        this.examination = EXAMINATION;
    }

    changeChosen(newChosen: number): void {
        this.chosenDescription = newChosen;
    }
}
