<div class="pp--cards-columns">
  <div class="pp--card-column pp--card-column-big">

    <app-loader #loader class="app-loader"></app-loader>
    <div class="pp--card">
      <div  class="pp--card-inner small" id="ExamListBody" style="padding-bottom: 0;">

        <h3 style="margin-bottom: 0; padding-bottom: 0;">
          {{'PatientsPortal.DashboardScreen.ExamList' |translate}}
        </h3>
      </div>

      <div class="pp--card-inner" *ngIf="isAgreementCard()" id="AgreementBody">
        <app-patient-agreement-card #patientAgreementCard [examListSize]="examList.length" (agreementResult)="patientExamSearchOption = $event" (startSearch)="getExamList()"></app-patient-agreement-card>
      </div>

      <div *ngIf="!isAgreementCard()">
        <div class="card-body" *ngIf="examList == [] || !examList[0]" style="margin-left: 15px">
          <h4><strong>{{'PatientsPortal.AgreementCard.NoExamsTitle' |translate}}</strong></h4>
          {{'PatientsPortal.AgreementCard.NoExamsTxtPt1' |translate}}<br>
          {{'PatientsPortal.AgreementCard.NoExamsTxtPt2' |translate}}
        </div>

        <table *ngIf="examList != [] && examList[0]" class="pp--table" id="examTable">

          <thead>
          <tr>
            <th>{{'PatientsPortal.ExamListScreen.ExamName' | translate}}</th>
            <th>Data i miejsca badania</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
          </thead>

          <tr *ngFor="let exam of examList">
            <td>
              <span class="exam_icon exam_icon--{{exam.exam_type.id_type}}-{{exam.anatomy_part.id_part}}"></span>
              {{exam.anatomy_part.anatomy_part_text}}<br />
              <span class="light">{{exam.exam_type.exam_type_name}}</span>
            </td>
            <td>
              {{ordersService.getDateString(exam.exam_date)}}<br />
              <span class="light">{{exam.medical_facility_name}}</span>
            </td>
            <td>
              <span *ngIf="exam.cloud_status == 1" class="pill pill-green">{{'PatientsPortal.DashboardScreen.InCloud' |translate}}</span>
            </td>
            <td>
              <a class="btn btn-light-blue btn-md"
                 (click)="seeExam(exam.id_exam, exam.exam_type.id_type)">{{'PatientsPortal.DashboardScreen.SeeDetails'|translate}}</a>
            </td>
          </tr>
        </table>
      </div>


    </div>
    <div class="pp--card-inner small" *ngIf="!isAgreementCard() && patientExamSearchOption == AGREEMENT_SKIPPED">
      <a *ngIf="patientExamSearchOption == AGREEMENT_SKIPPED" (click)="resetAgreement()">{{'PatientsPortal.DashboardScreen.AgreeToTerms' |translate}}</a>
    </div>

  </div>

  <div class="pp--card-column pp--card-column-small">
    <div class="pp--card">
      <div class="pp--card-inner small pp--actions_and_features">

        <div>
          <h5>Udostępnianie teczki</h5>
          <p *ngIf="patientCloudAgreement != NO_CLOUD">{{'PatientsPortal.CloudAgreement.ActiveTxt' |translate}}</p>
          <a class="btn btn-light fullwidth" (click)="shareExamModal.show(folderShareInfo.share_status == 1)">
            <ng-container *ngIf="folderShareInfo && folderShareInfo.share_status == 1">
              {{'PatientsPortal.ShareExamModal.FolderSharedBtn' |translate}}</ng-container>
            <ng-container *ngIf="!folderShareInfo || folderShareInfo.share_status == 0">
              {{'PatientsPortal.ShareExamModal.ShareFolderBtn' |translate}}</ng-container>
          </a>
          <p *ngIf="folderShareInfo && folderShareInfo.share_status == 1"
            style="text-align: center; opacity: 0.66; padding-top: 5px;">
            {{'PatientsPortal.ShareExamModal.ValidUntil' |translate}}:
            {{orderService.getDateString(folderShareInfo.share_end_time)}}
          </p>
        </div>

        <div>
          <h5>{{'PatientsPortal.ExamListScreen.ExamsInCloud' |translate}}
            <span *ngIf="patientCloudAgreement != NO_CLOUD" class="status pill pill-green">{{'PatientsPortal.CloudAgreement.ActiveLabel' |translate}}</span>
            <span *ngIf="patientCloudAgreement == NO_CLOUD" class="status pill pill-yellow">{{'PatientsPortal.CloudAgreement.ActivateLabel' |translate}}</span>
          </h5>
          <button class="btn btn-light fullwidth" *ngIf="patientCloudAgreement == NO_CLOUD" (click)="cloudAgreementModal.show()">{{'PatientsPortal.CloudAgreement.Activate' |translate}}</button>
          <p>
            <ng-container *ngIf="patientCloudAgreement != NO_CLOUD">
              {{'PatientsPortal.CloudAgreement.ActiveTxt' |translate}}
            </ng-container>
            <ng-container *ngIf="patientCloudAgreement == NO_CLOUD">
              {{'PatientsPortal.CloudAgreement.InactiveTxt' |translate}}
            </ng-container>
          </p>
        </div>

        <div>
          <h5>Wyszukiwanie badań <span class="status pill pill-green">Aktywne</span></h5>
          <p>Zbadani.pl automatycznie dodają badania
          z współpracujących placówek medycznych
          do Twojej teczki.</p>
        </div>

        <div>
          <button class="btn btn-primary btn-md fullwidth" (click)="uploadExamFromDisc()">{{'PatientsPortal.DashboardScreen.AddFromComputer'
            |translate}}</button>
        </div>
      </div>

    </div>
  </div>

</div>
<app-patient-share-exam-modal #shareExamModal [isFolderSharing]="true" [shareInfo]="folderShareInfo" (refreshShareInfo)="refreshShareInfo()"></app-patient-share-exam-modal>
<app-patient-exam-upload-modal #patientExamUploadModal></app-patient-exam-upload-modal>
<app-cloud-agreement-modal #cloudAgreementModal (cloudAgreementChanged)="patientCloudAgreement = $event"></app-cloud-agreement-modal>
