import { NavigationService } from './navigation.service';
import { EnvService } from '@/services/env.service';
import { EventEmitter } from '@angular/core';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';
import { UsersService } from './users.service';
import moment from 'moment';
import { initRefreshOrdersStatus } from '@/interfaces';
import { OrderDescriptionService } from './order-description.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "./session.service";
import * as i3 from "./users.service";
import * as i4 from "./order-description.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./env.service";
import * as i7 from "./navigation.service";
var OrdersService = /** @class */ (function () {
    function OrdersService(odataService, sessionService, userService, descriptionService, http, env, navigationService) {
        this.odataService = odataService;
        this.sessionService = sessionService;
        this.userService = userService;
        this.descriptionService = descriptionService;
        this.http = http;
        this.env = env;
        this.navigationService = navigationService;
        this.orders = [];
        this.sendingExamId = 0;
        this.secondsLeft = 0;
        this.refreshOrdersStatus = initRefreshOrdersStatus;
        this.isSendEmitter = new EventEmitter();
        this.ordersArchive = [];
        this.age = [];
        this.ageArchive = [];
        this.dates = [];
        this.datesArchive = [];
        this.hours = [];
        this.hoursArchive = [];
        this.id = null;
        this.facilityId = null; // used for switching facilities
        this.numberOfOrders = new EventEmitter();
        this.numberOfArchiveOrders = new EventEmitter();
        this.dashboardLoader = null;
        this.archiveLoader = null;
        this.examDescriptionUuid = '';
        this.numberOfOrders.emit(0);
        this.numberOfArchiveOrders.emit(0);
    }
    OrdersService.prototype.getCoExamOrders = function (filter, offset, limit, filtrSearch) {
        var _this = this;
        if (this.dashboardLoader)
            this.dashboardLoader.show = true;
        this.orders = [];
        var params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: this.id,
            filtr: JSON.stringify(filter),
            offset: offset,
            limit: limit,
            filtr_search: filtrSearch ? filtrSearch : ''
        };
        var prom = this.odataService.postPrivate('get_co_exam_orders_ext', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK')
                _this.orders = _this.setOrders(result, filter, filtrSearch);
            if (_this.dashboardLoader)
                _this.dashboardLoader.show = false;
            return result.Status;
        });
        return prom;
    };
    OrdersService.prototype.getExamOrdersRefreshStatus = function () {
        var _this = this;
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            this.odataService.postPrivate('get_exam_orders_refresh_status', this.odataService.paramsTokenAndLang({
                id_describing_center: this.userService.getCenterId()
            }), function (res) {
                var returnedJSON = JSON.stringify(res);
                var result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    _this.refreshOrdersStatus = JSON.parse(result.Value);
                }
            });
        }
    };
    OrdersService.prototype.getExamOrdersArchive = function (filter, offset, limit, filtrSearch) {
        var _this = this;
        if (this.archiveLoader)
            this.archiveLoader.show = true;
        this.ordersArchive = [];
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.id,
            filtr: JSON.stringify(filter),
            offset: offset,
            limit: limit,
            filtr_search: filtrSearch ? filtrSearch : ''
        });
        var prom = this.odataService.postPrivate('get_exam_orders_archive_ext', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK')
                _this.ordersArchive = _this.setOrdersArchive(result, filter, filtrSearch);
            if (_this.archiveLoader)
                _this.archiveLoader.show = false;
            return result.Status;
        });
        return prom;
    };
    OrdersService.prototype.getExamDescriptionPdf = function (id) {
        var _this = this;
        return this.odataService.postPrivate('get_exam_description_pdf', this.odataService.paramsTokenAndLang({ agreement_value: id }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.examDescriptionUuid = JSON.parse(result.Value).exam_description_uuid;
            }
            return result.Status;
        });
    };
    OrdersService.prototype.downloadExamFile = function () {
        var headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
            'Authorization': 'Bearer ' + this.sessionService.getToken()
        });
        this.http.get(this.env.examDescPdfUrl + this.examDescriptionUuid, { responseType: 'blob', headers: headers }).subscribe(function (response) {
            var blob = new Blob([response], { type: 'application/pdf' });
            var url = window.URL.createObjectURL(blob);
            window.open(url, '_blank', 'location=0');
        });
    };
    OrdersService.prototype.setOrders = function (result, filter, filtrSearch) {
        var value = JSON.parse(result.Value || '[]');
        var orders = value.map(function (val) { return val.json_info; });
        this.clearOrdersData();
        this.numberOfOrders.emit(result.ValueCount);
        for (var i = 0; i < orders.length; i++) {
            this.checkSendingExams(orders[i]);
            this.setOrderData(orders[i]);
        }
        return orders;
    };
    OrdersService.prototype.setOrdersArchive = function (result, filter, filtrSearch) {
        var value = JSON.parse(result.Value || '[]');
        var ordersArchive = value.map(function (val) { return val.json_info; });
        this.clearOrdersArchiveData();
        this.getNumberOfOrdersArchive(filter, filtrSearch);
        for (var i = 0; i < ordersArchive.length; i++) {
            this.setOrderArchiveData(ordersArchive[i]);
            if (ordersArchive[i].description_date != null)
                ordersArchive[i].description_date = this.formatDate(ordersArchive[i].description_date);
            if (ordersArchive[i].reject_info.reject_time != null)
                ordersArchive[i].reject_info.reject_time = this.formatDate(ordersArchive[i].reject_info.reject_time);
        }
        return ordersArchive;
    };
    OrdersService.prototype.sortOrderActions = function (order) {
        order.actions_info.sort(function (a1, a2) {
            if (a1.action_importance > a2.action_importance)
                return -1;
            else if (a1.action_importance == a2.action_importance)
                return 0;
            else
                return 1;
        });
    };
    OrdersService.prototype.clearOrdersData = function () {
        this.orders = [];
        this.age = [];
        this.hours = [];
        this.dates = [];
    };
    OrdersService.prototype.clearOrdersArchiveData = function () {
        this.ordersArchive = [];
        this.ageArchive = [];
        this.hoursArchive = [];
        this.datesArchive = [];
    };
    OrdersService.prototype.setOrderData = function (order) {
        var orderData = this.getOrderDateHourAge(order);
        this.age.push(orderData['age']);
        this.hours.push(orderData['hour']);
        this.dates.push(orderData['date']);
        this.sortOrderActions(order);
    };
    OrdersService.prototype.setOrderArchiveData = function (orderArchive) {
        var orderData = this.getOrderDateHourAge(orderArchive);
        this.ageArchive.push(orderData['age']);
        this.hoursArchive.push(orderData['hour']);
        this.datesArchive.push(orderData['date']);
        this.sortOrderActions(orderArchive);
    };
    OrdersService.prototype.formatDate = function (date) {
        return moment(date).format('YYYY-MM-DD');
    };
    OrdersService.prototype.checkSendingExams = function (order) {
        var _this = this;
        if (order.status_info.status_name == 'TO_APPOVE_BY_TIME') {
            this.descriptionService.examId = order.id_exam_order;
            this.descriptionService.getExamInfo().then(function () {
                var seconds = (600 - moment(moment()).diff(_this.descriptionService.orderDescription.descriptionDate, 'seconds')) * 1000;
                if (!_this.sendingExamTimeout) {
                    _this.sendingExamId = order.id_exam_order;
                    _this.secondsLeft = seconds;
                    _this.sendingExamTimeout = setTimeout(function () {
                        _this.checkIfStillSending();
                    }, _this.secondsLeft);
                }
                else if (_this.secondsLeft > seconds) {
                    clearTimeout(_this.sendingExamTimeout);
                    _this.sendingExamId = order.id_exam_order;
                    _this.secondsLeft = seconds;
                    _this.sendingExamTimeout = setTimeout(function () {
                        _this.checkIfStillSending();
                    }, _this.secondsLeft);
                }
            });
        }
    };
    OrdersService.prototype.checkIfStillSending = function () {
        var _this = this;
        this.descriptionService.examId = this.sendingExamId;
        this.descriptionService.getExamInfo().then(function () {
            if (_this.descriptionService.orderDescription.status.codename == 'TO_APPOVE_BY_TIME') {
                _this.setIntervalForRefresh();
            }
            else
                _this.isSendEmitter.emit(true);
        });
    };
    OrdersService.prototype.setIntervalForRefresh = function () {
        var _this = this;
        var interval = setInterval(function () {
            _this.descriptionService.getExamInfo().then(function () {
                if (_this.descriptionService.orderDescription.status.codename != 'TO_APPOVE_BY_TIME') {
                    _this.isSendEmitter.emit(true);
                    clearInterval(interval);
                }
            });
        }, 10000);
    };
    OrdersService.prototype.getOrderDateHourAge = function (order) {
        moment.locale(this.userService.iLanguage);
        var date = this.getDateString(order.order_date);
        var hour = moment(order.order_date).fromNow();
        var age = this.descriptionService.calculateAgeFromDates(new Date(order.exam_info.birth_date), new Date());
        return { age: age, hour: hour, date: date };
    };
    OrdersService.prototype.getDateString = function (orderDate) {
        var orderMoment = moment(orderDate);
        var today = moment(new Date()).hours(23).minutes(59);
        var daysDiff = moment(orderDate).hours(0).minutes(0).diff(today, 'days');
        if (daysDiff === 0)
            return "Dzi\u015B, " + orderMoment.format('HH:mm');
        else if (daysDiff === -1)
            return "Wczoraj, " + orderMoment.format('HH:mm');
        return orderMoment.format('DD-MM-YYYY, HH:mm');
    };
    OrdersService.prototype.setId = function (newId) {
        this.id = newId;
    };
    OrdersService.prototype.setFacilityId = function (newId) {
        this.facilityId = newId;
    };
    OrdersService.prototype.patientsInitials = function (patientName) {
        if (!patientName) {
            return '•••• ••••';
        }
        var spaceIdx = patientName.indexOf(' ');
        if (!spaceIdx || spaceIdx >= patientName.length)
            return patientName[0] + '••••';
        return patientName[0] + "\u2022\u2022\u2022\u2022 " + patientName[spaceIdx + 1] + "\u2022\u2022\u2022\u2022";
    };
    OrdersService.prototype.tooltipInfo = function (or) {
        var tooltip = "";
        // let tooltip = `${or.exam_info.modality.modality} ${or.exam_info.anatomy.part_name}`;
        if (or.exam_info.ordering_facility_name)
            tooltip += "<strong>Zlecaj\u0105cy:</strong> " + or.exam_info.ordering_facility_name;
        if (or.exam_info.order_exam_type)
            tooltip += "<br /><strong>Nazwa badania:</strong> " + or.exam_info.order_exam_type;
        // if (or.status_info.status) tooltip += `<br/>Status: ${or.status_info.status}`;
        if (or.exam_info.exam_date)
            tooltip += "<br/><strong>Data badania:</strong> " + moment(or.exam_info.exam_date).format('DD-MM-YYYY HH:mm');
        // if (or.exam_info.evo_accession_number) tooltip += `<br/>Accn: ${or.exam_info.evo_accession_number}`;
        if (or.exam_info.exam_order_notes)
            tooltip += "<br/><br/><strong>Uwagi:</strong> " + or.exam_info.exam_order_notes;
        return tooltip;
    };
    OrdersService.prototype.yearsDeclinatedString = function (age) {
        var yrs = age % 10; // last digit of age
        if (age == 1)
            return 'OrderDescriptionScreens.Current.Years1';
        if (yrs > 1 && yrs < 5)
            return 'OrderDescriptionScreens.Current.Years2to4';
        if (yrs === 0 || yrs === 1 || yrs > 4)
            return 'OrderDescriptionScreens.Current.Years5to9';
    };
    OrdersService.prototype.getNumberOfOrdersArchive = function (filter, filtrSearch) {
        var _this = this;
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.id,
            filtr: JSON.stringify(filter),
            filtr_search: filtrSearch ? filtrSearch : ''
        });
        this.odataService.postPrivate('count_co_archive_exam', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.numberOfArchiveOrders.emit(parseInt(result.Value));
            }
        });
    };
    OrdersService.prototype.clearAllOrdersData = function () {
        this.orders = [];
        this.refreshOrdersStatus = initRefreshOrdersStatus;
        this.ordersArchive = [];
        this.age = [];
        this.ageArchive = [];
        this.dates = [];
        this.datesArchive = [];
        this.hours = [];
        this.hoursArchive = [];
        this.id = null;
    };
    OrdersService.ngInjectableDef = i0.defineInjectable({ factory: function OrdersService_Factory() { return new OrdersService(i0.inject(i1.OdataService), i0.inject(i2.SessionService), i0.inject(i3.UsersService), i0.inject(i4.OrderDescriptionService), i0.inject(i5.HttpClient), i0.inject(i6.EnvService), i0.inject(i7.NavigationService)); }, token: OrdersService, providedIn: "root" });
    return OrdersService;
}());
export { OrdersService };
