import { OdataService } from '@/services/odata.service';
import { initShareInfo } from '@/interfaces/PatientsPortal';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
var PatientPortalService = /** @class */ (function () {
    function PatientPortalService(odata) {
        this.odata = odata;
        this.folderShareInfo = { share_end_time: '', share_pin: '', share_status: 0 };
    }
    PatientPortalService.prototype.getExamList = function (limit, offset, filter, order_column) {
        return this.odata.postPrivate('pp_patient_exam_folder_list', this.odata.paramsTokenAndLang({
            order_column: order_column ? order_column : '',
            filtr: filter ? filter : '',
            offset: offset ? offset : 0,
            limit: limit
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                return JSON.parse(result.Value);
            }
            else
                return [];
        });
    };
    PatientPortalService.prototype.getExamDetails = function (examId, examType) {
        return this.odata.postPrivate('pp_patient_get_exam_info', this.odata.paramsTokenAndLang({
            id_exam: examId,
            id_type: examType
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                return JSON.parse(result.Value);
            }
            else
                return undefined;
        });
    };
    PatientPortalService.prototype.getExamDesc = function (examId, examType) {
        return this.odata.postPrivate('pp_patient_get_exam_description', this.odata.paramsTokenAndLang({
            id_exam: examId,
            id_type: examType
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                if (result.Value != '')
                    return JSON.parse(result.Value);
                else
                    return [];
            }
            else
                return [];
        });
    };
    PatientPortalService.prototype.getDescPDF = function (examId, examType, examOrder) {
        return this.odata.postPrivate('pp_get_exam_description_pdf', this.odata.paramsTokenAndLang({
            id_exam: examId,
            id_type: examType,
            id_exam_order: examOrder
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                if (result.Value != '')
                    return JSON.parse(result.Value);
                else
                    return undefined;
            }
            else
                return undefined;
        });
    };
    PatientPortalService.prototype.removeAccents = function (str) {
        var accents = 'ĄĘŚĆŁÓŻŹŃąęśćłóżźń';
        var accentsOut = 'AESCLOZZNaesclozzn';
        str = str.split('');
        var strLen = str.length;
        var i, x;
        for (i = 0; i < strLen; i++) {
            if ((x = accents.indexOf(str[i])) != -1) {
                str[i] = accentsOut[x];
            }
        }
        return str.join('');
    };
    PatientPortalService.prototype.getImageClassName = function (resource) {
        var etn = resource.exam_type.exam_type_name;
        var apt = resource.anatomy_part.anatomy_part_text;
        var exam_type_string;
        exam_type_string = etn.replace(/\s+/g, '-');
        exam_type_string = this.removeAccents(exam_type_string);
        exam_type_string = exam_type_string.toLowerCase();
        var anatomy_part_string;
        anatomy_part_string = apt.replace(/\s+/g, '-');
        anatomy_part_string = this.removeAccents(anatomy_part_string);
        anatomy_part_string = anatomy_part_string.toLowerCase();
        return exam_type_string + '-' + anatomy_part_string;
    };
    PatientPortalService.prototype.generateSharePin = function () {
        return this.odata.postPrivate('pp_patient_generate_share_pin', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK')
                return JSON.parse(result.Value).share_pin;
            else
                return 'ERROR';
        });
    };
    PatientPortalService.prototype.getFolderShareStatus = function () {
        var _this = this;
        return this.odata.postPrivate('pp_get_folder_share_status', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK')
                _this.folderShareInfo = JSON.parse(result.Value).folder_info;
            else
                _this.folderShareInfo = initShareInfo;
        });
    };
    PatientPortalService.prototype.shareExam = function (id_exam) {
        var _this = this;
        return this.generateSharePin().then(function (pin) {
            if (pin != 'ERROR')
                return _this.odata.postPrivate('pp_patient_share_exam', _this.odata.paramsTokenAndLang({
                    share_pin: pin,
                    id_exam: id_exam
                }), function (res) {
                    var returnedJson = JSON.stringify(res);
                    var result = JSON.parse(returnedJson);
                    return result.Status === 'OK';
                });
            else
                return false;
        });
    };
    PatientPortalService.prototype.shareFolder = function () {
        var _this = this;
        return this.generateSharePin().then(function (pin) {
            if (pin != 'ERROR')
                return _this.odata.postPrivate('pp_share_folder', _this.odata.paramsTokenAndLang({
                    share_pin: pin
                }), function (res) {
                    var returnedJson = JSON.stringify(res);
                    var result = JSON.parse(returnedJson);
                    if (result.Status === 'OK') {
                        _this.folderShareInfo = JSON.parse(result.Value).folder_info;
                        return true;
                    }
                    else
                        return false;
                });
            else
                return false;
        });
    };
    PatientPortalService.prototype.cancelSharingFolder = function () {
        return this.odata.postPrivate('pp_cancel_sharing_folder', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            return JSON.parse(result.Value).Status;
        });
    };
    PatientPortalService.prototype.cancelSharingExam = function (exam_id) {
        return this.odata.postPrivate('pp_patient_cancel_share_exam', this.odata.paramsTokenAndLang({ id_exam: exam_id }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            return JSON.parse(result.Value).Status;
        });
    };
    PatientPortalService.prototype.checkPasswordFromToken = function (password) {
        return this.odata.postPrivate('confirm_user_password', this.odata.paramsTokenAndLang({ password: password }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            return JSON.parse(result.Value).Status;
        });
    };
    PatientPortalService.ngInjectableDef = i0.defineInjectable({ factory: function PatientPortalService_Factory() { return new PatientPortalService(i0.inject(i1.OdataService)); }, token: PatientPortalService, providedIn: "root" });
    return PatientPortalService;
}());
export { PatientPortalService };
