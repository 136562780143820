<table class="tile-table" id="tile-table">
    <tr *ngFor="let or of orders; let j = index;" class="tile">

            <td style="padding-left: 0px !important;"
                [ngClass]="{
                                'red': (or.exam_info.priority.priority === 'EMERGENCY'),
                                'orange': (or.exam_info.priority.priority === 'CITO')
                            }" class="color-status">
            </td>

            <td><span class="modality">{{or.exam_info.modality.modality || 'modality'}}</span></td>
            <td *ngIf="type == 'Dashboard'">
                <span [innerHTML] = "statusDescriptionArray[j]"></span>
                <app-progress-bar *ngIf="or.status_info.status_name == 'RECEIVED_FROM_MF'" [id]="or.id_exam_order" [progress]="or.exam_info.transfer_progress+'%'"></app-progress-bar>
            </td>
            <td *ngIf="type == 'Patients'">
                <svg-icon *ngIf="or.status_info.status_name == 'REJECTED_BY_PHYSICIAN'" [svgStyle]="{'width.px': 18, 'height.px': 18}" alt="" class="svg" src="../../../assets/images/icons/returned.svg"></svg-icon>
                <span *ngIf="or.status_info.status_name == 'REJECTED_BY_PHYSICIAN'">&nbsp;&nbsp;&nbsp;</span>
                <span [innerHTML] = "statusDescriptionArray[j]"></span>
                <ng-container *ngIf="or.description_date != null"><br>{{or.description_date}}</ng-container>
                <ng-container *ngIf="or.reject_info.reject_time"><br>{{or.reject_info.reject_time}}</ng-container>
                <app-progress-bar *ngIf="or.status_info.status_name == 'RECEIVED_FROM_MF'" [id]="or.id_exam_order" [progress]="or.exam_info.transfer_progress+'%'"></app-progress-bar>
            </td>
            <td *ngIf="type == 'Archive'">
                <span [innerHTML]="statusDescriptionArray[j]"></span><br>
                <ng-container *ngIf="or.description_date != null">{{or.description_date}}</ng-container>
                <ng-container *ngIf="or.reject_info.reject_time">{{or.reject_info.reject_time}}</ng-container>
            </td>
            <td style="max-width: 230px; white-space: nowrap;">
                <strong>{{dates[j] || 'date'}} </strong>
                <span class="light">({{hours[j] || 'howlongago'}})</span><br/>
                <ng-container *ngIf="or.exam_info.ordering_facility_name">
                    <div class="copiedTooltip" [clipboard]="or.exam_info.ordering_facility_name" (copied)="copiedText = $event">

                        <ng-container *ngIf="or.exam_info.ordering_facility_name.length > 16">{{or.exam_info.ordering_facility_name | slice:0:16}}...</ng-container>
                        <ng-container *ngIf="or.exam_info.ordering_facility_name.length <= 16">{{or.exam_info.ordering_facility_name}}</ng-container>

                        <div class="tooltipContainer" style="top: -17px;">
                          <span *ngIf="copiedText == or.exam_info.ordering_facility_name" class="tooltip copied">{{'Copied' | translate}}</span>
                          <span *ngIf="copiedText != or.exam_info.ordering_facility_name" class="tooltip notCopied">{{'CopyText' | translate}}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!or.exam_info.ordering_facility_name">
                    <span class="light">Zbadani.pl</span>
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="or.exam_info.ordering_facility_name">
                    <strong><div class="copiedTooltip" [clipboard]="or.exam_info.patient_name || 'patientName'" (copied)="copiedText = $event">{{or.exam_info.patient_name || 'patientName'}}
                      <div class="tooltipContainer" style="top: -17px;">
                        <span *ngIf="copiedText == or.exam_info.patient_name" class="tooltip copied">{{'Copied' | translate}}</span>
                        <span *ngIf="copiedText != or.exam_info.patient_name" class="tooltip notCopied">{{'CopyText' | translate}}</span>
                      </div><ng-container *ngIf="type == 'Dashboard'">({{or.exam_info.sex || 'sex'}})</ng-container>
                    </div>
                    </strong>
                    <div class="copiedTooltip" [clipboard]="or.exam_info.pesel || 'pesel'" (copied)="copiedText = $event">
                      {{or.exam_info.pesel || 'pesel'}}<div class="tooltipContainer" style="top: -17px;">
                        <span *ngIf="copiedText == or.exam_info.pesel" class="tooltip copied">{{'Copied' | translate}}</span>
                        <span *ngIf="copiedText != or.exam_info.pesel" class="tooltip notCopied">{{'CopyText' | translate}}</span>
                      </div>, {{or.exam_info.birth_date || 'birthDate'}}
                      <span class="light">
                        ({{age[j] || 'age'}} {{ordersService.yearsDeclinatedString(age[j]) | translate}})
                      </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!or.exam_info.ordering_facility_name">
                    <strong>{{ordersService.patientsInitials(or.exam_info.patient_name)}}</strong><br/>
                    •••••••••••, {{or.exam_info.birth_date || 'birthDate'}}
                    <span class="light">
                      ({{age[j] || 'age'}} {{ordersService.yearsDeclinatedString(age[j]) | translate}})
                    </span>
                </ng-container>
            </td>
            <div class="tooltipContainer overTile" style="left: -70px; top: -20px;">
                <span class="tooltip" [innerHTML]="ordersService.tooltipInfo(or)"></span>
            </div>
            <td>
                <strong>{{or.exam_info.anatomy.part_name || 'examType'}}</strong><br>
                <ng-container *ngIf="or.exam_info.icd10">
                  <div class="copiedTooltip" [clipboard]="or.exam_info.evo_accession_number || 'accession_number'" (copied)="copiedText = $event">
                    <span>
                          {{or.exam_info.evo_accession_number || 'accession_number'}}<div class="tooltipContainer" style="top: -17px;">
                            <span *ngIf="copiedText == or.exam_info.evo_accession_number" class="tooltip copied">{{'Copied' | translate}}</span>
                            <span *ngIf="copiedText != or.exam_info.evo_accession_number" class="tooltip notCopied">{{'CopyText' | translate}}</span>
                          </div>, {{or.exam_info.icd10}}
                    </span></div>
                </ng-container>
                <ng-container *ngIf="!or.exam_info.icd10">
                        <span>{{or.exam_info.evo_accession_number || 'accession_number'}}</span>
                </ng-container>
            </td>
            <td class="right text-right">

                <ng-container *ngIf="or.assign_physician_info.physician_assign">
                    <strong>{{or.assign_physician_info.physician_assign}}</strong><br/>
                    <span *ngIf="userService.username == or.assign_physician_info.physician_assign"
                          class="light">
                          ({{'DashboardScreen.You' | translate}})</span>
                </ng-container>
                <!-- not assigned -->
                <ng-container *ngIf="!or.assign_physician_info.physician_assign">
                    <span class="light">({{'DashboardScreen.NotAssigned' | translate}})</span>
                </ng-container>

            </td>

            <td class="actions actions-full">
                <a *ngIf="type == 'Dashboard' && or.exam_info.consultation && or.exam_info.consultation.consultation_status == 3
                 && or.exam_info.consultation.consultation_content.ct_pneumonia.probability > CovidThreshold"
                 class="btn btn-sm btn-covid" (click)="makeAction('VIEW_AI_CONSULTATION', j, or.id_exam_order)">
                   <svg-icon  class="svg" src="../../../../assets/images/icons/warning_circle.svg" alt=""></svg-icon>
                    {{(or.exam_info.consultation.consultation_content.ct_pneumonia.probability * 100).toFixed(2)}}% COVID-19</a>
                <span *ngFor="let b of or.actions_info; let i = index;">
                    <a (click)="makeAction(b.action_name, j, or.id_exam_order)" *ngIf="i < 2" class="btn btn-light"
                       style="margin-right: 5px;">{{b.button_name}}</a>
                </span>
                <button [ngClass]="{'dotsClass': type == 'Patients'}" *ngIf="or.actions_info.length > 2" aria-expanded="false" aria-haspopup="true"
                        class="btn btn-light card-action" data-toggle="dropdown" id="ddAction1" type="button">
                    <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                </button>
                <div *ngIf="or.actions_info.length > 2" aria-labelledby="ddAction1"
                     class="dropdown-menu dropdown-menu-right">
                    <ng-container *ngFor="let b of or.actions_info; let i = index;">
                        <a (click)="makeAction(b.action_name, j, or.id_exam_order)" *ngIf="i >= 2" class="dropdown-item"
                           style="cursor: pointer;">{{b.button_name}}</a>
                    </ng-container>
                </div>
            </td>

            <td class="actions actions-medium">
                <a *ngIf="or.exam_info.consultation && or.exam_info.consultation.consultation_status == 3
                && or.exam_info.consultation.consultation_content.ct_pneumonia.probability > CovidThreshold"
                 class="btn btn-sm btn-covid" (click)="makeAction('VIEW_AI_CONSULTATION', j, or.id_exam_order)">
                   <svg-icon  class="svg" src="../../../../assets/images/icons/warning_circle.svg" alt=""></svg-icon>
                  {{(or.exam_info.consultation.consultation_content.ct_pneumonia.probability * 100).toFixed(2)}}% COVID-19</a>
                <span *ngFor="let b of or.actions_info; let i = index;">
                    <a (click)="makeAction(b.action_name, j, or.id_exam_order)" *ngIf="i < 1" class="btn btn-light"
                       style="margin-right: 5px;">{{b.button_name}}</a>
                </span>
                <button [ngClass]="{'dotsClass': type == 'Patients'}" *ngIf="or.actions_info.length > 1" aria-expanded="false" aria-haspopup="true"
                        class="btn btn-light card-action" data-toggle="dropdown" id="ddAction1" type="button">
                    <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                </button>
                <div *ngIf="or.actions_info.length > 1" aria-labelledby="ddAction1"
                     class="dropdown-menu dropdown-menu-right">
                    <ng-container *ngFor="let b of or.actions_info; let i = index;">
                        <a (click)="makeAction(b.action_name, j, or.id_exam_order)" *ngIf="i >= 1" class="dropdown-item"
                           style="cursor: pointer;">{{b.button_name}}</a>
                    </ng-container>
                </div>
            </td>

            <td class="actions actions-compact">
                <a *ngIf="or.exam_info.consultation && or.exam_info.consultation.consultation_status == 3
                && or.exam_info.consultation.consultation_content.ct_pneumonia.probability > CovidThreshold"
                   class="btn btn-sm btn-covid" (click)="makeAction('VIEW_AI_CONSULTATION', j, or.id_exam_order)">
                  <svg-icon  class="svg" src="../../../../assets/images/icons/warning_circle.svg" alt=""></svg-icon>
                  {{(or.exam_info.consultation.consultation_content.ct_pneumonia.probability * 100).toFixed(2)}}% COVID-19</a>
                <span *ngFor="let b of or.actions_info; let i = index;">
                    <a (click)="makeAction(b.action_name, j, or.id_exam_order)" *ngIf="i < 0" class="btn btn-light"
                       style="margin-right: 5px;">{{b.button_name}}</a>
                </span>
                <button [ngClass]="{'dotsClass': type == 'Patients'}" *ngIf="or.actions_info.length > 0" aria-expanded="false" aria-haspopup="true"
                        class="btn btn-light card-action" data-toggle="dropdown" id="ddAction1" type="button">
                    <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                </button>
                <div *ngIf="or.actions_info.length > 0" aria-labelledby="ddAction1"
                     class="dropdown-menu dropdown-menu-right">
                    <ng-container *ngFor="let b of or.actions_info; let i = index;">
                        <a (click)="makeAction(b.action_name, j, or.id_exam_order)" *ngIf="i >= 0" class="dropdown-item"
                           style="cursor: pointer;">{{b.button_name}}</a>
                    </ng-container>
                </div>
            </td>

        </tr>
</table>
