import { Component, OnInit } from '@angular/core';
import { UsersService, DisplayService } from '@/services';

@Component({
    selector: 'app-language-button',
    templateUrl: './language-button.component.html',
    styleUrls: ['./language-button.component.scss']
})
export class LanguageButtonComponent implements OnInit {

    language = '';

    constructor(private usersService: UsersService) {
    }

    changeLang() {
        this.usersService.changeLang();
        this.language = this.usersService.iLanguage;
    }

    ngOnInit() {
        this.language = this.usersService.iLanguage;
    }

    switchMode(): void {
        DisplayService.switchMode();
    }

}
