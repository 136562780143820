<div aria-hidden="true" aria-labelledby="editPermissionModalLabel" class="modal fade" id="doubleInputModal" role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex flex-column">
                <h4 class="modal-title d-block"><strong>{{title}}</strong></h4>
                <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-3 pb-5">
                <label for="roleName">{{label1}}</label>
                <textarea class="form-control" id="roleName" rows="1"></textarea>

                <label for="roleDescription">{{label2}}</label>
                <textarea class="form-control" id="roleDescription" rows="5"></textarea>
            </div>
            <div class="modal-footer pt-5">
                <button class="btn btn btn-danger" type="button">
                    {{'DoubleSelectModal.CancelChanges' | translate}}
                </button>
                <button class="btn btn btn-primary" type="button">
                    {{'DoubleSelectModal.ConfirmChanges' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
