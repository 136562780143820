<div #dropdown="bs-dropdown" (onHidden)="onHidden()" [insideClick]="true"
    [ngClass]="{'unread': notificationsCount != 0}"
    class="dropdown dropdown-notifications" dropdown>

    <button 
        *ngIf="type != 'pp'"
        aria-expanded="false" 
        aria-haspopup="true"
        class="btn btn-secondary dropdown-toggle e-notifications-dropdown"
        dropdownToggle id="ddNotifications" 
        type="button">
        <svg-icon [applyCss]="true" class="svg"
                  src="../../../../../assets/images/icons/infobar/icon__notification.svg"></svg-icon>
        <ng-container *ngIf="type == 'normal'">{{notificationsCount}}</ng-container>
        <ng-container *ngIf="type == 'full'">
            <ng-container *ngIf="notificationsCount == 0">Brak powiadomień</ng-container>
            <ng-container *ngIf="notificationsCount > 0">{{notificationsCount}} powiadomień</ng-container>
        </ng-container>
    </button>

    <button 
        *ngIf="type == 'pp'" 
        aria-expanded="false" 
        aria-haspopup="true"
        class="btn btn-secondary dropdown-toggle e-notifications-dropdown" 
        dropdownToggle id="ddNotifications"
        type="button">
        
        <ng-container *ngIf="notificationsCount == 0">Brak powiadomień</ng-container>
        <ng-container *ngIf="notificationsCount > 0">{{notificationsCount}} powiadomień</ng-container>

        <svg-icon [applyCss]="true" class="svg" src="../../../../../assets/images/icons/infobar/icon__notification.svg">
        </svg-icon>

    </button>

    <ul *dropdownMenu aria-labelledby="ddNotifications"
        class="dropdown-menu dropdown-menu-right notifications-list-container" role="menu">
        <div>
            <div class="row">
                <div class="col-md-8">
                    <h3>{{'DashboardScreen.Notifications' | translate}} ({{notificationsCount}})</h3>
                </div>
                <div class="col-md-4">
                    <button (click)="onClear ($event)" class="btn btn-light float-right"
                            style="position: relative; top: -4px;">
                        {{'DashboardScreen.Clear' | translate}}
                    </button>
                </div>
            </div>

            <li *ngFor="let mes of notifications">
                <app-short-notification (close)='dropdown.hide()' (extend)='onExtend ($event)' (read)='onRead ($event)'
                                        (remove)='onRemove ($event)' [notification]=mes></app-short-notification>
            </li>
            <li role="menuitem"><a (click)="dropdown.hide()" class="btn btn-link"
                                   routerLink="/notifications">{{'DashboardScreen.All' | translate}}</a></li>
        </div>
    </ul>
</div>