import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@/utils/datatable/datatable.component';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { PageService } from '@/services/page.service';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-order-history-for-center',
    templateUrl: './order-history-for-center.component.html',
    styleUrls: ['./order-history-for-center.component.css']
})
export class OrderHistoryForCenterComponent implements OnInit, OnDestroy {
    @ViewChild('datatable') datatable: DatatableComponent;
    @ViewChild('filters') filters: FiltersComponent;

    constructor(public envService: EnvService, private pageService: PageService) {
    }

    ngOnInit() {
        document.getElementById('orderHistory').setAttribute('class', 'active');
        this.pageService.setTranslatedPageTitle('OrderHistory.Title', 0);
    }

    ngOnDestroy() {
        document.getElementById('orderHistory').setAttribute('class', '');
        this.pageService.resetPageTitle();
    }

    dropdown() {
        this.filters.dropdown();
    }

}
