import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UsersService } from './users.service';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    timeout: any;
    messagesCount: number = 0;
    messagesMainEvent = new EventEmitter<{ id_message?: number, id_user?: number, action: string }>();
    messagesShortEvent = new EventEmitter<{ id_message?: number, id_user?: number, action: string }>();
    refreshTime: number = 60000;
    messageToShow: number = undefined;

    constructor(private router: Router, private userService: UsersService,
        private odataService: OdataService, private sessionService: SessionService) {
    }

    updateMessagesCount() {
        if (sessionStorage.getItem('ACCOUNT_ACTIVE') == null) {
            return;
        }

        this.odataService.postPrivate('get_unread_messages_count',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    this.messagesCount = result.Value;
                } else {
                    console.log('get_unread_messages_count:', result.Status);
                }
            });
    }

    updateCount(value: number) {
        this.messagesCount = value;
    }

    mainRemoved(id_message: number) {
        const emiter = {id_message: id_message, action: 'remove'};
        this.messagesShortEvent.emit(emiter);
    }

    shortRemoved(id_message: number) {
        const emiter = {id_message: id_message, action: 'remove'};
        this.messagesMainEvent.emit(emiter);
    }

    mainReadState(id_user: number) {
        const emiter = { id_user: id_user, action: 'read'};
        this.messagesShortEvent.emit(emiter);
    }

    shortReaded(id_user: number) {
        const emiter = { id_user: id_user, action: 'read'};
        this.messagesMainEvent.emit(emiter);
    }

    realoadList() {
        this.restarTimer();
        const emiter = {id_message: 0, action: 'reload'};
        this.updateMessagesCount();
        this.messagesShortEvent.emit(emiter);
        this.messagesMainEvent.emit(emiter);
    }

    starTimer() {
        this.restarTimer();
    }

    stopTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    private restarTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.realoadList();
        }, this.refreshTime);
    }

    showMessage(id_message: number) {
        this.messageToShow = id_message;
        this.router.navigate(['/messages']);
    }

    setRefreshTime(newTimeout: number) {
      this.refreshTime = newTimeout; // no need to restart - reload list starts with the reset
    }

    getMessageToShow (): number {
        const id_message: number = this.messageToShow;
        this.messageToShow = undefined;
        return id_message;
    }
}
