import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertComponent } from '@/utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private translate: TranslateService) {
    }

    formatHour(hour: string): string {
        let formatedHour: string = '';
        if (hour != null && hour != '') {
            if (hour.length == 1) {
                formatedHour = '0' + hour + ':00';
            } else if (hour.length == 2) {
                formatedHour = hour + ':00';
            } else if (hour.length == 4) {
                formatedHour = '0' + hour;
            } else {
                formatedHour = hour;
            }
            formatedHour += ':00';
        }
        return formatedHour;
    }

    getSpecializations(spec: Map<number, boolean>): string {
        let specSelects: NodeListOf<HTMLElement> = document.getElementsByName('select_spec');
        let newSpec = new Map<number, boolean>();
        let deletedSpec = new Map<number, boolean>();
        let result: string = '[';
        for (let i = 0; i < specSelects.length; ++i) {
            newSpec.set(parseInt((<HTMLInputElement>specSelects[i]).value), false);
        }

        spec.forEach((v, k) => {
            if (!newSpec.has(k)) deletedSpec.set(k, true);
        });

        let i: number = 0;
        newSpec.forEach((v, k) => {
            result += '{"id_specialization": ' + k + ', "deleted": ' + v + '}';
            ++i;
            if (newSpec.size != i) result += ', ';
        });

        if (newSpec.size != 0 && deletedSpec.size != 0) result += ', ';
        i = 0;
        deletedSpec.forEach((v, k) => {
            if (deletedSpec.size != i && i != 0) result += ', ';
            result += '{"id_specialization": ' + k + ', "deleted": ' + v + '}';
            ++i;
        });
        result += ']';
        return result;
    }

    deleteSpecialization(id: number): void {
        let select = <HTMLInputElement>document.getElementById('select' + id);
        select.parentElement.parentElement.parentElement.parentElement
            .removeChild(select.parentElement.parentElement.parentElement);
    }

    addDays(workingHoursForm: FormGroup, start: number): string {
        let working_hours: string = '';
        let stop: number = start + 6;
        let day: number = 0;
        working_hours += '[';
        for (let i = start; i <= stop; ++i) {
            let hour_start: string = this.formatHour(workingHoursForm.get('day' + i + 'Start').value);
            let hour_stop: string = this.formatHour(workingHoursForm.get('day' + i + 'Stop').value);

            if (hour_start == '' && hour_stop == '') {
                working_hours += '{"hour_stop": null, "hour_start": ' +
                    'null, "day_of_week": ' + day + '}';
            } else if (hour_start != '' && hour_stop != '') {
                working_hours += '{"hour_stop": "' + hour_stop + '", "hour_start": "' +
                    hour_start + '", "day_of_week": ' + day + '}';
            } else {
                // TODO
            }

            if (i != stop)
                working_hours += ', ';

            ++day;
        }

        working_hours += ']';

        return working_hours;
    }

    showAlertHours(errorAlertHours: AlertComponent, workingHoursForm: FormGroup, start: number): void {
        errorAlertHours.displayError('hours');
    }

    translateDays(days: string[]): void {
        for (let i = 0; i < 7; ++i) {
            this.translate.get('Profile.Day' + i).subscribe((res: string) => {
                days[i] = res;
            });
        }
    }
}
