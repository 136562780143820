import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionsArray, ClassMap, IAttachment } from '@/interfaces';
import { TextEditorComponent } from '@/utils/text-editor/text-editor.component';
import {
  DraftSenderService,
  OrderActionsService,
  OrderDescriptionService,
  OrdersService,
  UsersService
} from '@/services';
import { RejectModalComponent } from '@/utils/reject-modal/reject-modal.component';
import { AssignPhysicianModalComponent } from '@/utils/assign-physician-modal/assign-physician-modal.component';
import { Router } from '@angular/router';
import moment from 'moment';
import { AuthorizeDescriptionModuleComponent } from '@/utils/authorize-description-module/authorize-description-module.component';
import { Subscription } from 'rxjs';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { TimerLabelService } from '@/services/timer-label.service';
import { ReopenExamToastComponent } from '@/utils/reopen-exam-toast/reopen-exam-toast.component';
import { SendAttentionModalComponent } from '@/utils/send-attention-modal/send-attention-modal.component';
import { TakingDescriptionComponent } from '@/utils/taking-description/taking-description.component';
import { PdfNotAvailableModalComponent } from '@/utils/pdf-not-available-modal/pdf-not-available-modal.component';
import { OrderToCorrectModalComponent } from '@/utils/order-to-correct-modal/order-to-correct-modal.component';
import { OrderDescriptionTemplatesModalComponent } from '@/utils/order-description-templates-modal/order-description-templates-modal.component';
import { AttachmentViewModalComponent } from '@/utils/attachment-view-modal/attachment-view-modal.component';
import { InsuffCharsModalComponent } from '@/utils/insuff-chars-modal/insuff-chars-modal.component';
import { CovidAIStatistics, initCovidStats, setCovidStatistics } from '@/interfaces/AIConsultation';
import { IDescTemplate } from '@/interfaces/order-desc-template';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-current-description',
    templateUrl: './current-description.component.html',
    styleUrls: ['./current-description.component.css']
})
export class CurrentDescriptionComponent implements OnInit, OnDestroy {
    @ViewChild('text_editor') editor: TextEditorComponent;
    @ViewChild('rejectModal') rejectModal: RejectModalComponent;
    @ViewChild('assignPhysicianModal') assignPhysicianModal: AssignPhysicianModalComponent;
    @ViewChild('authorizeDescriptionModal') authorizeDescriptionModal: AuthorizeDescriptionModuleComponent;
    @ViewChild('saveAndSendAttentionExamModal') saveAndSendAttentionExamModal: SendAttentionModalComponent;
    @ViewChild('insuffCharsModal') insuffCharsModal: InsuffCharsModalComponent;
    @ViewChild('loader') loader: LoaderComponent;
    @ViewChild('savedToast') savedToast: UniversalToastComponent;
    @ViewChild('reopenToast') reopenToast: ReopenExamToastComponent;
    @ViewChild('takeoverModal') takeoverModal: TakingDescriptionComponent;
    @ViewChild('pdfModal') pdfModal: PdfNotAvailableModalComponent;
    @ViewChild('toCorrectModal') toCorrectModal: OrderToCorrectModalComponent;
    @ViewChild('attachmentViewModalComponent') attachmentModal: AttachmentViewModalComponent;
    @ViewChild('orderDescriptionTemplatesModal') descTemplateModal: OrderDescriptionTemplatesModalComponent;

    descEditor: any;
    dropdownTemplates: IDescTemplate[] = [];
    timerActive: boolean = false;
    isArray = Array.isArray;
    descriptionService: OrderDescriptionService;
    isSaved = false;
    canSend: boolean = false;
    copiedCovidDesc: string = '';

    btnColors: ClassMap = {
      'red': ['REJECT', 'REJECT_BY_PHYSICIAN', 'CANCEL_ASSIGMENT'],
      'grey': ['VIEW_EXAM_ORDER', 'VIEW_DESCRIPTION_PDF', 'CORRECT_DESCRIPTION', 'ASSIGN_PHYSICIAN',
        'ASSIGN_MYSELF', 'SAVE_DESCRIPTION_DRAFT', 'CHANGE_ASSIGMENT', 'REOPEN_DESCRIPTION'],
      'green': ['ASSIGN_AND_START_DESCRIPTION', 'SAVE_EXAM_DESCRIPTION_IN_TIME', 'SAVE_EXAM_DESCRIPTION', 'START_DESCRIPTION', 'START_DESCRIPTION_CORRECTION'],
    };

    coExamActionReject: Function = () => {
        this.rejectModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionReject(this.descriptionService.examId, value).then(
                    res => {
                        if (res) {
                            this.reloadData();
                            this.rejectModal.hide();
                            this.descriptionService.reloadExamView();
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    getCoExamActionViewExam: Function = () => {
        this.orderActions.viewExamOrder(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.reloadData();
                    this.descriptionService.reloadExamView();
                }
            }
        );
    }

    coExamActionAssignToPhysician: Function = () => {
        this.assignPhysicianModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        let retPromise = new Promise<string>((resolve) => {
            promise.then(
                (value) => {
                    this.orderActions.coExamActionAssignToPhysian(this.descriptionService.examId, value).then(
                        res => {
                            if (res) {
                                this.descriptionService.reloadData();
                                resolve('reload');
                            }
                        }
                    );
                }, () => {
                    // TODO with param reason
                });
        });

        retPromise.then(value => {
            if (value == 'reload') {
                this.reloadData();
            }
        });
    }

    coExamActionAssignAndStartDescription: Function = () => {
        this.orderActions.coExamActionAssignAndStartDescription(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.reloadData();
                    this.descriptionService.reloadExamDescription();
                }
            }
        );
    }

    coExamActionAssignToMe: Function = () => {
        let retPromise = new Promise<string>((resolve) => {
            this.orderActions.coExamActionAssignToMe(this.descriptionService.examId).then(
                res => {
                    if (res) {
                        this.reloadData();
                        this.descriptionService.reloadExamView();
                        resolve('reload');
                    }
                }
            );
        });

        retPromise.then((value) => {
            if (value == 'reload') {
                this.reloadData();
            }
        });
    }

    isEnoughChars(): boolean {
        let editorChars = this.getEditorChars();
        return editorChars > 10;
    }

    getEditorChars: Function = () => {
      return this.editor.getText().replace(/(<([^>]+)>)/ig, '').length;
    }

    submitExamDescription: Function = () => {

        if (this.isEnoughChars()) {
            this.unsubscribeSaveAndSend();
            if (this.descriptionService.isCheckedAttention) {
                this.saveAndSendAttentionExamModal.show();
                this.isSaveSubscription = this.saveAndSendAttentionExamModal.isSave.subscribe((res) => {
                    if (res) {
                        this.isSendSubscription = this.saveAndSendAttentionExamModal.isSend.subscribe((send) => {
                            this.saveAndSendAttentionExamModal.hide();
                            if (send) {
                                this.showAuthorizeModal(true);
                            } else this.showAuthorizeModal(false);
                        });
                    } else this.saveAndSendAttentionExamModal.hide();
                });
            } else {
                this.showAuthorizeModal(false);
            }
        } else {
            this.insuffCharsModal.show(this.getEditorChars());
        }
    }

    submitExamDescriptionInTime: Function = () => {

        if (this.isEnoughChars()) {
            this.unsubscribeSaveAndSend();
            if (this.descriptionService.isCheckedAttention) {
                this.saveAndSendAttentionExamModal.show();
                this.isSaveSubscription = this.saveAndSendAttentionExamModal.isSave.subscribe((res) => {
                    if (res) {
                        this.isSendSubscription = this.saveAndSendAttentionExamModal.isSend.subscribe((send) => {
                            this.saveAndSendAttentionExamModal.hide();
                            if (send) {
                                this.showAuthorizeModalInTime(true);
                            } else this.showAuthorizeModalInTime(false);
                        });
                    } else this.saveAndSendAttentionExamModal.hide();
                });
            } else {
                this.showAuthorizeModalInTime(false);
            }
        } else {
            this.insuffCharsModal.show(this.getEditorChars());
        }
    }

    coExamActionRejectByPhysician: Function = () => {
        this.rejectModal.show(this.descriptionService.getOrderDataForModal(), undefined, true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionRejectByPhysician(this.descriptionService.examId, value).then(
                    res => {
                        if (res) {
                            this.rejectModal.hide();
                            this.router.navigate(['/' + this.orderActions.cameFrom]);
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    saveExamDraft: Function = () => {
        if (this.draftSender.isChanged) {
            this.loader.show = true;
            if (!this.draftSavedSubscription) {
                this.draftSavedSubscription = this.descriptionService.draftSavedEmitter.subscribe((res) => {
                    if (res.exam_id == this.descriptionService.examId) {
                        if (res.success == true) {
                            this.savedToast.success('DraftSaved');
                            this.descriptionService.descriptionText = this.editor.getText();
                        } else {
                            this.savedToast.error('DraftNotSaved');
                        }
                        this.loader.show = false;
                        this.savedToast.show();
                        setTimeout(() => {
                            this.savedToast.hide();
                        }, 1500);
                    }
                });
            }
            this.descriptionService.saveExamDraft(this.editor.getText());
        }
    }

    coExamActionStartDescription: Function = () => {
        this.orderActions.coExamActionStartDescrption(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.reloadData();
                    this.descriptionService.reloadExamDescription();
                }
            }
        );
    }

  coExamActionStartDescriptionCorrection: Function = () => {
    this.orderActions.coExamActionStartDescriptionCorrection(this.descriptionService.examId).then(
      res => {
        if (res) {
          this.reloadData();
          this.descriptionService.reloadExamDescription();
        }
      }
    );
  }

    reopenExamDesc: Function = () => {
        this.orderActions.coExamReopenDescription(this.descriptionService.examId).then(
            res => {
                if (res) {
                    this.reloadData();
                    this.descriptionService.reloadExamDescription();
                } else {
                    this.reopenToast.show();
                    setTimeout(() => {
                        this.reopenToast.hide();
                        this.router.navigate(['/dashboard']);
                    }, 1500);
                }
            }
        );
    }

    coExamCancelAssigment: Function = () => {
        this.rejectModal.show(this.descriptionService.getOrderDataForModal(), true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        let assignedId: number = this.descriptionService.orderDescription.physicianAssigned.id;
        promise.then(
            (value) => {
                this.orderActions.coExamActionCancelAssigment(this.descriptionService.examId, value).then(
                    res => {
                        if (res) {
                            this.rejectModal.hide();
                            if (assignedId == this.userService.userId) this.router.navigate(['/' + this.orderActions.cameFrom]);
                            else {
                                this.descriptionService.reloadData();
                                this.reloadData();
                            }
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    coExamChangeAssigment: Function = () => {
        this.assignPhysicianModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        let retPromise = new Promise<string>((resolve) => {
            promise.then(
                (value) => {
                    this.orderActions.coExamActionChangeAssigment(this.descriptionService.examId, value).then(
                        res => {
                            if (res) {
                                this.descriptionService.reloadData();
                                resolve('reload');
                            }
                        }
                    );
                }, () => {
                    // TODO
                });
        });

        retPromise.then(value => {
            if (value == 'reload') {
                this.reloadData();
            }
        });
    }

    getExamDescriptionPdf: Function = () => {
        this.ordersService.getExamDescriptionPdf(this.descriptionService.examId).then(
            (res) => {
                if (res == 'OK') {
                    if (this.ordersService.examDescriptionUuid == null) {
                        this.pdfModal.show();
                    } else {
                        this.ordersService.downloadExamFile();
                    }
                }
            }
        );
    }

    coExamDescriptionToCorrect: Function = () => {
        this.toCorrectModal.show(this.descriptionService.getOrderDataForModal());
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.toCorrectModal.setPromiseResolve(resolve);
            this.toCorrectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionOrderToCorrect(this.descriptionService.examId, value).then(
                    res => {
                        this.toCorrectModal.hide();
                        if (res) {
                            this.descriptionService.reloadData();
                            this.reloadData();
                        } else {
                            this.reopenToast.show();
                            setTimeout(() => {
                                this.reopenToast.hide();
                                this.router.navigate(['/dashboard']);
                            }, 1500);
                        }
                    }
                );
            }, () => {
                // TODO
            });
    }

    actionsArray: ActionsArray = {
        'REJECT': this.coExamActionReject,
        'REJECT_BY_PHYSICIAN': this.coExamActionRejectByPhysician,
        'START_DESCRIPTION': this.coExamActionStartDescription,
        'START_DESCRIPTION_CORRECTION': this.coExamActionStartDescriptionCorrection,
        'VIEW_EXAM_ORDER': this.getCoExamActionViewExam,
        'ASSIGN_PHYSICIAN': this.coExamActionAssignToPhysician,
        'ASSIGN_AND_START_DESCRIPTION': this.coExamActionAssignAndStartDescription,
        'ASSIGN_MYSELF': this.coExamActionAssignToMe,
        'SAVE_EXAM_DESCRIPTION': this.submitExamDescription,
        'SAVE_EXAM_DESCRIPTION_IN_TIME': this.submitExamDescriptionInTime,
        'SAVE_DESCRIPTION_DRAFT': this.saveExamDraft,
        'REOPEN_DESCRIPTION': this.reopenExamDesc,
        'CANCEL_ASSIGMENT': this.coExamCancelAssigment,
        'CHANGE_ASSIGMENT': this.coExamChangeAssigment,
        'VIEW_DESCRIPTION_PDF':  this.getExamDescriptionPdf,
        'CORRECT_DESCRIPTION': this.coExamDescriptionToCorrect
    };

    interval: any;

    textChangesSubscription: Subscription;
    authorizeSubscription: Subscription;
    isSendSubscription: Subscription;
    isSaveSubscription: Subscription;
    draftSavedSubscription: Subscription = null;
    insertToEditorSubscription: Subscription;
    templateListUpdate: Subscription;
    timeOutEvent: Subscription;
    covidStatistics: CovidAIStatistics = initCovidStats;


    constructor(descriptionService: OrderDescriptionService, private orderActions: OrderActionsService,
                private router: Router, public timerLabel: TimerLabelService, private userService: UsersService,
                public draftSender: DraftSenderService, private ordersActions: OrderActionsService,
                public ordersService: OrdersService, private env: EnvService) {
        this.descriptionService = descriptionService;
    }

    ngOnInit() {
        this.loader.show = true;
        this.interval = setInterval(() => {
            if (this.descriptionService.mode.mode == 'description' && this.draftSender.isChanged && this.editor.getText() != '' && !$('#orderDescriptionTemplatesModal').hasClass('show')) {
                this.descriptionService.saveExamDraft(this.editor.getText());
            }
        }, 30000);
        this.loadData();
        this.subscribeToEvent();
        this.savedToast.hide();
        this.templateListUpdate = this.descTemplateModal.templateListChangedEvent.subscribe(() => this.getTemplates());
        this.insertToEditorSubscription = this.descTemplateModal.insertToEditorEvent.subscribe(e => setTimeout(() => this.insert(e), 200));
    }

    ngOnDestroy() {
        this.insertToEditorSubscription.unsubscribe();
        this.templateListUpdate.unsubscribe();
        clearInterval(this.interval);

        this.textChangesSubscription.unsubscribe();
        if (this.authorizeSubscription != undefined) {
            this.authorizeSubscription.unsubscribe();
        }
        if (this.timeOutEvent != undefined) {
            this.timeOutEvent.unsubscribe();
        }
        this.unsubscribeSaveAndSend();
        if (((this.draftSender.isChanged && this.editor.getText() != '') || this.isSaved)
            && this.router.url == '/orderDescription/' + this.descriptionService.examId + '/orderDescriptionComparison?mode=description') {
            sessionStorage.setItem('tmpDescText', this.editor.getText());
        }
        this.descriptionService.descriptionText = '';

        if (this.draftSavedSubscription) this.draftSavedSubscription.unsubscribe();
    }

    subscribeToEvent(): void {
        this.textChangesSubscription = this.editor.textEmitter.subscribe((e) => {
            this.descriptionService.text = e;
            if (this.descriptionService.descriptionText != e) {
                this.draftSender.isSaved = false;
                this.draftSender.isChanged = true;
            } else {
                this.draftSender.isSaved = true;
                this.draftSender.isChanged = false;
            }
            this.canSend = this.isEnoughChars();
        });
    }

    showAuthorizeModal(isSendAttention: boolean) {
        if (this.authorizeSubscription) {
            this.authorizeSubscription.unsubscribe();
        }
        this.descriptionService.isCheckedAttention = isSendAttention;
        this.authorizeDescriptionModal.show();
        this.descriptionService.saveExamDraft(this.editor.getText());
        this.cancelDraftSavedSubscription();
        this.authorizeSubscription = this.authorizeDescriptionModal.isAuthorize.subscribe((e) => {
            if (e) {
                this.authorizeDescriptionModal.hide();
                this.savedToast.success('SendingInProgress', 'AutoRedirecting');
                this.savedToast.show();
                this.descriptionService.submitExamDescription(this.editor.getText()).then((res) => {
                    if (res) {
                        this.savedToast.hide();
                        this.router.navigate([this.ordersActions.cameFrom]);
                    } else {
                        this.savedToast.error('SaveExamError');
                        setTimeout(() => this.savedToast.hide(), 2000);
                    }
                });
            }
        });
    }

    showAuthorizeModalInTime(isSendAttention: boolean) {
        if (this.authorizeSubscription) {
            this.authorizeSubscription.unsubscribe();
        }
        this.descriptionService.isCheckedAttention = isSendAttention;
        this.authorizeDescriptionModal.show();
        this.descriptionService.saveExamDraft(this.editor.getText());
        this.cancelDraftSavedSubscription();
        this.authorizeSubscription = this.authorizeDescriptionModal.isAuthorize.subscribe((e) => {
            if (e) {
                this.authorizeDescriptionModal.hide();
                this.savedToast.success('QueuingExam', 'SendingInProgressInTime');
                this.savedToast.show();
                this.descriptionService.submitExamDescriptionInTime(this.editor.getText()).then((res) => {
                    if (res) {
                        this.showTimer(600);
                        this.savedToast.hide();
                        this.router.navigate([this.ordersActions.cameFrom]);
                    } else {
                        this.savedToast.error('SaveExamInTimeError');
                        setTimeout(() => this.savedToast.hide(), 2000);
                    }
                });
            }
        });
    }

    handleTimer(): void {
        if (this.descriptionService.orderDescription.status.codename == 'IN_PROGRESS') {
            this.timerLabel.endCountdown();
            this.timerActive = false;
        } else if (this.descriptionService.orderDescription.status.codename == 'TO_APPOVE_BY_TIME') {
            let seconds = moment(moment()).diff(this.descriptionService.orderDescription.descriptionDate, 'seconds');
            this.showTimer(seconds);
        }
    }

    unsubscribeSaveAndSend() {
        if (this.isSendSubscription) {
            this.isSendSubscription.unsubscribe();
        }
        if (this.isSaveSubscription) {
            this.isSaveSubscription.unsubscribe();
        }
    }

    loadData(): void {
        this.assignPhysicianModal.get_co_describing_physicians(this.descriptionService.examId);
        this.descriptionService.getExamActions();
        let lockPromise = this.getExamLock();
        if (sessionStorage.getItem('tmpDescText')) {
            this.editor.text = sessionStorage.getItem('tmpDescText');
            sessionStorage.removeItem('tmpDescText');
            this.isSaved = true;
        }
        Promise.all([this.descriptionService.actionsPromise, this.descriptionService.descriptionPromise, lockPromise]).then(() => {
            if (this.descriptionService.orderDescription.exam_info.consultation) this.covidStatistics = setCovidStatistics(undefined, this.descriptionService.orderDescription);
            if (this.descriptionService.orderDescription.status.codename == 'REJECTED') {
                this.descriptionService.descriptionText = this.descriptionService.orderDescription.rejectInfo.rejectReason;
                this.descriptionService.isRejected = true;
            } else {
                this.descriptionService.getExamDescriptionOrDraft().then(() => {
                     if (!this.isSaved) this.editor.text = this.descriptionService.descriptionText;
                     this.canSend = this.isEnoughChars();
                     this.isSaved = false;
                });
            }
            this.getTemplates();
            this.handleTimer();
            this.loader.show = false;
        });

        sessionStorage.setItem('currentPage', `orderDescription/${this.descriptionService.examId}/currentDescription`);
    }

    getExamLock(): Promise<boolean> {
        let lockPromise: Promise<boolean>;
        if (this.descriptionService.mode.mode == 'view') {
            lockPromise = new Promise<boolean>((resolve) => { resolve(true); });
        } else {
            lockPromise = this.descriptionService.getExamLock()
                .then((isLock) => {
                    if (!isLock) {
                        return this.takeoverModal.handleLockTakeover().then((isStillLock) => {
                            if (!isStillLock) {
                                this.descriptionService.mode = {
                                    mode: 'view',
                                    code: 0
                                };
                                sessionStorage.setItem('mode', 'view');
                                this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/currentDescription'], { queryParams: { mode: 'view' } });
                            }

                            return isStillLock;
                        });
                    } else {
                        return true;
                    }
                });
        }

        return lockPromise;
    }

    reloadData(): void {
        this.descriptionService.getExamInfo();
        this.loadData();
    }

    showTimer(second: number): void {
        second = 600 - second;
        let minutes = Math.floor(second / 60);
        let seconds = second - (minutes * 60);
        this.timerActive = true;
        this.timerLabel.doneLabel = 'Wysłano';
        this.timerLabel.startCountdown(minutes, seconds);
        this.timeOutEvent = this.timerLabel.timeOutEmitter.subscribe((e) => {
            if (e) {
                this.checkIfStillSending();
            }
        });
    }

    checkIfStillSending() {
        this.descriptionService.getExamInfo().then(() => {
            if (this.descriptionService.orderDescription.status.codename == 'TO_APPOVE_BY_TIME') {
                this.setIntervalForRefresh();
            } else this.descriptionService.reloadExamView();
        });
    }

    setIntervalForRefresh() {
        let interval = setInterval(() => {
            this.descriptionService.getExamInfo().then(() => {
                if (this.descriptionService.orderDescription.status.codename != 'TO_APPOVE_BY_TIME') {
                    this.descriptionService.reloadExamView();
                    clearInterval(interval);
                }
            });
        }, 10000);
    }

    saveWithAttention() {
        this.descriptionService.saveExamDraft(this.editor.getText());
    }

    cancelDraftSavedSubscription(): void {
        if (!this.draftSavedSubscription) return;

        this.draftSavedSubscription.unsubscribe();
        this.draftSavedSubscription = null;
    }

    insert(value: string) {
      this.descEditor.execCommand('mceInsertContent', false, value);
    }

  openDescriptionTemplates() {
      this.descriptionService.saveExamDraft(this.editor.getText());
    this.descTemplateModal.show(this.descriptionService.orderDescription.info.modality.id, this.descriptionService.orderDescription.info.anatomyPart.id);
  }

  setEditor() {
    this.descEditor = this.editor.tinymce;
  }

   getTemplates() {
    this.descriptionService.getDescriptionTemplates(undefined, undefined, true, 5 ).then(res => {
      this.dropdownTemplates = res;
    });
  }

  onTemplateClicked(template: IDescTemplate) {
    this.insert(template.content);
    this.descriptionService.descriptionTemplateUsed(template.id_template);
  }
}
