import { IAction } from '../../../../interfaces/Order';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { InviteToCooperationModalComponent } from '@/utils/invite-to-cooperation-modal/invite-to-cooperation-modal.component';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { SearchComponent } from '@/utils/search/search.component';
import { SendMessageToUserModalComponent } from '@/utils/send-message-to-user-modal/send-message-to-user-modal.component';
import { SendApplicationToCoModalComponent } from '@/utils/send-application-to-co-modal/send-application-to-co-modal.component';
import { Subscription } from 'rxjs';
import { Profile, IProfileAction } from '@/interfaces';
import { PageService } from '@/services/page.service';
import { OdataService, UsersService, DCenter, SessionService, NavigationService } from '@/services';
import { EnvService } from '@/services/env.service';
import { Router } from '@angular/router';

interface CenterProfile {
  name: string;
  email: string;
  address: string;
  action_list: IAction[];
  address_city: string;
  busines_type: string;
  phone_number: string;
  address_zip_code: string;
  id_describing_center: number;
  image_uuid: string;
}

@Component({
  selector: 'app-doctors-and-co-profiles',
  templateUrl: './doctors-and-co-profiles.component.html',
  styleUrls: ['./doctors-and-co-profiles.component.scss']
})
export class DoctorsAndCoProfilesComponent implements OnInit, OnDestroy {

    @ViewChild('inviteToCooperationModal') inviteToCooperationModal: InviteToCooperationModalComponent;
    @ViewChild('actionToast') actionToast: UniversalToastComponent;
    @ViewChild('pagination') pagination: PaginationComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('sendMessage') sendMessage: SendMessageToUserModalComponent;
    @ViewChild('sendApplication') sendApplication: SendApplicationToCoModalComponent;

    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    searchSubscription: Subscription;

    profiles: Profile[] = [];
    countOfVisibleCenters: number[] = [];
    dcProfiles: CenterProfile[] = [];
    profilesCount: number = 0;
    dcProfilesCount: number = 0;
    isPhysicians: boolean = true;

    navigationService: NavigationService;

    constructor(private pageService: PageService, private odataService: OdataService, private usersService: UsersService,
                public envService: EnvService, private router: Router, private sessionService: SessionService,  navigationService: NavigationService) {
      this.navigationService = navigationService;
    }

    ngOnInit() {
        this.checkCurrentPage();
        this.searchEvent();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();

        let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);

        if (!this.isPhysicians) {
          this.getDcProfiles(offset, parseInt(this.pagination.itemsOnPage)).then(
            () => {
              this.getDcProfilesCount().then(
                () => {
                  this.setNumberOfPages();
                }
              );
            }
          );
        } else {
          this.getPhysiciansProfiles(offset, parseInt(this.pagination.itemsOnPage)).then(
            () => {
              this.getPhysiciansProfilesCount().then(
                () => {
                  this.setNumberOfPages();
                }
              );
            }
          );
        }
        this.pageService.setTranslatedPageTitle('Profiles.Title', 0);
        document.getElementById('doctorsLink').setAttribute('class', 'active');

        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
          let body = document.getElementsByTagName('body')[0];
          body.classList.add('pp-portal');
        }
    }

    ngOnDestroy() {
        document.getElementById('doctorsLink').setAttribute('class', '');
        this.pageService.resetPageTitle();
        this.searchSubscription.unsubscribe();
        this.pageChangeSubscription.unsubscribe();
        this.itemsOnPageChangeSubscription.unsubscribe();

        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');
    }

    checkCurrentPage() {
      if (sessionStorage.getItem('goToDcProfile') || sessionStorage.getItem('inviteDc')) {
        this.isPhysicians = false;
        if (sessionStorage.getItem('dcProfilesActivePage') && sessionStorage.getItem('dcProfilesItemsOnPage')) {
          this.pagination.activePage = parseInt(sessionStorage.getItem('dcProfilesActivePage'));
          this.pagination.itemsOnPage = sessionStorage.getItem('dcProfilesItemsOnPage');
          sessionStorage.removeItem('dcProfilesActivePage');
          sessionStorage.removeItem('dcProfilesItemsOnPage');
        }
        sessionStorage.removeItem('inviteDc');
        sessionStorage.removeItem('goToDcProfile');
      } else {
        if (this.sessionService.getCurrentPage('doctorsProfiles'))
          this.pagination.activePage = this.sessionService.getCurrentPage('doctorsProfiles');
        if (this.sessionService.getItemsOnPage('doctorsProfiles'))
          this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('doctorsProfiles');
      }
    }

    showTab(tab: string) {
      this.search.filterSearch = '';
      this.search.isSearch = false;
      $('#searchInput').val('');
      document.getElementById('clearSearchButton').style.display = 'none';
      if (tab == 'dc' && this.isPhysicians) {
        this.profiles = [];
        this.isPhysicians = false;
        this.search.setPlaceholder(this.getSearchPlaceholder());
        this.getDcProfiles(0, parseInt(this.pagination.itemsOnPage)).then(
          () => {
            this.getDcProfilesCount().then(
              () => {
                this.setNumberOfPages();
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
              }
            );
          }
        );
      } else if (tab == 'physicians' && !this.isPhysicians) {
        this.dcProfiles = [];
        this.isPhysicians = true;
        this.search.setPlaceholder(this.getSearchPlaceholder());
        this.getPhysiciansProfiles(0, parseInt(this.pagination.itemsOnPage)).then(
          () => {
            this.getPhysiciansProfilesCount().then(
              () => {
                this.setNumberOfPages();
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
              }
            );
          }
        );
      }
    }

    getPhysiciansProfiles(offset: number, limit: number): Promise<any> {
        return this.odataService.postPrivate('patient_search_physician_profiles',
            this.odataService.paramsTokenAndLang({
                offset: offset,
                limit: limit,
                search_text: this.search.isSearch ? this.search.filterSearch : ''
            }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                  this.profiles = JSON.parse(result.Value || '[]');
                  this.countOfVisibleCenters = [];
                  for (let i: number = 0; i < this.profiles.length; ++i) {
                      this.countOfVisibleCenters.push(0);
                      this.profiles[i].action_list.sort(this.compareActions);
                  }
                }
                return result.Status;
            });
    }

    getPhysiciansProfilesCount(): Promise<any> {
        return this.odataService.postPrivate('count_patient_search_physician_profiles',
            this.odataService.paramsTokenAndLang({
                search_text: this.search.isSearch ? this.search.filterSearch : ''
            }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                  this.profilesCount = parseInt(result.Value);
                }
                return result.Status;
            });
    }

    getDcProfiles(offset: number, limit: number): Promise<any> {
        return this.odataService.postPrivate('patient_search_dc_profiles',
            this.odataService.paramsTokenAndLang({
                offset: offset,
                limit: limit,
                search_text: this.search.isSearch ? this.search.filterSearch : ''
            }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                  this.dcProfiles = JSON.parse(result.Value || '[]');
                  for (let i: number = 0; i < this.dcProfiles.length; ++i) {
                      this.dcProfiles[i].action_list.sort(this.compareActions);
                  }
                }
                return result.Status;
            });
    }

    getDcProfilesCount(): Promise<any> {
        return this.odataService.postPrivate('count_patient_search_dc_profiles',
            this.odataService.paramsTokenAndLang({
                search_text: this.search.isSearch ? this.search.filterSearch : ''
            }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                  this.dcProfilesCount = parseInt(result.Value);
                }
                return result.Status;
            });
    }

    compareActions(a1: IProfileAction, a2: IProfileAction): number {
        if (a1.id_action == a2.id_action) return 0;
        return a1.id_action > a2.id_action ? 1 : -1;
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.pagination.changePage(page));
    }

    setNumberOfPages() {
        this.pagination.pages = [];
        if (this.isPhysicians) this.pagination.numberOfPages = this.profilesCount / parseInt(this.pagination.itemsOnPage);
        else this.pagination.numberOfPages = this.dcProfilesCount / parseInt(this.pagination.itemsOnPage);
        Math.floor(this.pagination.numberOfPages);
        for (let i = 0; i < this.pagination.numberOfPages; i++) {
            this.pagination.pages.push(i + 1);
        }
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            () => {
              if (this.isPhysicians) {
                this.getPhysiciansProfiles((this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                parseInt(this.pagination.itemsOnPage)).then(
                  () => {
                    this.getPhysiciansProfilesCount().then(
                      () => {
                        this.setNumberOfPages();
                        this.pagination.inputPage = this.pagination.activePage + '';
                      }
                    );
                  }
                );
              } else {
                this.getDcProfiles((this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                parseInt(this.pagination.itemsOnPage)).then(
                  () => {
                    this.getDcProfilesCount().then(
                      () => {
                        this.setNumberOfPages();
                        this.pagination.inputPage = this.pagination.activePage + '';
                      }
                    );
                  }
                );
              }
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            () => {
              if (this.isPhysicians) {
                this.getPhysiciansProfiles(0, parseInt(this.pagination.itemsOnPage)).then(
                  () => {
                    this.getPhysiciansProfilesCount().then(
                      () => {
                        this.setNumberOfPages();
                        this.pagination.activePage = 1;
                        this.pagination.inputPage = '1';
                      }
                    );
                  }
                );
              } else {
                this.getDcProfiles(0, parseInt(this.pagination.itemsOnPage)).then(
                  () => {
                    this.getDcProfilesCount().then(
                      () => {
                        this.setNumberOfPages();
                        this.pagination.activePage = 1;
                        this.pagination.inputPage = '1';
                      }
                    );
                  }
                );
              }
            }
        );
    }

    makeAction(profile: any, actionName: string): void {
        if (actionName == 'VIEW PROFILE') {
          if (this.isPhysicians) {
            this.router.navigate(['/doctorsProfile/' + profile.id_physician]);
          } else {
            sessionStorage.setItem('goToDcProfile', 'true');
            sessionStorage.setItem('dcProfilesActivePage', this.pagination.activePage.toString());
            sessionStorage.setItem('dcProfilesItemsOnPage', this.pagination.itemsOnPage);
            this.router.navigate(['/publicCOProfile/' + profile.id_describing_center]);
          }
        } else if (actionName == 'SEND MESSAGE') {
            this.sendMessage.setUser(profile);
            this.sendMessage.show();
        } else if (actionName == 'PROFILE ACCEPT INVITATION') {
            this.reloadData();
        } else if (actionName == 'PROFILE SEND INVITATION') {
            this.inviteToCooperationModal.show(profile.physician_name, profile.id_physician, this.usersService.getCenterName());
        } else if (actionName == 'PROFILE SEND APPLICATION') {
            this.sendApplication.setCenter(profile.id_describing_center, profile.name);
            this.sendApplication.show();
      }
    }

    afterInvite(sent: boolean) {
        this.reloadData();
        if (sent) this.actionToast.success('OperationSuccess');
        else this.actionToast.error('OperationFailed');
        this.actionToast.show();
        setTimeout(() => this.actionToast.hide(), 1500);
    }

    reloadData(): void {
      if (this.isPhysicians) {
        this.getPhysiciansProfiles((this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
        parseInt(this.pagination.itemsOnPage)).then(
        () => {
          this.getPhysiciansProfilesCount().then(
            () => {
              this.setNumberOfPages();
            }
          );
        });
      } else {
        this.getDcProfiles((this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                    parseInt(this.pagination.itemsOnPage)).then(
                    () => {
                      this.getDcProfilesCount().then(
                        () => {
                          this.setNumberOfPages();
                        }
                      );
                    });
      }
    }

    getSearchPlaceholder(): string {
      return this.isPhysicians ? 'Profiles.SearchLPlaceholder' : 'Profiles.SearchCuPlaceholder';
    }

    getPhysicianDcList(index: number): string {
        let dcListString = '';
        this.setCountOfVisibleCenters(index);
        if (this.countOfVisibleCenters[index] == 0) {
            return dcListString;
        } else {
            for (let i = 0; i < this.countOfVisibleCenters[index]; i++) {
                dcListString += this.profiles[index].working_places[i].describing_center_name;
                if (i < this.countOfVisibleCenters[index] - 1) {
                    dcListString += ', ';
                }
                if (i == this.countOfVisibleCenters[index] - 1 && this.countOfVisibleCenters[index] < this.profiles[index].working_places.length) {
                    dcListString += ', ...';
                }
            }
        }
        return dcListString;
    }

    setCountOfVisibleCenters(i: number) {
        this.countOfVisibleCenters[i] = 0;
        if (this.profiles[i].working_places.length != 0) {
            let finalWidth = 0;
            document.getElementById('hiddenDcString' + this.profiles[i].id_physician).setAttribute('style', 'display: flex');
            for (let j = 0; j < this.profiles[i].working_places.length; j++) {
                finalWidth += document.getElementById('physicianDc' + this.profiles[i].id_physician + j).offsetWidth;
                if (finalWidth < document.getElementById('physicianDcList' + this.profiles[i].id_physician).offsetWidth) {
                    this.countOfVisibleCenters[i]++;
                } else {
                    break;
                }
            }
            document.getElementById('hiddenDcString' + this.profiles[i].id_physician).setAttribute('style', 'display: none');
        }
    }
}
