import { NavigationService } from '../../services';
import * as i0 from "@angular/core";
import * as i1 from "../../services/navigation.service";
var ContextGuardService = /** @class */ (function () {
    function ContextGuardService(navigationService) {
        this.navigationService = navigationService;
    }
    ContextGuardService.arrayToMap = function (array) {
        var map = new Map();
        array.forEach(function (element) {
            map.set(element.url, element.contexts);
        });
        return map;
    };
    ContextGuardService.prototype.canActivate = function (next, state) {
        var _this = this;
        if (this.navigationService.getCurrentContext() == '') {
            this.navigationService.navigateToDefault();
            return false;
        }
        if (state.url == '/registrationCo2') {
            if (!sessionStorage.getItem('newDcId')) {
                this.navigationService.navigateToDefault();
                return false;
            }
        }
        var url = state.url;
        var notFound = true;
        for (var i = 0; i < ContextGuardService.urlWithParams.length && notFound; ++i) {
            if (url.includes(ContextGuardService.urlWithParams[i])) {
                notFound = false;
                url = ContextGuardService.urlWithParams[i];
            }
        }
        var requiredContexts = [];
        if (!notFound) {
            requiredContexts = ContextGuardService.urlMap.get(url);
        }
        else
            requiredContexts = ContextGuardService.urlMap.get(state.url);
        if (requiredContexts != null) {
            var res_1 = false;
            if (requiredContexts.find(function (x) { return x == 'ADMINISTRATOR'; }) && this.navigationService.hasContext('ADMINISTRATOR')) {
                res_1 = true;
            }
            else {
                requiredContexts.forEach(function (element) {
                    if (_this.navigationService.getCurrentContext() == element) {
                        res_1 = true;
                    }
                });
            }
            if (!res_1) {
                this.navigationService.navigateToDefault();
                return false;
            }
        }
        return true;
    };
    ContextGuardService.urlAndContexts = [
        { url: '/serviceCatalog', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/patientsPortal', contexts: [] },
        { url: '/portal-pacjenta', contexts: ['PATIENT', 'PHYSICIAN'] },
        // {url: '/badania', contexts: ['PATIENT', 'PHYSICIAN']},
        { url: '/badania/lista-badan', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/badania/szczegoly', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/wizyty', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/uslugi', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/lekarze', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/twoje-konto', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/examinationDescription', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/orderHistory', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/emptyExams', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/getAccessForDc', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/dcProfiles', contexts: [] },
        { url: '/doctorsAndCoProfiles', contexts: ['PATIENT', 'PHYSICIAN'] },
        { url: '/publicDcProfile', contexts: [] },
        { url: '/beforeStartingWork', contexts: [] },
        { url: '/myProfile', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR'] },
        { url: '/registrationCo1', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT'] },
        { url: '/registrationCo2', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT'] },
        { url: '/messages', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR'] },
        { url: '/notifications', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT', 'ADMINISTRATOR'] },
        { url: '/orderDescription/currentDescription', contexts: ['DESCRIBING_CENTER'] },
        { url: '/orderDescription/consultation', contexts: ['DESCRIBING_CENTER'] },
        { url: '/orderDescription/orderDescriptionComparison', contexts: ['DESCRIBING_CENTER'] },
        { url: '/orderDescription', contexts: ['DESCRIBING_CENTER'] },
        { url: '/dashboard', contexts: ['DESCRIBING_CENTER'] },
        { url: '/patients', contexts: ['DESCRIBING_CENTER'] },
        { url: '/coProfile', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PHYSICIAN', 'PATIENT'] },
        { url: '/publicCOProfile', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PHYSICIAN', 'PATIENT'] },
        { url: '/doctorsProfile', contexts: ['DESCRIBING_CENTER', 'PATIENT', 'PHYSICIAN', 'ADMINISTRATOR'] },
        { url: '/teamSettings', contexts: ['DESCRIBING_CENTER'] },
        { url: '/roleSettings', contexts: ['DESCRIBING_CENTER'] },
        { url: '/permissionSettings', contexts: ['DESCRIBING_CENTER'] },
        { url: '/usersSettings', contexts: ['DESCRIBING_CENTER'] },
        { url: '/archive', contexts: ['DESCRIBING_CENTER'] },
        { url: '/profiles', contexts: ['DESCRIBING_CENTER'] },
        { url: '/invoices', contexts: ['DESCRIBING_CENTER'] },
        { url: '/balance', contexts: ['DESCRIBING_CENTER'] },
        { url: '/orderHistoryForCenter', contexts: ['DESCRIBING_CENTER'] },
        { url: '/manageMyFilters', contexts: ['DESCRIBING_CENTER'] },
        { url: '/medicalFacilityExams', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityArchive', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityDashboard', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityPatients', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityProfiles', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityRoleSettings', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityPermissionSettings', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityUsersSettings', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityTeamSettings', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityDownloads', contexts: ['MEDICAL_FACILITY'] },
        { url: '/medicalFacilityInvoices', contexts: ['MEDICAL_FACILITY'] }
    ];
    ContextGuardService.urlMap = ContextGuardService.arrayToMap(ContextGuardService.urlAndContexts);
    ContextGuardService.urlWithParams = [
        '/badania',
        '/wizyty',
        '/dashboard',
        '/archive',
        '/patients',
        '/orderDescription',
        '/publicCOProfile',
        '/doctorsProfile',
        '/publicDcProfile',
        '/medicalFacilityArchive',
        '/medicalFacilityExams',
        '/medicalFacilityDashboard',
        '/manageMyFilters'
    ];
    ContextGuardService.ngInjectableDef = i0.defineInjectable({ factory: function ContextGuardService_Factory() { return new ContextGuardService(i0.inject(i1.NavigationService)); }, token: ContextGuardService, providedIn: "root" });
    return ContextGuardService;
}());
export { ContextGuardService };
