export interface Examination {
    examinationId: string;
    status: string;
    modality: string;
    examinationName: string;
    date: string;
    centre: string;
    address: string;
    shortDescription: string;
    icd: string;
    icdDescription: string;
    descriptions: Description[];
}

export interface Description {
    status: string;
    date: string;
    dateOfOrder: string;
    mainDescription: string;
    physicianName: string;
    physicianSpecialization: string;
}

export let EXAMINATION: Examination = {
    examinationId: '2948264',
    modality: 'RTG',
    status: 'Opisane',
    examinationName: 'Staw biodrowy prawy',
    date: '01/03/2019 09:26',
    centre: 'SKANMED',
    address: 'ul. Koszykowa 63, Warszawa',
    shortDescription: 'Bóle biodra P od 3 m-cy dominujące w części przedniej. W załączeniu opis USG XI.2016, brak RTG do wglądu. Badanie MR stawów biodrowych, sekw. FSE/, PD, PD FAT SAT, STIR, w płaszczyznach czołowej i poprzecznej, strzałkowej, transagitalnej w osi długiej szyjki k. udowej prawej',
    icd: 'ICD10: W21.1',
    icdDescription: 'Uderzenie przez rzucony, wystający lub spadający przedmiot (w instytucji mieszkalnej)',
    descriptions: [
        {
            status: 'Dostępny',
            mainDescription: 'Anterowersja szyjek kości udowych i panewek stawowych w granicach normy. Szpary stawów biodrowych niezwężone. Prawidłowy stopień pokrycia głów kości udowych przez panewki stawowe.<br /><br />Po stronie prawej- drobne zaostrzenie przednio-dolnej krawędzi pogranicza głowowo-szyjkowego 3-4 mm z modelowaniem torebki stawowej. Obustronne zaostrzenia krawędzi panewek stawowych górnych.<br /><br />Po stronie prawej- odcinkowe uszkodzenie zwyrodnieniowe obrąbka przednio-górnego na długości 1,2 cm; zgrubienie, podwyższenie sygnału i możliwe drobne mineralizacje. Lokalnie niewielka hiperemia szpiku przednio-górnego brzegu panewki.<br /><br />Bez przemieszczenia obrąbka.Poza tym obrąbki stawowe obu stawów biodrowych bez widocznego uszkodzenia lub destabilizacji. Bez uszkodzenia chrzęstnokostnego powierzchni obciążanych. Prawidłowy sygnał szpiku. Bez wysięk w jamach stawów i w kaletkach. Bez patologicznego przerostu błony maziowej. Śladowy obrzęk pod pasmem biodrowo-piszczelowym wzdłuż kaletki krętarza większego prawego. Spojenie łonowe i stawy krzyżowo- biodrowe bezmian.<br /><br />Ścięgna i mięśnie w obszarze badania bez widocznej patologii, symetryczne.<br /><br />Narządy miednicy w polu widzenia bez zmian.',
            date: '03/03/2019',
            dateOfOrder: 'Wczoraj, 17:36',
            physicianName: 'dr Jolanta Masłowska',
            physicianSpecialization: 'specjalista radiolog'
        },
        {
            status: 'Dostępny',
            mainDescription: 'Jakiś inny opis.<br /><br /><br /><br />Anterowersja szyjek kości udowych i panewek stawowych w granicach normy. Szpary stawów biodrowych niezwężone. Prawidłowy stopień pokrycia głów kości udowych przez panewki stawowe.<br /><br />Po stronie prawej- drobne zaostrzenie przednio-dolnej krawędzi pogranicza głowowo-szyjkowego 3-4 mm z modelowaniem torebki stawowej. Obustronne zaostrzenia krawędzi panewek stawowych górnych.<br /><br />Po stronie prawej- odcinkowe uszkodzenie zwyrodnieniowe obrąbka przednio-górnego na długości 1,2 cm; zgrubienie, podwyższenie sygnału i możliwe drobne mineralizacje. Lokalnie niewielka hiperemia szpiku przednio-górnego brzegu panewki.<br /><br />Bez przemieszczenia obrąbka.Poza tym obrąbki stawowe obu stawów biodrowych bez widocznego uszkodzenia lub destabilizacji. Bez uszkodzenia chrzęstnokostnego powierzchni obciążanych. Prawidłowy sygnał szpiku. Bez wysięk w jamach stawów i w kaletkach. Bez patologicznego przerostu błony maziowej. Śladowy obrzęk pod pasmem biodrowo-piszczelowym wzdłuż kaletki krętarza większego prawego. Spojenie łonowe i stawy krzyżowo- biodrowe bezmian.<br /><br />Ścięgna i mięśnie w obszarze badania bez widocznej patologii, symetryczne.<br /><br />Narządy miednicy w polu widzenia bez zmian.',
            date: '04/03/2019',
            dateOfOrder: 'Wczoraj, 12:55',
            physicianName: 'dr Jan Kowalski',
            physicianSpecialization: 'specjalista kardiolog'
        },
        {
            status: 'W trakcie opisu',
            mainDescription: '',
            date: '05/03/2019',
            dateOfOrder: '6 minut temu',
            physicianName: 'dr Jolanta Masłowska',
            physicianSpecialization: 'specjalista radiolog'
        }
    ]
};
