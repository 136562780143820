import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { OrderDescriptionService, OrdersService, DraftSenderService, OdataService, UsersService, OrderActionsService } from '@/services';
import { SaveExamModalComponent } from '@/utils/save-exam-modal/save-exam-modal.component';
import { PageService } from '@/services/page.service';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { AttachmentViewModalComponent } from '@/utils/attachment-view-modal/attachment-view-modal.component';
import { initCovidStats, setCovidStatistics } from '@/interfaces/AIConsultation';
import { EnvService } from '@/services/env.service';
var OrderDescriptionComponent = /** @class */ (function () {
    function OrderDescriptionComponent(route, descriptionService, router, ordersActions, ordersService, pageService, draftSender, cdRef, odataService, userService, env) {
        this.route = route;
        this.router = router;
        this.ordersActions = ordersActions;
        this.ordersService = ordersService;
        this.pageService = pageService;
        this.draftSender = draftSender;
        this.cdRef = cdRef;
        this.odataService = odataService;
        this.userService = userService;
        this.env = env;
        this.ordersCount = 0;
        this.covidStatistics = initCovidStats;
        this.copiedText = '';
        this.descriptionService = descriptionService;
    }
    OrderDescriptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setCurrentRoute();
        this.descriptionService.isCheckedAttention = false;
        this.pageService.setTranslatedPageTitle('OrderDescriptionScreens.ExamDescription', 0);
        this.route.paramMap.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.route.queryParams
                    .subscribe(function (par) {
                    sessionStorage.setItem('mode', par.mode);
                    _this.descriptionService.mode.mode = par.mode;
                    _this.descriptionService.mode.code = par.mode == 'view' ? 0 : 1;
                });
                this.descriptionService.examId = +params.get('orderId');
                this.descriptionService.isViewDraft = false;
                this.descriptionService.isRejected = false;
                this.loadExamInfo().then(function () {
                    if (_this.descriptionService.orderDescription.exam_info.consultation)
                        _this.covidStatistics = setCovidStatistics(undefined, _this.descriptionService.orderDescription);
                    if (_this.descriptionService.mode.mode == 'description') {
                        _this.checkIfDescriptionModeAvailable();
                    }
                });
                return [2 /*return*/];
            });
        }); });
        this.cdRef.detectChanges();
    };
    OrderDescriptionComponent.prototype.goToDesc = function () {
        this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/currentDescription'], { queryParams: { mode: sessionStorage.getItem('mode') } });
    };
    OrderDescriptionComponent.prototype.checkIfDescriptionModeAvailable = function () {
        if (this.descriptionService.orderDescription.status.codename != 'IN_PROGRESS' ||
            this.descriptionService.orderDescription.physicianAssigned.id != this.userService.userId) {
            sessionStorage.setItem('mode', 'view');
            if (this.router.url.includes('currentDescription'))
                this.goToDesc();
            else
                this.navigateToComparasion();
        }
    };
    OrderDescriptionComponent.prototype.loadExamInfo = function () {
        var _this = this;
        return this.descriptionService.getExamInfo()
            .then(function () {
            _this.pageService.setPageTitle(_this.descriptionService.orderDescription.patient.name + ': ' +
                _this.descriptionService.orderDescription.patient.documentId, 0);
            _this.countOrdersForPatient();
            _this.orderInfoCardInput = _this.descriptionService.orderDescription;
        });
    };
    OrderDescriptionComponent.prototype.countOrdersForPatient = function () {
        var _this = this;
        var filter = FiltersComponent.createInitialIFiltrDashboard();
        var params = this.odataService.paramsTokenAndLang({
            id_exam_order: this.descriptionService.examId,
            patient_uuid: this.descriptionService.orderDescription.patientUuid
        });
        this.odataService.postPrivate('co_count_other_patient_described_exams', params, function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                _this.ordersCount = parseInt(result.Value);
            }
        });
    };
    OrderDescriptionComponent.prototype.ngOnDestroy = function () {
        $('div').remove('.modal-backdrop, .fade, .show');
        $('body').removeAttr('style');
        var body = document.getElementsByClassName('modal-open');
        if (body.item(0) != null) {
            body.item(0).setAttribute('class', 'h-100');
        }
        this.pageService.resetPageTitle();
    };
    OrderDescriptionComponent.prototype.canDeactivate = function () {
        if (this.descriptionService.mode.mode == 'description' &&
            !this.draftSender.isSaved && this.draftSender.isChanged) {
            this.saveExamModal.show();
            return this.saveExamModal.navigateAwaySelection;
        }
        else {
            return true;
        }
    };
    OrderDescriptionComponent.prototype.setCurrentRoute = function () {
        var _this = this;
        this.currentRoute = this.router.url.split('/').slice(-1)[0];
        this.router.events.forEach(function (event) {
            if (event instanceof NavigationStart) {
                _this.currentRoute = event.url.split('/').slice(-1)[0];
            }
        });
    };
    OrderDescriptionComponent.prototype.back = function () {
        if (this.descriptionService.mode.mode == 'description' &&
            !this.draftSender.isSaved && this.draftSender.isChanged) {
            this.saveExamModal.show();
            this.router.navigate([this.ordersActions.cameFrom]);
        }
        else {
            this.router.navigate([this.ordersActions.cameFrom]);
            this.draftSender.isSaved = false;
        }
    };
    OrderDescriptionComponent.prototype.navigateToComparasion = function () {
        this.router.navigate(['/orderDescription/' + this.descriptionService.examId + '/orderDescriptionComparison'], { queryParams: { mode: sessionStorage.getItem('mode') } });
    };
    OrderDescriptionComponent.prototype.showAttachment = function (attach) {
        if (attach.content_type.includes('pdf')) {
            window.open(this.env.examOrderAttUrl + attach.attachment_uuid, '_blank');
        }
        else {
            this.attachmentView.show(attach);
        }
    };
    OrderDescriptionComponent.prototype.cssCardColor = function () {
        var _a;
        var cardTypeClass = (_a = {},
            _a['APPROVED'] = 'green',
            _a['TO_APPOVE_IN_TIME'] = 'green',
            _a['CITO'] = 'orange',
            _a['EMERGENCY'] = 'red',
            _a);
        return cardTypeClass[this.descriptionService.orderDescription.priority.name] || 'green';
    };
    return OrderDescriptionComponent;
}());
export { OrderDescriptionComponent };
