import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var ProfileService = /** @class */ (function () {
    function ProfileService(translate) {
        this.translate = translate;
    }
    ProfileService.prototype.formatHour = function (hour) {
        var formatedHour = '';
        if (hour != null && hour != '') {
            if (hour.length == 1) {
                formatedHour = '0' + hour + ':00';
            }
            else if (hour.length == 2) {
                formatedHour = hour + ':00';
            }
            else if (hour.length == 4) {
                formatedHour = '0' + hour;
            }
            else {
                formatedHour = hour;
            }
            formatedHour += ':00';
        }
        return formatedHour;
    };
    ProfileService.prototype.getSpecializations = function (spec) {
        var specSelects = document.getElementsByName('select_spec');
        var newSpec = new Map();
        var deletedSpec = new Map();
        var result = '[';
        for (var i_1 = 0; i_1 < specSelects.length; ++i_1) {
            newSpec.set(parseInt(specSelects[i_1].value), false);
        }
        spec.forEach(function (v, k) {
            if (!newSpec.has(k))
                deletedSpec.set(k, true);
        });
        var i = 0;
        newSpec.forEach(function (v, k) {
            result += '{"id_specialization": ' + k + ', "deleted": ' + v + '}';
            ++i;
            if (newSpec.size != i)
                result += ', ';
        });
        if (newSpec.size != 0 && deletedSpec.size != 0)
            result += ', ';
        i = 0;
        deletedSpec.forEach(function (v, k) {
            if (deletedSpec.size != i && i != 0)
                result += ', ';
            result += '{"id_specialization": ' + k + ', "deleted": ' + v + '}';
            ++i;
        });
        result += ']';
        return result;
    };
    ProfileService.prototype.deleteSpecialization = function (id) {
        var select = document.getElementById('select' + id);
        select.parentElement.parentElement.parentElement.parentElement
            .removeChild(select.parentElement.parentElement.parentElement);
    };
    ProfileService.prototype.addDays = function (workingHoursForm, start) {
        var working_hours = '';
        var stop = start + 6;
        var day = 0;
        working_hours += '[';
        for (var i = start; i <= stop; ++i) {
            var hour_start = this.formatHour(workingHoursForm.get('day' + i + 'Start').value);
            var hour_stop = this.formatHour(workingHoursForm.get('day' + i + 'Stop').value);
            if (hour_start == '' && hour_stop == '') {
                working_hours += '{"hour_stop": null, "hour_start": ' +
                    'null, "day_of_week": ' + day + '}';
            }
            else if (hour_start != '' && hour_stop != '') {
                working_hours += '{"hour_stop": "' + hour_stop + '", "hour_start": "' +
                    hour_start + '", "day_of_week": ' + day + '}';
            }
            else {
                // TODO
            }
            if (i != stop)
                working_hours += ', ';
            ++day;
        }
        working_hours += ']';
        return working_hours;
    };
    ProfileService.prototype.showAlertHours = function (errorAlertHours, workingHoursForm, start) {
        errorAlertHours.displayError('hours');
    };
    ProfileService.prototype.translateDays = function (days) {
        var _loop_1 = function (i) {
            this_1.translate.get('Profile.Day' + i).subscribe(function (res) {
                days[i] = res;
            });
        };
        var this_1 = this;
        for (var i = 0; i < 7; ++i) {
            _loop_1(i);
        }
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.TranslateService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
