<div class="row">
    <div class="col-md-12 mb-5">
        <div class="card">
            <div class="card-body p-4">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="strong pt-2 m-0">{{'Datatable.Balance.CurrentBalance' | translate}}:</h4>
                        <h4 class="strong pb-0 m-0 blue">2 439,69 PLN {{'Datatable.Balance.Netto' | translate}}</h4>
                    </div>
                    <div class="col-md-6">
                        <a routerLink="/siteUnderConstruction"
                           class="btn btn-lg btn-primary float-right mr-0">{{'Datatable.Balance.BoostAccount' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <h3>{{'Datatable.Balance.History' | translate}}</h3>
    </div>
</div>

<app-datatable #datatable type="BALANCE"></app-datatable>