import DateTimeOffset from 'datetime-offset';

export class RegistrationService {

    isLogout = false;
    isLogoutAutomatically = false;
    isPassChanged = false;
    sessionExpiredLogout = false;

    iRegistration1: {
        language: string,
        email: string,
        password: string,
        id_term: number,
        id_agreement_list: number[],
        session_id: string,
        client_ip: string
    };
    iRegisterStatus: {
        RegistrationStatus: string,
        Token: string
    } = {
        RegistrationStatus: '',
        Token: ''
    };
    iRegisterStep1Status: {
        RegistrationUuid: string,
        RegistrationStatus: string,
        RegistrationFailedReason: string
    };

    private ilogin: {
        uuid: string,
        login_name: string
    } =
        {
            uuid: '',
            login_name: ''
        };
    iloginDoc: {
        Status: string,
        BlockTimestamp: DateTimeOffset,
        DocumentType: string,
        DocumentLength: number,
        FieldsToCheck: string
    };
    iPortalTerms: {
        TermID: number,
        TermsContents: string
    };
    iloginStatus: {
        LoginStatus: string,
        Token: string,
        LoginFailedAddtionalInfo: string,
        BlockTimestamp: string
    } = {LoginStatus: '', Token: '', LoginFailedAddtionalInfo: '', BlockTimestamp: ''};
    isExpired = false;

    setUuid(uuid: string) {
        this.ilogin.uuid = uuid;
        document.cookie = 'key_uuid=' + encodeURIComponent(this.ilogin.uuid) + ';max-age=' + 7 * 24 * 60 * 60 + ';';
    }

    getUuid (): string {
        if (this.ilogin.uuid !== '') {
            return this.ilogin.uuid;
        }

        let cookie = document.cookie.split(';').filter(cookie => cookie.includes('key_uuid'));
        this.ilogin.uuid = cookie.length ? decodeURIComponent(cookie[0].split('=')[1]) : '';

        return this.ilogin.uuid;
    }

    setILogin(i_login: {uuid: string, login_name: string}) {
        this.ilogin = i_login;

        document.cookie = 'key_uuid=' + encodeURIComponent(this.ilogin.uuid);
        document.cookie = 'key_name=' + encodeURIComponent(this.ilogin.login_name);
        document.cookie = 'max-age=' + 7 * 24 * 60 * 60;
    }

    getLoginName (): string {
        if (this.ilogin.login_name !== '') {
            return this.ilogin.login_name;
        }

        let cookie = document.cookie.split(';').filter(cookie => cookie.includes('key_name'));
        this.ilogin.login_name = cookie.length ? decodeURIComponent(cookie[0].split('=')[1]) : '';

        return this.ilogin.login_name;
    }

    clearAllData() {
        this.ilogin = {
            uuid: '',
            login_name: ''
        };
        this.iloginStatus = {LoginStatus: '', Token: '', LoginFailedAddtionalInfo: '', BlockTimestamp: ''};
        this.isExpired = false;
        this.iRegisterStatus = {
            RegistrationStatus: '',
            Token: ''
        };
        this.iRegisterStep1Status = null;
        this.iRegistration1 = null;
    }
}
