<ng-template #icd10resTemplate let-r="item">
  <span>{{r.icd10_code}} - {{r.icd10_text}}</span>
</ng-template>
<div aria-hidden="true" aria-labelledby="createOrderModal" class="modal fade" id="createOrderModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content p-0">
      <div class="modal-header d-flex flex-column p-4">
        <h3 class="modal-title d-block">{{'CreateOrderModal.Title' | translate}}</h3>
        <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button" (click)="setInitModalSetup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="accordion">
          <div class="card">
            <div class="card-header p-0" id="headingOne">
              <button class="btn w-100 h-100 text-left py-3 px-4"
                      (click)="setCollapseShown('collapseOne', 0)"
                      [ngClass]="{'btn-link': !isCollapseShown[0], 'btn-primary': isCollapseShown[0]}"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                <div class="row">
                  <div class="col">
                    <h5 class="mb-0" [ngClass]="{'text-white': isCollapseShown[0]}">
                      {{'CreateOrderModal.OrderDetails' | translate}}
                    </h5>
                  </div>
                  <div class="col text-right">
                    <svg-icon *ngIf="isValidSection[0] && !isOpenedForFirstTime[0]" class="mr-2" src="../../../../assets/images/icons/icon__exam_order_result.svg"></svg-icon>
                    <svg-icon *ngIf="isOpenedForFirstTime[0]" class="mr-2" src="../../../../assets/images/icons/icon__exam_order_assigned_to_physician.svg"></svg-icon>
                    <svg-icon *ngIf="!isValidSection[0] && !isOpenedForFirstTime[0]" class="mr-2" src="../../../../assets/images/icons/icon__exam_order_canceled.svg"></svg-icon>
                    <svg-icon *ngIf="isCollapseShown[0]" src="../../../../assets/images/icons/icon__arrow_up.svg"></svg-icon>
                    <svg-icon *ngIf="!isCollapseShown[0]" src="../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
                  </div>
                </div>
              </button>
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="row mt-2">
                  <div class="col d-flex">
                    <span class="modality mr-3">{{chosenExam.order_exam_modality}}</span>
                    <div class="my-auto">
                      {{chosenExam.first_name}} {{chosenExam.last_name}}<br>
                      PESEL: <strong>{{chosenExam.pesel}}</strong>
                    </div>
                  </div>
                  <div class="col my-auto">
                    HIS ID: <strong>{{chosenExam.his_patient_id}}</strong> <br>
                    {{'CreateOrderModal.Accn' | translate}}: <strong>{{chosenExam.exam_accession_number}}</strong>
                  </div>
                </div>
              </div>
              <div class="row mt-2 admin">
                <div class="col">
                  <div class="form-group ml-4 mr-2">
                    <label for="examNameSelect">{{'CreateOrderModal.ExamName' | translate}}</label>
                    <input style="border-radius: 0.3rem !important;" [(ngModel)]="chosenExam.order_exam_name" id="examNameSelect" type="text" class="datepicker-placeholder" disabled>
                  </div>
                </div>
                <div class="col">
                  <form [formGroup]="createOrderForm" novalidate>
                    <div class="form-group mr-4">
                      <label for="anatomyPart">{{'CreateOrderModal.AnatomyPart' | translate}}</label>
                      
                      <ng-select #ngAnatomySelect id="anatomyPart"
                                (change)="validateCollapseOne('anatomyPart')"
                                formControlName="anatomyPart"
                                placeholder="{{'CreateOrderModal.ChooseAnatomyPart' | translate}}"
                                notFoundText="{{'CreateOrderModal.NotFoundText' |translate}}"
                      >
                        <ng-option *ngFor="let part of anatomyPartOptions;" value="{{part.id_part}}">
                          {{part.value}}
                        </ng-option>
                      </ng-select>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row admin icd10">
                <div class="col form-group mx-4">
                  <label for="icd10Search">{{'CreateOrderModal.Icd10' | translate}}</label>
                  <input class="typeahead-custom custom-select py-4 form-control type_text"
                        id="icd10Search"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="asyncSelectedIcd10"
                        [typeaheadAsync]="true"
                        [typeaheadWaitMs]="200"
                        [typeahead]="searchIcd10DataSource"
                        (typeaheadLoading)="changeTypeaheadLoadingIcd10($event)"
                        (typeaheadOnSelect)="typeaheadOnIcd10Select($event)"
                        [typeaheadOptionsLimit]="15"
                        adaptivePosition="true"
                        typeaheadOptionField="display_name"
                        placeholder="{{'CreateOrderModal.Icd10Search' | translate}}"
                        [typeaheadMinLength]="3"
                        [typeaheadItemTemplate]="icd10resTemplate"
                        (input)="onValueChangeIcd10()"
                        style="border-radius: 0.3rem !important;"
                        (focus)="onValueChangeIcd10()"
                          autocomplete="off"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group mx-4">
                    <label for="comment">{{'CreateOrderModal.Comment' | translate}}</label>
                    <textarea style="border-radius: 0.3rem !important;" class="form-control" rows="3" id="comment"
                              placeholder="{{'CreateOrderModal.CommentPlaceholder' | translate}}"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header p-0" id="headingTwo">
              <button class="btn w-100 h-100 text-left py-3 px-4"
                      (click)="setCollapseShown('collapseTwo', 1)"
                      [ngClass]="{'btn-link': !isCollapseShown[1], 'btn-primary': isCollapseShown[1]}"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo">
                <div class="row">
                  <div class="col">
                    <h5 class="mb-0" [ngClass]="{'text-white': isCollapseShown[1]}">
                      {{'CreateOrderModal.Attachments' | translate}}
                    </h5>
                  </div>
                  <div class="col text-right">
                    <svg-icon class="mr-2" src="../../../../assets/images/icons/icon__exam_order_result.svg"></svg-icon>
                    <svg-icon *ngIf="isCollapseShown[1]" src="../../../../assets/images/icons/icon__arrow_up.svg"></svg-icon>
                    <svg-icon *ngIf="!isCollapseShown[1]" src="../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
                  </div>
                </div>
              </button>
            </div>

            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="card-body">
                <div class="row mb-4" *ngFor="let attachment of attachmentsList; let k = index;">
                  <div class="col-3">
                    <div class="form-group m-0">
                      <select class="form-control" style="border-radius: 6px !important;" disabled>
                        <option *ngFor="let attachmentType of attachmentOptions" value="{{attachmentType.id_attachment_type}}"
                                [selected]="attachmentType.id_attachment_type == attachment.id_attachment_type">
                          {{attachmentType.attachment_type_text}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col my-auto ml-3 p-0">
                    {{attachment.filename}}
                    <a class="ml-2" style="color: var(--red); text-decoration: underline; cursor: pointer;" (click)="delExamOrderAttachment(k)">
                      {{'CreateOrderModal.Remove' | translate}}
                    </a>
                  </div>
                </div>
                <div class="row" *ngIf="attachmentsList.length < 5">
                  <div class="col-3">
                    <div class="form-group m-0">
                      <select id="attachment" class="form-control" style="border-radius: 6px !important;">
                        <option *ngFor="let attachmentType of attachmentOptions" value="{{attachmentType.id_attachment_type}}">
                          {{attachmentType.attachment_type_text}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col d-flex">
                    <div class="form-group my-0 m-0">
                      <label class="btn btn-lg btn-light m-0" for="addFile">
                        {{'CreateOrderModal.AddAttachment' | translate}}
                      </label>
                      <input (change)="onFileSelected($event)" id="addFile"
                            type="file" accept=".jpg, .png, .bmp, .pdf" style="visibility: hidden; width: 1rem">
                    </div>
                    <span class="light my-auto" [innerHTML]="'CreateOrderModal.AcceptableFiles' | translate"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header p-0" id="headingThree">
              <button class="btn w-100 h-100 text-left px-4"
                      (click)="setCollapseShown('collapseThree', 2)"
                      [ngClass]="{'btn-link': !isCollapseShown[2], 'btn-primary': isCollapseShown[2],
                                        'py-2': !isCollapseShown[2], 'py-3': isCollapseShown[2]}"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree">
                <div class="row">
                  <div class="col">
                    <h5 class="mb-0" [style.line-height]="isCollapseShown[2] ? '1.2' : '1'" [ngClass]="{'text-white': isCollapseShown[2]}">
                      {{'CreateOrderModal.Mandatory' | translate}}<br>
                      <span *ngIf="!isCollapseShown[2]" class="light" style="font-size: 14px !important;">
                              <ng-container *ngIf="!chosenCenter">{{'CreateOrderModal.CenterName' | translate}}</ng-container>
                              <ng-container *ngIf="chosenCenter">{{chosenCenter.name}}</ng-container>
                            </span>
                    </h5>
                  </div>
                  <div class="col text-right my-auto">
                    <svg-icon *ngIf="isValidSection[2] && !isOpenedForFirstTime[2]" class="mr-2" src="../../../../assets/images/icons/icon__exam_order_result.svg"></svg-icon>
                    <svg-icon *ngIf="isOpenedForFirstTime[2]" class="mr-2" src="../../../../assets/images/icons/icon__exam_order_assigned_to_physician.svg"></svg-icon>
                    <svg-icon *ngIf="!isValidSection[2] && !isOpenedForFirstTime[2]" class="mr-2" src="../../../../assets/images/icons/icon__exam_order_canceled.svg"></svg-icon>
                    <svg-icon *ngIf="isCollapseShown[2]" src="../../../../assets/images/icons/icon__arrow_up.svg"></svg-icon>
                    <svg-icon *ngIf="!isCollapseShown[2]" src="../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
                  </div>
                </div>
              </button>
            </div>

            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="card-body">
                <form>
                  <div class="form-row">
                    <div class="col form-group" style="margin-bottom: 0; width: 100%;">
                      <label for="centerSearch">{{'CreateOrderModal.CenterSearch' | translate}}</label>
                      <input class="typeahead-custom py-4 form-control type_text"
                            id="centerSearch"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="asyncSelectedDescCenter"
                            [typeaheadWaitMs]="200"
                            [typeaheadAsync]="true"
                            [typeahead]="searchDCDataSource"
                            (typeaheadLoading)="changeTypeaheadLoadingDc($event)"
                            (typeaheadOnSelect)="typeaheadOnDcSelect($event)"
                            [typeaheadOptionsLimit]="15"
                            typeaheadOptionField="name"
                            adaptivePosition="true"
                            placeholder="{{'CreateOrderModal.CenterSearch' | translate}}"
                            [typeaheadMinLength]="0"
                            (input)="onValueChange()"
                            style="border-radius: 0.3rem !important;"
                            (focus)="onValueChange()"
                            autocomplete="off"
                      >
                    </div>
                  </div>
                </form>
                <div class="row mt-4" *ngIf="chosenCenter">
                  <div class="col d-flex">
                    <div class="card user-card mr-4 ml-1" style="box-shadow: 0 0 5px 0;" id="photoDiv">
                      <svg-icon *ngIf="chosenCenter.image_uuid == null"
                                src="../../assets/images/icons/user-card-no-photo.svg"
                                [svgStyle]="{ 'width.px':200, 'height.px': 200 }"
                                id="no-profile-pic-icon" [applyCss]="true"></svg-icon>
                      <img *ngIf="chosenCenter.image_uuid != null" src="{{envService.coLogoURL + chosenCenter.image_uuid}}" style="width: 200px !important; height: 200px;"
                          alt="" onerror="this.src='assets/images/icons/user-card-no-photo.svg'" #img>
                    </div>
                    <div class="my-auto">
                      <h3>{{chosenCenter.name}}</h3>
                      <h4>
                        {{'CreateOrderModal.Street' | translate}} {{chosenCenter.address_street}}<br>
                        {{chosenCenter.address_city}}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-4 admin">
        <strong>{{'CreateOrderModal.Priority' | translate}}</strong>
        <div class="form-group col-md-3 my-auto">
          <ng-select id="priority" #ngPrioritySelect
                    [searchable]="false"
                    [clearable]="false"
          >
            <ng-option *ngFor="let priority of priorityOptions;" value="{{priority.id_priority}}">
              {{priority.priority_text}}
            </ng-option>
          </ng-select>
        </div>
        <button class="btn p-3 btn-success" type="button" [disabled]="isSendDisabled()" (click)="mfCreateExamOrderEndTransaction()">
          <strong>{{'CreateOrderModal.SendOrder' | translate}}</strong>
        </button>
      </div>
    </div>
  </div>
</div>
