/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./insuff-chars-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./insuff-chars-modal.component";
var styles_InsuffCharsModalComponent = [i0.styles];
var RenderType_InsuffCharsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InsuffCharsModalComponent, data: {} });
export { RenderType_InsuffCharsModalComponent as RenderType_InsuffCharsModalComponent };
export function View_InsuffCharsModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { alert: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["aria-hidden", "true"], ["aria-labelledby", "insuffCharsModalLabel"], ["class", "modal fade"], ["id", "insuffCharsModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "modal-header d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nie mo\u017Cna wys\u0142a\u0107 opisu"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "modal-body pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Opis badania musi zawiera\u0107 przynajmniej 100 znak\u00F3w. "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brakuje: "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" znak\u00F3w."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (100 - _co.no); _ck(_v, 16, 0, currVal_0); }); }
export function View_InsuffCharsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-insuff-chars-modal", [], null, null, null, View_InsuffCharsModalComponent_0, RenderType_InsuffCharsModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.InsuffCharsModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InsuffCharsModalComponentNgFactory = i1.ɵccf("app-insuff-chars-modal", i2.InsuffCharsModalComponent, View_InsuffCharsModalComponent_Host_0, {}, {}, []);
export { InsuffCharsModalComponentNgFactory as InsuffCharsModalComponentNgFactory };
