import { OrderInfoModalComponent } from '@/utils/order-info-modal/order-info-modal.component';
import { FacilityOrdersService } from './../../../services/facility-orders.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoaderComponent } from '@/utils/loader/loader.component';
import {
  OrderDescriptionService,
  SessionService,
  UsersService
} from '@/services';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '@/services/page.service';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { SearchComponent } from '@/utils/search/search.component';
import { IFacilityOrdersFilter } from '@/interfaces';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { DescOrderReturnModalComponent } from '@/utils/desc-order-return-modal/desc-order-return-modal.component';

@Component({
  selector: 'app-medical-facility-dashboard',
  templateUrl: './medical-facility-dashboard.component.html',
  styleUrls: ['./medical-facility-dashboard.component.scss']
})
export class MedicalFacilityDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('search') search: SearchComponent;
  @ViewChild('loader') loader: LoaderComponent;
  @ViewChild('pagination') pagination: PaginationComponent;
  @ViewChild('orderInfoModal') orderInfoModal: OrderInfoModalComponent;
  @ViewChild('toast') toast: UniversalToastComponent;
  @ViewChild('returnDescOrder') returnDescOrder: DescOrderReturnModalComponent;

  currentId: string;
  searchSubscription: Subscription;
  pagesSubscription: Subscription;
  pageChangeSubscription: Subscription;
  itemsOnPageChangeSubscription: Subscription;
  facilitySubscription: Subscription;
  isOnlyCitoAndEmergency: boolean = false;
  filter = '{"status": [], "priority": []}';
  emptyFilter: IFacilityOrdersFilter = {
    status: [],
    priority: [],
    id_exam: null
  };
  ordersFilter: IFacilityOrdersFilter;
  isSingleClick: boolean = false;
  copiedText: string = '';
  examToOrdersId: number;
  showToastSubscription: Subscription;


  constructor(public userService: UsersService, private descriptionService: OrderDescriptionService,
              private sessionService: SessionService, public facilityOrdersService: FacilityOrdersService,
              private route: ActivatedRoute, private pageService: PageService) { }

  setNumberOfPages() {
    this.pagesSubscription = this.facilityOrdersService.numberOfOrders.subscribe(
      res => {
        this.pagination.pages = [];
        this.pagination.numberOfPages = res / parseInt(this.pagination.itemsOnPage);
        Math.floor(this.pagination.numberOfPages);
        for (let i = 0; i < this.pagination.numberOfPages; i++) {
          this.pagination.pages.push(i + 1);
        }
      }
    );

  }

  ngOnInit() {
    this.showToastEventSub();
    this.checkCurrentPage();
    this.searchEvent();
    this.changePageEvent();
    this.itemsOnPageChangeEvent();
    this.setNumberOfPages();
    if (this.facilityOrdersService.examToOrdersId) {
      this.ordersFilter = this.createOrderFilter(undefined, undefined, this.facilityOrdersService.examToOrdersId);
      this.examToOrdersId = this.facilityOrdersService.examToOrdersId;
      this.facilityOrdersService.clear_examToOrdersId();
      window.history.pushState({}, '', '/medicalFacilityDashboard/' + JSON.stringify(this.ordersFilter));
    } else {
      this.examToOrdersId = null;
      this.ordersFilter = this.createOrderFilter();
      this.currentId = 'All';
    }

    let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);
    this.facilitySubscription = this.userService.facilityChanged.subscribe(() => {
      this.facilityOrdersService.getMfExamOrders(this.ordersFilter, parseInt(this.pagination.itemsOnPage), offset);
    });

    this.facilityOrdersService.dashboardLoader = this.loader;


    this.pageService.setTranslatedPageTitle('DashboardScreen.Orders', 0);
    document.getElementById('facilityDashboardLink').setAttribute('class', 'active');
  }

  ngOnDestroy() {
    document.getElementById('facilityDashboardLink').setAttribute('class', '');
    this.searchSubscription.unsubscribe();
    this.pagesSubscription.unsubscribe();
    this.pageChangeSubscription.unsubscribe();
    this.itemsOnPageChangeSubscription.unsubscribe();
    this.facilitySubscription.unsubscribe();
    this.pageService.resetPageTitle();
    this.showToastSubscription.unsubscribe();
  }

  checkCurrentPage() {
    if (this.sessionService.getCurrentPage('MedicalFacilityDashboard'))
      this.pagination.activePage = this.sessionService.getCurrentPage('MedicalFacilityDashboard');
    if (this.sessionService.getItemsOnPage('MedicalFacilityDashboard'))
      this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('MedicalFacilityDashboard');
  }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.pagination.changePage(page));
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            (res) => {
                if (!this.search.isSearch) {
                    this.facilityOrdersService.getMfExamOrders(
                      this.ordersFilter,
                      parseInt(this.pagination.itemsOnPage),
                      (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage)
                      ).then(
                      () => {
                          this.pagination.inputPage = this.pagination.activePage + '';
                      }
                  );
                } else {
                  this.facilityOrdersService.getMfExamOrders(
                    this.ordersFilter,
                    parseInt(this.pagination.itemsOnPage),
                    (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                    this.search.filterSearch
                    ).then(
                    () => {
                        this.pagination.inputPage = this.pagination.activePage + '';
                    }
                );
                }
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            (res) => {
                this.getOrders('All');
            }
        );
    }

  getOrders(id: string): void {
    this.search.filterSearch = '';
    this.search.isSearch = false;
    $('#searchInput').val('');
    let priority = this.isOnlyCitoAndEmergency ? [2, 3] : [];

    if (id == '') {
      this.ordersFilter = this.createOrderFilter([], priority, this.facilityOrdersService.examToOrdersId);
    } else if (id == 'Completed') {
      this.ordersFilter = this.createOrderFilter([7], priority);
    } else if (id == 'NeedAttention') {
      this.ordersFilter = this.createOrderFilter([4, 8], priority);
    } else if (id == 'All') {
      this.ordersFilter = this.createOrderFilter(undefined, priority);
    }

    if (id != '' && this.examToOrdersId) this.examToOrdersId = null;
    this.currentId = id;
    window.history.pushState({}, '', '/medicalFacilityDashboard/' + JSON.stringify(this.ordersFilter));
    this.facilityOrdersService.getMfExamOrders(this.ordersFilter, parseInt(this.pagination.itemsOnPage), 0).then(
        () => {
            this.pagination.activePage = 1;
            this.pagination.inputPage = '1';
        }
    );
  }

  createOrderFilter(status?: number[], priority?: number[], id?: number): IFacilityOrdersFilter {
    let tempStatus = status ? status : [];
    let tempPriority = priority ? priority : [];
    let tempId = id ? id : null;
    return {
      status: tempStatus,
      priority: tempPriority,
      id_exam: tempId
    };
  }

  makeAction(index: number, name: string, orderId: number) {
      if (name == 'VIEW_EXAM_ORDER') {
        this.facilityOrdersService.getMfOrderDetails(orderId).then((res) => {
          if (res) this.orderInfoModal.show(this.facilityOrdersService.orderDetails);
          else {
            this.toast.error('DataLoadingFailure');
            this.toast.show();
            setTimeout(() => this.toast.hide(), 2000);
          }
        });
      } else if (name === 'DESCRPTION_TO_CORRECT') {
        this.facilityOrdersService.getMfOrderDetails(orderId).then((res) => {
          if (res) this.returnDescOrder.show(this.facilityOrdersService.orderDetails);
          else {
            this.toast.error('DataLoadingFailure');
            this.toast.show();
            setTimeout(() => this.toast.hide(), 2000);
          }
        });
      }
  }

  showToastEventSub() {
    this.showToastSubscription = this.returnDescOrder.showToastEvent.subscribe(t => {
      if (t.type == 'success') this.toast.success(t.message);
      else this.toast.error(t.message);
      this.toast.show();
      setTimeout(() => this.toast.hide(), t.timeout);
      if (t.refresh) {
        let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);
        this.getOrders('');
      }
    });
  }
}
