<div class="col-col-md-12" id="top-bar">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'Consultations.Title' | translate}}</h3>

            <ul *ngIf="!envService.hideUnderConstruction" id="top-bar-nav">
                <li class="active">
                    <a routerLink="/siteUnderConstruction">{{'Consultations.Inbox' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/siteUnderConstruction">{{'Consultations.Sent' | translate}}</a>
                </li>
                <li>
                    <a data-placement="right" data-toggle="tooltip" routerLink="/siteUnderConstruction"
                       title="Starsze niż 30 dni">{{'Consultations.Archived' | translate}}</a>
                </li>
            </ul>

        </div>
    </div>

</div>

<table class="tile-table">

    <tr class="tile">
        <td class="color-status orange"></td>
        <td><span class="modality">TK</span></td>
        <td><span class="dot"></span>Nowe</td>
        <td>
            <strong>Dziś, 09:13 </strong><span class="light">(15 minut temu)</span><br/>
            Centrum Zdrowia Mazowsza Zachodniego
        </td>
        <td>
            <strong>Monika Promienna-Michalak</strong><br/>
            895256279, 1982-12-31 <span class="light">(37 lat)</span>
        </td>
        <td>
            Dzień dobry, czy ja tu dobrze widzę, że lorem ipsum dolor sit amet,<br/>ahci abulam conit is fenis? Costinas
            paeh caprisi abulam erci...
        </td>
        <td *ngIf="!envService.hideUnderConstruction" class="actions">
            <a routerLink="/siteUnderConstruction" class="btn btn-light">{{'Consultations.Open' | translate}}</a>
            <button type="button" class="btn btn-light" data-toggle="button" aria-pressed="false"
                    autocomplete="off">
                <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
            </button>
        </td>
    </tr>

</table>
