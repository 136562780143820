<div class="news-container">

    <svg-icon alt="Wiadomości" class="news-container__icon" src="../../../assets/images/icons/memo.svg"></svg-icon>
    <h3 [innerHTML]="'News' | translate" class="news-container__header"></h3>

    <div class="news-container__news-box">
        <ng-container *ngFor="let new of news;">
            <p class="news-container__date">{{new.MessageDate}}</p>
            <div *ngFor="let titleLine of new.Title;">
                <p class="news-container__title">{{titleLine}}</p>
            </div>
            <p class="news-container__copy" [innerHTML]="new.Contents"></p>
        </ng-container>
    </div>

</div>