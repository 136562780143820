import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@/services/env.service';
import { map } from 'rxjs/operators';
import { ResponseStatus } from '@/core/api/response-status.enum';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  readonly ROOT_URL = this.env.publicServerURL;
  readonly ROOT_URL_PRIVATE = this.env.privateServerURL;

  constructor(private readonly env: EnvService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('ApiService') && req.headers.get('ApiService') === 'ApiService') {
      const request = req.clone({
        url: req.headers.get('private') === 'true' ?  this.ROOT_URL_PRIVATE + req.url : this.ROOT_URL + req.url,
        headers: req.headers
          .set('Content-Type', 'application/json')
          .delete('private')
          .delete('ApiService')
      });

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.Status === ResponseStatus.INVALID_TOKEN) {
              this.router.navigate(['loginFirst']);
              return;
            }
            return event.clone({body: {
              items: !!event.body.Value ? JSON.parse(event.body.Value) : event.body,
              count: !!event.body.ValueCount ? event.body.ValueCount : 0
              }
            });
          }
        }));
    }

    const requestOdataService = req.clone({headers: req.headers.delete('ApiService')});
    return next.handle(requestOdataService);
  }
}
