<div id="top-bar">
  <div class="row w-100">
      <div class="col-md-12 d-flex align-items-center my-auto w-100">
          <h3 class="page-name">{{'Profiles.PhysiciansTitle' | translate}}</h3>

          <ul class="d-flex ml-4 my-auto align-items-center" id="top-bar-nav">
              <li [ngClass]="{'active': isPhysicians}">
                  <a (click)="showTab('physicians')" style="cursor: pointer;">{{'Profiles.Physicians' | translate}}</a>
              </li>
              <li [ngClass]="{'active': !isPhysicians}">
                  <a (click)="showTab('dc')" style="cursor: pointer;">{{'Profiles.Centres' | translate}}</a>
              </li>
          </ul>

          <form>
              <app-search #search placeholder="{{getSearchPlaceholder()}}"></app-search>
          </form>

      </div>
  </div>

</div>
 
<div [hidden]="!isPhysicians">
     <!--<h5 class="divider">{{'Profiles.RecentlyAddedLProfiles' | translate}}</h5>-->
  <table *ngIf="profiles.length != 0" class="tile-table mt-4" id="tile-table">
      <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{{'Profiles.Name' | translate}}</th>
            <th>{{'Profiles.Contact' | translate}}</th>
            <th>&nbsp;</th>
            <th>{{'Profiles.Specializations' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let profile of profiles; let j = index;" class="tile">
                <td class="profiles-user-photo">
                    <svg-icon *ngIf="profile.image_uuid == null" [src]="'assets/images/icons/user-card-no-photo.svg'" style="width: 100% !important; height: 100%;" alt=""></svg-icon>
                    <img *ngIf="profile.image_uuid != null" src="{{envService.profilePicURL + profile.image_uuid}}" style="width: 90px !important; height: 90px;" alt="" onerror="this.src='assets/images/icons/user-card-no-photo.svg'" #img>
                </td>
                <td width="40%" id="physicianDcList{{profile.id_physician}}">
                    <div style="width: fit-content;" id="hiddenDcString{{profile.id_physician}}">
                        <div *ngFor="let dc of profile.working_places; let i = index;" id="physicianDc{{profile.id_physician}}{{i}}">
                            {{dc.describing_center_name}},
                        </div>
                    </div>
                    <span class="name">{{profile.physician_name}}</span>
                    <div style="width: fit-content;">
                        {{getPhysicianDcList(j)}}
                    </div>
              </td>
              <td>
                  <ng-container>
                     <ng-container *ngIf="profile.contact_info.address">{{profile.contact_info.address}}, {{profile.contact_info.address_zip_code}} {{profile.contact_info.address_city}}<br></ng-container>
                     <ng-container *ngIf="profile.contact_info.phone_number">{{profile.contact_info.phone_number}}<br></ng-container>
                     <ng-container *ngIf="profile.contact_info.email">{{profile.contact_info.email}}</ng-container>
                  </ng-container>
              </td>
              <td class="px-5"></td>
              <td>
                  <ng-container *ngFor="let spec of profile.specialization">
                          {{spec.specialization_name}}<br>
                  </ng-container>
              </td>
              <td class="actions">
                  <ng-container *ngFor="let action of profile.action_list">
                      <a *ngIf="action.id_action == 10001" class="btn btn-grey" (click)="makeAction(profile, action.action_name)">
                          {{action.button_name}}
                      </a>
                  </ng-container>
                  <button *ngIf="profile.action_list.length > 1" aria-expanded="false" aria-haspopup="true"
                      class="btn btn-light card-action dotsClass" data-toggle="dropdown" id="ddAction1" type="button">
                  <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                  </button>
                  <div *ngIf="profile.action_list.length > 1" aria-labelledby="ddAction1"
                      class="dropdown-menu dropdown-menu-right">
                      <ng-container *ngFor="let action of profile.action_list; let i = index;">
                          <a *ngIf="action.id_action != 10001" class="btn btn-grey" (click)="makeAction(profile, action.action_name)"
                              class="dropdown-item" style="cursor: pointer;">
                              {{action.button_name}}
                          </a>
                      </ng-container>
                  </div>
              </td>
          </tr>
      </tbody>
  </table>
</div>
 
<div [hidden]="isPhysicians">
  <!--<h5 class="divider">{{'Profiles.RecentlyAddedLProfiles' | translate}}</h5>-->
  <table *ngIf="dcProfiles.length != 0" class="tile-table mt-4" id="tile-table">
    <thead>
        <tr>
            <th>&nbsp;</th>
            <th>Nazwa</th>
            <th colspan="2">Dane kontaktowe</th>
            <th class="text-right">Akcje</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let profile of dcProfiles" class="tile">
            <td class="profiles-user-photo pl-2">
                <svg-icon *ngIf="profile.image_uuid == null" [src]="'assets/images/icons/user-card-no-photo.svg'" style="width: 100% !important; height: 100%;" alt=""></svg-icon>
                <img *ngIf="profile.image_uuid != null" src="{{envService.coLogoURL + profile.image_uuid}}" style="width: 100px !important;" alt="" onerror="this.src='assets/images/icons/user-card-no-photo.svg'" #img>
            </td>
            <td><span class="name">{{profile.name}}</span><!--Opcjonalna nazwa publicznego CU1 (...)--></td>
            <td class="pr-4">
                <ng-container>
                    {{profile.email || '-'}}<br>
                    {{profile.phone_number || '-'}}<br>
                    {{profile.address || '-'}}<br>
                    {{profile.address_zip_code}} {{profile.address_city}}
                </ng-container>
            </td>
            <td class="px-5"></td>
            <td class="actions">
                <ng-container *ngFor="let action of profile.action_list">
                    <a *ngIf="action.id_action == 10001" class="btn btn-grey" (click)="makeAction(profile, action.action_name)">
                    {{action.button_name}}
                    </a>
                </ng-container>
                <ng-container *ngIf="profile.action_list.length > 1">
                    <button aria-expanded="false" aria-haspopup="true"
                        class="btn btn-light card-action dotsClass" data-toggle="dropdown" id="ddAction1" type="button">
                        <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                    </button>
                    <div aria-labelledby="ddAction1"
                        class="dropdown-menu dropdown-menu-right">
                        <ng-container *ngFor="let action of profile.action_list; let i = index;">
                            <a *ngIf="(action.id_action != 10001 && action.id_action != 10002 && action.id_action != 10004); else nonActiveActions" class="btn btn-grey" (click)="makeAction(profile, action.action_name)"
                                class="dropdown-item" style="cursor: pointer;">
                                {{action.button_name}}
                            </a>
                            <ng-template #nonActiveActions>
                                <a *ngIf="action.id_action != 10001" class="btn btn-grey" (click)="makeAction(profile, action.action_name)"
                                    class="dropdown-item" style="pointer-events: none; cursor: default; color: gray">
                                    {{action.button_name}}
                                </a>
                            </ng-template>
                        </ng-container>
                    </div>
                </ng-container>
            </td>
        </tr>
    </tbody>
  </table>
</div>

<app-pagination #pagination componentName="doctorsProfiles" [hidden]="(profiles.length == 0 && isPhysicians) || (dcProfiles.length == 0 && !isPhysicians)"></app-pagination>
<app-invite-to-cooperation-modal #inviteToCooperationModal (invitationSent)="afterInvite($event)"></app-invite-to-cooperation-modal>
<app-send-message-to-user-modal #sendMessage [actionToast]="actionToast"></app-send-message-to-user-modal>
<app-send-application-to-co-modal #sendApplication [dataSavedToast]="actionToast"></app-send-application-to-co-modal>
<app-universal-toast #actionToast></app-universal-toast>
