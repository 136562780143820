import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./users.service";
import * as i3 from "./odata.service";
import * as i4 from "./session.service";
var MessagesService = /** @class */ (function () {
    function MessagesService(router, userService, odataService, sessionService) {
        this.router = router;
        this.userService = userService;
        this.odataService = odataService;
        this.sessionService = sessionService;
        this.messagesCount = 0;
        this.messagesMainEvent = new EventEmitter();
        this.messagesShortEvent = new EventEmitter();
        this.refreshTime = 60000;
        this.messageToShow = undefined;
    }
    MessagesService.prototype.updateMessagesCount = function () {
        var _this = this;
        if (sessionStorage.getItem('ACCOUNT_ACTIVE') == null) {
            return;
        }
        this.odataService.postPrivate('get_unread_messages_count', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        }, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                _this.messagesCount = result.Value;
            }
            else {
                console.log('get_unread_messages_count:', result.Status);
            }
        });
    };
    MessagesService.prototype.updateCount = function (value) {
        this.messagesCount = value;
    };
    MessagesService.prototype.mainRemoved = function (id_message) {
        var emiter = { id_message: id_message, action: 'remove' };
        this.messagesShortEvent.emit(emiter);
    };
    MessagesService.prototype.shortRemoved = function (id_message) {
        var emiter = { id_message: id_message, action: 'remove' };
        this.messagesMainEvent.emit(emiter);
    };
    MessagesService.prototype.mainReadState = function (id_user) {
        var emiter = { id_user: id_user, action: 'read' };
        this.messagesShortEvent.emit(emiter);
    };
    MessagesService.prototype.shortReaded = function (id_user) {
        var emiter = { id_user: id_user, action: 'read' };
        this.messagesMainEvent.emit(emiter);
    };
    MessagesService.prototype.realoadList = function () {
        this.restarTimer();
        var emiter = { id_message: 0, action: 'reload' };
        this.updateMessagesCount();
        this.messagesShortEvent.emit(emiter);
        this.messagesMainEvent.emit(emiter);
    };
    MessagesService.prototype.starTimer = function () {
        this.restarTimer();
    };
    MessagesService.prototype.stopTimer = function () {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    };
    MessagesService.prototype.restarTimer = function () {
        var _this = this;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(function () {
            _this.realoadList();
        }, this.refreshTime);
    };
    MessagesService.prototype.showMessage = function (id_message) {
        this.messageToShow = id_message;
        this.router.navigate(['/messages']);
    };
    MessagesService.prototype.setRefreshTime = function (newTimeout) {
        this.refreshTime = newTimeout; // no need to restart - reload list starts with the reset
    };
    MessagesService.prototype.getMessageToShow = function () {
        var id_message = this.messageToShow;
        this.messageToShow = undefined;
        return id_message;
    };
    MessagesService.ngInjectableDef = i0.defineInjectable({ factory: function MessagesService_Factory() { return new MessagesService(i0.inject(i1.Router), i0.inject(i2.UsersService), i0.inject(i3.OdataService), i0.inject(i4.SessionService)); }, token: MessagesService, providedIn: "root" });
    return MessagesService;
}());
export { MessagesService };
