import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { SessionService } from '@/services';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() componentName: string;

    itemsOnPage: string = '10';
    numberOfPages: number = 0;
    pages: number[] = [];
    activePage: number = 1;
    pageChangeEmitter: EventEmitter<number> = new EventEmitter<number>();
    changeItemsOnPageEmitter: EventEmitter<string> = new EventEmitter<string>();
    inputPage: string = '1';
    inputPageError: boolean = false;

    constructor(private sessionService: SessionService) {
    }

    ngOnInit() {
    }

    changePage(page: number) {
        if ((page <= this.pages.length) || page == 1) {
            this.sessionService.setCurrentPage(this.componentName, page);
            this.activePage = page;
            this.inputPageError = false;
            this.pageChangeEmitter.emit(this.activePage);
        }

        window.scroll(0, 0);
    }

    goToPage(page: string) {
        let pageNumber: number = parseInt(page);
        if (pageNumber && pageNumber <= this.pages.length && pageNumber > 0) {
            this.changePage(pageNumber);
        } else {
            this.inputPageError = true;
        }
    }

    setItemsOnPage() {
        this.sessionService.setItemsOnPage(this.componentName, this.itemsOnPage);
        this.sessionService.setCurrentPage(this.componentName, 1);
        this.changeItemsOnPageEmitter.emit(this.itemsOnPage);
        window.scroll(0, 0);
    }

}
