import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { EnvService } from '@/services/env.service';
import { SessionService } from '@/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit, OnDestroy {

    textEmitter: EventEmitter<string> = new EventEmitter<string>();
    subscription: Subscription;
    text: string = '';
    disabled: boolean = false;
    tinymceAPIKey: string = 'test';
    editorActive: boolean = true;
    editorConfig: any = {};
    tinymce: any;

    constructor(private envService: EnvService, private session: SessionService) {
        this.tinymceAPIKey = this.envService.tinymceAPIKey;
        this.editorConfig = this.envService.tinymceConfig;
        this.editorConfig.language = this.session.language;
        this.changeLang();
    }

    ngOnInit() {
    }

  ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getText(): string {
        return this.text;
    }

    changeEmit() {
        this.textEmitter.emit(this.text);
    }

    setText(value: string): void {
        this.text = value;
    }

    changeLang() {
        this.subscription = this.session.emitter.subscribe((res) => {
            this.editorConfig.language = res;
            this.editorActive = false;
            setTimeout(() => {
                this.editorActive = true;
            }, 0);
        });
    }

  setEditor(event: any) {
    this.tinymce = event.editor;
  }
}
