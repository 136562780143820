<div class="modal fade" id="orderDescriptionTemplatesModal" tabindex="-1" role="dialog"
     aria-labelledby="orderDescriptionTemplatesModalLabel" aria-hidden="true" data-dismiss="modal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
            <div class="card" id="templateList">
              <div class="card-header">
                <h3 class="card-title">{{'OrderDescriptionScreens.Templates.ListTitle' | translate}}</h3>
                <button (click)="onCloseModal()" type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>

              </div>
              <div class="card-body">
                <form [formGroup]="searchOptionsForm">
                  <div class="row" [ngStyle]="{'pointerEvents': editMode ? 'none' : 'auto'}">
                    <div class="col-md-5">
                      <label for="modality"></label>
                      <ng-select id="modality"
                                 (change)="get_templates()"
                                 [multiple]="true"
                                 [items]="modalityOptions"
                                 bindValue="id"
                                 bindLabel="value"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="false"
                                 formControlName="modality"
                                 placeholder="{{'OrderDescriptionScreens.Templates.ChooseModality' | translate}}"
                                 notFoundText="{{'OrderDescriptionScreens.Templates.NoResult' | translate}}"
                      >
                        <ng-template ng-header-tmp let-items="items">
                          <label style="margin-bottom: 0; font-weight: normal"><input type="checkbox" (change)="toggleCheckAll($event, 'modality')"
                                                                                      [checked]="allModalityIds.length == searchOptionsForm.controls.modality.value.length"
                          >
                            {{'OrderDescriptionScreens.Templates.All' | translate}}</label>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngFor="let item of items | slice:0:2">
                            <span class="ng-value-label">{{item.value}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                          </div>
                          <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{items.length - 2}} {{'OrderDescriptionScreens.Templates.More' | translate}}</span>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.value}}
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-md-7">
                      <label for="anatomy"></label>
                      <ng-select id="anatomy"
                                 (change)="get_templates()"
                                 [multiple]="true"
                                 [items]="anatomyOptions"
                                 bindValue="id"
                                 bindLabel="value"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="false"
                                 formControlName="anatomy"
                                 placeholder="{{'OrderDescriptionScreens.Templates.ChooseAnatomy' | translate}}"
                                 notFoundText="{{'OrderDescriptionScreens.Templates.NoResult' | translate}}"
                      >
                        <ng-template ng-header-tmp let-items="items">
                          <label style="margin-bottom: 0; font-weight: normal">
                            <input type="checkbox" (change)="toggleCheckAll($event, 'anatomy')"
                                   [checked]="searchOptionsForm.controls.anatomy.value.length == allAnatomyIds.length"
                            />
                            {{'OrderDescriptionScreens.Templates.All' | translate}}</label>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngFor="let item of items | slice:0:2">
                            <span class="ng-value-label">{{item.value}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                          </div>
                          <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{items.length - 2}} {{'OrderDescriptionScreens.Templates.More' | translate}}</span>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="anatomy-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.value}}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </form>
                <hr>
                <div *ngIf="userTemplates.length == 0">{{'OrderDescriptionScreens.Templates.NoTemplates' | translate}}</div>
                <cdk-virtual-scroll-viewport #templatesCDK id="templatesCDK" itemSize="100" minBufferPx="1200" maxBufferPx="1200">
                  <div *cdkVirtualFor="let template of userTemplates; trackBy: trackByFn" class="list-group-item py-3 template-item" [ngClass]="{'active' : template.id_template == currentTemplate.id_template,
                     'disabled' : template.id_template != currentTemplate.id_template && editMode}" (click)="onSelectTemplate(template)">
                    <span style="line-height: 36px">{{template.template_name}}</span>
                    <ng-container  *ngIf="!editMode">
                      <button type="button" class="btn btn-light btn-sm card-action float-right" id="templateOptions"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                      </button>
                      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="templateOptions">
                        <a class="dropdown-item" (click)="onEditTemplate(template)">{{'OrderDescriptionScreens.Templates.EditTemplate' | translate}}</a>
                        <a class="dropdown-item danger" (click)="onDeleteTemplate(template.id_template)">{{'OrderDescriptionScreens.Templates.DeleteTemplate' | translate}}</a>
                      </div>
                    </ng-container>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
              <div class="card-footer actions">
                <button class="btn btn-md btn-light mr-0" [disabled]="editMode" (click)="onEditTemplate()" style="margin-left: auto">{{'OrderDescriptionScreens.Templates.NewTemplate' | translate}}</button>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="card" id="templateEdit">
              <ng-container *ngIf="!editMode">
                <div class="card-body">
                  <strong><h4 *ngIf="currentTemplate.template_name !=''" class="card-title">{{currentTemplate.template_name}}
                  </h4>
                    <h4 *ngIf="currentTemplate.template_name == ''" class="card-title">{{'OrderDescriptionScreens.Templates.ChooseTemplate' | translate}}</h4></strong>
                  <div id="templateContent" [innerHTML]="currentTemplate.content"></div>

                </div>
                <div class="card-footer actions">
                  <button *ngIf="currentTemplate.template_name != ''" class="btn btn-md btn-primary mr-0" (click)="onCopyToEditor(currentTemplate)">Wstaw</button>
                </div>
              </ng-container>
              <div [hidden]="!editMode">
                <div class="card-body">
                  <form [formGroup]="templateForm">
                    <div class="form-group">
                      <label for="name">{{'OrderDescriptionScreens.Templates.Name' | translate}}</label>
                      <input id="name" type="text" class="form-control" formControlName="name">
                    </div>
                    <div class="row mb15">
                      <div class="col-md-6">
                        <label for="anatomyEdit">{{'OrderDescriptionScreens.Templates.Anatomy' | translate}}</label>
                        <ng-select id="anatomyEdit"
                                   [multiple]="true"
                                   [items]="anatomyOptions"
                                   bindValue="id"
                                   bindLabel="value"
                                   [selectableGroup]="true"
                                   [closeOnSelect]="false"
                                   formControlName="anatomy_parts"
                                   placeholder="{{'OrderDescriptionScreens.Templates.ChooseAnatomy' | translate}}"
                        >
                          <ng-template ng-header-tmp let-items="items">
                            <label style="margin-bottom: 0; font-weight: normal"><input type="checkbox" (change)="toggleCheckAll($event, 'anatomy')"
                                                                                        [checked]="allAnatomyIds.length == templateForm.controls.anatomy_parts.value.length"
                            />
                              {{'OrderDescriptionScreens.Templates.All' | translate}}</label>
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items | slice:0:2">
                              <span class="ng-value-label">{{item.value}}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 2">
                              <span class="ng-value-label">{{items.length - 2}} {{'OrderDescriptionScreens.Templates.More' | translate}}</span>
                            </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="anatomy-edit-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.value}}
                          </ng-template>
                        </ng-select>
                      </div>
                      <div class="col-md-6">
                        <label for="modalityEdit">{{'OrderDescriptionScreens.Templates.Type' | translate}}</label>
                        <ng-select id="modalityEdit"
                                   [multiple]="true"
                                   [items]="modalityOptions"
                                   bindValue="id"
                                   bindLabel="value"
                                   [selectableGroup]="true"
                                   [closeOnSelect]="false"
                                   formControlName="modalities"
                                   placeholder="{{'OrderDescriptionScreens.Templates.ChooseModality' | translate}}"
                        >
                          <ng-template ng-header-tmp let-items="items">
                            <label style="margin-bottom: 0; font-weight: normal"><input type="checkbox" (change)="toggleCheckAll($event, 'modality')"
                                                                                        [checked]="allModalityIds.length == templateForm.controls.modalities.value.length"/>
                              {{'OrderDescriptionScreens.Templates.All' | translate}}</label>
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items | slice:0:2">
                              <span class="ng-value-label">{{item.value}}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 2">
                              <span class="ng-value-label">{{items.length - 2}} {{'OrderDescriptionScreens.Templates.More' | translate}}</span>
                            </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="mod-edit-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.value}}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </form>
                  <app-text-editor #text_editor></app-text-editor>
                </div>
              </div>
              <div *ngIf="editMode" class="card-footer actions">
                <button class="btn btn-success btn-md" [disabled]="!templateForm.valid" (click)="onSave()">Zapisz</button>
                <button class="btn btn-danger btn-md mr-0" (click)="cancelEdit()">Anuluj</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
