import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, AfterViewInit {
    @Input() progress: string;
    @Input() id: number;

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        document.getElementById('progress' + this.id).style.width = this.progress;
    }

}
