import { element } from 'protractor';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { TranslateService } from '@ngx-translate/core';
import {OdataService, UsersService, SessionService, ValidationService} from '@/services';
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { IAgreement } from '@/interfaces';
import { FormBuilder, FormArray, Validators } from '@angular/forms';

interface IOption {
  value: any;
  id: number;
}

@Component({
  selector: 'app-pwz-verification-modal',
  templateUrl: './pwz-verification-modal.component.html',
  styleUrls: ['./pwz-verification-modal.component.scss']
})
export class PwzVerificationModalComponent implements OnInit {

  @ViewChild('errorToast') errorToast: UniversalToastComponent;
  scanFileName: string = '';
  base64: string = '';
  pwzSending: EventEmitter<string> = new EventEmitter<string>();

  agreementsList: IAgreement[] = [];
  registerPWZForm = this.formBuilder.group({
    agreements: this.formBuilder.array([]),
    pwz: ['', [Validators.required, ValidationService.pwzValidator, ValidationService.numberValidator]],
    title_id: [null, Validators.required],
    main_specialization_id: [null, Validators.required],
    specialization_list: []
  });
  degreeOptions: IOption[] = [];
  mainSpecializationOptions: IOption[] = [];
  otherSpecializationsOptions: IOption[] = [];
  otherSpecOptionIds: number[] = [];

  constructor(private odataService: OdataService, private translate: TranslateService,
              public userService: UsersService, private sessionService: SessionService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getUserAgreementForPhycisianVerification();
    this.getPhysicianDegreeList();
    this.getMainPhysicianSpecializationList();
    this.getPhysicianSpecializationList();
  }

  get agreements() {
    return this.registerPWZForm.get('agreements') as FormArray;
  }

  getUserAgreementForPhycisianVerification(): void {
    this.odataService.postPrivate('get_user_agreement_for_phycisian_verification', this.odataService.paramsTokenAndLang({}), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        this.agreementsList = JSON.parse(result.Value);
        this.agreementsList.forEach((agreement) => {
          if (agreement.is_required) {
            agreement.agreement_contents = '*' + agreement.agreement_contents;
          }
          this.addAgreement(agreement);
        });
      }
    });
  }

  getPhysicianSpecializationList(): void {
    this.odataService.postPrivate('get_physician_specialization_list', this.odataService.paramsTokenAndLang({}), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        let options = JSON.parse(result.Value);
        options.forEach(el => {
          this.otherSpecOptionIds.push(el.id_specialization);
          this.otherSpecializationsOptions.push({
            value: el.specialization_name,
            id: el.id_specialization
          });
        });
      }
    }).then(() => this.otherSpecializationsOptions = [...this.otherSpecializationsOptions]);
  }

  getMainPhysicianSpecializationList(): void {
    this.odataService.postPrivate('get_main_physician_specialization_list', this.odataService.paramsTokenAndLang({}), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        let list = JSON.parse(result.Value);
        list.forEach(o => this.mainSpecializationOptions.push({
          id: o.id_main_specialization,
          value: o.specialization_name
        }));
      }
    }).then(() => this.mainSpecializationOptions = [...this.mainSpecializationOptions]);
  }

  getPhysicianDegreeList(): void {
    this.odataService.postPrivate('get_physician_degree_list', this.odataService.paramsTokenAndLang({}), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        let list = JSON.parse(result.Value);
        list.forEach(o => this.degreeOptions.push({
          id: o.id_degree,
          value: o.degree_desc
        }));
      }
    }).then(() => this.degreeOptions = [...this.degreeOptions]);
  }

  addAgreement(agreement: IAgreement) {
    if (agreement.is_required) {
      this.agreements.push(this.formBuilder.control(false, Validators.requiredTrue));
    } else {
      this.agreements.push(this.formBuilder.control(false));
    }
  }

  sendPWZ() {
    let params = this.registerPWZForm.value;
    delete params.agreements; // agreements not a part of the pwz verif
    params.specialization_list = params.specialization_list ? params.specialization_list : [];
    this.odataService.postPrivate('user_send_pwz_to_verify_ext',
      this.odataService.paramsTokenAndLang({
        base64: this.base64,
        ...params
      }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.value == 'OK') {
          this.scanFileName = '';
          this.hide();
          this.pwzSending.emit('OK');
        } else if (result.value == 'INCORRECT_DATA') {
          this.errorToast.error('OperationFailed');
          this.errorToast.show();
          setTimeout(() => {
            this.errorToast.hide();
          }, 3000);
          this.translate.get('AccountProperties.VerifyPWZError')
            .subscribe((res: string) => {
              $('#enterPWZ').attr('placeholder', res);
            });
        }
        if (result.value != 'OK') {
          this.pwzSending.emit('FALSE');
        }
      });
  }

  show(): void {
    $('#verificationModal').modal('show');
  }

  hide(): void {
    $('#verificationModal').modal('hide');
  }

  onImageScanSelected(event) {
    let files = event.target.files;
    let file = files[0];
    if (file.name.length > 30) {
      this.scanFileName = file.name.substring(0, 30) + '...';
    } else this.scanFileName = file.name;

    if (files && file) {
      let reader = new FileReader();
      reader.onload = this.handleScanFile.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleScanFile(event) {
    let binaryString = event.target.result;
    this.base64 = btoa(binaryString);
    this.base64 = this.base64.replace(/\+/gi, '-');
    this.base64 = this.base64.replace(/\//gi, '_');
  }

  toggleCheckAll($event) {
    if ($event.target.checked) this.registerPWZForm.patchValue({specialization_list: this.otherSpecOptionIds});
    else this.registerPWZForm.patchValue({specialization_list: []});
  }
}
