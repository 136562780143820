import moment from 'moment';
import { IAttachment } from '@/interfaces/Order';
import { IConsultationAI, initConsultation } from '@/interfaces/AIConsultation';

export interface ExamInfo {
    assignPhysicianInfo: {
        physicianAssign: string,
        idPhysicianAssign: number
    };
    idExamOrder: number;
    descriptionDate: string;
    orderDate: string;
    rejectInfo: {
        rejectReason: string;
    };
    patientUuid: string;
    examInfo: {
        anatomy: {
            idPart: number,
            partName: string
        },
        modality: {
            idGroup: number,
            modality: string
        },
        priority: {
            priority: string,
            idPriority: number
        },
        birthDate: string,
        examDate: string, // ?
        examOrderNotesArray: string[],
        hisExamId: string, // ?
        hisPatientId: string, // ?
        idPatient: number,
        orderExamType: string,
        orderingFacilityName: string,
        patientAge: string,
        patientName: string,
        pesel: string,
        evoAccessionNumber: string,
        icd10: string,
        icd10Text: string,
        transferProgress: number
    };
    statusInfo: {
        status: string,
        idStatus: number,
        statusName: string
    };
}

export interface OrderDescStatus {
  name: string;
  id: number;
  codename: string;
}

export interface OrderDescription {
    exam_info: {
      consultation: IConsultationAI;
    };
    accession_number: string;
    icd10: string;
    icd10_text: string;
    status: OrderDescStatus;
    priority: {
        name: string,
        id: number
    };
    id: number;
    rejectInfo: {
        rejectReason: string;
    };
    statusDate: string;
    examDate: string;
    patientUuid: string;
    descriptionDate: string;
    patient: {
        name: string,
        documentType: string,
        documentId: string,
        birthdate: string,
        sex: string,
        age: number
    };
    info: {
        modality: {
            id: number,
            name: string
        },
        anatomyPart: {
            id: number,
            name: string
        },
        order_exam_type: string;
        descriptionArray: string[]
    };
    physicianAssigned: {
        name: string,
        id: number
    };
    client: {
        doctorName: string,
        facilityName: string
    };
    attachment_list: IAttachment[];
    attachment_prescription_list: IAttachment[];
    attachment_other_list: IAttachment[]; // two lists to sort the general att list
    transferProgress: number;
}

export interface ActionButton {
    idAction: number;
    actionName: string;
    buttonName: string;
    actionImportance: number;
}

export function examInfoToOrderDescription(exam: ExamInfo, attachments?: IAttachment[], consultation?: IConsultationAI): OrderDescription {
    let order: OrderDescription = {
        exam_info: { consultation: consultation },
        accession_number: exam.examInfo.evoAccessionNumber,
        icd10: exam.examInfo.icd10,
        icd10_text: exam.examInfo.icd10Text,
        status: { name: exam.statusInfo.status, id: exam.statusInfo.idStatus, codename: exam.statusInfo.statusName },
        priority: { id: exam.examInfo.priority.idPriority, name: exam.examInfo.priority.priority },
        id: exam.idExamOrder,
        statusDate: moment(exam.orderDate).format('DD.MM.YYYY HH:mm'),
        examDate: moment(exam.examInfo.examDate).format('DD.MM.YYYY HH:mm') == 'Invalid date' ? '-' : moment(exam.examInfo.examDate).format('DD.MM.YYYY HH:mm'),
        rejectInfo: {
            rejectReason: exam.rejectInfo.rejectReason,
        },
        patientUuid: exam.patientUuid,
        descriptionDate: exam.descriptionDate,
        patient: {
            name: exam.examInfo.patientName, documentType: 'PESEL', documentId: exam.examInfo.pesel,
            birthdate: exam.examInfo.birthDate, sex: '', age: parseInt(exam.examInfo.patientAge)
        },
        info: {
            modality: { id: exam.examInfo.modality.idGroup, name: exam.examInfo.modality.modality },
            anatomyPart: { id: exam.examInfo.anatomy.idPart, name: exam.examInfo.anatomy.partName },
            order_exam_type: exam.examInfo.orderExamType,
            descriptionArray: exam.examInfo.examOrderNotesArray
        },
        physicianAssigned: { name: exam.assignPhysicianInfo.physicianAssign, id: exam.assignPhysicianInfo.idPhysicianAssign },
        client: { doctorName: '', facilityName: exam.examInfo.orderingFacilityName },
        attachment_list:  attachments ? attachments : [],
        attachment_prescription_list: [],
        attachment_other_list: [],
        transferProgress: exam.examInfo.transferProgress ? exam.examInfo.transferProgress : 100
    };
    if (attachments) {
      attachments.forEach(attachment => { // sorting attachment list into prescription and others - 1 is id of prescription type
        if (attachment.attachment_type.id_attachment_type == 1) order.attachment_prescription_list.push(attachment);
        else order.attachment_other_list.push(attachment);
      });
    }
    return order;
}

export const dummyOrderDescription: OrderDescription = {
    exam_info: { consultation: initConsultation },
    accession_number: '',
    icd10: '',
    icd10_text: '',
    status: {
        name: '',
        id: 0,
        codename: ''
    },
    priority: {
        name: '',
        id: 0
    },
    id: 0,
    statusDate: '',
    examDate: '',
    rejectInfo: {
        rejectReason: '',
    },
    patientUuid: '',
    descriptionDate: '',
    patient: {
        name: '',
        documentType: '',
        documentId: '',
        birthdate: '',
        sex: '',
        age: 0
    },
    info: {
        modality: {
            id: 0,
            name: ''
        },
        anatomyPart: {
            id: 0,
            name: ''
        },
        order_exam_type: '',
        descriptionArray: []
    },
    physicianAssigned: {
        name: '',
        id: 0
    },
    client: {
        doctorName: '',
        facilityName: ''
    },
    attachment_list: [],
    attachment_other_list: [],
    attachment_prescription_list: [],
    transferProgress: 100
};

export interface IOrderData {
    id_exam_order: number;
    order_date: string;
    exam_info: {
        ordering_facility_name: string;
        patient_name: string;
        anatomy: {
            part_name: string
        };
    };
    assign_physician_info: {
        id_physician_assign: number
    };
}
