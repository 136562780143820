<div id="top-bar" class="col-md-12 w-100">
    <div class="row">
        <div class="col-md-12 pl-0 pr-0">
            <a style="cursor: pointer" class="back" (click)="navigateBack()">
                <svg-icon src="../../../../assets/images/icons/topbar/icon__arrow_left.svg" class="svg" alt="">
                </svg-icon>
            </a>
            <h3 class="page-name order-description">

                {{'Profile.COProfile.DescribingCentreProfile' | translate}}

            </h3>

            <ul id="top-bar-nav" class="ml-auto">

                <li *ngIf="!publicProfile || main.contact_info_enable" [ngClass]="{'active': acitvateField['contact']}"><a
                        (click)="scroll('section-start')">{{'Profile.Contact' | translate}}</a>
                </li>
                <li *ngIf="countKeys(workingHourInfo) != 0 && !hiddenTimetableAndPrice" [ngClass]="{'active': acitvateField['hours']}"><a
                        (click)="scroll('section-godziny-pracy')">{{'Profile.Hours' | translate}}</a>
                </li>
                <li *ngIf="countKeys(aboutDc) != 0" [ngClass]="{'active': acitvateField['about']}"><a
                        (click)="scroll('section-o-mnie')">{{'Profile.COProfile.AboutUs' | translate}}</a></li>
                <li *ngIf="countKeys(specializations) != 0" [ngClass]="{'active': acitvateField['specializations']}"><a
                        (click)="scroll('section-specjalizacje')">{{'Profile.Specializations' | translate}}</a></li>
                <li *ngIf="countKeys(priceListInfo) != 0 && !hiddenTimetableAndPrice" [ngClass]="{'active': acitvateField['portfolio']}"><a
                        (click)="scroll('section-portfolio')">{{'Profile.COProfile.PriceList' | translate}}</a>
                </li>

            </ul>

        </div>
    </div>

</div>

<div class="row" id="main_content" style="width: 980px; margin: 0 auto;">
    <div style="width: 100%">
        <div class="row">
            <div class="col">
                <!--AlertBox-->
                <app-alert #errorAlertEmptyPriceList title="AlertDefault"></app-alert>

                <div id="photo-and-info">

                <div class="card" id="photo-card">
                    <!-- start: user has profie picture -->
                    <ng-container *ngIf="coLogoUuid != null">
                        <div id="photoDiv" class="cu-logo-centered">
                            <img alt="" class="img-fluid user-img-loaded" src="{{coLogoUrl}}" style="height: auto; max-width: 100%;">
                        </div>
                        <input *ngIf="editPermission" class="btn btn-block btn-lg btn-white mt25"
                            onClick="document.getElementById('upload').click()" type="button"
                            value="{{'AccountProperties.ChangePhoto' | translate}}">
                        <input *ngIf="editPermission" (change)="onImageSelected($event)" id="upload" accept="image/*" type="file">
                    </ng-container>
                    <!-- end: user has profile picture -->

                    <!-- start: user doesn't have profile pic -->
                    <ng-container *ngIf="coLogoUuid === null">
                        <div style="height: auto; max-width: 100%;" id="photoDiv">
                            <svg-icon alt="" src="../../assets/images/icons/user-card-no-photo.svg"
                                [svgStyle]="{ 'width.px':139, 'height.px': 157 }" id="no-profile-pic-icon" [applyCss]="true"></svg-icon>
                            <input *ngIf="editPermission" class="btn btn-grey p-absolute"
                                onClick="document.getElementById('upload').click()" type="button"
                                value="{{'AccountProperties.AddPhoto' | translate}}">
                            <input *ngIf="editPermission" (change)="onImageSelected($event)" id="upload" accept="image/*" type="file">
                        </div>
                    </ng-container>
                    <!-- end: user doesn't have profile pic -->
                </div>

                <!--Card-->
                <div class="card big-card" id="section-start">

                    <div class="card-body pt15" style="display: flex; flex-direction: column; height: 100%;">

                        <div class="row h-100">
                            <div class="col d-flex flex-column h-100">
                                <h2 class="pt-2">{{main.name}}</h2>

                                <button *ngIf="editing.contact" class="btn btn-danger float-right ml-3"
                                    (click)="cancelEditing('contact')">{{'Profile.CancelChangesButton' | translate}}
                                </button>
                                <button *ngIf="editPermission && !editing.contact" class="btn btn-grey float-right"
                                    (click)="this.editing.contact=true">{{'Profile.EditButton' | translate}}
                                </button>
                                <button *ngIf="editing.contact" class="btn btn-primary float-right"
                                    (click)="changeDescribingCenterProfileSectionContactInfo()">{{'Profile.SaveChangesButton' | translate}}
                                </button>
                                <span *ngIf="editPermission" style="display: inline-block; float: right; margin-right: 16px;">
                                    <div (click)="changeDescribingCenterProfileSectionStatus(2, 'profile_contact_info')" class="toggle btn btn-success"
                                        data-toggle="toggle" id="privacy2" style="width: 120px; height: 10px;">

                                        <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger" data-on="Publiczne"
                                            data-onstyle="success" data-toggle="toggle" data-width="120" type="checkbox">

                                        <div class="toggle-group">
                                            <label class="btn btn-success toggle-on"
                                                style="line-height: 22.4px;">{{'Profile.SectionPublic' | translate}}
                                                ●</label>
                                            <label class="btn btn-danger toggle-off" style="line-height: 22.4px;">●
                                                {{'Profile.SectionPrivate' | translate}}</label>
                                            <span class="toggle-handle btn btn-light"></span>
                                        </div>
                                    </div>
                                </span>

                                <div *ngIf="!editing.contact" class="card-contact-info">
                                    <div>
                                        <svg-icon src="../../../assets/images/icons/icon__mail.svg" class="svg float-icon" alt=""></svg-icon>
                                        {{main.email}}
                                    </div>
                                    <div>
                                        <svg-icon src="../../../assets/images/icons/icon__phone.svg" class="svg float-icon" alt=""></svg-icon>
                                        {{main.phone_number}}
                                    </div>
                                    <div>
                                        <svg-icon src="../../../assets/images/icons/icon__marker.svg" class="svg float-icon" alt=""></svg-icon>
                                        {{main.address_street + '/' +
                                                            main.address_local + ', ' + main.address_zip_code + ' ' +
                                                            main.address_city}}
                                    </div>
                                </div>

                                <app-alert #errorAlertContact title="ProfileContact"></app-alert>

                                <div *ngIf="editing.contact" class="form-row">
                                    <form [formGroup]="contactForm">
                                        <div class="col form-group">
                                            <label for="email">{{'Profile.Mail' | translate}}</label>
                                            <input id="email" type="text" class="form-control" formControlName="email">
                                        </div>
                                    </form>
                                    <form [formGroup]="contactForm">
                                        <div class="col form-group">
                                            <label for="phone_number">{{'Profile.PhoneNumber' | translate}}</label>
                                            <input id="phone_number" type="text" class="form-control" formControlName="phone_number">
                                        </div>
                                    </form>
                                    <form [formGroup]="contactForm">
                                        <div class="col form-group">
                                            <label for="city">{{'Profile.City' | translate}}</label>
                                            <input id="city" type="text" class="form-control" formControlName="city">
                                        </div>
                                    </form>
                                    <form [formGroup]="contactForm">
                                        <div class="col form-group">
                                            <label for="street">{{'Profile.COProfile.Street' | translate}}</label>
                                            <input id="street" type="text" class="form-control" formControlName="street">
                                        </div>
                                    </form>
                                    <form [formGroup]="contactForm">
                                        <div class="col form-group">
                                            <label for="local">{{'Profile.COProfile.Local' | translate}}</label>
                                            <input id="local" type="text" class="form-control" formControlName="local">
                                        </div>
                                    </form>
                                    <form [formGroup]="contactForm">
                                        <div class="col form-group">
                                            <label for="zip">{{'Profile.ZipCode' | translate}}</label>
                                            <input id="zip" type="text" class="form-control" formControlName="zip">
                                        </div>
                                    </form>
                                </div>


                            </div>

                            <div style="margin-top: auto;">
                                <a *ngFor="let action of actionsList" class="btn comunicationButton btn-grey btn-text-grey"
                                    (click)="makeAction(action.id_action)">
                                    {{action.button_name}}
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
                <!--/Card-->

                </div>

                <!--Card-->
                <div [hidden]="hiddenTimetableAndPrice" *ngIf="countKeys(workingHourInfo) != 0" class="card big-card" id="section-godziny-pracy">
                    <h3 class="card-title">{{'Profile.Hours' | translate}}
                        <button *ngIf="editing.workingHours" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('workingHours')">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !editing.workingHours" class="btn btn-grey float-right"
                            (click)="this.editing.workingHours=true">{{'Profile.EditButton' | translate}}
                        </button>
                        <button *ngIf="editing.workingHours" class="btn btn-primary float-right"
                            (click)="changeDescribingCenterProfileSectionWorkingHours()">{{'Profile.SaveChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission"
                            class="btn btn-grey float-right mr-3">{{'Profile.COProfile.EditHolidaysButton' | translate}}</button>
                        <span *ngIf="editPermission" style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changeDescribingCenterProfileSectionStatus(1, 'profile_work_hours')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy1"
                                style="width: 120px; height: 10px;">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22.4px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22.4px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>

                    </h3>
                    <div class="card-body">

                        <div *ngIf="!editing.workingHours" class="row">
                            <div class="col-md-6">
                                <dl class="col-dl"
                                    *ngFor="let day of workingHourInfo.working_hours.working_hour_list; let i = index">
                                    <dt>{{daysOfWeek[i]}}</dt>
                                    <dd *ngIf="day.hour_start != null">
                                        {{day.hour_start.slice(0, 5) + ' - ' + day.hour_stop.slice(0, 5)}}</dd>
                                    <dd *ngIf="day.hour_start == null">{{'Profile.DoesNotWork' | translate}}</dd>
                                </dl>
                            </div>

                            <div class="col-md-6">
                                <div class="col-dl">
                                    <dd><strong>{{'Profile.COProfile.Holidays' | translate}}</strong></dd>
                                    <dd *ngFor="let day of workingHourInfo.working_hours.non_working_days">
                                        {{day.day_start}}-{{day.day_stop}}
                                    </dd>
                                </div>
                            </div>
                        </div>

                        <app-alert #errorAlertHours title="ProfileHours"></app-alert>

                        <div *ngIf="editing.workingHours" class="row">
                            <div class="col-md-6">
                                <dl class="col-dl" *ngFor="let day of workingHourInfo.working_hours.working_hour_list">
                                    <dt style="margin-top: 28px;">{{daysOfWeek[day.day_of_week]}}</dt>
                                    <dd>
                                      <div class="row">
                                        <div class="col form-group" style="padding: 0px; margin-bottom: 0px;">
                                          <app-time-picker #startHour [inputTime]="day.hour_start"></app-time-picker>
                                        </div>
                                        <div class="col-2" style="display: flex; align-items: center; justify-content: center;">
                                          <span style="text-align: center; display: inline-flex; align-items: center;">—</span>
                                        </div>
                                        <div class="col form-group" style="padding: 0px; margin-bottom: 0px;">
                                          <app-time-picker #stopHour [inputTime]="day.hour_stop"></app-time-picker>
                                        </div>
                                      </div>
                                    </dd>
                                    <div *ngIf="day.day_of_week == 6">
                                        <dt>{{'Profile.Info' | translate}}</dt>
                                        <br />
                                        <dd>{{'Profile.InfoText' | translate}}</dd>
                                    </div>
                                </dl>
                            </div>

                            <div class="col-md-1"></div>

                            <div class="col-md-4">
                                <div class="col-dl">
                                    <dd><strong>{{'Profile.COProfile.Holidays' | translate}}</strong></dd>
                                    <dd *ngFor="let day of workingHourInfo.working_hours.non_working_days">
                                        {{day.day_start}}-{{day.day_stop}}
                                    </dd>
                                </div>
                                <div class="btn btn-grey">{{'Profile.COProfile.EditHolidaysButton' | translate}}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div *ngIf="countKeys(aboutDc) != 0" class="card big-card" id="section-o-mnie">
                    <h3 class="card-title">{{'Profile.COProfile.AboutUsSection' | translate}}
                        <button *ngIf="editing.aboutCo" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('aboutCo')">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !editing.aboutCo" class="btn btn-grey float-right"
                            (click)="this.editing.aboutCo=true">{{'Profile.EditButton' | translate}}
                        </button>
                        <button *ngIf="editing.aboutCo" class="btn btn-primary float-right"
                            (click)="changeDescribingCenterProfileSectionAboutDc()">{{'Profile.SaveChangesButton' | translate}}
                        </button>
                        <span *ngIf="editPermission" style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changeDescribingCenterProfileSectionStatus(3, 'profile_about_dc')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy3"
                                style="width: 120px; height: 10px;">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22.4px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22.4px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">

                        <div class="row" *ngIf="!editing.aboutCo">
                            <div class="col-md-12 column-text">
                                <p style="column-count: 1;">{{aboutDc.about_dc_info.about_dc}}</p>
                            </div>
                        </div>

                        <div class="row" *ngIf="editing.aboutCo">
                            <div class="col-md-12 column-text">
                                <textarea name="about" id="aboutCoInput" class="form-control" rows="15"
                                    placeholder="{{'Profile.COProfile.WriteAboutCO' | translate}}"
                                    value="{{aboutDc.about_dc_info.about_dc}}">
                                        </textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div *ngIf="countKeys(specializations) != 0" class="card big-card" id="section-specjalizacje">
                    <h3 class="card-title">{{'Profile.Specializations' | translate}}
                        <button *ngIf="editing.specializations" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('specializations')">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !editing.specializations" class="btn btn-grey float-right"
                            (click)="this.editing.specializations=true">{{'Profile.EditButton' | translate}}
                        </button>
                        <button *ngIf="editing.specializations" class="btn btn-primary float-right"
                            (click)="changeDescribingCenterProfileSectionPhysicianSpecializations()">{{'Profile.SaveChangesButton' | translate}}
                        </button>
                        <span *ngIf="editPermission" style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changeDescribingCenterProfileSectionStatus(4, 'profile_specializations')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy4"
                                style="width: 120px; height: 10px;">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22.4px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22.4px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">

                        <div class="row" *ngIf="!editing.specializations">
                            <div class="col-md-12 column-text">
                                <ul *ngFor="let spec of specializations.specialization">
                                    <li>{{spec.specialization_name}}</li>
                                </ul>
                            </div>
                        </div>

                        <div *ngIf="editing.specializations" id="specEditContainer">
                            <div class="row" *ngFor="let spec of specializations.specialization; index as i;">
                                <div class="col-md-11">
                                    <div class="form-group">
                                        <select name="select_spec" id="select{{i}}" class="form-control">
                                            <option *ngIf="1 == spec.id_specialization" value="1" selected>Choroby
                                                wewnętrzne
                                            </option>
                                            <option *ngIf="1 != spec.id_specialization" value="1">Choroby wewnętrzne
                                            </option>
                                            <option *ngIf="2 == spec.id_specialization" value="2" selected>Medycyna
                                                nuklearna
                                            </option>
                                            <option *ngIf="2 != spec.id_specialization" value="2">Medycyna nuklearna
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-1 mt-2">
                                    <button class="btn btn-danger" type="button"
                                        (click)="profileService.deleteSpecialization(i)">{{'Profile.DeleteSpecButton' | translate}}
                                    </button>
                                </div>
                            </div>

                            <div class="row" id="addSpecButton">
                                <div class="col-md-12">
                                    <button class="btn btn-grey" type="button"
                                        (click)="addSpecialization('specEditContainer')">{{'Profile.AddSpecButton' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div [hidden]="hiddenTimetableAndPrice" *ngIf="countKeys(priceListInfo) != 0" class="card big-card" id="section-portfolio">
                    <h3 class="card-title">{{'Profile.COProfile.PriceList' | translate}}
                        ({{'Profile.COProfile.Default' | translate}})
                        <button *ngIf="editing.pricesCurrent" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('prices', priceListInfo.price_list_info.id_price_list_default)">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !editing.pricesCurrent" class="btn btn-grey float-right"
                            (click)="changePrices(priceListInfo.price_list_info.id_price_list_default)">{{'Profile.EditButton' | translate}}
                        </button>
                        <button *ngIf="editing.pricesCurrent" class="btn btn-primary float-right"
                            (click)="changePrices(priceListInfo.price_list_info.id_price_list_default)">{{'Profile.SaveChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission" class="btn btn-grey float-right mr-3" data-toggle="modal"
                            type="button" data-target="#copyList">Kopiuj /
                            Utwórz nowy
                        </button>
                        <span *ngIf="editPermission" style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changeDescribingCenterProfileSectionStatus(5, 'profile_price_list')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy5"
                                style="width: 120px; height: 10px;">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22.4px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22.4px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">
                        <app-alert #errorAlertPrices title="ProfilePrices"></app-alert>

                        <div class="row" *ngIf="!_isEdit">
                            <div class="col-md-12 table-responsive">
                                <table width="100%" cellpadding="15" class="table-portfolio">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th *ngFor="let mod of portfolioService.modalities">
                                            {{mod.value}}
                                        </th>
                                    </tr>
                                    <tr *ngFor="let part of portfolioService.anatomyParts; let i = index">
                                        <td>{{part.value}}</td>
                                        <td *ngFor="let price of portfolioService.finalDefaultCoPortfolio[i]">
                                            {{price.price}} zł
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="row mt-4" *ngIf="_isEdit">
                            <div class="col-md-12">

                                <h3 class="mt-2 mb-4">{{'Profile.COProfile.ChangeManyPrices' | translate}}</h3>

                                <p class="blue strong">Wg modalności</p>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select [value]="idPortfolioModality" [(ngModel)]="idPortfolioModality" name="" id=""
                                                    class="form-control">
                                                <option value="all">wszystkie</option>
                                                <option *ngFor="let mod of portfolioService.modalities; let i = index"
                                                        value="{{i}}">{{mod.value}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" class="form-control" placeholder="Cena" aria-label="Cena"
                                                   aria-describedby="cena" [(ngModel)]="allPortfolioModalities">
                                        </div>
                                        <div class="col-md-1 mt-1">
                                            <button (click)="changeAllModalitiesPortfolio()" class="btn btn-grey" type="button">
                                                Zmień
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <p class="blue strong">Wg części anatomicznej</p>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select [value]="idPortfolioPart" [(ngModel)]="idPortfolioPart" name="" id=""
                                                    class="form-control">
                                                <option value="all">wszystkie</option>
                                                <option
                                                    *ngFor="let part of portfolioService.anatomyParts; let i = index"
                                                    value="{{i}}">{{part.value}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" class="form-control" placeholder="Cena"
                                                   aria-label="Cena" aria-describedby="cena" [(ngModel)]="allPortfolioParts">
                                        </div>
                                        <div class="col-md-1 mt-1">
                                            <button (click)="changeAllPartsPortfolio()" class="btn btn-grey" type="button">
                                                Zmień
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4 mb-4" *ngIf="_isEdit">
                            <div class="col-md-12 table-responsive">
                                <table width="100%" cellpadding="15" class="table-portfolio edit">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th *ngFor="let mod of portfolioService.modalities">
                                            {{mod.value}}
                                        </th>
                                    </tr>
                                    <tr *ngFor="let part of portfolioService.anatomyParts; let i = index">
                                        <td>{{part.value}}</td>
                                        <td *ngFor="let price of portfolioService.finalDefaultCoPortfolio[i]">
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Cena"
                                                       aria-label="Cena" aria-describedby="cena"
                                                       value="{{price.price}}" [(ngModel)]="price.price">
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <!--/Card-->


                <!--Card-->
                <div class="card big-card mb50" id="portfolio{{i}}"
                    *ngFor="let portfolio of portfolioService.listCOPortfolios; let i = index">
                    <h3 class="card-title">{{'Profile.COProfile.PriceList' | translate}}
                        ({{portfolioService.priceLists[i].price_list_name}})
                        <button *ngIf="is_edit_price[i]" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('prices', portfolioService.priceLists[i].id_price_list_main, i)">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !is_edit_price[i]" class="btn btn-grey float-right"
                            (click)="changePrices(portfolioService.priceLists[i].id_price_list_main, i)">{{'Profile.EditButton' | translate}}
                        </button>
                        <button *ngIf="is_edit_price[i]" class="btn btn-primary float-right"
                            (click)="changePrices(portfolioService.priceLists[i].id_price_list_main, i)">{{'Profile.SaveChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission" class="btn btn-danger float-right mr-3"
                            (click)="deleteDescribingCenterPriceList(portfolioService.priceLists[i].id_price_list_main)">{{'Profile.COProfile.DeletePriceList' | translate}}</button>
                        <span *ngIf="editPermission" style="display: inline-block; float: right; margin-right: 16px;">
                            <div class="toggle btn btn-danger off" disabled data-toggle="toggle" id="privacy6"
                                style="width: 120px; height: 10px;">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22.4px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off disabled" style="line-height: 22.4px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">

                      <div *ngIf="isInvalidPrice[i]" class="alert alert-danger" role="alert">
                          <h4 class="alert-heading">{{'AlertTitle.ProfilePrices' | translate}}</h4>
                          <p>{{'Errors.prices' | translate}}</p>
                      </div>

                      <div class="row">
                            <div class="col-md-12 column-text przypisany_do">
                                <p><strong>{{'Profile.COProfile.AssignedTo' | translate}}</strong></p>
                                <ul>
                                    <li class="pricesList"><img src="../../../assets/images/_dummy_data/logo-1.png" alt="">Nowa
                                        radiologia
                                        <a *ngIf="is_edit_price[i] && !env.hideUnderConstruction" routerLink="/siteUnderConstruction"
                                            class="ml-3 btn btn-small btn-danger">Usuń
                                            przypisanie</a>
                                    </li>
                                    <li class="pricesList"><img src="../../../assets/images/_dummy_data/logo-2.png" alt="">Centrum
                                        Onkologii - Instytut im. Marii Skłodowskiej-Curie
                                        <a *ngIf="is_edit_price[i] && !env.hideUnderConstruction" routerLink="/siteUnderConstruction"
                                            class="ml-3 btn btn-small btn-danger">Usuń
                                            przypisanie</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row" *ngIf="is_edit_price[i] && !env.hideUnderConstruction">
                            <div class="col-md-12">
                                <a routerLink="/siteUnderConstruction" class="btn btn-grey">Przypisz nową placówkę</a>
                            </div>
                        </div>

                        <div class="row" *ngIf="!is_edit_price[i]">
                            <div class="col-md-12 table-responsive">
                                <table width="100%" cellpadding="15" class="table-portfolio">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th *ngFor="let mod of portfolioService.modalities">
                                            {{mod.value}}
                                        </th>
                                    </tr>
                                    <tr *ngFor="let part of portfolioService.anatomyParts; let j = index">
                                        <td>{{part.value}}</td>
                                        <td *ngFor="let prices of portfolio[j]">
                                            {{prices.price}} zł
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>

                        <div class="row mt-4" *ngIf="is_edit_price[i]">
                            <div class="col-md-12">

                                <h3 class="mt-2 mb-4">{{'Profile.COProfile.ChangeManyPrices' | translate}}</h3>

                                <p class="blue strong">Wg modalności</p>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select [value]="idModality" [(ngModel)]="idModality" name="" id=""
                                                class="form-control">
                                                <option value="all">wszystkie</option>
                                                <option *ngFor="let mod of portfolioService.modalities; let k = index"
                                                    value="{{k}}">{{mod.value}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" class="form-control" placeholder="Cena" aria-label="Cena"
                                                aria-describedby="cena" [(ngModel)]="allModalities">
                                        </div>
                                        <div class="col-md-1 mt-1">
                                            <button (click)="changeAllModalities(i)" class="btn btn-grey" type="button">
                                                Zmień
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <p class="blue strong">Wg części anatomicznej</p>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select [value]="idPart" [(ngModel)]="idPart" name="" id=""
                                                class="form-control">
                                                <option value="all">wszystkie</option>
                                                <option
                                                    *ngFor="let part of portfolioService.anatomyParts; let k = index"
                                                    value="{{k}}">{{part.value}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" class="form-control" placeholder="Cena" aria-label="Cena"
                                                aria-describedby="cena" [(ngModel)]="allParts">
                                        </div>
                                        <div class="col-md-1 mt-1">
                                            <button (click)="changeAllParts(i)" class="btn btn-grey" type="button">
                                                Zmień
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4 mb-4" *ngIf="is_edit_price[i]">
                            <div class="col-md-12 table-responsive">
                                <table width="100%" cellpadding="15" class="table-portfolio edit">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th *ngFor="let mod of portfolioService.modalities">
                                            {{mod.value}}
                                        </th>
                                    </tr>
                                    <tr *ngFor="let part of portfolioService.anatomyParts; let j = index">
                                        <td>{{part.value}}</td>
                                        <td *ngFor="let prices of portfolio[j]">
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Cena"
                                                    aria-label="Cena" aria-describedby="cena" value="{{prices.price}}"
                                                    [(ngModel)]="prices.price">
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/Card-->

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="modal__removeAccount"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modal__removeAccount">{{'Profile.COProfile.DeleteProfile' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{'Profile.COProfile.DeleteProfileText' | translate}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger">{{'Profile.COProfile.AgreeDeleting' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- end: wyslanieZaproszeniaDoWspolpracyModal -->

<app-add-list-modal></app-add-list-modal>
<app-send-application-to-co-modal #sendApplication [dataSavedToast]="dataSavedToast"></app-send-application-to-co-modal>
<app-universal-toast #dataSavedToast></app-universal-toast>
