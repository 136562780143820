import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '@/core/components/base-component/base.component';
import { ToastType } from '@/core/components/toast/toast.model';
import { ToastService } from '@/core/components/toast/toast.service';
import { takeUntil } from 'rxjs/operators';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
var ToastComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ToastComponent, _super);
    function ToastComponent(toastService) {
        var _this = _super.call(this) || this;
        _this.toastService = toastService;
        _this.toasts = [];
        _this.faClose = faTimesCircle;
        return _this;
    }
    ToastComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.toastService.onToast().pipe(takeUntil(this.destroy$))
            .subscribe(function (toast) {
            var toastToIncrement = _this.toasts.find(function (t) { return t.message === toast.message && !toast.autoClose && !t.autoClose; });
            toastToIncrement ? _this.incrementToast(toastToIncrement) : _this.toasts = _this.toasts.concat(toast);
            if (toast.autoClose) {
                setTimeout(function () { return _this.removeToast(toast); }, 5000);
            }
        });
    };
    ToastComponent.prototype.incrementToast = function (toast) {
        this.toasts.find(function (t) { return t === toast; }).sameToastCount += 1;
    };
    ToastComponent.prototype.removeToast = function (toast) {
        var _this = this;
        this.toasts.find(function (t) { return t == toast; }).fade = true;
        setTimeout(function () {
            _this.toasts = _this.toasts.filter(function (t) { return t !== toast; });
        }, 500);
    };
    ToastComponent.prototype.faColor = function (alert) {
        var _a;
        if (!alert)
            return;
        var faClass = (_a = {},
            _a[ToastType.Success] = 'alert-success',
            _a[ToastType.Error] = 'alert-danger',
            _a[ToastType.Info] = 'alert-info',
            _a[ToastType.Warning] = 'alert-warning',
            _a);
        return faClass[alert.type];
    };
    ToastComponent.prototype.cssClass = function (alert) {
        var _a;
        if (!alert)
            return;
        var classes = ['alert', 'alert-dismissable'];
        var alertTypeClass = (_a = {},
            _a[ToastType.Success] = 'alert alert-success',
            _a[ToastType.Error] = 'alert alert-danger',
            _a[ToastType.Info] = 'alert alert-info',
            _a[ToastType.Warning] = 'alert alert-warning',
            _a);
        classes.push(alertTypeClass[alert.type]);
        if (alert.fade) {
            classes.push('fade');
        }
        return classes.join(' ');
    };
    return ToastComponent;
}(BaseComponent));
export { ToastComponent };
