import { Injectable, EventEmitter } from '@angular/core';

import { UsersService } from './users.service';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    timeout: any;
    notificationsCountEvent = new EventEmitter<number>();
    notificationsMainEvent = new EventEmitter<{ id_notification: number, action: string }>();
    notificationsShortEvent = new EventEmitter<{ id_notification: number, action: string }>();

    constructor(private userService: UsersService, private odataService: OdataService,
        private sessionService: SessionService) {
    }

    updateNotificationsCount() {
        if (sessionStorage.getItem('ACCOUNT_ACTIVE') == null) {
            return;
        }

        this.odataService.postPrivate('get_unread_notifications_count',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    this.notificationsCountEvent.emit(+result.Value);
                } else {
                    console.log('get_unread_notifications_count:', result.Status);
                }
            });
    }

    updateCount(value: number) {
        this.notificationsCountEvent.emit(value);
    }

    mainRemoved(id_notification: number) {
        const emiter = { id_notification: id_notification, action: 'remove' };
        this.notificationsShortEvent.emit(emiter);
    }

    shortRemoved(id_notification: number) {
        const emiter = { id_notification: id_notification, action: 'remove' };
        this.notificationsMainEvent.emit(emiter);
    }

    mainReadState(id_notification: number, state: boolean) {
        const emiter = { id_notification: id_notification, action: state ? 'read' : 'unread' };
        this.notificationsShortEvent.emit(emiter);
    }

    shortReaded(id_notification: number) {
        const emiter = { id_notification: id_notification, action: 'read' };
        this.notificationsMainEvent.emit(emiter);
    }

    realoadList() {
        this.restarTimer();
        const emiter = { id_notification: 0, action: 'reload' };
        this.updateNotificationsCount();
        this.notificationsShortEvent.emit(emiter);
        this.notificationsMainEvent.emit(emiter);
    }

    starTimer() {
        this.restarTimer();
    }

    stopTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    private restarTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.realoadList();
        }, 60000);
    }

}
