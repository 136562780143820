import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-attention-modal',
  templateUrl: './send-attention-modal.component.html',
  styleUrls: ['./send-attention-modal.component.scss']
})
export class SendAttentionModalComponent implements OnInit {

  isSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  show(): void {
    $('#saveAndSendAttentionExamModal').modal('show');
  }

  hide(): void {
    $('#saveAndSendAttentionExamModal').modal('hide');
  }

  isSendAndSave(isSend: boolean, isSave: boolean) {
      this.isSave.emit(isSave);
      this.isSend.emit(isSend);
  }

}
