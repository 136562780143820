<div id="top-bar">
  <div class="row">

    <div class="col-md-12">
      <h3 class="page-name">{{'SettingsScreens.Title' | translate}}</h3>
      <ul id="top-bar-nav">
        <li id="SettingsScreensUsers">
          <a routerLink="/medicalFacilityUsersSettings" style="cursor: pointer;">{{'SettingsScreens.Users' | translate}}</a>
        </li>
        <li id="SettingsScreensTeams">
          <a routerLink="/medicalFacilityTeamSettings" style="cursor: pointer;">{{'SettingsScreens.Teams' | translate}}</a>
        </li>
        <li id="SettingsScreensPermissions">
          <a routerLink="/medicalFacilityPermissionSettings" style="cursor: pointer;">{{'SettingsScreens.Permissions' | translate}}</a>
        </li>
        <li class="active" id="SettingsScreensRoles">
          <a routerLink="/medicalFacilityRoleSettings" style="cursor: pointer;">{{'SettingsScreens.Roles' | translate}}</a>
        </li>
      </ul>

      <form>
        <app-search #search placeholder="SettingsScreens.SearchPlaceholder"></app-search>
      </form>
    </div>

  </div>
</div>

<div class="container-settings">
  <div class="row">

    <div class="col-md-3" *ngFor="let role_item of settings_roles">
      <div class="card tiny-card">
        <span>{{role_item.role_name_value}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction1"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
          <a class="dropdown-item"
             (click)="onEditTeamMembers (role_item)">
            {{'SettingsScreens.RolesSettings.EditTeamMembers' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-double-select-modal (select)="onSelect($event)" #editTeamMembers
                         title="{{'SettingsScreens.RolesSettings.EditRole' | translate}}"
                         left_title="{{'SettingsScreens.RolesSettings.AvailableMembers' | translate}}"
                         right_title="{{'SettingsScreens.RolesSettings.AssignedMembers' | translate}}"></app-double-select-modal>
