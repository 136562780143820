import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { OrderDescriptionService } from '@/services';
import { AlertComponent } from '..';

@Component({
    selector: 'app-taking-description',
    templateUrl: './taking-description.component.html',
    styleUrls: ['./taking-description.component.css']
})
export class TakingDescriptionComponent implements OnInit {
    @ViewChild('alert') alert: AlertComponent;

    isTaking = false;
    password: string = '';

    decision: EventEmitter<boolean> = new EventEmitter();

    constructor(private descriptionService: OrderDescriptionService) {
    }

    handleLockTakeover(): Promise<boolean> {
        const modal = $('#takeDescriptionModal');
        modal.modal({ backdrop: 'static', keyboard: false });
        modal.modal('show');
        return new Promise((resolve) => {
            this.decision.subscribe(isLock => resolve(isLock));
        });
    }

    hide() {
        $('#takeDescriptionModal').modal('hide');
        this.decision.emit(false);
    }

    takeover() {
        this.descriptionService.takeOverExamLock(this.password).then(isLock => {
            if (isLock) {
                this.decision.emit(true);
                $('#takeDescriptionModal').modal('hide');
            } else if (!isLock) {
                this.alert.displayError('TAKEOVER_FAILED');
                setTimeout(() => {
                    this.alert.clear();
                    this.decision.emit(false);
                    $('#takeDescriptionModal').modal('hide');
                }, 3000);
            }
        });
    }

    ngOnInit() {
    }

    onKeyUp(e): void {
        if (e && e.keyCode === 13) { // Enter
            $('#ok-button').trigger('click');
        }
    }

}
