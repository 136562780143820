import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CovidAIStatistics, initCovidStats } from '@/interfaces/AIConsultation';

export interface CovidReportInfo {
  browserLink: string;
  consultationDate: string;
  toCopy: string;
}

// ----------------------------------------- -------
// ---- 29.06.21 breaking down big components ------
// -------------------------------------------------
@Component({
  selector: 'app-covid-card-content',
  templateUrl: './covid-card-content.component.html',
  styleUrls: ['./covid-card-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CovidCardContentComponent implements OnInit {
  @Input() covidStatistics: CovidAIStatistics = initCovidStats;
  @Input() covidReportInfo: CovidReportInfo = {browserLink: '', consultationDate: '', toCopy: ''};
  @Input() copiedText: string = '';
  @Output() copiedInside: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  openReport() {
    window.open(this.covidReportInfo.browserLink, '_blank');
  }
}
