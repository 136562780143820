import { Injectable } from '@angular/core';
import { Functions } from './DummyDataService';

export class Timer {
    timeout;
    time: string;
    private timeHours: number;
    private timeMinutes: number;

    constructor() {
        this.setTime();
    }

    updateTime(): void {
        this.timeMinutes++;
        if (this.timeMinutes == 60) {
            this.timeMinutes = 0;
            this.timeHours++;
            if (this.timeHours == 24) this.timeHours = 0;
        }

        if (this.timeHours < 10) this.time = '0' + this.timeHours + ':';
        else this.time = this.timeHours + ':';

        if (this.timeMinutes < 10) this.time += '0' + this.timeMinutes;
        else this.time += this.timeMinutes;

        this.timeout = setTimeout(() => {
            this.updateTime();
        }, 60000);
    }

    setTime(): void {
        let now: Date = Functions.getDate();
        this.timeHours = now.getHours();
        this.timeMinutes = now.getMinutes();

        if (this.timeHours < 10) this.time = '0' + this.timeHours + ':';
        else this.time = this.timeHours + ':';

        if (this.timeMinutes < 10) this.time += '0' + this.timeMinutes;
        else this.time += this.timeMinutes;

        this.timeout = setTimeout(() => {
            this.updateTime();
        }, (60 - now.getSeconds()) * 1000);
    }

    clearTimeout(): void {
        clearTimeout(this.timeout);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TimerService {
    constructor() {
    }
}
