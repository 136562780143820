import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UsersService, OdataService, ValidationService, SessionService } from '@/services';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PeselInputComponent, AlertComponent } from '@/utils';
import { RegistrationService } from '@/services/registration.service';
import { PageService } from '@/services/page.service';

interface IChangePass {
    Status: string;
    PasswordChangedFailedInfo: string;
}

@Component({
    selector: 'app-forgot-pass-second',
    templateUrl: './forgot-pass-second.component.html',
    styleUrls: ['./forgot-pass-second.component.css']
})
export class ForgotPassSecondComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlert') errorAlert: AlertComponent;
    @ViewChild('peselInput') peselInput: PeselInputComponent;

    userService: UsersService;
    checkRobot: boolean;
    resultGetInfo: {
        LoginName: string,
        IDTyp: string,
        FieldsToCheck: string,
        Language: string,
        Status: string,
        BlockedTimestamp: string
    } =
        {
            LoginName: '',
            IDTyp: '',
            FieldsToCheck: '',
            Language: '',
            Status: '',
            BlockedTimestamp: ''
        };
    resultChangePass: IChangePass;
    InfoMessage: any;
    newPassword: string = '';
    newPasswordRetry: string = '';
    fields;
    fieldsToCheck: number[];
    isDisable: boolean[];
    filledAray: number[] = [];
    filledFieldsString: string = '';
    uuid: string = '';
    errorField = '';


    constructor(userService: UsersService, private http: HttpClient, private route: ActivatedRoute,
                private router: Router, private odataService: OdataService, private translate: TranslateService,
                private session: SessionService, private registrationService: RegistrationService,
                private pageService: PageService) {
        this.userService = userService;
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
        });
        this.forgoten_password_check_robot();
        this.forgoten_password_step2_get_info();
        // this.odataService.getIMessage;
        this.pageService.setTranslatedPageTitle('ForgotFirstScreen.PageTitle', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    forgoten_password_step2_change_password() {
        this.errorField = '';
        this.peselInput.clearError();
        let passValidation = ValidationService.validatePass(this.newPassword);
        if (!passValidation) {
            this.errorField = 'pass';
            this.errorAlert.displayError('VALIDATE_PASS_FALSE');
        } else if (this.newPassword != this.newPasswordRetry) {
            this.errorAlert.displayError('PASS_MISMATCH');
            this.errorField = 'confirmPass';
        } else {
            this.odataService.postPublic('forgoten_password_step2_change_password',
                {
                    uuid: this.uuid,
                    password: this.newPassword,
                    filled_fields: this.peselInput.calculateFilledFieldsString()
                },
                res => {
                    let returnedJson = JSON.stringify(res);
                    this.resultChangePass = JSON.parse(returnedJson);
                    if (this.resultChangePass.Status == 'PASSWORD_CHANGED_SUCCESSFUL') {
                        this.registrationService.isPassChanged = true;
                        this.router.navigate(['/loginFirst']);
                    } else if (this.resultChangePass.Status == 'PASSWORD_CHANGED_FAILED') {
                        this.errorAlert.displayError(this.resultChangePass.PasswordChangedFailedInfo);
                        if (this.resultChangePass.PasswordChangedFailedInfo == 'ATTEMPT_FILLED_FIELDS_INCORRECT') {
                            this.peselInput.communicateError();
                        }
                    }
                });
        }
    }

    forgoten_password_check_robot() {
        this.odataService.postPublic('forgoten_password_check_robot',
            {session_id: this.session.getSessionId()},
            res => {
                let returnedJson = JSON.stringify(res);
                this.checkRobot = JSON.parse(returnedJson).value;
                if (this.checkRobot == false) {
                    // TODO
                }
            });
    }

    forgoten_password_step2_get_info() {
        this.odataService.postPublic('forgoten_password_step2_get_info',
            {uuid: this.uuid},
            res => {
                let returnedJson = JSON.stringify(res);
                this.resultGetInfo = JSON.parse(returnedJson);
                if (this.resultGetInfo.Status == 'OK') {
                    this.peselInput.fillFields(this.resultGetInfo.FieldsToCheck);
                } else {
                    this.errorAlert.displayError(this.resultGetInfo.Status);
                }
            });
    }


}
