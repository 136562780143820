import { Component, OnDestroy, OnInit } from '@angular/core';
import { statistics, IStatistics } from '@/interfaces';
import { UsersService } from '@/services';
import { ChartType } from 'chart.js';
import { MultiDataSet, Color } from 'ng2-charts';
import { PageService } from '@/services/page.service';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {
    statistics: IStatistics;
    userService: UsersService;

    ordersChartData: MultiDataSet = [[7, 3]];
    ordersChartType: ChartType = 'pie';
    ordersColors: Color[] = [{ backgroundColor: ['#0848FF', 'rgba(8, 72, 255, 0.15)'] }];

    slaChartData: MultiDataSet = [[7, 3]];
    slaChartType: ChartType = 'pie';
    slaColors: Color[] = [{ backgroundColor: ['#F5A623', '#D91657'] }];

    doughnutChartOptions: any = {
        borderWidth: 0,
        aspectRatio: 1,
        maintainAspectRatio: true,
        responsive: true,
        cutoutPercentage: 75,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        legend: {
            position: 'bottom',
            labels: {
                pointStyle: 'circle',
                usePointStyle: false
            }
        },
        tooltips: false,
        segmentShowStroke: false,
        hover: { mode: null }
    };

    constructor(public envService: EnvService, userService: UsersService, private pageService: PageService) {
        this.userService = userService;
    }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('StatisticsScreen.Title', 0);
        document.getElementById('statistics').setAttribute('class', 'active');
        this.getStatistics();
        this.ordersChartData = [[10, statistics.currentOrdersCount]];
        this.slaChartData = [[statistics.comeToExceededSLACount, statistics.exceededSLACount]];
    }

    ngOnDestroy(): void {
        document.getElementById('statistics').setAttribute('class', '');
        this.pageService.resetPageTitle();
    }

    getStatistics() {
        this.statistics = statistics;
    }
}
