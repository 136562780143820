import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IFacilityExams, initFacilityExam, IPriority, IToastInfo } from '@/interfaces';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OdataService, SessionService, UsersService } from '@/services';
import { HttpErrorResponse } from '@angular/common/http';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { FacilityOrdersService } from '@/services/facility-orders.service';
import { EnvService } from '@/services/env.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-create-order-modal',
  templateUrl: './create-order-modal.component.html',
  styleUrls: ['./create-order-modal.component.scss']
})
export class CreateOrderModalComponent implements OnInit {

  @ViewChild('ngAnatomySelect') anatomySelect: NgSelectComponent;
  @ViewChild('ngPrioritySelect') prioritySelect: NgSelectComponent;

  createOrderForm: FormGroup;

  isCollapseShown: boolean[] = [true, false, false];

  showToastEvent: EventEmitter<IToastInfo> = new EventEmitter<IToastInfo>();
  toast: IToastInfo;

  searchIcd10DataSource: Observable<any>;
  asyncSelectedIcd10: string;
  chosenIcd10: any = null;

  asyncSelectedDescCenter: string;
  searchDCDataSource: Observable<any>;
  typeaheadLoadingDc: boolean;
  Dc_search_value: number = undefined;
  anatomyPartOptions: any[] = [];
  priorityOptions: IPriority[] = [];
  attachmentOptions: any[] = [];
  promise: Promise<any>;
  attachmentsList: any[] = [];
  loadedFile: any = {type: '', name: ''};
  idExamOrder: number;
  chosenExam: IFacilityExams = initFacilityExam;
  chosenCenter: any = null;
  isValidSection: boolean[] = [true, true, true];
  isOpenedForFirstTime: boolean[] = [true, false, true];
  idc10_search_value: string = '';
  idc10_search_text: string;
  typeaheadLoadingIcd10: boolean;
  private Dc_search_text: string = '';

  constructor(private odataService: OdataService, private sessionService: SessionService, private userService: UsersService,
              private facilityExamsService: FacilityOrdersService, public envService: EnvService, private fb: FormBuilder) {
  }

  ngOnInit() {

    this.createOrderForm = this.fb.group({
      anatomyPart: [null, Validators.required]
    });

    this.searchIcd10DataSource = new Observable((observer: any) => {
      observer.next(this.asyncSelectedIcd10);
    }).pipe(
      mergeMap((token: string) => this.getIcd10DataAsObservable(token)),
      catchError(this.handleError)
    );

    this.searchDCDataSource = new Observable((observer: any) => {
      observer.next(this.asyncSelectedDescCenter);
    }).pipe(
      mergeMap((token: string) => this.getDcDataAsObservable(token)),
      catchError(this.handleError)
    );

    $('#createOrderModal').on('hidden.bs.modal', function () {
      $('#collapseOne').collapse('show');
    });
  }

  addExamOrderAttachment(base64: string) {
    let type = parseInt((<HTMLSelectElement>document.getElementById('attachment')).value);
    this.odataService.postPrivate('add_exam_order_attachment',
      this.odataService.paramsTokenAndLang({
        id_exam_order: this.idExamOrder,
        id_attachment_type: type,
        base64: base64,
        filename: this.loadedFile.name,
        content_type: this.loadedFile.type
      }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          result = JSON.parse(result.Value);
          result = JSON.parse(result);
          if (result.status) {
            this.attachmentsList.push({
              filename: this.loadedFile.name,
              id_attachment_type: type,
              attachment_uuid: result.attachment_uuid.attachment_uuid
            });
            this.showToastEvent.emit(this.createToastToEmit('AddFileSuccess', 'success'));
          } else this.showToastEvent.emit(this.createToastToEmit('AddFileError', 'error'));
        } else this.showToastEvent.emit(this.createToastToEmit('AddFileError', 'error'));
      });
  }

  changeTypeaheadLoadingIcd10(e: boolean) {
    this.typeaheadLoadingIcd10 = e;
  }

  changeTypeaheadLoadingDc(e: boolean): void {
    this.typeaheadLoadingDc = e;
  }

  createToastToEmit(message: string, type: string, timeout?: number, refresh?: boolean): IToastInfo {
    return {
      message: message,
      type: type,
      timeout: timeout ? timeout : 3000,
      refresh: refresh ? refresh : false
    };
  }

  dataMap(result) {
    let to_return = [];

    if (result.Status === 'OK') {
      let json: any = JSON.parse(result.Value);
      if (document.getElementById('centerSearch') === document.activeElement) to_return = JSON.parse(json.json_agg);
      else to_return = json;
    } else {
      console.log('REASON:', result.Status);
    }
    return to_return;
  }

  delExamOrderAttachment(index: number) {
    this.odataService.postPrivate('del_exam_order_attachment',
      this.odataService.paramsTokenAndLang({
        id_exam_order: this.idExamOrder,
        attachment_uuid: this.attachmentsList[index].attachment_uuid
      }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          result = JSON.parse(result.Value);
          result = JSON.parse(result);
          if (result.status) {
            this.showToastEvent.emit(this.createToastToEmit('RemoveFileSuccess', 'success'));
            this.attachmentsList = this.attachmentsList.filter(x => x.attachment_uuid != this.attachmentsList[index].attachment_uuid);
          } else this.showToastEvent.emit(this.createToastToEmit('RemoveFileError', 'error'));
        } else this.showToastEvent.emit(this.createToastToEmit('RemoveFileError', 'error'));
      });
  }

  getAnatomyPartList(): Promise<any> {
    return this.odataService.postPrivate('get_anatomy_parts',
      this.odataService.paramsTokenAndLang({}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          this.anatomyPartOptions = JSON.parse(result.Value);
        }
        return result;
      });
  }

  getIcd10DataAsObservable(search: string): Observable<any> {
    let srch = search ? search : '';
    return this.odataService.postPrivateObservable('get_icd10_codes_list_filtr', this.odataService.paramsTokenAndLang({
      filtr: srch
    })).pipe(
      map(data => this.dataMap(data)),
    );
  }

  setInfoPromises() {
    let promises: Promise<any>[] = [];
    promises.push(this.getAnatomyPartList());
    promises.push(this.getPriorityList());
    promises.push(this.getExamOrderAttachmentTypeList());
    this.promise = Promise.all(promises);
    this.setInitModalSetup();
  }

  getDcDataAsObservable(search: string): Observable<any> {
    let srch = search ? search : '';
    return this.odataService.postPrivateObservable('get_cu_list_for_mf', this.odataService.paramsTokenAndLang({
      id_medical_facility: this.userService.getFacilityId(),
      search_text: srch,
      mode: srch.length == 0 ? 1 : 2
    })).pipe(
      map(data => this.dataMap(data)),
    );
  }

  getExamOrderAttachmentTypeList(): Promise<any> {
    return this.odataService.postPrivate('get_exam_order_attachment_type_list',
      this.odataService.paramsTokenAndLang({}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          this.attachmentOptions = JSON.parse(result.Value);
        }
        return result;
      });
  }

  getPriorityList(): Promise<any> {
    return this.odataService.postPrivate('get_priority_types',
      this.odataService.paramsTokenAndLang({}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          this.priorityOptions = JSON.parse(result.Value);
        }
        return result;
      });
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    return of([]);
  }

  handleFile(event) {
    let binaryString = event.target.result;
    let base64 = btoa(binaryString);
    base64 = base64.replace(/\+/gi, '-');
    base64 = base64.replace(/\//gi, '_');
    this.addExamOrderAttachment(base64);
  }

  isValidNewOrderModal(index?: number): boolean {
    if (index) {
      if (index == 0) return this.validateCollapseOne();
      else return this.validateCollapseThree();
    } else {
      return this.validateCollapseOne() && this.validateCollapseThree();
    }
  }

  isSendDisabled(): boolean {
    return !this.anatomySelect.hasValue
      || !this.chosenIcd10
      || !this.chosenCenter;
  }

  mfCreateExamOrderEndTransaction() {
    if (!this.isValidNewOrderModal()) {

    } else {
      this.odataService.postPrivate('mf_create_exam_order_end_transaction',
        this.odataService.paramsTokenAndLang({
          id_exam_order: this.idExamOrder,
          anatomy_part: parseInt(<string>this.anatomySelect.selectedValues[0]),
          icd10_code: this.chosenIcd10.icd10_code,
          order_notes: (<HTMLSelectElement>document.getElementById('comment')).value,
          exam_priority: parseInt(<string>this.prioritySelect.selectedValues[0]),
          id_describing_center: this.chosenCenter.id_describing_center
        }),
        res => {
          let returnedJson = JSON.stringify(res);
          let result = JSON.parse(returnedJson);
          $('#createOrderModal').modal('hide');
          if (result.Status == 'OK') {
            result = JSON.parse(result.Value);
            result = JSON.parse(result);
            if (result.status) this.showToastEvent.emit(this.createToastToEmit('CreateOrderSuccess', 'success', undefined, true));
            else this.showToastEvent.emit(this.createToastToEmit('CreateOrderError', 'error'));
          } else this.showToastEvent.emit(this.createToastToEmit('CreateOrderError', 'error'));
        });
    }
  }

  mfCreateExamOrderStartTransaction(idExam: number): Promise<any> {
    return this.odataService.postPrivate('mf_create_exam_order_start_transaction',
      this.odataService.paramsTokenAndLang({id_exam: idExam, id_medical_facility: this.userService.getFacilityId()}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        return JSON.parse(result.Value);
      });
  }

  onFileSelected(event: any) {
    let files = event.target.files;
    let file = files[0];
    if (file.size > 5242880) {
      this.showToastEvent.emit(this.createToastToEmit('BigFile', 'error'));
    } else {
      this.loadedFile = {
        type: file.name.split('.').pop(),
        name: file.name
      };

      if (files && file) {
        let reader = new FileReader();
        reader.onload = this.handleFile.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }

  onValueChangeIcd10() {
    if (this.idc10_search_value !== undefined) {
      this.idc10_search_value = undefined;
      this.idc10_search_text = '';
      this.validateCollapseOne();
    } else this.chosenIcd10 = null;
  }

  onValueChange() {
    if (this.Dc_search_value !== undefined) {
      this.Dc_search_value = undefined;
      this.Dc_search_text = '';
    } else this.chosenCenter = null;
    if (!this.isOpenedForFirstTime[2]) {
      this.validateCollapseThree();
    }
  }

  setCollapseShown(collapse: string, index: number) {
    for (let i = 0; i < this.isCollapseShown.length; i++) {
      if (this.isCollapseShown[i] && i != 1) {
        this.isOpenedForFirstTime[i] = false;
        this.isValidNewOrderModal(i);
      }
      this.isCollapseShown[i] = false;
    }
    if (!$('#' + collapse).hasClass('show')) {
      this.isCollapseShown[index] = true;
      if (index == 2 && this.isOpenedForFirstTime[2]) {
        document.getElementById('centerSearch').setAttribute('style', 'border-radius: 0.3rem !important;');
      }
    }
  }

  setDataToModal(icd10?: string, exam_priority?: string, registration_notes?: string) {
    this.setInitModalSetup();
    if (this.chosenExam.anatomy_part) {
      let item = this.anatomySelect.itemsList.findItem(this.chosenExam.anatomy_part.id_part.toString());
      this.anatomySelect.select(item);
    } else this.anatomySelect.handleClearClick();
    if (document.getElementById('anatomyPart'))
      document.getElementById('anatomyPart').parentElement.removeAttribute('style');
    if (icd10) {
      this.chosenIcd10 = {icd10_code: icd10};
      $('#icd10Search').val(icd10).trigger('change');
    } else {
      $('#icd10Search').val('');
      this.chosenIcd10 = null;
    }
    if (document.getElementById('icd10Search'))
      document.getElementById('icd10Search').removeAttribute('style');
    if (exam_priority) {
      let item = this.prioritySelect.itemsList.findItem(exam_priority);
      this.prioritySelect.select(item);
    } else {
      let itemsList = this.prioritySelect.itemsList;
      this.prioritySelect.select(itemsList.items[0]);
    }
    if (document.getElementById('comment')) {
      if (registration_notes) (<HTMLSelectElement>document.getElementById('comment')).value = registration_notes;
      else (<HTMLSelectElement>document.getElementById('comment')).value = '';
    }
    if (document.getElementById('centerSearch')) {
      (<HTMLSelectElement>document.getElementById('centerSearch')).value = '';
      document.getElementById('centerSearch').setAttribute('style', 'border-radius: 0.3rem !important;');
    }
    if (this.chosenCenter) this.chosenCenter = null;
    $('#createOrderModal').modal('show');
  }

  setInitModalSetup() {
    this.isCollapseShown = [true, false, false];
    $('#collapseOne').collapse('show');
    this.isValidSection = [true, true, true];
    this.isOpenedForFirstTime = [true, false, true];
    this.attachmentsList = [];
  }

  show(idExam: number) {
    this.mfCreateExamOrderStartTransaction(idExam).then(
      res => {
        if (res.status) {
          this.idExamOrder = res.id_transaction.id_exam_order;
          this.chosenExam = this.facilityExamsService.exams.find(x => x.id_exam == idExam);
          if (res.extra_info) {
            this.promise.then(() => this.setDataToModal(res.extra_info.icd10, res.extra_info.exam_priority, res.extra_info.registration_notes));
          } else this.promise.then(() => this.setDataToModal());
        }
      }
    );
  }

  typeaheadOnIcd10Select(e: TypeaheadMatch) {
    this.idc10_search_value = e.item.icd10_code;
    this.idc10_search_text = e.item.icd10_text;
    this.chosenIcd10 = e.item;
    this.validateCollapseOne();
  }

  typeaheadOnDcSelect(e: TypeaheadMatch): void {
    this.Dc_search_value = e.item.id_describing_center;
    this.Dc_search_text = e.item.name;
    this.chosenCenter = e.item;
    this.validateCollapseThree();
  }

  validateCollapseOne(name?: string): boolean {
    let res = true;
    this.isValidSection[0] = true;
    if (!this.anatomySelect.hasValue) {
      this.isValidSection[0] = false;
      res = false;
    }
    if (!this.chosenIcd10) {
      if (!name || name == 'icd10') {
        document.getElementById('icd10Search').setAttribute('style', 'border: 2px solid red; border-radius: 0.3rem !important;');
      }
      this.isValidSection[0] = false;
      res = false;
    } else document.getElementById('icd10Search').setAttribute('style', 'border-radius: 0.3rem !important;');
    return res;
  }

  validateCollapseThree() {
    let res = true;
    if (!this.chosenCenter) {
      document.getElementById('centerSearch').setAttribute('style', 'border: 2px solid red; border-radius: 0.3rem !important;');
      this.isValidSection[2] = false;
      res = false;
    } else {
      document.getElementById('centerSearch').setAttribute('style', 'border-radius: 0.3rem !important;');
      this.isValidSection[2] = true;
    }
    return res;
  }

  log(e) {
    console.log(e);
  }
}
