import { EnvService } from '@/services/env.service';
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UsersService, OdataService, ValidationService, SessionService } from '@/services';
import { Router, ActivatedRoute } from '@angular/router';
import './registrationInterface';
import { AlertComponent } from '@/utils';
import { RegistrationService } from '@/services/registration.service';
import { PageService } from '@/services/page.service';
var RegistrationFirstComponent = /** @class */ (function () {
    function RegistrationFirstComponent(userService, router, env, odataService, formBuilder, session, registrationService, pageService, route) {
        this.router = router;
        this.env = env;
        this.odataService = odataService;
        this.formBuilder = formBuilder;
        this.session = session;
        this.registrationService = registrationService;
        this.pageService = pageService;
        this.route = route;
        this.portalTerms = { TermID: 0, TermsContents: '' };
        this.registrationStep1 = {
            language: '',
            email: '',
            password: '',
            id_term: 0,
            id_agreement_list: [],
            session_id: '',
            client_ip: ''
        };
        this.agreementList = [];
        this.termRequired = [];
        this.uuid = '';
        this.errorField = '';
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.maxLength(200)]],
            pass: ['', [Validators.required, ValidationService.passwordValidator, Validators.maxLength(64)]],
            retryPass: ['', Validators.required],
            checkRegulations: [false, Validators.requiredTrue],
            agreements: this.formBuilder.array([])
        }, { validators: ValidationService.matchingPasswordsValidator });
        this.userService = userService;
    }
    Object.defineProperty(RegistrationFirstComponent.prototype, "agreements", {
        get: function () {
            return this.registerForm.get('agreements');
        },
        enumerable: true,
        configurable: true
    });
    RegistrationFirstComponent.prototype.addAgreement = function (required) {
        if (required) {
            this.agreements.push(this.formBuilder.control(false, Validators.requiredTrue));
        }
        else {
            this.agreements.push(this.formBuilder.control(false));
        }
    };
    RegistrationFirstComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.env.registrationDisabled)
            this.getDefaultData();
        else {
            this.route.queryParams.subscribe(function (param) {
                if (param.invitation_uuid) {
                    _this.uuid = param.invitation_uuid;
                }
            });
            if (this.uuid != '') {
                this.checkRegistrationUuid().then(function (res) {
                    if (res) {
                        _this.getDefaultData();
                    }
                    else {
                        _this.router.navigate([_this.env.registrationDisabledUrl]);
                    }
                });
            }
            else
                this.router.navigate([this.env.registrationDisabledUrl]);
        }
        this.pageService.setSignUpPageTitle();
    };
    RegistrationFirstComponent.prototype.ngOnDestroy = function () {
        this.pageService.resetPageTitle();
    };
    RegistrationFirstComponent.prototype.getDefaultData = function () {
        this.registrationService.isLogout = false;
        this.registrationStep1.language = this.userService.iLanguage;
        if (!this.session.getSessionId())
            this.session.setNewSessionId();
        this.registrationStep1.session_id = this.session.getSessionId();
        this.get_portal_terms(this.registrationStep1.language);
        this.get_agreement_list_for_registration(this.registrationStep1.language);
    };
    RegistrationFirstComponent.prototype.checkRegistrationUuid = function () {
        return this.odataService.postPublic('check_invitation_uuid', { registration_uuid: this.uuid }, function (res) {
            var json = JSON.stringify(res);
            var result = JSON.parse(json);
            return result.value;
        });
    };
    RegistrationFirstComponent.prototype.get_portal_terms = function (language) {
        var _this = this;
        this.odataService.postPublic('get_portal_terms', { language: language }, function (res) {
            var returnedJson = JSON.stringify(res);
            _this.portalTerms = JSON.parse(returnedJson);
            _this.registrationService.iPortalTerms = _this.portalTerms;
            _this.registrationStep1.id_term = _this.portalTerms.TermID;
            _this.portalTerms.TermsContents = '* ' + _this.portalTerms.TermsContents;
        });
    };
    RegistrationFirstComponent.prototype.get_agreement_list_for_registration = function (language) {
        var _this = this;
        this.odataService.postPublic('get_agreement_list_for_registration', { language: language }, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            _this.agreementList = result.value;
            _this.agreementList.forEach(function (agreement) {
                if (agreement.IsRequired) {
                    agreement.AgreementContents = '* ' + agreement.AgreementContents;
                    _this.addAgreement(true);
                }
                else {
                    _this.addAgreement(false);
                }
            });
        });
    };
    RegistrationFirstComponent.prototype.submitDisabled = function () {
        return !this.registerForm.get('email').value || !this.registerForm.get('pass').value
            || !this.registerForm.get('retryPass').value;
    };
    RegistrationFirstComponent.prototype.validateForm = function () {
        var errors = {
            'email': 'email',
            'pass': 'pass',
            'confirmPass': 'confirmPass',
            'agreements': 'agreements',
            'TOO_LONG_PASS': 'TOO_LONG_PASS',
            'TOO_LONG_EMAIL': 'TOO_LONG_EMAIL',
            '': ''
        };
        var error = '';
        this.errorField = '';
        this.errorAlert.clear();
        var result = false;
        if (!this.registerForm.get('email').valid) {
            this.errorField = 'email';
            if (this.registerForm.get('email').value.length > 200) {
                error = 'TOO_LONG_EMAIL';
            }
            else {
                error = 'email';
            }
        }
        else if (!this.registerForm.get('pass').valid) {
            this.errorField = 'pass';
            if (this.registerForm.get('pass').value.length > 64) {
                error = 'TOO_LONG_PASS';
            }
            else {
                error = 'pass';
            }
        }
        else if (this.registerForm.errors && this.registerForm.errors.passwordsMatch) {
            this.errorField = 'confirmPass';
            error = 'confirmPass';
        }
        else if (!this.agreements.valid || !document.getElementById('regulations').checked) {
            this.errorField = 'agreements';
            error = 'agreements';
        }
        else {
            result = true;
        }
        if (!result) {
            this.errorAlert.displayError(errors[error]);
        }
        return result;
    };
    RegistrationFirstComponent.prototype.handleErrorFromServer = function (errorMessage) {
        var errors = {
            'WRONG_PASSWORD': 'pass',
            'AGREEMENT_MISSING': '',
            'OTHER_REASON': ''
        };
        this.errorField = errors[errorMessage];
        this.errorAlert.displayError(errorMessage);
    };
    RegistrationFirstComponent.prototype.fillStep1WithFormData = function () {
        var _this = this;
        this.registrationStep1.email = this.registerForm.get('email').value;
        this.registrationStep1.password = this.registerForm.get('pass').value;
        this.registrationStep1.id_agreement_list = this.agreements.value.reduce(function (array, x, i) {
            if (x) {
                array.push(_this.agreementList[i].AgreementID);
            }
            return array;
        }, []);
    };
    RegistrationFirstComponent.prototype.register_user_step1 = function () {
        var _this = this;
        if (this.validateForm()) {
            this.fillStep1WithFormData();
            this.registrationService.iRegistration1 = this.registrationStep1;
            sessionStorage.setItem('register_mail', this.registrationStep1.email);
            this.odataService.postPublic('register_user_step1', this.registrationStep1, function (res) {
                var returnedJson = JSON.stringify(res);
                var registerStep1Status = JSON.parse(returnedJson);
                _this.registrationService.iRegisterStep1Status = registerStep1Status;
                if (registerStep1Status.RegistrationStatus === 'REGISTRATION_OK') {
                    _this.router.navigate(['registrationSecond', registerStep1Status.RegistrationUuid]);
                }
                else if (registerStep1Status.RegistrationStatus === 'REGISTRATION_FAILED') {
                    _this.handleErrorFromServer(registerStep1Status.RegistrationFailedReason);
                }
            });
        }
    };
    return RegistrationFirstComponent;
}());
export { RegistrationFirstComponent };
