import { EventEmitter } from '@angular/core';
import { OdataService } from './odata.service';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
var DraftSenderService = /** @class */ (function () {
    function DraftSenderService(odataService) {
        this.odataService = odataService;
        this.lastRequestFinished = true;
        this.requestsCount = 0;
        this.finalParams = null;
        this.draftSentEmitter = new EventEmitter();
        this.isSaved = false;
        this.isChanged = false;
    }
    DraftSenderService.prototype.saveExamDraft = function (params) {
        var _this = this;
        if (this.lastRequestFinished) {
            this.lastRequestFinished = false;
            this.odataService.postPrivate('save_exam_description_draft', params, function (res) {
                if (_this.finalParams != null) {
                    _this.sendFinal();
                }
                else {
                    _this.lastRequestFinished = true;
                    var returnedJson = JSON.stringify(res);
                    var result = JSON.parse(returnedJson);
                    var event_1 = { success: result.ActionStatus, exam_id: params.id_exam_order };
                    _this.draftSentEmitter.emit(event_1);
                }
                return res;
            });
        }
        else {
            this.finalParams = params;
            this.requestsCount++;
        }
        this.isSaved = true;
        this.isChanged = false;
    };
    DraftSenderService.prototype.sendFinal = function () {
        var _this = this;
        this.requestsCount = 0;
        this.odataService.postPrivate('save_exam_description_draft', this.finalParams, function (res) {
            if (_this.requestsCount == 0) {
                var returnedJson = JSON.stringify(res);
                var result = JSON.parse(returnedJson);
                var event_2 = { success: result.ActionStatus, exam_id: _this.finalParams.id_exam_order };
                _this.draftSentEmitter.emit(event_2);
                _this.finalParams = null;
                _this.lastRequestFinished = true;
            }
            else {
                _this.sendFinal();
            }
            return res;
        });
    };
    DraftSenderService.ngInjectableDef = i0.defineInjectable({ factory: function DraftSenderService_Factory() { return new DraftSenderService(i0.inject(i1.OdataService)); }, token: DraftSenderService, providedIn: "root" });
    return DraftSenderService;
}());
export { DraftSenderService };
