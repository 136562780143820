import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface EItem {
    id: number;
    text: string;
    img_src?: string;
    extra?: {};
}

@Component({
    selector: 'app-double-select-modal',
    templateUrl: './double-select-modal.component.html',
    styleUrls: ['./double-select-modal.component.css']
})
export class DoubleSelectModalComponent implements OnInit {
    select1: EItem[];
    select1Start: EItem[] = new Array();
    select2: EItem[];
    select2Start: EItem[] = new Array();
    id: string = 'doubleSelectModal';

    @Input() title: string;
    @Input() subtitle: string;
    @Input() left_title: string;
    @Input() right_title: string;

    @Output() select = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    show(): void {
        $('#' + this.id).modal('show');
    }

    hide(): void {
        $('#' + this.id).modal('hide');
    }

    setSubtitle (sub: string) {
        this.subtitle = sub;
    }

    setContent(s1: EItem[], s2: EItem[]): void {
        this.select1 = s1;
        this.select2 = s2;
        this.copyArray(this.select1Start, s1);
        this.copyArray(this.select2Start, s2);
    }

    copyArray(a1: EItem[], a2: EItem[]): void {
        a1.length = 0;
        for (let i = 0; i < a2.length; ++i) {
            a1.push(a2[i]);
        }
    }

    setId(newId: string): void {
        document.getElementById(this.id).setAttribute('id', newId);
        this.id = newId;
    }

    cancelChanges(): void {
        this.copyArray(this.select1, this.select1Start);
        this.copyArray(this.select2, this.select2Start);
        this.hide();
    }

    onAssign(): void {
        let selectedOptions = (<HTMLSelectElement>document.getElementById('left_select_' + this.id)).selectedOptions;
        let selected = [];

        for (let i = 0; i < selectedOptions.length; ++i) {
            selected.push(+selectedOptions[i].value);
        }

        let removed = [];

        for (let i = 0; i < this.select1.length; i++) {
            if (selected.indexOf (this.select1[i].id) > -1) {
                removed.push ((this.select1.splice (i, 1))[0]);
                i--;
            }
        }

        for (let i = 0; i < removed.length; i++) {
            this.select2.push(removed [i]);
        }
    }

    onRemove(): void {
        let selectedOptions = (<HTMLSelectElement>document.getElementById('right_select_' + this.id)).selectedOptions;
        let selected = [];

        for (let i = 0; i < selectedOptions.length; ++i) {
            selected.push(+selectedOptions[i].value);
        }

        let removed = [];

        for (let i = 0; i < this.select2.length; i++) {
            if (selected.indexOf(this.select2[i].id) > -1) {
                removed.push((this.select2.splice(i, 1))[0]);
                i--;
            }
        }

        for (let i = 0; i < removed.length; i++) {
            this.select1.push(removed[i]);
        }
    }

    onSave (): void {
        let event = { id: this.id, selected: this.select2 };
        this.select.emit(event);
    }

}
