<div class="register-screen">
    <div class="container-fluid">
        <div class="row vh-100">
            <div class="col col-sm-12 col-md-6 col-lg-5 p-100">
                <div class="row">
                    <div class="col">
                        <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                    </div>
                </div>

                <div>
                    <app-alert #errorAlert title="Registration"></app-alert>
                    <br>
                    <button routerLink="/loginFirst" class="btn btn-lg btn-primary">
                        {{'AlreadyRegisteredScreen.ReturnToLoginFirst' | translate}}
                    </button>
                </div>
            </div>
            <div class="col col-sm-12 col-md-6 col-lg-7
                  bg p-100 p-desktop align-items-center
                  d-flex ">

                <app-language-button></app-language-button>
            </div>
        </div>
    </div>
</div>