<ng-container *ngIf="screenSwitch.agreementScreen">
  <div class="pp--columns vertical-center mt-4">
    <div class="pp--column no-shrink">

      <img src="../../../../assets/images/pacjent.png" alt="Pacjent">

    </div>
    <div class="pp--column">

      <form [formGroup]="permissionCheckbox">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input formControlName="userAgreement" class="custom-control-input" id="userAgreement" type="checkbox">
            <label class="custom-control-label" for="userAgreement">
              {{'PatientsPortal.DashboardScreen.AgreementPt1' |translate}} <a
              href="{{envService.regulationsPath}}" target="_blank">{{'PatientsPortal.DashboardScreen.TermsOfUse'|translate}}</a>
              {{'PatientsPortal.DashboardScreen.And' |translate}} <a
              href="{{envService.privacyPolicyPath}}" target="_blank">{{'PatientsPortal.DashboardScreen.PrivacyPolicy'|translate}}</a>
              {{'PatientsPortal.DashboardScreen.AgreementPt2'|translate}}
            </label>

          </div>
        </div>
      </form>
      <button [disabled]="permissionCheckbox.controls.userAgreement.value == false" class="btn btn-md btn-primary"
              style="width: 100%"
              (click)="setAgreement(AGREED_NO_CLOUD)">
        {{'PatientsPortal.DashboardScreen.FindExamsButton'|translate}}</button>

      <a (click)="setAgreement(AGREEMENT_SKIPPED)" class="agreement_skipped">{{'PatientsPortal.DashboardScreen.Skip'|translate}}</a>

    </div>
  </div>
</ng-container>
<ng-container *ngIf="!screenSwitch.agreementScreen">
  <br />
  <h3 *ngIf="screenSwitch.searchScreen">{{'PatientsPortal.AgreementCard.ExamSearching' |translate}}</h3>
  <h3 *ngIf="screenSwitch.foundExamsScreen">{{'PatientsPortal.AgreementCard.FoundExamsTitle' |translate}}</h3>
  <h3 *ngIf="screenSwitch.noExamsScreen">{{'PatientsPortal.AgreementCard.FoundExamsTitle' |translate}}</h3>
  <ng-container *ngIf="screenSwitch.searchScreen">
    <div class="progress" style="margin: 30px 0;">
      <div class="progress-bar" role="progressbar" [style.width]="progress + '%'" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    {{'PatientsPortal.AgreementCard.ExamSearchText' |translate}}
  </ng-container>
  <ng-container *ngIf="screenSwitch.foundExamsScreen">
    <div class="progress" style="margin: 30px 0;">
      <div class="progress-bar" role="progressbar" aria-valuenow="100" style="width: 100%" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    {{'PatientsPortal.AgreementCard.FoundExamsTxt' |translate}} {{examListSize}}
    <ng-container *ngIf="examListSize % 2 == 0 || examListSize % 10 == 0">{{'PatientsPortal.AgreementCard.ExamsOpt2' |translate}}</ng-container>
    <ng-container *ngIf="examListSize % 2 != 0 && examListSize % 10 != 0">{{'PatientsPortal.AgreementCard.ExamsOpt1' |translate}}</ng-container>
    <button class="btn btn-md btn-primary" (click)="agreementResult.emit(AGREED_NO_CLOUD)">{{'PatientsPortal.AgreementCard.ShowExamsBtn' |translate}}</button>
  </ng-container>
  <ng-container *ngIf="screenSwitch.noExamsScreen">
    <div class="progress" style="margin: 30px 0;">
      <div class="progress-bar" role="progressbar" aria-valuenow="100" style="width: 100%" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <h3>{{'PatientsPortal.AgreementCard.NoExamsTitle' |translate}}</h3><br>
    {{'PatientsPortal.AgreementCard.NoExamsTxtPt1' |translate}}<br>
    {{'PatientsPortal.AgreementCard.NoExamsTxtPt2' |translate}}
    <a (click)="agreementResult.emit(AGREED_NO_CLOUD)">{{'PatientsPortal.AgreementCard.CloseNoExamsBtn' |translate}}</a>
  </ng-container>
</ng-container>
