<div class="form-row">
    <div class="col form-group">
        <div>
            <label for="datepicker-range-date-archive">{{'DashboardScreen.Filters.DateRange' | translate}}</label>
            <div>
                <input #placeholderInput class="datepicker-placeholder" id="placeholder-input-archive"
                       type="text" name="placeholder_name" [(ngModel)]="currentDateValue"
                       placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
                       autocomplete="off">
            </div>
            <div id="ghost-div">
                <input #pickerDateRange id="datepicker-range-date-archive" type="text" name="daterangeInput"
                       daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
                       class="daterangeInputClass"/>
            </div>
        </div>
    </div>
    <div class="col form-group filter">
        <label for="statusArchive">{{'DashboardScreen.Filters.Status' | translate}}</label>
        <select id="statusArchive" class="custom-select" multiple>
            <option *ngFor="let status of statusTypesForArchive" value="{{status.value.id_status}}">
                {{status.value.status}}
            </option>
        </select>
    </div>
</div>
<div class="form-row">
    <div class="col form-group filter">
        <label for="modalityArchive">{{'DashboardScreen.Filters.Modality' | translate}}</label>
        <select id="modalityArchive" class="custom-select" multiple>
            <option *ngFor="let modality of modalityTypes" value="{{modality.value.id_group}}">
                {{modality.value.value}}
            </option>
        </select>
    </div>
    <div class="col form-group filter">
        <label for="anatomyArchive">{{'DashboardScreen.Filters.AnatomyPart' | translate}}</label>
        <select id="anatomyArchive" class="custom-select" multiple>
            <option *ngFor="let anatomy of anatomyParts" value="{{anatomy.value.id_part}}">
                {{anatomy.value.value}}
            </option>
        </select>
    </div>
</div>
<div class="form-row">
    <div class="col form-group filter">
        <label for="assignedToArchive">{{'DashboardScreen.Filters.AssignedTo' | translate}}</label>
        <select id="assignedToArchive" class="custom-select" multiple>
            <option *ngFor="let doctor of assignedToList" value="{{doctor.value.id_physician}}">
                {{doctor.value.name}}
            </option>
        </select>
    </div>
    <div class="col form-group filter">
        <label for="priorityArchive">{{'DashboardScreen.Filters.Priority' | translate}}</label>
        <select id="priorityArchive" class="custom-select" multiple>
            <option *ngFor="let priority of priorityTypes" value="{{priority.value.id_priority}}">
                {{priority.value.priority_text}}
            </option>
        </select>
    </div>
</div>
<div class="form-row">
    <div class="col form-group">
        <div>
            <label for="datepicker-range-date-desc">{{'DashboardScreen.Filters.DescriptionDateRange' | translate}}</label>
            <div>
                <input #placeholderInputDesc class="datepicker-placeholder" id="placeholder-input-desc"
                       type="text" name="placeholder_name" [(ngModel)]="currentDescDateValue"
                       placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
                       autocomplete="off">
            </div>
            <div id="ghost-div-desc">
                <input #pickerDescDateRange id="datepicker-range-date-desc" type="text" name="daterangeInput"
                       daterangepicker [options]="options" (selected)="selectedDate($event, daterangeDesc)"
                       class="daterangeInputClass"/>
            </div>
        </div>
    </div>
    <div class="col form-group filter">
        <label for="facilityArchive">{{'DashboardScreen.Filters.FacilityArchive' | translate}}</label>
        <select id="facilityArchive" class="custom-select" multiple>
            <option *ngFor="let facility of facilityList" value="{{facility.value.id_medical_facility}}">
                {{facility.value.medical_facility_name}}
            </option>
        </select>
    </div>
</div>
