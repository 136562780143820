import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DoubleSelectModalComponent } from '@/utils/double-select-modal/double-select-modal.component';
import { OdataService, SessionService, UsersService } from '@/services';
import { EnvService } from '@/services/env.service';
import { PageService } from '@/services/page.service';

@Component({
  selector: 'app-medical-facility-role-settings',
  templateUrl: './medical-facility-role-settings.component.html',
  styleUrls: ['./medical-facility-role-settings.component.scss']
})
export class MedicalFacilityRoleSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('editTeamMembers') editTeamMembers: DoubleSelectModalComponent;

  odataService: OdataService;
  userService: UsersService;
  settings_roles: any[];

  constructor(userService: UsersService, odataService: OdataService, private sessionService: SessionService,
              private env: EnvService, private pageService: PageService) {
    this.odataService = odataService;
    this.userService = userService;
  }

  ngOnInit() {
    this.get_system_roles ();
    this.pageService.setSettingPageTranslatedTitle('SettingsScreens.Roles', 0);
    document.getElementById('facilitySettings').setAttribute('class', 'active');
  }

  ngOnDestroy () {
    this.pageService.resetPageTitle();
    document.getElementById('facilitySettings').setAttribute('class', '');
  }

  get_system_roles () {
    this.settings_roles = [
      {id_role: 1000, role_name_value: 'Własciciel'},
      {id_role: 1001, role_name_value: 'Lekarz'},
      {id_role: 1002, role_name_value: 'Koordynator'},
      {id_role: 1003, role_name_value: 'Administrator'}
    ];
  }

  get_users_by_role(id_role: number) {
    let not_granted: any[] = [
      {id: 548, text: 'Robert Frankowski', img_src: '../../assets/images/icons/user.svg'},
      {id: 585, text: 'Zachariasz Karpowicz', img_src: 'https://files.emkaweb.pl/miniprofile/651a1c72-120e-46a9-9019-d6a913805dcc'},
      {id: 574, text: 'Zachariasz Karpowicz', img_src: 'https://files.emkaweb.pl/miniprofile/d7d2e68b-f639-45ce-aa78-156fbe3b40e8'},
      {id: 643, text: 'Marko Polio', img_src: '../../assets/images/icons/user.svg'},
      {id: 674, text: 'Jan Kowalski', img_src: '../../assets/images/icons/user.svg'}
    ];
    let granted: any[] = [
      {id: 577, text: 'Marek Targonski', img_src: 'https://files.emkaweb.pl/miniprofile/af2b2d58-885d-4fe0-99c0-65f1b02a856b'},
      {id: 551, text: 'Paweł Kowalski', img_src: '../../assets/images/icons/user.svg'},
      {id: 575, text: 'Zachariasz Karpowicz', img_src: '../../assets/images/icons/user.svg'},
      {id: 584, text: 'Zachariasz Karpowicz', img_src: '../../assets/images/icons/user.svg'},
      {id: 586, text: 'Zachariasz Karpowicz', img_src: '../../assets/images/icons/user.svg'},
      {id: 521, text: 'Piotr Filipczuk', img_src: 'https://files.emkaweb.pl/miniprofile/a54f739e-225d-4c9a-9492-1e29569e893b'},
      {id: 523, text: 'Jan Kowalski', img_src: 'https://files.emkaweb.pl/miniprofile/f8c06aa4-e973-473c-8540-712c15e059fd'}
    ];

    this.editTeamMembers.setContent(not_granted, granted);
    this.editTeamMembers.show();
  }

  save_users_by_role(id_role: number, users: number[]) {
    this.editTeamMembers.hide ();
  }

  onEditTeamMembers(item: { id_role: number, role_name_value: string }) {
    this.editTeamMembers.setId('' + item.id_role);
    this.editTeamMembers.setSubtitle(item.role_name_value);

    this.get_users_by_role(item.id_role);
  }

  onSelect(event: any) {
    if (event === undefined) {
      return;
    }

    if (!('id' in event) || !('selected' in event)) {
      return; // nie powinno mieć miejsca ale się zabezpieczamy.
    }

    const id_role = +event.id;
    let users: number[] = [];

    for (let i = 0; i < event.selected.length; i++) {
      if ('id' in event.selected [i]) {
        users.push(+event.selected[i].id);
      }
    }

    this.save_users_by_role(id_role, users);
  }
}
