<div class="col-col-md-12" id="top-bar">
  <div class="row w-100">
    <div class="col-md-12 d-flex align-items-center my-auto w-100">
      <h3 class="page-name">{{'Profiles.Title' | translate}}</h3>

      <ul class="d-flex ml-4 my-auto align-items-center" id="top-bar-nav">
        <li class="active">
          <a routerLink="/siteUnderConstruction">{{'Profiles.All' | translate}}</a>
        </li>
        <li>
          <a routerLink="/siteUnderConstruction">{{'Profiles.Physicians' | translate}}</a>
        </li>
        <li>
          <a routerLink="/siteUnderConstruction">{{'Profiles.Centres' | translate}}</a>
        </li>
        <li>
          <a routerLink="/siteUnderConstruction">{{'Profiles.Centres2' | translate}}</a>
        </li>
      </ul>

      <div class="d-flex">
        <div class="custom-control custom-checkbox" id="tylko-moi" style="position: relative; top:-2px;">
          <input class="custom-control-input" id="tylkoMoiCheck" type="checkbox">
          <label class="custom-control-label" for="tylkoMoiCheck"
                 style="font-weight: normal;">{{'Profiles.OnlyMyCoworkers' | translate}}</label>
        </div>
      </div>

      <form class="d-flex my-auto align-items-center mr-1">
        <app-search #search placeholder="Profiles.SearchPlaceholder"></app-search>
      </form>

    </div>
  </div>

</div>

<div>

  <h3>{{'Profiles.RecentlyAdded' | translate}}</h3>

  <div class="row mt-4">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngFor="let profile of profiles">

      <div class="card users-card pb-3">
        <div class="d-flex pl-2 pr-2">
          <div>
            <a routerLink="/doctorsProfile/doctor_id%3D13">
              <svg-icon [src]="'assets/images/icons/user-card-no-photo.svg'"
                        style="width: 100% !important; height: 100%;"
                        alt=""></svg-icon></a>
          </div>
          <div class="align-self-start align-items-start ml-4">

            <div class="stats-stars mt-1 mb-1 d-none">
              <svg-icon [src]="'../../../../assets/images/icons/stars.svg'" class="svg" alt=""></svg-icon>
              <span style="position: relative; top: 3px; margin-left: 12px;">4.9 / 5</span>
            </div>

            <p class="name"><a class="internal-link"
                               routerLink="/doctorsProfile/doctor_id%3D13">{{profile.name}}</a></p>
            <p><strong>{{'Profiles.Physician' | translate}} </strong>{{profile.city}}, {{profile.country}}
            </p>
            <p>{{profile.specialization}}</p>
          </div>

          <div class="d-flex my-auto ml-auto">
            <a routerLink="/doctorsProfile/doctor_id%3D13" class="btn btn-grey mr-3"
               [innerHTML]="'Profiles.SeeProfile' | translate"></a>
            <a routerLink="/siteUnderConstruction" class="btn btn-grey mr-3" [innerHTML]="'Profiles.SendMessage' | translate"></a>
            <button type="button" class="btn btn-grey btn-sm card-action" id="ddAction3"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
              <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Profiles.Invite' | translate}}</a>
              <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Profiles.Applicate' | translate}}</a>
              <a class="dropdown-item danger" routerLink="/siteUnderConstruction">{{'Profiles.CancelInvitation' | translate}}</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
