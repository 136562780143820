import { IAnatomyPart, IAttachment, IIcd10 } from '@/interfaces/Order';

export const DATA_AGREEMENT_NO_OPTION: number = 0;
export const DATA_AGREEMENT_SKIPPED: number = 1;
export const DATA_AGREEMENT_OK: number = 2;

export const CLOUD_NO_AGREEMENT: number = 0;
export const CLOUD_AGREED: number = 1;

export interface PatientDashboardExam {
  pesel: string;
  exam_date: string;
  exam_type: PatientExamType;
  birth_date: string;
  his_exam_id: string;
  insert_date: string;
  anatomy_part: IAnatomyPart;
  patient_name: string;
  his_patient_id: string;
  order_exam_type: string;
  pm_accession_number: string;
  evo_accession_number: string;
  medical_facility_name: string;
  id_exam: number;
  cloud_status: number;
}

export interface PatientExamType {
  id_type: number;
  exam_type_name: string;
}

export interface IMedicalFacilityInfoPP {
  address_city: string;
  address_street: string;
  address_zip_code: string;
  id_medical_facility: number;
  name: string;
  phone: string;
}

export interface IPatientExamDetailsPP {
  anatomy_part: IAnatomyPart;
  birth_date: string;
  evo_accession_number: string;
  exam_date: string;
  his_exam_id: string;
  his_patient_id: string;
  order_exam_type: string;
  patient_name: string;
  pesel: string;
  pm_accession_number: string;
  medical_facility: IMedicalFacilityInfoPP;
  exam_type: PatientExamType;
  exam_share_info: IShareInfo;
}

export const EmptyIPatientExamDetailsPP: IPatientExamDetailsPP = {
  exam_share_info: {share_end_time: '', share_pin: '', share_status: 0},
  anatomy_part: undefined,
  birth_date: '',
  evo_accession_number: '',
  exam_date: '',
  his_exam_id: '',
  his_patient_id: '',
  medical_facility: {
    address_city: '',
    address_street: '',
    address_zip_code: '',
    id_medical_facility: 0,
    name: '',
    phone: ''
  },
  order_exam_type: '',
  patient_name: '',
  pesel: '',
  pm_accession_number: '',
  exam_type: {id_type: 0, exam_type_name: ''}
};

export interface IPatientExamDescPP {
  pwz: string;
  icd10: IIcd10;
  image_uuid: string;
  id_physician: number;
  id_exam_order: number;
  physician_name: string;
  physician_uuid: string;
  attachment_list: IAttachment[];
  exam_description: string;
  image_uuid_serial: number;
  attention_required: boolean;
  exam_description_date: string;
  physician_info: {
    title: string;
    username: string;
    id_physician: number;
    profile_image_uuid: string;
    main_specialization: string;
    additional_specializations: string;
  };
}

export const EmptyIPatientExamDescPP: IPatientExamDescPP = {
  physician_info: {
    additional_specializations: '',
    id_physician: 0,
    main_specialization: '',
    profile_image_uuid: '',
    title: '',
    username: ''
  },
  attachment_list: [],
  attention_required: false,
  exam_description: '',
  exam_description_date: '',
  icd10: {
    icd10_code: '',
    icd10_text: '',
    display_name: ''
  },
  id_exam_order: 0,
  id_physician: 0,
  image_uuid: '',
  image_uuid_serial: 0,
  physician_name: '',
  physician_uuid: '',
  pwz: ''
};

export interface IPatientDescPDFPP {
  exam_description_uuid: string;
}

export const EmptyIPatientDescPDFPP: IPatientDescPDFPP = {
  exam_description_uuid: ''
};

export interface IPatientExamPlaceholderImagesPP {
  imageClassName: string;
}

export const EmptyIPatientExamPlaceholderImagesPP: IPatientExamPlaceholderImagesPP = {
  imageClassName: ''
};

export interface IShareResultsPatientInfo {
  first_name: string;
  last_name: string;
  birth_date: string;
  sex: string;
}

export interface IShareInfo {
  share_status: number;
  share_pin: string;
  share_end_time: string;
}

export const initShareInfo: IShareInfo = { share_end_time: '', share_pin: '', share_status: 0 };
