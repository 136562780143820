/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-bootstrap/timepicker/ngx-bootstrap-timepicker.ngfactory";
import * as i3 from "ngx-bootstrap/timepicker";
import * as i4 from "@angular/forms";
import * as i5 from "./time-picker.component";
import * as i6 from "@angular/common";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "timepicker", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "isValid"], [null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.time = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isValid" === en)) {
        var pd_1 = ((_co.isValid = $event) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (_co.setTime($event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = ((_co.isAutoChange = !_co.isAutoChange) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_TimepickerComponent_0, i2.RenderType_TimepickerComponent)), i1.ɵprd(512, null, i3.TimepickerStore, i3.TimepickerStore, []), i1.ɵdid(2, 704512, null, 0, i3.TimepickerComponent, [i3.TimepickerConfig, i1.ChangeDetectorRef, i3.TimepickerStore, i3.TimepickerActions], { mousewheel: [0, "mousewheel"], arrowkeys: [1, "arrowkeys"], showMeridian: [2, "showMeridian"] }, { isValid: "isValid" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TimepickerComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.isMouseWheelAllowed; var currVal_8 = _co.isKeyAllowed; var currVal_9 = _co.showMeridian; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.time; _ck(_v, 4, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-time-picker", [], null, null, null, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵdid(1, 114688, null, 0, i5.TimePickerComponent, [i6.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimePickerComponentNgFactory = i1.ɵccf("app-time-picker", i5.TimePickerComponent, View_TimePickerComponent_Host_0, { inputTime: "inputTime" }, { time: "time" }, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
