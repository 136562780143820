export class TestShort {
    shortcut: string;
    name: string;
    date: Date;
    status: string;
    placeName: string;
    placeAdress: string;
    buttons: string[];
    description?: string;
    dateString?: string;
}

export class Alert {
    text: string;
}

export class Notification {
    subject: string;
    text: string;
    isNew: boolean;
    buttons?: string[];
}

export let TESTS: TestShort[] = [
    {
        shortcut: 'RTG',
        date: new Date(2019, 1, 22, 12, 45, 40),
        name: 'Staw barkowy prawy',
        status: 'Opisane',
        placeName: 'SKANMED',
        placeAdress: 'ul. Koszykowa 63, Warszawa',
        buttons: ['Opis badania']
    },
    {
        shortcut: 'RTG',
        date: new Date(2019, 1, 22, 12, 45, 40),
        name: 'Staw barkowy prawy',
        status: 'Opisane',
        placeName: 'SKANMED',
        placeAdress: 'ul. Koszykowa 63, Warszawa',
        buttons: ['Opis badania']
    }
];

export let ALERTS: Alert[] = [{text: 'Uzupełnij profil. Dodaj numer telefonu.'}];

export let NOTIFICATIONS: Notification[] = [
    {subject: 'Ważne zlecenie', text: 'Zlecenie Na CITO 1/4323567 czeka na Twój opis', isNew: true},
    {subject: 'Ważne zlecenie', text: 'Zlecenie Ambulatoryjne 1/4323567 czeka na Twój opis', isNew: true},
    {
        subject: 'Konsultacja międzylekarska',
        text: 'Janina Kowalska prosi Cię o konsultację międzylekarską',
        isNew: false
    },
    {subject: 'Propozycja współpracy', text: 'Centrum Medyczne Nowa zaprasza Cię do współpracy!', isNew: false}
];

export class Functions {
    static getDate(): Date {
        let date: Date = new Date();
        return date;
    }

    static getNotificationCount(): number {
        return 4;
    }

    static getNotifications(): Notification[] {
        return NOTIFICATIONS;
    }

    static getAlerts(): Alert[] {
        return ALERTS;
    }

    static prepareDateOfTestString(d: Date, date: Date): string {
        let result: string = '';
        if (date.getFullYear() == d.getFullYear() &&
            date.getMonth() == d.getMonth() &&
            date.getDate() == d.getDate()) {
            result = 'Dziś, ' + date.getHours() + ':' + date.getMinutes();
        } else {
            result = date.getFullYear() + '-';
            if ((date.getMonth() + 1) >= 10)
                result += (date.getMonth() + 1) + '-';
            else
                result += '0' + (date.getMonth() + 1) + '-';
            if (date.getDate() >= 10)
                result += date.getDate();
            else
                result += '0' + date.getDate();
        }
        return result;
    }

    static getTests(): TestShort[] {
        return [];
    }

    static deleteAccount(): void {
    }
}
