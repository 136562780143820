import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@/services/page.service';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-consultations',
    templateUrl: './consultations.component.html',
    styleUrls: ['./consultations.component.css']
})
export class ConsultationsComponent implements OnInit, OnDestroy {

    constructor(public envService: EnvService, private pageService: PageService) {
    }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('Consultations.Title', 0);
        document.getElementById('consultations').setAttribute('class', 'active');
    }

    ngOnDestroy() {
        document.getElementById('consultations').setAttribute('class', '');
        this.pageService.resetPageTitle();
    }

}
