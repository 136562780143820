<div class="register-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>

                    <app-alert #errorAlert title="Registration"></app-alert>

                    <div>
                        <h2 [innerHTML]="'RegistrationThirdScreen.Title' | translate" class="blue"></h2>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.Name' | translate" class="bigLabel"
                                            for="input1"></label>
                                    <input [(ngModel)]="registrationStep3.imie"
                                            aria-describedby="help1"
                                            autofocus
                                            class="form-control"
                                            id="input1"
                                            name="imie"
                                            placeholder="{{'RegistrationThirdScreen.NamePlaceholder' | translate}}"
                                            type="text">
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.Surname' | translate"
                                            class="bigLabel"
                                            for="input2"></label>
                                    <input [(ngModel)]="registrationStep3.nazwisko"
                                            aria-describedby="help2"
                                            class="form-control"
                                            id="input2"
                                            name="nazwisko"
                                            placeholder="{{'RegistrationThirdScreen.SurnamePlaceholder' | translate}}"
                                            type="text">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.Nationality' | translate"
                                            class="bigLabel"
                                            for="obywatelstwo"></label>
                                    <select (change)="changeType(!isShown)" class="form-control" disabled
                                            id="obywatelstwo">
                                        <option [innerHTML]="'RegistrationThirdScreen.Polish' | translate"></option>
                                        <option [innerHTML]="'RegistrationThirdScreen.Foreign' | translate"></option>
                                    </select>
                                </div>
                                <div [hidden]="isShown" class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.PeselN' | translate"
                                            class="bigLabel"
                                            for="pesel"></label>
                                    <input [(ngModel)]="id_value" aria-describedby="help2" class="form-control"
                                            id="pesel"
                                            name="nrPesel"
                                            placeholder="{{'RegistrationThirdScreen.PeselPlaceholder' | translate}}"
                                            type="text">
                                </div>
                                <div [hidden]="!isShown" class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.Birthday' | translate"
                                            class="bigLabel"
                                            for="birthdate"></label>
                                    <input class="form-control" id="birthdate" type="text">
                                </div>
                            </div>
                        </div>
                        <div [hidden]="!isShown" class="form-group">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.DocumentType' | translate"
                                            class="bigLabel"
                                            for="documentType"></label>
                                    <select [(ngModel)]="documentType" [value]="docType[0]" class="form-control"
                                            id="documentType">
                                        <option *ngFor="let d of docType" [value]="d">{{d}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.DocumentN' | translate"
                                            class="bigLabel"
                                            for="docNum"></label>
                                    <input [(ngModel)]="id_value1" class="form-control" id="docNum" name="nrDoc"
                                            placeholder="{{'RegistrationThirdScreen.DocumentPlaceholder' | translate}}"
                                            type="text">
                                </div>
                            </div>
                        </div>
                        <div [hidden]="hideLogin" class="form-group">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <label [innerHTML]="'RegistrationThirdScreen.SuggestedLogin' | translate"
                                            class="bigLabel"
                                            for="login"></label>
                                    <div class="label-input-div" id="label-input-div">
                                        <label *ngIf="showLabel"
                                                [innerHTML]="'RegistrationThirdScreen.Suggestion' | translate"
                                                class="text-muted"
                                                style="margin-left: 16px; width: 100%; font-size: 12px;">
                                        </label>
                                        <input (blur)="inactiveDiv()"
                                                (click)="activeDiv()"
                                                (keydown)="remLabel()"
                                                [(ngModel)]="registrationStep3.login_name"
                                                [ngModelOptions]="{standalone: true}"
                                                aria-describedby="help1"
                                                class="form-control"
                                                id="login"
                                                name="login"
                                                placeholder="{{'RegistrationThirdScreen.LoginPlaceholder' | translate}}"
                                                style="border: none; height: 29.35px; transition: none;"
                                                type="text">
                                    </div>
                                    <span [innerHTML]="'RegistrationThirdScreen.LoginSpan' | translate"
                                            class="form-text text-muted"
                                            id="loginHelp"></span>
                                </div>
                            </div>
                        </div>
                        <button (click)="register_user_step3()"
                                [innerHTML]="'RegistrationThirdScreen.NextBtn' | translate"
                                class="btn btn-lg btn-primary">
                        </button>
                    </div>
                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop align-items-center d-flex">

                <app-stepper stepNum=3 stepperType="user"></app-stepper>

            </div>
        </div>
    </div>
</div>
