<div class="password-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>
                    <app-horizontal-stepper stepNum=1></app-horizontal-stepper>
                </div>

                <div>

                    <app-alert #errorAlert title="ForgotPassFirst"></app-alert>

                    <h2 [innerHTML]="'ForgotFirstScreen.Title' | translate" class="blue"></h2>
                    <p [innerHTML]="'ForgotFirstScreen.Text' | translate"></p>
                </div>

                <div>
                    <form>
                        <div class="form-group">
                            <label [innerHTML]="'ForgotFirstScreen.InputLabel' | translate" class="bigLabel"
                                    for="login1"
                                    style="padding-bottom: 0; margin-bottom: 0;"></label>
                            <input *ngIf="!isError"
                                    [(ngModel)]="params.value"
                                    autofocus
                                    id="login1"
                                    name="login"
                                    placeholder="{{'ForgotFirstScreen.InputPlaceholder' | translate}}"
                                    type="text"
                                    value=""/>
                            <input *ngIf="isError"
                                    [(ngModel)]="params.value"
                                    autofocus
                                    class="is-error"
                                    id="login"
                                    name="login"
                                    placeholder="{{'ForgotFirstScreen.InputPlaceholder' | translate}}"
                                    type="text"
                                    value=""/>
                        </div>
                        <a [innerHTML]="'ForgotFirstScreen.BackToLoginBtn' | translate" class="btn btn-lg" routerLink="/loginFirst"></a>
                        <button (click)="forgoten_password_step1()" [disabled]="params.value === ''"
                                [innerHTML]="'ForgotFirstScreen.NextStepBtn' | translate"
                                class="btn btn-lg btn-primary"
                                type="button"></button>
                    </form>
                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop">
                <app-news></app-news>
            </div>
        </div>
    </div>
</div>