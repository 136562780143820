import * as i0 from "@angular/core";
var CookiesService = /** @class */ (function () {
    function CookiesService() {
    }
    CookiesService.prototype.getCookie = function (cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    CookiesService.prototype.createSessionCookie = function () {
        var date = new Date();
        var minutes = 15;
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        document.cookie = 'sessionControl=true; expires=' + date + '; path=/';
    };
    CookiesService.ngInjectableDef = i0.defineInjectable({ factory: function CookiesService_Factory() { return new CookiesService(); }, token: CookiesService, providedIn: "root" });
    return CookiesService;
}());
export { CookiesService };
