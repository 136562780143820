import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

    private static stepsOptions = {
        user: ['UserStepperSpan1', 'UserStepperSpan2', 'UserStepperSpan3', 'UserStepperSpan4'],
        co: ['CoStepperSpan1', 'CoStepperSpan2']
    };
    @Input() stepNum: number;
    @Input() stepperType: string;
    steps: string[];

    constructor() {
    }

    ngOnInit() {
        if (this.stepperType == 'user') {
            this.steps = StepperComponent.stepsOptions.user;
        } else if (this.stepperType == 'co') {
            this.steps = StepperComponent.stepsOptions.co;
        }
    }

}
