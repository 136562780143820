import { EnvService } from '@/services/env.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { IAction, IFiltrDashboard, IFiltrPatients, IOrder, Patient } from '@/interfaces';
import { OdataService, OrderActionsService, UsersService, OrdersService } from '@/services';
import { AssignPhysicianModalComponent } from '@/utils/assign-physician-modal/assign-physician-modal.component';
import { RejectModalComponent } from '@/utils/reject-modal/reject-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { PageService } from '@/services/page.service';
import { FiltersService } from '@/services/filters.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@/services/session.service';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { Subscription } from 'rxjs';
import { SearchComponent } from '@/utils/search/search.component';
import { PdfNotAvailableModalComponent } from '@/utils/pdf-not-available-modal/pdf-not-available-modal.component';

@Component({
    selector: 'app-patients',
    templateUrl: './patients.component.html',
    styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit, OnDestroy {
    @ViewChild('filters') filters: FiltersComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('assignPhysicianModal') assignPhysicianModal: AssignPhysicianModalComponent;
    @ViewChild('rejectModal') rejectModal: RejectModalComponent;
    @ViewChild('loader') loader: LoaderComponent;
    @ViewChild('patientsPagination') patientsPagination: PaginationComponent;
    @ViewChild('pdfModal') pdfModal: PdfNotAvailableModalComponent;

    patients: Patient[] = [];
    isShown: boolean[] = [];
    ordersIndex = 0;
    patientsFilter: IFiltrPatients = null;
    ordersFilter: IFiltrDashboard;
    sortColumn: string = 'surname';
    sortType: string = 'ASC';
    currentId: string = '';
    currentPatient = '';

    searchSubscription: Subscription;
    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    numberOfPatients: number = 0;
    ordersOnPage: string = '10';
    numberOfOrderPages: number = 0;
    numberOfOrders: number[] = [];
    orderPages: number[][] = [];
    activeOrderPage: number = 1;
    inputPage: string = '1';
    inputPageError: boolean = false;
    moreFilters: string = '';
    isActiveOtherFilter = false;
    filterService: FiltersService;
    isEmptyPatients: boolean = false;
    downloadingOrders: boolean = true;

    coExamActionAssignToMe: Function = (examId: number, patient: Patient) => {
        this.orderActions.coExamActionAssignToMe(examId).then(
            res => {
                if (res) {
                    this.getExamOrderForPatient(patient, this.ordersFilter, 10, 0);
                }
            }
        );
    }

    coExamActionAssignToPhysian: Function = (examId: number, patient: Patient) => {
        let order = patient.patients_orders.find(x => x.id_exam_order == examId);
        this.assignPhysicianModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionAssignToPhysian(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient, this.ordersFilter, 10, 0);
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamActionReject = (examId: number, patient: Patient) => {
        let order = patient.patients_orders.find(x => x.id_exam_order == examId);
        this.rejectModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionReject(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient, this.ordersFilter, 10, 0);
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamActionRejectByPhysician: Function = (examId: number, patient: Patient) => {
        let order = patient.patients_orders.find(x => x.id_exam_order == examId);
        this.rejectModal.show(order, undefined, true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionRejectByPhysician(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient, this.ordersFilter, 10, 0);
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamCancelAssigment: Function = (examId: number, patient: Patient) => {
        let order = patient.patients_orders.find(x => x.id_exam_order == examId);
        this.rejectModal.show(order, true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionCancelAssigment(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient, this.ordersFilter, 10, 0);
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamChangeAssigment: Function = (examId: number, patient: Patient) => {
        let order = patient.patients_orders.find(x => x.id_exam_order == examId);
        this.assignPhysicianModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionChangeAssigment(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient, this.ordersFilter, 10, 0);
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    getExamDescriptionPdf: Function = (examId: number) => {
        this.ordersService.getExamDescriptionPdf(examId).then(
            (res) => {
                if (res == 'OK') {
                    if (this.ordersService.examDescriptionUuid == null) {
                        this.pdfModal.show();
                    } else {
                        this.ordersService.downloadExamFile();
                    }
                }
            }
        );
    }

    actionsList = {
        'ASSIGN_MYSELF': this.coExamActionAssignToMe,
        'START_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'patients';
            this.filterService.searchInputPatients = this.search.filterSearch;
            this.filterService.currentChosenPatientId = this.currentPatient;
            return this.orderActions.coExamActionStartDescrption(examId);
        },
        'ASSIGN_PHYSICIAN': this.coExamActionAssignToPhysian,
        'REJECT': this.coExamActionReject,
        'ASSIGN_AND_START_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'patients';
            this.filterService.searchInputPatients = this.search.filterSearch;
            this.filterService.currentChosenPatientId = this.currentPatient;
            return this.orderActions.coExamActionAssignAndStartDescription(examId);
        },
        'VIEW_EXAM_ORDER': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'patients';
            this.filterService.searchInputPatients = this.search.filterSearch;
            this.filterService.currentChosenPatientId = this.currentPatient;
            return this.orderActions.viewExamOrder(examId);
        },
        'REJECT_BY_PHYSICIAN': this.coExamActionRejectByPhysician,
        'CANCEL_ASSIGMENT': this.coExamCancelAssigment,
        'CHANGE_ASSIGMENT': this.coExamChangeAssigment,
        'VIEW_DESCRIPTION_PDF':  this.getExamDescriptionPdf
    };

    constructor(private odataService: OdataService, private userService: UsersService, private sessionService: SessionService,
        private route: ActivatedRoute, private orderActions: OrderActionsService, private router: Router, private ordersService: OrdersService,
        private pageService: PageService, filterService: FiltersService, private translate: TranslateService,
        private env: EnvService) {
        this.filterService = filterService;
        this.ordersFilter = FiltersComponent.createInitialIFiltrDashboard();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (sessionStorage.getItem('filtersPatients') != null) {
                this.patientsFilter = JSON.parse(sessionStorage.getItem('filtersPatients'));
                window.history.pushState({}, '', '/patients/' + JSON.stringify(this.patientsFilter));
            } else if (params.get('filter') == null || params.get('filter') === JSON.stringify(FiltersComponent.createInitialFiltrPatients())) {
                this.patientsFilter = FiltersComponent.createInitialFiltrPatients();
            } else if (params.get('filter') != null) {
                this.patientsFilter = JSON.parse(decodeURI(params.get('filter')));
            }
            this.filters.patientsFilter = this.patientsFilter;
        });

        this.checkCurrentPage();

        this.searchEvent();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();

        if (this.filterService.searchInputPatients != '') {
            this.search.filterSearch = this.filterService.searchInputPatients;
            document.getElementById('clearSearchButton').style.display = 'block';
        }

        let offset = (this.patientsPagination.activePage * parseInt(this.patientsPagination.itemsOnPage)) - parseInt(this.patientsPagination.itemsOnPage);

        this.filterService.getFilters('patients').then(() => {
          let myFilterId = sessionStorage.getItem('patientsMyFilter');
          if (myFilterId && myFilterId != '') {
            this.currentId = myFilterId;
            this.checkMyFiltersForFavorite(myFilterId);
          }
        });
        this.translate.get('DashboardScreen.More')
            .subscribe((res: string) => {
                this.moreFilters = res;
            });
        this.getNumberOfPatients(this.patientsFilter).then(
            () => {
                this.getPatientViewList(this.patientsFilter, parseInt(this.patientsPagination.itemsOnPage), offset, this.search.filterSearch).then(
                    () => {
                        if (JSON.stringify(this.patientsFilter) === JSON.stringify(FiltersComponent.createInitialFiltrPatients())) {
                            if (this.patients.length == 0) {
                                this.isEmptyPatients = true;
                            } else {
                                this.isEmptyPatients = false;
                            }
                        }
                    }
                );
            }
        );

        this.pageService.setTranslatedPageTitle('PatientsScreen.Title', 0);
        document.getElementById('patients').setAttribute('class', 'active');

        this.userService.dcListPromise.then(res => {
            this.getCoDescribingPhysiciansByCenter();
        });
    }

    getCoDescribingPhysiciansByCenter(): void {
        this.odataService.postPrivate('get_co_describing_physicians_by_center', this.odataService.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                this.assignPhysicianModal.physicians = value;
            }
        });
    }

    ngOnDestroy() {
        document.getElementById('patients').setAttribute('class', '');
        if (JSON.stringify(this.patientsFilter) != JSON.stringify(FiltersComponent.createInitialFiltrPatients())) {
            sessionStorage.setItem('filtersPatients', JSON.stringify(this.patientsFilter));
        } else sessionStorage.removeItem('filtersPatients');
        this.pageService.resetPageTitle();
    }

    showOrders(i: number) {
        this.getExamOrderForPatient(this.patients[i], this.ordersFilter, parseInt(this.ordersOnPage), 0);

        this.inputPageError = false;
        this.inputPage = '1';
        if (this.isShown[i]) {
            this.isShown[i] = false;
            this.currentPatient = '';
        } else {
            for (let j = 0; j < this.patients.length; j++) {
                this.isShown[j] = false;
            }
            this.isShown[i] = true;
            this.currentPatient = i.toString();
        }
    }

    dropdown() {
        this.filters.dropdown();
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('Patients'))
            this.patientsPagination.activePage = this.sessionService.getCurrentPage('Patients');
        if (this.sessionService.getItemsOnPage('Patients'))
            this.patientsPagination.itemsOnPage = this.sessionService.getItemsOnPage('Patients');
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.patientsPagination.changePage(page));
    }

    updatePatientsList() {
        this.filterService.searchInputPatients = this.search.filterSearch;
        this.isShown = [];
        if (!this.search.isSearch) {
            this.getPatientViewList(
                this.patientsFilter,
                parseInt(this.patientsPagination.itemsOnPage),
                (this.patientsPagination.activePage * parseInt(this.patientsPagination.itemsOnPage)) - parseInt(this.patientsPagination.itemsOnPage)
            ).then(
                () => {
                    this.getNumberOfPatients(this.patientsFilter);
                }
            );
        } else {
            this.getPatientViewList(
                this.patientsFilter,
                parseInt(this.patientsPagination.itemsOnPage),
                (this.patientsPagination.activePage * parseInt(this.patientsPagination.itemsOnPage)) - parseInt(this.patientsPagination.itemsOnPage),
                this.search.filterSearch
            ).then(
                () => {
                    this.getNumberOfPatients(this.patientsFilter);
                }
            );
        }
    }

    changePageEvent() {
        this.pageChangeSubscription = this.patientsPagination.pageChangeEmitter.subscribe(
            () => {
                this.updatePatientsList();
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.patientsPagination.changeItemsOnPageEmitter.subscribe(
            () => {
                this.patientsPagination.activePage = 1;
                this.patientsPagination.inputPage = '1';
                this.updatePatientsList();
            }
        );
    }

    changeOrdersPage(index: number, page: number) {
        if (page <= this.orderPages[index].length) {
            this.activeOrderPage = page;
            this.inputPage = page + '';
            this.getExamOrderForPatient(
                this.patients[index],
                this.ordersFilter,
                parseInt(this.ordersOnPage),
                (page * parseInt(this.ordersOnPage)) - parseInt(this.ordersOnPage),
            );
            this.inputPageError = false;
        }
    }

    changeNumberOfOrdersPages(index: number) {
        this.activeOrderPage = 1;
        this.inputPage = '1';
        this.getExamOrderForPatient(this.patients[index], this.ordersFilter, parseInt(this.ordersOnPage), 0);
    }

    getNumberOfPatients(filter: IFiltrPatients): Promise<string> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                filtr: JSON.stringify(filter),
                filtr_search: this.search.filterSearch
            }
        );

        let prom = this.odataService.postPrivate('count_patient_view_list_ext', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.numberOfPatients = parseInt(result.Value);
                this.patientsPagination.pages = [];
                this.patientsPagination.numberOfPages = this.numberOfPatients / parseInt(this.patientsPagination.itemsOnPage);
                Math.floor(this.patientsPagination.numberOfPages);
                for (let i = 0; i < this.patientsPagination.numberOfPages; i++) {
                    this.patientsPagination.pages.push(i + 1);
                }
            }
            return result.Status;
        });
        return prom;
    }

    getPatientViewList(filter: IFiltrPatients, limit: number, offset: number, filtrSearch?: string): Promise<string> {
        this.loader.show = true;
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                filtr: JSON.stringify(filter),
                filtr_search: filtrSearch ? filtrSearch : '',
                offset: offset,
                limit: limit
            }
        );

        let prom = this.odataService.postPrivate('get_patient_view_list_ext', params, res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value;
                if (result.Value != '' && result.Value != '{}') value = JSON.parse(result.Value);
                else value = [];
                this.patients = [];
                this.isShown = [];
                value.forEach((element) => {
                    this.isShown.push(false);
                    this.patients.push(this.parseIntoPatient(element));
                    this.numberOfOrders.push(element.exam_count);
                    this.orderPages.push([]);
                });
                if (this.filterService.currentChosenPatientId != '') {
                    this.showOrders(parseInt(this.filterService.currentChosenPatientId));
                    this.filterService.currentChosenPatientId = '';
                }
            }
            this.loader.show = false;
            return result.Status;
        });
        return prom;
    }

    getExamOrderForPatient(patient: Patient, filter: IFiltrDashboard, limit: number, offset: number): Promise<string> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_patient: patient.id_patient,
                filtr: JSON.stringify(filter),
                offset: offset,
                limit: limit
            }
        );

        this.downloadingOrders = true;
        let prom = this.odataService.postPrivate('get_exam_order_for_patient', params, res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value = JSON.parse(result.Value || '[]');
                let orders: IOrder[] = value;
                this.orderPages.push([]);
                this.numberOfOrderPages = this.numberOfOrders[this.numberOfOrders.length - 1] / parseInt(this.ordersOnPage);
                Math.floor(this.numberOfOrderPages);
                for (let i = 0; i < this.numberOfOrderPages; i++) {
                    this.orderPages[this.orderPages.length - 1].push(i + 1);
                }
                patient.hours = [];
                patient.dates = [];
                patient.patients_orders = [];
                for (let i = 0; i < orders.length; i++) {
                    moment.locale(this.userService.iLanguage);
                    let date = moment(orders[i].order_date).format('DD-MM-YYYY, HH:mm');
                    let hour = moment(orders[i].order_date).startOf('hour').fromNow();
                    if (orders[i].reject_info.reject_time)
                        orders[i].reject_info.reject_time = moment(orders[i].reject_info.reject_time).format('DD-MM-YYYY');
                    else if (orders[i].description_date)
                        orders[i].description_date = moment(orders[i].description_date).format('DD-MM-YYYY');
                    patient.hours.push(hour);
                    patient.dates.push(date);
                    orders[i].actions_info.sort((a1: IAction, a2: IAction) => {
                        if (a1.action_importance > a2.action_importance) return -1;
                        else if (a1.action_importance == a2.action_importance) return 0;
                        else return 1;
                    });
                    patient.patients_orders.push(orders[i]);
                }
                this.downloadingOrders = false;
            }
            return result.Status;
        });
        return prom;
    }

    makeAction(action: string, id: number, patient: Patient): void {
        if (action == 'START_DESCRIPTION' || action == 'VIEW_EXAM_ORDER' || action == 'ASSIGN_AND_START_DESCRIPTION') {
            let order = patient.patients_orders.find(x => x.id_exam_order == id);
            this.actionsList[action](id).then(res => {
                if (res) {
                    if (action == 'VIEW_EXAM_ORDER') {
                        sessionStorage.setItem('mode', 'view');
                        this.router.navigate(['/orderDescription/' + id + '/currentDescription'], { queryParams: { mode: 'view' } });
                    } else {
                        sessionStorage.setItem('mode', 'description');
                        this.router.navigate(['/orderDescription/' + id + '/currentDescription'], { queryParams: { mode: 'description' } });
                    }
                }
            });
        } else {
            this.actionsList[action](id, patient);
        }
    }

    parseIntoPatient(obj: any): Patient {
        let patient: Patient = {
            sex: obj.sex,
            pesel: obj.pesel,
            birth_date: obj.birth_date,
            last_name: obj.last_name,
            first_name: obj.first_name,
            id_patient: obj.id_patient,
            exam_count: obj.exam_count,
            patients_orders: [],
            age: new Date().getFullYear() - parseInt(obj.birth_date),
            dates: [],
            hours: []
        };

        return patient;
    }

    hideFilter() {
        sessionStorage.setItem('activeFilter', 'true');
        this.dropdown();
    }

    getOrders(id: string): void {
      this.hideFilter();
      this.patientsFilter = this.filterService.getMyFilters('patients')[parseInt(id)].value;
      this.search.filterSearch = '';
      this.search.isSearch = false;
      $('#searchInput').val('');
      document.getElementById('clearSearchButton').style.display = 'none';
      this.checkMyFiltersForFavorite(id);
      sessionStorage.setItem('patientsMyFilter', id);
      this.getSort();
      this.filters.patientsFilter = this.patientsFilter;
      this.filters.setFilterPatients();
      sessionStorage.removeItem('filtersPatients');
      this.sessionService.removeCurrentPage('Patients');
      window.history.pushState({}, '', '/patients/' + JSON.stringify(this.patientsFilter));
      this.getPatientViewList(this.patientsFilter, parseInt(this.patientsPagination.itemsOnPage), 0);
      this.getNumberOfPatients(this.patientsFilter);
      this.currentId = id;
    }

    goToPageOrders(index: number, page: string) {
        let pageNumber: number = parseInt(page);
        if (pageNumber && pageNumber <= this.orderPages[index].length && pageNumber > 0) {
            this.changeOrdersPage(index, pageNumber);
        } else {
            this.inputPageError = true;
        }
    }

    getSort(): void {
        this.patientsFilter.sort.sort_column = this.sortColumn;
        this.patientsFilter.sort.sort_type = this.sortType;
    }

    filterPatients(filter: IFiltrPatients): void {
        this.patientsFilter = filter;
        this.getSort();
        sessionStorage.removeItem('filtersPatients');
        sessionStorage.removeItem('patientsMyFilter');
        this.sessionService.removeCurrentPage('Patients');
        window.history.pushState({}, '', '/patients/' + JSON.stringify(filter));
        this.getPatientViewList(this.patientsFilter, parseInt(this.patientsPagination.itemsOnPage), 0, this.search.filterSearch).then(
            () => {
                this.patientsPagination.activePage = 1;
                this.patientsPagination.inputPage = '1';
            }
        );
        this.getNumberOfPatients(this.patientsFilter);
        this.translate.get('DashboardScreen.More')
            .subscribe((res: string) => {
                this.moreFilters = res;
            });
        this.isActiveOtherFilter = false;
        this.currentId = '';
    }

    createArray(length: number, value: any): any {
        let array = [];

        for (let i: number = 0; i < length; i++) {
            array.push(value);
        }

        return array;
    }

    declinateYears(age: number): string {
        return this.ordersService.yearsDeclinatedString(age);
    }

  checkMyFiltersForFavorite(id: string) {
    if (!this.filterService.getMyFilters('patients')[parseInt(id)].isFavourite) {
      this.moreFilters = this.filterService.getMyFilters('patients')[parseInt(id)].name;
      this.isActiveOtherFilter = true;
    } else {
      this.translate.get('DashboardScreen.More')
        .subscribe((res: string) => {
          this.moreFilters = res;
        });
      this.isActiveOtherFilter = false;
    }
  }
}
