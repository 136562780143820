<div aria-hidden="true" aria-labelledby="confirmOperationModalLabel" class="modal fade" id="confirmOperationModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="confirmOperationModalLabel">{{title}}</h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close" (click)="confirmOperation(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">{{content}}</div>
      <div class="modal-footer">
        <button type="button" (click)="confirmOperation(true)" class="btn btn-lg btn-success">{{confirmBtnTxt}}</button>
        <button type="button" (click)="confirmOperation(false)" class="btn btn-lg btn-danger">{{rejectBtnTxt}}</button>
      </div>
    </div>
  </div>
</div>
