<div class="row mt50">
    <div class="col-md-6">
        <div class="card big-card">
            <h3 class="card-title text-center"
                style="margin-bottom: 0; border: 0px none;">{{'DescribingCenter' | translate}}</h3>
            <img alt="" class="img-fluid" src="../../../assets/images/photos/co.jpg">
            <div class="card-body text-center">
                <a [routerLink]="['/registrationCo1']" class="btn btn-lg btn-grey" style="margin-bottom: 15px;">
                    <svg-icon alt=""
                         class="svg btn-icon"
                         src="../../../assets/images/icons/sidebar/icon__zlecenia.svg"></svg-icon>
                    {{'BeforeWorkDoctor.RegisterDC' | translate}}</a>
                <a class="btn btn-lg btn-grey" routerLink="/findCO" style="margin-bottom: 15px;">
                    <svg-icon alt=""
                         class="svg btn-icon"
                         src="../../../assets/images/icons/sidebar/icon__profile.svg"></svg-icon>
                    {{'BeforeWorkDoctor.FindDC' | translate}}</a>
            </div>
            <div class="card-footer">
                <p class="text-center">{{'BeforeWorkDoctor.AskCoworker' | translate}}</p>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="card big-card">
            <h3 class="card-title text-center" style="margin-bottom: 0; border: 0px none;">
                {{'MedicalCenter' | translate}}</h3>
            <img alt="" class="img-fluid" src="../../../assets/images/photos/pm.jpg">
            <div class="card-body text-center">
                <a *ngIf="!envService.hideUnderConstruction" class="btn btn-lg btn-grey" routerLink="/siteUnderConstruction" style="margin-bottom: 15px;">
                    <svg-icon alt=""
                         class="btn-icon"
                         src="../../../assets/images/icons/sidebar/icon__pm_lista_badan.svg"></svg-icon>
                    {{'BeforeWorkDoctor.RegisterMedicalCenter' | translate}}</a>
                <a class="btn btn-lg btn-grey" routerLink="/listOfMedicalFacilities" style="margin-bottom: 15px;">
                    <svg-icon alt=""
                         class="btn-icon"
                         src="../../../assets/images/icons/sidebar/icon__pm_zlecenia.svg"></svg-icon>
                    {{'BeforeWorkDoctor.FindMedicalCenter' | translate}}</a>
            </div>
            <div class="card-footer">
                <p class="text-center">{{'BeforeWorkDoctor.Questions' | translate}}
                    <a routerLink="/siteUnderConstruction">{{'BeforeWorkDoctor.ContactUs' | translate}}</a></p>
            </div>
        </div>
    </div>

</div>