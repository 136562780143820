<table class="tile-table classic-table alt-table" id="dataTable" style="width:100%">

    <ng-container *ngIf="type == 'INVOICES'">
        <thead>
        <tr>
            <th>{{'Datatable.Invoices.InvoiceId' | translate}}</th>
            <th>{{'Datatable.Invoices.DateOfIssue' | translate}}</th>
            <th>{{'Datatable.Invoices.DateOfSale' | translate}}</th>
            <th>{{'Datatable.Invoices.Netto' | translate}}</th>
            <th>{{'Datatable.Invoices.Brutto' | translate}}</th>
            <th class="text-center">{{'Datatable.Invoices.Download' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of rows">
            <td>{{row.id}}</td>
            <td>{{row.dateOfIssue}}</td>
            <td>{{row.dateOfSale}}</td>
            <td>{{row.netto}}</td>
            <td>{{row.brutto}}</td>
            <td>
                <ng-container *ngFor="let a of row.actions">
                    <a class="btn-sm btn-white" href="">{{a.name}}</a>&nbsp;
                </ng-container>
            </td>
        </tr>
        </tbody>
    </ng-container>

    <ng-container *ngIf="type == 'BALANCE'">
        <thead>
        <tr>
            <th>#</th>
            <th>{{'Datatable.Balance.Date' | translate}}</th>
            <th>{{'Datatable.Balance.Amount' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of rows; let i = index;">
            <td>{{i + 1}}</td>
            <td>{{row.date}}</td>
            <td>{{row.sum}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
            <td>{{rows.length}} {{'Datatable.Balance.Boosts' | translate}}</td>
            <td>&nbsp;</td>
            <td>{{'Datatable.Balance.ForAmount' | translate}}: {{sum}}</td>
        </tr>
        </tfoot>
    </ng-container>

    <ng-container *ngIf="type == 'HISTORY'">
        <thead>
        <tr>
            <th>{{'Datatable.History.Date' | translate}}</th>
            <th>{{'Datatable.History.Physician' | translate}}</th>
            <th>{{'Datatable.History.Modality' | translate}}</th>
            <th>{{'Datatable.History.Principal' | translate}}</th>
            <th>{{'Datatable.History.Price' | translate}}</th>
            <th class="text-center">{{'Datatable.History.Actions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of rows">
            <td>{{row.date}}</td>
            <td>{{row.physician}}</td>
            <td>{{row.modality}}</td>
            <td>{{row.principal}}</td>
            <td>{{row.sum}}</td>
            <td>
                <ng-container *ngFor="let a of row.actions">
                    <a *ngIf="!envService.hideUnderConstruction" class="btn btn-sm btn-white"routerLink="/siteUnderConstruction">{{a.name}}</a>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </ng-container>

</table>
<app-alert-modal #infoModal [modalConfig]="alertModalConfig"></app-alert-modal>
