<div aria-hidden="true" class="modal fade"
     role="dialog"
     tabindex="-1" id="generateResultsPairModal"
     aria-labelledby="generateResultsPairModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="generateResultsPairModalLabel">{{'MFShareResultsModal.Title' |translate}}</h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div [hidden]="bigSpinnerHidden" id="mainSpinner" class="spinner-border spinner-border-bg text-primary" role="status">
        <span class="sr-only">Loading...</span></div>
      <div [hidden]="activeScreen != 'INPUT'">
        <div class="modal-body">
          <form #f="ngForm" [formGroup]="peselPhoneForm">
            <div class="col form-group" style="padding-left: 0; padding-right: 0;">
              <label for="phone">{{'MFShareResultsModal.InputScreen.Phone' |translate}}</label><br />
              <ngx-intl-tel-input
                [cssClass]="'custom'"
                [enablePlaceholder]="true"
                [enableAutoCountrySelect]="true"
                [selectedCountryISO]="CountryISO.Poland"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [phoneValidation]="true"
                formControlName="phone_number" id="phone"></ngx-intl-tel-input>
            </div>

            <div class="col form-group" style="padding-left: 0; padding-right: 0; position: relative;">
              <label for="pesel">{{'MFShareResultsModal.InputScreen.Pesel' |translate}}</label>
              <input class="form-control" type="text" id="pesel" formControlName="pesel" placeholder="" maxlength="11"
                     (keyup)="checkPesel($event)"
              >
              <span *ngIf="patientInfo && patientInfo.first_name" id="peselPatientInfo">{{patientInfo.first_name}} {{patientInfo.last_name}}, {{patientInfo.birth_date}}, {{patientInfo.sex}}</span>
            </div>
          </form>
          <div [hidden]="spinnerHidden" id="peselSpinner" class="pesel-spinner spinner-border spinner-border-sm text-primary" role="status">
            <span class="sr-only">Loading...</span></div>
          <div [hidden]="peselPhoneForm.controls.pesel.value.length != 11 && !spinnerHidden" id="peselSearchStatus">
            <svg-icon *ngIf="peselPhoneForm.controls.pesel.valid" style="display: block;" class="logo" src="../../../assets/images/icons/ok-circle.svg"></svg-icon>
            <svg-icon *ngIf="!peselPhoneForm.controls.pesel.valid" style="display: block;" class="logo" src="../../../assets/images/icons/icon__close.svg"></svg-icon>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-md btn-primary" (click)="onSubmit()" [disabled]="!peselPhoneForm.valid">
            {{'MFShareResultsModal.InputScreen.Submit' |translate}}</button>
        </div>
      </div>
      <ng-container *ngIf="activeScreen === 'CONFIRMED'">
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            <span><svg-icon class="alert-svg" src="../../../assets/images/icons/ok-circle.svg"></svg-icon>
              {{'MFShareResultsModal.SuccessAlert' |translate}}</span>
          </div>
          <span>{{'MFShareResultsModal.SuccessMsg' |translate}}</span>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-md" (click)="activeScreen = 'INPUT'">{{'MFShareResultsModal.ClearForm' |translate}}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="activeScreen === 'ERROR'">
        <div class="modal-body">
          <div class="alert alert-danger" role="alert">
            <svg-icon class="alert-svg" src="../../../assets/images/icons/icon__close.svg"></svg-icon>
            {{'MFShareResultsModal.ErrorAlert' |translate}}
          </div>
          <span>{{'MFShareResultsModal.ErrorMsg' |translate}}</span>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-md" (click)="retrySubmit()">{{'MFShareResultsModal.TryAgain' |translate}}</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
