import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DoubleSelectModalComponent } from 'src/app/utils/double-select-modal/double-select-modal.component';
import { UsersService, OdataService, SessionService } from '@/services';
import { EnvService } from '@/services/env.service';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-role-settings',
    templateUrl: './role-settings.component.html',
    styleUrls: ['./role-settings.component.css']
})
export class RoleSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('editTeamMembers') editTeamMembers: DoubleSelectModalComponent;

    odataService: OdataService;
    userService: UsersService;
    settings_roles: any[];

    constructor(userService: UsersService, odataService: OdataService, private sessionService: SessionService,
        private env: EnvService, private pageService: PageService) {
        this.odataService = odataService;
        this.userService = userService;
    }

    ngOnInit() {
        document.getElementById('settings').setAttribute('class', 'active');
        this.getSystemRoles();
        this.pageService.setSettingPageTranslatedTitle('SettingsScreens.Roles', 0);
    }

    ngOnDestroy() {
        document.getElementById('settings').setAttribute('class', '');
        this.pageService.resetPageTitle();
    }

    getSystemRoles() {
        this.odataService.postPrivate('get_system_roles',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    this.settings_roles = JSON.parse(result.Value);
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    getUsersByRole(id_role: number) {
        this.odataService.postPrivate('get_users_by_role',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_role: id_role
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let roles: any[] = JSON.parse(result.Value);
                    let granted: any[] = [];
                    let not_granted: any[] = [];

                    for (let i = 0; i < roles.length; i++) {
                        if ('id_user' in roles[i] && roles[i].id_user) {
                            let user = {
                                id: roles[i].id_user,
                                text: roles[i].name,
                            };

                            if (roles[i].image_uuid) {
                                user['img_src'] = this.env.miniProfilePicURL + roles[i].image_uuid;
                            } else {
                                user['img_src'] = '../../assets/images/icons/user.svg';
                            }

                            if ('role_granted' in roles[i] && roles[i].role_granted) {
                                granted.push(user);
                            } else {
                                not_granted.push(user);
                            }
                        }
                    }

                    this.editTeamMembers.setContent(not_granted, granted);
                    this.editTeamMembers.show();

                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    saveUsersByRole(id_role: number, users: number[]) {
        this.odataService.postPrivate('save_users_by_role',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_role: id_role,
                users: JSON.stringify(users)
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    this.editTeamMembers.hide();
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            });
    }

    onEditTeamMembers(item: { id_role: number, role_name_value: string }) {
        this.editTeamMembers.setId('' + item.id_role);
        this.editTeamMembers.setSubtitle(item.role_name_value);
        this.getUsersByRole(item.id_role);
    }

    onSelect(event: any) {
        if (event === undefined) {
            return;
        }

        if (!('id' in event) || !('selected' in event)) {
            return;
        }

        const id_role = +event.id;
        let users: number[] = [];

        for (let i = 0; i < event.selected.length; i++) {
            if ('id' in event.selected[i]) {
                users.push(+event.selected[i].id);
            }
        }

        this.saveUsersByRole(id_role, users);
    }
}
