import { EventEmitter, Injectable } from '@angular/core';
import { OdataService } from './odata.service';
import { UsersService } from './users.service';
import {
  ActionButton,
  dummyOrderDescription,
  ExamInfo,
  examInfoToOrderDescription,
  IOrderData,
  OrderDescription
} from './../interfaces/orderDescription';
import { DraftSenderService, IParams } from './draft-sender.service';
import { SessionService } from './session.service';
import { ISavedResult } from '@/interfaces';
import { Router } from '@angular/router';
import { IDescTemplate } from '@/interfaces/order-desc-template';

interface IMode {
    mode: string;
    code: number;
}

@Injectable({
    providedIn: 'root'
})
export class OrderDescriptionService {
    examId: number;
    patientId: number;
    ordersCount: number = 0;

    orderStatusPromise: Promise<any>;
    descriptionPromise: Promise<OrderDescription>;
    actionsPromise: Promise<ActionButton[]>;
    doctorsDescriptionPromise: Promise<string>;
    templatePromise: Promise<IDescTemplate[]>;

    orderDescription: OrderDescription = dummyOrderDescription;
    actionButtons: ActionButton[];
    descriptionText: string = '';

    descriptionEmitter: EventEmitter<string> = new EventEmitter<string>();
    draftSavedEmitter: EventEmitter<ISavedResult> = new EventEmitter<ISavedResult>();

    isViewDraft: boolean = false;
    isRejected: boolean = false;
    mode: IMode = {
        mode: '',
        code: 0
    };
    text: string = '';
    version: number = 1;
    isCheckedAttention: boolean = false;

    constructor(private odataService: OdataService, private usersService: UsersService, private router: Router,
        private draftSender: DraftSenderService, private sessionService: SessionService) {
        draftSender.draftSentEmitter.subscribe((e) => { this.draftSavedEmitter.emit(e); });
    }

    getExamInfo(): Promise<OrderDescription> {
        this.descriptionPromise = this.odataService.postPrivate('get_exam_order_exam_info',
            this.odataService.paramsTokenAndLang({
                id_exam_order: this.examId,
                id_describing_center: this.usersService.getCenterId()
            }),
            res => {
          // todo: Ujednolicenie nazewnictwa w funkcjach i interfejsach -> keysToCamel itp.
                let stringJSON = JSON.stringify(res);
                let resForAttachments = JSON.parse(stringJSON);
                let resultForAttachments: OrderDescription = JSON.parse(resForAttachments.Value); // passing attachments via different parsing due to interface differences (Camel and _)
                const result: ExamInfo = this.odataService.keysToCamel(JSON.parse(res.Value));
                this.patientId = result.examInfo.idPatient;
                const info: OrderDescription = examInfoToOrderDescription(result, resultForAttachments.attachment_list, resultForAttachments.exam_info.consultation);
                this.orderDescription = info;
                this.orderDescription.patient.age = this.calculateAgeFromDates(new Date(this.orderDescription.patient.birthdate), new Date());
                return info;
            });
        return this.descriptionPromise;
    }

    getExamActions(): Promise<ActionButton[]> {
        this.actionsPromise = this.odataService.postPrivate('get_exam_order_action_list',
            this.odataService.paramsTokenAndLang({
                id_exam_order: this.examId,
                id_describing_center: this.usersService.getCenterId(),
                mode: this.mode.code
            }),
            res => {
                const cammelRes = this.odataService.keysToCamel(JSON.parse(res.Value || '{}'));
                this.actionButtons = cammelRes.actionsInfo || [];
                this.sortOrderActions(this.actionButtons);
                return this.actionButtons;
            });
        return this.actionsPromise;
    }

    getExamLock(): Promise<boolean> {
        return this.odataService.postPrivate('get_exam_descrption_lock',
            this.odataService.paramsTokenAndLang({
                id_exam_order: this.examId
            }),
            res => {
                return res.ActionStatus;
            });
    }

    takeOverExamLock(password: string): Promise<boolean> {
      return this.odataService.postPrivate('take_over_exam_descrption_lock',
          this.odataService.paramsTokenAndLang({
            id_exam_order: this.examId,
            user_password: password
          }),
          res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
              return res.ActionStatus;
            }
          });
    }

    saveExamDraft(text: string): void {
        let params: IParams = {
            token: this.sessionService.getToken(),
            language: this.usersService.iLanguage,
            id_exam_order: this.examId,
            description: text,
            attention_required: this.isCheckedAttention,
            version: this.version
        };
        this.draftSender.saveExamDraft(params);
    }

    getExamDraft(): Promise<string> {
        this.doctorsDescriptionPromise = this.odataService.postPrivate('get_exam_description_draft',
            this.odataService.paramsTokenAndLang({
                id_exam_order: this.examId,
                version: null
            }),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    if (result.Value.length > 0) {
                        result = JSON.parse(result.Value);
                        this.version = result[0].description_version + 1;
                        if (result[0].attention_required != null) {
                            this.isCheckedAttention = result[0].attention_required;
                        } else this.isCheckedAttention = false;
                        this.descriptionText = result[0].description;
                        return result[0].description;
                    } else {
                        this.descriptionText = '';
                        return '';
                    }
                } else {
                    return '';
                }
            });
        return this.doctorsDescriptionPromise;
    }

    getExamDescriptionOrDraft(): Promise<string> {
        this.isViewDraft = false;
        switch (this.mode.mode) {
            case 'description': {
                if (this.orderDescription.status.codename == 'IN_PROGRESS') return this.getExamDraft();
                else return this.getExamDescription();
            }
            case 'view': {
                if (this.orderDescription.status.codename == 'IN_PROGRESS') {
                    this.isViewDraft = true;
                    return this.getExamDraft();
                } else {
                    return this.getExamDescription();
                }
            }
            default: {
                return this.getExamDescription();
            }
        }
    }

    getExamDescription(): Promise<string> {
        this.doctorsDescriptionPromise = this.odataService.postPrivate('get_exam_description_json',
            this.odataService.paramsTokenAndLang({
                id_exam_order: this.examId
            }),
            res => {
                let result = JSON.parse(res.Value);
                this.isCheckedAttention = result.attention_required;
                if (result.exam_description != null) {
                    this.descriptionText = result.exam_description;
                    return result.exam_description;
                } else {
                    this.descriptionText = '';
                    return '';
                }

            });
        return this.doctorsDescriptionPromise;
    }

    getDescriptionTemplates(modalities?: number[], anatomyParts?: number[], last_used?: boolean, limit?: number, offset?: number): Promise<IDescTemplate[]> {
     this.templatePromise =  this.odataService.postPrivate('get_description_template',
        this.odataService.paramsTokenAndLang({
          id_describing_center: this.usersService.getCenterId(),
          template_name: '',
          modality_list: modalities ? modalities : [],
          anatomy_list: anatomyParts ? anatomyParts : [],
          offset: offset ? offset : 0,
          limit: limit ? limit : 10,
          last_used: last_used ? last_used : false
        }),
        res => {
        let returnedJSON = JSON.stringify(res);
        let result = JSON.parse(returnedJSON);
        let templates: IDescTemplate[] = result.Value ? JSON.parse(result.Value) : [];
        return templates;
        });
     return this.templatePromise;
    }

    saveDescriptionTemplate(name: string, content: string, modality_list: number[], anatomy_list: number[], id?: number): Promise<any> {
      return this.odataService.postPrivate('save_description_template', this.odataService.paramsTokenAndLang({
        id_template: id,
        template_name: name,
        content: content,
        modality_list: modality_list,
        anatomy_list: anatomy_list,
        id_describing_center: this.usersService.getCenterId()
      }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status != 'OK') console.log('error');
        return result;
      });
    }

    deleteDescriptionTemplate(id: number): Promise<any> {
      return this.odataService.postPrivate('delete_description_template', this.odataService.paramsTokenAndLang({
        id_template: id
      }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status != 'OK') console.log('error');
        return result;
      });
    }

    descriptionTemplateUsed(id: number) {
      this.odataService.postPrivate('description_template_used', this.odataService.paramsTokenAndLang({
        id_template: id
      }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status != 'OK') console.log('error');
      });
    }

    submitExamDescription(desc: string): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: this.examId,
                id_describing_center: this.usersService.getCenterId(),
                description: desc,
                attention_required: this.isCheckedAttention
            }
        );

        return this.odataService.postPrivate('submit_exam_description', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            return result.Status == 'OK';
        });
    }

    submitExamDescriptionInTime(desc: string): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: this.examId,
                id_describing_center: this.usersService.getCenterId(),
                description: desc,
                attention_required: this.isCheckedAttention
            }
        );

        return this.odataService.postPrivate('submit_exam_description_in_time', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            return result.Status == 'OK';
        });
    }

    sortOrderActions(buttons: ActionButton[]): any {
        buttons.sort((a1: ActionButton, a2: ActionButton) => {
            if (a1.actionImportance > a2.actionImportance) return -1;
            else if (a1.actionImportance == a2.actionImportance) return 0;
            else return 1;
        });
    }

    reloadData(): void {
        this.getExamInfo();
        this.getExamActions();
        if (this.mode.mode == 'description') {
            this.getExamDraft();
        } else if (this.mode.mode == 'view') {
            this.getExamDescriptionOrDraft();
        }
    }

    reloadExamDescription(): void {
        this.mode = {
            mode: 'description',
            code: 1
        };

        this.router.navigate(['/orderDescription/' + this.examId + '/currentDescription'], { queryParams: { mode: 'description' } });
    }

    reloadExamView(): void {
        this.mode = {
            mode: 'view',
            code: 0
        };

        this.router.navigate(['/orderDescription/' + this.examId + '/currentDescription'], { queryParams: { mode: 'view' } });
    }


    calculateAgeFromDates(bdate: Date, today: Date): number {
        let [bday, bmon, byear] = [bdate.getDay(), bdate.getMonth(), bdate.getFullYear()];
        let [tday, tmon, tyear] = [today.getDay(), today.getMonth(), today.getFullYear()];

        if (tyear < byear) return null;

        let age = tyear - byear - 1;
        if (tmon > bmon)
            age++;

        else if (tmon === bmon) {
            if (tday >= bday)
                age++;
        }
        if (age < 0)
            return null;
        return age;
    }

    getOrderDataForModal(): IOrderData {
      return {
          id_exam_order: this.examId,
          order_date: this.orderDescription.statusDate,
          exam_info: {
            patient_name: this.orderDescription.patient.name,
            ordering_facility_name: this.orderDescription.client.facilityName,
            anatomy: {
              part_name: this.orderDescription.info.anatomyPart.name
            }
          },
          assign_physician_info: {
            id_physician_assign: this.orderDescription.physicianAssigned.id
          }
        };
    }

    isOrderComparisonViewPermission(): boolean {
      return  ((this.orderDescription.status.codename === 'ASSIGNED' || this.orderDescription.status.codename === 'IN_PROGRESS')
        && this.usersService.userId == this.orderDescription.physicianAssigned.id);
    }
}
