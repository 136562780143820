<div id="top-bar" class="col-col-md-12">
    <div class="row w-100">
        <div class="col-md-12 d-flex my-auto w-100">
            <h3 class="page-name">Znajdź Centrum Opisowe</h3>

            <form *ngIf="!envService.hideUnderConstruction" class="d-flex my-auto align-items-center justify-content-end"
                style="position: relative; top: -2px; right: -14px;">
                <app-search #search placeholder="SearchPlaceholder"></app-search>
            </form>

        </div>
    </div>

</div>

<div>

    <h3>Ostatnio dodane</h3>

    <div class="row mt-4">
        <div class="col-lg-6 col-md-12">

            <div class="card users-card">
                <div class="row d-flex">
                    <div class="col-md-2">
                        <a><img src="../../../assets/images/_dummy_data/logo-3-big.png"
                                style="width: 100% !important; height: auto;" alt=""></a>
                    </div>
                    <div class="col-md-6">
                        <p class="name mt-4"><a class="internal-link">Nazwa Centrum Opisowego</a></p>
                        <p>Warszawa, Polska</p>
                    </div>

                    <div *ngIf="!envService.hideUnderConstruction" class="col-md-4 d-flex my-auto justify-content-end">
                        <a routerLink="/siteUnderConstruction" class="btn btn-light mr-3">Zobacz profil</a>
                        <button type="button" class="btn btn-light card-action" id="ddAction3"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="svg" src="../../../assets/images/icons/more-dots.svg" alt="">
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
                            <a class="dropdown-item" routerLink="/siteUnderConstruction">Zaproś do współpracy</a>
                            <a class="dropdown-item" routerLink="/siteUnderConstruction">Wyślij wiadomość</a>
                            <a class="dropdown-item danger" routerLink="/siteUnderConstruction">Anuluj zaproszenie</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-6 col-md-12">

            <div class="card users-card">
                <div class="row d-flex">
                    <div class="col-md-2">
                        <a><img src="../../../assets/images/_dummy_data/logo-3-big.png"
                                style="width: 100% !important; height: auto;" alt=""></a>
                    </div>
                    <div class="col-md-6">
                        <p class="name mt-4"><a class="internal-link">Nazwa Centrum Opisowego</a></p>
                        <p>Warszawa, Polska</p>
                    </div>

                    <div *ngIf="!envService.hideUnderConstruction" class="col-md-4 d-flex my-auto justify-content-end">
                        <a routerLink="/siteUnderConstruction" class="btn btn-light mr-3">Zobacz profil</a>
                        <button type="button" class="btn btn-light card-action" id="ddAction3"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="svg" src="../../../assets/images/icons/more-dots.svg" alt="">
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
                            <a class="dropdown-item" routerLink="/siteUnderConstruction">Zaproś do współpracy</a>
                            <a class="dropdown-item" routerLink="/siteUnderConstruction">Wyślij wiadomość</a>
                            <a class="dropdown-item danger" routerLink="/siteUnderConstruction">Anuluj zaproszenie</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>