<div aria-hidden="true" aria-labelledby="cloudAgreementModalLabel" class="modal fade" id="cloudAgreementModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="cloudAgreementModalLabel">{{'PatientsPortal.CloudAgreement.Title' |translate}}</h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{'PatientsPortal.CloudAgreement.ListTitle' |translate}}
        <ul>
          <li *ngFor="let item of listOptions">{{item.text}}</li>
        </ul>
        {{'PatientsPortal.CloudAgreement.ModalTxt' |translate}} {{'PatientsPortal.CloudAgreement.Here' |translate}}
        {{'PatientsPortal.CloudAgreement.ModalTxt2' |translate}}
      </div>
      <div class="modal-footer">
        <a (click)="hide()">{{'PatientsPortal.CloudAgreement.DecideLater' |translate}}</a>
        <button class="btn btn-primary" (click)="activateCloud()">{{'PatientsPortal.CloudAgreement.Activate' |translate}}</button>
      </div>
    </div>
  </div>
</div>
