/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-operation-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-operation-modal.component";
var styles_ConfirmOperationModalComponent = [i0.styles];
var RenderType_ConfirmOperationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmOperationModalComponent, data: {} });
export { RenderType_ConfirmOperationModalComponent as RenderType_ConfirmOperationModalComponent };
export function View_ConfirmOperationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["aria-hidden", "true"], ["aria-labelledby", "confirmOperationModalLabel"], ["class", "modal fade"], ["id", "confirmOperationModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "modal-dialog modal-dialog-centered modal-md"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "modal-title mb-4"], ["id", "confirmOperationModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmOperation(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-lg btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmOperation(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-lg btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmOperation(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.content; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.confirmBtnTxt; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.rejectBtnTxt; _ck(_v, 15, 0, currVal_3); }); }
export function View_ConfirmOperationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-operation-modal", [], null, null, null, View_ConfirmOperationModalComponent_0, RenderType_ConfirmOperationModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConfirmOperationModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmOperationModalComponentNgFactory = i1.ɵccf("app-confirm-operation-modal", i2.ConfirmOperationModalComponent, View_ConfirmOperationModalComponent_Host_0, {}, { confirmed: "confirmed" }, []);
export { ConfirmOperationModalComponentNgFactory as ConfirmOperationModalComponentNgFactory };
