import { UsersService } from '@/services/users.service';
import { OdataService } from '@/services/odata.service';
import * as i0 from "@angular/core";
import * as i1 from "./users.service";
import * as i2 from "./odata.service";
var OrderActionsService = /** @class */ (function () {
    function OrderActionsService(userService, odataService) {
        this.userService = userService;
        this.odataService = odataService;
        this.cameFrom = '';
    }
    OrderActionsService.prototype.viewExamOrder = function (examId) {
        var params = this.odataService.paramsTokenAndLang({
            id_exam_order: examId
        });
        var result = this.odataService.postPrivate('get_co_exam_action_view_exam', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionStartDescrption = function (examId) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId
        });
        var result = this.odataService.postPrivate('co_exam_action_start_descrption', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionStartDescriptionCorrection = function (examId) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId
        });
        return this.odataService.postPrivate('co_exam_action_start_description_correction', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            return result.Status == 'OK';
        });
    };
    OrderActionsService.prototype.coExamReopenDescription = function (examId) {
        var params = this.odataService.paramsTokenAndLang({
            id_exam_order: examId,
            reason: 'Test'
        });
        var result = this.odataService.postPrivate('reopen_exam_description', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.ActionStatus) {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionAssignAndStartDescription = function (examId) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId
        });
        var result = this.odataService.postPrivate('co_exam_action_assign_and_start_description', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionReject = function (examId, value) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId,
            reject_reason: value
        });
        var result = this.odataService.postPrivate('co_exam_action_reject', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionAssignToPhysian = function (examId, value) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId,
            id_physician_to_assign: value
        });
        var result = this.odataService.postPrivate('co_exam_action_assign_to_physian', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionAssignToMe = function (examId) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId
        });
        var result = this.odataService.postPrivate('co_exam_action_assign_to_me', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
                // TODO
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionRejectByPhysician = function (examId, value) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId,
            reject_reason: value
        });
        var result = this.odataService.postPrivate('co_exam_action_reject_by_physician', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionOrderToCorrect = function (examId, value) {
        var params = this.odataService.paramsTokenAndLang({
            id_exam_order: examId,
            reason: value
        });
        return this.odataService.postPrivate('exam_description_to_correct', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return result.ActionStatus;
            }
            else {
                return false;
            }
        });
    };
    OrderActionsService.prototype.coExamActionCancelAssigment = function (examId, value) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId,
            reject_reason: value
        });
        var result = this.odataService.postPrivate('co_exam_cancel_assigment_action', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.prototype.coExamActionChangeAssigment = function (examId, value) {
        var params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId(),
            id_exam_order: examId,
            id_physician_to_assign: value
        });
        var result = this.odataService.postPrivate('co_exam_change_assigment_action', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            }
            else {
                return false;
            }
        });
        return result;
    };
    OrderActionsService.ngInjectableDef = i0.defineInjectable({ factory: function OrderActionsService_Factory() { return new OrderActionsService(i0.inject(i1.UsersService), i0.inject(i2.OdataService)); }, token: OrderActionsService, providedIn: "root" });
    return OrderActionsService;
}());
export { OrderActionsService };
