import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-add-filter-modal',
    templateUrl: './add-filter-modal.component.html',
    styleUrls: ['./add-filter-modal.component.scss']
})
export class AddFilterModalComponent implements OnInit {

    filterName: string = '';
    filterType: string = '';
    isFavourite: boolean = false;
    addFilterEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    show(type: string): void {
        this.filterType = type;
        this.addFilterEmitter.emit(false);
        $('#addFilterModal').modal('show');
    }

    hide(): void {
        this.filterType = '';
        this.addFilterEmitter.emit(false);
        this.filterName = '';
        $('#addFilterModal').modal('hide');
    }

    save() {
        if (this.filterName != '') {
            this.addFilterEmitter.emit(true);
        }
    }

}
