<div class="password-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row h-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">
                
                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>
                    <app-horizontal-stepper stepNum=3></app-horizontal-stepper>
                </div>

                <div>

                    <app-alert #errorAlert title="ForgotPassSecond"></app-alert>

                    <h2 [innerHTML]="'ForgotSecondScreen.Title' | translate" class="blue"></h2>
                    <p [innerHTML]="'ForgotSecondScreen.Text' | translate"></p>
                </div>
                
                <div>
                    <form>
                        <div class="form-group">
                            <label [innerHTML]="'ForgotSecondScreen.LoginName' | translate"
                                    class="bigLabel"></label>
                            <input class="form-control"
                                    disabled
                                    name="login"
                                    type="text"
                                    value="{{resultGetInfo.LoginName}}">
                        </div>
                        <div class="form-group">
                            <label [innerHTML]="'ForgotSecondScreen.NewLoginLabel' | translate"
                                    class="bigLabel"></label>
                            <p [innerHTML]="'ForgotSecondScreen.NewLoginSmall' | translate"></p>
                            <input [(ngModel)]="newPassword" [class.is-error]="errorField == 'pass' || errorField == 'confirmPass'"
                                    autofocus
                                    class="form-control"
                                    name="pass"
                                    placeholder="{{'ForgotSecondScreen.InputPlaceholder' | translate}}"
                                    type="password">

                        </div>
                        <div class="form-group">
                            <label [innerHTML]="'ForgotSecondScreen.NewLoginRetryLabel' | translate"
                                    class="bigLabel"></label>
                            <input [(ngModel)]="newPasswordRetry"
                                    [class.is-error]="errorField == 'confirmPass'"
                                    class="form-control"
                                    name="passRetry"
                                    placeholder="{{'ForgotSecondScreen.InputPlaceholderRetry' | translate}}"
                                    type="password">
                        </div>

                        <app-pesel-input #peselInput docType='PESEL'></app-pesel-input>

                        <div class="clearfix"></div>
                        <button (click)="forgoten_password_step2_change_password()"
                                [disabled]="newPassword === '' || newPassword.length < 8"
                                [innerHTML]="'ForgotSecondScreen.NewPassBtn' | translate"
                                class="btn btn-lg btn-primary"
                                id="CheckPasswordAndPeselBtn"></button>
                        <a [innerHTML]="'ForgotSecondScreen.BackToFirstScreen' | translate" class="btn btn-lg"
                            routerLink="/loginFirst"></a>
                    </form>
                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop">
                <app-news></app-news>
            </div>
        </div>
    </div>
</div>