/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authorize-description-module.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./authorize-description-module.component";
var styles_AuthorizeDescriptionModuleComponent = [i0.styles];
var RenderType_AuthorizeDescriptionModuleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthorizeDescriptionModuleComponent, data: {} });
export { RenderType_AuthorizeDescriptionModuleComponent as RenderType_AuthorizeDescriptionModuleComponent };
export function View_AuthorizeDescriptionModuleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["aria-hidden", "true"], ["aria-labelledby", "authorizeDescriptionModalLabel"], ["class", "modal fade"], ["id", "authorizeDescriptionModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "modal-title mb-4"], ["id", "authorizeDescriptionModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Czy na pewno chcesz autoryzowa\u0107?"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-light btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Anuluj"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-light btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.authorize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Autoryzuj"]))], null, null); }
export function View_AuthorizeDescriptionModuleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authorize-description-module", [], null, null, null, View_AuthorizeDescriptionModuleComponent_0, RenderType_AuthorizeDescriptionModuleComponent)), i1.ɵdid(1, 114688, null, 0, i2.AuthorizeDescriptionModuleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthorizeDescriptionModuleComponentNgFactory = i1.ɵccf("app-authorize-description-module", i2.AuthorizeDescriptionModuleComponent, View_AuthorizeDescriptionModuleComponent_Host_0, {}, {}, []);
export { AuthorizeDescriptionModuleComponentNgFactory as AuthorizeDescriptionModuleComponentNgFactory };
