import { Component, OnInit, ViewChild } from '@angular/core';
import { TextEditorComponent } from '@/utils/text-editor/text-editor.component';

@Component({
    selector: 'app-consultation',
    templateUrl: './consultation.component.html',
    styleUrls: ['./consultation.component.css']
})
export class ConsultationComponent implements OnInit {
    @ViewChild('text_editor') editor: TextEditorComponent;

    constructor() {
    }

    ngOnInit() {
    }

}
