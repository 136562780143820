<div aria-hidden="true" aria-labelledby="doubleSelectModalLabel" class="modal fade" id="doubleSelectModal" role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex flex-column">
                <h4 class="modal-title d-block"><strong>{{title}}</strong></h4>
                <h2 class="modal-title d-block" id="doubleSelectModalLabel">{{subtitle}}</h2>
                <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-3 pb-5">
                <div class="row d-flex align-items-center">
                    <div class="col-md-5">
                        <p><strong>{{left_title}}</strong></p>
                        <select class="custom-select custom-list-select" id="left_select_{{id}}" multiple
                                style="height: 300px">
                            <option *ngFor="let item of select1" value="{{item.id}}">
                                <img *ngIf="item.img_src" class="extra_image" src="{{item.img_src}}" alt="" />
                                {{item.text}}</option>
                        </select>
                    </div>
                    <div class="col-md-2 text-center">
                        <button (click)="onAssign()" class="btn btn-lg btn-grey mt-5 mb-2 d-block" style="width: 100%;">
                            {{'DoubleSelectModal.Assign' | translate}} &rarr;
                        </button>
                        <br/>
                        <button (click)="onRemove()" class="btn btn-lg btn-grey mt-2 d-block" style="width: 100%;">
                            &larr; {{'DoubleSelectModal.Delete' | translate}}
                        </button>
                    </div>
                    <div class="col-md-5">
                        <p><strong>{{right_title}}</strong></p>
                        <select class="custom-select custom-list-select" id="right_select_{{id}}" multiple
                                style="height: 300px">
                            <option *ngFor="let item of select2" value="{{item.id}}">
                                <img *ngIf="item.img_src" class="extra_image" src="{{item.img_src}}" alt="" />
                                {{item.text}}</option>
                        </select>
                    </div>
                </div>

            </div>
            <div class="modal-footer pt-5">
                <button (click)="cancelChanges()" class="btn btn btn-danger" type="button">
                    {{'DoubleSelectModal.CancelChanges' | translate}}
                </button>
                <button (click)="onSave()" class="btn btn btn-primary" type="button">
                    {{'DoubleSelectModal.ConfirmChanges' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
