import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Toast, ToastType, ToastTypeStringTable } from '@/core/components/toast/toast.model';

@Injectable({ providedIn: 'root'})
export class ToastService {
  private toastSubject = new Subject<Toast>();

  // for subscribing to toasts
  onToast(): Observable<Toast> {
    return this.toastSubject.asObservable();
  }
  // new Toast
  toast(message: string, type = 'info', autoClose = true) {
    const toast = new Toast({type: ToastTypeStringTable[type] || ToastType.Info, message: message, autoClose: autoClose});
    this.toastSubject.next(toast);
  }
}
