import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageService } from '@/services/page.service';

@Component({
  selector: 'app-medical-facility-profiles',
  templateUrl: './medical-facility-profiles.component.html',
  styleUrls: ['./medical-facility-profiles.component.scss']
})
export class MedicalFacilityProfilesComponent implements OnInit, OnDestroy {
  profiles: any[] = [];

  constructor(private pageService: PageService) {
  }

  ngOnInit() {
    this.getProfiles();
    this.pageService.setTranslatedPageTitle('Profiles.Title', 0);
    document.getElementById('facilityDoctorsProfileLink').setAttribute('class', 'active');
  }

  ngOnDestroy() {
    document.getElementById('facilityDoctorsProfileLink').setAttribute('class', '');
    this.pageService.resetPageTitle();
  }

  getProfiles(): void {
    this.profiles = [
      {
          name: 'prof. Barbara Leśniak',
          specialization: 'Kardiolog',
          city: 'Warszawa',
          country: 'Polska',
          id: 0
      },
      {
          name: 'prof. Grzegorz Nowak',
          specialization: 'Urolog',
          city: 'Warszawa',
          country: 'Polska',
          id: 0
      },
      {
          name: 'Anna Pronobis',
          specialization: 'Laryngolog',
          city: 'Ciechocinek',
          country: 'Polska',
          id: 0
      },
      {
          name: 'lek. med. Kazimierz Bąk',
          specialization: 'Internista',
          city: 'Lemingrad',
          country: 'Polska',
          id: 0
      }
  ];
  }

}
