<button (click)="sendSms()" *ngIf='false' class="btn btn-primary"
        type="buton">{{'PhoneModal.ActivatePhone' | translate}}</button>

<!-- Modal -->
<div class="modal fade" id="smsModal" tabindex="-1" role="dialog" aria-labelledby="smsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">{{'PhoneModal.ConfirmNumber' | translate}}</h2>
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <app-alert #errorAlert title="NumberActivation"></app-alert>

                <p [innerHtml]="numberSentInfo"></p>

                <form>
                    <div class="form-group" style="padding-bottom: 0; margin-bottom: 10px;">
                        <label for="pinInput" class="bigLabel">
                            {{'PhoneModal.WriteCode' | translate}}
                        </label>
                        <input type="text" name="pinInput" id="pinInput"
                               placeholder="{{'PhoneModal.WriteCode' | translate}}" value=""
                               [(ngModel)]="pin"/>
                    </div>
                    <p>
                        <small [innerHTML]="'PhoneModal.Small' | translate">
                        </small>
                    </p>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-lg btn-primary"
                        (click)="confirmPin()">{{'Confirm' | translate}}</button>
            </div>
        </div>
    </div>
</div>