import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { AlertComponent } from '..';

@Component({
  selector: 'app-order-to-correct-modal',
  templateUrl: './order-to-correct-modal.component.html',
  styleUrls: ['./order-to-correct-modal.component.scss']
})
export class OrderToCorrectModalComponent implements OnInit {

    @ViewChild('alert') alert: AlertComponent;

    promiseResolve: Function;
    promiseReject: Function;
    id: number;
    patientName: string;
    orderDate: string;
    orderName: string;
    anatomyPart: string;

    constructor() {
    }

    ngOnInit() {
    }

    show(order): void {
        this.id = order.id_exam_order;
        this.patientName = order.exam_info.patient_name;
        if (moment(order.order_date, 'DD.MM.YYYY HH:mm', true).isValid()) this.orderDate = order.order_date;
        else this.orderDate = moment(order.order_date).format('DD.MM.YYYY HH:mm');
        this.orderName = order.exam_info.ordering_facility_name;
        this.anatomyPart = order.exam_info.anatomy.part_name;
        (<HTMLInputElement>document.getElementById('reason')).value = '';
        $('#descToCorrectModal').modal('show');
    }

    hide(): void {
        $('#descToCorrectModal').modal('hide');
    }

    setPromiseResolve(func: Function): void {
        this.promiseResolve = func;
    }

    setPromiseReject(func: Function): void {
        this.promiseReject = func;
    }

    resolvePromise(): void {
        let value = (<HTMLInputElement>document.getElementById('reason')).value;
        this.promiseResolve(value);
    }
}
