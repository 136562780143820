/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-under-construction.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./site-under-construction.component";
var styles_SiteUnderConstructionComponent = [i0.styles];
var RenderType_SiteUnderConstructionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteUnderConstructionComponent, data: {} });
export { RenderType_SiteUnderConstructionComponent as RenderType_SiteUnderConstructionComponent };
export function View_SiteUnderConstructionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "welcome"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Strona w budowie."]))], null, null); }
export function View_SiteUnderConstructionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-under-construction", [], null, null, null, View_SiteUnderConstructionComponent_0, RenderType_SiteUnderConstructionComponent)), i1.ɵdid(1, 114688, null, 0, i2.SiteUnderConstructionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteUnderConstructionComponentNgFactory = i1.ɵccf("app-site-under-construction", i2.SiteUnderConstructionComponent, View_SiteUnderConstructionComponent_Host_0, {}, {}, []);
export { SiteUnderConstructionComponentNgFactory as SiteUnderConstructionComponentNgFactory };
