// interfaces for order description templates in DC
export interface IDescTemplate {
  id_template: number;
  template_name: string;
  content: string;
  modality_list: number[];
  anatomy_part_list: number[];
}

export const initDescTemplate: IDescTemplate = {
  anatomy_part_list: [], content: '', id_template: 0, modality_list: [], template_name: ''
};
