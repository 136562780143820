import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UsersService, OdataService, SessionService, NavigationService } from '@/services';
import { AlertComponent } from '@/utils';
import { PageService } from '@/services/page.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-registration-fourth',
    templateUrl: './registration-fourth.component.html',
    styleUrls: ['./registration-fourth.component.css']
})
export class RegistrationFourthComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlert') errorAlert: AlertComponent;
    @ViewChild('sendDataToast') sendDataToast: UniversalToastComponent;

    result: { value: string } = {value: ''};
    confirmByBank: {
        token: string,
        client_ip: string,
        language: string
    } = {
        token: '',
        client_ip: '',
        language: ''
    };
    confirmByScan: {
        token: string,
        client_ip: string,
        language: string,
        scan_front: string,
        scan_back: string
    } = {
        token: '',
        client_ip: '',
        language: '',
        scan_front: '',
        scan_back: ''
    };
    confirmBySertificate: {
        token: string,
        client_ip: string,
        language: string,
        base64: string,
        password: string
    } = {
        token: '',
        client_ip: '',
        language: '',
        base64: '',
        password: ''
    };
    userService: UsersService;
    registrationMethod: string = 'bank';
    ezlaFileName: string = '';
    frontImageFileName: string = '';
    backImageFileName: string = '';
    eZlaBtnVisible: boolean = false;
    certThumbnail1: any;
    certThumbnail2: any;

    constructor(userService: UsersService, private navigationService: NavigationService,
                private odataService: OdataService, private router: Router, private sessionService: SessionService,
                private pageService: PageService, @Inject(DOCUMENT) private document: any, private translate: TranslateService) {
        this.userService = userService;
        if (sessionStorage.getItem('ACCOUNT_ACTIVE') != null) {
            this.navigationService.navigateToDefault();
        }
    }

    ngOnInit() {
        sessionStorage.removeItem('register_mail');
        if (this.sessionService.getToken()) {
            this.confirmByBank.token = this.sessionService.getToken();
            this.confirmByScan.token = this.sessionService.getToken();
            this.confirmBySertificate.token = this.sessionService.getToken();
        }
        this.confirmByBank.language = this.userService.iLanguage;
        this.confirmByScan.language = this.userService.iLanguage;
        this.confirmBySertificate.language = this.userService.iLanguage;
        this.activeBank();
        this.pageService.setSignUpPageTitle();
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    activeBank() {
        this.errorAlert.clear();
        this.registrationMethod = 'bank';
    }

    activeId() {
        this.errorAlert.clear();
        this.registrationMethod = 'id';
    }

    activeEzla() {
        this.errorAlert.clear();
        this.registrationMethod = 'ezla';
    }

    onImageFrontSelected(event) {
        let files = event.target.files;
        let file = files[0];
        this.frontImageFileName = file.name;

        if (files && file) {
            let reader = new FileReader();
            reader.onload = this.handleFrontFile.bind(this);
            reader.readAsBinaryString(file);

            let thumbReader = new FileReader();
            thumbReader.onload = () => { 
                this.certThumbnail1 = thumbReader.result;
            }
            thumbReader.readAsDataURL(file);
        }
    }

    handleFrontFile(event) {
        let binaryString = event.target.result;
        this.confirmByScan.scan_front = btoa(binaryString);
        this.confirmByScan.scan_front = this.confirmByScan.scan_front.replace(/\+/gi, '-');
        this.confirmByScan.scan_front = this.confirmByScan.scan_front.replace(/\//gi, '_');
    }

    onImageBackSelected(event) {
        let files = event.target.files;
        let file = files[0];
        this.backImageFileName = file.name;

        if (files && file) {
            let reader = new FileReader();
            reader.onload = this.handleBackFile.bind(this);
            reader.readAsBinaryString(file);

            let thumbReader = new FileReader();
            thumbReader.onload = () => { 
                this.certThumbnail2 = thumbReader.result;
            }
            thumbReader.readAsDataURL(file);
        }
    }

    handleBackFile(event) {
        let binaryString = event.target.result;
        this.confirmByScan.scan_back = btoa(binaryString);
        this.confirmByScan.scan_back = this.confirmByScan.scan_back.replace(/\+/gi, '-');
        this.confirmByScan.scan_back = this.confirmByScan.scan_back.replace(/\//gi, '_');
    }

    onImageZusSelected(event) {
        let files = event.target.files;
        let file = files[0];
        this.ezlaFileName = file.name;

        if (files && file) {
            let reader = new FileReader();

            reader.onload = this.handleZusFile.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleZusFile(event) {
        let binaryString = event.target.result;
        this.confirmBySertificate.base64 = btoa(binaryString);
        this.confirmBySertificate.base64 = this.confirmBySertificate.base64.replace(/\+/gi, '-');
        this.confirmBySertificate.base64 = this.confirmBySertificate.base64.replace(/\//gi, '_');
    }

    account_confirmation_by_bank() {
        this.odataService.postPrivate('account_confirmation_by_bank', this.confirmByBank,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                this.result.value = result.value;
                this.document.location.href = this.result.value;
            });
    }

    account_confirmation_by_zus_certificate() {
        this.odataService.postPrivate('account_confirmation_by_zus_certificate', this.confirmBySertificate,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.sessionService.clearAllSessionInfo();
                    this.router.navigate(['loginFirst']);
                } else {
                    this.errorAlert.displayError(result.value);
                }
            });
    }

    account_confirmation_by_scan() {
        if (this.confirmByScan.scan_back == '' || this.confirmByScan.scan_front == '') {
            this.errorAlert.displayError('FileNotChosen');
        } else {
            this.errorAlert.clear();
            this.sendDataToast.success('UploadData');
            this.sendDataToast.isLoaderShown = true;
            this.sendDataToast.show();
            this.odataService.postPrivate('account_confirmation_by_scan', this.confirmByScan,
                res => {
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    if (result.value == 'OK') {
                        this.sendDataToast.isLoaderShown = false;
                        this.sendDataToast.success('UploadSuccess');
                        this.sessionService.clearAllSessionInfo();
                    } else {
                        this.sendDataToast.error('UploadFailed');
                    }
                    setTimeout(() => {
                        this.sendDataToast.hide();
                        if (result.value == 'OK') {
                            this.router.navigate(['loginFirst']);
                        }
                    }, 2500);
                });
            }
    }

}
