import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-insufficient-rights-modal',
    templateUrl: './insufficient-rights-modal.component.html',
    styleUrls: ['./insufficient-rights-modal.component.css']
})
export class InsufficientRightsModalComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    show(): void {
        $('#insufficientRightsModal').modal('show');
    }

}
