import { Component, Input, OnInit } from '@angular/core';
import { emptyAttachment, IAttachment} from '@/interfaces';
import { EnvService } from '@/services/env.service';

@Component({
  selector: 'app-attachment-view-modal',
  templateUrl: './attachment-view-modal.component.html',
  styleUrls: ['./attachment-view-modal.component.scss']
})
export class AttachmentViewModalComponent implements OnInit {

  url: string = '';
  attachment_title: string = '';
  attachment: IAttachment = emptyAttachment;

  constructor(private envService: EnvService) { }

  ngOnInit() {
  }

  show(input: IAttachment) {
    this.attachment = input;
    this.url = this.envService.examOrderAttUrl + input.attachment_uuid;
    $('#attachmentViewModal').modal('show');
  }

}
