<!-- start: orderInfoModal -->
<div aria-hidden="true"class="modal fade"
     role="dialog"
     tabindex="-1" id="orderInfoModal"
     aria-labelledby="orderInfoModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content pt-4 pb-0 px-0">
      <div class="modal-header px-4" style="border-bottom: 1px solid #d6e0ff;">
        <h3 class="modal-title mb-4" id="orderInfoModalLabel">{{'OrderInfoModal.Title' | translate}}</h3>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-4" *ngIf="orderInfo">
        <span class="mr-2" style="font-weight: bold;">{{'OrderInfoModal.Status' | translate}}:</span>
        <svg-icon *ngIf="orderInfo.status_info.status_name == 'NEW'
                        || orderInfo.status_info.status_name == 'TRANSFER_IN_PROGRES'
                        || orderInfo.status_info.status_name == 'TRANSFER_COMPLETED'"
                        src="../../../../assets/images/icons/icon__exam_order_send.svg">
              </svg-icon>
              <svg-icon *ngIf="orderInfo.status_info.status_name == 'IN_DESCRIBING_CENTER'"
                        src="../../../../assets/images/icons/icon__exam_order_assigned_to_physician.svg">
              </svg-icon>
              <svg-icon *ngIf="orderInfo.status_info.status_name == 'IN_DESCRIBING_CENTER_IN_PROGRESS'"
                        src="../../../../assets/images/icons/icon__exam_order_reported.svg">
              </svg-icon>
              <svg-icon *ngIf="orderInfo.status_info.status_name == 'DESCRIBING_CENTER_DONE'"
                        src="../../../../assets/images/icons/icon__exam_order_result.svg">
              </svg-icon>
              <svg-icon *ngIf="false" src="../../../../assets/images/icons/icon__exam_order_complaint.svg"></svg-icon>
              <svg-icon *ngIf="false" src="../../../../assets/images/icons/icon__exam_order_to_complete.svg"></svg-icon>
              <svg-icon *ngIf="orderInfo.status_info.status_name == 'TRANSFER_FAILED'
                        || orderInfo.status_info.status_name == 'DESCRIBING_CENTER_REJECTED'
                        || orderInfo.status_info.status_name == 'CANCELED'"
                        src="../../../../assets/images/icons/icon__exam_order_canceled.svg">
              </svg-icon>
        {{orderInfo.status_info.status}} <br>
        <div class="row mt-3">
          <div class="col" style="display: flex;">
            <span class="modality">{{orderInfo.modality.modality}}</span>
            <div class="my-auto ml-3">
              {{orderInfo.patient_name}}<br>
              PESEL: <span style="font-weight: bold;">{{orderInfo.pesel}}</span>
            </div>
          </div>
          <div class="col my-auto">
            HIS ID: <span style="font-weight: bold;">{{orderInfo.his_exam_id}}</span><br>
            {{'OrderInfoModal.Accn' | translate}}: <span style="font-weight: bold;">{{orderInfo.pm_accession_number}}</span>
          </div>
        </div>
        <div class="my-3"></div>
        <table class="table-borderless table-sm">
          <tr>
            <td><span style="font-weight: bold;">{{'OrderInfoModal.Priority' | translate}}:</span></td>
            <td>{{orderInfo.priority.priority}}</td>
          </tr>
          <tr>
            <td><span style="font-weight: bold;">{{'OrderInfoModal.ExamDate' | translate}}:</span></td>
            <td>{{facilityOrdersService.getDateString(orderInfo.exam_date)}}</td>
          </tr>
          <tr>
            <td><span style="font-weight: bold;">{{'OrderInfoModal.OrderDate' | translate}}:</span></td>
            <td>{{facilityOrdersService.getDateString(orderInfo.exam_order_date)}}</td>
          </tr>
          <tr>
            <td><span style="font-weight: bold;">{{'OrderInfoModal.OrderName' | translate}}:</span></td>
            <td>{{orderInfo.order_exam_type}}</td>
          </tr>
          <tr>
            <td><span style="font-weight: bold;">{{'OrderInfoModal.AnatomyPart' | translate}}:</span></td>
            <td>{{orderInfo.anatomy.part_name}}</td>
          </tr>
          <tr>
            <td><span style="font-weight: bold;">{{'OrderInfoModal.Icd10' | translate}}:</span></td>
            <td>{{orderInfo.icd10}}</td>
          </tr>
        </table>
        <div class="my-3"></div>
        <span style="font-weight: bold;">{{'OrderInfoModal.Comment' | translate}}:</span> <br>
        <span>{{orderInfo.exam_order_notes}}</span><br>
        <ng-container *ngIf="orderInfo.attachment_list">
          <div class="my-3"></div>
          <span style="font-weight: bold;">{{'OrderInfoModal.Attachments' | translate}}:</span> <br>
          <table class="table-sm table-borderless">
            <tr *ngFor="let attach of orderInfo.attachment_list">
              <td>{{attach.attachment_type.attachment_type_text}}:</td>
              <td><a (click)="attachment_view(attach)"
                     style="text-decoration: underline; color: var(--blue);">{{attach.filename}}</a></td>
            </tr>
          </table>
        </ng-container>
        <div class="my-3"></div>
        <span style="font-weight: bold;">{{'OrderInfoModal.Mandatory' | translate}}:</span><br>
        <span>{{orderInfo.describing_facility_name}}</span><br>
        <div class="my-3"></div>
        <div *ngIf="orderInfo.exam_description_info && orderInfo.exam_description_info.exam_description != ''">
        <span style="font-weight: bold;">{{'OrderInfoModal.Description' | translate}}:</span><br>
        <div  [innerHTML]="orderInfo.exam_description_info.exam_description"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-attachment-view-modal #attachmentViewModal></app-attachment-view-modal>
<!-- end: orderInfoModal -->
