<div aria-hidden="true"class="modal fade"
     role="dialog"
     tabindex="-1" id="attachmentViewModal"
     aria-labelledby="attachmentViewModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content pt-4 pb-0 px-0">
      <div class="modal-header px-4" style="border-bottom: 1px solid #d6e0ff;">
        <h3 class="modal-title mb-4" id="attachmentViewModalLabel">{{attachment.filename}}</h3>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-4 py-4" style="margin: 0 auto">
        <ng-container *ngIf="attachment.content_type.includes('image')">
          <img src="{{url}}" alt="">
        </ng-container>
        <ng-container *ngIf="attachment.content_type.includes('pdf')">
          <div class="pdf-viewer-container">
          <pdf-viewer [src]="url" [render-text]="true" [original-size]="false" [fit-to-page]="true" style="display: block; max-height: calc(80vh - 200px); width: 740px;"></pdf-viewer>
          </div>
        </ng-container>
        <ng-container *ngIf="attachment.content_type.includes('txt')"></ng-container>
      </div>
    </div>
  </div>
</div>

