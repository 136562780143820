/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./universal-toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./universal-toast.component";
import * as i4 from "@ngx-translate/core";
var styles_UniversalToastComponent = [i0.styles];
var RenderType_UniversalToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UniversalToastComponent, data: {} });
export { RenderType_UniversalToastComponent as RenderType_UniversalToastComponent };
function View_UniversalToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sendingExamInfo; _ck(_v, 0, 0, currVal_0); }); }
function View_UniversalToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "svg"], ["src", "../../../assets/images/loading.svg"]], null, null, null, null, null))], null, null); }
export function View_UniversalToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["aria-atomic", "true"], ["aria-live", "assertive"], ["class", "toast fade success hide"], ["data-autohide", "false"], ["id", "data-saved-toast"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "toast-body text-center pt-4 pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "info"], ["id", "infoMessage"], ["style", "color: #0848FF;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UniversalToastComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UniversalToastComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isSaveExam; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isLoaderShown; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 3, 0, currVal_0); }); }
export function View_UniversalToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-universal-toast", [], null, null, null, View_UniversalToastComponent_0, RenderType_UniversalToastComponent)), i1.ɵdid(1, 114688, null, 0, i3.UniversalToastComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UniversalToastComponentNgFactory = i1.ɵccf("app-universal-toast", i3.UniversalToastComponent, View_UniversalToastComponent_Host_0, {}, {}, []);
export { UniversalToastComponentNgFactory as UniversalToastComponentNgFactory };
