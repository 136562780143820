import { SafeResourceUrl } from '@angular/platform-browser';

export interface IField {
    name: string;
    topHeight: number;
}

export interface IModalities {
    value: string;
    id_group: number;
    language: string;
}

export interface IAnatomyParts {
    value: string;
    id_part: number;
    language: string;
}

export interface IPortfolio {
    enable: boolean;
    id_part: number;
    id_group: number;
}

interface ISpecialization {
    id_specialization: number;
    specialization_name: string;
}

interface IVacation {
    day_stop: string;
    day_start: string;
}

interface IWorkingHour {
    hour_stop: string;
    hour_start: string;
    day_of_week: string;
}

export interface IDoctorWorkingHourList {
    token: string;
    language: string;
    working_hours: string;
}

export interface IWorkingPlacesList {
    logo: SafeResourceUrl;
    logo_uuid: string;
    id_describing_center: number;
    describing_center_name: string;
}

export interface IPhysicianProfile {
    contact_info: {
        contact_info_enable: boolean,
        contact_info: {
            address: string,
            address_city: string,
            address_zip_code: string,
            email: string,
            phone_number: string
        }
    };
    about_me: {
        about_me_enable: boolean,
        about_me_info: {
            about_me: string
        }
    };
    main: {
        birthday: string,
        email: string,
        firstname: string,
        id_user: number,
        image_profile: string,
        last_name: string,
        phone_number: string,
        pwz: string,
        title: string,
        username: string
    };
    portfolio: {
        portfolio: IPortfolio[],
        portfolio_enable: boolean
    };
    specialization: {
        specialization: ISpecialization[],
        specialization_enable: boolean
    };
    statistics: {
        statistics_enable: boolean,
        statistics_info: {
            avarage_reaction_time: string,
            avarage_realization_time: string,
            orders_completed: string,
            rating: string
        }
    };
    working_hour_info: {
        working_hours: {
            vacation_list: IVacation[],
            working_hour_list: IWorkingHour[]
        },
        working_hours_enable: boolean
    };
    working_places: {
        working_places: IWorkingPlacesList[],
        working_places_enable: boolean
    };
    describing_center_status: {
      status: {
        id_status: number,
        status_name: string,
        status_text: string,
        id_describing_center: number
      },
    };
}

export interface IChangeDoctorsPrivacy {
    token: string;
    language: string;
    section_name: string;
    value: boolean;
}

export const phys_profile: IPhysicianProfile = {
    contact_info: {
        contact_info_enable: false,
        contact_info: {
            address: '',
            address_city: '',
            address_zip_code: '',
            email: '',
            phone_number: ''
        }
    },
    about_me: {
        about_me_enable: false,
        about_me_info: {
            about_me: ''
        }
    },
    main: {
        birthday: '',
        email: '',
        firstname: '',
        id_user: 0,
        image_profile: '',
        last_name: '',
        phone_number: '000000000',
        pwz: '',
        title: '',
        username: ''
    },
    portfolio: {
        portfolio: [],
        portfolio_enable: false
    },
    specialization: {
        specialization: [],
        specialization_enable: false
    },
    statistics: {
        statistics_enable: false,
        statistics_info: {
            avarage_reaction_time: '',
            avarage_realization_time: '',
            orders_completed: '',
            rating: ''
        }
    },
    working_hour_info: {
        working_hours: {
            vacation_list: [],
            working_hour_list: [
                {hour_stop: '', hour_start: '', day_of_week: ''},
                {hour_stop: '', hour_start: '', day_of_week: ''},
                {hour_stop: '', hour_start: '', day_of_week: ''},
                {hour_stop: '', hour_start: '', day_of_week: ''},
                {hour_stop: '', hour_start: '', day_of_week: ''},
                {hour_stop: '', hour_start: '', day_of_week: ''},
                {hour_stop: '', hour_start: '', day_of_week: ''},
            ]
        },
        working_hours_enable: false
    },
    working_places: {
        working_places: [],
        working_places_enable: false
    },
    describing_center_status: {
      status: null
    }
};
