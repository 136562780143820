<app-loader #loader></app-loader>
<div id="main_content" class="opis" [hidden]="loader.show">
    <div id="card-opis">
        <div class="card">
            <div class="card-body">
              <div *ngIf="this.descriptionService.mode.mode == 'description'" class="mb-2" style="display: flex; vertical-align: text-bottom">
                <h4>{{'OrderDescriptionScreens.ExamDescription' | translate}}</h4>
                <button type="button" class="btn btn-sm btn-primary" id="ddTemplates" (click)="setEditor()"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        style="margin-left: auto">Szablony ...</button>
                <div class="dropdown-menu dropdown-menu-right template-dropdown mt-2" aria-labelledby="ddTemplates">
                  <h4 *ngIf="dropdownTemplates != []" style="color: #999999; display: inline-block; padding-left: 10px; font-weight: bold; font-size: 13px; letter-spacing: 1px;">{{'OrderDescriptionScreens.Templates.Recent' | translate}}</h4>
                  <a *ngFor="let template of dropdownTemplates" (click)="onTemplateClicked(template)" class="dropdown-item">
                    {{template.template_name}}</a>
                  <a class="dropdown-item btn-light-blue templates-btn" style="text-align: center" (click)="openDescriptionTemplates()">{{'OrderDescriptionScreens.Templates.All' | translate}}</a>
                </div>
              </div> <!-- todo this, just better-->
                <app-text-editor [hidden]="!(this.descriptionService.mode.mode == 'description')" #text_editor></app-text-editor>

                <ng-container *ngIf="!(this.descriptionService.mode.mode == 'description')">
                    <p *ngIf="!descriptionService.isViewDraft && !this.descriptionService.isRejected" class="opis-badania-wyslany-header">
                        <strong>{{'OrderDescriptionScreens.ExamDescriptionPreview' | translate}}</strong>
                    </p>
                    <p *ngIf="descriptionService.isViewDraft && !this.descriptionService.isRejected" class="opis-badania-wyslany-header">
                        <strong>{{'OrderDescriptionScreens.ExamDescriptionPreview' | translate}} - {{'OrderDescriptionScreens.Draft' | translate}}</strong>
                    </p>
                    <p *ngIf='this.descriptionService.isRejected' class="opis-badania-wyslany-header">
                        <strong>{{'OrderDescriptionScreens.ExamDescriptionRejected'|translate}}</strong>
                    </p>
                    <div id="editor-not-active" [innerHTML]='descriptionService.descriptionText | safeHtml' ></div>
                </ng-container>
                <div class="custom-control custom-checkbox mt-3" id="wymagajace">
                    <input [disabled]="descriptionService.orderDescription.status.id == 9 || descriptionService.mode.mode == 'view'"
                           type="checkbox" class="custom-control-input" id="customCheck1"
                           [(ngModel)]="descriptionService.isCheckedAttention" [checked]="descriptionService.isCheckedAttention"
                            (ngModelChange)="saveWithAttention()">
                    <label class="custom-control-label" for="customCheck1">Wymagające uwagi</label>
                </div>
            </div>

            <div class="card-footer actions" style="padding-top: 0px;">
                <a *ngFor="let button of descriptionService.actionButtons"
                    class="btn btn-md" style="margin-top: 10px;"
                    [ngClass]="{
                        'btn-light': btnColors['grey'].includes(button.actionName),
                        'btn-danger redFont': btnColors['red'].includes(button.actionName),
                        'btn-success whiteFont': btnColors['green'].includes(button.actionName),
                        'group-right': button.actionName == 'SAVE_DESCRIPTION_DRAFT',
                        'inactiveButton':
                               (button.actionName == 'SAVE_DESCRIPTION_DRAFT' && !draftSender.isChanged)
                            || (button.actionName == 'SAVE_EXAM_DESCRIPTION_IN_TIME' && !this.canSend)
                            || (button.actionName == 'SAVE_EXAM_DESCRIPTION' && !this.canSend)
                    }"
                    (click)='actionsArray[button.actionName]()'>
                    {{button.buttonName}}</a>
            </div>

            <div class='send-timer mt-3 mb-4' *ngIf='timerActive' style="margin-left: 35px;">
                {{'OrderDescriptionScreens.SendingProgressLabel'|translate}}: <a class="ml-3"> {{timerLabel.timeLabel}}</a>
            </div>

        </div>
    </div>

</div>

<!-- start: skierowanieModal -->
<div class="modal fade" id="skierowanieModal" tabindex="-1" role="dialog" aria-labelledby="skierowanieModalLabel"
     aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <img src="" id="imagePreview" style="width: 100%; padding-top: 50px; height: auto;">
            </div>
        </div>
    </div>
</div>
<!-- end: skierowanieModal -->
<app-attachment-view-modal #attachmentViewModalComponent></app-attachment-view-modal>
<app-taking-description #takeoverModal></app-taking-description>
<app-send-attention-modal #saveAndSendAttentionExamModal></app-send-attention-modal>
<app-reject-modal #rejectModal></app-reject-modal>
<app-authorize-description-module #authorizeDescriptionModal></app-authorize-description-module>
<app-assign-physician-modal #assignPhysicianModal></app-assign-physician-modal>
<app-universal-toast #savedToast></app-universal-toast>
<app-reopen-exam-toast #reopenToast></app-reopen-exam-toast>
<app-pdf-not-available-modal #pdfModal></app-pdf-not-available-modal>
<app-order-to-correct-modal #toCorrectModal></app-order-to-correct-modal>
<app-order-description-templates-modal #orderDescriptionTemplatesModal></app-order-description-templates-modal>
<app-insuff-chars-modal #insuffCharsModal></app-insuff-chars-modal>
<!-- <app-timer-label #timerLabel></app-timer-label> -->
