/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./help.component";
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
export { RenderType_HelpComponent as RenderType_HelpComponent };
export function View_HelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tutaj powinna by\u0107 pomoc. Na razie szablon tej strony nie istnieje."]))], null, null); }
export function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 114688, null, 0, i2.HelpComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpComponentNgFactory = i1.ɵccf("app-help", i2.HelpComponent, View_HelpComponent_Host_0, {}, {}, []);
export { HelpComponentNgFactory as HelpComponentNgFactory };
