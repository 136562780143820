import { Component, OnInit, ViewChild } from '@angular/core';
import { OdataService, SessionService, UsersService } from '@/services';
import { EnvService } from '@/services/env.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;

interface IPhysician {
  id_physician: number;
  physician_name: string;
  profile_image_uuid: string;
  pwz: string;
}

@Component({
  selector: 'app-assign-physician-modal',
  templateUrl: './assign-physician-modal.component.html',
  styleUrls: ['./assign-physician-modal.component.css']
})
export class AssignPhysicianModalComponent implements OnInit {
  @ViewChild('selectPhys') physicianSelect: NgSelectComponent;
  assignPhysForm: FormGroup;

  physicians: IPhysician[] = [];
  promiseResolve: Function;
  promiseReject: Function;
  id: number;
  selectedValue: string = '';
  patientName: string;
  orderDate: string;
  orderName: string;
  anatomyPart: string;

  constructor(private odataService: OdataService, private userService: UsersService,
              private session: SessionService, public env: EnvService, private translate: TranslateService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.assignPhysForm = this.fb.group({
      physician: [null, Validators.required]
    });
  }

  get_co_describing_physicians(id: number): Promise<boolean> {
    let params = {
      token: this.session.getToken(),
      language: this.userService.iLanguage,
      id_exam_order: id
    };

    return this.odataService.postPrivate('get_co_describing_physicians', params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        let value = JSON.parse(result.Value);
        this.physicians = value.json;
        return true;
      } else {
        return false;
        // TODO
      }
    });
  }
  show(curOrder): void {
    this.selectedValue = '';
    this.assignPhysForm.reset();
    this.physicianSelect.items = this.physicians;
    if (curOrder.assign_physician_info.physician_assign) {
      let p = this.physicians.find(x => x.physician_name == curOrder.assign_physician_info.physician_assign);
      let item = this.physicianSelect.itemsList.findItem(p);
      this.physicianSelect.select(item);
    }
    this.id = curOrder.id_exam_order;
    this.patientName = curOrder.exam_info.patient_name;
    if (moment(curOrder.order_date, 'DD.MM.YYYY HH:mm', true).isValid()) this.orderDate = curOrder.order_date;
    else this.orderDate = moment(curOrder.order_date).format('DD.MM.YYYY HH:mm');
    this.orderName = curOrder.exam_info.ordering_facility_name;
    this.anatomyPart = curOrder.exam_info.anatomy.part_name;
    $('#przypiszZlecenieModal').modal('show');
  }

  hide(): void {
    this.selectedValue = '';
    $('#przypiszZlecenieModal').modal('hide');
  }

  setPromiseResolve(func: Function): void {
    this.promiseResolve = func;
  }

  setPromiseReject(func: Function): void {
    this.promiseReject = func;
  }

  setButtonText(): string {
    let text = '';
    if (this.selectedValue != '') this.translate.get('AssignPhysicianModal.Assign').subscribe((res) => text = res  + ' ' + this.selectedValue);
    else this.translate.get('AssignPhysicianModal.ChooseDoctor').subscribe((res) => text = res);
    return text;
  }

  resolvePromise(): void {
    let selectedOption = this.assignPhysForm.controls.physician.value;
    const res = selectedOption.id_physician;
    if (!isNaN(res)) {
      this.promiseResolve(res);
      this.hide();
    } else {
      console.log('Nie wybrano lekarza'); // TODO
    }
  }

  getProfileUrl(ph: IPhysician): string {
    let pic_url = this.env.profilePicURL + ph.profile_image_uuid;
    if (ph.profile_image_uuid === '00000000-0000-0000-0000-000000000000') {
      // no profile pic
      pic_url = '../../assets/images/icons/user-card-no-photo.svg';
    }
    return pic_url;
  }
}
