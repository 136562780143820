<div class="col-col-md-12" id="top-bar">
  <div class="row w-100">
    <div class="col-md-12">
      <h3 class="page-name">
        {{'DashboardScreen.Filters.ManageYourFilters' | translate}}
      </h3>

      <ul id="top-bar-nav">
        <li *ngFor="let type of filterService.typeOptions; let i = index;" [ngClass]="{'active': currentTypeOption == type}">
          <a routerLink="/manageMyFilters/{{types[i]}}">{{type}}</a>
        </li>
      </ul>

      <ul class="ml-auto d-flex my-auto align-items-center" id="top-bar-nav">
        <li>
          <button (click)="saveNewFilter()" class="btn btn-success">{{'DashboardScreen.Filters.NewFilter' | translate}}</button>
        </li>
      </ul>

    </div>
  </div>

</div>

<div [hidden]="getCurrentFilters().length == 0" class="row mt-5">
  <div *ngIf="getCurrentFilters().length != 0" class="col-md-3">
    <ng-container *ngIf="filterService.countOfFavourites > 0">
      <h5>{{'DashboardScreen.Filters.Favourite' | translate}}</h5>
      <div class="card mb-4">
        <div class="card-body p-0">
          <ul class="list-group filterList">
            <ng-container *ngFor="let filter of getCurrentFilters()">
            <li *ngIf="filter.isFavourite" class="list-group-item py-3"
                [ngClass]="{'active' : chosenFilter.id == filter.id}">
              <span style="line-height: 36px">
                {{filter.name}}
              </span>
              <ng-container *ngIf="chosenFilter.id != filter.id">
                <button type="button" class="btn btn-grey btn-sm card-action float-right" id="filterOptions"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterOptions">
                  <a class="dropdown-item" (click)="changeChosenFilter(filter)">{{'DashboardScreen.Filters.EditFilter' | translate}}</a>
                  <a class="dropdown-item danger" (click)="deleteFilter(filter.id)">{{'DashboardScreen.Filters.DeleteFilter' | translate}}</a>
                </div>
              </ng-container>
              <button *ngIf="chosenFilter.id == filter.id" class="btn btn-danger float-right"
                      (click)="deleteFilter(filter.id)">{{'DashboardScreen.Filters.Delete' | translate}}
              </button>
            </li>
          </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="getCurrentFilters().length > filterService.countOfFavourites">
      <h5>{{'DashboardScreen.Filters.Other' | translate}}</h5>
      <div class="card">
        <div class="card-body p-0">
          <ul class="list-group filterList">
            <ng-container *ngFor="let filter of getCurrentFilters()">
            <li *ngIf="!filter.isFavourite" class="list-group-item py-3"
                [ngClass]="{'active' : chosenFilter.id == filter.id}">
              <span style="line-height: 36px">
                {{filter.name}}
              </span>
              <ng-container *ngIf="chosenFilter.id != filter.id">
                <button type="button" class="btn btn-grey btn-sm card-action float-right" id="filterOptions"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterOptions">
                  <a class="dropdown-item" (click)="changeChosenFilter(filter)">{{'DashboardScreen.Filters.EditFilter' | translate}}</a>
                  <a class="dropdown-item danger" (click)="deleteFilter(filter.id)">{{'DashboardScreen.Filters.DeleteFilter' | translate}}</a>
                </div>
              </ng-container>
              <button *ngIf="chosenFilter.id == filter.id" class="btn btn-danger float-right"
                      (click)="deleteFilter(filter.id)">{{'DashboardScreen.Filters.Delete' | translate}}
              </button>
            </li>
          </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-md-9">
    <div class="row">
      <div class="col">
        <div class="card big-card" id="section-dane">
          <h3 *ngIf="getCurrentFilters().length != 0" class="card-title">{{chosenFilter.name}}</h3>
          <div class="card-body">
            <form>
              <div *ngIf="getCurrentFilters().length != 0" class="form-row">
                <div class="col form-group">
                  <label for="filterName">{{'DashboardScreen.Filters.FilterName' | translate}}</label>
                  <input id="filterName"
                         type="text"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="currentName">
                </div>
                <div class="col form-group mt-5">
                  <div class="custom-control custom-checkbox">
                    <input [checked]="chosenFilter.isFavourite"
                           [(ngModel)]="isFavourite"
                           [ngModelOptions]="{standalone: true}"
                           class="custom-control-input"
                           id="isFavourite"
                           type="checkbox">
                    <label class="custom-control-label"
                           for="isFavourite">
                           {{'DashboardScreen.Filters.AddToFavourites' | translate}}
                    </label>
                  </div>
                </div>
              </div>
              <app-dashboard-filters
                [hidden]="chosenFilter.type != 'dashboard'"
                #dashboardFilter></app-dashboard-filters>
              <app-archive-filters
                [hidden]="chosenFilter.type != 'archive'"
                #archiveFilter></app-archive-filters>
              <app-patient-filters [hidden]="chosenFilter.type != 'patients'" #patientFilter></app-patient-filters>
              <button class="btn btn-lg btn-success" (click)="saveChangesInFilter()">
                {{'DashboardScreen.Filters.SaveChanges' | translate}}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="emptyList" class="row mt-5">
  <div class="col mt-5" style="text-align: center;">
    <h2>{{'DashboardScreen.Filters.EmptyList' | translate}} "{{currentTypeOption}}"</h2>
  </div>
  
</div>

<app-add-filter-modal #addFilterModal></app-add-filter-modal>
<app-universal-toast #filterSavedToast></app-universal-toast>
