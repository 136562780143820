import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@/services/page.service';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-before-starting-work',
    templateUrl: './before-starting-work.component.html',
    styleUrls: ['./before-starting-work.component.css']
})
export class BeforeStartingWorkComponent implements OnInit, OnDestroy {

    constructor(public envService: EnvService, private pageService: PageService) {
    }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('BeforeWorkDoctor.Title', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }
}
