/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stepper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./stepper.component";
var styles_StepperComponent = [i0.styles];
var RenderType_StepperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepperComponent, data: {} });
export { RenderType_StepperComponent as RenderType_StepperComponent };
function View_StepperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["alt", ""], ["src", "../../../assets/images/icons/icon__finished.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "../../../assets/images/icons/icon__finished.svg"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StepperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.index + 1); _ck(_v, 1, 0, currVal_0); }); }
function View_StepperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [], [[2, "active", null], [2, "finished", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepperComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepperComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "label"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.stepNum > (_v.context.index + 1)); _ck(_v, 3, 0, currVal_2); var currVal_3 = !(_co.stepNum > (_v.context.index + 1)); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.stepNum == (_v.context.index + 1)); var currVal_1 = (_co.stepNum > (_v.context.index + 1)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(("Stepper." + _v.context.$implicit))); _ck(_v, 6, 0, currVal_4); }); }
export function View_StepperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "stepper-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "stepper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepperComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.steps; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_StepperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stepper", [], null, null, null, View_StepperComponent_0, RenderType_StepperComponent)), i1.ɵdid(1, 114688, null, 0, i6.StepperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepperComponentNgFactory = i1.ɵccf("app-stepper", i6.StepperComponent, View_StepperComponent_Host_0, { stepNum: "stepNum", stepperType: "stepperType" }, {}, []);
export { StepperComponentNgFactory as StepperComponentNgFactory };
