import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({ selector: '[clipboard]' })
export class CopyClipboardDirective {

    @Input('clipboard')
    public payload: string;

  // tslint:disable-next-line:no-output-rename
    @Output('copied')
    public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('dblclick', ['$event'])
    public onDblClick(event: MouseEvent): void {
        event.preventDefault();
        if (!this.payload)
            return;

        let listener = (e: ClipboardEvent) => {
            let clipboard = e.clipboardData || window['clipboardData'];
            clipboard.setData('text', this.payload.toString());
            e.preventDefault();
            this.copied.emit(this.payload);
        };

        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
        let selectedText = document.getSelection();
        selectedText.removeAllRanges();
    }
}
