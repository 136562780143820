<div aria-hidden="true"class="modal fade"
     role="dialog"
     tabindex="-1" id="consultationModal"
     aria-labelledby="consultationModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <ng-container *ngIf="!requestSent">
        <div class="modal-header">
          <h2 class="modal-title">{{'Consultation.ConsultationTitle' | translate}}</h2>
          <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex align-self-center align-items-center justify-content-between mt-4">
            <div>
              <strong>{{exam.order_exam_modality}} {{exam.anatomy_part.anatomy_part_text}}</strong> <br>
              <strong>{{exam.exam_accession_number}}</strong><br>
              {{'Consultation.ExamDate' |translate}} {{facilityOrdersService.getDateString(exam.exam_date)}} <br>
              <br>
              {{exam.first_name}} {{exam.last_name}} ({{exam.sex}})  <br>
              PESEL: {{exam.pesel}}
            </div>
            <div>
              <button class="btn btn-primary btn-md" (click)="onSubmitConsultation()">{{'Consultation.CreateAIOrder' | translate}}</button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="requestSent">
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 *ngIf="requestSuccess">{{'ToastMessage.Consultation' |translate}}</h2>
        <h2 *ngIf="!requestSuccess">{{'ToastMessage.ConsultationFail' |translate}}</h2>
      </ng-container>
    </div>
  </div>
</div>
<app-universal-toast #toast></app-universal-toast>
