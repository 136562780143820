/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i4 from "angular-svg-icon";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./news.component";
import * as i7 from "../../services/odata.service";
import * as i8 from "../../services/session.service";
import * as i9 from "@angular/platform-browser";
var styles_NewsComponent = [i0.styles];
var RenderType_NewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsComponent, data: {} });
export { RenderType_NewsComponent as RenderType_NewsComponent };
function View_NewsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "news-container__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_NewsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "news-container__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [["class", "news-container__copy"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.Title; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.MessageDate; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit.Contents; _ck(_v, 5, 0, currVal_2); }); }
export function View_NewsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "news-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["alt", "Wiadomo\u015Bci"], ["class", "news-container__icon"], ["src", "../../../assets/images/icons/memo.svg"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "news-container__header"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "news-container__news-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "../../../assets/images/icons/memo.svg"; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.news; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("News")); _ck(_v, 3, 0, currVal_1); }); }
export function View_NewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-news", [], null, null, null, View_NewsComponent_0, RenderType_NewsComponent)), i1.ɵdid(1, 245760, null, 0, i6.NewsComponent, [i7.OdataService, i8.SessionService, i9.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsComponentNgFactory = i1.ɵccf("app-news", i6.NewsComponent, View_NewsComponent_Host_0, {}, {}, []);
export { NewsComponentNgFactory as NewsComponentNgFactory };
