<div class="register-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>

                    <h2 [innerHTML]="'RegistrationFourthPayScreen.Title' | translate" class="blue"></h2>
                    <app-alert #errorAlert class="row ml-0" title="Registration"></app-alert>
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a (click)="activeBank()" [class.active]="registrationMethod == 'bank'"
                               [innerHTML]="'RegistrationFourthPayScreen.Pay1zl' | translate"
                               class="nav-link"></a>
                        </li>
                        <li class="nav-item">
                            <a (click)="activeId()"  [class.active]="registrationMethod == 'id'" class="nav-link">
                                {{'RegistrationFourthPayScreen.IdScan' | translate}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="this.eZlaBtnVisible" (click)="activeEzla()" [class.active]="registrationMethod == 'ezla'"
                               [innerHTML]="'RegistrationFourthPayScreen.Certificate' | translate"
                               class="nav-link" style="margin-right: 0px;"></a>
                        </li>
                    </ul>

                    <!-- Kolejne metody będą trochę inaczej ustawione jeśli będą tutaj-->

                </div>

                <div *ngIf="registrationMethod == 'bank'">
                    <p [innerHTML]="'RegistrationFourthPayScreen.Text' | translate"></p>
                    <button (click)="account_confirmation_by_bank()"
                            [innerHTML]="'RegistrationFourthPayScreen.ActivateBtn' | translate"
                            class="btn btn-lg btn-primary"
                            type="button">
                    </button>
                </div>


                <div *ngIf="registrationMethod == 'ezla'">
                    <p [innerHTML]="'RegistrationFourthEZLAScreen.Text' | translate"></p>
                    <div class="form-group">
                        <label [innerHTML]="'RegistrationFourthEZLAScreen.File' | translate" class="bigLabel custom-file-control"></label>
                        <label class="btn"
                               for="certFile">{{'RegistrationFourthEZLAScreen.ChooseFile' | translate}}<span *ngIf="ezlaFileName != ''" class="badge badge-primary ml-2">{{ezlaFileName}}</span></label>
                        <input (change)="onImageZusSelected($event)" id="certFile"
                               type="file" accept=".pfx" style="visibility: hidden; width: 1rem">
                    </div>

                    <div class="form-group">
                        <label [innerHTML]="'RegistrationFourthEZLAScreen.CertPass' | translate" class="bigLabel"
                               for="certPass"></label>
                        <input [(ngModel)]="confirmBySertificate.password" class="form-control" id="certPass"
                               name="pass" type="password">
                    </div>
                    <button (click)="account_confirmation_by_zus_certificate()" [innerHTML]="'RegistrationFourthEZLAScreen.ActivateBtn' | translate"
                            class="btn btn-lg btn-primary"
                            type="button"></button>
                </div>

                <div *ngIf="registrationMethod == 'id'">
                    <p [innerHTML]="'RegistrationFourthScanScreen.Text' | translate">
                    </p>

                    <div class="form-group">
                        <label [innerHTML]="'RegistrationFourthScanScreen.FirstTitle' | translate" class="bigLabel"
                               for="certFile1"></label>
                        <label class="btn"
                               for="certFile1">{{'RegistrationFourthScanScreen.ChooseFile' | translate}}<span *ngIf="frontImageFileName != ''" class="badge badge-primary ml-2">{{frontImageFileName}}</span></label>
                        <input (change)="onImageFrontSelected($event)" id="certFile1"
                               type="file" accept="image/*" style="visibility: hidden; width: 1rem">

                        <div *ngIf="certThumbnail1" style="width: 160px; height: 100px; overflow: hidden; position: relative;">
                            <img [src]="certThumbnail1" style="width: 160px; height: auto; position: absolute; top: 50%; transform: translateY(-50%)">
                        </div>
                        
                    </div>

                    <div class="form-group">
                        <label [innerHTML]="'RegistrationFourthScanScreen.SecondTitle' | translate" class="bigLabel"
                               for="certFile2"></label>
                        <label class="btn"
                               for="certFile2">{{'RegistrationFourthScanScreen.ChooseFile' | translate}}<span *ngIf="backImageFileName != ''" class="badge badge-primary ml-2">{{backImageFileName}}</span></label>
                        <input (change)="onImageBackSelected($event)" id="certFile2"
                               type="file" accept="image/*" style="visibility: hidden; width: 1rem">

                        <div *ngIf="certThumbnail2" style="width: 160px; height: 100px; overflow: hidden; position: relative;">
                            <img [src]="certThumbnail2" style="width: 160px; height: auto; position: absolute; top: 50%; transform: translateY(-50%)">
                        </div>
                    </div>

                    <button (click)="account_confirmation_by_scan()" class="btn btn-lg btn-primary" type="button" style="margin-bottom: 50px;">
                        Aktywuj konto
                    </button>
                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop align-items-center d-flex">

                <app-stepper stepNum=4 stepperType="user"></app-stepper>

            </div>
        </div>
    </div>
</div>

<app-universal-toast #sendDataToast></app-universal-toast>
