import { Router } from '@angular/router';
import { OdataService } from './../../services/odata.service';
import { SessionService } from './../../services/session.service';
import { UsersService } from '@/services';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SendMessageToUserModalComponent } from '../send-message-to-user-modal/send-message-to-user-modal.component';
import { UniversalToastComponent } from '../universal-toast/universal-toast.component';

@Component({
  selector: 'app-empty-orders-list',
  templateUrl: './empty-orders-list.component.html',
  styleUrls: ['./empty-orders-list.component.scss']
})
export class EmptyOrdersListComponent implements OnInit {

  @Input() ordersCount: number;
  @Input() isLoaderShow: boolean;
  @Input() isSearched: boolean;
  @Input() isFiltered: boolean;
  @Input() page: string;
  @Input() mode: string;

  @ViewChild('sendMessage') sendMessage: SendMessageToUserModalComponent;
  @ViewChild('dataSavedToast') dataSavedToast: UniversalToastComponent;

  constructor(public userService: UsersService, private sessionService: SessionService, private odataService: OdataService,
              private router: Router) { }

  ngOnInit() {
  }

  sendMessageFunc(): void {
      this.sendMessage.setUser({
          pwz: '',
          image_uuid: null,
          action_list: [],
          contact_info: {
              email: '',
              address: '',
              address_city: '',
              phone_number: '',
              address_zip_code: ''
          },
          working_places: [],
          id_physician: this.userService.getContactId(),
          physician_name: this.userService.getContactName(),
          specialization: [],
          image_uuid_serial: '',
          specialization_enable: false
      });
      this.sendMessage.show();
  }

  lockScreen(sendMessage: boolean): void {
    if (sendMessage) this.sessionService.sendMessage('user' + this.userService.userId, 'lock');

    this.odataService.clearRequests();
    sessionStorage.setItem('currentPage', decodeURI(this.router.url.toString()));
    let modal = document.getElementsByClassName('modal-backdrop fade show');
    if (modal.item(0) != null) {
        modal.item(0).setAttribute('class', '');
    }
    this.sessionService.lockSession();
    this.router.navigate(['unlockScreen']);
  }
}
