<div id="top-bar">
    <div class="row w-100">
        <div class="col-md-12 d-flex align-items-center my-auto w-100">
            <h3 class="page-name">{{'Profiles.Title' | translate}}</h3>

            <ul *ngIf="!envService.hideUnderConstruction" class="d-flex ml-4 my-auto align-items-center" id="top-bar-nav">
                <li class="active">
                    <a routerLink="/siteUnderConstruction">{{'Profiles.Physicians' | translate}}</a>
                </li>
                <li *ngIf="!envService.hideUnderConstruction">
                    <a routerLink="/siteUnderConstruction">{{'Profiles.Centres' | translate}}</a>
                </li>
                <li *ngIf="!envService.hideUnderConstruction">
                    <a routerLink="/siteUnderConstruction">{{'Profiles.Centres2' | translate}}</a>
                </li>
            </ul>

            <form>
                <ul class="top-bar-nav">
                    <li>
                      <div class="custom-control custom-checkbox mr-4" style="position: relative;">
                          <input class="custom-control-input" id="ulubioneCheck" type="checkbox"
                                 (change)="getOnlyCoworkers()" [(ngModel)]="isOnlyCoworkers" [ngModelOptions]="{standalone: true}">
                          <label class="custom-control-label" for="ulubioneCheck" style="font-weight: normal;">
                              {{'Profiles.OnlyFromActiveDc' | translate}}
                          </label>
                      </div>
                    </li>
                </ul>
                <app-search #search placeholder="Profiles.SearchLPlaceholder"></app-search>
            </form>

        </div>
    </div>

</div>

<div>

    <!--<h5 class="divider">{{'Profiles.RecentlyAddedLProfiles' | translate}}</h5>-->
    <table class="tile-table mt-4" id="tile-table">
        <thead>
            <tr class="tile">
                <th>&nbsp;</th>
                <th>{{'Profiles.Name' | translate}}</th>
                <th>{{'Profiles.Contact' | translate}}</th>
                <th>{{'Profiles.Specializations' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let profile of profiles; let j = index;" class="tile">
                <td class="profiles-user-photo">
                    <svg-icon *ngIf="profile.image_uuid == null" [src]="'assets/images/icons/user-card-no-photo.svg'" style="width: 100% !important; height: 100%;" alt=""></svg-icon>
                    <img *ngIf="profile.image_uuid != null" src="{{envService.profilePicURL + profile.image_uuid}}" style="width: 90px !important; height: 90px;" alt="" onerror="this.src='assets/images/icons/user-card-no-photo.svg'" #img>
                </td>
                <td id="physicianDcList{{profile.id_physician}}" style="min-width: 250px; white-space: normal">
                    <div id="hiddenDcString{{profile.id_physician}}">
                        <div *ngFor="let dc of profile.working_places; let i = index;" id="physicianDc{{profile.id_physician}}{{i}}">
                            {{dc.describing_center_name}},
                        </div>
                    </div>

                    <div class="name copiedTooltip" [clipboard]="profile.physician_name" (copied)="copiedText = $event">{{profile.physician_name}}
                      <div class="tooltipContainer" style="top: -17px;">
                        <span *ngIf="copiedText == profile.physician_name" class="tooltip copied">{{'Copied' | translate}}</span>
                        <span *ngIf="copiedText != profile.physician_name" class="tooltip notCopied">{{'CopyText' | translate}}</span>
                      </div>
                    </div>
                    <div style="opacity: 0.66">
                        {{getPhysicianDcList(j)}}
                    </div>
                </td>
                <td>
                    <ng-container>
                        <ng-container *ngIf="profile.contact_info.address">{{profile.contact_info.address}}, {{profile.contact_info.address_zip_code}} {{profile.contact_info.address_city}}<br></ng-container>
                        <ng-container *ngIf="profile.contact_info.phone_number">{{profile.contact_info.phone_number}}<br></ng-container>
                        <ng-container *ngIf="profile.contact_info.email">{{profile.contact_info.email}}</ng-container>
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngFor="let spec of profile.specialization">
                            {{spec.specialization_name}}<br>
                    </ng-container>
                </td>
                <td class="actions">
                    <ng-container *ngFor="let action of profile.action_list">
                        <a *ngIf="action.id_action == 10001" class="btn btn-grey" (click)="makeAction(profile, action.action_name)">
                            {{action.button_name}}
                        </a>
                    </ng-container>
                    <button *ngIf="profile.action_list.length > 1" aria-expanded="false" aria-haspopup="true"
                        class="btn btn-light card-action dotsClass" data-toggle="dropdown" id="ddAction1" type="button">
                    <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                    </button>
                    <div *ngIf="profile.action_list.length > 1" aria-labelledby="ddAction1"
                        class="dropdown-menu dropdown-menu-right">
                        <ng-container *ngFor="let action of profile.action_list; let i = index;">
                            <a *ngIf="action.id_action != 10001" class="btn btn-grey" (click)="makeAction(profile, action.action_name)"
                                class="dropdown-item" style="cursor: pointer;">
                                {{action.button_name}}
                            </a>
                        </ng-container>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<app-pagination #pagination componentName="Profiles" [hidden]="profiles.length == 0"></app-pagination>
<app-invite-to-cooperation-modal #inviteToCooperationModal (invitationSent)="afterInvite($event)"></app-invite-to-cooperation-modal>
<app-universal-toast #actionToast></app-universal-toast>
<app-send-message-to-user-modal #sendMessage [actionToast]="actionToast"></app-send-message-to-user-modal>
