import { EventEmitter, Injectable } from '@angular/core';
import { IFilter } from '@/interfaces';
import { OdataService, SessionService, UsersService } from '@/services';

interface MyFilters {
    type: string;
    filters: IFilter[];
}

@Injectable({
    providedIn: 'root'
})
export class FiltersService {

    myFilters: MyFilters[] = [
        {type: 'dashboard', filters: []},
        {type: 'archive', filters: []},
        {type: 'patients', filters: []}
    ];
    typeOptions: string[] = ['Zlecenia', 'Archiwum zleceń', 'Pacjenci'];
    currentChosenType: string = '';
    currentChosenPatientId = '';
    countOfFavourites: number = 0;

    // saved search inputs
    searchInputArchive: string = '';
    searchInputDashboard: string = '';
    searchInputPatients: string = '';

    clearSearchFromFilterTileEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private odata: OdataService, private userService: UsersService, private sessionService: SessionService) { }

    saveFilter(filter: any, type: string, name: string, isFavourite: boolean): Promise<boolean> {
        let params = {
            id_describing_center: this.userService.getCenterId(),
            filter_name: name,
            filter_type: type,
            filter_value: JSON.stringify(filter),
            is_favourite: isFavourite
        };

        return this.odata.postPrivate('co_save_user_filter', this.odata.paramsTokenAndLang(params),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                return result.value == 'OK';
            });
    }

    clearSavedSearchAndFilters(type?: string) {
      if (!type) {
        this.searchInputPatients = '';
        this.searchInputDashboard = '';
        this.searchInputArchive = '';
        sessionStorage.removeItem('timeIsChanged');
        sessionStorage.removeItem('dashboardMyFilter');
        sessionStorage.removeItem('archiveMyFilter');
        sessionStorage.removeItem('patientsMyFilter');
      } else if (type == 'dashboard') {
        this.searchInputDashboard = '';
        sessionStorage.removeItem('dashboardMyFilter');
      } else if (type === 'patients') {
        this.searchInputPatients = '';
        sessionStorage.removeItem('patientsMyFilter');
      } else if (type === 'archive') {
        this.searchInputArchive = '';
        sessionStorage.removeItem('timeIsChanged');
        sessionStorage.removeItem('archiveMyFilter');
      }
    }

    clearSearch(emit: boolean, type: string) { // clearing search inputs from filter tiles
      this.clearSearchFromFilterTileEvent.emit(emit);
      if (type === 'ARCHIVE') this.searchInputArchive = '';
      else if (type === 'DASHBOARD') this.searchInputDashboard = '';
      else if (type === 'PATIENTS') this.searchInputPatients = '';
    }

    getFilters(type: string): Promise<boolean> {
        let params = {
            id_describing_center: this.userService.getCenterId(),
            filter_type: type
        };
        this.countOfFavourites = 0;

        return this.odata.postPrivate('co_get_user_filters', this.odata.paramsTokenAndLang(params),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    result = JSON.parse(result.Value);
                    this.myFilters.find(x => x.type == type).filters = [];
                    result.forEach((filter) => {
                        this.myFilters.find(x => x.type == type).filters.push({
                            id: filter.id_filter,
                            name: filter.filter_name,
                            type: filter.filter_type,
                            value: filter.filter_value,
                            isFavourite: filter.is_favourite
                        });
                        if (filter.is_favourite) {
                            this.countOfFavourites++;
                        }
                    });
                    return true;
                } else return false;
            });
    }

    getMyFilters(type: string): IFilter[] {
        return this.myFilters.find(x => x.type == type).filters;
    }

    deleteFilter(id: number): Promise<boolean> {
        let params = {
            id_describing_center: this.userService.getCenterId(),
            filter_id: id
        };

        return this.odata.postPrivate('co_delete_user_filter', this.odata.paramsTokenAndLang(params),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                return result.value == 'OK';
            });
    }

    getStatusTypesForExamOrders(): Promise<any> {
        return this.odata.postPrivate('get_status_types_for_exam_orders', this.odata.paramsTokenAndLang({}), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                return value;
            } else return [];
        });
    }

    getStatusTypesForArchive(): Promise<any> {
        return this.odata.postPrivate('get_status_types_for_archive', this.odata.paramsTokenAndLang({}), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                return value;
            } else return [];
        });
    }

    getStatusTypesForPatients(): Promise<any> {
        return this.odata.postPrivate('co_get_status_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    let value: any[] = JSON.parse(result.Value);
                    return value;
                } else return [];
            });
    }

    getModalitiesGroups(): Promise<any> {
        return this.odata.postPrivate('get_modalities_groups', this.odata.paramsTokenAndLang({}), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                return value;
            } else return [];
        });
    }

    getAnatomyParts(): Promise<any> {
        return this.odata.postPrivate('get_anatomy_parts', this.odata.paramsTokenAndLang({}), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                return value;
            } else return [];
        });
    }

    getAccessibilityOptionsForExams(): Promise<any> {
        return this.odata.postPrivate('get_accessibility_options_for_exams', this.odata.paramsTokenAndLang({}), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                return value;
            } else return [];
        });
    }

    getPriorityTypes(): Promise<any> {
        return this.odata.postPrivate('get_priority_types', this.odata.paramsTokenAndLang({}), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                return value;
            } else return [];
        });
    }

    getPhysiciansByCenter(type: string): Promise<any> {
        let funcName = '';
        if (type == 'dashboard') {
            funcName = 'get_co_describing_physicians_by_center';
        } else if (type == 'archive') {
            funcName = 'get_co_archive_describing_center_physician_by_center';
        }

        return this.odata.postPrivate(funcName, this.odata.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    let value: any[] = JSON.parse(result.Value);
                    return value;
                } else return [];
            });
    }

    getFacilityTypes(): Promise<any> {
        return this.odata.postPrivate('co_get_medical_facility_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    let value: any[] = JSON.parse(result.Value);
                    return value;
                } else return [];
            });
    }

    getDoctorsForPatients(): Promise<any> {
        return this.odata.postPrivate('co_get_phycisian_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    let value: any[] = JSON.parse(result.Value);
                    return value;
                } else return [];
            });
    }

    getMedicalFacilities(): Promise<any> {
        return this.odata.postPrivate('co_get_medical_facility_list_for_patient_list', this.odata.paramsTokenAndLang({
                id_describing_center: this.userService.getCenterId()
            }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    let value: any[] = JSON.parse(result.Value);
                    return value;
                } else return [];
            });
    }


    getAvailableInviteRoles(): Promise<any> {
      return this.odata.postPrivate('get_dc_availble_invite_roles', {
          token: this.sessionService.getToken(),
          language: this.userService.iLanguage,
          id_describing_center: this.userService.getCenterId()
        },
        res => {
          let returnedJSON = JSON.stringify(res);
          let result = JSON.parse(returnedJSON);
          if (result.Status === 'OK') {
            let value: any[] = JSON.parse(result.Value).json_info;
            return value;
          } else {
            console.log('REASON', result.Status);
            return [];
          }
        });
    }

    removeSessionFilters() {
        sessionStorage.removeItem('filtersDashboard');
        sessionStorage.removeItem('filtersArchive');
        sessionStorage.removeItem('filtersPatients');
    }
}
