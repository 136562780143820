import { Component, OnInit } from '@angular/core';
import { CoProfileComponent } from '@/dm/co-profile/co-profile.component';
import { OdataService, SessionService, UsersService } from '@/services';
import { PortfolioService } from '@/services/portfolio.service';

@Component({
    selector: 'app-add-list-modal',
    templateUrl: './add-list-modal.component.html',
    styleUrls: ['./add-list-modal.component.css']
})
export class AddListModalComponent implements OnInit {

    profile: CoProfileComponent;
    id_price_list = '';
    newListName: string = '';

    constructor(profile: CoProfileComponent, private odataService: OdataService,
                private session: SessionService, private userService: UsersService,
                private portfolioService: PortfolioService) {
        this.profile = profile;
    }

    ngOnInit() {
    }

    changeId(event) {
        this.id_price_list = event.target.value;
    }

    createDescribingCenterPriceList() {

        this.odataService.postPrivate('create_describing_center_price_list',
            {
                token: this.session.getToken(),
                id_describing_center: this.userService.getCenterId(),
                id_price_list: parseInt(this.id_price_list),
                price_list_name: this.newListName,
                language: this.userService.iLanguage
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                console.log(result);
                this.profile.editing.newPriceList = false;
                if (result.Status == 'OK') {
                    this.portfolioService.priceLists = [];
                    this.portfolioService.listCOPortfolios = [];
                    this.portfolioService.finalDefaultCoPortfolio = [];
                    this.profile.getOwnDescribingCenterProfile();
                } else if (result.Status == 'OTHER_REASON') {
                    // TODO
                }
            }
        );
    }

}
