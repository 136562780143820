import { Injectable } from '@angular/core';
import { OdataService } from '@/services/odata.service';
import {
  IShareInfo,
  IPatientDescPDFPP,
  IPatientExamDescPP,
  IPatientExamDetailsPP,
  PatientDashboardExam, initShareInfo
} from '@/interfaces/PatientsPortal';

@Injectable({
  providedIn: 'root'
})

export class PatientPortalService {
  folderShareInfo: IShareInfo = {share_end_time: '', share_pin: '', share_status: 0};

  constructor(private odata: OdataService) { }

  getExamList( limit: number, offset?: number, filter?: string, order_column?: string): Promise<PatientDashboardExam[]> {
    return this.odata.postPrivate('pp_patient_exam_folder_list', this.odata.paramsTokenAndLang({
      order_column: order_column ? order_column : '',
      filtr: filter ? filter : '',
      offset: offset ? offset : 0,
      limit: limit
    }), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status === 'OK') {
        return  JSON.parse(result.Value);
      } else return [];
    });
  }

  getExamDetails(examId: number, examType: number): Promise<IPatientExamDetailsPP> {
    return this.odata.postPrivate('pp_patient_get_exam_info', this.odata.paramsTokenAndLang({
      id_exam: examId,
      id_type: examType
    }), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status === 'OK') {
        return  JSON.parse(result.Value);
      } else return undefined;
    });
  }

  getExamDesc(examId: number, examType: number): Promise<IPatientExamDescPP[]> {
    return this.odata.postPrivate('pp_patient_get_exam_description', this.odata.paramsTokenAndLang({
      id_exam: examId,
      id_type: examType
    }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status === 'OK') {
          if (result.Value != '') return JSON.parse(result.Value);

          else return [];
        } else return [];
    });
  }

  getDescPDF(examId: number, examType: number, examOrder: number): Promise<IPatientDescPDFPP> {
    return this.odata.postPrivate('pp_get_exam_description_pdf', this.odata.paramsTokenAndLang({
      id_exam: examId,
      id_type: examType,
      id_exam_order: examOrder
    }), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);

      if (result.Status === 'OK') {
        if (result.Value != '') return JSON.parse(result.Value);
        else return undefined;
      } else return undefined;
    });
  }

  removeAccents(str) {
    let accents = 'ĄĘŚĆŁÓŻŹŃąęśćłóżźń';
    let accentsOut = 'AESCLOZZNaesclozzn';
    str = str.split('');
    let strLen = str.length;
    let i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
  }

  getImageClassName(resource) {
    let etn = resource.exam_type.exam_type_name;
    let apt = resource.anatomy_part.anatomy_part_text;

    let exam_type_string: string;
    exam_type_string = etn.replace(/\s+/g, '-');
    exam_type_string = this.removeAccents(exam_type_string);
    exam_type_string = exam_type_string.toLowerCase();

    let anatomy_part_string: string;
    anatomy_part_string = apt.replace(/\s+/g, '-');
    anatomy_part_string = this.removeAccents(anatomy_part_string);
    anatomy_part_string = anatomy_part_string.toLowerCase();

    return exam_type_string + '-' + anatomy_part_string;
  }

  generateSharePin(): Promise<string> {
    return this.odata.postPrivate('pp_patient_generate_share_pin', this.odata.paramsTokenAndLang({}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status === 'OK') return JSON.parse(result.Value).share_pin;
        else return 'ERROR';
      });
  }

  getFolderShareStatus(): Promise<any> {
    return this.odata.postPrivate('pp_get_folder_share_status', this.odata.paramsTokenAndLang({}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status === 'OK') this.folderShareInfo = JSON.parse(result.Value).folder_info;
        else this.folderShareInfo = initShareInfo;
      });
  }

  shareExam(id_exam): Promise<boolean> {
    return this.generateSharePin().then( pin => {
      if (pin != 'ERROR') return this.odata.postPrivate('pp_patient_share_exam', this.odata.paramsTokenAndLang({
        share_pin: pin,
        id_exam: id_exam
      }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        return result.Status === 'OK';
      });
      else return false;
    });
  }

  shareFolder(): Promise<boolean> {
    return this.generateSharePin().then(pin => {
      if (pin != 'ERROR') return this.odata.postPrivate('pp_share_folder', this.odata.paramsTokenAndLang({
        share_pin: pin
        }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status === 'OK') {
          this.folderShareInfo = JSON.parse(result.Value).folder_info;
          return true;
        } else return false;
      });
      else return false;
    });

  }

  cancelSharingFolder(): Promise<boolean> {
    return this.odata.postPrivate('pp_cancel_sharing_folder', this.odata.paramsTokenAndLang({}), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      return JSON.parse(result.Value).Status;
    });
  }

  cancelSharingExam(exam_id): Promise<boolean> {
    return this.odata.postPrivate('pp_patient_cancel_share_exam', this.odata.paramsTokenAndLang({id_exam: exam_id}), res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      return JSON.parse(result.Value).Status;
    });
  }

  checkPasswordFromToken(password: string): Promise<boolean> {
    return this.odata.postPrivate('confirm_user_password', this.odata.paramsTokenAndLang({ password: password }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        return JSON.parse(result.Value).Status;
      });
  }

}
