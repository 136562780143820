<div class="register-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>
            
        
                <div>

                    <app-alert #errorAlert title=""></app-alert>
                    <div *ngIf="isExpired" class="alert alert-danger big-alert" role="alert">
                        <div class="pre"><svg-icon alt="" src="../../../assets/images/icons/icon__alert.svg"></svg-icon></div>
                        <div class="alert-content">
                            <h4 class="alert-heading">{{'RegistrationSecondScreen.AlertTitle' | translate}}</h4>
                            <p [innerHTML]="'RegistrationSecondScreen.AlertText' | translate"></p>
                            <button (click)="regenerate_registraction_link()" class="btn btn-lg" type="button">
                                {{'RegistrationSecondScreen.AlertButton' | translate}}
                            </button>
                        </div>
                    </div>

                    <form>
                        <h2 [innerHTML]="'RegistrationSecondScreen.Title' | translate" class="blue"></h2>
                        <p><span [innerHTML]="'RegistrationSecondScreen.Text' | translate:mail"> </span> <a
                                routerLink="/registrationFirst"><strong>{{'RegistrationSecondScreen.ClickHere' | translate}}</strong></a>
                        </p>
                        <div class="form-group">
                            <label [innerHTML]="'RegistrationSecondScreen.InputPin' | translate" class="bigLabel"
                                    for="inputPIN"></label>
                            <input [(ngModel)]="registerStep1Pin.pin" aria-describedby="emailHelp" autofocus
                                    class="form-control pin"
                                    id="inputPIN" name="pin"
                                    placeholder="{{'RegistrationSecondScreen.PinPlaceholder' | translate}}"
                                    type="text">
                        </div>
                        <div class="clearfix"></div>
                        <button (click)="register_user_step1_check_pin()"
                                [disabled]="registerStep1Pin.pin === ''"
                                [innerHTML]="'RegistrationSecondScreen.NextBtn' | translate"
                                class="btn btn-lg btn-primary">
                        </button>
                    </form>
                </div>


                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop align-items-center d-flex">
            
                <app-stepper stepNum=2 stepperType="user"></app-stepper>
            
            </div>
        </div>
    </div>
</div>