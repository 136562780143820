import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { isObject, isArray } from 'util';
import { Router } from '@angular/router';
import { EnvService } from './env.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OdataService {

    errorsEmitter: EventEmitter<string> = new EventEmitter<string>();

    headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers_private: HttpHeaders;
    readonly ROOT_URL = this.env.publicServerURL;
    readonly ROOT_URL_PRIVATE = this.env.privateServerURL;

    httpPrivateRequests: Set<Promise<any>> = new Set<Promise<any>>();

    handleRights = (res) => {
        let status = res && res.Status;
        if (status === 'INSUFFICIENT_RIGHTS') {
            this.errorsEmitter.emit(status);
        }
        return res;
    }

    handleInvalidToken = (res) => {
        let status = res && res.Status;
        if (status === 'INVALID_TOKEN') {
            this.errorsEmitter.emit(status);
            this.sessionService.clearAllSessionInfo();
            this.router.navigate(['/loginFirst']);
            throw status;
        }
        return res;
    }

    constructor(private http: HttpClient, private sessionService: SessionService,
        private router: Router, private env: EnvService) {
    }

    communicate_error(error) {
        console.log(error);
    }

    insert_header_private() {
        let token = this.sessionService.getToken();
        this.headers_private = new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        );
    }

    postPublic(serverFunctionName, params, resFunction): Promise<any> {
        let fullURL = this.ROOT_URL + serverFunctionName;

        return this.http.post(fullURL, params, {headers: this.headers}).toPromise()
            .then(resFunction).catch(this.communicate_error);
    }

    postPrivate(serverFunctionName, params, resFunction): Promise<any> {
        this.insert_header_private();
        let fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;

        let promise: Promise<any> = this.http.post(fullURL, params, {headers: this.headers_private}).toPromise()
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return this.handleInvalidToken(res); } else { return ''; } })
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return this.handleRights(res); } else { return ''; } })
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return resFunction(res); } else { return ''; } })
            .catch((error) => { if (this.httpPrivateRequests.has(promise)) { this.communicate_error(error); } })
            .then((res) => { this.httpPrivateRequests.delete(promise); return res; });

        this.httpPrivateRequests.add(promise);

        return promise;
    }

    getPrivate(serverFunctionName, params, resFunction): Promise<any> {
        this.insert_header_private();
        let fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;

        let promise: Promise<any> = this.http.get(fullURL, { params: params, headers: this.headers_private }).toPromise()
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return this.handleInvalidToken(res); } else { return ''; } })
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return this.handleRights(res); } else { return ''; } })
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return resFunction(res); } else { return ''; } })
            .catch((error) => { if (this.httpPrivateRequests.has(promise)) { this.communicate_error(error); } })
            .then((res) => { this.httpPrivateRequests.delete(promise); return res; });

        this.httpPrivateRequests.add(promise);

        return promise;
    }

    patchPrivate(serverFunctionName, body, params, resFunction): Promise<any> {
        this.insert_header_private();
        let fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;

        let promise: Promise<any> = this.http.patch(fullURL, body, { params: params, headers: this.headers_private }).toPromise()
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return this.handleInvalidToken(res); } else { return ''; } })
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return this.handleRights(res); } else { return ''; } })
            .then((res) => { if (this.httpPrivateRequests.has(promise)) { return resFunction(res); } else { return ''; } })
            .catch((error) => { if (this.httpPrivateRequests.has(promise)) { this.communicate_error(error); } })
            .then((res) => { this.httpPrivateRequests.delete(promise); return res; });

        this.httpPrivateRequests.add(promise);

        return promise;
    }

    postPrivateObservable(serverFunctionName, params): Observable<any> {
        this.insert_header_private();
        const fullURL = this.ROOT_URL_PRIVATE + serverFunctionName;

        return this.http.post(fullURL, params, {headers: this.headers_private});
    }

    get_ip(resFunction) {
        this.http.get('https://api.ipify.org?format=json').toPromise()
            .then(resFunction).catch(this.communicate_error);
    }

    paramsTokenAndLang(otherParams: object): object {
        return {
            token: this.sessionService.getToken(),
            language: this.sessionService.language,
            ...otherParams
        };
    }

    toCamel = (s) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase().replace('-', '').replace('_', '');
        });
    }

    keysToCamel = function (o) {
        if (isArray(o)) {
            return o.map((i) => this.keysToCamel(i));
        } else if (isObject(o)) {
            const n = {};
            Object.keys(o).forEach((k) => {
                n[this.toCamel(k)] = this.keysToCamel(o[k]);
            });
            return n;
        }

        return o;
    };

    clearRequests(): void {
        this.httpPrivateRequests.clear();
    }

    parseResponse(val): any {
      const returnedJSON = JSON.stringify(val);
      return JSON.parse(returnedJSON);
    }

  getFile(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${this.sessionService.getToken()}` },
    }).pipe(map(v => URL.createObjectURL(v)));
  }

}
