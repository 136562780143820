import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-confirm-mail',
    templateUrl: './confirm-mail.component.html',
    styleUrls: ['./confirm-mail.component.css']
})
export class ConfirmMailComponent implements OnInit, OnDestroy {

    constructor(private pageService: PageService) {
    }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('ConfirmMailScreen.Title', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

}
