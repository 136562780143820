import { Component, OnInit, SecurityContext, OnDestroy } from '@angular/core';
import { OdataService, SessionService } from '@/services';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import moment from 'moment';

interface INews {
    Title: string[];
    Contents: string;
    MessageDate: string;
}

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
    news: INews[] = [];

    subscription: Subscription;

    constructor(private odata: OdataService, private sessionService: SessionService, private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.getNews();

        this.subscription = this.sessionService.emitter.subscribe(() => {
            this.getNews();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getNews(): void {
        this.getIMessage().then((res) => {
            this.news = res;
        }).catch((error) => {
            console.log(error);
        });
    }

    getIMessage(): Promise<any> {
        return this.odata.postPublic('login_info_messages',
            { language: this.sessionService.language },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                let news;

                if (result.value != {} && result.value != null && result.value) {
                    news = result.value;
                    news.forEach(element => {
                        this.parseElement(element);
                    });
                } else {
                    news = [];
                }

                return news;
            });
    }

    parseElement(element) {
        element.Title = element.Title.split('\\n');
        element.Contents = this.sanitizer.sanitize(SecurityContext.HTML, element.Contents);
        element.MessageDate = moment(element.MessageDate).format('DD.MM.YYYY');
    }

}
