import { Injectable, EventEmitter } from '@angular/core';
import { OdataService } from './odata.service';
import { ISavedResult } from '@/interfaces';

export interface IParams {
    token: string;
    language: string;
    id_exam_order: number;
    description: string;
    attention_required: boolean;
    version: number;
}

@Injectable({
    providedIn: 'root'
})
export class DraftSenderService {
    lastRequestFinished: boolean = true;
    requestsCount: number = 0;
    finalParams: IParams = null;
    draftSentEmitter: EventEmitter<ISavedResult> = new EventEmitter<ISavedResult>();
    isSaved = false;
    isChanged = false;

    constructor(private odataService: OdataService) { }

    saveExamDraft(params: IParams): void {
        if (this.lastRequestFinished) {
            this.lastRequestFinished = false;
            this.odataService.postPrivate('save_exam_description_draft', params, res => {
                if (this.finalParams != null) {
                    this.sendFinal();
                } else {
                    this.lastRequestFinished = true;
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    let event: ISavedResult = {success: result.ActionStatus, exam_id: params.id_exam_order};
                    this.draftSentEmitter.emit(event);
                }
                return res;
            });
        } else {
            this.finalParams = params;
            this.requestsCount++;
        }
        this.isSaved = true;
        this.isChanged = false;
    }

    sendFinal(): void {
        this.requestsCount = 0;
        this.odataService.postPrivate('save_exam_description_draft', this.finalParams, res => {
            if (this.requestsCount == 0) {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                let event: ISavedResult = {success: result.ActionStatus, exam_id: this.finalParams.id_exam_order};
                this.draftSentEmitter.emit(event);
                this.finalParams = null;
                this.lastRequestFinished = true;
            } else {
                this.sendFinal();
            }
            return res;
        });
    }
}
