<p class="status">
  <span class="dot"></span>
  {{orderInfoInput.status.name || '-'}},
  {{orderInfoInput.priority.name || '-'}}
  ({{orderInfoInput.statusDate}})
  <app-progress-bar *ngIf="orderInfoInput.status.codename == 'RECEIVED_FROM_MF'"
                    id="1" [progress]="orderInfoInput.transferProgress+'%'"></app-progress-bar>
</p>
<div class="description my-4">
  <div class="modality float-left mr-3">{{orderInfoInput.info.modality.name || '-'}}
  </div>

  <div>
    <strong>{{orderInfoInput.info.anatomyPart.name || '-'}}</strong><br />
    {{orderInfoInput.info.order_exam_type}}<br />
  </div>
</div>

<div class='exam-date description my-4'>
  <strong>{{'OrderDescriptionScreens.ExamDate' | translate}}</strong><br />{{orderInfoInput.examDate}}
</div>

<div class="additional-description">
  <strong>{{'OrderDescriptionScreens.Current.AssignedTo' | translate}}</strong><br />{{orderInfoInput.physicianAssigned.name || '-'}}<br /><br />
  <strong>{{'OrderDescriptionScreens.Current.AccessionNumber' | translate}}</strong><br />{{orderInfoInput.accession_number || '-'}}<br /><br />
  <strong>{{'OrderDescriptionScreens.Current.ICD10' | translate}}</strong><br />
  {{orderInfoInput.icd10 || '-'}}<br />
  {{orderInfoInput.icd10_text || '-'}}
  <br /><br />
  <strong>{{'OrderDescriptionScreens.Current.Comments' | translate}}</strong><br />
  <div *ngIf="orderInfoInput.info.descriptionArray">
    <ng-container *ngFor="let desc of orderInfoInput.info.descriptionArray ">
      <div *ngIf="desc != ''" class="my-2" [innerHTML]="desc"></div>
    </ng-container>
    <div class="my-2" *ngIf="!isDescArrayEmpty(orderInfoInput.info.descriptionArray)">
      {{'OrderDescriptionScreens.Current.Empty' | translate}}
    </div>
  </div>
</div>

<div class="opis-skierowanie">
  <p class="strong mb-0 pb-0">{{'OrderDescriptionScreens.Current.View' | translate}}</p>
  <div class="my-2">
    <ul *ngIf="orderInfoInput.attachment_prescription_list.length != 0">
      <li *ngFor="let attach of orderInfoInput.attachment_prescription_list">
        <a (click)="showAttachment.emit(attach)"
           style="text-decoration: underline; color: var(--blue);">{{attach.filename}}</a>
      </li>
    </ul>
    <span
      *ngIf="orderInfoInput.attachment_prescription_list.length == 0">{{'OrderDescriptionScreens.Current.NoPrescription' | translate}}</span>
  </div>
</div>

<div class="opis-attachments">
  <p class="strong mb-0 pb-0">{{'OrderDescriptionScreens.Current.Attachments' | translate}}</p>
  <div class="my-2">
    <ng-container *ngIf="orderInfoInput.attachment_other_list.length == 0">{{'OrderDescriptionScreens.Current.Empty' | translate}}</ng-container>
    <ul *ngIf="orderInfoInput.attachment_other_list.length != 0">
      <li *ngFor="let attach of orderInfoInput.attachment_other_list">
        <a (click)="showAttachment.emit(attach)"
           style="text-decoration: underline; color: var(--blue);">{{attach.filename}}</a>
      </li>
    </ul>
  </div>
</div>
