<div class="login-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">
                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>
                <div>

                    <app-alert #loginAlert title="Login"></app-alert>

                    <form autocomplete="off">
                        <div class="form-group">
                            <input type="text" id="username" name="username" style="visibility: hidden; height: 0px;" [value]="login.value">
                            <p [innerHTML]="'LoginSecondScreen.LoginStrong' | translate:login"></p>
                        </div>
                        <div class="form-group">
                            <label [innerHTML]="'LoginSecondScreen.PassLabel' | translate" class="bigLabel"
                                    for="inputPassword"></label>
                            <a [innerHTML]="'LoginSecondScreen.ForgotPassBtn' | translate"
                                class="btn btn-small aside"
                                routerLink="/forgotPassFirst"></a>
                            <input *ngIf="isSuccess || !isError"
                                    [(ngModel)]="password"
                                    aria-describedby="passwordHelp"
                                    class="form-control"
                                    id="inputPassword"
                                    name="pass"
                                    placeholder="{{'LoginSecondScreen.PassPlaceholder' | translate}}"
                                    type="password">
                            <input *ngIf="isError"
                                    [(ngModel)]="password"
                                    aria-describedby="passwordHelp"
                                    class="form-control is-error"
                                    name="pass"
                                    placeholder="{{'LoginSecondScreen.PassPlaceholder' | translate}}"
                                    type="password">
                        </div>

                        <app-pesel-input #peselInput docType='PESEL'></app-pesel-input>

                        <div class="clearfix"></div>
                        <a [innerHTML]="'LoginSecondScreen.BackToFirstScreenBtn' | translate" class="btn btn-lg" routerLink="/loginFirst"></a>
                        <button (click)="login_attempt_step3()" [disabled]="password === ''"
                                id="CheckPasswordAndPeselBtn"
                                [innerHTML]="'LoginSecondScreen.LoginNextBtn' | translate"
                                class="btn btn-lg btn-primary"></button>
                    </form>
                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop">
                <app-news></app-news>
            </div>
        </div>
    </div>
</div>