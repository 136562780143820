export class EnvService { // Dane są zaciągane z env.js

    public envLoaded = false;
    public enableDebug = true;
    public hideUnderConstruction = true;
    public redirectToMessagesPage = true;
    public registrationDisabled = false;

    public bugSnagEnabled = false;

    public publicServerURL = 'https://odata.emkaweb.pl:7596/evolution.svc/';
    public privateServerURL = 'https://odata.emkaweb.pl:7597/evolution.svc/';
    public registrationDisabledUrl = 'https://dev.emkaweb.pl';

    public profilePicURL = 'https://filesdev.emkaweb.pl/profile/';
    public miniProfilePicURL = 'https://filesdev.emkaweb.pl/miniprofile/';
    public coLogoURL = 'https://filesdev.emkaweb.pl/co/profile/';
    public coMiniLogoURL = 'https://filesdev.emkaweb.pl/co/miniprofile/';
    public attachmentFileUrl = 'https://filesdev.emkaweb.pl/msgatt/';
    public examDescPdfUrl = 'https://filesdev.emkaweb.pl/dc/examdesc/';
    public idScanSide1 = 'https://filesdev.emkaweb.pl/admin/idscan/side1/';
    public idScanSide2 = 'https://filesdev.emkaweb.pl/admin/idscan/side2/';
    public adminPWZAttachment = 'https://filesdev.emkaweb.pl/admin/pwz/';
    public examOrderAttUrl = 'https://filesdev.emkaweb.pl/exam_order_att/';
    public shareExamUrl = 'https://link.zbadani.pl';

    public patientExamUploadTus = 'https://filesdev.emkaweb.pl/pltr';

    public tinymceAPIKey = 'test';
    public tinymceConfig = {
        toolbar: 'undo redo copy paste | bold italic underline strikethrough | fontselect fontsizeselect formatselect | casechange permanentpen formatpainter removeformat',
        branding: false,
        elementpath: false,
        browser_spellcheck: true,
        forced_root_block: false,
        menubar: '',
        content_style: '#tinymce { line-height: 1.2 }',
        language_url: '../../../assets/tinymce-lang/pl.js',
        language: '',
        plugins: 'paste',
        smart_paste: false,
        paste_data_images: false,
        formats: {
           bold : {inline : 'b' },
           italic : {inline : 'i' },
           underline: { inline: 'u' }
        },
        extended_valid_elements : 'u,b,i,b/strong,i/em',
        invalid_elements : 'a, img, button'
    };

    public minutesLeft = '15';
    public secondsLeft = '0';
    public secondsLeftForLoadingExams = '10';

    public mainPage = 'https://zbadani.pl';
    public regulationsPath = 'https://zbadani.pl/regulamin/';
    public privacyPolicyPath = 'https://zbadani.pl/polityka-prywatnosci/';

    constructor() {
    }

}
