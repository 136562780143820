<div aria-hidden="true" class="modal fade"
     role="dialog"
     tabindex="-1" id="shareExamModal"
     aria-labelledby="shareExamModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-block">
        <div class="d-flex">
        <h3 class="modal-title mb-2" id="shareExamModalLabel">
          <ng-container *ngIf="!isFolderSharing && !afterSubmitScreen">{{'PatientsPortal.ShareExamModal.Title' |translate}}</ng-container>
          <ng-container *ngIf="!isFolderSharing && afterSubmitScreen">{{'PatientsPortal.ShareExamModal.SharedTitle' |translate}}</ng-container>
          <ng-container *ngIf="isFolderSharing && !afterSubmitScreen">{{'PatientsPortal.ShareExamModal.ShareAllTitle' |translate}}</ng-container>
          <ng-container *ngIf="isFolderSharing && afterSubmitScreen">{{'PatientsPortal.ShareExamModal.AllSharedTitle' |translate}}</ng-container>
        </h3>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
      </div>

      <div class="modal-body">
        <!-- Share singular exam -->
        <ng-container *ngIf="!isFolderSharing">
            <ng-container *ngIf="!afterSubmitScreen">
              {{'PatientsPortal.ShareExamModal.SingExamTxt' |translate}} ({{endShareDate}})<br /><br />
              {{'PatientsPortal.ShareExamModal.SingExamTxt2nd' |translate}} <a (click)="isFolderSharing = true" style="color: var(--blue); text-decoration: underline;">{{'PatientsPortal.ShareExamModal.ClickHere' |translate}}</a><br /><br />
            </ng-container>
            <!-- Second screen, after submitting sharing singular exam or clicking to see the link again-->
            <ng-container *ngIf="afterSubmitScreen">

              <p>Do otwarcia teczki badań będzie konieczny sześciocyfrowy PIN oraz Twój numer PESEL. Zapisz PIN w bezpiecznym miejscu
                i
                przekaż lekarzowi:</p>

              <form class="shareForm">
                <div>
                  <label class="sr-only" for="urlInput"></label>
                  <div class="copiedTooltip" [clipboard]="env.shareExamUrl" (copied)="copiedText = $event">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">{{'PatientsPortal.ShareExamModal.ShareThisUrl' |translate}}</div>
                      </div>

                      <input type="text" class="form-control" id="urlInput" placeholder="{{env.shareExamUrl}}" readonly>
                      <div class="tooltipContainer" style="top: -7px; right: 150px">
                        <span *ngIf="copiedText == env.shareExamUrl" class="tooltip copied">{{'Copied' | translate}}</span>
                        <span *ngIf="copiedText != env.shareExamUrl"
                              class="tooltip notCopied">{{'CopyText' | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label class="sr-only" for="codeInput"></label>
                  <div class="copiedTooltip" [clipboard]="shareInfo.share_pin" (copied)="copiedText = $event">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">{{'PatientsPortal.ShareExamModal.ShareThisCode' |translate}}</div>
                      </div>
                      <input type="text" class="form-control" id="codeInput" placeholder="{{shareInfo.share_pin}}" readonly>
                      <div class="tooltipContainer" style="top: -7px; right: 150px">
                        <span *ngIf="copiedText == shareInfo.share_pin" class="tooltip copied">{{'Copied' | translate}}</span>
                        <span *ngIf="copiedText != shareInfo.share_pin"
                              class="tooltip notCopied">{{'CopyText' | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="isFolderSharing">
          <ng-container *ngIf="!afterSubmitScreen">
            {{'PatientsPortal.ShareExamModal.ShareAllText' |translate}}
            ({{endShareDate}})
            <br /><br />
            <app-alert #loginAlert></app-alert>
            <form autocomplete="off">
              <div class="form-group">
                <input *ngIf="isSuccess || !isError"
                       [(ngModel)]="password"
                       aria-describedby="passwordHelp"
                       class="form-control"
                       id="inputPassword"
                       name="pass"
                       placeholder="{{'PatientsPortal.ShareExamModal.ShareAllPwdPlaceholder' | translate}}"
                       type="password">
                <input *ngIf="isError"
                       [(ngModel)]="password"
                       aria-describedby="passwordHelp"
                       class="form-control is-error"
                       name="pass"
                       placeholder="{{'PatientsPortal.ShareExamModal.ShareAllPwdPlaceholder' | translate}}"
                       type="password">
              </div>
            </form>

            {{'PatientsPortal.ShareExamModal.ShareAllTextBottom' |translate}}

          </ng-container>
          <ng-container *ngIf="afterSubmitScreen">

            <form class="shareForm">
              <div>
                <label class="sr-only" for="urlInput"></label>
                <div class="copiedTooltip" [clipboard]="env.shareExamUrl" (copied)="copiedText = $event">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">{{'PatientsPortal.ShareExamModal.ShareThisUrl' |translate}}</div>
                    </div>

                    <input type="text" class="form-control" id="urlInput" placeholder="{{env.shareExamUrl}}" readonly>
                    <div class="tooltipContainer" style="top: -7px; right: 150px">
                      <span *ngIf="copiedText == env.shareExamUrl" class="tooltip copied">{{'Copied' | translate}}</span>
                      <span *ngIf="copiedText != env.shareExamUrl"
                            class="tooltip notCopied">{{'CopyText' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label class="sr-only" for="codeInput"></label>
                <div class="copiedTooltip" [clipboard]="shareInfo.share_pin" (copied)="copiedText = $event">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">{{'PatientsPortal.ShareExamModal.ShareThisCode' |translate}}</div>
                    </div>
                    <input type="text" class="form-control" id="codeInput" placeholder="{{shareInfo.share_pin}}" readonly>
                    <div class="tooltipContainer" style="top: -7px; right: 150px">
                      <span *ngIf="copiedText == shareInfo.share_pin" class="tooltip copied">{{'Copied' | translate}}</span>
                      <span *ngIf="copiedText != shareInfo.share_pin"
                            class="tooltip notCopied">{{'CopyText' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="afterSubmitScreen">
          {{'PatientsPortal.ShareExamModal.ShareValidUntil' |translate}}
          {{orderService.getDateString(shareInfo.share_end_time)}}
        </ng-container>

      </div>

      <div class="modal-footer">
        <ng-container *ngIf="!isFolderSharing">
          <ng-container *ngIf="!afterSubmitScreen">
            <button  class="btn btn-light ml-auto" (click)="share()">{{'PatientsPortal.ShareExamModal.ShareBtn' |translate}}</button>
          </ng-container>
          <ng-container *ngIf="afterSubmitScreen">
            <button class="btn btn-danger" (click)="showConfirmModal()">{{'PatientsPortal.ShareExamModal.StopAccessBtn' |translate}}</button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isFolderSharing">
          <ng-container *ngIf="!afterSubmitScreen">
            <button  class="btn btn-light ml-auto" (click)="share()">{{'PatientsPortal.ShareExamModal.ShareFolderBtn' |translate}}</button>
          </ng-container>
          <ng-container *ngIf="afterSubmitScreen">
            <button class="btn btn-danger" (click)="showConfirmModal()">{{'PatientsPortal.ShareExamModal.StopAccessBtn' |translate}}</button>
          </ng-container>
        </ng-container>

      </div>
    </div>
  </div>
</div>
<app-confirm-operation-modal #confirmOperationModal></app-confirm-operation-modal>
