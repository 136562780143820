<div class="password-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>
                    <app-horizontal-stepper stepNum=2></app-horizontal-stepper>
                </div>

                <div style="text-align: left;">
                    <h2 [innerHTML]="'ConfirmMailScreen.Title' | translate" class="blue"></h2>
                    <p style="padding: 0px;">{{'ConfirmMailScreen.Text' | translate}}</p>
                </div>
                
                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop"></div>
        </div>
    </div>
</div>