<div *ngIf="ordersCount == 0 && !isLoaderShow" class="row">
  <div class="col-2"></div>
  <div class="col-8 px-5">
      <div class="row empty-list">
          <div class="card w-100">
              <div class="card-body ml-2 mr-5">
                <h5 class="card-title mt-2" style="color: black;">
                  <ng-container *ngIf="page == 'archive' || page == 'dashboard'">{{'DashboardScreen.EmptyOrders' | translate}}</ng-container>
                  <ng-container *ngIf="page == 'patients'">{{'DashboardScreen.EmptyPatients' | translate}}</ng-container>
                  <ng-container *ngIf="page == 'exams'">{{'DashboardScreen.EmptyExams' | translate}}</ng-container>
                </h5>
                <p class="card-text">
                  <ng-container *ngIf="mode === 'DC'">
                    <ng-container *ngIf="userService.isCenterVerified()">
                      <ng-container *ngIf="page == 'archive' || page == 'dashboard'">
                        <ng-container *ngIf="!isSearched && !isFiltered">{{'DashboardScreen.EmptyOrdersText' | translate}}</ng-container>
                        <ng-container *ngIf="isFiltered || isSearched">{{'DashboardScreen.EmptyFilteredOrdersText' | translate}}</ng-container>
                      </ng-container>
                      <ng-container *ngIf="page == 'patients'">
                        <ng-container *ngIf="!isSearched && !isFiltered">{{'DashboardScreen.EmptyPatientsText' | translate}}</ng-container>
                        <ng-container *ngIf="isFiltered || isSearched">{{'DashboardScreen.EmptyFilteredPatientsText' | translate}}</ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!userService.isCenterVerified()">
                      {{'DashboardScreen.VerificationInProgress' | translate}}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="mode === 'MF'">
                    <ng-container *ngIf="page == 'archive' || page == 'dashboard'">
                      <ng-container *ngIf="!isSearched && !isFiltered">{{'DashboardScreen.EmptyOrdersMFText' | translate}}</ng-container>
                      <ng-container *ngIf="isFiltered || isSearched">{{'DashboardScreen.EmptyFilteredOrdersText' | translate}}</ng-container>
                    </ng-container>
                    <ng-container *ngIf="page == 'exams'">
                      <ng-container *ngIf="!isSearched && !isFiltered">{{'DashboardScreen.EmptyExamsText' | translate}}</ng-container>
                      <ng-container *ngIf="isFiltered || isSearched">{{'DashboardScreen.EmptyFilteredExamsText' | translate}}</ng-container>
                    </ng-container>
                  </ng-container>
                </p>
                <a *ngIf="mode === 'DC'" routerLink="/siteUnderConstruction" class="card-link">{{'DashboardScreen.WhatToDo' | translate}}</a>
              </div>
          </div>
      </div>
      <div *ngIf="mode === 'DC'" class="row mt-4 empty-list-actions">
          <div class="col pl-0 pr-2" *ngIf="userService.isSendingMessageEnable()">
              <a (click)="sendMessageFunc()" style="cursor: pointer;">
                  <div class="card pt-4">
                      <svg-icon alt=""
                              src="../../../../assets/images/icons/chat-message.svg"
                              id="no-profile-pic-icon" [applyCss]="true"></svg-icon>
                      <div class="card-body">
                      <p class="card-text">{{'DashboardScreen.SendMessageTo' | translate}}<br>
                        {{userService.getContactName()}} {{'DashboardScreen.Coordynator' | translate}}</p>
                      </div>
                  </div>
                  </a>
          </div>
          <div class="col" [ngClass]="{'pl-0': !userService.isSendingMessageEnable()}">
              <a routerLink="/profiles">
                  <div class="card pt-4">
                      <svg-icon alt=""
                              src="../../../../assets/images/icons/menu_item_profile.svg"
                              id="no-profile-pic-icon" [applyCss]="true"></svg-icon>
                      <div class="card-body">
                      <p class="card-text" [innerHTML]="'DashboardScreen.SeeProfiles' | translate"></p>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col pr-0 pl-2 coffee">
              <a style="cursor: pointer;" (click)="lockScreen(true)">
                  <div class="card pt-4">
                      <svg-icon alt=""
                              src="../../../../assets/images/icons/coffee.svg"
                              id="no-profile-pic-icon" [applyCss]="true" style="height: 90px;"></svg-icon>
                      <div class="card-body">
                      <p class="card-text" [innerHTML]="'DashboardScreen.LockScreen' | translate"></p>
                      </div>
                  </div>
              </a>
          </div>
      </div>
  </div>
</div>

<app-send-message-to-user-modal #sendMessage [actionToast]="dataSavedToast"></app-send-message-to-user-modal>
<app-universal-toast #dataSavedToast></app-universal-toast>
