import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IFilter } from '@/interfaces';
import { FiltersService } from '@/services/filters.service';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { Subscription } from 'rxjs';
import { AddFilterModalComponent } from '@/utils/add-filter-modal/add-filter-modal.component';
import { DashboardFiltersComponent } from '@/utils/manage-my-filters/dashboard-filters/dashboard-filters.component';
import { PatientFiltersComponent } from '@/utils/manage-my-filters/patient-filters/patient-filters.component';
import { ArchiveFiltersComponent } from '@/utils/manage-my-filters/archive-filters/archive-filters.component';

@Component({
    selector: 'app-manage-my-filters',
    templateUrl: './manage-my-filters.component.html',
    styleUrls: ['./manage-my-filters.component.scss']
})
export class ManageMyFiltersComponent implements OnInit, OnDestroy {
    @ViewChild('addFilterModal') addFilterModal: AddFilterModalComponent;
    @ViewChild('filterSavedToast') filterSavedToast: UniversalToastComponent;
    @ViewChild('dashboardFilter') dashboardFilter: DashboardFiltersComponent;
    @ViewChild('patientFilter') patientFilter: PatientFiltersComponent;
    @ViewChild('archiveFilter') archiveFilter: ArchiveFiltersComponent;

    types: string[] = ['dashboard', 'archive', 'patients'];
    chosenFilter: IFilter = {
        id: 0,
        value: null,
        name: '',
        type: this.types[0],
        isFavourite: false
    };
    isFavourite: boolean = false;
    currentName = '';
    currentTypeOption = '';
    saveFilterSubscription: Subscription;
    filterService: FiltersService;
    emptyList: boolean = false;
    typeId = 0;

    constructor(filterService: FiltersService, private route: ActivatedRoute) {
        this.filterService = filterService;
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
           this.loadDefaultData(params.get('type'));
        });
    }

    ngOnDestroy() {
        if (this.saveFilterSubscription != undefined) {
            this.saveFilterSubscription.unsubscribe();
        }
    }

    loadDefaultData(type) {
        this.filterService.getFilters(type).then(
            (res) => {
                if (res) {
                    if (this.filterService.getMyFilters(type).length > 0) {
                        this.chosenFilter = this.filterService.getMyFilters(type)[0];
                    } else {
                        this.chosenFilter = this.getDefaultFilter(type);
                        this.emptyList = true;
                    }
                    this.currentName = this.chosenFilter.name;
                    this.setCurrentFilter();
                    this.setCurrentPageOption(this.chosenFilter.type);
                    this.setFilterData();
                }
            }
        );
    }

    setCurrentPageOption(type) {
        if (type == 'dashboard') {
            this.currentTypeOption = this.filterService.typeOptions[0];
        } else if (type == 'archive') {
            this.currentTypeOption = this.filterService.typeOptions[1];
        } else if (type == 'patients') {
            this.currentTypeOption = this.filterService.typeOptions[2];
        }
    }

    setFilterData() {
        this.dashboardFilter.setFilterData();
        this.patientFilter.setFilterData();
        this.archiveFilter.setFilterData();
    }

    setCurrentFilter() {
        if (this.chosenFilter.type == 'dashboard') {
            this.dashboardFilter.setCurrentFilter(this.chosenFilter);
        } else if (this.chosenFilter.type == 'patients') {
            this.patientFilter.setCurrentFilter(this.chosenFilter);
        } else if (this.chosenFilter.type == 'archive') {
            this.archiveFilter.setCurrentFilter(this.chosenFilter);
        }
    }

    deleteFilter(id: number) {
        this.filterService.clearSavedSearchAndFilters(this.chosenFilter.type);
        this.filterService.deleteFilter(id).then((res) => {
            if (res) {
                this.filterService.getFilters(this.chosenFilter.type).then(
                    (res) => {
                        if (res) {
                            if (this.filterService.getMyFilters(this.chosenFilter.type).length > 0) {
                                this.changeChosenFilter(this.filterService.getMyFilters(this.chosenFilter.type)[0]);
                            } else {
                                this.changeChosenFilter(this.getDefaultFilter(this.chosenFilter.type));
                                this.emptyList = true;
                            }
                        }
                    }
                );
            }
        });
    }

    getDefaultFilter(type: string): IFilter {
        return {
            id: 0,
            value: (type == 'dashboard')
            ? FiltersComponent.createInitialIFiltrDashboard()
            : (type == 'archive')
            ? FiltersComponent.createInitialIFiltrArchive()
            : FiltersComponent.createInitialFiltrPatients(),
            name: '',
            type: type,
            isFavourite: false
        };
    }

    saveNewFilter() {
        this.addFilterModal.show(this.currentTypeOption);
        if (this.saveFilterSubscription) this.saveFilterSubscription.unsubscribe();
        this.saveFilterSubscription = this.addFilterModal.addFilterEmitter.subscribe(
            (res) => {
                if (res) {
                    if (this.filterService.getMyFilters(this.chosenFilter.type).filter(x => x.isFavourite).length < 2 || !this.addFilterModal.isFavourite) {
                        let filtr = (this.chosenFilter.type == 'dashboard')
                            ? FiltersComponent.createInitialIFiltrDashboard()
                            : (this.chosenFilter.type == 'archive')
                            ? FiltersComponent.createInitialIFiltrArchive()
                            : FiltersComponent.createInitialFiltrPatients();
                        this.filterService.saveFilter(filtr, this.chosenFilter.type,
                            this.addFilterModal.filterName, this.addFilterModal.isFavourite).then(
                                (res) => {
                                    if (res) {
                                        this.currentName = this.addFilterModal.filterName;
                                        this.filterSavedToast.success('FilterSaved');
                                        this.filterService.getFilters(this.chosenFilter.type).then(
                                            (res) => {
                                                if (res) {
                                                    if (this.filterService.getMyFilters(this.chosenFilter.type).length > 0) {
                                                        this.emptyList = false;
                                                        let savedFilter = this.filterService.getMyFilters(this.chosenFilter.type).find(x => x.name == this.currentName);
                                                        this.changeChosenFilter(savedFilter);
                                                    } else {
                                                        this.currentName = '';
                                                        this.changeChosenFilter(this.getDefaultFilter(this.chosenFilter.type));
                                                        this.emptyList = true;
                                                    }
                                                }
                                            }
                                        );
                                        this.addFilterModal.hide();
                                    } else {
                                        this.filterSavedToast.error('FilterNotSaved');
                                    }
                                    this.filterSavedToast.show();
                                    setTimeout(() => this.filterSavedToast.hide(), 1500);
                                }
                            );
                    } else {
                        this.addFilterModal.hide();
                        this.filterSavedToast.error('TooMuchFavourites');
                        this.filterSavedToast.show();
                        setTimeout(() => this.filterSavedToast.hide(), 2000);
                    }
                }
            }
        );
    }

    changeChosenFilter(filter: IFilter) {
        this.chosenFilter = filter;
        this.isFavourite = this.chosenFilter.isFavourite;
        this.currentName = this.chosenFilter.name;
        this.setCurrentPageOption(this.chosenFilter.type);
        this.setCurrentFilter();
        this.setFilter(this.chosenFilter.type);
    }

    saveChangesInFilter() {
        this.filterService.clearSavedSearchAndFilters(this.chosenFilter.type);
        let promise: Promise<any>;
        let countOfFavourites = this.filterService.getMyFilters(this.chosenFilter.type).filter(x => x.isFavourite).length;
        if (countOfFavourites < 3 || !this.isFavourite) {
            this.chosenFilter.isFavourite = this.isFavourite;
            if (this.chosenFilter.type == 'dashboard') {
                promise = this.dashboardFilter.saveChanges(this.currentName);
            } else if (this.chosenFilter.type == 'patients') {
                promise = this.patientFilter.saveChanges(this.currentName);
            } else if (this.chosenFilter.type == 'archive') {
                promise = this.archiveFilter.saveChanges(this.currentName);
            }

            promise.then(
                (res) => {
                    if (res) {
                        this.filterSavedToast.success('FilterSaved');
                        if (this.currentName != this.chosenFilter.name) {
                            this.deleteFilter(this.chosenFilter.id);
                        }
                        this.filterService.getFilters(this.chosenFilter.type).then(
                            (res) => {
                                if (res) {
                                    this.setFilter(this.chosenFilter.type);
                                }
                            }
                        );
                    } else {
                        this.filterSavedToast.error('FilterNotSaved');
                    }
                    this.filterSavedToast.show();
                    setTimeout(() => this.filterSavedToast.hide(), 1500);
                }
            );
        } else {
            this.filterSavedToast.error('TooMuchFavourites');
            this.filterSavedToast.show();
            setTimeout(() => this.filterSavedToast.hide(), 2000);
        }
    }

    setFilter(type: string) {
        if (this.chosenFilter.value != null) {
            if (type == 'dashboard') {
                this.dashboardFilter.setFilter();
            } else if (type == 'patients') {
                this.patientFilter.setFilter();
            } else if (type == 'archive') {
                this.archiveFilter.setFilter();
            }
        }
    }

    getCurrentFilters(): IFilter[] {
        return this.filterService.getMyFilters(this.chosenFilter.type);
    }
}
