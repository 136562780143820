<!-- start: wyslanieZaproszeniaDoWspolpracyModal -->
<div class="modal fade" id="wyslanieZaproszeniaDoWspolpracyModal" tabindex="-1" role="dialog"
     aria-labelledby="wyslanieZaproszeniaDoWspolpracyModalLabel" aria-hidden="true" data-dismiss="modal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="wyslanieZaproszeniaDoWspolpracyModalLabel">{{'SettingsScreens.UsersSettings.SendInvitationQuestion' | translate}}</h2>
        <button (click)="hide()" type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p>{{'SettingsScreens.UsersSettings.InvitationRecipient' | translate}} <a><u>{{user_name_to_invite}}</u></a></p>
        <form [formGroup]="inviteToCoopForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="roles">{{'SettingsScreens.UsersSettings.InvitationRole' | translate}} <a><u>{{center_name}}</u></a></label>
                <ng-select id="roles"
                           [multiple]="true"
                           [items]="available_roles"
                           bindValue="id"
                           bindLabel="value.role_description"
                           [selectableGroup]="true"
                           [closeOnSelect]="false"
                           formControlName="roles"
                           placeholder="{{'InviteToCoopModal.PickRole' | translate}}"
                           notFoundText="{{'InviteToCoopModal.NoResult' | translate}}"

                >
                  <ng-template ng-header-tmp let-items="items">
                    <label style="margin-bottom: 0; font-weight: normal">
                      <input type="checkbox" (change)="toggleCheckAll($event)"
                             [checked]="inviteToCoopForm.controls.roles.value && inviteToCoopForm.controls.roles.value.length == available_roles.length"
                      />
                      {{'OrderDescriptionScreens.Templates.All' | translate}}</label>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:2">
                      <span class="ng-value-label">{{item.value.role_description}}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 2">
                      <span class="ng-value-label">{{items.length - 2}} {{'OrderDescriptionScreens.Templates.More' | translate}}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="role-{{item.id}}" type="checkbox" [checked]="item$.selected" /> {{item.value.role_description}}
                  </ng-template>

                </ng-select>
              </div>
            </div>
          </div>
          <textarea name="invitation_message_textarea" id="invitation_message_textarea"
                    class="form-control mt-2 mb-4"
                    formControlName="message"
                    placeholder="{{'SettingsScreens.UsersSettings.MessageContent' | translate}}"
                    rows="5">
          </textarea>
        </form>


      </div>
      <div class="modal-footer">
        <button (click)="onInvitationSend()"  type="button"
                class="btn btn-lg btn-success" [disabled]="!inviteToCoopForm.valid" >{{'SettingsScreens.UsersSettings.InviteButton' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<!-- end: wyslanieZaproszeniaDoWspolpracyModal -->
