import * as tslib_1 from "tslib";
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { TranslateService } from '@ngx-translate/core';
import { OdataService, UsersService, SessionService, ValidationService } from '@/services';
import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var PwzVerificationModalComponent = /** @class */ (function () {
    function PwzVerificationModalComponent(odataService, translate, userService, sessionService, formBuilder) {
        this.odataService = odataService;
        this.translate = translate;
        this.userService = userService;
        this.sessionService = sessionService;
        this.formBuilder = formBuilder;
        this.scanFileName = '';
        this.base64 = '';
        this.pwzSending = new EventEmitter();
        this.agreementsList = [];
        this.registerPWZForm = this.formBuilder.group({
            agreements: this.formBuilder.array([]),
            pwz: ['', [Validators.required, ValidationService.pwzValidator, ValidationService.numberValidator]],
            title_id: [null, Validators.required],
            main_specialization_id: [null, Validators.required],
            specialization_list: []
        });
        this.degreeOptions = [];
        this.mainSpecializationOptions = [];
        this.otherSpecializationsOptions = [];
        this.otherSpecOptionIds = [];
    }
    PwzVerificationModalComponent.prototype.ngOnInit = function () {
        this.getUserAgreementForPhycisianVerification();
        this.getPhysicianDegreeList();
        this.getMainPhysicianSpecializationList();
        this.getPhysicianSpecializationList();
    };
    Object.defineProperty(PwzVerificationModalComponent.prototype, "agreements", {
        get: function () {
            return this.registerPWZForm.get('agreements');
        },
        enumerable: true,
        configurable: true
    });
    PwzVerificationModalComponent.prototype.getUserAgreementForPhycisianVerification = function () {
        var _this = this;
        this.odataService.postPrivate('get_user_agreement_for_phycisian_verification', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.agreementsList = JSON.parse(result.Value);
                _this.agreementsList.forEach(function (agreement) {
                    if (agreement.is_required) {
                        agreement.agreement_contents = '*' + agreement.agreement_contents;
                    }
                    _this.addAgreement(agreement);
                });
            }
        });
    };
    PwzVerificationModalComponent.prototype.getPhysicianSpecializationList = function () {
        var _this = this;
        this.odataService.postPrivate('get_physician_specialization_list', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                var options = JSON.parse(result.Value);
                options.forEach(function (el) {
                    _this.otherSpecOptionIds.push(el.id_specialization);
                    _this.otherSpecializationsOptions.push({
                        value: el.specialization_name,
                        id: el.id_specialization
                    });
                });
            }
        }).then(function () { return _this.otherSpecializationsOptions = _this.otherSpecializationsOptions.slice(); });
    };
    PwzVerificationModalComponent.prototype.getMainPhysicianSpecializationList = function () {
        var _this = this;
        this.odataService.postPrivate('get_main_physician_specialization_list', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                var list = JSON.parse(result.Value);
                list.forEach(function (o) { return _this.mainSpecializationOptions.push({
                    id: o.id_main_specialization,
                    value: o.specialization_name
                }); });
            }
        }).then(function () { return _this.mainSpecializationOptions = _this.mainSpecializationOptions.slice(); });
    };
    PwzVerificationModalComponent.prototype.getPhysicianDegreeList = function () {
        var _this = this;
        this.odataService.postPrivate('get_physician_degree_list', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                var list = JSON.parse(result.Value);
                list.forEach(function (o) { return _this.degreeOptions.push({
                    id: o.id_degree,
                    value: o.degree_desc
                }); });
            }
        }).then(function () { return _this.degreeOptions = _this.degreeOptions.slice(); });
    };
    PwzVerificationModalComponent.prototype.addAgreement = function (agreement) {
        if (agreement.is_required) {
            this.agreements.push(this.formBuilder.control(false, Validators.requiredTrue));
        }
        else {
            this.agreements.push(this.formBuilder.control(false));
        }
    };
    PwzVerificationModalComponent.prototype.sendPWZ = function () {
        var _this = this;
        var params = this.registerPWZForm.value;
        delete params.agreements; // agreements not a part of the pwz verif
        params.specialization_list = params.specialization_list ? params.specialization_list : [];
        this.odataService.postPrivate('user_send_pwz_to_verify_ext', this.odataService.paramsTokenAndLang(tslib_1.__assign({ base64: this.base64 }, params)), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.value == 'OK') {
                _this.scanFileName = '';
                _this.hide();
                _this.pwzSending.emit('OK');
            }
            else if (result.value == 'INCORRECT_DATA') {
                _this.errorToast.error('OperationFailed');
                _this.errorToast.show();
                setTimeout(function () {
                    _this.errorToast.hide();
                }, 3000);
                _this.translate.get('AccountProperties.VerifyPWZError')
                    .subscribe(function (res) {
                    $('#enterPWZ').attr('placeholder', res);
                });
            }
            if (result.value != 'OK') {
                _this.pwzSending.emit('FALSE');
            }
        });
    };
    PwzVerificationModalComponent.prototype.show = function () {
        $('#verificationModal').modal('show');
    };
    PwzVerificationModalComponent.prototype.hide = function () {
        $('#verificationModal').modal('hide');
    };
    PwzVerificationModalComponent.prototype.onImageScanSelected = function (event) {
        var files = event.target.files;
        var file = files[0];
        if (file.name.length > 30) {
            this.scanFileName = file.name.substring(0, 30) + '...';
        }
        else
            this.scanFileName = file.name;
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this.handleScanFile.bind(this);
            reader.readAsBinaryString(file);
        }
    };
    PwzVerificationModalComponent.prototype.handleScanFile = function (event) {
        var binaryString = event.target.result;
        this.base64 = btoa(binaryString);
        this.base64 = this.base64.replace(/\+/gi, '-');
        this.base64 = this.base64.replace(/\//gi, '_');
    };
    PwzVerificationModalComponent.prototype.toggleCheckAll = function ($event) {
        if ($event.target.checked)
            this.registerPWZForm.patchValue({ specialization_list: this.otherSpecOptionIds });
        else
            this.registerPWZForm.patchValue({ specialization_list: [] });
    };
    return PwzVerificationModalComponent;
}());
export { PwzVerificationModalComponent };
