/*
 * (c) ImpiCode Sp. z o.o.
 * Stamp: PLE 2018-10-24
*/
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {

    constructor(private translate: TranslateService, private userService: UsersService) {
        translate.setDefaultLang(this.userService.iLanguage);
    }

}
