import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  DisplayService,
  Functions,
  NavigationService,
  OdataService,
  ProfileService,
  SessionService,
  UsersService,
  ValidationService
} from '@/services';
import { AlertComponent } from '@/utils';
import { PortfolioService } from '@/services/portfolio.service';
import { PageService } from '@/services/page.service';
import {
  IAnatomyParts,
  IChangeDoctorsPrivacy,
  IDoctorWorkingHourList,
  IModalities,
  IPhysicianProfile,
  IPortfolio,
  phys_profile,
  IField
} from '@/interfaces';
import { TimePickerComponent } from '@/utils/time-picker/time-picker.component';
import { EnvService } from '@/services/env.service';
import { ContextsService } from '@/services/contexts.service';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { DomSanitizer } from '@angular/platform-browser';
import { InviteToCooperationModalComponent } from '@/utils/invite-to-cooperation-modal/invite-to-cooperation-modal.component';
import { SendMessageToUserModalComponent } from '@/utils/send-message-to-user-modal/send-message-to-user-modal.component';

interface IPermission {
    id_context: number;
    id_permission: number;
    context_required: number[];
    permissions_name: string;
}

@Component({
    selector: 'app-doctors-profile',
    templateUrl: './doctors-profile.component.html',
    styleUrls: ['./doctors-profile.component.css']
})
export class DoctorsProfileComponent implements OnInit, OnDestroy {
    @ViewChild('errorAlertHours') errorAlertHours: AlertComponent;
    @ViewChild('errorAlertContact') errorAlertContact: AlertComponent;

    @ViewChildren('startHour') startHours: QueryList<TimePickerComponent>;
    @ViewChildren('stopHour') stopHours: QueryList<TimePickerComponent>;

    @ViewChild('dataSavedToast') dataSavedToast: UniversalToastComponent;

    @ViewChild('inviteToCooperationModal') inviteToCooperationModal: InviteToCooperationModalComponent;
    @ViewChild('sendMessage') sendMessage: SendMessageToUserModalComponent;

    coId: number;
    permissions: IPermission[];
    doctor_id: number;
    isOwner: boolean;
    editPermission: boolean;
    invitePermission: boolean;
    hiddenTimetable: boolean = true;

    editing: {
        workingHours: boolean,
        holidays: boolean,
        contact: boolean,
        aboutMe: boolean,
        portfolio: boolean,
        specializations: boolean
    } = {
            workingHours: false,
            holidays: false,
            contact: false,
            aboutMe: false,
            portfolio: false,
            specializations: false
        };

    workingHoursForm = this.formBuilder.group({
        day1Start: ['', [ValidationService.workingHoursValidator]],
        day1Stop: ['', [ValidationService.workingHoursValidator]],
        day2Start: ['', [ValidationService.workingHoursValidator]],
        day2Stop: ['', [ValidationService.workingHoursValidator]],
        day3Start: ['', [ValidationService.workingHoursValidator]],
        day3Stop: ['', [ValidationService.workingHoursValidator]],
        day4Start: ['', [ValidationService.workingHoursValidator]],
        day4Stop: ['', [ValidationService.workingHoursValidator]],
        day5Start: ['', [ValidationService.workingHoursValidator]],
        day5Stop: ['', [ValidationService.workingHoursValidator]],
        day6Start: ['', [ValidationService.workingHoursValidator]],
        day6Stop: ['', [ValidationService.workingHoursValidator]],
        day7Start: ['', [ValidationService.workingHoursValidator]],
        day7Stop: ['', [ValidationService.workingHoursValidator]]
    });

    addressForm = this.formBuilder.group({
        email: ['', [ValidationService.mailValidator, Validators.required]],
        phone_number: ['', [ValidationService.validatePhone, Validators.required]],
        address: ['', [Validators.required]],
        address_city: ['', [ValidationService.nameValidator, Validators.required]],
        address_zip_code: ['', [ValidationService.zipCodeValidator, Validators.required]]
    });

    p = true;
    modalities: IModalities[] = [];
    anatomyParts: IAnatomyParts[] = [];
    daysOfWeek: string[] = ['', '', '', '', '', '', ''];
    physician_profile: IPhysicianProfile = phys_profile;
    avg_reaction_hours: number = 0;
    workingHourList: IDoctorWorkingHourList = {
        token: '',
        language: '',
        working_hours: ''
    };
    changePrivacy: IChangeDoctorsPrivacy = {
        token: '',
        language: '',
        section_name: '',
        value: false
    };
    spec = new Map<number, boolean>();
    specCount: number;
    portfolio: IPortfolio[] = [];
    finalPortfolio: IPortfolio[][] = [];
    portfolioService: PortfolioService;
    imageUrl: string = '';
    stickySidebarFunction: any;

    fields: IField[] = [
        { name: 'informations', topHeight: 0 }
    ];
    activateField = {
        'informations': true,
        'hours': false,
        'contact': false,
        'about': false,
        'specializations': false,
        'portfolio': false,
        'places': false
    };
    activeFieldsFunction: any = null;
    contextService: ContextsService;

    timeout: any = null;

    base64textString = '';
    imageNoCacheSuffix: string = '';
    publicProfile: boolean = true;
    isEndCoopReady: boolean = false; // todo end cooperation funcionality

    constructor(private route: ActivatedRoute, private odataService: OdataService, public env: EnvService,
        private userService: UsersService, private translate: TranslateService,
        private formBuilder: FormBuilder, private session: SessionService, private router: Router,
        private profileService: ProfileService, portfolioService: PortfolioService,
        private navigationService: NavigationService, private pageService: PageService,
                contextService: ContextsService, private domSanitizer: DomSanitizer) {
        this.contextService = contextService;
        this.portfolioService = portfolioService;
        this.changeImageNoCacheSuffix();
    }

    ngOnInit() {

      if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
        let body = document.getElementsByTagName('body')[0];
        body.classList.add('pp-portal');
      }

        this.profileService.translateDays(this.daysOfWeek);
        if (this.route.snapshot.routeConfig.path == 'myProfile') {
            if (this.navigationService.hasContext('PHYSICIAN')) {
                this.publicProfile = false;
                this.userService.userDataPromise.then(() => { this.doctor_id = this.userService.userId; });
                this.editPermission = true;
                Promise.all([new Promise((resolve, reject) => {
                    this.getOwnPhysicianProfile(resolve, reject, true);
                })]).then(() => {
                    this.portfolioService.setDoctorsPortfolio(this.physician_profile.portfolio.portfolio);
                    this.timeout = setTimeout(() => {
                        // this.setFields();
                        this.timeout = null;
                        //  this.stickySidebarFunction = DisplayService.createStickySidebar('sticky_sidebar', 1000, 282, 120, '100%');
                    }, 100);
                });
            } else this.navigationService.navigateToDefault();
        } else {
            this.publicProfile = true;
            this.route.paramMap.subscribe(params => {
                this.doctor_id = parseInt(params.get('doctor_id'));
                this.editPermission = false;
                this.invitePermission = this.userService.checkDcPermission('DC_SENDING_INVITATIONS');
                Promise.all([new Promise((resolve, reject) => {
                    this.get_public_physician_profile(resolve, reject, true, this.invitePermission);
                })]).then(() => {
                    this.portfolioService.setDoctorsPortfolio(this.physician_profile.portfolio.portfolio);

                    this.timeout = setTimeout(() => {
                        // this.setFields();
                        this.timeout = null;
                        // let height: number = 850 - (7 - this.fields.length) * 75;
                        // document.getElementById('sticky_sidebar').style.height = height + 'px';
                        // DisplayService.createStickySidebar('sticky_sidebar', height, 282, 120, '100%');
                    }, 100);
                });
            });
        }

        this.userService.userDataPromise.then(() => {
            this.isOwner = this.checkIfOwner();
            if (this.isOwner) {
                this.pageService.setTranslatedPageTitle('Profile.DoctorsProfile.YourPublicDoctorsProfile', 0);
            } else {
                this.pageService.setTranslatedPageTitle('Profile.DoctorsProfile.DoctorsProfile', 0);
            }
        });

        if (this.editPermission) {
            this.userService.userDataPromise.then(() => {
                if (this.userService.profileImageUuid != null) {
                    this.imageUrl = this.userService.profileImageUrl;
                }
            });
        }
    }

    checkIfOwner(): boolean {
        return this.doctor_id == this.userService.userId;
    }

    ngOnDestroy() {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove('pp-portal');
        // window.removeEventListener('scroll', this.stickySidebarFunction);
        if (this.activeFieldsFunction != null) window.removeEventListener('scroll', this.activeFieldsFunction);
        if (this.timeout != null) clearTimeout(this.timeout);
        this.pageService.resetPageTitle();
        if (this.router.url != '/profiles') sessionStorage.removeItem('isOnlyCoworkers');
    }

    setFields(): void {
        this.fields[0].topHeight = 0;
        let rect;

        if (!this.publicProfile || this.physician_profile.working_hour_info.working_hours_enable) {
            rect = document.getElementById('section-godziny-pracy').getBoundingClientRect();
            this.fields.push({ name: 'hours', topHeight: rect.top + document.documentElement.scrollTop - 1 });
        }

        if (!this.publicProfile || this.physician_profile.contact_info.contact_info_enable) {
            rect = document.getElementById('section-kontakt').getBoundingClientRect();
            this.fields.push({ name: 'contact', topHeight: rect.top + document.documentElement.scrollTop - 1 });
        }

        if (!this.publicProfile || this.physician_profile.about_me.about_me_enable) {
            rect = document.getElementById('section-o-mnie').getBoundingClientRect();
            this.fields.push({ name: 'about', topHeight: rect.top + document.documentElement.scrollTop - 1 });
        }

        if (!this.publicProfile || this.physician_profile.specialization.specialization_enable) {
            rect = document.getElementById('section-specjalizacje').getBoundingClientRect();
            this.fields.push({ name: 'specializations', topHeight: rect.top + document.documentElement.scrollTop - 1 });
        }

        if (!this.publicProfile || this.physician_profile.portfolio.portfolio_enable) {
            rect = document.getElementById('section-portfolio').getBoundingClientRect();
            this.fields.push({ name: 'portfolio', topHeight: rect.top + document.documentElement.scrollTop - 1 });
        }

        if (!this.publicProfile || this.physician_profile.working_places.working_places_enable) {
            rect = document.getElementById('section-pracuje_w').getBoundingClientRect();
            this.fields.push({ name: 'places', topHeight: rect.top + document.documentElement.scrollTop - 1 });
        }

        this.activeFieldsFunction = DisplayService.activateSidebarFieldsOnScroll(this.fields, this.activateField);
    }

    setValuesInForm(): void {
        this.addressForm.controls.email.setValue(this.physician_profile.contact_info.contact_info.email);
        this.addressForm.controls.phone_number.setValue(this.physician_profile.contact_info.contact_info.phone_number);
        this.addressForm.controls.address.setValue(this.physician_profile.contact_info.contact_info.address);
        this.addressForm.controls.address_city.setValue(this.physician_profile.contact_info.contact_info.address_city);
        this.addressForm.controls.address_zip_code.setValue(this.physician_profile.contact_info.contact_info.address_zip_code);
    }

    get_public_physician_profile(resolve, reject, isPromise: boolean, isInvitePermission?: boolean) {
        let id_center = null;
        if (isInvitePermission) id_center = this.userService.getCenterId();
        this.odataService.postPrivate('get_public_physician_profile',
            this.odataService.paramsTokenAndLang({
                id_user: this.doctor_id,
                id_describing_center: id_center,
            }),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson).Value;
                if (result != '') this.physician_profile = JSON.parse(result);
                if (!this.physician_profile.working_hour_info.working_hours_enable)
                    this.physician_profile.working_hour_info.working_hours.working_hour_list = phys_profile.working_hour_info.working_hours.working_hour_list;
                if (this.physician_profile.statistics == undefined) this.physician_profile.statistics = phys_profile.statistics;
                if (this.physician_profile.main.image_profile != null) {
                    this.imageUrl = this.env.profilePicURL + this.physician_profile.main.image_profile + '?' + this.imageNoCacheSuffix;
                }
                if (this.physician_profile.working_places.working_places != null) {
                    this.physician_profile.working_places.working_places.forEach((place) => {
                        place.logo = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + place.logo);
                    });
                }
                this.avg_reaction_hours = +this.physician_profile.statistics.statistics_info.avarage_reaction_time / 60;
                this.setButtons();
                this.setValuesInForm();
                if (this.physician_profile.specialization.specialization != null) {
                    this.specCount = this.physician_profile.specialization.specialization.length;
                    for (let i = 0; i < this.physician_profile.specialization.specialization.length; ++i) {
                        this.spec.set(this.physician_profile.specialization.specialization[i].id_specialization, false);
                    }
                } else {
                    this.specCount = 0;
                }
                if (isPromise) resolve('OK');
            }
        );
    }

    getOwnPhysicianProfile(resolve, reject, isPromise: boolean) {
        this.odataService.postPrivate('get_own_physician_profile',
            this.odataService.paramsTokenAndLang({}),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson).Value;
                this.physician_profile = JSON.parse(result);
                if (this.physician_profile.working_places.working_places != null) {
                    this.physician_profile.working_places.working_places.forEach((place) => {
                        place.logo = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + place.logo);
                    });
                }
                this.physician_profile.working_hour_info.working_hours.working_hour_list.sort((a, b) => (a.day_of_week > b.day_of_week) ? 1 : -1);
                this.avg_reaction_hours = +this.physician_profile.statistics.statistics_info.avarage_reaction_time / 60;
                this.setButtons();
                this.setValuesInForm();
                if (this.physician_profile.specialization.specialization != null) {
                    this.specCount = this.physician_profile.specialization.specialization.length;
                    for (let i = 0; i < this.physician_profile.specialization.specialization.length; ++i) {
                        this.spec.set(this.physician_profile.specialization.specialization[i].id_specialization, false);
                    }
                } else {
                    this.specCount = 0;
                }
                if (isPromise) resolve('OK');
            }
        );
    }

    changePhysicianProfilePectionContactInfo() {
        if (this.addressForm.valid) {
            this.errorAlertContact.clear();
            let params = this.odataService.paramsTokenAndLang({
                email: this.addressForm.controls.email.value,
                phone_number: this.addressForm.controls.phone_number.value,
                address: this.addressForm.controls.address.value,
                address_city: this.addressForm.controls.address_city.value,
                address_zip_code: this.addressForm.controls.address_zip_code.value
            });
            this.odataService.postPrivate('change_physician_profile_section_contact_info',
                params,
                res => {
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    if (result.value == 'OK') {
                        let resolve, reject;
                        this.editing.contact = false;
                        this.getOwnPhysicianProfile(resolve, reject, false);
                        this.dataSavedToast.success('Saved');
                    } else if (result.value == 'OTHER_REASON') {
                        this.dataSavedToast.error('NotSaved');
                    }
                    this.dataSavedToast.show();
                    setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
                }
            );
        } else {
            this.showAlertContact();
        }
    }

    showAlertContact(): void {
        let displayed: boolean = false;

        if (this.addressForm.controls.email.status == 'INVALID') {
            this.errorAlertContact.displayError('email');
            document.getElementById('adres_email').setAttribute('class', 'form-control is-error');
            displayed = true;
        } else {
            document.getElementById('adres_email').setAttribute('class', 'form-control');
        }

        if (this.addressForm.controls.phone_number.status == 'INVALID') {
            this.errorAlertContact.displayError('phone_number');
            document.getElementById('numer_telefonu').setAttribute('class', 'form-control is-error');
            displayed = true;
        } else {
            document.getElementById('numer_telefonu').setAttribute('class', 'form-control');
        }

        if (this.addressForm.controls.address.status == 'INVALID') {
            this.errorAlertContact.displayError('address');
            document.getElementById('adres').setAttribute('class', 'form-control is-error');
            displayed = true;
        } else {
            document.getElementById('adres').setAttribute('class', 'form-control');
        }

        if (this.addressForm.controls.address_city.status == 'INVALID') {
            if (!displayed) {
                this.errorAlertContact.displayError('city');
                displayed = true;
            }
            document.getElementById('miasto').setAttribute('class', 'form-control is-error');
        } else {
            document.getElementById('miasto').setAttribute('class', 'form-control');
        }

        if (this.addressForm.controls.address_zip_code.status == 'INVALID') {
            if (!displayed) this.errorAlertContact.displayError('zip_code');
            document.getElementById('kod_pocztowy').setAttribute('class', 'form-control is-error');
        } else {
            document.getElementById('kod_pocztowy').setAttribute('class', 'form-control');
        }
    }

    addSpecialization(): void {
        let deleteButton: string;
        this.translate.get('Profile.DeleteSpecButton').subscribe((res: string) => {
            deleteButton = res;
        });
        let newElement = document.createElement('div');
        newElement.setAttribute('class', 'row');
        newElement.innerHTML =
            '<div class="col-md-11">\
                <div class="form-group">\
                    <select name="select_spec" id="select' + this.specCount + '" class="form-control">\
                    <option value="1">Choroby wewnętrzne</option>\
                    <option value="2">Medycyna nuklearna</option>\
                </select>\
            </div>\
        </div>\
        <div class="col-md-1 mt-2">\
            <button class="btn btn-danger" type="button" onclick="let select = document.getElementById(\'select' + this.specCount + '\'); select.parentElement.parentElement.parentElement.parentElement.removeChild(select.parentElement.parentElement.parentElement);">' + deleteButton + '</button>\
        </div>';
        let editContainer = document.getElementById('specEditContainer');
        let addSpecButton = document.getElementById('addSpecButton');
        editContainer.removeChild(addSpecButton);
        editContainer.appendChild(newElement);
        editContainer.appendChild(addSpecButton);
        this.specCount++;
    }

    portfolioJSON(): string {
        let json: string = '[';

        for (let i = 0; i < this.portfolioService.finalDoctorsPortfolio.length; i++) {
            for (let j = 0; j < this.portfolioService.finalDoctorsPortfolio[i].length; j++) {
                json += '{"id_group": ' + this.portfolioService.finalDoctorsPortfolio[i][j].id_group +
                    ', "id_part": ' + this.portfolioService.finalDoctorsPortfolio[i][j].id_part +
                    ', "enable": ' + this.portfolioService.finalDoctorsPortfolio[i][j].enable + '},';
            }
        }
        json = json.slice(0, json.length - 1);
        json += ']';

        return json;
    }

    changePhysicianProfileSectionPortfolio() {
        this.odataService.postPrivate('change_physician_profile_section_portfolio',
            this.odataService.paramsTokenAndLang({
                portfolio_info: this.portfolioJSON()
            }),
            res => {
                let resolve, reject;
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.editing.portfolio = false;
                    Promise.all([new Promise((resolve, reject) => {
                        this.getOwnPhysicianProfile(resolve, reject, true);
                    })])
                        .then(() => {
                            this.portfolioService.setDoctorsPortfolio(this.physician_profile.portfolio.portfolio);
                        });
                    this.dataSavedToast.success('Saved');
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            }
        );
    }

    changePhysicianProfileSectionPhysicianSpecializations() {
        this.odataService.postPrivate('change_physician_profile_section_physician_specializations',
            this.odataService.paramsTokenAndLang({
                language: this.userService.iLanguage,
                physician_specializations: this.profileService.getSpecializations(this.spec)
            }),
            res => {
                let resolve, reject;
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.editing.specializations = false;
                    this.getOwnPhysicianProfile(resolve, reject, false);
                    this.dataSavedToast.success('Saved');
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            }
        );
    }

    setButtons(): void {
        if (this.editPermission) {
            for (let i = 1; i < 7; ++i) {
                let elem = document.getElementById('privacy' + i);
                if (elem) {
                    if (this.getSectionPrivacy(i)) elem.setAttribute('class', 'toggle btn btn-success');
                    else elem.setAttribute('class', 'toggle btn btn-danger off');
                }
            }
        }
    }

    changePhysicianProfileSectionWorkingHours(): void {
        let startHours = this.startHours.toArray();
        let stopHours = this.stopHours.toArray();

        this.workingHoursForm.patchValue({
          day1Start: startHours[0].GetTime(),
          day1Stop: stopHours[0].GetTime(),
          day2Start: startHours[1].GetTime(),
          day2Stop: stopHours[1].GetTime(),
          day3Start: startHours[2].GetTime(),
          day3Stop: stopHours[2].GetTime(),
          day4Start: startHours[3].GetTime(),
          day4Stop: stopHours[3].GetTime(),
          day5Start: startHours[4].GetTime(),
          day5Stop: stopHours[4].GetTime(),
          day6Start: startHours[5].GetTime(),
          day6Stop: stopHours[5].GetTime(),
          day7Start: startHours[6].GetTime(),
          day7Stop: stopHours[6].GetTime(),
        });

        let isValid = true;
        for (let i = 0; i < startHours.length; i++) {
          if (startHours[i].GetTime() == '' && stopHours[i].GetTime() != '') {
              isValid = false;
              break;
          } else if (startHours[i].GetTime() != '' && stopHours[i].GetTime() == '') {
            isValid = false;
            break;
          } else {
            let hour1 = new Date();
            hour1.setHours(parseInt(startHours[i].GetTime().split(':')[0]), parseInt(startHours[i].GetTime().split(':')[1]));
            let hour2 = new Date();
            hour2.setHours(parseInt(stopHours[i].GetTime().split(':')[0]), parseInt(stopHours[i].GetTime().split(':')[1]));
            if (hour1 > hour2) {
              isValid = false;
              break;
            }
          }
        }

        if (this.workingHoursForm.valid && isValid) {
            this.workingHourList.working_hours = this.profileService.addDays(this.workingHoursForm, 1);
            this.workingHourList.token = this.session.getToken();
            this.workingHourList.language = this.userService.iLanguage;

            this.odataService.postPrivate('change_physician_profile_section_working_hours', this.workingHourList,
                res => {
                    let resolve, reject;
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    this.workingHourList = {
                        token: '',
                        language: '',
                        working_hours: ''
                    };
                    if (result.Status == 'OK') {
                        this.errorAlertHours.clear();
                        this.editing.workingHours = false;
                        this.getOwnPhysicianProfile(resolve, reject, false);
                        this.dataSavedToast.success('Saved');
                    } else if (result.Status == 'OTHER_REASON') {
                        this.dataSavedToast.error('NotSaved');
                    }
                    this.dataSavedToast.show();
                    setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
                });
        } else {
            for (let i = 0; i < startHours.length; i++) {
                startHours[i].setHour();
                stopHours[i].setHour();
            }
            this.errorAlertHours.displayError('hours');
        }
    }

    changePhysicianProfileSectionAboutMe(): void {
        this.editing.aboutMe = false;
        let params = {
            token: this.session.getToken(),
            language: this.userService.iLanguage,
            about_me: (<HTMLInputElement>document.getElementById('aboutMeInput')).value
        };
        this.odataService.postPrivate('change_physician_profile_section_about_me', params,
            res => {
                let resolve, reject;
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.status == 'OK' || result.value == 'OK') {
                    this.editing.aboutMe = false;
                    this.getOwnPhysicianProfile(resolve, reject, false);
                    this.dataSavedToast.success('Saved');
                } else if (result.status == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            });
    }

    editPortfolio(): void {
        this.editing.portfolio = false;
    }

    portfolioCheckColumn(col: number, isChecked: boolean): void {
        this.portfolioService.isColsChecked[col] = isChecked;
        for (let i = 0; i < this.portfolioService.finalDoctorsPortfolio.length; i++) {
            this.portfolioService.finalDoctorsPortfolio[i][col].enable = isChecked;
        }
    }

    portfolioCheckRow(row: number, isChecked: boolean): void {
        this.portfolioService.isRowsChecked[row] = isChecked;
        for (let i = 0; i < this.portfolioService.finalDoctorsPortfolio[row].length; i++) {
            this.portfolioService.finalDoctorsPortfolio[row][i].enable = isChecked;
        }
    }

    getSectionPrivacy(section_number: number): boolean {
        if (section_number == 1) return this.physician_profile.working_hour_info.working_hours_enable;
        if (section_number == 2) return this.physician_profile.contact_info.contact_info_enable;
        if (section_number == 3) return this.physician_profile.about_me.about_me_enable;
        if (section_number == 4) return this.physician_profile.specialization.specialization_enable;
        if (section_number == 5) return this.physician_profile.portfolio.portfolio_enable;
        if (section_number == 6) return this.physician_profile.working_places.working_places_enable;
    }

    setSectionPrivacy(section_number: number, value: boolean): void {
        if (section_number == 1) this.physician_profile.working_hour_info.working_hours_enable = value;
        else if (section_number == 2) this.physician_profile.contact_info.contact_info_enable = value;
        else if (section_number == 3) this.physician_profile.about_me.about_me_enable = value;
        else if (section_number == 4) this.physician_profile.specialization.specialization_enable = value;
        else if (section_number == 5) this.physician_profile.portfolio.portfolio_enable = value;
        else if (section_number == 6) this.physician_profile.working_places.working_places_enable = value;
    }

    changePhysicianProfileSectionStatus(section_number: number, section_name: string): void {
        this.changePrivacy.token = this.session.getToken();
        this.changePrivacy.language = this.userService.iLanguage;
        this.changePrivacy.section_name = section_name;
        this.changePrivacy.value = !this.getSectionPrivacy(section_number);

        this.odataService.postPrivate('change_physician_profile_section_status', this.changePrivacy,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.changePrivacy = {
                        token: '',
                        language: '',
                        section_name: '',
                        value: false
                    };

                    this.setSectionPrivacy(section_number, !this.getSectionPrivacy(section_number));
                    let elem = document.getElementById('privacy' + section_number);
                    if (this.getSectionPrivacy(section_number)) elem.setAttribute('class', 'toggle btn btn-success');
                    else elem.setAttribute('class', 'toggle btn btn-danger off');
                    this.dataSavedToast.success('Saved');
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            });
    }

    deleteAccount(): void {
        Functions.deleteAccount();
    }

    scroll(id: string): void {
        DisplayService.scroll(id);
    }

    cancelEditing(section) {
        if (section == 'portfolio') {
            this.portfolioService.setDoctorsPortfolio(this.physician_profile.portfolio.portfolio);
            this.editing.portfolio = false;
        } else if (section == 'specializations') {
            this.editing.specializations = false;
        } else if (section == 'aboutMe') {
            this.editing.aboutMe = false;
        } else if (section == 'contact') {
            this.errorAlertContact.clear();
            this.setValuesInForm();
            this.editing.contact = false;
        } else if (section == 'workingHours') {
            this.editing.workingHours = false;
            this.errorAlertHours.clear();
        } else if (section == 'holidays') {
            this.editing.holidays = false;
        }
    }

    onPortfolioCheckChange(row: number, col: number): void {
        let oldChecked: boolean = this.portfolioService.finalDoctorsPortfolio[row][col].enable;
        this.portfolioService.finalDoctorsPortfolio[row][col].enable = !oldChecked;
        if (oldChecked) {
            this.portfolioService.isRowsChecked[row] = false;
            this.portfolioService.isColsChecked[col] = false;
        } else {
            let rowChecked: boolean = true;
            for (let mod of this.portfolioService.finalDoctorsPortfolio[row]) {
                if (!mod.enable) {
                    rowChecked = false;
                    break;
                }
            }
            this.portfolioService.isRowsChecked[row] = rowChecked;
            let colChecked: boolean = true;
            for (let i = 0; i < this.portfolioService.finalDoctorsPortfolio.length; ++i) {
                let mod = this.portfolioService.finalDoctorsPortfolio[i][col];
                if (!mod.enable) {
                    colChecked = false;
                    break;
                }
            }
            this.portfolioService.isColsChecked[col] = colChecked;
        }
    }

    onImageSelected(event) {
        let files = event.target.files;
        let file = files[0];

        if (files && file) {
            let reader = new FileReader();

            reader.onload = this.handleFile.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleFile(event) {
        this.dataSavedToast.success('UploadImage');
        this.dataSavedToast.isLoaderShown = true;
        this.dataSavedToast.show();
        let binaryString = event.target.result;
        this.base64textString = btoa(binaryString);
        this.base64textString = this.base64textString.replace(/\+/gi, '-');
        this.base64textString = this.base64textString.replace(/\//gi, '_');
        this.accountPploadProfileImage();
    }

    accountPploadProfileImage() {
        this.odataService.postPrivate('account_upload_profile_image',
            {
                token: this.session.getToken(),
                language: this.userService.iLanguage,
                base64: this.base64textString
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.userService.getUserData().then(() => {
                        this.imageUrl = this.userService.profileImageUrl;
                    });
                    this.dataSavedToast.hide();
                } else {
                    this.dataSavedToast.error('UploadImageError');
                    setTimeout(() => this.dataSavedToast.hide(), 3000);
                }
            });
    }

    navigateBack(): void {
        this.navigationService.navigateBack();
    }

    changeImageNoCacheSuffix(): void {
        this.imageNoCacheSuffix = new Date().getTime().toString();
    }

    navigateToPublicProfile(): void {
        if (this.doctor_id) this.router.navigate(['/doctorsProfile/' + this.doctor_id]);
    }

    sendInvitation(): void {
        this.inviteToCooperationModal.show(this.physician_profile.main.firstname +
          ' ' + this.physician_profile.main.last_name, this.physician_profile.main.id_user, this.userService.getCenterName());
    }

    afterInvite(sent: boolean): void {
        if (sent) this.dataSavedToast.success('OperationSuccess');
        else this.dataSavedToast.error('OperationFailed');
        Promise.all([new Promise((resolve, reject) => {
          this.get_public_physician_profile(resolve, reject, true, true);
        })]);
        this.dataSavedToast.show();
        setTimeout(() => this.dataSavedToast.hide(), 1500);
    }

    sendMessageFunc(): void {
        this.sendMessage.setUser({
            pwz: '',
            image_uuid: this.physician_profile.main.image_profile,
            action_list: [],
            contact_info: {
                email: '',
                address: '',
                address_city: '',
                phone_number: '',
                address_zip_code: ''
            },
            working_places: [],
            id_physician: this.physician_profile.main.id_user,
            physician_name: this.physician_profile.main.firstname +
                ' ' + this.physician_profile.main.last_name,
            specialization: [],
            image_uuid_serial: '',
            specialization_enable: true
        });
        this.sendMessage.show();
    }

    navigateToCenterProfile(centerId: number): void {
        this.router.navigate(['/publicCOProfile/' + centerId]);
    }
}
