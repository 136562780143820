<div class="pp--cards-columns">
  <div class="pp--card-column pp--card-column-big">
    <app-loader #loader class="app-loader"></app-loader>
    <div class="pp--card" id="ExamListCard">

      <div class="pp--card-inner small" style="padding-bottom: 0;">
         <h3 style="margin-bottom: 0; padding-bottom: 0;">
           {{'PatientsPortal.DashboardScreen.ExamList' |translate}}
         </h3>
       </div>

      <div class="pp--card-inner" *ngIf="isAgreementCard()" id="AgreementBody">
        <app-patient-agreement-card #patientAgreementCard [examListSize]="examList.length" (agreementResult)="patientExamSearchOption = $event" (startSearch)="getExamList()"></app-patient-agreement-card>
      </div>

      <div *ngIf="!isAgreementCard()" id="ExamListBody" style="padding-bottom: 0;">
        <div class="card-body" *ngIf="examList == [] || !examList[0]" style="margin-left: 15px">
          <h4><strong>{{'PatientsPortal.AgreementCard.NoExamsTitle' |translate}}</strong></h4>
          {{'PatientsPortal.AgreementCard.NoExamsTxtPt1' |translate}}<br>
          {{'PatientsPortal.AgreementCard.NoExamsTxtPt2' |translate}}
        </div>

        <table *ngIf="examList != [] && examList[0]" class="pp--table" id="examTable">

          <thead>
            <tr>
              <th>{{'PatientsPortal.ExamListScreen.ExamName' | translate}}</th>
              <th>Data i miejsca badania</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tr *ngFor="let exam of examList">
            <td>
              <span class="exam_icon exam_icon--{{exam.exam_type.id_type}}-{{exam.anatomy_part.id_part}}"></span>
              {{exam.anatomy_part.anatomy_part_text}}<br />
              <span class="light">{{exam.exam_type.exam_type_name}}</span>
            </td>
            <td>
              {{orderService.getDateString(exam.insert_date)}}<br />
              <span class="light">{{exam.medical_facility_name}}</span>
            </td>
            <td>
              <span *ngIf="exam.cloud_status == 1" class="pill pill-green">{{'PatientsPortal.DashboardScreen.InCloud' |translate}}</span>
            </td>
            <td>
              <a class="btn btn-light-blue btn-md"
                (click)="seeExam(exam.id_exam, exam.exam_type.id_type)">{{'PatientsPortal.DashboardScreen.SeeDetails'|translate}}</a>
            </td>
          </tr>
        </table>

      </div>

      <div class="pp--card-inner small" *ngIf="!isAgreementCard() && patientExamSearchOption == AGREEMENT_SKIPPED">
        <a style="text-decoration: underline;cursor: pointer;color: #0848FF;" *ngIf="patientExamSearchOption == AGREEMENT_SKIPPED" (click)="resetAgreement()">{{'PatientsPortal.DashboardScreen.AgreeToTerms' |translate}}</a>
      </div>

    </div>

    <div class="row d-flex">

      <div class="col-md-4 col-sm-6 col-xs-12 mt-5">
        <div style="height: 100%" class="card">
          <img style="object-fit: cover; width: 100%" src="assets/images/pp/cd.jpg" alt="">
          <div class="card-body small d-flex flex-column justify-content-between">
            <div style="display: flex; flex-direction: column">
              <h4 style="font-weight: 500;">Posiadasz badania na CD?</h4>
              <p style="font-size: 15px">Prześlij badanie do Zbadanych, aby bezpiecznie je przechować lub zasięgnąć konsultacji.</p>
            </div>
            <button class="btn btn-light fullwidth" (click)="uploadExamFromDisc()">{{'PatientsPortal.DashboardScreen.AddFromComputer'
              |translate}}</button>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 mt-5">
        <div style="height: 100%" class="card">
          <img style="object-fit: cover; width: 100%" src="assets/images/pp/teczka.jpg" alt="">
          <div class="card-body small d-flex flex-column justify-content-between">
            <div style="display: flex; flex-direction: column">
              <h4 style="font-weight: 500;">Udostępnij teczkę badań</h4>
              <p style="font-size: 15px">Jeśli masz aktywną usługę chmury możesz udostępnić wszystkie badania wybranemu lekarzowi.</p>
            </div>
            <button class="btn btn-light fullwidth" (click)="shareExamModal.show(folderShareInfo.share_status == 1)">
              <ng-container *ngIf="folderShareInfo && folderShareInfo.share_status == 1">
                {{'PatientsPortal.ShareExamModal.FolderSharedBtn' |translate}}</ng-container>
              <ng-container *ngIf="!folderShareInfo || folderShareInfo.share_status == 0">
                {{'PatientsPortal.ShareExamModal.ShareFolderBtn' |translate}}</ng-container>
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 mt-5">
        <div style="height: 100%" class="card">
          <img style="object-fit: cover; width: 100%" src="assets/images/pp/konto.jpg" alt="">
          <div class="card-body small d-flex flex-column justify-content-between">
            <div style="display: flex; flex-direction: column">
              <h4 style="font-weight: 500;">Twoje konto</h4>
              <p style="font-size: 15px">Edytuj swoje dane i ustawienia konta oraz przeglądaj swoją aktywność.<br /><br /></p>
            </div>
            <button routerLink="/accountProperties" class="btn btn-light fullwidth">Ustawienia konta</button>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="pp--card-column pp--card-column-small">
    <div class="pp--card" id="calendarCard">
      <div class="pp--card-inner small">
        <h3>{{'PatientsPortal.DashboardScreen.YourCalendar' |translate}}</h3>
        <bs-datepicker-inline [bsConfig]="{ showWeekNumbers: false, daysDisabled:[0] }"></bs-datepicker-inline>
        <p style="padding: 30px 0 0 0; color: #666; text-align: center;">Brak zaplanowanych badań i&nbsp;telekonsultacji.</p>
      </div>

    </div>
  </div>

</div>

<app-patient-share-exam-modal #shareExamModal [isFolderSharing]="true" [shareInfo]="folderShareInfo" (refreshShareInfo)="refreshShareInfo()"></app-patient-share-exam-modal>
<app-patient-exam-upload-modal #patientExamUploadModal></app-patient-exam-upload-modal>
