import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertModalConfig } from '@/utils/alert-modal/alert-modal.config';

export interface AlertModel {
  title: string;
  message: string;
  buttonText: string;
}

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  @Input() public modalConfig: AlertModalConfig;
  @Output() alertClosed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
  }

  show(): void {
    $('#alertModal').modal('show');
  }

  close(): void {
    $('#alertModal').modal('hide');
    this.alertClosed.emit();
  }

}
