import { Directive, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appMessageInsideClick]'
})
export class MessageInsideClickDirective {

    @Output() public messageInsideClick = new EventEmitter<any>();

    constructor(private _elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event.target'])

    public onClick(targetElement) {
        this.messageInsideClick.emit({ clickInside: this._elementRef.nativeElement.contains(targetElement) });
    }
}
