import {Component, OnInit, ViewChild} from '@angular/core';
import {OdataService} from '@/services';
import {IFacilityExams, initFacilityExam} from '@/interfaces';
import {UniversalToastComponent} from '@/utils/universal-toast/universal-toast.component';
import {FacilityOrdersService} from '@/services/facility-orders.service';

@Component({
  selector: 'app-consultation-modal',
  templateUrl: './consultation-modal.component.html',
  styleUrls: ['./consultation-modal.component.scss']
})
export class ConsultationModalComponent implements OnInit {
  exam: IFacilityExams = initFacilityExam;
  requestSuccess: boolean = false;
  requestSent: boolean = false; // replacing toast due yo issues with displaying

  @ViewChild('toast') toast: UniversalToastComponent;

  constructor(private odataService: OdataService, public facilityOrdersService: FacilityOrdersService) { }

  ngOnInit() {
  }

  show(input: IFacilityExams) {
    this.requestSent = this.requestSuccess = false;
    this.exam = input;
    $('#consultationModal').modal('show');
  }

  onSubmitConsultation() {
    this.odataService.postPrivate('mf_create_ai_consultation',
      this.odataService.paramsTokenAndLang({
        id_exam: this.exam.id_exam,
        id_medical_facility: this.exam.id_medical_facility,
        id_consultation_type: this.exam.consultation.id_consultation_type
      }), (res) => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          this.requestSent = this.requestSuccess = true;
          this.facilityOrdersService.exams.find(x => x.id_exam == this.exam.id_exam).consultation.consultation_status = JSON.parse(result.Value).consultation_status;
          setTimeout(() => $('#consultationModal').modal('hide'), 2000);
        } else {
          this.requestSuccess = false;
          this.requestSent = true;
          setTimeout(() => $('#consultationModal').modal('hide'), 2000);
        }
    });
  }
}
