import { Injectable } from '@angular/core';
import StickySidebar from 'node_modules/sticky-sidebar';
import { IField } from '@/interfaces';

@Injectable({
    providedIn: 'root'
})
export class DisplayService {

    constructor() {
    }

    static fullScreen(): void {
        let element = document.documentElement;
        let fullscreenElement: boolean = (!document['fullscreenElement'] &&
            !document['msFullscreenElement'] &&
            !document['mozFullScreenElement'] &&
            !document['webkitFullscreenElement']);

        if (fullscreenElement && (document.fullscreenEnabled || document['mozFullscreenEnabled'] || document['webkitFullscreenEnabled'] || document['msFullscreenEnabled'])) {
            if (element['requestFullscreen']) {
                element['requestFullscreen']();
            } else if (element['msRequestFullscreen']) {
                element['msRequestFullscreen']();
            } else if (element['mozRequestFullScreen']) {
                element['mozRequestFullScreen']();
            } else if (element['webkitRequestFullscreen']) {
                element['webkitRequestFullscreen']();
            }
        } else {
            if (document['exitFullscreen']) {
                document['exitFullscreen']();
            } else if (document['webkitExitFullscreen']) {
                document['webkitExitFullscreen']();
            } else if (document['mozCancelFullScreen']) {
                document['mozCancelFullScreen']();
            } else if (document['msExitFullscreen']) {
                document['msExitFullscreen']();
            }
        }
    }

    static scroll(id: string): void {
        let section = document.getElementById(id);

        if (section != null) {
            section.scrollIntoView();
        }
    }

    static activateSidebarFieldsOnScroll(fields: IField[], activateField: any): Function {
        let lastIndex: number = 0;
        let handlerFunction: any = () => {
            let viewportTop: number = document.documentElement.scrollTop || document.body.scrollTop;
            let i: number = 0;

            while (i < fields.length && fields[i].topHeight < viewportTop)
                ++i;

            if (i != 0) --i;
            if (i < fields.length && i != lastIndex) {
                activateField[fields[i].name] = true;
                activateField[fields[lastIndex].name] = false;
                lastIndex = i;
            }

        };

        window.addEventListener('scroll', handlerFunction);
        return handlerFunction;
    }

    static switchMode(): void {
        let dashboard: HTMLElement = document.getElementById('dashboard');
        if (localStorage.getItem('darkMode') == 'false') {
            localStorage.setItem('darkMode', 'true');
            if (dashboard)
                dashboard.setAttribute('class', 'dark');
            document.body.setAttribute('class', 'h-100 dark-mode');
        } else {
            localStorage.setItem('darkMode', 'false');
            if (dashboard)
                dashboard.setAttribute('class', 'bright');
            document.body.setAttribute('class', 'h-100');
        }
    }

    static setMode(): void {
        if (localStorage.getItem('darkMode') == 'true') {
            document.getElementById('dashboard').setAttribute('class', 'dark');
            document.body.setAttribute('class', 'h-100 dark-mode');
        } else {
            document.getElementById('dashboard').setAttribute('class', 'bright');
            document.body.setAttribute('class', 'h-100');
        }
    }

    static createSidebar(sidebar: string, container: string, inner_wrapper: string): StickySidebar {
        return new StickySidebar('#' + sidebar, {
            containerSelector: '#' + container,
            innerWrapperSelector: '#' + inner_wrapper,
            topSpacing: 20,
            bottomSpacing: 20,
        });
    }

    static createStickySidebar(sidebarId: string, sidebarHeight: number, topSpacing: number, bottomSpacing: number, sidebarWidth: string): any {
        let sidebar: HTMLElement = document.getElementById(sidebarId);

        let lastTop: number = 0;
        let lastLeft: number = 0;
        let stickedToBottom: boolean = false;
        let stickedToTop: boolean = false;

        let handlerFunction: any = () => {
            let viewportHeight: number = document.documentElement.clientHeight;
            let viewportTop: number = document.documentElement.scrollTop || document.body.scrollTop;
            let viewportBottom: number = viewportTop + viewportHeight;
            let viewportLeft: number = window.scrollX;

            if (viewportLeft == 0) {
                let sidebarTop: number;
                if (stickedToTop)
                    sidebarTop = viewportTop;
                else if (stickedToBottom)
                    sidebarTop = viewportBottom - sidebarHeight;
                else if (sidebarWidth)
                    sidebarTop = Math.abs(sidebar.offsetTop) + topSpacing;
                else
                    sidebarTop = Math.abs(sidebar.offsetTop);

                let sidebarBottom: number = sidebarTop + sidebarHeight;

                if (viewportHeight < sidebarHeight) {
                    if (viewportTop > lastTop) {
                        if (!stickedToBottom && !stickedToTop && sidebarBottom <= viewportBottom && sidebarBottom < (document.documentElement.scrollHeight - bottomSpacing)) {
                            if (sidebarWidth)
                                sidebar.style.width = sidebar.offsetWidth + 'px';
                            sidebar.style.position = 'fixed';
                            sidebar.style.bottom = '0px';
                            sidebar.style.top = '';
                            stickedToBottom = true;
                            stickedToTop = false;
                        } else if (stickedToTop) {
                            stickedToTop = false;
                            if (sidebarWidth)
                                sidebar.style.width = sidebarWidth;
                            sidebar.style.position = 'relative';
                            sidebar.style.top = (viewportTop - topSpacing) + 'px';
                            sidebar.style.bottom = '';
                        } else if (stickedToBottom && sidebarBottom >= (document.documentElement.scrollHeight - bottomSpacing)) {
                            stickedToBottom = false;
                            if (sidebarWidth)
                                sidebar.style.width = sidebarWidth;
                            sidebar.style.position = 'relative';
                            sidebar.style.top = (document.documentElement.scrollHeight - bottomSpacing - sidebarHeight - topSpacing) + 'px';
                        }
                    } else if (viewportTop < lastTop) {
                        if (!stickedToTop && sidebarTop >= viewportTop && !(sidebarTop <= topSpacing)) {
                            if (sidebarWidth)
                                sidebar.style.width = sidebar.offsetWidth + 'px';
                            sidebar.style.position = 'fixed';
                            sidebar.style.top = '0px';
                            stickedToTop = true;
                            stickedToBottom = false;
                        } else if (stickedToBottom) {
                            stickedToBottom = false;
                            if (sidebarWidth)
                                sidebar.style.width = sidebarWidth;
                            sidebar.style.position = 'relative';
                            sidebar.style.top = (viewportBottom - sidebarHeight - topSpacing) + 'px';
                        } else if (stickedToTop && sidebarTop <= topSpacing) {
                            stickedToTop = false;
                            if (sidebarWidth)
                                sidebar.style.width = sidebarWidth;
                            sidebar.style.position = 'relative';
                            sidebar.style.top = '0px';
                        }
                        sidebar.style.bottom = '';
                    }
                } else {
                    if (viewportTop < lastTop) {
                        if (stickedToTop && sidebarTop <= topSpacing && topSpacing != 0) {
                            stickedToTop = false;
                            if (sidebarWidth)
                                sidebar.style.width = sidebarWidth;
                            sidebar.style.position = 'relative';
                            sidebar.style.top = '0px';
                        } else if (sidebarTop > topSpacing) {
                            sidebar.style.width = sidebar.offsetWidth + 'px';
                            sidebar.style.position = 'fixed';
                            sidebar.style.top = '0px';
                            stickedToTop = true;
                            stickedToBottom = false;
                        }
                    } else {
                        if (stickedToTop && sidebarBottom >= (document.documentElement.scrollHeight - bottomSpacing)) {
                            stickedToTop = false;
                            if (sidebarWidth)
                                sidebar.style.width = sidebarWidth;
                            sidebar.style.position = 'relative';
                            sidebar.style.top = (sidebarTop - topSpacing) + 'px';
                        } else if (sidebarBottom < (document.documentElement.scrollHeight - bottomSpacing) && viewportTop >= topSpacing) {
                            sidebar.style.width = sidebar.offsetWidth + 'px';
                            sidebar.style.position = 'fixed';
                            sidebar.style.top = '0px';
                            stickedToTop = true;
                            stickedToBottom = false;
                        }
                    }
                }

                lastTop = viewportTop;
            }  else if (lastLeft == 0 && viewportLeft > 0) {
                stickedToTop = false;
                stickedToBottom = false;
                if (sidebarWidth)
                    sidebar.style.width = sidebarWidth;
                sidebar.style.position = 'relative';
                sidebar.style.top = '0px';
            }
            lastLeft = viewportLeft;
        };
        window.addEventListener('scroll', handlerFunction);
        return handlerFunction;
    }
}
