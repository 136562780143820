export interface IStatistics {
    averageReactionTime: string;
    averageRealizationTime: string;
    descriptionsCount: number;
    monthSalary: number;
    freeOrdersCount: number;
    canceledOrders: number;
    timeLimitExceededOrdersCount: number;
    currentOrdersCount: number;
    exceededSLACount: number;
    comeToExceededSLACount: number;
}

export const statistics: IStatistics = {
    averageReactionTime: '16:24',
    averageRealizationTime: '05:12',
    descriptionsCount: 48,
    monthSalary: 10734,
    freeOrdersCount: 7,
    canceledOrders: 5,
    timeLimitExceededOrdersCount: 3,
    currentOrdersCount: 6,
    exceededSLACount: 2,
    comeToExceededSLACount: 4
};
