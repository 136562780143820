<div id="top-bar" style="z-index: 50;">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'PatientsScreen.Title' | translate}}</h3>

            <ul class="top-bar-nav">
              <ng-container *ngFor="let filter of filterService.getMyFilters('patients'); let i = index">
                <li *ngIf="filter.isFavourite"  id="myFilter{{i}}" [ngClass]="{'active': currentId == i.toString()}">
                  <a (click)="getOrders(i.toString())" [style.pointer-events]="isEmptyPatients ? 'none' : 'all'"
                     style="cursor: pointer;">{{filter.name}}</a>
                </li>
              </ng-container>
              <li class="dropdown">
                <a style="cursor: pointer;" id="otherFilters" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false" [style.background]="isActiveOtherFilter ? 'rgba(8, 72, 255, 0.1)' : 'none'"
                   [style.color]="isActiveOtherFilter ? '#0848FF' : '#444444'" (click)="hideFilter()">
                  {{moreFilters}}
                  <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                </a>
                <div class="dropdown-menu" aria-labelledby="otherFilters">
                  <ng-container *ngFor="let filter of filterService.getMyFilters('patients'); let i = index">
                    <a *ngIf="!filter.isFavourite" class="dropdown-item" id="myFilter{{i}}" [style.pointer-events]="isEmptyPatients ? 'none' : 'all'"
                       (click)="getOrders(i.toString())" style="cursor: pointer">{{filter.name}}</a>
                  </ng-container>
                  <a routerLink="/manageMyFilters/patients" class="dropdown-item" style="cursor: pointer">
                    {{'DashboardScreen.Filters.ManageYourFilters' | translate}}
                  </a>
                </div>
              </li>
            </ul>
            <form>
                <ul class="top-bar-nav">
                    <li class="filter_dropdown" (click)="dropdown()" style="cursor: pointer;" [style.pointer-events]="isEmptyPatients ? 'none' : 'all'">
                        <a><span *ngIf="filters.filtersActivePatients" class="dot"></span>
                          {{'DashboardScreen.Filters.FilterSort' | translate}}
                            <svg-icon class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
                        </a>
                    </li>
                </ul>
                <app-search [style.pointer-events]="isEmptyPatients ? 'none' : 'all'" #search placeholder="PatientsScreen.SearchPatient"></app-search>
            </form>

        </div>
    </div>

</div>

<app-filters #filters (filter)="filterPatients($event)" type="PATIENTS"></app-filters>

<table [hidden]="patients.length == 0" class="tile-table classic-table alt-table" id="dataTableExample">

    <thead>
    <tr>
        <th>{{'PatientsScreen.Surname' | translate}}
            <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'surname' && sortType == 'DESC'}"
                (click)="sortColumn = 'surname'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
            <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'surname' && sortType == 'ASC'}"
                (click)="sortColumn = 'surname'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
        </th>
        <th>{{'PatientsScreen.Name' | translate}}
            <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'name' && sortType == 'DESC'}"
                (click)="sortColumn = 'name'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
            <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'name' && sortType == 'ASC'}"
                (click)="sortColumn = 'name'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
        </th>
        <th>{{'PatientsScreen.Sex' | translate}}
            <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'sex' && sortType == 'DESC'}"
                (click)="sortColumn = 'sex'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
            <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'sex' && sortType == 'ASC'}"
                (click)="sortColumn = 'sex'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
        </th>
        <th>{{'PatientsScreen.Pesel' | translate}}
            <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'pesel' && sortType == 'DESC'}"
                (click)="sortColumn = 'pesel'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
            <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'pesel' && sortType == 'ASC'}"
                (click)="sortColumn = 'pesel'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
        </th>
        <th>{{'PatientsScreen.Age' | translate}}
            <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'age' && sortType == 'DESC'}"
                (click)="sortColumn = 'age'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
            <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'age' && sortType == 'ASC'}"
                (click)="sortColumn = 'age'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
        </th>
        <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>
    <ng-container *ngFor="let patient of patients; let i = index">
        <tr class="tile">
            <td>{{patient.last_name}}</td>
            <td>{{patient.first_name}}</td>
            <td [ngSwitch]="patient.sex">
                <ng-container *ngSwitchCase="'M'">{{'PatientsScreen.Male' | translate}}</ng-container>
                <ng-container *ngSwitchCase="'F'">{{'PatientsScreen.Female' | translate}}</ng-container>
                <ng-container *ngSwitchDefault>{{'PatientsScreen.Unknow' | translate}}</ng-container>
            </td>
            <td>{{patient.pesel}}</td>
            <td>{{patient.age}} {{declinateYears(patient.age) | translate}}</td>
            <td class="actions">
                <a (click)="showOrders(i)" [ngClass]="{'disabled' : patient.exam_count === 0, 'buttonShowOrdersActive': isShown[i]}"
                   class="btn btn-light">{{'PatientsScreen.ShowOrders' | translate}}
                    ({{patient.exam_count}})</a>
            </td>

        </tr>

        <tr *ngIf="isShown[i]" class="tile-children">
            <td colspan="6">
                <ng-container *ngIf="downloadingOrders">
                    <app-loader></app-loader>
                </ng-container>
                <ng-container *ngIf="!downloadingOrders">
                    <app-orders-list #ordersList (actionEmitter)="makeAction($event.action, $event.id, $event.patient)" [orders]="patient.patients_orders" [hours]="patient.hours" [dates]="patient.dates" [age]="createArray(patient.patients_orders.length, patient.age)" [patient]="patient" type="Patients"></app-orders-list>
                    <div class="row mt-1 mr-3" *ngIf="patient.patients_orders.length != 0">
                        <div class="col-1">
                            <select (change)="changeNumberOfOrdersPages(i);" [(ngModel)]="ordersOnPage"
                                    [value]="ordersOnPage" class="custom-select">
                                <option [value]="10" selected>10</option>
                                <option [value]="25">25</option>
                                <option [value]="50">50</option>
                                <option [value]="100">100</option>
                            </select>
                        </div>
                        <div class="col-7"></div>
                        <div class="col-3">
                            <nav aria-label="...">
                                <ul class="pagination justify-content-end">
                                    <li *ngIf="activeOrderPage != 1" class="page-item">
                                        <a class="page-link" (click)="changeOrdersPage(i, activeOrderPage-1)">{{'Pages.Previous' | translate}}</a>
                                    </li>
                                    <li *ngIf="activeOrderPage == 1" class="page-item disabled">
                                        <a class="page-link">{{'Pages.Previous' | translate}}</a>
                                    </li>
                                    <li *ngFor="let page of orderPages[i]; let k = index" class="page-item"
                                        [ngClass]="{'active': k==activeOrderPage-1}">
                                        <div *ngIf="orderPages[k].length >= 5">
                                            <a *ngIf="page == activeOrderPage || page == activeOrderPage-1 || page == activeOrderPage+1 || page == pages.length || page == 1"
                                            class="page-link" (click)="changeOrdersPage(i, page)">{{page}}</a>
                                            <a *ngIf="(page == activeOrderPage-2 || page == activeOrderPage+2) && page != 1 && page != orderPages[k].length"
                                            class="page-link">...</a>
                                        </div>
                                        <div *ngIf="orderPages[k].length < 5">
                                            <a class="page-link" (click)="changeOrdersPage(i, page)">{{page}}</a>
                                        </div>
                                    </li>
                                    <li *ngIf="activeOrderPage != orderPages[i].length" class="page-item">
                                        <a class="page-link" (click)="changeOrdersPage(i, activeOrderPage+1)">{{'Pages.Next' | translate}}</a>
                                    </li>
                                    <li *ngIf="activeOrderPage == orderPages[i].length" class="page-item disabled">
                                        <a class="page-link">{{'Pages.Next' | translate}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-1">
                            <input [ngClass]="{'errorBorder': inputPageError}"
                                class="form-control text-center" id="inputPage" type="text"
                                placeholder="{{'Pages.Page' | translate}}"
                                [(ngModel)]="inputPage" (keydown.enter)="goToPageOrders(i, inputPage)">
                        </div>
                    </div>
                </ng-container>
            </td>
        </tr>
    </ng-container>
    </tbody>
</table>

<app-loader #loader></app-loader>

<app-pagination #patientsPagination componentName="Patients" [hidden]="patients.length == 0"></app-pagination>

<app-empty-orders-list [mode]="'DC'" [page]="'patients'" [ordersCount]="patients.length" [isLoaderShow]="loader.show" [isSearched]="search.isSearch" [isFiltered]="filters.filtersActivePatients || currentId != ''"></app-empty-orders-list>

<app-assign-physician-modal #assignPhysicianModal></app-assign-physician-modal>
<app-reject-modal #rejectModal></app-reject-modal>
<app-pdf-not-available-modal #pdfModal></app-pdf-not-available-modal>
