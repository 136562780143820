import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ControlValueAccessor } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UsersService, OdataService, SessionService } from '@/services';
import { EnvService } from '@/services/env.service';
var SelectRecipientAsyncComponent = /** @class */ (function () {
    function SelectRecipientAsyncComponent(userService, odataService, env, sanitizer, sessionService) {
        var _this = this;
        this.odataService = odataService;
        this.env = env;
        this.sanitizer = sanitizer;
        this.sessionService = sessionService;
        this.typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
        this.valid = true;
        this.innerValue = undefined;
        this.changed = new Array();
        this.touched = new Array();
        this.userService = userService;
        this.dataSource = new Observable(function (observer) {
            // Runs on every search
            observer.next(_this.asyncSelected);
        })
            .pipe(mergeMap(function (token) { return _this.getDataAsObservable(token); }), catchError(this.handleError));
    }
    Object.defineProperty(SelectRecipientAsyncComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (value) {
            if (this.innerValue !== value) {
                this.innerValue = value;
                this.changed.forEach(function (f) { return f(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    SelectRecipientAsyncComponent.prototype.ngOnInit = function () {
    };
    SelectRecipientAsyncComponent.prototype.handleError = function (error) {
        console.log(error);
        return of([]);
    };
    SelectRecipientAsyncComponent.prototype.getDataAsObservable = function (search) {
        var _this = this;
        if (search.length < 3) {
            return of([]);
        }
        return this.odataService.postPrivateObservable('get_messages_user_list', this.odataService.paramsTokenAndLang({
            filtr: search
        })).pipe(map(function (data) { return _this.dataMap(data); }));
    };
    SelectRecipientAsyncComponent.prototype.dataMap = function (res) {
        if (res.Value === '')
            return [];
        var result = JSON.parse(res.Value);
        if (Array.isArray(result)) {
            for (var i = 0; i < result.length; i++) {
                if (!('image_uuid' in result[i]) || !result[i].image_uuid || result[i].image_uuid === '') {
                    result[i]['profile_image'] = '../../assets/images/icons/user.svg';
                }
                else {
                    result[i]['profile_image'] = this.env.miniProfilePicURL + result[i].image_uuid;
                }
            }
        }
        else {
            console.log('Error not array', result);
            result = [];
        }
        return result;
    };
    SelectRecipientAsyncComponent.prototype.changeTypeaheadLoading = function (e) {
        this.typeaheadLoading = e;
    };
    SelectRecipientAsyncComponent.prototype.typeaheadOnSelect = function (e) {
        this.typeaheadIcon = e.item.profile_image;
        this.value = e.item.id_user;
    };
    SelectRecipientAsyncComponent.prototype.onValueChange = function () {
        if (this.value !== undefined) {
            this.typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
            this.value = undefined;
        }
    };
    SelectRecipientAsyncComponent.prototype.touch = function () {
        this.touched.forEach(function (f) { return f(); });
    };
    SelectRecipientAsyncComponent.prototype.writeValue = function (obj) {
        // NIE USUWAĆ to jest potrzebne.
    };
    SelectRecipientAsyncComponent.prototype.registerOnChange = function (fn) {
        this.changed.push(fn);
    };
    SelectRecipientAsyncComponent.prototype.registerOnTouched = function (fn) {
        this.touched.push(fn);
    };
    SelectRecipientAsyncComponent.prototype.clear = function () {
        this.typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
        this.value = undefined;
        this.asyncSelected = '';
    };
    return SelectRecipientAsyncComponent;
}());
export { SelectRecipientAsyncComponent };
