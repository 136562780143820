import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    static streetRegex = RegExp(/[,.!@#$%^&*\[\]\{\}\\\|\(\)_?=+`~:;"<>]/);
    static mailRegex = RegExp(/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
    static passRegex = RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
    static numberRegex = RegExp(/^(\+48)?[0-9]{9,}$/);
    static numericRegex = RegExp(/^[0-9]*$/);
    static nameRegex = RegExp(/[0-9!@#$%^&*\[\]\{\}\\\|\(\)_?=+`~:;"<>.,]/);
    static dateRegex = RegExp(/^[012]{1}[0-9]{1}[/.-]{1}[01]{1}[0-9]{1}[/.-]{1}[0-9]{2,4}$/);
    static loginRegex = RegExp(/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>\-_]{9,}$/);
    static priceRegex = RegExp(/^[1-9][0-9]*$/);

    static numberValidator = Validators.pattern(ValidationService.numericRegex);
    static mailValidator = Validators.pattern(ValidationService.mailRegex);
    static priceValidator = Validators.pattern(ValidationService.priceRegex);
    static passwordValidator = Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
    static dateValidator = Validators.pattern(ValidationService.dateRegex);
    static phoneNumberValidator = Validators.pattern(ValidationService.numberRegex);
    static workingHoursValidator = Validators.pattern(/^([0-1]?[0-9]|2[0-4]):([0-5]?[0-9])(:[0-5][0-9])?$/);
    static zipCodeValidator = Validators.pattern(/^[0-9]{2}[-]{1}[0-9]{3}$/);
    static localValidator = Validators.pattern(/^[0-9]{1,4}$/);
    static loginValidator = Validators.pattern(ValidationService.loginRegex);
    static pwzValidator(control: FormControl) {
      let pwz = control.value;
      if (pwz) return ValidationService.validatePWZ(pwz) ? null : {invalidReason: 'invalid combination'};
      else return { invalidReason: 'undefined'};
    }
    static peselValidator(control: FormControl) {
      let pesel = control.value;
      if (pesel) return ValidationService.validatePesel(pesel) ? null : {invalidReason: 'invalid combination'};
      else return { invalidReason: 'undefined'};
    }
    static birthdateValidator = Validators.pattern(/^[0-9]{4}[-]{1}[01]{1}[0-9]{1}[-]{1}[0123]{1}[0-9]{1}$/);
    static timestampValidator = Validators.pattern(/^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-][01]\d:[0-5]\d)$/);
    private static checksums = {
        pesel: {
            length: 11,
            factors: [9, 7, 3, 1, 9, 7, 3, 1, 9, 7, 0],
            resFun: (sum, lastDigit) => sum % 10 == lastDigit
        },
        nip: {
            length: 10,
            factors: [6, 5, 7, 2, 3, 4, 5, 6, 7, 0],
            resFun: (sum, lastDigit) => sum % 11 == lastDigit && sum % 11 != 10
        },
        regon: {
            length: 9,
            factors: [8, 9, 2, 3, 4, 5, 6, 7, 0],
            resFun: (sum, lastDigit) => sum % 11 % 10 == lastDigit
        }
    };

    constructor() {
    }

    static validateDate(date: string): boolean {
        return ValidationService.dateRegex.test(date);
    }

    static validateLogin(login: string): boolean {
        return ValidationService.loginRegex.test(login);
    }

    static validatePrice(price: string): boolean {
        return ValidationService.priceRegex.test(price);
    }

    static validateMail(mail: string): boolean {
        return ValidationService.mailRegex.test(mail);
    }

    static validatePass(pass: string): boolean {
        return ValidationService.passRegex.test(pass);
    }

    static validatePhone(number: string): boolean {
        return ValidationService.numberRegex.test(number);
    }

    static validateName(name: string): boolean {
        if (name.length == 0) return false;
        return !ValidationService.nameRegex.test(name);
    }

    static validatePesel(pesel: any): boolean {
        if (pesel.length != 11) return false;
        let sum: number = Number(pesel[0]) * 9 + Number(pesel[1]) * 7 + Number(pesel[2]) * 3 + Number(pesel[3]) +
            Number(pesel[4]) * 9 + Number(pesel[5]) * 7 + Number(pesel[6]) * 3 + Number(pesel[7]) +
            Number(pesel[8]) * 9 + Number(pesel[9]) * 7;
        sum = sum % 10;

        return sum == Number(pesel[10]);
    }

    static validatePWZ(pwz: any): boolean {
      if (pwz.length != 7 || Number(pwz[0]) == 0) return false;
      let sum: number = Number(pwz[1]) + Number(pwz[2]) * 2 + Number(pwz[3]) * 3 + Number(pwz[4]) * 4
        + Number(pwz[5]) * 5 + Number(pwz[6]) * 6;
      sum = sum % 11;
      return sum == Number(pwz[0]);
    }

    static validNumber(number: string, numberType): boolean {
        let length: number = number.length;
        if (length != numberType.length) {
            return false;
        } else {
            let sum: number = 0;
            number.split('').forEach((element, index) =>
                sum += numberType.factors[index] * +element);
            return numberType.resFun(sum, number[length - 1]);
        }
    }

    static matchingPasswordsValidator(control: FormGroup) {
        const pass = control.get('pass');
        const retryPass = control.get('retryPass');

        return pass && retryPass && pass.value != retryPass.value ? {'passwordsMatch': true} : null;
    }

    static nameValidator(control: AbstractControl) {
        let error: boolean = ValidationService.nameRegex.test(control.value);
        return error ? {'imie': true} : null;
    }

    static streetValidator(control: AbstractControl) {
        let error: boolean = ValidationService.streetRegex.test(control.value);
        return error ? {'ulica': true} : null;
    }

    peselValidator(control: AbstractControl) {
        let error: boolean = !ValidationService.validNumber(control.value, ValidationService.checksums.pesel);
        return error ? {'pesel': true} : null;
    }

    regonValidator(control: AbstractControl) {
        let error: boolean = !ValidationService.validNumber(control.value, ValidationService.checksums.regon);
        return error ? {'regon': true} : null;
    }

    nipValidator(control: AbstractControl) {
        let error: boolean = !ValidationService.validNumber(control.value, ValidationService.checksums.nip);
        return error ? {'nip': true} : null;
    }

}
