<!-- start: przypiszZlecenieModal -->
<div aria-hidden="true" class="modal fade"
     role="dialog"
     tabindex="-1" id="przypiszZlecenieModal"
     aria-labelledby="przypiszZlecenieModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="przypiszZlecenieModalLabel">{{'AssignPhysicianModal.Title' | translate}}</h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{'AssignPhysicianModal.Patient' | translate}}: <strong style="color: var(--blue);">{{patientName}}</strong><br>
        {{'AssignPhysicianModal.Date' | translate}}: <strong style="color: var(--blue);">{{orderDate + ', ' + orderName}}</strong><br>
        <p>{{'AssignPhysicianModal.Anatomy' | translate}}: <strong style="color: var(--blue);">{{anatomyPart}}</strong></p><br>
        <h4>{{'AssignPhysicianModal.ChooseDoctor' | translate}}:</h4>
        <form [formGroup]="assignPhysForm" novalidate>
          <div class="form-group">
            <ng-select id="selectPhys" #selectPhys
                       [items]="physicians"
                       formControlName="physician"
                       style="width: 75%;"
                       hideSelected="true"
                       bindLabel="physician_name"
                       notFoundText="{{'CreateOrderModal.NotFoundText' |translate}}"
            >
              <ng-template ng-label-tmp let-item="item">
               <img height="15" width="15" [src]="getProfileUrl(item)" alt=""/>
                {{item.physician_name}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <span class="text container">
                  <img [src]="getProfileUrl(item)" style="width: 28px; height: 28px" alt=""/>
                  <span style="position: relative; top: 2px; left: 8px">
                    <span [ngOptionHighlight]="search">{{item.physician_name}}</span>, PWZ {{item.pwz}}
                  </span>
                </span>
              </ng-template>
            </ng-select>
          </div>

        </form>


      </div>
      <div class="modal-footer mt-5">
        <p></p>
        <button (click)="resolvePromise()" [disabled]="!assignPhysForm.valid" type="button" class="btn btn-success btn-lg">{{setButtonText()}}</button>
      </div>
    </div>
  </div>
</div>
<!-- end: przypiszZlecenieModal -->
