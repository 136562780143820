/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./insufficient-rights-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./insufficient-rights-modal.component";
var styles_InsufficientRightsModalComponent = [i0.styles];
var RenderType_InsufficientRightsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InsufficientRightsModalComponent, data: {} });
export { RenderType_InsufficientRightsModalComponent as RenderType_InsufficientRightsModalComponent };
export function View_InsufficientRightsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["aria-hidden", "true"], ["aria-labelledby", "insufficientRightsModalLabel"], ["class", "modal fade"], ["id", "insufficientRightsModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "modal-title mb-4"], ["id", "insufficientRightsModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brak uprawnie\u0144"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
export function View_InsufficientRightsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-insufficient-rights-modal", [], null, null, null, View_InsufficientRightsModalComponent_0, RenderType_InsufficientRightsModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.InsufficientRightsModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InsufficientRightsModalComponentNgFactory = i1.ɵccf("app-insufficient-rights-modal", i2.InsufficientRightsModalComponent, View_InsufficientRightsModalComponent_Host_0, {}, {}, []);
export { InsufficientRightsModalComponentNgFactory as InsufficientRightsModalComponentNgFactory };
