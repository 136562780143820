<div class="form-group">
    <label *ngIf="docType === 'PESEL'" [innerHTML]="'LoginSecondScreen.PeselLabel' | translate"
           class="bigLabel"></label>
    <label *ngIf="docType === 'Paszport'" [innerHTML]="'LoginSecondScreen.DocumentLabel' | translate"
           class="bigLabel"></label>
    <div class="masked-login-group" id="pesel-group">
        <div *ngFor="let i of isDisable; index as a;" class="masked-login">
            <input *ngIf="i" class="form-control" disabled id="inputID-{{a+1}}" maxlength="1"
                   placeholder="" type="text">
            <input (keydown)="moveCursor(a, $event)" *ngIf="!i" [(ngModel)]="filledAray[a]"
                   [class.is-error]="error" [ngModelOptions]="{standalone : true}"
                   class="form-control" id="inputID-{{a+1}}"
                   name="pin-{{a+1}}"
                   autocomplete="off"
                   maxlength="1" placeholder="" type="text">
            <label for="inputID-{{a+1}}">{{a + 1}}</label>
        </div>
    </div>
</div>