<img width="39" height="39" src="../../../../../../../assets/images/icons/warning_circle.svg" alt="" style="margin-right: 12px; width:39px; height: 39px; float: left;" />
<strong>{{'Consultation.COVIDSuspected' |translate}}</strong><br>
{{'Consultation.ConsultationResult' |translate}} {{'Consultation.Date' |translate}}
{{covidReportInfo.consultationDate}}
<div class="clearfix" style="clear:both;">&nbsp;</div>
<ng-container *ngIf="covidStatistics.RUL.percentage != ''"><span style="width: 100px; display: inline-block;">RUL</span> <strong>{{covidStatistics.RUL.percentage}}%</strong>
  <span class="light">({{covidStatistics.RUL.volume}} cm3)</span><br></ng-container>
<ng-container *ngIf="covidStatistics.RML.percentage != ''"><span style="width: 100px; display: inline-block;">RML</span> <strong>{{covidStatistics.RML.percentage}}%</strong>
  <span class="light">({{covidStatistics.RML.volume}} cm3)</span><br></ng-container>
<ng-container *ngIf="covidStatistics.RLL.percentage != ''"><span style="width: 100px; display: inline-block;">RLL</span> <strong>{{covidStatistics.RLL.percentage}}%</strong>
  <span class="light">({{covidStatistics.RLL.volume}} cm3)</span><br></ng-container>
<ng-container *ngIf="covidStatistics.LUL.percentage != ''"><span style="width: 100px; display: inline-block;">LUL</span> <strong>{{covidStatistics.LUL.percentage}}%</strong>
  <span class="light">({{covidStatistics.LUL.volume}} cm3)</span><br></ng-container>
<ng-container *ngIf="covidStatistics.LLL.percentage != ''"><span style="width: 100px; display: inline-block;">LLL</span> <strong>{{covidStatistics.LLL.percentage}}%</strong>
  <span class="light">({{covidStatistics.LLL.volume}} cm3)</span><br></ng-container>
<br />
<ng-container *ngIf="covidStatistics.totalLung.percentage != ''">
  <span style="width: 100px; display: inline-block;">{{'Consultation.LungsTotal' | translate}}</span> <strong>{{covidStatistics.totalLung.percentage}}%</strong>
  <span class="light">({{covidStatistics.totalLung.volume}} cm3)</span>
</ng-container>
<br /><br />
<div class="copiedTooltip">
  <button class="btn btn-light btn-sm" rel="noreferrer noopener" style="margin-right: 15px; margin-bottom: 0.5em"
     (click)="openReport()">{{'Consultation.SeeInBrowser' | translate}}</button>
  <a class="btn btn-sm btn-light" [copy-clipboard-button]="covidReportInfo.toCopy" (copied)="copiedInside.emit($event)">{{'Consultation.CopyDescription' | translate}}</a>
  <div *ngIf="copiedText === covidReportInfo.toCopy" class="tooltipContainer" style="top: -30px; right: 60px">
    <span class="tooltip copied" style="transition-delay: 1s">{{'Copied' | translate}}</span>
  </div>
</div>
