<div id="top-bar" style="z-index: 40;">
  <div class="row">
    <div class="col-md-12">
      <h3 class="page-name">{{'DashboardScreen.Exams' | translate}}</h3>

      <ul id="top-bar-nav">
        <li id="All" [ngClass]="{'active': currentId == 'All'}">
          <a (click)="getOrders('All')"
             style="cursor: pointer;">{{'DashboardScreen.All' | translate}}</a>
        </li>
        <li id="NotOrdered" [ngClass]="{'active': currentId == 'NotOrdered'}">
          <a (click)="getOrders('NotOrdered')"
             style="cursor: pointer;">{{'DashboardScreen.NotOrdered' | translate}}</a>
        </li>
        <li id="Ordered" [ngClass]="{'active': currentId == 'Ordered'}">
          <a (click)="getOrders('Ordered')"
             style="cursor: pointer;">{{'DashboardScreen.Ordered' | translate}}</a>
        </li>
      </ul>

      <form>
        <ul class="top-bar-nav">
          <li (click)="dropdown()" class="filter_dropdown">
            <a style="cursor: pointer;"><span *ngIf="filters.filtersActiveFacilityExams" class="dot"></span>
            {{'DashboardScreen.Filters.FilterSort' | translate}}
                <svg-icon alt="" class="svg"
                        src="../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
            </a>
          </li>
          <li>
            <button *ngIf="isImportButton" type="button" class="btn btn-primary mr-3">{{'DashboardScreen.Import' | translate}}</button>
          </li>
        </ul>
        <app-search #search placeholder="SearchPlaceholder"></app-search>
      </form>

    </div>
  </div>
</div>

<app-universal-toast #toast></app-universal-toast>
<app-filters (filter)="filterExams($event);" #filters type="FACILITY_EXAMS"></app-filters>
<app-loader #loader></app-loader>

<app-empty-orders-list [mode]="'MF'" [page]="'exams'" [ordersCount]="facilityExamsService.exams.length" [isLoaderShow]="loader.show" [isSearched]="search.isSearch" [isFiltered]="filters.filtersActiveDashboard || currentId != ''"></app-empty-orders-list>

<table *ngIf="facilityExamsService.exams.length != 0" class="tile-table" id="tile-table">
  <thead>
    <tr>
        <th class="pb-3 pl-2">{{'DashboardScreen.Type' | translate}}</th>
        <th class="pb-3 pl-3">{{'DashboardScreen.AnatomyPart' | translate}}</th>
        <th class="pb-3">{{'OrderDescriptionScreens.ExamDate' | translate}}</th>
        <th class="pb-3">{{'DashboardScreen.Patient' | translate}}</th>
        <th class="pb-3">{{'DashboardScreen.ExamInfo' | translate}}</th>
        <th class="pb-3" style="display: none;">{{'DashboardScreen.Availability' | translate}}</th>
        <th class="pb-3">Zlecenie</th>
        <th class="pb-3">{{'DashboardScreen.AIConsultations' | translate}}</th>
    </tr>
  </thead>
  <ng-container *ngFor="let exam of facilityExamsService.exams; let j = index;">
    <tr class="tile">
      <td>
        <span class="modality">
          {{exam.order_exam_modality || '-'}}
        </span>
      </td>
      <td><span>{{exam.anatomy_part.anatomy_part_text}}</span></td>
      <td>
        {{facilityExamsService.examsDates[j]}}
      </td>

      <td>
        <ng-container *ngIf="exam.first_name">
          <strong>
            {{exam.first_name + ' ' + exam.last_name || 'patientName'}}</strong>
          <strong> ({{exam.sex}})</strong><br/>
          <div class="copiedTooltip" style="display: inline-block;" [clipboard]="exam.pesel" (copied)="copiedText = $event">
            {{exam.pesel || 'pesel'}},
            <div class="tooltipContainer" style="top: -17px;">
              <span *ngIf="copiedText == exam.pesel" class="tooltip copied">{{'Copied' | translate}}</span>
              <span *ngIf="copiedText != exam.pesel" class="tooltip notCopied">{{'CopyText' | translate}}</span>
            </div>
          </div> {{exam.birth_date || '-'}}
          <span *ngIf="exam.birth_date" class="light">
            ({{facilityExamsService.examsAges[j] || 'age'}} {{facilityExamsService.yearsDeclinatedString(facilityExamsService.examsAges[j]) | translate}})
          </span>
        </ng-container>
        <ng-container *ngIf="!exam.first_name">
          <span class="light">{{'DashboardScreen.NoPatientInfo' | translate}}</span>
        </ng-container>
      </td>
      <td>
        <div class="copiedTooltip" style="display: inline-block;" [clipboard]="exam.exam_accession_number" (copied)="copiedText = $event">
          {{exam.exam_accession_number}}
          <div class="tooltipContainer" style="top: -17px;">
            <span *ngIf="copiedText == exam.exam_accession_number" class="tooltip copied">{{'Copied' | translate}}</span>
            <span *ngIf="copiedText != exam.exam_accession_number" class="tooltip notCopied">{{'CopyText' | translate}}</span>
          </div>
        </div>
        <div class="sourceDiv ml-2 px-2">{{exam.pm_info}}</div>
      </td>

      <td width="150">
        <div style="display: inline-block; width: 80px;" class="status-icons-wrapper">
          <ng-container *ngIf="exam.orders_info.exam_order_count > 0">
            <ng-container *ngFor="let order of exam.orders_info.exam_order_info; let i = index;">
              <span class="mr-1 status-icons-item" *ngIf="i < 2">
                <svg-icon *ngIf="order.status_name == 'NEW'
                                || order.status_name == 'TRANSFER_IN_PROGRES'
                                || order.status_name == 'TRANSFER_COMPLETED'"
                  src="../../../../assets/images/icons/icon__exam_order_send.svg">
                </svg-icon>
                <svg-icon *ngIf="order.status_name == 'IN_DESCRIBING_CENTER'"
                  src="../../../../assets/images/icons/icon__exam_order_assigned_to_physician.svg">
                </svg-icon>
                <svg-icon *ngIf="order.status_name == 'IN_DESCRIBING_CENTER_IN_PROGRESS'"
                  src="../../../../assets/images/icons/icon__exam_order_reported.svg">
                </svg-icon>
                <svg-icon *ngIf="order.status_name == 'DESCRIBING_CENTER_DONE'"
                  src="../../../../assets/images/icons/icon__exam_order_result.svg">
                </svg-icon>
                <svg-icon *ngIf="false" src="../../../../assets/images/icons/icon__exam_order_complaint.svg"></svg-icon>
                <svg-icon *ngIf="false" src="../../../../assets/images/icons/icon__exam_order_to_complete.svg"></svg-icon>
                <svg-icon *ngIf="order.status_name == 'TRANSFER_FAILED'
                                || order.status_name == 'DESCRIBING_CENTER_REJECTED'
                                || order.status_name == 'CANCELED'"
                  src="../../../../assets/images/icons/icon__exam_order_canceled.svg">
                </svg-icon>
                <div class="tooltipContainer" style="top: -20px;">
                  <span class="tooltip">
                    <strong>{{'DashboardScreen.OrderStatus' | translate}}: {{order.status}}</strong><br>
                    <p>
                      {{'DashboardScreen.SendedTo' | translate}}: {{order.describing_center_name}}<br>
                      {{'DashboardScreen.OrderDate' | translate}}: {{facilityExamsService.getDateString(order.order_date)}}</p>
                    <p>
                      {{order.order_exam_name}}<br>
                      {{order.icd10}} - {{order.icd10_text}}
                    </p>
                    <div class="row w-100 pr-0 mx-0">
                      <button *ngIf="checkIsEnable(order, 'CANCEL')" class="btn btn-danger mr-2"
                        (click)="makeAction('CANCEL', order.mf_id_exam_order)">{{'DashboardScreen.Cancel' | translate}}</button>
                      <button *ngIf="checkIsEnable(order, 'EDIT_EXAM_ORDER')" class="btn btn-light"
                        (click)="makeAction('EDIT_EXAM_ORDER', order.mf_id_exam_order)">{{'DashboardScreen.EditOrder' | translate}}</button>
                      <button *ngIf="checkIsEnable(order, 'VIEW_EXAM_ORDER')" class="btn btn-light ml-auto"
                        (click)="makeAction('VIEW_EXAM_ORDER', order.mf_id_exam_order)">{{'DashboardScreen.View' | translate}}</button>
                      <button *ngIf="checkIsEnable(order, 'GO_TO_EXAM_ORDER')" class="btn btn-light ml-auto"
                        (click)="makeAction('GO_TO_EXAM_ORDER', order.mf_id_exam_order)">{{'DashboardScreen.ViewOrder' | translate}}</button>
                    </div>
                  </span>
                </div>
              </span>
            </ng-container>
          </ng-container>
          <span *ngIf="exam.orders_info.exam_order_count > 2">
            +{{exam.orders_info.exam_order_count - 2}}
          </span>
          <span *ngIf="exam.orders_info.exam_order_count == 0">
            <a (click)="openCreateOrderModal(exam.id_exam)" class="btn btn-light-blue btn-sm" style="padding: 10px;">
              Utwórz zlecenie
            </a>
          </span>
        </div>
        <button *ngIf="exam.orders_info.exam_order_count != 0" type="button" class="btn btn-light-blue btn-sm card-action"
          style="padding: 8px 10px;" id="examMoreActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="examMoreActions">
          <ng-container *ngFor="let action of exam.exam_actions; let i = index;">
            <ng-container *ngFor="let action of exam.exam_actions; let i = index;">
              <a *ngIf="i < 2" class="dropdown-item" style="cursor: pointer;"
                [ngClass]="{'d-none': action.action_name === 'GOTO_EXAM_ORDERS' && exam.orders_info.exam_order_count == 0}"
                (click)="makeAction(action.action_name, exam.id_exam)">
                Pokaż zlecenia do tego badania
              </a>
            </ng-container>
            <a (click)="openCreateOrderModal(exam.id_exam)" class="dropdown-item" style="cursor: pointer">
              Utwórz kolejne zlecenie
            </a>
            <a (click)="makeAction(action.action_name, exam.id_exam)" class="dropdown-item" *ngIf="i >= 2"
              [ngClass]="{'d-none': action.action_name === 'GOTO_EXAM_ORDERS' && exam.orders_info.exam_order_count == 0}"
              style="cursor: pointer;">
              {{action.button_name}}
            </a>
          </ng-container>
        </div>

      </td>

      <td width="190">
        <ng-container *ngIf="exam.consultation">
          <a *ngIf="exam.consultation.consultation_status == 1" class="btn btn-sm btn-light-blue"
             (click)="makeAction('CREATE_AI_CONSULTATION', undefined, exam)">{{'DashboardScreen.CreateAIOrder' |translate}}</a>
          <span *ngIf="exam.consultation.consultation_status == 2" class="text-muted">W realizacji</span>
          <ng-container *ngIf="exam.consultation.consultation_status == 3">
            <a class="btn btn-covid btn-sm"
            (click)="makeAction('VIEW_AI_CONSULTATION', undefined, exam)">
              <svg-icon *ngIf="exam.consultation.consultation_content.ct_pneumonia.probability > CovidThreshold" class="svg" src="../../../../assets/images/icons/warning_circle.svg" alt=""></svg-icon>
              <svg-icon *ngIf="exam.consultation.consultation_content.ct_pneumonia.probability < CovidThreshold" class="svg" src="../../../../assets/images/icons/ok_circle.svg" alt=""></svg-icon>
              COVID-19 ({{(exam.consultation.consultation_content.ct_pneumonia.probability * 100).toFixed(2)}}%)</a>
          </ng-container>
          <span *ngIf="exam.consultation.consultation_status == 4" class="text-muted">{{'DashboardScreen.ConsultationFailed' |translate}}</span>
        </ng-container>
      </td>

      <td style="display: none;">
        <ng-container *ngIf="exam.mf_exam_status.status_name != 'online'">
          <span class="mr-2">
            <svg-icon *ngIf="exam.mf_exam_status.status_name == 'offline' || exam.mf_exam_status.status_name == 'transfer_error'"
                      src="../../../../assets/images/icons/icon__exam_not_imported.svg"></svg-icon>
            <svg-icon *ngIf="exam.mf_exam_status.status_name == 'transfer'" src="../../../../assets/images/icons/icon__exam_import_in_progress.svg"></svg-icon>
          </span>
          {{exam.mf_exam_status.status_text}}
        </ng-container>
      </td>
    </tr>
  </ng-container>
</table>

<app-pagination #pagination componentName="MedicalFacilityExams" [hidden]="facilityExamsService.exams.length == 0"></app-pagination>

<app-create-order-modal #createOrderModalComponent></app-create-order-modal>
<app-order-info-modal #orderInfoModalComponent></app-order-info-modal>
<app-consultation-modal #consultationModalComponent></app-consultation-modal>
<app-consultation-view-modal #consultationViewModalComponent></app-consultation-view-modal>
