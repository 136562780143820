import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UsersService, OdataService, SessionService } from '@/services';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-select-recipient-async',
    templateUrl: './select-recipient-async.component.html',
    styleUrls: ['./select-recipient-async.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: SelectRecipientAsyncComponent, multi: true }
    ],
})

export class SelectRecipientAsyncComponent implements OnInit, ControlValueAccessor {
    typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
    asyncSelected: string;
    typeaheadLoading: boolean;
    dataSource: Observable<any>;
    userService: UsersService;
    @Input() valid = true;
    private innerValue: number = undefined;
    private changed = new Array<(value: number) => void>();
    private touched = new Array<() => void>();

    constructor(userService: UsersService, private odataService: OdataService, private env: EnvService,
        private sanitizer: DomSanitizer, private sessionService: SessionService) {
        this.userService = userService;
        this.dataSource = new Observable((observer: any) => {
            // Runs on every search
            observer.next(this.asyncSelected);
        })
            .pipe(
                mergeMap((token: string) => this.getDataAsObservable(token)),
                catchError(this.handleError)
            );
    }

    get value(): number {
        return this.innerValue;
    }

    set value(value: number) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.changed.forEach(f => f(value));
        }
    }

    ngOnInit() {
    }

    handleError(error: HttpErrorResponse) {
        console.log(error);
        return of([]);
    }

    getDataAsObservable(search: string): Observable<any> {
        if (search.length < 3) {
            return of<any>([]);
        }

        return this.odataService.postPrivateObservable('get_messages_user_list', this.odataService.paramsTokenAndLang({
            filtr: search
        })).pipe(
            map(data => this.dataMap(data))
        );
    }

    dataMap(res) {
        if (res.Value === '')
            return [];

        let result = JSON.parse(res.Value);

        if (Array.isArray(result)) {
            for (let i = 0; i < result.length; i++) {
                if (!('image_uuid' in result[i]) || !result[i].image_uuid || result[i].image_uuid === '') {
                    result[i]['profile_image'] = '../../assets/images/icons/user.svg';
                } else {
                    result[i]['profile_image'] = this.env.miniProfilePicURL + result[i].image_uuid;
                }
            }
        } else {
            console.log('Error not array', result);
            result = [];
        }

        return result;
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        this.typeaheadIcon = e.item.profile_image;
        this.value = e.item.id_user;
    }

    onValueChange() {
        if (this.value !== undefined) {
            this.typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
            this.value = undefined;
        }
    }

    touch() {
        this.touched.forEach(f => f());
    }

    writeValue(obj: any): void {
        // NIE USUWAĆ to jest potrzebne.
    }

    registerOnChange(fn: (value: number) => void) {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }

    public clear() {
        this.typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
        this.value = undefined;
        this.asyncSelected = '';
    }
}
