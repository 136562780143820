import { SessionService } from './../../../services/session.service';
import { SendMessageToUserModalComponent } from '@/utils/send-message-to-user-modal/send-message-to-user-modal.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Profile, IProfileAction } from '@/interfaces';
import { PageService } from '@/services/page.service';
import { OdataService, UsersService } from '@/services';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { Subscription } from 'rxjs';
import { EnvService } from '@/services/env.service';
import { SearchComponent } from '@/utils/search/search.component';
import { Router } from '@angular/router';
import { InviteToCooperationModalComponent } from '@/utils/invite-to-cooperation-modal/invite-to-cooperation-modal.component';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';

@Component({
    selector: 'app-profiles',
    templateUrl: './profiles.component.html',
    styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit, OnDestroy {
    @ViewChild('inviteToCooperationModal') inviteToCooperationModal: InviteToCooperationModalComponent;
    @ViewChild('actionToast') actionToast: UniversalToastComponent;
    @ViewChild('pagination') pagination: PaginationComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('sendMessage') sendMessage: SendMessageToUserModalComponent;

    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    searchSubscription: Subscription;

    profiles: Profile[] = [];
    countOfVisibleCenters: number[] = [];
    profilesCount: number = 0;
    isOnlyCoworkers: boolean = false;
    copiedText: string = '';

    constructor(private pageService: PageService, private odataService: OdataService,
                private userService: UsersService, public envService: EnvService, private router: Router,
                private sessionService: SessionService) {
    }

    ngOnInit() {
        this.checkCurrentPage();
        this.searchEvent();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();

        let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);

        this.getProfiles(offset, parseInt(this.pagination.itemsOnPage)).then(
            () => {
                this.setNumberOfPages();
            }
        );
        this.pageService.setTranslatedPageTitle('Profiles.Title', 0);
        document.getElementById('doctorsProfileLink').setAttribute('class', 'active');
    }

    ngOnDestroy() {
        document.getElementById('doctorsProfileLink').setAttribute('class', '');
        this.pageService.resetPageTitle();
        this.searchSubscription.unsubscribe();
        this.pageChangeSubscription.unsubscribe();
        this.itemsOnPageChangeSubscription.unsubscribe();
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('Profiles'))
            this.pagination.activePage = this.sessionService.getCurrentPage('Profiles');
        if (this.sessionService.getItemsOnPage('Profiles'))
            this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('Profiles');
        if (sessionStorage.getItem('isOnlyCoworkers')) {
            this.isOnlyCoworkers = true;
            sessionStorage.removeItem('isOnlyCoworkers');
        }
    }

    getOnlyCoworkers() {
        this.getProfiles(0, parseInt(this.pagination.itemsOnPage)).then(
            () => {
                this.setNumberOfPages();
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
            }
        );
    }

    getProfiles(offset: number, limit: number): Promise<any> {
        return this.odataService.postPrivate('get_profile_list',
            this.odataService.paramsTokenAndLang({
                id_describing_center: this.userService.getCenterId(),
                profile_type: 'physicians',
                only_coworkers: this.isOnlyCoworkers,
                offset: offset,
                limit: limit,
                filtr_text: this.search.isSearch ? this.search.filterSearch : ''
            }),
            res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                this.profiles = JSON.parse(result.Value);
                this.countOfVisibleCenters = [];
                for (let i: number = 0; i < this.profiles.length; ++i) {
                    this.countOfVisibleCenters.push(0);
                    this.profiles[i].action_list.sort(this.compareActions);
                }
                this.profilesCount = result.ValueCount;
            });
    }

    compareActions(a1: IProfileAction, a2: IProfileAction): number {
        if (a1.id_action == a2.id_action) return 0;
        return a1.id_action > a2.id_action ? 1 : -1;
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => this.pagination.changePage(page));
    }

    setNumberOfPages() {
        this.pagination.pages = [];
        this.pagination.numberOfPages = this.profilesCount / parseInt(this.pagination.itemsOnPage);
        Math.floor(this.pagination.numberOfPages);
        for (let i = 0; i < this.pagination.numberOfPages; i++) {
            this.pagination.pages.push(i + 1);
        }
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            () => {
                this.getProfiles((this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                    parseInt(this.pagination.itemsOnPage)).then(
                    () => {
                        this.setNumberOfPages();
                        this.pagination.inputPage = this.pagination.activePage + '';
                    }
                );
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            () => {
                this.getProfiles(0, parseInt(this.pagination.itemsOnPage)).then(
                    () => {
                        this.setNumberOfPages();
                        this.pagination.activePage = 1;
                        this.pagination.inputPage = '1';
                    }
                );
            }
        );
    }

    makeAction(profile: Profile, actionName: string): void {
        if (actionName == 'VIEW PROFILE') {
            if (this.isOnlyCoworkers) sessionStorage.setItem('isOnlyCoworkers', 'true');
            this.router.navigate(['/doctorsProfile/' + profile.id_physician]);
        } else if (actionName == 'SEND MESSAGE') {
            this.sendMessage.setUser(profile);
            this.sendMessage.show();
        } else if (actionName == 'PROFILE ACCEPT INVITATION') {
            this.acceptUserAplication(profile.id_physician);
            this.reloadData();
        } else if (actionName == 'PROFILE SEND INVITATION') {
            this.inviteToCooperationModal.show(profile.physician_name, profile.id_physician, this.userService.getCenterName());
        }
    }

    acceptUserAplication(id_user: number): void {
        this.odataService.postPrivate('co_accept_user_application',
            this.odataService.paramsTokenAndLang({
                id_describing_center: this.userService.getCenterId(),
                id_user_to_accept: id_user
            }), res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);

                if (result.Status == 'OK') {
                    let response = JSON.parse(result.Value);
                    if (response.status) this.actionToast.success('OperationSuccess');
                    else this.actionToast.error('OperationFailed');
                } else {
                    this.actionToast.error('OperationFailed');
                }
                this.actionToast.show();
                setTimeout(() => this.actionToast.hide(), 1500);
            });
    }

    afterInvite(sent: boolean) {
        this.reloadData();

        if (sent) this.actionToast.success('OperationSuccess');
        else this.actionToast.error('OperationFailed');
        this.actionToast.show();
        setTimeout(() => this.actionToast.hide(), 1500);
    }

    reloadData(): void {
        this.getProfiles((this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                    parseInt(this.pagination.itemsOnPage)).then(
                    () => {
                        this.setNumberOfPages();
                    });
    }

    getPhysicianDcList(index: number): string {
        let dcListString = '';
        this.setCountOfVisibleCenters(index);
        if (this.countOfVisibleCenters[index] == 0) {
            return dcListString;
        } else {
            for (let i = 0; i < this.countOfVisibleCenters[index]; i++) {
                dcListString += this.profiles[index].working_places[i].describing_center_name;
                if (i < this.countOfVisibleCenters[index] - 1) {
                    dcListString += ', ';
                }
                if (i == this.countOfVisibleCenters[index] - 1 && this.countOfVisibleCenters[index] < this.profiles[index].working_places.length) {
                    dcListString += ', ...';
                }
            }
        }
        return dcListString;
    }

    setCountOfVisibleCenters(i: number) {
        this.countOfVisibleCenters[i] = 0;
        if (this.profiles[i].working_places.length != 0) {
            let finalWidth = 0;
            document.getElementById('hiddenDcString' + this.profiles[i].id_physician).setAttribute('style', 'display: flex');
            for (let j = 0; j < this.profiles[i].working_places.length; j++) {
                finalWidth += document.getElementById('physicianDc' + this.profiles[i].id_physician + j).offsetWidth;
                if (finalWidth < document.getElementById('physicianDcList' + this.profiles[i].id_physician).offsetWidth) {
                    this.countOfVisibleCenters[i]++;
                } else {
                    break;
                }
            }
            document.getElementById('hiddenDcString' + this.profiles[i].id_physician).setAttribute('style', 'display: none');
        }
    }
}
