import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INotification } from '@/dm/notifications/inotification';
import {
  UsersService,
  NavigationService,
  OdataService,
  SessionService,
  NotificationsService,
  DisplayService,
  OrderActionsService
} from '@/services';
import { element } from '@angular/core/src/render3';
import { PageService } from '@/services/page.service';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { Subscription } from 'rxjs';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})

export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('pagination') pagination: PaginationComponent;
    @ViewChild('actionToast') actionToast: UniversalToastComponent;

    notificationsCount: number;
    notifications: INotification[];
    odataService: OdataService;
    private sub_route: any;
    private sub_counter: any;
    private sub_action: any;
    notifications_categories = [];
    categories_id: number = undefined;
    categories_text: string = undefined;

    navigationService: NavigationService;

    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    requestPage: number = 1;

    SETTINGS_COLUMN: string[] = ['notification_setttings_panel', 'notification_setttings_email', 'notification_setttings_sms'];
    notifications_settings = [];

    constructor(private router: Router, private userService: UsersService, odataService: OdataService, private orderActions: OrderActionsService,
        private route: ActivatedRoute, private sessionService: SessionService, private notificationsService: NotificationsService, private pageService: PageService, navigationService: NavigationService) {
        this.odataService = odataService;
        this.navigationService = navigationService;
    }

    ngOnInit() {

        DisplayService.createSidebar('sticky_sidebar', 'main_content', 'inner_wrapper');

        this.notificationsCount = 0;
        this.notifications = [];

        this.sub_action = this.notificationsService.notificationsMainEvent.subscribe(
            (value: { id_notification: number, action: string }) => { this.processAction(value); });

        this.sub_counter = this.notificationsService.notificationsCountEvent
            .subscribe((value: number) => { this.notificationsCount = value; });

        this.notificationsService.realoadList();

        this.checkCurrentPage();
        this.changePageEvent();
        this.itemsOnPageChangeEvent();

        this.sub_route = this.route.params.subscribe(params => {
            if ('id_notification' in params) {
                const notification_id = +params['id_notification'];

                if (this.pagination.activePage != 1) {
                    this.pagination.activePage = 1;
                    this.pagination.inputPage = '1';
                    this.requestPage = 1;
                    this.notificationsService.realoadList();
                }

                this.scroll(notification_id);

                for (let i = 0; i < this.notifications.length; i++) {
                    if (this.notifications[i].id_notification === notification_id) {
                        if (!this.notifications[i].is_read && this.notificationsCount > 0) {
                            this.notifications[i].is_read = true;
                            this.notificationsService.updateCount(this.notificationsCount - 1);
                            this.notificationsService.mainReadState(notification_id, true);
                        }
                    }
                }
            }
        });
        this.pageService.setTranslatedPageTitle('Notifications.Notifications', 0);

        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add('pp-portal');
        }

    }

    ngOnDestroy() {
        this.sub_route.unsubscribe();
        this.sub_counter.unsubscribe();
        this.sub_action.unsubscribe();
        this.pageService.resetPageTitle();

        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');
    }

    getNotificationList() {
        this.odataService.postPrivate('get_notifications_for_user',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                offset: (this.requestPage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                limit: parseInt(this.pagination.itemsOnPage),
                notifications_types: this.categories_id === undefined ? [] : [this.categories_id]
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let notifications: INotification[];

                    if (result.Value && result.Value !== '') {
                        const value = JSON.parse(result.Value);

                        if ('jsonValue' in value) {
                            notifications = value.jsonValue;
                        }

                        if ('total' in value) {
                            let total = value.total;
                            this.pagination.pages = [];
                            this.pagination.numberOfPages = total / parseInt(this.pagination.itemsOnPage);
                            Math.floor(this.pagination.numberOfPages);

                            for (let i = 0; i < this.pagination.numberOfPages; i++) {
                                this.pagination.pages.push(i + 1);
                            }
                        }
                    }

                    this.notifications = notifications;
                    this.pagination.inputPage = this.requestPage.toString();
                    this.pagination.activePage = this.requestPage;
                } else {
                    this.requestPage = parseInt(this.pagination.inputPage);
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    getNotificationsCategories() {
        this.odataService.postPrivate('get_notifications_categories',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    if (result.Value && result.Value !== '') {
                        const value = JSON.parse(result.Value);
                        this.notifications_categories = value;
                    }
                } else {
                    console.log('Error: ', result.Status);
                }
            });
    }

    onRemove(id_notification_value: number) {
        this.odataService.postPrivate('notification_delete', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_notification: id_notification_value
        },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    for (let i = 0; i < this.notifications.length; i++) {
                        if (this.notifications[i].id_notification === id_notification_value) {
                            if (!this.notifications[i].is_read && this.notificationsCount > 0) {
                                this.notificationsService.updateCount(this.notificationsCount - 1);
                            }

                            this.notifications.splice(i, 1);
                            this.notificationsService.mainRemoved(id_notification_value);
                            return;
                        }
                    }
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            }
        );
    }

    onTogRead(notification_id: number, readed_value: boolean) {
        this.odataService.postPrivate('notification_read',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                readed: readed_value,
                id_notification: notification_id
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    for (let i = 0; i < this.notifications.length; i++) {
                        if (this.notifications[i].id_notification === notification_id) {
                            if (this.notifications[i].is_read !== readed_value) {
                                if (readed_value) {
                                    if (this.notificationsCount > 0) {
                                        this.notificationsService.updateCount(this.notificationsCount - 1);
                                    }
                                } else {
                                    this.notificationsService.updateCount(this.notificationsCount + 1);
                                }
                            }

                            this.notifications[i].is_read = readed_value;
                            this.notificationsService.mainReadState(notification_id, readed_value);
                            this.notificationsService.realoadList();
                            return;
                        }
                    }
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            });
    }

    onClearAll() {
        const notifications_list: number[] = [];

        for (let i = 0; i < this.notifications.length; i++) {
            notifications_list.push(this.notifications[i].id_notification);
        }

        this.odataService.postPrivate('delete_all_notifications',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                ids: notifications_list
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    this.notificationsService.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            });
    }

    onMarkAllAsRead() {
        const notifications_list: number[] = [];

        for (let i = 0; i < this.notifications.length; i++) {
            notifications_list.push(this.notifications[i].id_notification);
        }

        this.odataService.postPrivate('set_all_notification_read',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                ids: notifications_list
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    this.notificationsService.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            });
    }

    processAction(value: { id_notification: number, action: string }) {
        if (value.action === 'reload') {
            this.getNotificationList();
            return;
        }

        for (let i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].id_notification === value.id_notification) {
                switch (value.action) {
                    case 'remove':
                        this.notifications.splice(i, 1);
                        break;
                    case 'read':
                        this.notifications[i].is_read = true;
                        break;
                    case 'unread':
                        this.notifications[i].is_read = false;
                        break;
                    default:
                        break;
                }
            }
        }
    }

    scroll(id: number): void {
        DisplayService.scroll('section-' + id);
    }

    onCategoriesSelect(id: number, text: string): void {
        console.log(id, text);
        if (id !== this.categories_id) {
            if (id) {
                this.categories_text = ': ' + text;
                this.categories_id = id;
            } else {
                this.categories_text = undefined;
                this.categories_id = undefined;
            }
            this.notificationsService.realoadList();
        }
    }

    co_exam_action_start_descrption = (describing_center: number, exam_order: number) => {
        if (describing_center !== this.userService.getCenterId()) {
            // TODO: Dodać komunikat o tym że wybraliśmy badanie które nie jest z tego centrum opisowego.
            console.log('Pruba opisu badania z niewłaściwego centrum opisowego');
            return;
        }

        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: describing_center,
            id_exam_order: exam_order
        };

        this.odataService.postPrivate('co_exam_action_start_descrption', params,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    this.router.navigate(['/orderDescription/currentDescription']);
                } else {
                    // TODO:
                }
            });
    }

    makeAction(n_object: INotification, action_name: string) {
        switch (action_name) {
            case 'START_DESCRIPTION':
                if ('notification_params' in n_object) {
                    const describing_center: number = n_object.notification_params.id_describing_center;
                    const exam_order: number = n_object.notification_params.id_exam_order;

                    this.co_exam_action_start_descrption(describing_center, exam_order);
                }
            break;
            case 'NOTIFICATION REJECT INVITATION':
                if ('notification_params' in n_object && 'id_invitation' in n_object.notification_params) {
                    this.replyToInvitation(n_object.notification_params.id_invitation, false);
                }
            break;
            case 'NOTIFICATION ACCEPT INVITATION':
                if ('notification_params' in n_object && 'id_invitation' in n_object.notification_params) {
                    this.replyToInvitation(n_object.notification_params.id_invitation, true);
                }
            break;
            case 'VIEW EXAM':
                console.log('VIEW EXAM', n_object.notification_params);
                if ('id_exam_order' in n_object.notification_params) {
                    sessionStorage.setItem('mode', 'view');
                    this.orderActions.cameFrom = 'notifications';
                    this.router.navigate(['/orderDescription/' + n_object.notification_params.id_exam_order + '/currentDescription'], { queryParams: { mode: 'view' } });
                }
            break;
            default:
                console.log('Sorry, no ' + action_name + ' on list.');
            // TODO:
        }
    }

    replyToInvitation(id_invitation: number, accept: boolean): void {
        this.odataService.postPrivate('process_co_invitation',
            this.odataService.paramsTokenAndLang({
                id_invitation: id_invitation,
                status: accept
            }), res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);

                if (result.value == 'OK') {
                    this.actionToast.success('OperationSuccess');
                    this.notificationsService.realoadList();

                    if (accept)
                        this.realoadPermissions ();
                } else {
                    this.actionToast.error('OperationFailed');
                }
                this.actionToast.show();
                setTimeout(() => this.actionToast.hide(), 1500);
            });
    }

    realoadPermissions(): void {
        console.log('realoadPermissions');
        window.location.reload();
    }

    setColumnHeader(id: number, value: boolean) {
        const doc_element = (<HTMLInputElement>document.getElementById('settingsColumn' + id));

        if (doc_element.checked != value)
            doc_element.checked = value;
    }

    setRowHeader(id: number, value: boolean) {
        let doc_element = (<HTMLInputElement>document.getElementById('settingsRow' + id));

        if (doc_element.checked != value)
            doc_element.checked = value;
    }

    columnAllSet(id: number): boolean {
        for (let i = 0; i < this.notifications_settings.length; ++i) {
            if (!(<HTMLInputElement>document.getElementById('settingsCell' + i + '' + id)).checked) {
                return false;
            }
        }

        return true;
    }

    rowAllSet(id: number): boolean {
        for (let i = 0; i < this.SETTINGS_COLUMN.length; ++i) {
            if (!(<HTMLInputElement>document.getElementById('settingsCell' + id + '' + i)).checked) {
                return false;
            }
        }

        return true;
    }

    setSettingsForm() {
        for (let row_id = 0; row_id < this.notifications_settings.length; row_id++) {
            for (let key of Object.keys(this.notifications_settings[row_id].settings)) {
                let column_id: number = this.SETTINGS_COLUMN.indexOf(key);

                if (column_id >= 0) {
                    this.onSettingsCell(row_id, column_id);
                }
            }
        }
    }

    setFormColumns() {
        for (let column_id = 0; column_id < this.SETTINGS_COLUMN.length; column_id++) {
            const key = this.SETTINGS_COLUMN[column_id];
            let checked = true;

            for (let row_id = 0; row_id < this.notifications_settings.length; row_id++) {
                if (!(key in this.notifications_settings[row_id].settings) || !(this.notifications_settings[row_id].settings[key])) {
                    checked = false;
                    break;
                }
            }

            const doc_element = (<HTMLInputElement>document.getElementById('settingsColumn' + column_id));

            if (doc_element.checked != checked)
                doc_element.checked = checked;
        }
    }

    addRowData(data: any[]): any[] {
        for (let row_id = 0; row_id < data.length; row_id++) {
            let checked = true;
            for (let key of this.SETTINGS_COLUMN) {
                if (!(key in data[row_id].settings) || !(data[row_id].settings[key])) {
                    checked = false;
                    break;
                }
            }

            data[row_id]['row_checked'] = checked;
        }

        return data;
    }

    collectSettingsData(): any[] {
        let data: any[] = [];

        for (let row_id = 0; row_id < this.notifications_settings.length; row_id++) {
            let row_data = {
                'settings': {},
                'id_notification_type': this.notifications_settings[row_id].id_notification_type
            };
            for (let column_id = 0; column_id < this.SETTINGS_COLUMN.length; column_id++) {
                const key = this.SETTINGS_COLUMN[column_id];
                let checked: boolean = (<HTMLInputElement>document.getElementById('settingsCell' + row_id + '' + column_id)).checked;
                row_data.settings[key] = checked;
            }
            data.push(row_data);
        }

        return data;
    }

    onSettings() {
        this.odataService.postPrivate('get_user_notification_settings',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    if (result.Value && result.Value !== '') {
                        const value = JSON.parse(result.Value);
                        value.sort((n1, n2) => {
                            if (n1.id_notification_type > n2.id_notification_type)
                                return 1;
                            if (n1.id_notification_type < n2.id_notification_type)
                                return -1;
                            return 0;
                        });
                        this.notifications_settings = this.addRowData(value);
                    }
                    this.setFormColumns();
                    $('#ustawieniaPowiadomien').modal('show');
                } else {
                    // TODO: reason, incorect_contex, insufficent_rights
                    console.log('REASON:', result.Status);
                }
            });
    }

    onSettingsRestoreDefaults() {
        this.odataService.postPrivate('get_user_notification_settings_defaults',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    if (result.Value && result.Value !== '') {
                        const value = JSON.parse(result.Value);
                        value.sort((n1, n2) => {
                            if (n1.id_notification_type > n2.id_notification_type)
                                return 1;
                            if (n1.id_notification_type < n2.id_notification_type)
                                return -1;
                            return 0;
                        });
                        this.notifications_settings = this.addRowData(value);
                    }
                    this.setFormColumns();
                } else {
                    // TODO: reason, incorect_contex, insufficent_rights
                    console.log('REASON:', result.Status);
                }
            });
    }

    onSettingsSave() {
        const notification_settings = this.collectSettingsData();

        this.odataService.postPrivate('save_user_notification_settings',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                notification_settings: JSON.stringify(notification_settings)
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    $('#ustawieniaPowiadomien').modal('hide');
                } else {
                    // TODO: reason, incorect_contex, insufficent_rights
                    console.log('REASON:', result.value);
                }
            });
    }

    onSettingsCell(row_id: number, column_id: number) {
        let checked: boolean = (<HTMLInputElement>document.getElementById('settingsCell' + row_id + '' + column_id)).checked;

        if (checked) {
            if (this.columnAllSet(column_id))
                this.setColumnHeader(column_id, true);
            if (this.rowAllSet(row_id))
                this.setRowHeader(row_id, true);
        } else {
            this.setColumnHeader(column_id, checked);
            this.setRowHeader(row_id, checked);
        }
    }

    onSettingsColumn(column_id: number) {
        let checked: boolean = (<HTMLInputElement>document.getElementById('settingsColumn' + column_id)).checked;

        if (checked) {
            for (let i = 0; i < this.notifications_settings.length; ++i) {
                (<HTMLInputElement>document.getElementById('settingsCell' + i + '' + column_id)).checked = checked;
                if (this.rowAllSet(i))
                    this.setRowHeader(i, true);
            }
        } else {
            for (let i = 0; i < this.notifications_settings.length; ++i) {
                (<HTMLInputElement>document.getElementById('settingsCell' + i + '' + column_id)).checked = checked;
                this.setRowHeader(i, false);
            }
        }
    }

    onSettingsRow(row_id: number) {
        let checked: boolean = (<HTMLInputElement>document.getElementById('settingsRow' + row_id)).checked;

        if (checked) {
            for (let i = 0; i < this.SETTINGS_COLUMN.length; ++i) {
                (<HTMLInputElement>document.getElementById('settingsCell' + row_id + '' + i)).checked = checked;
                if (this.columnAllSet(i))
                    this.setColumnHeader(i, true);
            }
        } else {
            for (let i = 0; i < this.SETTINGS_COLUMN.length; ++i) {
                (<HTMLInputElement>document.getElementById('settingsCell' + row_id + '' + i)).checked = checked;
                this.setColumnHeader(i, false);
            }
        }
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('Notifications'))
            this.pagination.activePage = this.sessionService.getCurrentPage('Notifications');
        if (this.sessionService.getItemsOnPage('Notifications'))
            this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('Notifications');
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            (res) => {
                this.requestPage = res;
                this.notificationsService.realoadList();
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            (res) => {
                this.requestPage = 1;
                this.notificationsService.realoadList();
            }
        );
    }
}
