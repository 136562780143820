<div aria-hidden="true" aria-labelledby="addFilterModalLabel" class="modal fade" id="addFilterModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex flex-column">
        <h3 class="modal-title d-block"><strong>{{'AddFilterModal.Title' | translate}} {{'"' + filterType + '"'}}</strong></h3>
        <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-3">
        <label for="roleDescription">{{'AddFilterModal.FilterName' | translate}}</label>
        <input type="text" class="form-control" [(ngModel)]="filterName" id="roleDescription">
        <div class="custom-control custom-checkbox mt-3" id="wymagajace">
          <input type="checkbox" class="custom-control-input" id="customCheck1" [(ngModel)]="isFavourite" [checked]="isFavourite">
          <label class="custom-control-label" for="customCheck1">{{'DashboardScreen.Filters.AddToFavourites' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer pt-3">
        <button class="btn btn btn-danger" type="button" (click)="hide()">
          {{'AddFilterModal.Cancel' | translate}}
        </button>
        <button class="btn btn btn-primary" type="button" (click)="save()">
          {{'AddFilterModal.Save' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
