import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-aside',
  templateUrl: './card-aside.component.html',
  styleUrls: ['./card-aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAsideComponent {
  @Input() cardClass: string = '';
  @Input() cardStyle: string = '';
}


