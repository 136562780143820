<div id="top-bar" class="col-md-12 w-100">
    <div class="row">
        <div class="col-md-12 pl-0 pr-0">
            <a style="cursor: pointer" class="back" (click)="back()" routerLinkActive="router-link-active">
                <svg-icon src="../../../../assets/images/icons/topbar/icon__arrow_left.svg" class="svg" alt=""></svg-icon>
            </a>
            <h3 class="page-name order-description">

                {{descriptionService.orderDescription.patient.name || '-'}}
                <span>({{descriptionService.orderDescription.patient.documentId}})</span>
                <small>
                    ur. {{descriptionService.orderDescription.patient.birthdate || '-'}},
                    {{descriptionService.orderDescription.patient.sex || ''}}
                    ({{descriptionService.orderDescription.patient.age}}
                    {{ordersService.yearsDeclinatedString(descriptionService.orderDescription.patient.age) | translate}})
                </small>

            </h3>

          <ng-container *ngIf="descriptionService.isOrderComparisonViewPermission()">
            <ul id="top-bar-nav" class="ml-auto my-auto" style="padding-top: 23px;">
                <li id="currentDescription"
                      [ngClass]="{'active': currentRoute == 'currentDescription?mode=view' || currentRoute == 'currentDescription?mode=description'}">
                      <a (click)="goToDesc()"
                         style="cursor: pointer">{{'OrderDescriptionScreens.CurrentDescription' | translate}}</a>
                  </li>
                  <li id="orderDescriptionComparison" style="border: 0px none"
                      [ngClass]="{'active': currentRoute == 'orderDescriptionComparison?mode=view' || currentRoute == 'orderDescriptionComparison?mode=description'}">
                      <a (click)="navigateToComparasion()" style="cursor: pointer" [style.pointer-events]="ordersCount == 0 ? 'none' : 'all'">
                        {{'OrderDescriptionScreens.OtherPatientsOrders' | translate}}&nbsp;({{ordersCount}})</a>
                  </li>
              </ul>
          </ng-container>

        </div>
    </div>

</div>
<div class="content-wrap">
  <div class="w-25 mw-400 mnw-320 mr-5">
    <ng-container *ngIf="descriptionService.orderDescription.exam_info.consultation
    && descriptionService.orderDescription.exam_info.consultation.consultation_time != ''">
      <app-card-aside cardClass="mb25">
        <app-covid-card-content
          [covidStatistics]="covidStatistics"
          [covidReportInfo]="{
              browserLink: descriptionService.orderDescription.exam_info.consultation.consultation_content.browser_url,
              consultationDate: ordersService.getDateString(descriptionService.orderDescription.exam_info.consultation.consultation_time),
              toCopy: descriptionService.orderDescription.exam_info.consultation.consultation_content.ai_report
            }"
          [copiedText]="copiedText"
          (copiedInside)="copiedText = $event"
        >
        </app-covid-card-content>
      </app-card-aside>
    </ng-container>
    <app-card-aside [cardClass]="cssCardColor()">
      <app-order-info-card-content
        [orderInfoInput]="descriptionService.orderDescription"
        (showAttachment)="showAttachment($event)"
      >
      </app-order-info-card-content>
    </app-card-aside>
  </div>
  <div class="w-75">
    <router-outlet></router-outlet>
  </div>
</div>




<app-attachment-view-modal #attachmentViewModalComponent></app-attachment-view-modal>
<app-save-exam-modal #saveExamModal></app-save-exam-modal>
