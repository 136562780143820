import { Router } from '@angular/router';
import { EnvService } from '@/services/env.service';
import { MessageSenderService } from './../../../../services/message-sender.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IMessage } from '@/dm/messages/imessage';
import { UsersService, OdataService, SessionService, MessagesService } from '@/services';

@Component({
  selector: 'app-dd-message-button',
  templateUrl: './dd-message-button.component.html',
  styleUrls: ['./dd-message-button.component.css']
})
export class DdMessageButtonComponent implements OnInit, OnDestroy {
    @Input() type: string;
    messages: IMessage[];
    messagesList = true;
    private sub_action: any;
    update_noExtended = true;

    constructor(public userService: UsersService, public odataService: OdataService, public messageSender: MessageSenderService,
            public messagesService: MessagesService, private env: EnvService, private router: Router) { }

    ngOnInit() {
        this.messages = [];
        this.sub_action = this.messagesService.messagesShortEvent.subscribe(
        (value: { id_message: number, action: string }) => {
            this.processAction(value);
        });

        this.messagesService.updateMessagesCount ();
        this.getShortMessageList();
        this.messagesService.starTimer ();
    }

    ngOnDestroy() {
        this.messagesService.stopTimer ();
        this.sub_action.unsubscribe();
    }

    getShortMessageList() {
        if (!this.update_noExtended) {
            return;
        }

        this.odataService.postPrivate('get_short_message_list',
        this.odataService.paramsTokenAndLang({}),
        res => {
            const returnedJson = JSON.stringify(res);
            const result = JSON.parse(returnedJson);

            if (result.Status === 'OK') {
                if (result.Value !== '') {
                    const value = JSON.parse(result.Value);
                    if ('jsonValue' in value) {
                        this.messages = value.jsonValue;
                    }
                }
            }
        });
  }

    onNewMessage (event: any) {
        event.stopPropagation();
        this.messagesList = false;
        this.messageSender.setDefaultData();
    }

    onReturnMessages (event: any) {
        event.stopPropagation();
        this.messagesList = true;
        this.messageSender.setDefaultData();
    }

    onHidden () {
        this.messagesList = true;
    }

    checkIfRedirect() {
        if (this.env.redirectToMessagesPage) this.router.navigate(['/messages']);
        else this.messagesService.updateMessagesCount();
    }

    onSubmit (form: NgForm) {
        this.messageSender.onSubmit(form.value.id_user, form.value.message_text).then((res) => {
            if (res) {
                this.messagesList = true;
                this.messagesService.realoadList();
            } else {
                // TODO:
                console.log('FAILED');
            }
        });
    }

    onRemove (message_id: number) {
        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id_message === message_id) {
                if (!this.messages[i].message_read && this.messagesService.messagesCount > 0) {
                    this.messagesService.updateCount(this.messagesService.messagesCount - 1);
                    this.messagesService.shortReaded(this.messages[i].id_user_from);
                }

                this.messages.splice(i, 1);
                this.messagesService.shortRemoved(message_id);
                break;
            }
        }

        this.messagesService.realoadList();
    }

    onRead (message_id: number) {
        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id_message === message_id) {
                this.messagesService.shortReaded(this.messages[i].id_user_from);
                return;
            }
        }
    }

    onExtend (extended: boolean) {
        this.update_noExtended = !extended;
    }

    processAction(value: { id_message?: number, id_user?: number, action: string }) {
        if (value.action === 'reload') {
            this.getShortMessageList();
            return;
        }

        if (value.action === 'read' && 'id_user' in value) {
            let update_count: number = 0;

            for (let i = 0; i < this.messages.length; i++) {
                if (this.messages[i].id_user_from == value.id_user && this.messages[i].message_read == false) {
                    this.messages[i].message_read = true;
                    update_count ++;
                }
            }

            if (update_count > 0) {
                if (update_count > this.messagesService.messagesCount) {
                    this.messagesService.updateCount(0);
                    return;
                }

                if (this.messagesService.messagesCount > 0) {
                    this.messagesService.updateCount(this.messagesService.messagesCount - 1);
                }
            }

            return;
        }

        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id_message === value.id_message) {
                switch (value.action) {
                    case 'remove':
                        this.messages.splice(i, 1);
                        break;
                    case 'unread':
                        this.messages[i].message_read = false;
                        break;
                    default:
                        break;
                }
            }
        }
    }
}
