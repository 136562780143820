import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertComponent } from '../alert/alert.component';
import moment from 'moment';

@Component({
    selector: 'app-reject-modal',
    templateUrl: './reject-modal.component.html',
    styleUrls: ['./reject-modal.component.css']
})
export class RejectModalComponent implements OnInit {
    @ViewChild('alert') alert: AlertComponent;
    promiseResolve: Function;
    promiseReject: Function;
    id: number;
    patientName: string;
    orderDate: string;
    orderName: string;
    anatomyPart: string;
    isCancelAssigment: boolean = false;
    isRejectedByPhysician: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }

    show(order, isCancelAssigment?: boolean, isRejectedByPhysician?: boolean): void {
        if (isRejectedByPhysician) {
          this.isRejectedByPhysician = true;
        }
        if (isCancelAssigment) {
            this.isCancelAssigment = true;
        } else this.isCancelAssigment = false;
        this.id = order.id_exam_order;
        this.patientName = order.exam_info.patient_name;
        if (moment(order.order_date, 'DD.MM.YYYY HH:mm', true).isValid()) this.orderDate = order.order_date;
        else this.orderDate = moment(order.order_date).format('DD.MM.YYYY HH:mm');
        this.orderName = order.exam_info.ordering_facility_name;
        this.anatomyPart = order.exam_info.anatomy.part_name;
        this.alert.active = false;
        (<HTMLInputElement>document.getElementById('rejection')).value = '';
        $('#rejectModal').modal('show');
    }

    hide(): void {
        $('#rejectModal').modal('hide');
    }

    setPromiseResolve(func: Function): void {
        this.promiseResolve = func;
    }

    setPromiseReject(func: Function): void {
        this.promiseReject = func;
    }

    resolvePromise(): void {
        let value = (<HTMLInputElement>document.getElementById('rejection')).value;
        this.promiseResolve(value);
    }
}
