import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  dummyOrderDescription,
  IAttachment,
  OrderDescription,
} from '@/interfaces';

// ----------------------------------------- -------
// ---- 29.06.21 breaking down big components ------
// -------------------------------------------------

@Component({
  selector: 'app-order-info-card-content',
  templateUrl: './order-info-card-content.component.html',
  styleUrls: ['./order-info-card-content.component.scss']
})
export class OrderInfoCardContentComponent {
  @Input() orderInfoInput: OrderDescription = dummyOrderDescription;
  @Output() showAttachment: EventEmitter<IAttachment> = new EventEmitter<IAttachment>();

  isDescArrayEmpty(array: string[]): boolean {
    return !!array.find(e => e != '');
  }

}
