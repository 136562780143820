import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reopen-exam-toast',
    templateUrl: './reopen-exam-toast.component.html',
    styleUrls: ['./reopen-exam-toast.component.scss']
})
export class ReopenExamToastComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    hide(): void {
        $('#reopen-exam-toast').toast('hide');
    }

    show(): void {
        $('#reopen-exam-toast').toast('show');
    }
}
