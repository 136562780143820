import { IFacilityOrderDetails, initFacilityOrderDetails } from './../../interfaces/MedicalFacility';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EnvService } from '@/services/env.service';
import { AttachmentViewModalComponent } from '@/utils/attachment-view-modal/attachment-view-modal.component';
import {IAttachment} from '@/interfaces';
import {FacilityOrdersService} from '@/services/facility-orders.service';

@Component({
    selector: 'app-order-info-modal',
    templateUrl: './order-info-modal.component.html',
    styleUrls: ['./order-info-modal.component.scss']
})
export class OrderInfoModalComponent implements OnInit {
  @ViewChild('attachmentViewModal') attachmentDialog: AttachmentViewModalComponent;

    orderInfo: IFacilityOrderDetails = initFacilityOrderDetails;

    constructor(public envService: EnvService, public facilityOrdersService: FacilityOrdersService) { }

    ngOnInit() {
    }

    attachment_view(attachment: IAttachment) {
      this.attachmentDialog.show(attachment);
    }

    show(orderInfo: IFacilityOrderDetails) {
      this.orderInfo = orderInfo;
      $('#orderInfoModal').modal('show');
    }
}
