<div aria-hidden="true"class="modal fade"
     role="dialog"
     tabindex="-1" id="consultationViewModal"
     aria-labelledby="consultationViewModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header mb-4">
        <h2 class="modal-title">{{'Consultation.ConsultationTitle' | translate}} -
          <ng-container *ngIf="orderInput">{{(order.exam_info.consultation.consultation_content.ct_pneumonia.probability * 100).toFixed(2)}}%</ng-container>
          <ng-container *ngIf="!orderInput">{{(exam.consultation.consultation_content.ct_pneumonia.probability * 100).toFixed(2)}}%</ng-container></h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-self-center align-items-center justify-content-between">
          <div *ngIf="!orderInput" style="width: 50%">
            <strong>{{'Consultation.Consultation' | translate}}</strong><br>
            {{'Consultation.Date' | translate}} {{facilityOrdersService.getDateString(exam.consultation.consultation_start)}} <br>
            <br />
            <strong>{{exam.order_exam_modality}} {{exam.anatomy_part.anatomy_part_text}} {{exam.his_exam_id}}</strong><br>
            {{'Consultation.ExamDate' | translate}} {{facilityOrdersService.getDateString(exam.exam_date)}}<br>
            {{exam.first_name}} {{exam.last_name}} ({{exam.sex}})<br>
            PESEL: {{exam.pesel}}<br>
          </div>
          <div *ngIf="orderInput" style="width: 50%">
            <strong>{{'Consultation.Consultation' | translate}}</strong><br>
            {{'Consultation.Date' | translate}} {{facilityOrdersService.getDateString(order.exam_info.consultation.consultation_start)}} <br>
            <br />
            <strong>{{order.exam_info.modality.modality}} {{order.exam_info.anatomy.part_name}} {{order.exam_info.his_exam_id}}</strong><br>
            {{'Consultation.ExamDate' | translate}} {{facilityOrdersService.getDateString(order.exam_info.exam_date)}}<br>
            {{order.exam_info.patient_name}} ({{order.exam_info.sex}})<br>
            PESEL: {{order.exam_info.pesel}}<br>
          </div>
          <div style="width: 50%">
            <ng-container *ngIf="stats.RUL.percentage != ''">RUL {{stats.RUL.percentage}}% ({{stats.RUL.volume}} cm3)<br></ng-container>
            <ng-container *ngIf="stats.RML.percentage != ''">RML {{stats.RML.percentage}}% ({{stats.RML.volume}} cm3)<br></ng-container>
            <ng-container *ngIf="stats.RLL.percentage != ''">RLL {{stats.RLL.percentage}}% ({{stats.RLL.volume}} cm3)<br></ng-container>
            <ng-container *ngIf="stats.LUL.percentage != ''">LUL {{stats.LUL.percentage}}% ({{stats.LUL.volume}} cm3)<br></ng-container>
            <ng-container *ngIf="stats.LLL.percentage != ''">LLL {{stats.LLL.percentage}}% ({{stats.LLL.volume}} cm3)<br></ng-container>
            <br />
            <ng-container *ngIf="stats.totalLung.percentage != ''">
              {{'Consultation.LungsTotal' | translate}} {{stats.totalLung.percentage}}% ({{stats.totalLung.volume}} cm3) <br>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-4" *ngIf="!orderInput">
        <a class="btn btn-light btn-md" target="_blank" rel="noopener norefferer" [href]="exam.consultation.consultation_content.browser_url">{{'Consultation.SeeInBrowser' | translate}}</a>
        <button class="btn btn-md btn-primary" (click)="onCreateOrder()">{{'Consultation.CreateOrder' | translate}}</button>
      </div>
    </div>
  </div>
</div>
