import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    UsersService, OdataService, Timer, DisplayService,
    SessionService, CookiesService, OrdersService
} from '@/services';
import { Router } from '@angular/router';
import { RegistrationService } from '@/services/registration.service';
import { PageService } from '@/services/page.service';

import { BsDatepickerInlineConfig } from 'ngx-bootstrap/datepicker/bs-datepicker-inline.config';
import { PortfolioService } from '@/services/portfolio.service';
import { EnvService } from '@/services/env.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '@/utils/alert/alert.component';

@Component({
    selector: 'app-unlock-screen',
    templateUrl: './unlock-screen.component.html',
    styleUrls: ['./unlock-screen.component.css']
})
export class UnlockScreenComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlert') errorAlert: AlertComponent;

    unlockSession: {
        token: string,
        session_id: string,
        password: string
    } = {
            token: '',
            session_id: '',
            password: ''
        };
    isError = false;
    minutes: number;
    seconds: number;
    remainingTime: string = '';
    timer: Timer;
    timeout;
    language = '';
    imageUrl: string = '';
    username: string = '';
    isValidAvatar: boolean = true;

    date = new Date();
    dp_config: Partial<BsDatepickerInlineConfig> = {};
    isOpen: boolean = false;

    constructor(private userService: UsersService, private odata: OdataService,
        private router: Router, private sessionService: SessionService,
        private registrationService: RegistrationService,
        private pageService: PageService, private cookiesService: CookiesService,
        private portfolioService: PortfolioService, private ordersService: OrdersService,
        private env: EnvService, private translate: TranslateService) {
    }

    ngOnInit() {
        this.unlockSession.session_id = this.sessionService.getSessionId();
        this.unlockSession.token = this.sessionService.getToken();
        this.start_countdown();

        this.translate.get('UnlockScreen.Error')
            .subscribe((res: string) => {
                this.errorAlert.message = res;
          });
        this.timer = new Timer();
        this.language = this.userService.iLanguage;

        this.userService.userDataPromise.then(() => {
            this.hideAvatar = () => { this.isValidAvatar = false; };

            if (this.userService.profileImageUuid != null) {
                this.imageUrl = this.userService.profileImageUrl;
            } else {
                this.isValidAvatar = false;
            }

            if (this.userService.username != null) {
                this.username = this.userService.username;
            }

            window.onstorage = (ev) => {
                if (ev.key == 'unlockUser' + this.userService.userId) {
                    if (ev.newValue) {
                        this.sessionService.setToken(ev.newValue);
                        this.sessionService.unlockSession();
                        this.cookiesService.createSessionCookie();
                        let currPage: string = sessionStorage.getItem('currentPage');
                        if (!currPage.includes('orderDescription')) {
                            this.router.navigate([currPage]);
                        } else {
                            let mode: string = currPage.includes('view') ? 'view' : 'description';
                            this.router.navigate([currPage.replace('?mode=' + mode, '')], { queryParams: { mode: mode } });
                        }
                    }
                } else if (ev.key == 'logOutUser' + this.userService.userId) {
                    if (ev.newValue == '') {
                        this.logOut(false);
                    }
                }
            };
        });

        this.pageService.setTranslatedPageTitle('UnlockScreen.PageTitle', 0);
    }

    ngOnDestroy(): void {
        this.timer.clearTimeout();
        clearTimeout(this.timeout);
        sessionStorage.removeItem('minutesLeft');
        sessionStorage.removeItem('secondsLeft');
        this.pageService.resetPageTitle();
        window.removeEventListener('storage', window.onstorage);
    }

    start_countdown() {
        if (sessionStorage.getItem('minutesLeft') == null || sessionStorage.getItem('minutesLeft') == '') {
            sessionStorage.setItem('minutesLeft', this.env.minutesLeft);
            sessionStorage.setItem('secondsLeft', this.env.secondsLeft);
        }

        this.minutes = +sessionStorage.getItem('minutesLeft');
        this.seconds = +sessionStorage.getItem('secondsLeft');

        this.countTime();
    }

    hideAvatar() { }

    unlock_session() {
        if (this.unlockSession.password == '') {
            this.errorAlert.active = true;
            this.isError = true;
        } else {
            this.errorAlert.active = false;
            this.isError = false;
            this.odata.postPrivate('unlock_session', this.unlockSession,
                res => {
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    if (result.Status == 'UNLOCK_SUCCESSFUL') {
                        this.sessionService.setToken(result.Token);
                        this.sessionService.unlockSession();
                        this.cookiesService.createSessionCookie();
                        if (sessionStorage.getItem('currentContext') == 'PATIENT' || sessionStorage.getItem('currentContext') == 'PHYSICIAN') {
                            localStorage.setItem('darkMode', 'false');
                        }
                        let currPage: string = sessionStorage.getItem('currentPage');
                        if (!currPage.includes('orderDescription')) {
                            this.router.navigate([currPage]);
                        } else {
                            let mode: string = currPage.includes('view') ? 'view' : 'description';
                            this.router.navigate([currPage.replace('?mode=' + mode, '')], { queryParams: { mode: mode } });
                        }
                        this.sessionService.sendMessage('unlockUser' + this.userService.userId, result.Token);
                    } else if (result.Status == 'UNLOCK_FAILED') {
                        this.errorAlert.active = true;
                        this.isError = true;
                    } else if (result.Status == 'SESSION_EXPIRED') {
                        this.registrationService.sessionExpiredLogout = true;
                        this.logOut(true);
                    }
                });
        }
    }

    countTime(): void {
        if (this.minutes >= 0) {
            this.timeout = setTimeout(() => {
                this.countTime();
            }, 1000);
        } else {
            this.registrationService.isLogoutAutomatically = true;
            this.logOut(true);
        }

        this.minutes = +sessionStorage.getItem('minutesLeft');
        this.seconds = +sessionStorage.getItem('secondsLeft');

        if (this.minutes >= 10) {
            this.remainingTime = this.minutes + ':';
        } else {
            this.remainingTime = '0' + this.minutes + ':';
        }

        if (this.seconds >= 10) {
            this.remainingTime += this.seconds + '';
        } else {
            this.remainingTime += '0' + this.seconds;
        }

        this.seconds--;
        sessionStorage.setItem('secondsLeft', '' + this.seconds);

        if (this.seconds == -1) {
            this.seconds = 59;
            sessionStorage.setItem('secondsLeft', '' + this.seconds);
            this.minutes--;
            sessionStorage.setItem('minutesLeft', '' + this.minutes);
        }
    }

    logOut(sendMessage: boolean): void {
        if (sendMessage) this.sessionService.sendMessage('logOutUser' + this.userService.userId, '');

        this.odata.clearRequests();
        this.sessionService.closeSession(this.odata);
        this.sessionService.clearAllSessionInfo();
        this.registrationService.isLogout = true;
        this.router.navigate(['loginFirst']);
        this.registrationService.clearAllData();
        this.userService.clearUserData();
        this.portfolioService.clearPortfolioData();
        this.ordersService.clearAllOrdersData();
    }

    switchMode(): void {
        DisplayService.switchMode();
    }

    changeLang() {
        this.userService.changeLang();
        this.language = this.userService.iLanguage;
    }
}
