<div aria-hidden="true" aria-labelledby="descToCorrectModalLabel" class="modal fade" id="descToCorrectModal" role="dialog"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-4" id="descToCorrectModalLabel">
                    {{'RejectModal.ToCorrect' | translate}}
                </h2>
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-alert #alert title="ToCorrect"></app-alert>
                {{'AssignPhysicianModal.Patient' | translate}}: <strong style="color: var(--blue);">{{patientName}}</strong><br>
                {{'AssignPhysicianModal.Date' | translate}}: <strong style="color: var(--blue);">{{orderDate + ', ' + orderName}}</strong><br>
                <p>{{'AssignPhysicianModal.Anatomy' | translate}}: <strong style="color: var(--blue);">{{anatomyPart}}</strong></p><br>
                <form>
                    <textarea placeholder="{{'RejectModal.ToCorrectReason' | translate}}" class="form-control mb-5" name="correctReason"
                        id="reason" rows="10"></textarea>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="resolvePromise()" class="btn btn-lg btn-danger">
                    {{'RejectModal.CancelAssigmentBtn' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>