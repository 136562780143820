<div class="lr-language-selector">
    <div class="mode">
        <a (click)="switchMode()" class="dark-mode" style="cursor: pointer;"><svg-icon alt="" class="svg" src="../../../assets/images/icons/infobar/icon__dark_mode.svg"></svg-icon></a>
    </div>
    <div class="lang" style="display: none;">
        <a *ngIf="language === 'pl'" class="btn btn-light">Polski</a>
        <a (click)="changeLang()" *ngIf="language === 'en'" class="btn btn-link">Polski</a>
        <a *ngIf="language === 'en'" class="btn btn-light">English</a>
        <a (click)="changeLang()" *ngIf="language === 'pl'" class="btn btn-link">English</a>
    </div>
</div>
