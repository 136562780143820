/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-aside.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./card-aside.component";
var styles_CardAsideComponent = [i0.styles];
var RenderType_CardAsideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardAsideComponent, data: {} });
export { RenderType_CardAsideComponent as RenderType_CardAsideComponent };
export function View_CardAsideComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card card-aside"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "card-body"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "card card-aside"; var currVal_1 = _co.cardClass; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.cardStyle; _ck(_v, 2, 0, currVal_2); }, null); }
export function View_CardAsideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-aside", [], null, null, null, View_CardAsideComponent_0, RenderType_CardAsideComponent)), i1.ɵdid(1, 49152, null, 0, i3.CardAsideComponent, [], null, null)], null, null); }
var CardAsideComponentNgFactory = i1.ɵccf("app-card-aside", i3.CardAsideComponent, View_CardAsideComponent_Host_0, { cardClass: "cardClass", cardStyle: "cardStyle" }, {}, ["*"]);
export { CardAsideComponentNgFactory as CardAsideComponentNgFactory };
