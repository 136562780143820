<div class="col-col-md-12" id="top-bar">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'StatisticsScreen.Title' | translate}}</h3>

            <ul *ngIf="!envService.hideUnderConstruction" id="top-bar-nav">
                <li>
                    <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" href="#" id="top-bar-wiecej">
                        {{'StatisticsScreen.FromStart' | translate}}
                        <svg-icon alt="" class="svg" src="../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
                    </a>
                    <div aria-labelledby="top-bar-wiecej" class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'StatisticsScreen.FromStart' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'StatisticsScreen.Last30' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'StatisticsScreen.Last7' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'StatisticsScreen.Today' | translate}}</a>
                    </div>
                </li>

            </ul>

        </div>
    </div>

</div>

<div class="row ml-0 pl-0 mb-4">
    <h3 class="light" style="position: relative; top: 4px;">{{'StatisticsScreen.Welcome' | translate}} {{userService.username}}!</h3>
    <div *ngIf="!envService.hideUnderConstruction">
        <a routerLink="/siteUnderConstruction" class="btn btn-small btn-primary ml-4">{{'StatisticsScreen.HideSummary' | translate}}</a>
    </div>
</div>

<!-- Example stats -->
<div class="row stat-row">
    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat blue">{{statistics.averageReactionTime}}
                            <small>{{'StatisticsScreen.ReactionTimeSmall' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.ReactionTimeStrong' | translate">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat green">{{statistics.averageRealizationTime}}
                            <small>{{'StatisticsScreen.RealizationTimeSmall' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.RealizationTimeStrong' | translate">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat green">{{statistics.descriptionsCount}}
                            <small>{{'StatisticsScreen.DescriptionsSmall' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.DescriptionsStrong' |translate:statistics">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat grey">{{statistics.monthSalary}}
                            <small>{{'StatisticsScreen.Salary' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.SalaryStrong' | translate">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat orange">{{statistics.freeOrdersCount}}
                            <small>{{'StatisticsScreen.FreeOrdersSmall' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.FreeOrdersStrong' | translate:statistics">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat red">{{statistics.canceledOrders}}
                            <small>{{'StatisticsScreen.CanceledSmall' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.CanceledStrong' | translate:statistics">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="number-stat red">{{statistics.timeLimitExceededOrdersCount}}
                            <small>{{'StatisticsScreen.LimitExceededSmall' | translate}}</small>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.LimitExceededStrong' | translate:statistics">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5 position-relative">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand">
                                <div class=""></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink">
                                <div class=""></div>
                            </div>
                        </div>
                        <canvas baseChart
                            [colors] = "ordersColors"
                            [options]="doughnutChartOptions"
                            [labels]="[]"
                            [data]="ordersChartData"
                            [chartType]="ordersChartType"
                            id="chart1" style="display: block; height: 143px; width: 143px;" width="178" height="178"
                            class="chartjs-render-monitor position-absolute stat-doughnut-chart">
                        </canvas>
                        <div class="position-absolute chart-stat">
                            <p class="text-center">{{statistics.currentOrdersCount}}</p>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0" [innerHTML]="'StatisticsScreen.CurrentOrders' | translate:statistics">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5 position-relative">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand">
                                <div class=""></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink">
                                <div class=""></div>
                            </div>
                        </div>
                        <canvas baseChart
                            [colors] = "slaColors"
                            [options]="doughnutChartOptions"
                            [labels]="[]"
                            [data]="slaChartData"
                            [chartType]="slaChartType"
                            id="chart2" style="display: block; height: 143px; width: 143px;" 
                            width="178" height="178"
                            class="chartjs-render-monitor position-absolute stat-doughnut-chart">
                        </canvas>
                        <div class="position-absolute chart-stat">
                            <p class="text-center">{{'StatisticsScreen.SLA' | translate}}</p>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0">
                            <span class="dot red"></span> {{statistics.exceededSLACount}} {{'StatisticsScreen.ExceededSLA' | translate}}
                            <br/><span
                                class="dot orange"></span>{{statistics.comeToExceededSLACount}} {{'StatisticsScreen.ComeToExceededSLA' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-4 mb-4">
        <div class="card stat-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <img src="../../../assets/images/stats.png" alt="" class="fluid">
                    </div>
                    <div class="col-md-7 d-flex align-self-center">
                        <p class="m-0 p-0">
                            <strong>{{'StatisticsScreen.Title' | translate}} </strong><br/>{{'StatisticsScreen.NewStatistics' | translate}}
                            <br/><br/>
                            <a *ngIf="!envService.hideUnderConstruction" routerLink="/siteUnderConstruction" class="btn btn-grey">{{'StatisticsScreen.SeeStatistics' | translate}}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>