import { UniversalToastComponent } from './../../../utils/universal-toast/universal-toast.component';
import { EnvService } from './../../../services/env.service';
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService, OdataService, UsersService, SessionService, NavigationService } from '@/services';
import { Router } from '@angular/router';
import { AlertComponent } from '@/utils';
import { PageService } from '@/services/page.service';
var CoStep1Component = /** @class */ (function () {
    function CoStep1Component(formBuilder, validation, router, odataService, sessionService, env, userService, pageService, navigationService) {
        this.formBuilder = formBuilder;
        this.validation = validation;
        this.router = router;
        this.odataService = odataService;
        this.sessionService = sessionService;
        this.env = env;
        this.userService = userService;
        this.pageService = pageService;
        this.registerCoForm = this.formBuilder.group({
            firm: this.formBuilder.group({
                type: [0, Validators.min(1)],
                nip: ['', [Validators.required, this.validation.nipValidator]],
                name: ['', [Validators.required]],
                krs: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
                regon: ['', [Validators.required, this.validation.regonValidator]]
            }),
            firstName: [{ value: '', disabled: true }],
            lastName: [{ value: '', disabled: true }],
            address: this.formBuilder.group({
                postCode: ['', [Validators.required, Validators.pattern('[0-9]{2}-[0-9]{3}')]],
                city: ['', [Validators.required]],
                street: ['', [Validators.required]],
                number: ['', [Validators.required, Validators.pattern('[0-9]+[A-Za-z]?((\\s)?\/(\\s)?[0-9]+[A-Za-z]?)?')]]
            }),
            agreements: this.formBuilder.array([])
        });
        this.inputs = {
            // @ts-ignore
            firmType: this.registerCoForm.controls.firm.controls.type,
            // @ts-ignore
            nip: this.registerCoForm.controls.firm.controls.nip,
            // @ts-ignore
            firmName: this.registerCoForm.controls.firm.controls.name,
            // @ts-ignore
            krs: this.registerCoForm.controls.firm.controls.krs,
            // @ts-ignore
            regon: this.registerCoForm.controls.firm.controls.regon,
            firstName: this.registerCoForm.controls.firstName,
            lastName: this.registerCoForm.controls.lastName,
            // @ts-ignore
            postCode: this.registerCoForm.controls.address.controls.postCode,
            // @ts-ignore
            city: this.registerCoForm.controls.address.controls.city,
            // @ts-ignore
            street: this.registerCoForm.controls.address.controls.street,
            // @ts-ignore
            addressNumber: this.registerCoForm.controls.address.controls.number
        };
        this.showAlert = false;
        this.alertText = '';
        this.businessTypes = [];
        this.registerCo = {
            token: '', language: '', id_busines_type: 0,
            address_street: '', address_city: '', address_zip_code: '', address_local: '', address_country: 'pl',
            id_agreement_list: [], nip: '', regon: '', krs: '', describing_name: ''
        };
        this.agreementsList = [];
        this.navigationService = navigationService;
    }
    Object.defineProperty(CoStep1Component.prototype, "agreements", {
        get: function () {
            return this.registerCoForm.get('agreements');
        },
        enumerable: true,
        configurable: true
    });
    CoStep1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.getBusinessTypes();
        this.alert.clear();
        this.getAgreementsForCORegistration();
        this.pageService.setTranslatedPageTitle('BeforeWorkDoctor.RegisterDCTitle', 0);
        this.fillUserName();
        setTimeout(function () { return _this.onBusinessTypeChange(); });
        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('pp-portal');
        }
    };
    CoStep1Component.prototype.onBusinessTypeChange = function () {
        var _this = this;
        this.registerCoForm.controls.firm.get('type').valueChanges.subscribe(function (businessType) {
            if (businessType == 1 || businessType == 2) {
                _this.registerCoForm.controls.firm.get('krs').reset();
                _this.registerCoForm.controls.firm.get('krs').disable();
                document.getElementById('krs').setAttribute('class', 'form-control');
                _this.registerCoForm.controls.firm.get('krs').setValue('');
            }
            else {
                _this.registerCoForm.controls.firm.get('krs').setValidators([Validators.required, Validators.pattern('[0-9]{10}')]);
                _this.registerCoForm.controls.firm.get('krs').enable();
            }
            _this.registerCoForm.controls.firm.get('krs').updateValueAndValidity();
        });
    };
    CoStep1Component.prototype.fillUserName = function () {
        var _this = this;
        this.userService.userDataPromise.then(function () {
            var array = _this.userService.username.split(' ');
            var name = '';
            var lastName = array[array.length - 1];
            for (var i = 0; i < array.length - 1; ++i) {
                name += array[i];
                if (i < array.length - 2)
                    name += ' ';
            }
            _this.registerCoForm.controls.firstName.setValue(name);
            _this.registerCoForm.controls.lastName.setValue(lastName);
        });
    };
    CoStep1Component.prototype.addAgreement = function (agreement) {
        if (agreement.is_required) {
            this.agreements.push(this.formBuilder.control(false, Validators.requiredTrue));
        }
        else {
            this.agreements.push(this.formBuilder.control(false));
        }
    };
    CoStep1Component.prototype.ngOnDestroy = function () {
        this.pageService.resetPageTitle();
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');
    };
    CoStep1Component.prototype.getBusinessTypes = function () {
        var _this = this;
        this.odataService.postPrivate('get_business_types', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        }, function (res) {
            var result = JSON.parse(JSON.stringify(res));
            var status = result.Status;
            _this.businessTypes = JSON.parse(result.Value);
            _this.registerCoForm.controls.firm.get('type').setValue(1);
        });
    };
    CoStep1Component.prototype.showErrors = function () {
        if (!this.inputs.firmType.valid) {
            document.getElementById('type').setAttribute('class', 'form-control is-error');
            document.getElementById('nip').setAttribute('class', 'form-control');
            document.getElementById('name').setAttribute('class', 'form-control');
            document.getElementById('krs').setAttribute('class', 'form-control');
            document.getElementById('regon').setAttribute('class', 'form-control');
            document.getElementById('postCode').setAttribute('class', 'form-control');
            document.getElementById('city').setAttribute('class', 'form-control');
            document.getElementById('street').setAttribute('class', 'form-control');
            document.getElementById('addressNumber').setAttribute('class', 'form-control');
        }
        else {
            document.getElementById('type').setAttribute('class', 'form-control');
            if (!this.inputs.nip.valid) {
                document.getElementById('nip').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('nip').setAttribute('class', 'form-control');
            }
            if (!this.inputs.firmName.valid) {
                document.getElementById('name').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('name').setAttribute('class', 'form-control');
            }
            if (this.inputs.krs.invalid && this.inputs.krs.enabled) {
                document.getElementById('krs').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('krs').setAttribute('class', 'form-control');
            }
            if (!this.inputs.regon.valid) {
                document.getElementById('regon').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('regon').setAttribute('class', 'form-control');
            }
            if (!this.inputs.postCode.valid) {
                document.getElementById('postCode').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('postCode').setAttribute('class', 'form-control');
            }
            if (!this.inputs.city.valid) {
                document.getElementById('city').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('city').setAttribute('class', 'form-control');
            }
            if (!this.inputs.street.valid) {
                document.getElementById('street').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('street').setAttribute('class', 'form-control');
            }
            if (!this.inputs.addressNumber.valid) {
                document.getElementById('addressNumber').setAttribute('class', 'form-control is-error');
            }
            else {
                document.getElementById('addressNumber').setAttribute('class', 'form-control');
            }
        }
    };
    CoStep1Component.prototype.onSubmit = function () {
        var _this = this;
        if (this.checkData()) {
            this.showErrors();
            this.insertData();
            this.universalToast.isLoaderShown = true;
            this.universalToast.success('UploadData');
            this.universalToast.show();
            this.odataService.postPrivate('register_describing_center_step1', this.registerCo, function (res) {
                var returnedJson = JSON.stringify(res);
                var result = JSON.parse(returnedJson);
                if (result.Status === 'OK') {
                    _this.userService.getUserData();
                    _this.userService.getDcList();
                    sessionStorage.setItem('newDcId', result.Value);
                    _this.universalToast.hide();
                    _this.router.navigate(['/registrationCo2']);
                }
                else {
                    _this.universalToast.isLoaderShown = false;
                    _this.universalToast.error('CenterExists');
                    setTimeout(function () {
                        _this.universalToast.hide();
                    }, 3000);
                }
            });
        }
    };
    CoStep1Component.prototype.getAgreementsForCORegistration = function () {
        var _this = this;
        var params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        };
        this.odataService.postPrivate('get_user_agreement_for_co_registration', params, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.agreementsList = JSON.parse(result.Value);
                _this.agreementsList.forEach(function (agreement) {
                    if (agreement.is_required) {
                        agreement.agreement_contents = '* ' + agreement.agreement_contents;
                    }
                    _this.addAgreement(agreement);
                });
            }
        });
    };
    CoStep1Component.prototype.checkData = function () {
        this.alert.clear();
        this.alertText = '';
        if (this.registerCoForm.valid) {
            return true;
        }
        else {
            var countOfErrors = 0;
            this.showErrors();
            var errorMessage = void 0;
            if (!this.inputs.firmType.valid) {
                errorMessage = 'WRONG_FIRM_TYPE';
            }
            else {
                if (!this.inputs.nip.valid) {
                    errorMessage = 'nip';
                    countOfErrors++;
                }
                if (!this.inputs.firmName.valid) {
                    errorMessage = 'firmName';
                    countOfErrors++;
                }
                if (this.inputs.krs.invalid && this.inputs.krs.enabled) {
                    errorMessage = 'krs';
                    countOfErrors++;
                }
                if (!this.inputs.regon.valid) {
                    errorMessage = 'regon';
                    countOfErrors++;
                }
                if (!this.inputs.postCode.valid) {
                    errorMessage = 'zip_code';
                    countOfErrors++;
                }
                if (!this.inputs.city.valid) {
                    errorMessage = 'city';
                    countOfErrors++;
                }
                if (!this.inputs.street.valid) {
                    errorMessage = 'street';
                    countOfErrors++;
                }
                if (!this.inputs.addressNumber.valid) {
                    errorMessage = 'addressNumber';
                    countOfErrors++;
                }
                if (!this.agreements.valid) {
                    errorMessage = 'agreements';
                    countOfErrors++;
                }
                if (countOfErrors > 1) {
                    errorMessage = 'manyErrors';
                }
            }
            this.alertText = errorMessage;
            this.alert.displayError(errorMessage);
        }
    };
    CoStep1Component.prototype.insertData = function () {
        var _this = this;
        this.registerCo.describing_name = this.inputs.firmName.value;
        this.registerCo.token = this.sessionService.getToken();
        this.registerCo.id_busines_type = +this.inputs.firmType.value;
        this.registerCo.nip = this.inputs.nip.value;
        this.registerCo.krs = this.inputs.krs.value;
        this.registerCo.regon = this.inputs.regon.value;
        this.registerCo.address_zip_code = this.inputs.postCode.value;
        this.registerCo.address_city = this.inputs.city.value;
        this.registerCo.address_street = this.inputs.street.value;
        this.registerCo.address_local = this.inputs.addressNumber.value;
        this.registerCo.language = this.userService.iLanguage;
        this.registerCo.id_agreement_list = [];
        this.agreementsList.forEach(function (agreement) {
            _this.registerCo.id_agreement_list.push(agreement.id_agreement);
        });
    };
    return CoStep1Component;
}());
export { CoStep1Component };
