import { IFacilityExams } from '@/interfaces/MedicalFacility';
import { OrderDescription } from '@/interfaces/orderDescription';
import { IOrder } from '@/interfaces/Order';

export const CovidThreshold: number = 0.01;

export interface CovidAIStatistics {
  RUL: { percentage: any; volume: any };
  RML: { percentage: any; volume: any };
  RLL: { percentage: any; volume: any };
  LUL: { percentage: any; volume: any };
  LLL: { percentage: any; volume: any };
  totalLung: { percentage: any; volume: any };
}

export const initCovidStats: CovidAIStatistics = {
  RUL: { percentage: '', volume: '' },
  RML: { percentage: '', volume: '' },
  RLL: { percentage: '', volume: '' },
  LUL: { percentage: '', volume: '' },
  LLL: { percentage: '', volume: '' },
  totalLung: { percentage: '', volume: '' }
};

export const initFacilityConsultationStats: IConsultationAIStats = {
  keySlice: 0,
  lesionVolume: 0,
  max: 0,
  maxArea: 0,
  min: 0,
  otherStatistic: 0,
  pixelCtSum: 0,
  pixelNum: 0,
  statistic: [],
  step: 0,
  volume: 0

};

export const initConsultation: IConsultationAI = {
  consultation_content: {
    ai_report: '',
    browser_url: '',
    ct_lung_analysis: {
      leftLungLowerLobe: initFacilityConsultationStats,
      leftLungUpperLobe: initFacilityConsultationStats,
      rightLungLowerLobe: initFacilityConsultationStats,
      rightLungMiddleLobe: initFacilityConsultationStats,
      rightLungUpperLobe: initFacilityConsultationStats,
      totalLung: initFacilityConsultationStats
    },
    ct_pneumonia: {genotyping: '', probability: 0, time: ''}
  },
  consultation_start: '',
  consultation_status: 0,
  consultation_time: '',
  id_consultation_type: 0
};

export interface IConsultationAIStats {
  max: number;
  min: number;
  step: number;
  volume: number;
  maxArea: number;
  keySlice: number;
  pixelNum: number;
  statistic: number[];
  pixelCtSum: number;
  lesionVolume: number;
  otherStatistic: number;
}

export interface IConsultationAI {
  consultation_time: string;
  consultation_start: string;
  consultation_status: number;
  consultation_content: {
    ai_report: string;
    browser_url: string;
    ct_pneumonia: {
      time: string;
      genotyping: string;
      probability: number
    };
    ct_lung_analysis: {
      totalLung: IConsultationAIStats;
      leftLungLowerLobe: IConsultationAIStats;
      leftLungUpperLobe: IConsultationAIStats;
      rightLungLowerLobe: IConsultationAIStats;
      rightLungUpperLobe: IConsultationAIStats;
      rightLungMiddleLobe: IConsultationAIStats;
    }
  };
  id_consultation_type: number;
}

export function setCovidStatistics(exam?: IFacilityExams, orderDescription?: OrderDescription, order?: IOrder): CovidAIStatistics {
  let res: CovidAIStatistics = initCovidStats;
  let input;
  if (exam) input = exam;
  else input = orderDescription ? orderDescription.exam_info : order.exam_info;
  if (input.consultation.consultation_content.ct_lung_analysis.rightLungUpperLobe) {
    res.RUL = countCovidStatistics(input.consultation.consultation_content.ct_lung_analysis.rightLungUpperLobe.lesionVolume,
      input.consultation.consultation_content.ct_lung_analysis.rightLungUpperLobe.volume);
  }
  if (input.consultation.consultation_content.ct_lung_analysis.rightLungMiddleLobe) {
    res.RML = countCovidStatistics(input.consultation.consultation_content.ct_lung_analysis.rightLungMiddleLobe.lesionVolume,
      input.consultation.consultation_content.ct_lung_analysis.rightLungMiddleLobe.volume);
  }
  if (input.consultation.consultation_content.ct_lung_analysis.rightLungLowerLobe) {
    res.RLL = countCovidStatistics(input.consultation.consultation_content.ct_lung_analysis.rightLungLowerLobe.lesionVolume,
      input.consultation.consultation_content.ct_lung_analysis.rightLungLowerLobe.volume);
  }
  if (input.consultation.consultation_content.ct_lung_analysis.leftLungUpperLobe) {
    res.LUL = countCovidStatistics(input.consultation.consultation_content.ct_lung_analysis.leftLungUpperLobe.lesionVolume,
      input.consultation.consultation_content.ct_lung_analysis.leftLungUpperLobe.volume);
  }
  if (input.consultation.consultation_content.ct_lung_analysis.leftLungLowerLobe) {
    res.LLL = countCovidStatistics(input.consultation.consultation_content.ct_lung_analysis.leftLungLowerLobe.lesionVolume,
      input.consultation.consultation_content.ct_lung_analysis.leftLungLowerLobe.volume);
  }
  if (input.consultation.consultation_content.ct_lung_analysis.totalLung) {
    res.totalLung = countCovidStatistics(input.consultation.consultation_content.ct_lung_analysis.totalLung.lesionVolume,
      input.consultation.consultation_content.ct_lung_analysis.totalLung.volume);
  }
  return res;
}

function countCovidStatistics(lesionVol: any, totalVol: any): { percentage: any; volume: any } {
  return { percentage: ( lesionVol * 100 / totalVol ).toFixed(2),
    volume: lesionVol.toFixed(2)};
}
