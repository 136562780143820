<div class="row mt50">
    <div class="col-md-8">

        <!--Card-->
        <div class="card big-card">
            <h3 class="card-title">{{'RegistrationCo.AccountVerification' | translate}}</h3>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <p class="strong">{{'RegistrationCo.ThankYou' | translate}}<br><span
                                class="blue">{{'RegistrationCo.MayTakeUpTo2Days' | translate}}</span></p>
                        <a (click)="goTo('profiles')" style="cursor: pointer;"
                            class="btn btn-light btn-block mt-2">{{'RegistrationCo.InviteDoctors' | translate}}</a>
                        <a (click)="goTo('coProfile')" style="cursor: pointer;"
                            class="btn btn-light btn-block">{{'RegistrationCo.CompleteCompanyProfile' | translate}}</a>
                        <a (click)="goTo('pulpit')" style="cursor: pointer;"
                            class="btn btn-light btn-block">{{'RegistrationCo.GoToDesktop' | translate}}</a>
                    </div>
                    <div class="col-md-6 offset-md-1 mobile-only-margin-top">
                        <p><strong>Jakie wymagania należy spełnić,<br>aby przejśc pozytywnie weryfikację?</strong></p>
                        <ul class="list-group">
                            <li class="list-group-item">Musisz mieć przynajmniej jednego zweryfikowanego lekarza w
                                zespole</li>
                            <li class="list-group-item">Dane firmowe muszą być uzupełnione</li>
                        </ul>
                        <p></p>
                    </div>
                </div>


            </div>
        </div>
        <!--/Card-->

    </div>

    <div class="col-md-4" class="desktop-only">
        <app-stepper stepNum="2" stepperType="co"></app-stepper>
    </div>

</div>
