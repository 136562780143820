/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./horizontal-stepper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./horizontal-stepper.component";
var styles_HorizontalStepperComponent = [i0.styles];
var RenderType_HorizontalStepperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HorizontalStepperComponent, data: {} });
export { RenderType_HorizontalStepperComponent as RenderType_HorizontalStepperComponent };
export function View_HorizontalStepperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "horizontal-stepper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "step"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "step-blue": 0, "step-grey": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "step"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "step-blue": 0, "step-grey": 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "step"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "step-blue": 0, "step-grey": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "step"; var currVal_1 = _ck(_v, 3, 0, (_co.stepNum == 1), (_co.stepNum != 1)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "step"; var currVal_3 = _ck(_v, 6, 0, (_co.stepNum == 2), (_co.stepNum != 2)); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = "step"; var currVal_5 = _ck(_v, 9, 0, (_co.stepNum == 3), (_co.stepNum != 3)); _ck(_v, 8, 0, currVal_4, currVal_5); }, null); }
export function View_HorizontalStepperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-horizontal-stepper", [], null, null, null, View_HorizontalStepperComponent_0, RenderType_HorizontalStepperComponent)), i1.ɵdid(1, 114688, null, 0, i3.HorizontalStepperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HorizontalStepperComponentNgFactory = i1.ɵccf("app-horizontal-stepper", i3.HorizontalStepperComponent, View_HorizontalStepperComponent_Host_0, { stepNum: "stepNum" }, {}, []);
export { HorizontalStepperComponentNgFactory as HorizontalStepperComponentNgFactory };
