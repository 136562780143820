<div aria-hidden="true" class="modal fade"
     role="dialog"
     tabindex="-1" id="descOrderReturn"
     aria-labelledby="descOrderReturnLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="row content">
        <div class="col-md-5 p-25">
          <h2 class="modal-title mb-4">{{'OrderDescReturnModal.Title' | translate}}</h2><button type="button" class="btn btn-light close" style="top: 20px" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <form id="form" [formGroup]="returnOrderForm">
            <div class="form-group form-row">
              <label for="is_complaint">{{'OrderDescReturnModal.ReturnMethod' | translate}}<br><br>
              <input type="radio" [value]=false formControlName="is_complaint" id="is_complaint">
              <span> {{'OrderDescReturnModal.ReturnToPhysician' |translate}}</span>
                <br><span *ngIf="returnOrderForm.controls.is_complaint.value == false" style="opacity: 0.5; font-weight: normal">{{'OrderDescReturnModal.ReturnToPhysicianText' |translate}} {{orderDetails.exam_description_info.physician_name}}</span></label>

              <label>
                <input type="radio" [value]=true formControlName="is_complaint"><span> {{'OrderDescReturnModal.ReturnToCoordinator' |translate}}
              <br><span *ngIf="returnOrderForm.controls.is_complaint.value == true" style="opacity: 0.5; font-weight: normal"> {{'OrderDescReturnModal.ReturnCoordinatorText' |translate}}</span></span>
              </label>

            </div>

            <div class="form-row form-group">
              <label for=comment>
                {{'OrderDescReturnModal.DescNotes' | translate}} ({{'OrderDescReturnModal.Required' | translate}} {{minCommentLength}} {{'OrderDescReturnModal.Chars' | translate}})
              </label>
              <textarea type="text" class="form-control" formControlName="comment" placeholder="{{'OrderDescReturnModal.DescNotesPlaceholder' |translate}}" id="comment"></textarea>
            </div>
          </form>

          <div class="row mb-4" *ngFor="let attachment of attachmentsList; let k = index;">
            <div class="col my-auto ml-3 p-0">
              {{attachment.filename}}
              <a class="ml-2" style="color: var(--red); text-decoration: underline; cursor: pointer;" (click)="delExamOrderAttachment(k)">
                {{'CreateOrderModal.Remove' | translate}}
              </a>
            </div>
          </div>
          <div class="row" *ngIf="attachmentsList.length < 5">
            <div class="col d-flex">
              <div class="form-group my-0 m-0">
                <label class="btn btn-sm btn-light m-0" for="addFile">
                  {{'CreateOrderModal.AddAttachment' | translate}}
                </label>
                <input (change)="onFileSelected($event)" id="addFile"
                       type="file" accept=".jpg, .png, .bmp, .pdf" style="visibility: hidden; width: 1rem">
              </div>
              <span class="light my-auto" [innerHTML]="'CreateOrderModal.AcceptableFiles' | translate"></span>
            </div>
          </div>
          <button class="btn btn-sm btn-primary ml-auto" [disabled]="!returnOrderForm.valid" (click)="submit()">{{'OrderDescReturnModal.Submit' |translate}}</button>


        </div>
        <div class="col-md-7 p-25" style="background: #fafbff">
          <div class="mb-4">
            <ul class="top-bar-nav">
              <li id="All" [ngClass]="{'active': currentCard == 'preview'}">
                <a (click)="switchView('preview')"
                   style="cursor: pointer;">{{'OrderDescReturnModal.View' | translate}}</a>
              </li>
              <li id="Completed" [ngClass]="{'active': currentCard == 'details'}">
                <a (click)="switchView('details')"
                   style="cursor: pointer;">{{'OrderDescReturnModal.Details' | translate}}</a>
              </li>
            </ul>
          </div>
          <div *ngIf="currentCard == 'details'">
            <div class="row">
              <div class="col" style="display: flex;">
                <span class="modality">{{orderDetails.modality.modality}}</span>
                <div class="my-auto ml-3">
                  {{orderDetails.anatomy.part_name}}<br>
                  <span style="font-weight: bold;">{{orderDetails.priority.priority}}</span>
                </div>
              </div>
              <div class="col my-auto">
                {{'OrderDescReturnModal.OrderDate' |translate}} <span style="font-weight: bold;">{{ordersService.getDateString(orderDetails.exam_order_date)}}</span><br>
                {{'OrderDescReturnModal.ExamDate' |translate}} <span style="font-weight: bold;">{{ordersService.getDateString(orderDetails.exam_date)}}</span>
              </div>
            </div><br>
            <span class="mt-3" style="font-weight: bold;">{{'OrderInfoModal.Icd10' | translate}}:</span>
            {{orderDetails.icd10}}
            <div class="my-3"></div>
            <span style="font-weight: bold;">{{'OrderDescReturnModal.Comment' | translate}}:</span> <br>
            <span>{{orderDetails.exam_order_notes}}</span><br>
            <ng-container *ngIf="orderDetails.attachment_list">
              <div class="my-3"></div>
              <span style="font-weight: bold;">{{'OrderDescReturnModal.Attachments' | translate}}:</span> <br>
              <table class="table-sm table-borderless">
                <tr *ngFor="let attach of orderDetails.attachment_list">
                  <td>{{attach.attachment_type.attachment_type_text}}:</td>
                  <td><a (click)="attachmentViewModalComponent.show(attach)"
                         style="text-decoration: underline; color: var(--blue);">{{attach.filename}}</a></td>
                </tr>
              </table>
            </ng-container>
          </div>


          <div *ngIf="currentCard == 'preview'">
            <label for="version_select">{{'OrderDescReturnModal.DescVersion' |translate}}</label>
            <select id="version_select" #descSelect class="custom-select" (change)="changeDesc(descSelect.selectedIndex)">
            <option *ngFor="let desc of orderDetails.exam_description_history; let j = index" [value]="desc">{{'OrderDescReturnModal.Desc' |translate}}
              {{orderDetails.exam_description_history.length - j}}/{{orderDetails.exam_description_history.length}} - {{ordersService.getDateString(desc.exam_description_date)}}
              <ng-container *ngIf="j == 0"> - {{'OrderDescReturnModal.CurrentReturn'|translate}}</ng-container></option>
          </select>
            <div class="mt-3 p-10" [innerHTML]="currentDesc.exam_description"></div>
          </div>

        </div>
        </div>
      </div>
    </div>
  </div>
<app-attachment-view-modal #attachmentViewModalComponent></app-attachment-view-modal>
