import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SessionService, NavigationService } from '@/services';

@Injectable()
export class LockGuardService implements CanActivate {

    constructor(private session: SessionService, private navigationService: NavigationService) {
    }

    canActivate(): boolean {
        if (this.session.isLocked()) {
            return true;
        } else {
            this.navigationService.navigateToDefault();
            return false;
        }
    }

}
