import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TextEditorComponent } from '@/utils/text-editor/text-editor.component';
import { IDescTemplate, initDescTemplate } from '@/interfaces/order-desc-template';
import { FiltersService } from '@/services/filters.service';
import { OrderDescriptionService } from '@/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
interface IOption {
  value: any;
  id: number;
}

@Component({
  selector: 'app-order-description-templates-modal',
  templateUrl: './order-description-templates-modal.component.html',
  styleUrls: ['./order-description-templates-modal.component.scss']
})
export class OrderDescriptionTemplatesModalComponent implements OnInit {
  @ViewChild('text_editor') editor: TextEditorComponent;
  @ViewChild('templatesCDK') templateList: CdkVirtualScrollViewport;

  templateForm: FormGroup = this.formBuilder.group({
    id_template:  [{value: '', disabled: true}],
    name: [ '', [Validators.required]],
    modalities: [[], [Validators.required]],
    anatomy_parts: [[], [Validators.required]]
  });

  searchOptionsForm: FormGroup = this.formBuilder.group({
    anatomy: [],
    modality: []
  });

  allModalityIds: number[] = [];
  allAnatomyIds: number[] = [];

  modalityOptions: IOption[] = [];
  anatomyOptions: IOption[] = [];

  insertToEditorEvent: EventEmitter<string> = new EventEmitter<string>();
  templateListChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  currentTemplate: IDescTemplate = initDescTemplate;
  userTemplates: IDescTemplate[] = [];
  editMode: boolean = false;


  constructor(private filterService: FiltersService, public orderDescriptionService: OrderDescriptionService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.get_anatomy_parts().then(() => this.anatomyOptions = [...this.anatomyOptions]); // ng-select doesnt recognize changes via push
    this.get_modalities_groups().then(() => this.modalityOptions = [...this.modalityOptions]); // updating options for ng-select
  }

  show(modalityId: number, anatomyId: number): void {
    this.currentTemplate = initDescTemplate;
    this.editMode = false;
    this.searchOptionsForm.patchValue({
      modality: [modalityId],
      anatomy: [anatomyId]
    });
    this.get_templates();
    this.templateList.scrollToIndex(0);
    $('#orderDescriptionTemplatesModal').modal('show');
  }

  hide(): void {
    $('#orderDescriptionTemplatesModal').modal('hide');
  }

  onSelectTemplate(template: IDescTemplate): void {
    this.currentTemplate = template;
  }

  onCloseModal() {
    this.currentTemplate = initDescTemplate;
    this.hide();
  }

  get_templates(): Promise<any> {
    return this.orderDescriptionService.getDescriptionTemplates(this.searchOptionsForm.controls.modality.value || [],
      this.searchOptionsForm.controls.anatomy.value || [],
      false, 100).then(res => {
      this.userTemplates = res;
    });
  }

  get_modalities_groups(): Promise<any> {
    return this.filterService.getModalitiesGroups().then(
      (value) => {
        value.forEach((v) => {
          this.allModalityIds.push(v.id_group);
          this.modalityOptions.push({ value: v.value, id: v.id_group });
        });
      }
    );
  }

  get_anatomy_parts(): Promise<any> {
    return this.filterService.getAnatomyParts().then(
      (value) => {
        value.forEach((v) => {
          this.allAnatomyIds.push(v.id_part);
          this.anatomyOptions.push({ value: v.value, id: v.id_part });
        });
      }
    );
  }

  trackByFn(index: number, item: any) {
    return item ? item.id_user : null;
  }


  onEditTemplate(template?: IDescTemplate) {
    this.currentTemplate = template ? template : initDescTemplate;
    this.templateForm.patchValue({
      id: this.currentTemplate.id_template,
      name: this.currentTemplate.template_name,
      modalities: this.currentTemplate.modality_list,
      anatomy_parts: this.currentTemplate.anatomy_part_list
    });
    this.editMode = true;
    this.editor.setText(this.currentTemplate.content);
  }

  onCopyToEditor(template: IDescTemplate) {
    this.orderDescriptionService.descriptionTemplateUsed(template.id_template);
    this.insertToEditorEvent.emit(template.content);
    this.hide();
  }


  cancelEdit() {
    this.editMode = false;
    this.templateForm.reset();
    this.editor.setText('');
  }

  onDeleteTemplate(id: number) {
    this.orderDescriptionService.deleteDescriptionTemplate(id).then(() => {
      this.get_templates().then(() => this.templateListChangedEvent.emit());
    });
  }

  onSave() {
    let id = this.currentTemplate.id_template == 0 ? undefined : this.currentTemplate.id_template;
    this.orderDescriptionService.saveDescriptionTemplate(this.templateForm.controls.name.value, this.editor.getText(),
      this.templateForm.controls.modalities.value, this.templateForm.controls.anatomy_parts.value, id).then(res => {
      if (res.Status == 'OK') {
        this.editor.setText('');
        this.editMode = false;
        this.get_templates().then(() => this.currentTemplate = this.currentTemplate.id_template == 0 ? initDescTemplate : this.userTemplates.find(x => x.id_template == this.currentTemplate.id_template));
        this.templateListChangedEvent.emit();
      }
    });
  }

  selectAll(source: string) {
    if (source === 'modality') {
      if (this.editMode) this.templateForm.patchValue({modalities: this.allModalityIds});
      else this.searchOptionsForm.patchValue({modality: this.allModalityIds});
    } else {
      if (this.editMode) this.templateForm.patchValue({anatomy_parts: this.allAnatomyIds});
      else this.searchOptionsForm.patchValue({anatomy: this.allAnatomyIds});
    }
  }

  deselectAll(source: string) {
    if (source === 'modality') {
      if (this.editMode) this.templateForm.patchValue({modalities: []});
      else this.searchOptionsForm.patchValue({modality: []});
    } else {
      if (this.editMode) this.templateForm.patchValue({anatomy_parts: []});
      else this.searchOptionsForm.patchValue({anatomy: []});
    }
  }

  toggleCheckAll($event, source: string) {
    if ($event.target.checked) this.selectAll(source);
    else this.deselectAll(source);
  }

}
