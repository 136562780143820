import { PageService } from './../../../services/page.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-medical-facility-invoices',
  templateUrl: './medical-facility-invoices.component.html',
  styleUrls: ['./medical-facility-invoices.component.scss']
})
export class MedicalFacilityInvoicesComponent implements OnInit, OnDestroy {

  constructor(private pageService: PageService) { }

  ngOnInit() {
    this.pageService.setTranslatedPageTitle('DashboardScreen.Payments', 0);
    document.getElementById('facilityInvoicesLink').setAttribute('class', 'active');
  }

  ngOnDestroy() {
    document.getElementById('facilityInvoicesLink').setAttribute('class', '');
    this.pageService.resetPageTitle();
  }

}
