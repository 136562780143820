import * as tslib_1 from "tslib";
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@ngx-translate/core";
var PageService = /** @class */ (function () {
    function PageService(titleService, translate) {
        this.titleService = titleService;
        this.translate = translate;
    }
    PageService.prototype.setTranslatedPageTitle = function (paramName, alertsNumber) {
        var _this = this;
        this.getTranslation(paramName).then(function (res) {
            if (res != '') {
                _this.setPageTitle(res, alertsNumber);
            }
            else {
                _this.resetPageTitle();
            }
        });
    };
    PageService.prototype.setPageTitle = function (title, alertsNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertsString, _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        alertsString = '';
                        if (alertsNumber > 0) {
                            alertsString = ' (' + alertsNumber + ')';
                        }
                        _b = (_a = this.titleService).setTitle;
                        _c = title + alertsString + ' | ';
                        return [4 /*yield*/, this.getDefaultPageTitle()];
                    case 1:
                        _b.apply(_a, [_c + (_d.sent())]);
                        return [2 /*return*/];
                }
            });
        });
    };
    PageService.prototype.setSettingPageTranslatedTitle = function (paramName, alertsNumber) {
        var _this = this;
        var translations = [this.getTranslation('DashboardScreen.Settings'), this.getTranslation(paramName)];
        Promise.all(translations).then(function (res) {
            _this.setPageTitle(res[0] + ': ' + res[1], alertsNumber);
        });
    };
    PageService.prototype.setPatientsPortalPageTitle = function (alertsNumber) {
        this.setPageTitle('Portal Pacjenta', alertsNumber);
    };
    PageService.prototype.setLogInPageTitle = function () {
        this.setTranslatedPageTitle('LoginFirstScreen.PageTitle', 0);
    };
    PageService.prototype.setSignUpPageTitle = function () {
        this.setTranslatedPageTitle('RegistrationFirstScreen.PageTitle', 0);
    };
    PageService.prototype.resetPageTitle = function () {
        var _this = this;
        this.getDefaultPageTitle().then(function (res) {
            _this.titleService.setTitle(res);
        });
    };
    PageService.prototype.getDefaultPageTitle = function () {
        return this.getTranslation('DefaultPageTitle');
    };
    PageService.prototype.getTranslation = function (paramName) {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.translate.get(paramName).subscribe(function (res) {
                resolve(res);
            });
        });
        return returnPromise;
    };
    PageService.ngInjectableDef = i0.defineInjectable({ factory: function PageService_Factory() { return new PageService(i0.inject(i1.Title), i0.inject(i2.TranslateService)); }, token: PageService, providedIn: "root" });
    return PageService;
}());
export { PageService };
