import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { IOrder, Patient } from '@/interfaces';
import { OrdersService, UsersService } from '@/services';
import moment from 'moment';
import { CovidThreshold } from '@/interfaces/AIConsultation';

interface IAction {
    action: string;
    index: number;
    id?: number;
    patient?: Patient;
}

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, OnChanges {

    @Input() orders: IOrder[] = [];
    @Input() hours = [];
    @Input() dates = [];
    @Input() age = [];
    @Input() type: string;
    @Input() patient: Patient = null;

    ordersService: OrdersService;
    userService: UsersService;
    copiedText: string = '';

    statusDescriptionArray: string[] = [];

    @Output() actionEmitter: EventEmitter<IAction> = new EventEmitter<IAction>();
  CovidThreshold: number = CovidThreshold;

    constructor(ordersService: OrdersService, userService: UsersService, private translateService: TranslateService) {
        this.ordersService = ordersService;
        this.userService = userService;
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.orders) {
            this.statusDescriptionArray = [];
            changes.orders.currentValue.forEach(e => {
                this.statusDescriptionArray.push(this.statusDescription(e));
            });
        }
    }

    statusDescription(or: IOrder): string {
        let [orderID, orderStatusName, orderStatus] = [or.id_exam_order, or.status_info.status_name, or.status_info.status];
        if (orderStatusName === 'TO_APPOVE_BY_TIME') {
            let timeLeft = 10 - Math.floor(moment.duration(moment().diff(or.description_date)).asMinutes());
            if (timeLeft < 0) {
                timeLeft = 0;
            }
            return `<span class="dot green-border"></span> ${orderStatus}...<br>\
            <span class="light">(${this.getTimeLeftString(timeLeft)})</span>`;
        } else if (orderStatus === 'Nowe') {
            return `<span class="dot"></span> ${orderStatus}`;
        } else if (orderStatus === 'Przypisane') {
            return `<span class="dot light"></span> ${orderStatus}`;
        } else if (orderStatus === 'W opisie') {
            return `<span class="dot orange"></span> ${orderStatus}`;
        } else if (orderStatus === 'Odrzucone') {
            return `<span class="dot red"></span> ${orderStatus}`;
        } else if (orderStatus === 'Zatwierdzone') {
            return `<span class="dot green"></span> ${orderStatus}`;
        } else if (orderStatus === 'Zwrócone') {
            return `<span class="dot red"></span> ${orderStatus}`;
        } else {
            return orderStatus;
        }
    }

    getTimeLeftString(time): string {
        let result = '';
        this.translateService.get('OrderDescriptionScreens.SendIn').subscribe((res: string) => {
            result += res + ' ' + time + ' ';
        });
        let timestring = '';
        if ((time <= 10 && time >= 5) || time == 0) {
            timestring = 'OrderDescriptionScreens.Time1';
        } else if (time <= 4 && time >= 2) {
            timestring = 'OrderDescriptionScreens.Time2';
        } else timestring = 'OrderDescriptionScreens.Time3';

        this.translateService.get(timestring)
        .subscribe((res: string) => {
            result += res;
        });
        return result;
    }

    makeAction(action: string, index: number, id: number): void {
        if (this.type != 'Patients') {
            this.actionEmitter.emit({
                action: action,
                index: index
            });
        } else {
            this.actionEmitter.emit({
                action: action,
                index: index,
                id: id,
                patient: this.patient
            });
        }
    }
}
