import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { AssignPhysicianModalComponent } from '@/utils/assign-physician-modal/assign-physician-modal.component';
import { RejectModalComponent } from '@/utils/reject-modal/reject-modal.component';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { IAction, IFiltrDashboard, IFiltrPatients, IOrder, Patient } from '@/interfaces';
import { OdataService, OrderActionsService, UsersService } from '@/services';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '@/services/page.service';
import moment from 'moment';
import { initConsultation } from '@/interfaces/AIConsultation';

@Component({
    selector: 'app-medical-facility-patients',
    templateUrl: './medical-facility-patients.component.html',
    styleUrls: ['./medical-facility-patients.component.scss']
})
export class MedicalFacilityPatientsComponent implements OnInit, OnDestroy {
    @ViewChild('filters') filters: FiltersComponent;
    @ViewChild('assignPhysicianModal') assignPhysicianModal: AssignPhysicianModalComponent;
    @ViewChild('rejectModal') rejectModal: RejectModalComponent;
    @ViewChild('loader') loader: LoaderComponent;

    patients: Patient[] = [];
    isShown: boolean[] = [];
    ordersIndex = 0;
    patientsFilter: IFiltrPatients = null;
    ordersFilter: IFiltrDashboard;
    sortColumn: string = 'surname';
    sortType: string = 'ASC';

    patientsOnPage: string = '10';
    numberOfPages: number = 0;
    numberOfPatients: number = 0;
    pages: number[] = [];
    activePage: number = 1;
    inputPatientsPage: string = '1';
    inputPagePatientsError: boolean = false;
    ordersOnPage: string = '10';
    numberOfOrderPages: number = 0;
    numberOfOrders: number[] = [];
    orderPages: number[][] = [];
    activeOrderPage: number = 1;
    inputPage: string = '1';
    inputPageError: boolean = false;

    coExamActionAssignToMe: Function = (examId: number, patient: Patient) => {
        this.orderActions.coExamActionAssignToMe(examId).then(
            res => {
                if (res) {
                    this.getExamOrderForPatient(patient);
                }
            }
        );
    }

    coExamActionAssignToPhysian: Function = (examId: number, patient: Patient) => {
        this.assignPhysicianModal.get_co_describing_physicians(examId).then((res) => {
            if (res) {
                this.assignPhysicianModal.show(examId);
            }
        });
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionAssignToPhysian(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient);
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamActionReject = (examId: number, patient: Patient) => {
        this.rejectModal.show(examId);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionReject(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient);
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamActionRejectByPhysician: Function = (examId: number, patient: Patient) => {
        this.rejectModal.show(examId);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionRejectByPhysician(examId, value).then(
                    res => {
                        if (res) {
                            this.getExamOrderForPatient(patient);
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    actionsList = {
        'ASSIGN_MYSELF': this.coExamActionAssignToMe,
        'START_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'medicalFacilityPatients/' + JSON.stringify(this.patientsFilter);
            return this.orderActions.coExamActionStartDescrption(examId);
        },
        'ASSIGN_PHYSICIAN': this.coExamActionAssignToPhysian,
        'REJECT': this.coExamActionReject,
        'ASSIGN_AND_START_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'medicalFacilityPatients/' + JSON.stringify(this.patientsFilter);
            return this.orderActions.coExamActionAssignAndStartDescription(examId);
        },
        'VIEW_EXAM_ORDER': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'medicalFacilityPatients/' + JSON.stringify(this.patientsFilter);
            return this.orderActions.viewExamOrder(examId);
        },
        'REJECT_BY_PHYSICIAN': this.coExamActionRejectByPhysician
    };

    constructor(private odataService: OdataService, private userService: UsersService, private route: ActivatedRoute,
        private orderActions: OrderActionsService, private router: Router, private pageService: PageService) {
        this.ordersFilter = FiltersComponent.createInitialIFiltrDashboard();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('filter') == null) {
                this.patientsFilter = FiltersComponent.createInitialFiltrPatients();
            } else {
                this.patientsFilter = JSON.parse(decodeURI(params.get('filter')));
            }
            this.filters.patientsFilter = this.patientsFilter;
        });

        this.getNumberOfPatients();
        this.getPatientViewList();

        this.patientsFilter = FiltersComponent.createInitialFiltrPatients();
        this.pageService.setTranslatedPageTitle('PatientsScreen.Title', 0);
        document.getElementById('facilityPatients').setAttribute('class', 'active');
    }

    ngOnDestroy() {
        document.getElementById('facilityPatients').setAttribute('class', '');
        this.pageService.resetPageTitle();
    }

    showOrders(i: number) {
        this.inputPageError = false;
        this.inputPage = '1';
        if (this.isShown[i]) {
            this.isShown[i] = false;
        } else {
            for (let j = 0; j < this.patients.length; j++) {
                this.isShown[j] = false;
            }
            this.isShown[i] = true;
        }
    }

    dropdown() {
        this.filters.dropdown();
    }

    changePage(page: number) {
        if (page <= this.pages.length) {
            this.activePage = page;
            this.inputPatientsPage = page + '';
            this.isShown = [];
            this.getPatientViewList();
            this.getNumberOfPatients();
            this.inputPagePatientsError = false;
        }

        window.scroll(0, 0);
    }

    changeOrdersPage(index: number, page: number) {
        if (page <= this.orderPages[index].length) {
            this.activeOrderPage = page;
            this.inputPage = page + '';
            this.getExamOrderForPatient(this.patients[index]);
            this.inputPageError = false;
        }
    }

    changeNumberOfPages() {
        this.activePage = 1;
        this.inputPatientsPage = '1';
        this.isShown = [];
        this.getPatientViewList();
    }

    changeNumberOfOrdersPages(index: number) {
        this.activeOrderPage = 1;
        this.inputPage = '1';
        this.getExamOrderForPatient(this.patients[index]);
    }

    getNumberOfPatients() {
        this.numberOfPatients = 3;
    }

    getPatientViewList() {
        this.loader.show = true;

        this.patients = [
            this.parseIntoPatient({
                birth_day: '1984-06-26',
                first_name: 'HALINA',
                id_patient: 1,
                last_name: 'DERPEŃSKA',
                pesel: '84062619428',
                sex: 'F'
            }),
            this.parseIntoPatient({
                birth_day: '1948-11-06',
                first_name: 'IRENA',
                id_patient: 301,
                last_name: 'GOLAK',
                pesel: '48110607807',
                sex: 'F'
            }),
            this.parseIntoPatient({
                birth_day: '1995-02-12',
                first_name: 'ARTUR',
                id_patient: 303,
                last_name: 'MOGIELNICKI',
                pesel: '95021200018',
                sex: 'M'
            })
        ];
        for (let i = 0; i < this.numberOfPatients; i++) {
            this.isShown.push(false);
            this.getNumberOfOrdersForPatient();
            this.getExamOrderForPatient(this.patients[i]);
        }
        this.loader.show = false;
    }

    getExamOrderForPatient(patient: Patient) {
        let orders: IOrder[] = [{
            actions_info: [
                { id_action: 3005, action_name: 'VIEW_EXAM_ORDER', button_name: 'Podgląd', action_importance: 5 }
            ],
            assign_physician_info: { physician_assign: 'Piotr Filipczuk' },
            exam_info: {
              consultation: initConsultation,
                anatomy: { id_part: 5, part_name: 'Jama brzuszna', anatomy_part_text: '' },
                birth_date: '1981-04-27',
                evo_accession_number: '000A000000000059',
                exam_date: null,
                exam_order_notes: 'Zlecenie automatyczne z Placówka Medyczna 1',
                his_exam_id: '1/16502',
                his_patient_id: '1/16502',
                icd10: '',
                modality: { id_group: 1, modality: 'RTG' },
                order_exam_type: 'JAMA BRZUSZNA',
                ordering_facility_name: 'Placówka Medyczna 1',
                describing_facility_name : 'Oppis1',
                patient_name: 'MARZENA GIEŁDA',
                pesel: '81042703683',
                priority: { priority: 'EMERGENCY', id_priority: 3, priority_text: '', priority_value: 0 },
                transfer_progress: 100,
                pm_accession_number: '156-88',
            },
            id_exam_order: 10,
            exam_reject_reason: '',
            order_date: '2019-06-13T16:37:18.018+02:00',
            status_info: { status: 'Odrzucone', id_status: 3, status_name: 'REJECTED' },
            reject_info: {
                reject_time: '',
                reject_reason: ''
              }
        }];
        this.orderPages.push([]);
        this.numberOfOrderPages = this.numberOfOrders[this.numberOfOrders.length - 1] / parseInt(this.ordersOnPage);
        Math.floor(this.numberOfOrderPages);
        for (let i = 0; i < this.numberOfOrderPages; i++) {
            this.orderPages[this.orderPages.length - 1].push(i + 1);
        }
        patient.hours = [];
        patient.dates = [];
        patient.patients_orders = [];
        for (let i = 0; i < orders.length; i++) {
            moment.locale(this.userService.iLanguage);
            let date = moment(orders[i].order_date).format('DD-MM-YYYY, HH:mm');
            let hour = moment(orders[i].order_date).startOf('hour').fromNow();
            patient.hours.push(hour);
            patient.dates.push(date);
            orders[i].actions_info.sort((a1: IAction, a2: IAction) => {
                if (a1.action_importance > a2.action_importance) return -1;
                else if (a1.action_importance == a2.action_importance) return 0;
                else return 1;
            });
            patient.patients_orders.push(orders[i]);
        }
    }

    getNumberOfOrdersForPatient() {
        this.numberOfOrders.push(1);
    }

    makeAction(action: string, id: number, patient: Patient): void {
        if (action == 'START_DESCRIPTION' || action == 'VIEW_EXAM_ORDER' || action == 'ASSIGN_AND_START_DESCRIPTION') {
            this.actionsList[action](id).then(res => {
                if (res) {
                    if (action == 'VIEW_EXAM_ORDER') {
                        sessionStorage.setItem('mode', 'view');
                        this.router.navigate(['/orderDescription/' + id + '/currentDescription'], { queryParams: { mode: 'view' } });
                    } else {
                        sessionStorage.setItem('mode', 'description');
                        this.router.navigate(['/orderDescription/' + id + '/currentDescription'], { queryParams: { mode: 'description' } });
                    }
                }
            });
        } else {
            this.actionsList[action](id, patient);
        }
    }

    parseIntoPatient(obj: any): Patient {
        let patient: Patient = {
            sex: obj.sex,
            pesel: obj.pesel,
            birth_date: obj.birth_date,
            last_name: obj.last_name,
            first_name: obj.first_name,
            id_patient: obj.id_patient,
            exam_count: obj.exam_count,
            patients_orders: [],
            age: new Date().getFullYear() - parseInt(obj.birth_date),
            dates: [],
            hours: []
        };

        return patient;
    }

    goToPageOrders(index: number, page: string) {
        let pageNumber: number = parseInt(page);
        if (pageNumber && pageNumber <= this.orderPages[index].length && pageNumber > 0) {
            this.changeOrdersPage(index, pageNumber);
        } else {
            this.inputPageError = true;
        }
    }

    goToPagePatients(page: string) {
        let pageNumber: number = parseInt(page);
        if (pageNumber && pageNumber <= this.pages.length && pageNumber > 0) {
            this.changePage(pageNumber);
        } else {
            this.inputPagePatientsError = true;
        }
    }

    getSort(): void {
        this.patientsFilter.sort.sort_column = this.sortColumn;
        this.patientsFilter.sort.sort_type = this.sortType;
    }

    filterPatients(filter: IFiltrPatients): void {
        this.patientsFilter = filter;
        this.getSort();
        window.history.pushState({}, '', '/medicalFacilityPatients/' + JSON.stringify(filter));
        this.getPatientViewList();
    }

}
