import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertComponent } from '..';

@Component({
  selector: 'app-insuff-chars-modal',
  templateUrl: './insuff-chars-modal.component.html',
  styleUrls: ['./insuff-chars-modal.component.scss']
})
export class InsuffCharsModalComponent implements OnInit {

  no: number;

  @ViewChild('alert') alert: AlertComponent;
  
  constructor() {
  }

  ngOnInit() {

  }

  show(charNumber): void {
    this.no = charNumber;
    $('#insuffCharsModal').modal('show');
  }

  hide(): void {
    $('#insuffCharsModal').modal('hide');
  }

}
