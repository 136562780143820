import { Injectable } from '@angular/core';
import { OdataService } from './odata.service';
import { ActivatedRoute } from '@angular/router';

export interface IUser {
  UserID: number;
  LoginName: string;
  FirstName: string;
  LastName: string;
  DocumentType: string;
  ValueId: string;
  Active: boolean;
  Email: string;
  Citizenship: string;
  PhoneNumber: string;
  Birthday: string;
  Verified: boolean;
  AccountBlockedPermamently: boolean;
  FailedAttempts: number;
  BlockedTimestamp: string;
  PhoneNumberVerified: boolean;
  EmailVerified: boolean;
  AccountDeleted: boolean;
}

@Injectable({
  providedIn: 'root'
})



export class AdminService {

  users: IUser[] = [];
  usersData: any;

  constructor(private odataService: OdataService, private route: ActivatedRoute) { }

  getUsers(params: any, callback: Function): void {
    this.odataService.getPrivate('Users', params, callback);
  }

  getUserData(id: number, params: any, callback: Function): void {

    this.odataService.getPrivate('Users(' + id + ')', params, callback);
  }

  editUser(id: number, body: any, params: any, callback: Function): void {
    this.odataService.patchPrivate('Users(' + id + ')', body, params, callback);
  }

  addUser(params: any, callback: Function): void {
    this.odataService.postPrivate('add_user', params, callback);
  }

  getCenters(params: any, callback: Function): void {
    this.odataService.postPrivate('get_centers', params, callback);
  }

  getCenterData(id, callback: Function): void {
    this.odataService.postPrivate('get_center_data', this.odataService.paramsTokenAndLang(
      {id_describing_center: id}), callback);
  }

  editCenter(params: any, callback: Function): void {
    this.odataService.postPrivate('edit_center', params, callback);
  }

  addCenter(params: any, callback: Function): void {
    this.odataService.postPrivate('add_center', params, callback);
  }

  getOrdersForAdmin(params: any, callback: Function): void {
    this.odataService.postPrivate('admin_get_co_exam_orders_ext', params, callback);
  }

  getFacilities(params: any, callback: Function): void {
    this.odataService.postPrivate('get_medical_facilities', params, callback);
  }

  getExams(params: any, callback: Function): void {
    this.odataService.postPrivate('get_medical_facilities_exams', params, callback);
  }

  getCOTransfers(params: any, callback: Function): void {
    this.odataService.postPrivate('admin_co_transfer_list', params, callback);
  }

  getUsers2(): Promise<any> {
    this.usersData = this.odataService.postPrivate('get_users',
      this.odataService.paramsTokenAndLang({
        offset: 0,
        limit: 10
      }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          let value = JSON.parse(result.Value);
          this.setUsersData(value);
        } else {
          // TODO
        }
      });
    return this.usersData;
  }

  setUsersData(data: any): void {
    let usersData = data;
    usersData.forEach(singleUserData => {
      let user: IUser = this.parseIntoUser(singleUserData);
      this.users.push(user);
    });
  }

  parseIntoUser(obj: any): IUser {
    let user: IUser = {
      UserID: obj['id_user'],
      LoginName: obj['login_name'],
      FirstName: obj['first_name'],
      LastName: obj['last_name'],
      DocumentType: obj['id_document_type'],
      ValueId: obj['id_value'],
      Active: obj['active'],
      Email: obj['email'],
      Citizenship: obj['citizenship'],
      PhoneNumber: obj['phone_number'],
      Birthday: obj['birthday'],
      Verified: obj['account_verified'],
      AccountBlockedPermamently: obj['account_blocked_permamently'],
      FailedAttempts: obj['login_failed_attemps'],
      BlockedTimestamp: obj['account_blocked_timestamp'],
      PhoneNumberVerified: obj['phone_number_verified'],
      EmailVerified: obj['email_verified'],
      AccountDeleted: obj['account_deleted'],
    };
    return user;
  }

  getCOStaff(params: any, callback: Function) {
    this.odataService.postPrivate('admin_get_co_staff', params, callback);
  }
}
