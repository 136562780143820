import { FacilityOrdersService } from './../services/facility-orders.service';
import { NotificationsService } from './../services/notifications.service';
import { MessagesService } from './../services/messages.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Alert,
  CookiesService,
  DCenter,
  DisplayService,
  Functions,
  NavigationService,
  Notification,
  OdataService,
  OrdersService,
  SessionService,
  Timer,
  UsersService
} from '@/services';
import { Router } from '@angular/router';
import { RegistrationService } from '@/services/registration.service';
import { IMessage } from './dmInterface';
import { InsufficientRightsModalComponent } from '@/utils/insufficient-rights-modal/insufficient-rights-modal.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { EnvService } from '@/services/env.service';

import { BsDatepickerInlineConfig } from 'ngx-bootstrap/datepicker/bs-datepicker-inline.config';
import { PortfolioService } from '@/services/portfolio.service';
import { ContextsService } from '@/services/contexts.service';
import { IMedFacility } from '@/interfaces';
import set = Reflect.set;
import {FiltersService} from '@/services/filters.service';
import {GenerateResultPairModalComponent} from '@/utils/generate-result-pair-modal/generate-result-pair-modal.component';

@Component({
    selector: 'app-dm',
    templateUrl: './dm.component.html',
    styleUrls: ['./dm.component.scss']
})
export class DMComponent implements OnInit, OnDestroy {

    @ViewChild('insufficientRightsModal') insufficientRightsModal: InsufficientRightsModalComponent;
    @ViewChild('generateResultPairModal') generateResultPairModal: GenerateResultPairModalComponent;

    timer: Timer;
    messages: IMessage[] = [];
    alerts: Alert[] = [];
    notifications: Notification[] = [];
    timeout;
    odataService: OdataService;
    ordersService: OrdersService;
    userService: UsersService;
    stickySidebarFunction: any;
    contextService: ContextsService;
    navigationService: NavigationService;

    date = new Date();
    dp_config: Partial<BsDatepickerInlineConfig> = {};
    isOpen: boolean = false;
    styleTimeout: any = null;
    intervalDc: any;
    intervalMf: any;
    hover: boolean = false;

    displayDcSettings: boolean;

    constructor(userService: UsersService, private router: Router, ordersService: OrdersService,
        odataService: OdataService, private sessionService: SessionService,
        private registrationService: RegistrationService, navigationService: NavigationService,
        public envService: EnvService, private localeService: BsLocaleService, private cookiesService: CookiesService,
        private portfolioService: PortfolioService, contextService: ContextsService, public messagesService: MessagesService,
        public notificationsService: NotificationsService, public ordersFacilityService: FacilityOrdersService,
                private filtersService: FiltersService) {

        this.odataService = odataService;
        this.userService = userService;
        this.ordersService = ordersService;
        this.contextService = contextService;
        this.navigationService = navigationService;

        this.setLang(this.sessionService.language);

        this.dp_config = Object.assign({}, { containerClass: 'theme-default' });
        this.subscibeChangeLang();
    }

    ngOnInit() {
        this.checkSessionCookie();

        if (sessionStorage.getItem('DESCRIBING_CENTER') != null) {
            this.userService.dcListPromise = this.getDcList();
            this.userService.dcListPromise.then(() => {
                this.ordersService.getExamOrdersRefreshStatus();
                this.intervalDc = setInterval(() => {
                    this.ordersService.getExamOrdersRefreshStatus();
                }, 60000);
            });
           // this.canDisplayDcSettings(this.userService.getCenterId());
        }

        if (sessionStorage.getItem('MEDICAL_FACILITY') != null) {
            this.userService.mfListPromise = this.getUserMedicalFacilities();
            this.userService.mfListPromise.then(() => {
                this.ordersFacilityService.getNumberOfOrders();
                this.intervalMf = setInterval(() => {
                    this.ordersFacilityService.getNumberOfOrders();
                }, 60000);
            });
        }

        if (sessionStorage.getItem('DESCRIBING_CENTER') != null || sessionStorage.getItem('PHYSICIAN') != null) {
            if (!this.envService.hideUnderConstruction) this.stickySidebarFunction = DisplayService.createStickySidebar('sidebar', 890, 0, 0, null);
            else {
                this.setSidebar();
            }
        }

        if (localStorage.getItem('darkMode') != 'true') {
            localStorage.setItem('darkMode', 'false');
        } else {
            let elem = document.getElementById('secondDMSidebarWrapper');
            if (elem) {
                elem.style.transition = 'width 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000)';
                this.styleTimeout = setTimeout(() => {
                    elem.style.transition = 'all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000)';
                    this.styleTimeout = null;
                }, 100);
            }
        }

        this.listenToRightsError();
        this.notifications = Functions.getNotifications();
        this.alerts = Functions.getAlerts();
        this.timer = new Timer();

        DisplayService.setMode();

        this.userService.userDataPromise.then(() => {
            window.onstorage = (ev) => {
                if (ev.key == 'user' + this.userService.userId) {
                    if (ev.newValue == 'lock') {
                        this.lockScreen(false);
                    } else if (ev.newValue == 'logout') {
                        this.logOut(false);
                    }
                }
            };
        });
    }

    ngOnDestroy() {
        if (this.styleTimeout) clearTimeout(this.styleTimeout);
        clearTimeout(this.timeout);
        if (this.intervalDc) clearInterval(this.intervalDc);
        if (this.intervalMf) clearInterval(this.intervalMf);
        this.timer.clearTimeout();
        window.removeEventListener('scroll', this.stickySidebarFunction);
        window.removeEventListener('storage', window.onstorage);
    }

    getUserMedicalFacilities(): Promise<any> {
        return this.userService.getMfList().then(
            res => {
                let facilityIndex = this.getFacilityIndex();
                this.userService.userDataPromise.then(() => {
                    if (sessionStorage.getItem('currentContext') == 'MEDICAL_FACILITY') {
                        this.changeMedicalFacility(facilityIndex);
                    }
                });
            }
        );
    }

    setSidebar() {
        if (sessionStorage.getItem('currentContext') == 'MEDICAL_FACILITY') {
            document.getElementById('sidebar').style.height = '790px';
            document.getElementById('dmSidebarWrapper').style.minHeight = '790px';
            this.stickySidebarFunction = DisplayService.createStickySidebar('sidebar', 790, 0, 0, null);
        } else if (sessionStorage.getItem('currentContext') == 'DESCRIBING_CENTER') {
            document.getElementById('sidebar').style.height = '650px';
            document.getElementById('dmSidebarWrapper').style.minHeight = '650px';
            this.stickySidebarFunction = DisplayService.createStickySidebar('sidebar', 650, 0, 0, null);
        } else {
            document.getElementById('sidebar').style.height = '650px';
            document.getElementById('patientSidebarWrapper').style.minHeight = '650px';
            this.stickySidebarFunction = DisplayService.createStickySidebar('sidebar', 650, 0, 0, null);
        }
    }

    subscibeChangeLang() {
        this.sessionService.emitter.subscribe((res) => {
            this.setLang(res);
        });
    }

    setLang(lang: string): void {
        switch (lang) {
            case 'en':
                this.localeService.use('en');
                break;
            default:
                this.localeService.use('pl');
        }
    }

    hideFilter() {
        sessionStorage.setItem('activeFilter', 'false');
        document.getElementById('filter_wrapper').style.overflow = 'hidden';
        $('.filter_dropdown a').removeClass('active');
        $('.filter_component_bg').removeClass('active');
        $('.filter_component_wrapper').removeClass('active');
    }

    getDcList(): Promise<any> {
        return this.userService.getDcList().then(
            res => {
                let centerIndex: any = this.getCenterIndex();
                this.userService.userDataPromise.then(() => {
                    if (sessionStorage.getItem('currentContext') == 'DESCRIBING_CENTER') {
                        this.changeCenter(centerIndex.index, centerIndex.verified);
                    }
                });
            }
        );
    }

    getCenterIndex(): any {
        let index: number = -1;
        let verified: boolean = true;
        if (sessionStorage.getItem('currentCenter')) {
            let currCenter: number = parseInt(sessionStorage.getItem('currentCenter'));
            this.userService.descCenters.forEach((x, i) => {
                if (x.idDescribingCenter == currCenter) {
                    index = i;
                }
            });

            if (index == -1) {
                this.userService.unverifiedDescCenters.forEach((x, i) => {
                    if (x.idDescribingCenter == currCenter) {
                        index = i;
                    }
                });
                verified = false;
            }
        }

        if (index == -1) {
            if (this.userService.descCenters.length > 0) return {index: 0, verified: true};
            else return {index: 0, verified: false};
        }

        return {index: index, verified: verified};
    }

    getFacilityIndex(): number {
        let index = -1;
        if (sessionStorage.getItem('currentFacility') || this.ordersService.facilityId) {
          // using facility id if storage not updated yet. If site refreshed - id from storage
            let key = this.ordersService.facilityId ? this.ordersService.facilityId : parseInt(sessionStorage.getItem('currentFacility'));
            this.userService.medFacilities.forEach((x, i) => {
                if (x.idMedicalFacility == key) {
                    index = i;
                }
            });
        }

        if (index == -1) {
            return 0;
        }

        return index;
    }

    parsePanelInfo(res) {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        return JSON.parse(result.Value);
    }

    listenToRightsError() {
        this.odataService.errorsEmitter.subscribe(event => {

            if (event === 'INSUFFICIENT_RIGHTS') {
                setTimeout(() => {this.insufficientRightsModal.show(); }, 1500);
            }
        });
    }

    changeCenterAndNavigate(i: number, verified: boolean): void {
        this.isBackToDarkMode();
        this.navigationService.setCurrentContext('DESCRIBING_CENTER');
        this.setSidebar();
        if (verified) sessionStorage.setItem('currentCenter', JSON.stringify(this.userService.descCenters[i].idDescribingCenter));
        else sessionStorage.setItem('currentCenter', JSON.stringify(this.userService.unverifiedDescCenters[i].idDescribingCenter));
        sessionStorage.setItem('centerChanged', 'true');
        sessionStorage.removeItem('currentFacility');
        this.ordersService.facilityId = null;
        // clearing saved filter searches and filter date settings
        this.filtersService.clearSavedSearchAndFilters();
        this.navigationService.navigateToDefault();
    }

    changeCenter(i: number, verified: boolean): void {
        let center: DCenter;
        if (verified) center = this.userService.descCenters[i];
        else center = this.userService.unverifiedDescCenters[i];
        this.ordersService.setId(center.idDescribingCenter);
        this.userService.changeDescCenter(center);
        this.userService.getUserDcPermissions(this.userService.getCenterId());
    }

    changeMedicalFacilityAndNavigate(i: number) {
        sessionStorage.setItem('currentFacility', JSON.stringify(this.userService.medFacilities[i].idMedicalFacility));
        this.ordersService.facilityId = this.userService.medFacilities[i].idMedicalFacility; // setting new id as it's faster than storage -> for initial examss loading
        this.isBackToDarkMode();
        this.odataService.clearRequests();
        this.ordersService.clearAllOrdersData();
        this.navigationService.setCurrentContext('MEDICAL_FACILITY');
        sessionStorage.removeItem('currentCenter');
        this.setSidebar();
        // removing filter date settings if the same would be used in mf
        this.filtersService.clearSavedSearchAndFilters();
        this.navigationService.navigateToDefault();
    }

    changeMedicalFacility(i: number): void {
        let facility: IMedFacility = this.userService.medFacilities[i];
        this.ordersService.setFacilityId(facility.idMedicalFacility);
        this.userService.changeMedFacility(facility);
    }

    goToPatientPortal() {
        localStorage.setItem('wasInDarkMode', localStorage.getItem('darkMode'));
        sessionStorage.removeItem('currentFacility');
        sessionStorage.removeItem('currentCenter');
        this.odataService.clearRequests();
        this.ordersService.clearAllOrdersData();
        if (this.navigationService.hasContext('PHYSICIAN')) {
            this.navigationService.setCurrentContext('PHYSICIAN');
        } else this.navigationService.setCurrentContext('PATIENT');
        this.setSidebar();
        this.navigationService.navigateToDefault();
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove() {
        clearTimeout(this.timeout);
        this.setNewTimeout();
    }

    @HostListener('document:keypress', ['$event'])
    onKeyPress() {
        clearTimeout(this.timeout);
        this.setNewTimeout();
    }

    setNewTimeout() {
        this.cookiesService.createSessionCookie();
        this.timeout = setTimeout(() => {
            this.lockScreen(true);
        }, 900000);
    }

    lockScreen(sendMessage: boolean): void {
        if (sendMessage) this.sessionService.sendMessage('user' + this.userService.userId, 'lock');

        this.odataService.clearRequests();
        sessionStorage.setItem('currentPage', decodeURI(this.router.url.toString()));
        let modal = document.getElementsByClassName('modal-backdrop fade show');
        if (modal.item(0) != null) {
            modal.item(0).setAttribute('class', '');
        }
        this.sessionService.lockSession();
        this.router.navigate(['unlockScreen']);
    }

    switchMode(): void {
        DisplayService.switchMode();
    }

    switchToFullScreen(): void {
        DisplayService.fullScreen();
    }

    isDarkMode(): boolean {
        return localStorage.getItem('darkMode') === 'true';
    }

    logOut(sendMessage: boolean): void {
        if (sendMessage) this.sessionService.sendMessage('user' + this.userService.userId, 'logout');

        this.odataService.clearRequests();
        sessionStorage.clear();
        this.sessionService.closeSession(this.odataService);
        this.sessionService.clearAllSessionInfo();
        this.registrationService.isLogout = true;
        this.registrationService.clearAllData();
        this.userService.clearUserData();
        this.portfolioService.clearPortfolioData();
        this.ordersService.clearAllOrdersData();
        this.router.navigate(['loginFirst']);
    }

    checkSessionCookie(): void {
        const cookie = this.cookiesService.getCookie('sessionControl');
        if (cookie != 'true')
            this.lockScreen(true);
    }

    resetDashboardSettings() {
        this.sessionService.removeCurrentPage('Dashboard');
        this.sessionService.removeItemsOnPage('Dashboard');
    }

    resetArchiveSettings() {
        this.sessionService.removeCurrentPage('Archive');
        this.sessionService.removeItemsOnPage('Archive');
    }

    resetPatientsSettings() {
        this.sessionService.removeCurrentPage('Patients');
        this.sessionService.removeItemsOnPage('Patients');
    }

    resetProfilesSettings() {
        this.sessionService.removeCurrentPage('Profiles');
        this.sessionService.removeItemsOnPage('Profiles');
    }

    resetDoctorsAndDcProfilesSettings() {
        this.sessionService.removeCurrentPage('doctorsProfiles');
        this.sessionService.removeItemsOnPage('doctorsProfiles');
        sessionStorage.removeItem('dcProfilesActivePage');
        sessionStorage.removeItem('dcProfilesItemsOnPage');
        sessionStorage.removeItem('goToDcProfile');
    }

    inviteToCoworking() {
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            this.router.navigate(['profiles']);
        } else if (this.contextService.checkPermission('doctorsAndCoProfiles')) {
          if (this.navigationService.getCurrentContext() == 'PHYSICIAN') { sessionStorage.setItem('inviteDc', 'true'); }
          this.router.navigate(['/doctorsAndCoProfiles'], );
        }
    }

    goToDcProfile() {
        sessionStorage.removeItem('newDcId');
        this.router.navigate(['/coProfile']);
    }

    isBackToDarkMode() {
        if (localStorage.getItem('wasInDarkMode') == 'true' && localStorage.getItem('darkMode') == 'false') this.switchMode();
        localStorage.removeItem('wasInDarkMode');
    }
}
