import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrdersService, UsersService } from '@/services';
import {
  DATA_AGREEMENT_SKIPPED,
  IShareInfo,
  DATA_AGREEMENT_NO_OPTION,
  PatientDashboardExam,
  CLOUD_NO_AGREEMENT
} from '@/interfaces/PatientsPortal';
import { PatientPortalService } from '@/services/patient-portal.service';
import { Router } from '@angular/router';
import { PatientExamUploadModalComponent } from '@/utils/patient-exam-upload-modal/patient-exam-upload-modal.component';
import moment from 'moment';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { PatientShareExamModalComponent } from '@/utils/patient-share-exam-modal/patient-share-exam-modal.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { PatientAgreementCardComponent } from '@/utils/patient-agreement-card/patient-agreement-card.component';
import { CloudAgreementModalComponent } from '@/utils/cloud-agreement-modal/cloud-agreement-modal.component';

@Component({
  selector: 'app-patient-exam-list',
  templateUrl: './patient-exam-list.component.html',
  styleUrls: ['./patient-exam-list.component.scss']
})
export class PatientExamListComponent implements OnInit {

  @ViewChild('patientExamUploadModal') patientExamUploadModal: PatientExamUploadModalComponent;
  @ViewChild('loader') loader: LoaderComponent;
  @ViewChild('shareExamModalComponent') shareExamModal: PatientShareExamModalComponent;
  @ViewChild('patientAgreementCard') patientAgreementCard: PatientAgreementCardComponent;
  @ViewChild('cloudAgreementModal') cloudAgreementModal: CloudAgreementModalComponent;

  examList: PatientDashboardExam[] = [];
  patientExamSearchOption: number = DATA_AGREEMENT_NO_OPTION;
  patientCloudAgreement: number = CLOUD_NO_AGREEMENT;
  readonly AGREEMENT_SKIPPED: number = DATA_AGREEMENT_SKIPPED;
  readonly NO_CLOUD: number = CLOUD_NO_AGREEMENT;
  folderShareInfo: IShareInfo;

  constructor(private router: Router, private userService: UsersService, public ordersService: OrdersService,
              private portalService: PatientPortalService, public orderService: OrdersService) { }

  ngOnInit() {
    this.refreshShareInfo();

    // Temporary fix for global classes
    let body = document.getElementsByTagName('body')[0];
        body.classList.add('pp-portal');
    let menuActiveElement = document.getElementById('examsLink');
        menuActiveElement.classList.add('active');

    this.userService.userDataPromise.then(e => {
      this.patientExamSearchOption = this.userService.getPatientSearchDataAgreement();
      this.patientCloudAgreement = this.userService.getPatientCloudAgreement();
      if (this.patientExamSearchOption != DATA_AGREEMENT_NO_OPTION) this.getExamList().then(res => this.loader.show = false);
      else this.loader.show = false;
    });
  }

  getExamList(): Promise<any> {
    return this.portalService.getExamList(7).then( res =>  {
      this.examList = res;
    });
  }

  refreshShareInfo() {
    this.portalService.getFolderShareStatus().then( e => this.folderShareInfo = this.portalService.folderShareInfo);
  }

  seeExam(examId: number, examType: number) {
    this.router.navigate(['badania/szczegoly'], {queryParams: {id: examId, type: examType}});
  }

  uploadExamFromDisc() {
    this.patientExamUploadModal.show();
  }

  isAgreementCard(): boolean {
      return this.patientExamSearchOption == DATA_AGREEMENT_NO_OPTION;
  }

  resetAgreement() {
    this.userService.changePatientSearchDataAgreement(DATA_AGREEMENT_NO_OPTION).then(res => this.patientExamSearchOption = DATA_AGREEMENT_NO_OPTION);
  }
}
