<ng-container *ngIf="examType == 1">

  <div class="pp--cards-columns">
    <div class="pp--card-column pp--card-column-big">

      <div class="pp--card brb-0 exam_bg exam_bg--{{examImages.imageClassName}}" id="examPhotoPlaceholder">

        <a style="cursor: pointer" class="back" (click)="navigateBack()">
          <svg-icon src="../../../../assets/images/icons/topbar/icon__arrow_left.svg" class="svg">
          </svg-icon>
          {{'PatientsPortal.ExamDetails.Back' |translate}}
        </a>

        <div class="examPhotoPlaceholder--disclaimer">{{'PatientsPortal.ExamDetails.PhotoDisclaimer' |translate}}</div>

      </div>

      <div class="pp--card brt-0">
        <div class="pp--card-inner">

          <h3 style="padding-bottom: 0; margin-bottom: 0;">{{examDetails.exam_type.exam_type_name}}</h3>
          <p>{{'PatientsPortal.ExamDetails.FromDay' |translate}} {{orderService.getDateString(examDetails.exam_date)}}</p>


          <div *ngIf="agreementType == 4">{{'PatientsPortal.ExamDetails.ExamInCloud' |translate}}</div>
          <!-- To determine the if's todo-->

          <div class="pp--columns" style="margin-top: 30px;">
            <div class="pp--column">
              <p><strong>{{'PatientsPortal.ExamDetails.Info'|translate}}</strong><br />
              {{'PatientsPortal.ExamDetails.Patient'|translate}}: {{examDetails.patient_name}}<br />
              PESEL: {{examDetails.pesel}}</p>
            </div>

            <div class="pp--column">
              <p><strong>{{'PatientsPortal.ExamDetails.FromFacility'|translate}}</strong><br />
              {{examDetails.medical_facility.name}}<br />
              {{examDetails.medical_facility.address_street}}, {{examDetails.medical_facility.address_zip_code}} {{examDetails.medical_facility.address_city}}</p>
            </div>
          </div>
         <p *ngIf="examDetails.medical_facility.phone != ''">{{'PatientsPortal.ExamDetails.Phone' |translate}} {{examDetails.medical_facility.phone}}</p>

        </div>
      </div>

      <div class="pp--card mt-50">
        <div class="pp--card-inner">

          <h3>{{'PatientsPortal.ExamDetails.Descriptions' |translate}}</h3>

          <ng-container *ngIf="examDescriptions.length == 0">
            {{'PatientsPortal.ExamDetails.NoDesc' |translate}}
          </ng-container>

          <ng-container *ngIf="examDescriptions.length != 0">
            <div class="wybor-lekarza">

              <div *ngFor="let exam of examDescriptions" class="opis-wybor-lekarza" [ngClass]="(currentDescription.id_exam_order == exam.id_exam_order) ? 'opis-wybor-lekarza--aktywny' : 'opis-wybor-lekarza'">

                <div class="exam-view--columns">

                  <div class="exam-view--photo">
                    <svg-icon *ngIf="exam.physician_info.profile_image_uuid == null" [src]="'assets/images/icons/user-card-no-photo.svg'"
                      style="width: 100% !important; height: 100%;"></svg-icon>
                    <img *ngIf="exam.physician_info.profile_image_uuid != null"
                      src="{{envService.profilePicURL + exam.physician_info.profile_image_uuid}}"
                      style="width: 90px !important; height: 90px;" alt="" onerror="this.src='assets/images/icons/user-card-no-photo.svg'"
                      #img>
                  </div>

                  <div class="exam-view--text">
                    <strong>{{exam.physician_info.title}} {{exam.physician_info.username}} </strong>
                    <ng-container *ngIf="exam.physician_info.main_specialization != ''">{{exam.physician_info.main_specialization}},
                    </ng-container><br />
                    {{exam.physician_info.additional_specializations}}<br />
                    {{'PatientsPortal.ExamDetails.Described' |translate}} {{orderService.getDateString(exam.exam_description_date)}}
                  </div>

                </div>

                <a class="btn btn-md btn-light" (click)="currentDescription = exam"><!-- todo: ngClass które klasy jeśli examOrder jest taki sam (wybrany/niewybrany) -->
                  <ng-container *ngIf="currentDescription.id_exam_order == exam.id_exam_order">{{'PatientsPortal.ExamDetails.BtnCurrentDesc' |translate}}</ng-container>
                  <ng-container *ngIf="currentDescription.id_exam_order != exam.id_exam_order">{{'PatientsPortal.ExamDetails.BtnPickDesc' |translate}}</ng-container>
                </a>
              </div>

            </div>

            <h3 style="margin-top: 50px;">Opis badania</h3>
            <ng-container *ngFor="let exam of examDescriptions">
              <div *ngIf="currentDescription.id_exam_order == exam.id_exam_order" class="tresc-opisu" [innerHTML]="exam.exam_description"></div>
            </ng-container>

            <br />
            <p>Badanie opisane dzięki</p>
            <div class="z-logo" style="margin-top: 10px;">
              <svg-icon class="svg" src="../../assets/images/logo.svg" style="display: inline;">
              </svg-icon>
            </div>

            <div class="opis-rozpoznanie-zalaczniki">

              <div class="rozpoznanie">
                <strong>{{'PatientsPortal.ExamDetails.Diagnosis' |translate}}</strong><br />
                {{currentDescription.icd10.display_name}}
              </div>
              <br />
              <div class="zalaczniki">
                <strong>{{'PatientsPortal.ExamDetails.Attachments' |translate}}</strong><br />
                <ng-container *ngIf="currentDescription.attachment_list.length == 0">
                  {{'PatientsPortal.ExamDetails.NoAttach' |translate}}
                </ng-container>
                <ng-container *ngIf="currentDescription.attachment_list.length != 0">
                  <a style=" text-decoration: underline;cursor: pointer;color: #0848FF;"
                     *ngFor="let attachment of currentDescription.attachment_list"
                     (click)="openSafeHref(this.envService.examOrderAttUrl + attachment.attachment_uuid)"
                  >{{attachment.filename}}</a>
                </ng-container>
              </div>

            </div>

            <div class="opis-akcje">
              <a *ngIf="descPDF && descPDF.exam_description_uuid" (click)="openSafeHref(this.envService.examDescPdfUrl + descPDF.exam_description_uuid)"class="btn btn-light">{{'PatientsPortal.ExamDetails.BtnPDFDownload' |translate}}</a>
            </div>

          </ng-container>

        </div>
      </div>

    </div>

    <div class="pp--card-column pp--card-column-small">
      <div class="pp--card">
        <div class="pp--card-inner">
          <h3>{{'PatientsPortal.ExamDetails.Services' |translate}}</h3>
          <p>{{'PatientsPortal.ExamDetails.ServicesFuture' |translate}}</p>
          <a class="btn btn-light fullwidth" (click)="shareExamModal.show(examDetails.exam_share_info.share_status == 1, false)">
            <ng-container *ngIf="examDetails.exam_share_info.share_status == 0">{{'PatientsPortal.ShareExamModal.ShareLinkBtn' |translate}}</ng-container>
            <ng-container *ngIf="examDetails.exam_share_info.share_status == 1">{{'PatientsPortal.ShareExamModal.ChangeAccBtn' |translate}}</ng-container></a>
            <p *ngIf="examDetails.exam_share_info.share_status == 1" style="text-align: center; opacity: 0.66; padding-top: 5px;">
              {{'PatientsPortal.ShareExamModal.ValidUntil' |translate}}:
              {{orderService.getDateString(examDetails.exam_share_info.share_end_time)}}</p>
        </div>
      </div>

      <div class="pp--card mt-50" [hidden]="true">
        <div class="pp--card-inner">
          <h3>{{'PatientsPortal.ExamDetails.YourFiles' |translate}}</h3>
          <p>{{'PatientsPortal.ExamDetails.YourFilesExplanation' |translate}}</p>
          <a class="btn btn-light fullwidth">{{'PatientsPortal.ExamDetails.AddFile' |translate}}</a>
        </div>
      </div>

    </div>

  </div>

</ng-container>
<app-patient-share-exam-modal #shareExamModalComponent [isFolderSharing]="false" [examId]="examId" [shareInfo]="examDetails.exam_share_info" (refreshShareInfo)="getExamDetails($event)"></app-patient-share-exam-modal>
<app-cloud-agreement-modal #cloudAgreementModalComponent></app-cloud-agreement-modal>
