/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./short-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i4 from "angular-svg-icon";
import * as i5 from "../../pipe/safe-html.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../notification-inside-click.directive";
import * as i8 from "./short-notification.component";
import * as i9 from "../../../../services/users.service";
import * as i10 from "../../../../services/odata.service";
import * as i11 from "../../../../services/session.service";
import * as i12 from "@angular/router";
var styles_ShortNotificationComponent = [i0.styles];
var RenderType_ShortNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShortNotificationComponent, data: {} });
export { RenderType_ShortNotificationComponent as RenderType_ShortNotificationComponent };
function View_ShortNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-grey float-right"], ["style", "padding: 5px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makeAction(_co.notification, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.button_name; _ck(_v, 1, 0, currVal_0); }); }
function View_ShortNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index < 2); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShortNotificationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-grey float-right card-action"], ["data-toggle", "dropdown"], ["id", "ddAction1"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["alt", ""], ["class", "svg"], ["src", "../../../../assets/images/icons/more-dots.svg"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "../../../../assets/images/icons/more-dots.svg"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShortNotificationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makeAction(_co.notification, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.button_name; _ck(_v, 1, 0, currVal_0); }); }
function View_ShortNotificationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.index >= 2); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShortNotificationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["aria-labelledby", "ddAction1"], ["class", "dropdown-menu dropdown-menu-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_7)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.notification.notification_params == null) ? null : _co.notification.notification_params.actions_info); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShortNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "actions clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.notification.notification_params == null) ? null : _co.notification.notification_params.actions_info); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.notification.notification_params == null) ? null : _co.notification.notification_params.actions_info.length) > 2); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.notification.notification_params == null) ? null : _co.notification.notification_params.actions_info.length) > 2); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ShortNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.notification.notification_params == null) ? null : _co.notification.notification_params.actions_info) != undefined); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShortNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SafeHtmlPipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "a", [["appNotificationInsideClick", ""]], null, [[null, "notificationInsideClick"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("notificationInsideClick" === en)) {
        var pd_1 = (_co.notificationInsideClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 16384, null, 0, i7.NotificationInsideClickDirective, [i1.ElementRef], null, { notificationInsideClick: "notificationInsideClick" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "description": 0, "notification-short": 1 }), i1.ɵppd(9, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortNotificationComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.notification.is_read == false) ? "notification new" : ((_co.extended == false) ? "notification" : "notification extended")); _ck(_v, 2, 0, currVal_0); var currVal_3 = _ck(_v, 8, 0, true, !_co.extended); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.extended; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.notification.notification_title; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.notification.notification_text)); _ck(_v, 6, 0, currVal_2); }); }
export function View_ShortNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-short-notification", [], null, null, null, View_ShortNotificationComponent_0, RenderType_ShortNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i8.ShortNotificationComponent, [i9.UsersService, i10.OdataService, i11.SessionService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShortNotificationComponentNgFactory = i1.ɵccf("app-short-notification", i8.ShortNotificationComponent, View_ShortNotificationComponent_Host_0, { notification: "notification" }, { remove: "remove", read: "read", close: "close", extend: "extend" }, []);
export { ShortNotificationComponentNgFactory as ShortNotificationComponentNgFactory };
