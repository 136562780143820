import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OdataService, UsersService, ValidationService } from '@/services';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import {IShareResultsPatientInfo} from '@/interfaces/PatientsPortal';


@Component({
  selector: 'app-generate-result-pair-modal',
  templateUrl: './generate-result-pair-modal.component.html',
  styleUrls: ['./generate-result-pair-modal.component.scss']
})
export class GenerateResultPairModalComponent implements OnInit {
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;

  patientInfo: IShareResultsPatientInfo = undefined;

  peselPhoneForm = this.formBuilder.group({
    phone_number: [{value: ''}, [Validators.required]],
    pesel: ['', [Validators.required, ValidationService.peselValidator, Validators.minLength(11)]]
  });
  spinnerHidden: boolean = true;
  bigSpinnerHidden: boolean = true;
  activeScreen: string = 'INPUT';

  retryValues = {
    phone_number: '',
    pesel: ''
  };

  constructor(private formBuilder: FormBuilder, private odata: OdataService, private userService: UsersService) { }

  ngOnInit() {
  }

  show() {
    this.activeScreen = 'INPUT';
    this.peselPhoneForm.reset();
    this.peselPhoneForm.patchValue({
      pesel: '',
      phone_number: ''
    });
    this.patientInfo = undefined;
    this.bigSpinnerHidden = true;
    $('#generateResultsPairModal').modal('show');
  }

  checkPesel(e) {
    if (this.peselPhoneForm.controls.pesel.valid) {
      this.spinnerHidden = false;
      this.odata.postPrivate('mf_get_patient_pesel_info', this.odata.paramsTokenAndLang({
        pesel: this.peselPhoneForm.controls.pesel.value,
        id_medical_facility: this.userService.getFacilityId()
      }), res => {
        let string = JSON.stringify(res);
        let result = JSON.parse(string);
        if (result.Status === 'OK') {
          this.patientInfo = JSON.parse(result.Value);
          return true;
        }
      }).then(e => { if (e) this.spinnerHidden = true; });
    } else if (e.target.value.length == 11) {
      this.spinnerHidden = true;
      this.patientInfo = undefined;
    } else {
      // this.spinnerHidden = e.target.value.length <= 10; - jeśli chcemy spinner wcześniej
      this.patientInfo = undefined;
    }
  }

  onSubmit(pesel?: string, phone_number?: string) {
    this.bigSpinnerHidden = false;
    this.odata.postPrivate('mf_register_pesel_phone_pair', this.odata.paramsTokenAndLang({
      id_medical_facility: this.userService.getFacilityId(),
      pesel: pesel ? pesel : this.peselPhoneForm.controls.pesel.value,
      phone_number: phone_number ? phone_number : this.peselPhoneForm.controls.phone_number.value.nationalNumber
    }), res => {
      let string = JSON.stringify(res);
      let result = JSON.parse(string);
      if (result.Status === 'OK') {
        return 'CONFIRMED';
      } else {
        if (this.activeScreen != 'ERROR') this.retryValues = {
          pesel: this.peselPhoneForm.controls.pesel.value,
          phone_number: this.peselPhoneForm.controls.phone_number.value.nationalNumber
        };
        return 'ERROR';
      }
    }).then(result => {
      this.peselPhoneForm.reset();
      this.peselPhoneForm.patchValue({
        pesel: '',
        phone_number: ''
      });
      this.patientInfo = undefined;
      this.bigSpinnerHidden = true;
      this.activeScreen = result;
    });
  }

  retrySubmit() {
    this.onSubmit(this.retryValues.pesel, this.retryValues.phone_number);
  }
}
