import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ToastService } from '@/core/components/toast/toast.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private toastService: ToastService, private zone: NgZone) { }

  handleError(error: Error) {
    // this.zone.run(() =>
    //   this.toastService.toast('Errors.AppErrors.GenericError', 'error', false
    // ));
    console.error('Global handler:', error);
  }
}
