import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../../services';

export interface UrlAndContext {
    url: string;
    contexts: string[];
}

@Injectable({
    providedIn: 'root'
})
export class ContextGuardService implements CanActivate {

    static urlAndContexts: UrlAndContext[] = [
        {url: '/serviceCatalog', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/patientsPortal', contexts: []},
        {url: '/portal-pacjenta', contexts: ['PATIENT', 'PHYSICIAN']},
       // {url: '/badania', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/badania/lista-badan', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/badania/szczegoly', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/wizyty', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/uslugi', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/lekarze', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/twoje-konto', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/examinationDescription', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/orderHistory', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/emptyExams', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/getAccessForDc', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/dcProfiles', contexts: []},
        {url: '/doctorsAndCoProfiles', contexts: ['PATIENT', 'PHYSICIAN']},
        {url: '/publicDcProfile', contexts: []},
        {url: '/beforeStartingWork', contexts: []},
        {url: '/myProfile', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {url: '/registrationCo1', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {url: '/registrationCo2', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {url: '/messages', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {url: '/notifications', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT', 'ADMINISTRATOR']},
        {url: '/orderDescription/currentDescription', contexts: ['DESCRIBING_CENTER']},
        {url: '/orderDescription/consultation', contexts: ['DESCRIBING_CENTER']},
        {url: '/orderDescription/orderDescriptionComparison', contexts: ['DESCRIBING_CENTER']},
        {url: '/orderDescription', contexts: ['DESCRIBING_CENTER']},
        {url: '/dashboard', contexts: ['DESCRIBING_CENTER']},
        {url: '/patients', contexts: ['DESCRIBING_CENTER']},
        {url: '/coProfile', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PHYSICIAN', 'PATIENT']},
        {url: '/publicCOProfile', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PHYSICIAN', 'PATIENT']},
        {url: '/doctorsProfile', contexts: ['DESCRIBING_CENTER', 'PATIENT', 'PHYSICIAN', 'ADMINISTRATOR']},
        {url: '/teamSettings', contexts: ['DESCRIBING_CENTER']},
        {url: '/roleSettings', contexts: ['DESCRIBING_CENTER']},
        {url: '/permissionSettings', contexts: ['DESCRIBING_CENTER']},
        {url: '/usersSettings', contexts: ['DESCRIBING_CENTER']},
        {url: '/archive', contexts: ['DESCRIBING_CENTER']},
        {url: '/profiles', contexts: ['DESCRIBING_CENTER']},
        {url: '/invoices', contexts: ['DESCRIBING_CENTER']},
        {url: '/balance', contexts: ['DESCRIBING_CENTER']},
        {url: '/orderHistoryForCenter', contexts: ['DESCRIBING_CENTER']},
        {url: '/manageMyFilters', contexts: ['DESCRIBING_CENTER']},
        {url: '/medicalFacilityExams', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityArchive', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityDashboard', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityPatients', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityProfiles', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityRoleSettings', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityPermissionSettings', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityUsersSettings', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityTeamSettings', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityDownloads', contexts: ['MEDICAL_FACILITY']},
        {url: '/medicalFacilityInvoices', contexts: ['MEDICAL_FACILITY']}
    ];
    static urlMap: Map<string, string[]> = ContextGuardService.arrayToMap(ContextGuardService.urlAndContexts);

    static urlWithParams: string[] = [
        '/badania',
        '/wizyty',

        '/dashboard',
        '/archive',
        '/patients',
        '/orderDescription',
        '/publicCOProfile',
        '/doctorsProfile',
        '/publicDcProfile',
        '/medicalFacilityArchive',
        '/medicalFacilityExams',
        '/medicalFacilityDashboard',
        '/manageMyFilters'
    ];

    constructor(private navigationService: NavigationService) {
    }

    static arrayToMap(array: UrlAndContext[]): Map<string, string[]> {
        let map: Map<string, string[]> = new Map<string, string[]>();

        array.forEach((element) => {
            map.set(element.url, element.contexts);
        });

        return map;
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.navigationService.getCurrentContext() == '') {
            this.navigationService.navigateToDefault();
            return false;
        }

        if (state.url == '/registrationCo2') {
            if (!sessionStorage.getItem('newDcId')) {
                this.navigationService.navigateToDefault();
                return false;
            }
        }
        let url: string = state.url;
        let notFound: boolean = true;
        for (let i: number = 0; i < ContextGuardService.urlWithParams.length && notFound; ++i) {
            if (url.includes(ContextGuardService.urlWithParams[i])) {
                notFound = false;
                url = ContextGuardService.urlWithParams[i];
            }
        }

        let requiredContexts: string[] = [];
        if (!notFound) {
            requiredContexts = ContextGuardService.urlMap.get(url);
        } else requiredContexts = ContextGuardService.urlMap.get(state.url);

        if (requiredContexts != null) {
            let res = false;
            if (requiredContexts.find(x => x == 'ADMINISTRATOR') && this.navigationService.hasContext('ADMINISTRATOR')) {
                res = true;
            } else {
                requiredContexts.forEach((element) => {
                    if (this.navigationService.getCurrentContext() == element) {
                        res = true;
                    }
                });
            }
            if (!res) {
                this.navigationService.navigateToDefault();
                return false;
            }
        }

        return true;
    }
}
