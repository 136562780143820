import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { IFilter } from '@/interfaces';
import { FiltersService } from '@/services/filters.service';
import moment from 'moment';
import { SessionService } from '@/services';
import { FiltersComponent } from '@/utils/filters/filters.component';

interface IOption {
    value: any;
    selected: boolean;
}

@Component({
    selector: 'app-dashboard-filters',
    templateUrl: './dashboard-filters.component.html',
    styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit, AfterViewInit, OnDestroy {

    chosenFilter: IFilter = {
        id: 0,
        value: FiltersComponent.createInitialIFiltrDashboard(),
        name: '',
        type: 'dashboard',
        isFavourite: false
    };
    statusTypesForDashboard: IOption[] = [];
    modalityTypes: IOption[] = [];
    anatomyParts: IOption[] = [];
    priorityTypes: IOption[] = [];
    assignedToList: IOption[] = [];
    selectpickers: {
        statusPickerForDashboard: any,
        modalityPicker: any,
        anatomyPicker: any,
        assignedToPicker: any,
        priorityPicker: any
    } = {
            statusPickerForDashboard: null,
            modalityPicker: null,
            anatomyPicker: null,
            assignedToPicker: null,
            priorityPicker: null
        };

    private picker: ElementRef;

    @ViewChild('pickerDateRange') set PickerSetter(content: ElementRef) {
        this.picker = content;
    }

    private picker_placeholder: ElementRef;

    @ViewChild('placeholderInput') set pickerPlaceholderSetter(content: ElementRef) {
        this.picker_placeholder = content;
    }

    public daterange: any = {};
    isActiveDateSort = false;
    globalPickerListener: any;
    enOptions: any = {
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Current Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
            format: 'YYYY-MM-DD',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            fromLabel: 'From',
            toLabel: 'To',
            customRangeLabel: 'Custom',
            daysOfWeek: [
                'Mo',
                'Tu',
                'We',
                'Th',
                'Fr',
                'Sa',
                'Su'
            ],
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            'firstDay': 0
        },
        alwaysShowCalendars: false,
    };
    plOptions: any = {
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        ranges: {
            'Dzisiaj': [moment(), moment()],
            'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment()],
            'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment()],
            'Ten Miesiąc': [moment().startOf('month'), moment().endOf('month')],
            'Ostatni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
            format: 'YYYY-MM-DD',
            applyLabel: 'Zatwierdź',
            cancelLabel: 'Anuluj',
            fromLabel: 'Z',
            toLabel: 'Do',
            customRangeLabel: 'Własny przedział',
            daysOfWeek: [
                'Po',
                'Wt',
                'Śr',
                'Cz',
                'P',
                'So',
                'N'
            ],
            'monthNames': [
                'Styczeń',
                'Luty',
                'Marzec',
                'Kwiecień',
                'Maj',
                'Czerwiec',
                'Lipiec',
                'Sierpień',
                'Wrzesień',
                'Październik',
                'Listopad',
                'Grudzień'
            ],
            'firstDay': 0
        },
        alwaysShowCalendars: false,
    };
    currentDateValue: string = '';
    public options: any = this.setLangForDatepicker(this.sessionService.language);

    constructor(private filterService: FiltersService, private sessionService: SessionService, private renderer: Renderer2) {
    }

    ngOnInit() {
    }

    public ngAfterViewInit(): void {
        this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', () => {
            this.activateDatepicker();
        });
    }

    public ngOnDestroy() {
        this.globalPickerListener();
    }

    private activateDatepicker() {
        this.picker.nativeElement.click();
    }

    setCurrentFilter(filter: IFilter) {
        this.daterange.start = moment().subtract(29, 'days');
        this.daterange.end = moment();
        this.chosenFilter = filter;
        if (this.chosenFilter.value.date_range.date_from != '') {
            if (!this.isActiveDateSort) {
                this.isActiveDateSort = true;
            }
            this.daterange.start = this.chosenFilter.value.date_range.date_from;
            this.daterange.end = this.chosenFilter.value.date_range.date_to;
            this.updatePickerPlaceholder(new Date(this.chosenFilter.value.date_range.date_from),
                new Date(this.chosenFilter.value.date_range.date_to));
        } else this.clearDaterangeFilter();
    }

    saveChanges(name: string): Promise<any> {
        this.chosenFilter.value = FiltersComponent.createInitialIFiltrDashboard();
        this.selectpickers.statusPickerForDashboard.selectpicker('val').forEach((value) => {
            this.chosenFilter.value.status.push(parseInt(value));
        });
        this.selectpickers.priorityPicker.selectpicker('val').forEach((value) => {
            this.chosenFilter.value.priority.push(parseInt(value));
        });
        this.selectpickers.assignedToPicker.selectpicker('val').forEach((value) => {
            this.chosenFilter.value.assign_to.push(parseInt(value));
        });
        this.selectpickers.anatomyPicker.selectpicker('val').forEach((value) => {
            this.chosenFilter.value.anatomy.push(parseInt(value));
        });
        this.selectpickers.modalityPicker.selectpicker('val').forEach((value) => {
            this.chosenFilter.value.modality.push(parseInt(value));
        });

        if (this.getDate()) {
            return this.filterService.saveFilter(this.chosenFilter.value, this.chosenFilter.type,
                name, this.chosenFilter.isFavourite);
        }
    }

    setFilterData() {
        let promises: Promise<any>[] = [];
        promises.push(this.getStatusTypesForDashboard());
        promises.push(this.getModalityTypes());
        promises.push(this.getAnatomyParts());
        promises.push(this.getPriorityTypes());
        promises.push(this.getAssignedToList());
        Promise.all(promises).then(() => this.setFilter());
    }


    getStatusTypesForDashboard(): Promise<any> {
        return this.filterService.getStatusTypesForExamOrders().then(
            (res) => {
                res.forEach((v) => {
                    this.statusTypesForDashboard.push({
                        value: v,
                        selected: false
                    });
                });
                this.selectpickers.statusPickerForDashboard = $('#statusDashboard')['selectpicker']();
            }
        );
    }

    getModalityTypes(): Promise<any> {
        return this.filterService.getModalitiesGroups().then(
            (res) => {
                res.forEach((v) => {
                    this.modalityTypes.push({ value: v, selected: false });
                });
                this.selectpickers.modalityPicker = $('#modality')['selectpicker']();
            }
        );
    }

    getAnatomyParts(): Promise<any> {
        return this.filterService.getAnatomyParts().then(
            (res) => {
                res.forEach((v) => {
                    this.anatomyParts.push({ value: v, selected: false });
                });
                this.selectpickers.anatomyPicker = $('#anatomy')['selectpicker']();
            });
    }

    getPriorityTypes(): Promise<any> {
        return this.filterService.getPriorityTypes().then(
            (res) => {
                res.forEach((v) => {
                    this.priorityTypes.push({
                        value: {
                            priority_text: v.priority_text,
                            id_priority: v.id_priority,
                            priority_value: v.priority_value
                        }, selected: false
                    });
                });
                this.selectpickers.priorityPicker = $('#priority')['selectpicker']();
            }
        );
    }

    getAssignedToList(): Promise<any> {
        return this.filterService.getPhysiciansByCenter('dashboard').then(
            (res) => {
                res.forEach((v) => {
                    this.assignedToList.push({
                        value: {
                            name: v.physician_name,
                            id_physician: v.id_physician,
                            profile_image_uuid: v.profile_image_uuid
                        }, selected: false
                    });
                });
                this.selectpickers.assignedToPicker = $('#assignedTo')['selectpicker']();
            }
        );
    }

    setFilter() {
        // Status for dashboard
        this.statusTypesForDashboard.forEach((o) => {
            o.selected = false;
        });
        this.chosenFilter.value.status.forEach((status) => {
            this.statusTypesForDashboard.forEach((o) => {
                if (status == o.value.id_status) {
                    o.selected = true;
                }
            });
        });
        setTimeout(() => this.selectpickers.statusPickerForDashboard.selectpicker('val', this.chosenFilter.value.status), 0);

        // Anatomy
        this.anatomyParts.forEach((o) => {
            o.selected = false;
        });
        this.chosenFilter.value.anatomy.forEach((anatomy) => {
            this.anatomyParts.forEach((o) => {
                if (anatomy == o.value.id_part) {
                    o.selected = true;
                }
            });
        });
        setTimeout(() => this.selectpickers.anatomyPicker.selectpicker('val', this.chosenFilter.value.anatomy), 0);

        // Modality
        this.modalityTypes.forEach((o) => {
            o.selected = false;
        });
        this.chosenFilter.value.modality.forEach((modality) => {
            this.modalityTypes.forEach((o) => {
                if (modality == o.value.id_group) {
                    o.selected = true;
                }
            });
        });
        setTimeout(() => this.selectpickers.modalityPicker.selectpicker('val', this.chosenFilter.value.modality), 0);

        // Priority
        this.priorityTypes.forEach((o) => {
            o.selected = false;
        });
        this.chosenFilter.value.priority.forEach((priority) => {
            this.priorityTypes.forEach((o) => {
                if (priority == o.value.id_priority) {
                    o.selected = true;
                }
            });
        });
        setTimeout(() => this.selectpickers.priorityPicker.selectpicker('val', this.chosenFilter.value.priority), 0);

        // Assigned to
        this.assignedToList.forEach((o) => {
            o.selected = false;
        });
        this.chosenFilter.value.assign_to.forEach((assign_to) => {
            this.assignedToList.forEach((o) => {
                if (assign_to == o.value.id_physician) {
                    o.selected = true;
                }
            });
        });
        setTimeout(() => this.selectpickers.assignedToPicker.selectpicker('val', this.chosenFilter.value.assign_to), 0);
    }

    setLangForDatepicker(lang: string): any {
        if (lang == 'en') {
            return this.enOptions;
        } else return this.plOptions;
    }

    public selectedDate(value: any, datepicker?: any) {
        // this is the date the user selected
        if (!this.isActiveDateSort) {
            this.isActiveDateSort = true;
        }
        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;

        // or manupulat your own internal property
        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;

        this.updatePickerPlaceholder(value.start._d, value.end._d);
    }

    private updatePickerPlaceholder(start_date: Date, end_date: Date) {
        let start_date_str = moment(start_date).format('YYYY-MM-DD').toString();
        let end_date_str = moment(end_date).format('YYYY-MM-DD').toString();
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content);
        }
    }

    public updatePickerPlaceholderContent(content: string) {
        this.currentDateValue = content;
    }

    getDateRange(): any {
        return {
            date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
            date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
        };
    }

    getDate(): boolean {
        let tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            } else if (this.currentDateValue == '') {
                this.clearDaterangeFilter();
            } else {
                this.chosenFilter.value.date_range = this.getDateRange();
            }
        } else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        this.chosenFilter.value.date_range.date_from = this.currentDateValue.substring(0, 10);
                        this.chosenFilter.value.date_range.date_to = this.currentDateValue.substring(13, 23);
                    } else {
                        this.chosenFilter.value.date_range.date_from = this.currentDateValue;
                        this.chosenFilter.value.date_range.date_to = this.currentDateValue;
                    }
                    this.daterange.start = this.chosenFilter.value.date_range.date_from;
                    this.daterange.end = this.chosenFilter.value.date_range.date_to;
                } else {
                    document.getElementById('placeholder-input').setAttribute('class', 'error');
                    tmp = false;
                }
            } else {
                this.clearDaterangeFilter();
            }
        }

        return tmp;
    }

    clearDaterangeFilter(): void {
        this.isActiveDateSort = false;
        this.updatePickerPlaceholderContent('');
        this.chosenFilter.value.date_range.date_from = '';
        this.chosenFilter.value.date_range.date_to = '';
    }

    isValidDateRange(dateRange: string): boolean {
        if (dateRange.length != 23 && dateRange.length != 10) {
            return false;
        } else {
            if (dateRange.length == 23) {
                let from = dateRange.substring(0, 10);
                let to = dateRange.substring(13, 23);
                if (!moment(from, 'YYYY-MM-DD').isValid() || !moment(to, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            } else {
                if (!moment(dateRange, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
        }

        return true;
    }
}
