import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';
import { NavigationService } from './navigation.service';
import { EnvService } from './env.service';
import { IMedFacility } from '@/interfaces';

export interface DCenter {
    name: string;
    imageUuid: string;
    idDescribingCenter: number;
    status: boolean;
    contactName: string;
    contactId: number;
}

export interface DcPermission {
  idContext: number;
  idPermission: number;
  contextRequired: number[];
  permissionsName: string;
}

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    iClientIp: string = '';
    iLanguage: string = '';
    translate: TranslateService;
    descCenter: DCenter = null;
    unverifiedCenterExists: boolean = false;
    medFacility: IMedFacility = null;
    username: string = '';
    profileImageUrl: string = '';
    miniProfileImageUrl: string = '';
    imageNoCacheSuffix: string = '';
    userDataPromise: Promise<any>;
    dcListPromise: Promise<any> = null;
    mfListPromise: Promise<any> = null;
    profileImageUuid: string = '';
    userId: number;
    centerChanged: EventEmitter<number> = new EventEmitter<number>();
    facilityChanged: EventEmitter<number> = new EventEmitter<number>();
    descCenters: DCenter[] = [];
    medFacilities: IMedFacility[] = [];
    unverifiedDescCenters: DCenter[] = [];
    userDcPermissions: DcPermission[] = [];
    patientSearchDataAgreement: number = 0;
    patientCloudAgreement: number = 0;

    constructor(private odataService: OdataService, private sessionService: SessionService,
        private navigationService: NavigationService, private env: EnvService) {
        this.setLanguage();
        this.changeImageNoCacheSuffix();
        if (this.sessionService.tokenExist()) {
            this.getUserData();
        }
    }

    changeLang(): void {
        this.sessionService.changeLang();
        this.iLanguage = this.sessionService.language;
    }

    setLanguage() {
        this.sessionService.setLanguage();
        this.iLanguage = this.sessionService.language;
    }

    getProfileImageUrl = async () => this.profileImageUrl;

    getUserData(): Promise<any> {
        this.userDataPromise = this.odataService.postPrivate('get_user_profile2',
            this.odataService.paramsTokenAndLang({}),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    let value = JSON.parse(result.Value);
                    sessionStorage.setItem('username', value.username);
                    this.username = value.username;
                    this.userId = value.id_user;
                    this.profileImageUuid = value.profile_image_uuid;
                    this.profileImageUrl = this.env.profilePicURL + value.profile_image_uuid + '?' + this.imageNoCacheSuffix;
                    this.miniProfileImageUrl = this.env.miniProfilePicURL + value.profile_image_uuid + '?' + this.imageNoCacheSuffix;
                    this.navigationService.setContexts(value.user_context);
                    this.patientSearchDataAgreement = value.user_personal_data_agreement_status;
                    this.patientCloudAgreement = value.user_cloud_agreement_status;
                } else {
                    // TODO
                }
            });
        return this.userDataPromise;
    }

    getPatientSearchDataAgreement(): number { return this.patientSearchDataAgreement; }

    getPatientCloudAgreement(): number {
      return this.patientCloudAgreement;
    }

  getDcList(): Promise<any> {
        return this.odataService.postPrivate('get_user_co_list',
            this.odataService.paramsTokenAndLang({}),
            res => {
                let centers: DCenter[] = this.parseList(res);
                this.descCenters = [];
                this.unverifiedDescCenters = [];
                centers.forEach((e) => {
                    if (e.status) this.descCenters.push(e);
                    else {
                        this.unverifiedDescCenters.push(e);
                        this.unverifiedCenterExists = true;
                    }
                });
                return res;
            }
        );
    }

    getUserDcPermissions(id: number): Promise<any> {
      return this.odataService.postPrivate('get_co_permissions',
        this.odataService.paramsTokenAndLang({
          id_describing_center: id
        }),
              res => {
                this.userDcPermissions = this.parseList(res);
                return res;
          }
        );
    }

    getMfList(): Promise<any> {
        return this.odataService.postPrivate('get_user_mf_list',
            this.odataService.paramsTokenAndLang({}),
            res => {
                this.medFacilities = this.parseList(res);
                return res;
            }
        );
    }

    getContactId(): number {
        let id = 0;
        let isVerified = false;
        this.descCenters.forEach((el) => {
            if (el.idDescribingCenter == this.getCenterId()) {
                id = el.contactId;
                isVerified = true;
            }
        });
        if (!isVerified) {
            this.unverifiedDescCenters.forEach((el) => {
                if (el.idDescribingCenter == this.getCenterId()) {
                    id = el.contactId;
                }
            });
        }
        return id;
    }

    getContactName(): string {
        let name = '';
        let isVerified = false;
        this.descCenters.forEach((el) => {
            if (el.idDescribingCenter == this.getCenterId()) {
                name = el.contactName;
                isVerified = true;
            }
        });
        if (!isVerified) {
            this.unverifiedDescCenters.forEach((el) => {
                if (el.idDescribingCenter == this.getCenterId()) {
                    name = el.contactName;
                }
            });
        }
        return name;
    }

    isSendingMessageEnable(): boolean {
        return this.getContactId() != this.userId;
    }

    isCenterVerified(): boolean {
        let isVerified = false;
        this.descCenters.forEach((el) => {
            if (el.idDescribingCenter == this.getCenterId()) {
                isVerified = true;
            }
        });
        return isVerified;
    }

    parseList(res) {
        const result = JSON.parse(JSON.stringify(res));
        const value = this.odataService.keysToCamel(JSON.parse(result.Value));
        return value;
    }

    changeDescCenter(center: DCenter): void {
        this.centerChanged.emit(center.idDescribingCenter);
        this.descCenter = center;
        sessionStorage.setItem('currentCenter', center.idDescribingCenter.toString());
    }

    changeMedFacility(facility: IMedFacility): void {
        this.medFacility = facility;
        sessionStorage.setItem('currentFacility', facility.idMedicalFacility.toString());
        this.facilityChanged.emit(facility.idMedicalFacility);
    }

    getCenterId(): number {
        if (this.descCenter == null && sessionStorage.getItem('currentCenter'))
            return parseInt(sessionStorage.getItem('currentCenter'));
        else
            return this.descCenter && this.descCenter.idDescribingCenter;
    }

    getFacilityId(): number {
        if (this.medFacility == null && sessionStorage.getItem('currentFacility'))
            return parseInt(sessionStorage.getItem('currentFacility'));
        else
            return this.medFacility && this.medFacility.idMedicalFacility;
    }

    getCenterName(): string {
        return this.descCenter && this.descCenter.name;
    }

    getCenterStatus(): boolean {
        return this.descCenter && this.descCenter.status;
    }

    clearUserData() {
        this.iClientIp = '';
        this.descCenter = null;
        this.medFacility = null;
        this.descCenters = [];
        this.unverifiedDescCenters = [];
        this.medFacilities = [];
        this.username = '';
        this.profileImageUrl = '';
        this.miniProfileImageUrl = '';
        this.profileImageUuid = '';
        this.userId = -1;
        this.unverifiedCenterExists = false;
    }

    changeImageNoCacheSuffix(): void {
        this.imageNoCacheSuffix = new Date().getTime().toString();
    }

    setFacility(facility: IMedFacility) {
        this.medFacility = facility;
    }

    getFacilityName(): string {
        return this.medFacility && this.medFacility.medicalFacilityName;
    }

    checkDcPermission(name: string): boolean {
      return this.userDcPermissions.filter(e => e.permissionsName === name).length > 0;
    }

    changePatientSearchDataAgreement(option: number): Promise<any> {
      return this.odataService.postPrivate('pp_change_user_personal_data_agreement_status', this.odataService.paramsTokenAndLang({
        status: option
      }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.value == 'OK') this.patientSearchDataAgreement = option;
      });
    }

    changePatientCloudAgreement(option: number): Promise<any> {
      return this.odataService.postPrivate('pp_change_user_cloud_agreement_status', this.odataService.paramsTokenAndLang({
        status: option
      }), res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.value == 'OK') this.patientCloudAgreement = option;
      });
    }
}
