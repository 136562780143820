<!-- start: verificationModal -->
<div aria-hidden="true" class="modal fade" role="dialog" tabindex="-1" id="verificationModal"
     aria-labelledby="verificationModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document" style="margin: auto; max-width: 1000px;">
    <div class="modal-content py-4">
      <div class="modal-header mb-4">
        <h3 class="modal-title" id="verificationModalLabel">
          {{'GetDCAccessScreen.ActivatePwz' | translate}}
        </h3>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="registerPWZForm">
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label for="enterPWZ">{{'GetDCAccessScreen.YourPWZ' | translate}}</label>
                <input [class.is-error]="registerPWZForm.controls.pwz.touched && registerPWZForm.controls.pwz.invalid" type="text" class="form-control" id="enterPWZ"
                       placeholder="{{'GetDCAccessScreen.InputPWZ' | translate}}" style="border-radius: 0.25rem;"
                       formControlName="pwz"
                >
              </div>
              <div class="col-md-8 mt-auto">
                <div class="row">
                  <label class="col-sm-6 pr-0 col-form-label" style="height: fit-content;"
                         [innerHTML]="'GetDCAccessScreen.DocumentScan' | translate"></label>
                  <div class="col-sm-1">
                    <input (change)="onImageScanSelected($event)" id="getScan" type="file"
                           accept="image/*" style="visibility: hidden; width: 0; height: 0;"
                    >
                  </div>
                  <div class="col-sm-5 pl-0" style="height: fit-content;">
                    <label class="btn btn-lg btn-grey w-100 mb-0" for="getScan" id="getScanLabel"
                           style="font-weight: bold;">
                      {{'GetDCAccessScreen.AddScanBtn' | translate}}
                      <span class="badge badge-primary" style="top: -10px;"
                            *ngIf="scanFileName != ''">{{scanFileName}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 admin">
              <div class="col-3">
                <label for="degree">{{'GetDCAccessScreen.Degree' | translate}}
                </label>
                <ng-select id="degree"
                           formControlName="title_id"
                           bindValue="id"
                           bindLabel="value"
                           placeholder="{{'GetDCAccessScreen.ChooseDegree' | translate}}"
                           [items]="degreeOptions"
                           notFoundText="{{'CreateOrderModal.NotFoundText' |translate}}"
                >
                  <ng-template ng-option-tmp let-item="item">
                    {{item.value}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-3">
                <label for="mainSpecialization">{{'GetDCAccessScreen.MainSpecialization' | translate}}
                </label>
                <ng-select id="mainSpecialization"
                           formControlName="main_specialization_id"
                           bindValue="id"
                           bindLabel="value"
                           placeholder="{{'GetDCAccessScreen.ChooseSpecialization' | translate}}"
                           [items]="mainSpecializationOptions"
                           notFoundText="{{'CreateOrderModal.NotFoundText' |translate}}"
                >
                  <ng-template ng-option-tmp let-item="item">
                    {{item.value}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <label for="otherSpecializations">{{'GetDCAccessScreen.OtherSpecializations' | translate}}
                </label>
                <ng-select id="otherSpecializations"
                           formControlName="specialization_list"
                           bindValue="id"
                           bindLabel="value"
                           placeholder="{{'GetDCAccessScreen.ChooseOther' | translate}}"
                           [items]="otherSpecializationsOptions"
                           notFoundText="{{'CreateOrderModal.NotFoundText' |translate}}"
                           [multiple]="true"
                           [closeOnSelect]="false"
                >
                  <ng-template ng-header-tmp let-items="items">
                    <label style="margin-bottom: 0; font-weight: normal">
                      <input type="checkbox" (change)="toggleCheckAll($event)"
                             [checked]="registerPWZForm.controls.specialization_list.value
                                                       && registerPWZForm.controls.specialization_list.value.length == otherSpecializationsOptions.length"
                      />
                      {{'OrderDescriptionScreens.Templates.All' | translate}}</label>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label">{{item.value}}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">+{{items.length - 1}}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="role-{{item.id}}" type="checkbox" [checked]="item$.selected" /> {{item.value}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div formArrayName="agreements" class="mb-4">
            <div *ngFor="let a of agreementsList; let i = index" class="custom-control custom-checkbox mb-2">
              <input [formControlName]="i" class="custom-control-input"
                     id="zgoda{{i}}" type="checkbox">
              <label [innerHTML]="a.agreement_contents" class="custom-control-label" for="zgoda{{i}}">
              </label>
            </div>
          </div>
          <div class="row m-0">
            <span class="light ml-0 my-auto">* pola wymagane</span>
            <button (click)="sendPWZ()" type="submit" class="btn btn-primary btn-lg ml-auto mr-0"
                    style="font-weight: bold;" [disabled]="!agreements.valid || registerPWZForm.invalid || base64 == ''">
              {{'GetDCAccessScreen.SendFormBtn' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- end: verificationModal -->

<app-universal-toast #errorToast></app-universal-toast>
