/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./available-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./available-services.component";
var styles_AvailableServicesComponent = [i0.styles];
var RenderType_AvailableServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvailableServicesComponent, data: {} });
export { RenderType_AvailableServicesComponent as RenderType_AvailableServicesComponent };
export function View_AvailableServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" available-services works!\n"]))], null, null); }
export function View_AvailableServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-available-services", [], null, null, null, View_AvailableServicesComponent_0, RenderType_AvailableServicesComponent)), i1.ɵdid(1, 114688, null, 0, i2.AvailableServicesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvailableServicesComponentNgFactory = i1.ɵccf("app-available-services", i2.AvailableServicesComponent, View_AvailableServicesComponent_Host_0, {}, {}, []);
export { AvailableServicesComponentNgFactory as AvailableServicesComponentNgFactory };
