<div class="row mt50">
    <div class="col-md-8">
        <div class="card big-card">
            <h3 [innerHTML]="'RegistrationCo.Title' | translate" class="card-title"></h3>
            <div class="card-body">
                <app-alert #alert title="Registration"></app-alert>

                <form (ngSubmit)="onSubmit()" [formGroup]="registerCoForm">

                    <div formGroupName="firm">

                        <div class="form-row">
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.FirmType' | translate"
                                       for="type"></label>
                                <select class="form-control"
                                        formControlName="type" id="type" name="typ-dzialalnosci" >
                                    <ng-container *ngFor="let business of businessTypes">
                                        <option [innerHTML]="business.value" value="{{business.id_business}}"></option>
                                    </ng-container>
                                </select>
                            </div>
                            <div class="col form-group">

                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.NIP' | translate" for="nip"></label>
                                <div class="input-group">
                                    <input aria-describedby="nip"
                                           aria-label="NIP" class="form-control"
                                           formControlName="nip"
                                           id="nip"
                                           placeholder="{{'RegistrationCo.NIP' | translate}}" type="text">
                                    <div *ngIf="!env.hideUnderConstruction" class="input-group-append nopad">
                                        <button [innerHTML]="'RegistrationCo.CEIDG' | translate" class="btn"
                                                type="button" [disabled]="!inputs.nip.valid"
                                                [ngClass]="{'btn-primary': inputs.nip.valid, 'btn-grey darkerText': !inputs.nip.valid}">
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.FirmName' | translate"
                                       for="name"></label>
                                <input class="form-control"
                                       formControlName="name"
                                       id="name"
                                       placeholder="{{'RegistrationCo.FirmName' | translate}}"
                                       type="text">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.KRS' | translate" for="krs"></label>
                                <input class="form-control" formControlName="krs"
                                       id="krs"
                                       placeholder="{{'RegistrationCo.KRS' | translate}}"
                                       type="text">
                            </div>
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.REGON' | translate" for="regon"></label>
                                <input class="form-control"
                                       formControlName="regon"
                                       id="regon"
                                       placeholder="{{'RegistrationCo.REGON' | translate}}"
                                       type="text">
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col form-group">
                            <label [innerHTML]="'RegistrationCo.Name' | translate" for="firstName"></label>
                            <input class="form-control"
                                   formControlName="firstName" id="firstName"
                                   placeholder="{{'RegistrationCo.Name' | translate}}"
                                   type="text">
                        </div>
                        <div class="col form-group">
                            <label [innerHTML]="'RegistrationCo.Surname' | translate"
                                   for="lastName"></label>
                            <input class="form-control"
                                   formControlName="lastName" id="lastName"
                                   placeholder="{{'RegistrationCo.Surname' | translate}}"
                                   type="text">
                        </div>
                    </div>

                    <div formGroupName="address">
                        <div class="form-row">
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.PostCode' | translate"
                                       for="postCode"></label>
                                <input class="form-control"
                                       formControlName="postCode"
                                       id="postCode"
                                       placeholder="{{'RegistrationCo.PostCode' | translate}}"
                                       type="text">
                            </div>
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.City' | translate" for="city"></label>
                                <input class="form-control"
                                       formControlName="city"
                                       id="city"
                                       placeholder="{{'RegistrationCo.City' | translate}}" type="text"
                                       value="">
                            </div>
                        </div>


                        <div class="form-row">
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.Street' | translate"
                                       for="street"></label>
                                <input class="form-control"
                                       formControlName="street" id="street"
                                       placeholder="{{'RegistrationCo.Street' | translate}}"
                                       type="text">
                            </div>
                            <div class="col form-group">
                                <label [innerHTML]="'RegistrationCo.AddressNumber' | translate"
                                       for="addressNumber"></label>
                                <input class="form-control"
                                       formControlName="number"
                                       id="addressNumber"
                                       placeholder="{{'RegistrationCo.AddressNumber' | translate}}"
                                       type="text">
                            </div>
                        </div>
                    </div>

                    <div formArrayName="agreements">
                        <div *ngFor="let a of agreementsList; let i = index" class="custom-control custom-checkbox"
                             [ngClass]="{'mb-2': i != agreementsList.length-1}">
                            <input [formControlName]="i" class="custom-control-input"
                                   id="zgoda{{i}}" type="checkbox">
                            <label [innerHTML]="a.agreement_contents" class="custom-control-label" for="zgoda{{i}}">
                            </label>
                        </div>
                    </div>

                    <div class="card-footer" style="background-color: white;">
                        <button type="submit" [innerHTML]="'RegistrationCo.NextStep' | translate"
                                class="btn btn-lg btn-success float-right" [disabled]="!agreements.valid">
                        </button>
                    </div>

                </form>

            </div>
        </div>
    </div>

    <div class="col-md-4 desktop-only">
        <app-stepper stepNum="1" stepperType="co"></app-stepper>
    </div>

</div>

<app-universal-toast #universalToast></app-universal-toast>
