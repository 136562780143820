<div id="top-bar" class="col-md-12 w-100">
    <div class="row">
        <div class="col-md-12 pl-0 pr-0">
            <a style="cursor: pointer" class="back" (click)="navigateBack()">
                <svg-icon src="../../../../assets/images/icons/topbar/icon__arrow_left.svg" class="svg" alt=""></svg-icon>
            </a>
            <h3 class="page-name order-description">

                {{'Profile.DoctorsProfile.DoctorsProfile' | translate}}

            </h3>

            <ul id="top-bar-nav" class="ml-auto">

                <li [ngClass]="{'active': activateField['informations']}">
                    <a (click)="scroll('section-start')">{{'Profile.DoctorsProfile.Informations' | translate}}</a>
                </li>
                <li *ngIf="!publicProfile || physician_profile.working_places.working_places_enable" [ngClass]="{'active': activateField['places']}">
                    <a (click)="scroll('section-pracuje_w')">{{'Profile.DoctorsProfile.WorkingPlaces' | translate}}</a>
                </li>
                <li *ngIf="!publicProfile || physician_profile.portfolio.portfolio_enable" [ngClass]="{'active': activateField['portfolio']}">
                    <a (click)="scroll('section-portfolio')">{{'Profile.Portfolio' | translate}}</a>
                </li>
                <li *ngIf="!publicProfile || physician_profile.working_hour_info.working_hours_enable && !hiddenTimetable" [ngClass]="{'active': activateField['hours']}">
                    <a (click)="scroll('section-godziny-pracy')">{{'Profile.Hours' | translate}}</a>
                </li>
                <li *ngIf="!publicProfile || physician_profile.about_me.about_me_enable" [ngClass]="{'active': activateField['about']}">
                    <a (click)="scroll('section-o-mnie')">{{'Profile.DoctorsProfile.AboutMe' | translate}}</a>
                </li>
            </ul>

        </div>
    </div>

</div>

<div class="row" id="main_content" style="width: 980px; margin: 0 auto;">

    <div style="width: 100%">
        <div class="row">
            <div class="col">
                <div *ngIf="editPermission" class="card big-card" style="height: 90px;">
                    <span style="display: inline-block; margin-top: auto; margin-bottom: auto; margin-left: 20px;">
                        {{'Profile.DoctorsProfile.EditMode' | translate}}
                        <span id="publicProfileLink" (click)="navigateToPublicProfile()">{{'Profile.DoctorsProfile.PressHere' | translate}}</span>
                    </span>
                </div>

                <div id="photo-and-info">

                   <div class="card" id="photo-card">
                        <!-- start: user has profie picture -->
                        <ng-container *ngIf="imageUrl != ''">
                            <div class="card user-card">
                                <img alt="" class="img-fluid user-img-loaded"
                                     src="{{imageUrl}}" style="height: auto; max-width: 100%;"
                                     onerror="this.src='assets/images/icons/user-card-no-photo.svg'">
                            </div>
                            <input class="btn btn-grey" style="position: absolute; bottom: 10px; right: 10px;" onClick="document.getElementById('upload').click()"
                                   type="button"
                                   value="{{'AccountProperties.ChangePhoto' | translate}}"
                                   *ngIf="isOwner"
                                   >
                            <input (change)="onImageSelected($event)" id="upload" accept="image/*" type="file" *ngIf="isOwner">
                        </ng-container>
                        <!-- end: user has profile picture -->

                        <!-- start: user doesn't have profile pic -->
                        <ng-container *ngIf="imageUrl === '' && isOwner">
                            <div class="card user-card" style="height: 320px; width: 320px;">
                                <svg-icon alt=""
                                          src="../../assets/images/icons/user-card-no-photo.svg"
                                          [svgStyle]="{ 'width.px':139, 'height.px': 157 }"
                                          id="no-profile-pic-icon" [applyCss]="true"></svg-icon>
                                <input class="btn btn-grey" style="position: absolute; bottom: 10px; right: 10px;" onClick="document.getElementById('upload').click()"
                                       type="button"
                                       value="{{'AccountProperties.AddPhoto' | translate}}">
                                <input (change)="onImageSelected($event)" id="upload" accept="image/*" type="file">
                            </div>
                        </ng-container>
                        <!-- end: user doesn't have profile pic -->

                        <ng-container *ngIf="imageUrl === '' && !isOwner">
                            <div class="card user-card" style="min-height: 320px;">
                                <img alt="" class="img-fluid user-img-loaded"
                                    src="../../assets/images/icons/user-card-no-photo.svg"
                                    style="max-height: 320px; max-width: 320px;">
                            </div>
                        </ng-container>
                    </div>

                    <!--Card-->
                    <div class="card big-card" id="section-start">
                        <div class="card-body" style="display: flex; flex-direction: column; height: 100%;">
                            <div class="row h-100" >
                                <div class="col d-flex flex-column h-100">
                                    <ng-container *ngIf="physician_profile.main.title != null && physician_profile.main.title != ''">
                                        <span style="font-weight: bold; font-size: 16px;">{{physician_profile.main.title}}</span>&nbsp;
                                        <span style="display: inline-block; background-color: #E3E4E8; border-radius: 50%; width: 18px; height: 18px;">
                                            <span style="display: inline-block; position: relative; top: -2px; left: 5px; color: #444444;">?</span>
                                        </span>
                                    </ng-container>
                                    <h2 class="pt-2">
                                        {{physician_profile.main.firstname + ' ' + physician_profile.main.last_name}}
                                    </h2>
                                    <span style="position: relative; top: -10px; opacity: 0.66">
                                        <span class="specialization" *ngFor="let spec of physician_profile.specialization.specialization" >{{spec.specialization_name}}<span class="specialization-divider"> &bull; </span></span>
                                    </span>

                                    <div class="stats-numbers" style="display: none;">
                                        <dl class="col-dl">
                                            <dt class="text-right"><span class="blue">{{avg_reaction_hours}}
                                                    {{'Profile.HoursShortcut' | translate}}</span>
                                            </dt>
                                            <dd>{{'Profile.AverageOrderReactionTime' | translate}}</dd>
                                            <dt class="text-right"><span
                                                    class="blue">{{physician_profile.statistics.statistics_info.avarage_realization_time || '0'}}
                                                    {{'Profile.MinutesShortcut' | translate}}</span></dt>
                                            <dd>{{'Profile.AverageOrderRealizationTime' | translate}}</dd>
                                            <dt class="text-right"><span
                                                    class="blue">{{physician_profile.statistics.statistics_info.orders_completed || '0'}}</span>
                                            </dt>
                                            <dd>{{'Profile.OrdersCompleted' | translate}}</dd>
                                        </dl>
                                    </div>

                                    <div class="clear"></div>

                                    <div *ngIf="!editing.contact && physician_profile.contact_info.contact_info_enable" class="card-contact-info">
                                        <div>
                                            <svg-icon alt="" class="svg float-icon"
                                                src="../../../assets/images/icons/icon__mail.svg"></svg-icon>
                                            {{physician_profile.contact_info.contact_info.email}}
                                            <span *ngIf="!physician_profile.contact_info.contact_info.email" class="light">Brak informacji o adresie e-mail</span>
                                        </div>
                                        <div>
                                            <svg-icon alt="" class="svg float-icon"
                                                src="../../../assets/images/icons/icon__phone.svg"></svg-icon>
                                            {{physician_profile.contact_info.contact_info.phone_number}}
                                            <span *ngIf="!physician_profile.contact_info.contact_info.phone_number" class="light">Brak informacji o numerze telefonu</span>
                                        </div>
                                        <div style="position: relative; left: 2px;">
                                            <svg-icon alt="" class="svg float-icon"
                                                src="../../../assets/images/icons/icon__marker.svg"></svg-icon>
                                            <span *ngIf="physician_profile.contact_info.contact_info.address != null
                                                        || physician_profile.contact_info.contact_info.address_city != null
                                                        || physician_profile.contact_info.contact_info.address_zip_code != null">
                                                <span *ngIf="physician_profile.contact_info.contact_info.address">{{physician_profile.contact_info.contact_info.address}},</span> {{physician_profile.contact_info.contact_info.address_zip_code}} {{physician_profile.contact_info.contact_info.address_city}}
                                                <span *ngIf="!physician_profile.contact_info.contact_info.address" class="light">Brak informacji o adresie</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="!publicProfile || physician_profile.contact_info.contact_info_enable" style="margin-top: auto;">
                                        <div class="btn-group-flex">
                                            <span style="display: inline-block;">
                                                <div (click)="changePhysicianProfileSectionStatus(2, 'profile_contact_info')"
                                                    class="toggle btn btn-success" data-toggle="toggle" id="privacy2"
                                                    style="width: 120px; height: 10px;" [hidden]="!editPermission">

                                                    <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                                        data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                                        type="checkbox">

                                                    <div class="toggle-group">
                                                        <label class="btn btn-success toggle-on"
                                                            style="line-height: 22px;">{{'Profile.SectionPublic' | translate}}
                                                            ●</label>
                                                        <label class="btn btn-danger toggle-off" style="line-height: 22px;">●
                                                            {{'Profile.SectionPrivate' | translate}}</label>
                                                        <span class="toggle-handle btn btn-light"></span>
                                                    </div>
                                                </div>
                                            </span>

                                            <button *ngIf="editing.contact" class="btn btn-danger"
                                                (click)="cancelEditing('contact')">{{'Profile.CancelChangesButton' | translate}}
                                            </button>
                                            <button *ngIf="editPermission && !editing.contact" class="btn btn-grey"
                                                (click)="this.editing.contact=true">{{'Profile.EditButton' | translate}}</button>
                                            <button *ngIf="editing.contact" class="btn btn-primary"
                                                (click)="changePhysicianProfilePectionContactInfo()">{{'Profile.SaveChangesButton' | translate}}</button>

                                        </div>

                                        <app-alert #errorAlertContact title="ProfileContact"></app-alert>

                                        <div *ngIf="editing.contact" class="form-row">
                                            <form [formGroup]="addressForm">
                                                <div class="col form-group">
                                                    <label for="adres_email">{{'Profile.Mail' | translate}}</label>
                                                    <input id="adres_email" type="text" class="form-control" placeholder=""
                                                        formControlName="email">
                                                </div>
                                            </form>
                                            <form [formGroup]="addressForm">
                                                <div class="col form-group">
                                                    <label for="numer_telefonu">{{'Profile.PhoneNumber' | translate}}</label>
                                                    <input id="numer_telefonu" type="text" class="form-control" placeholder=""
                                                        formControlName="phone_number">
                                                </div>
                                            </form>
                                            <form [formGroup]="addressForm">
                                                <div class="col form-group">
                                                    <label for="adres">{{'Profile.DoctorsProfile.Address' | translate}}</label>
                                                    <input id="adres" type="text" class="form-control" placeholder=""
                                                        formControlName="address">
                                                </div>
                                            </form>
                                            <form [formGroup]="addressForm">
                                                <div class="col form-group">
                                                    <label for="miasto">{{'Profile.City' | translate}}</label>
                                                    <input id="miasto" type="text" class="form-control" placeholder=""
                                                        formControlName="address_city">
                                                </div>
                                            </form>
                                            <form [formGroup]="addressForm">
                                                <div class="col form-group">
                                                    <label for="kod_pocztowy">{{'Profile.ZipCode' | translate}}</label>
                                                    <input id="kod_pocztowy" type="text" class="form-control" placeholder=""
                                                        formControlName="address_zip_code">
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    <div style="margin-top: auto;">
                                      <ng-container *ngIf="invitePermission && publicProfile && contextService.checkPermission('sendMessage')">
                                        <a *ngIf="physician_profile.describing_center_status === null" (click)="sendInvitation()"
                                           class="btn btn-md btn-primary mr-2 ml-auto" style="color: white;">{{'Profile.InviteToCooperation' | translate}}</a>
                                        <a *ngIf="physician_profile.describing_center_status != null &&
                                            physician_profile.describing_center_status.status != null &&
                                            physician_profile.describing_center_status.status.status_name === 'DELETED'" (click)="sendInvitation()"
                                           class="btn btn-md btn-primary mr-2 ml-auto" style="color: white;">{{'Profile.InviteToCooperation' | translate}}</a>
                                        <a *ngIf="physician_profile.describing_center_status != null &&
                                           physician_profile.describing_center_status.status != null &&
                                           physician_profile.describing_center_status.status.status_name === 'WAITING'"
                                           class="btn btn-md btn-primary disabled mr-2 ml-auto" style="color: white;">{{'Profile.InvitationPending' | translate}}</a>
                                      </ng-container>
                                        <a *ngIf="publicProfile" (click)="sendMessageFunc()"
                                            class="btn btn-md btn-light">{{'Profile.SendMessage' | translate}}</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/Card-->
                </div>

                <!--Card-->
                <div *ngIf="publicProfile && physician_profile.working_hour_info.working_hours_enable" class="card big-card" [hidden]="hiddenTimetable" id="section-godziny-pracy">
                    <h3 class="card-title">{{'Profile.Hours' | translate}}</h3>
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-6">
                                <dl class="col-dl"
                                    *ngFor="let day of physician_profile.working_hour_info.working_hours.working_hour_list; let i = index">
                                    <dt>{{daysOfWeek[i]}}</dt>
                                    <dd *ngIf="day.hour_start != null">
                                        {{day.hour_start.slice(0, 5) + ' - ' + day.hour_stop.slice(0, 5)}}</dd>
                                    <dd *ngIf="day.hour_start === null">{{'Profile.DoesNotWork' | translate}}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div *ngIf="!publicProfile" style="position: relative;">
                    <div [hidden]="hiddenTimetable" class="card big-card" id="section-godziny-pracy" style="width: 49.5%;">
                        <h3 class="card-title" style="display: inline-block; padding: 20px 20px;">
                            {{'Profile.Hours' | translate}}
                            <span style="display: inline-block; float: right;">

                                <div (click)="changePhysicianProfileSectionStatus(1, 'profile_work_hours')"
                                    class="toggle btn btn-success" data-toggle="toggle" id="privacy1"
                                    style="width: 120px; height: 10px;" [hidden]="!editPermission">

                                    <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                        data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                        type="checkbox">

                                    <div class="toggle-group">
                                        <label class="btn btn-success toggle-on"
                                            style="line-height: 22px;">{{'Profile.SectionPublic' | translate}}
                                            ●</label>
                                        <label class="btn btn-danger toggle-off" style="line-height: 22px;">●
                                            {{'Profile.SectionPrivate' | translate}}</label>
                                        <span class="toggle-handle btn btn-light"></span>
                                    </div>
                                </div>

                            </span>
                            <div style="height: 40px; width: 100%; margin-top: 15px;">
                                <button *ngIf="editing.workingHours" class="btn btn-danger float-left" style="margin-left: 0px; margin-right: 16px;"
                                    (click)="cancelEditing('workingHours')">{{'Profile.CancelChangesButton' | translate}}
                                </button>
                                <button *ngIf="editPermission && !editing.workingHours" class="btn btn-grey float-left"
                                    (click)="this.editing.workingHours=true">{{'Profile.EditButton' | translate}}</button>
                                <button *ngIf="editing.workingHours" class="btn btn-primary float-left"
                                    (click)="changePhysicianProfileSectionWorkingHours()">{{'Profile.SaveChangesButton' | translate}}</button>
                            </div>
                        </h3>
                        <div class="card-body" style="padding: 20px 20px;">

                            <div *ngIf="!editing.workingHours" class="row">
                                <div class="col-md-12">
                                    <dl class="col-dl"
                                        *ngFor="let day of physician_profile.working_hour_info.working_hours.working_hour_list; let i = index">
                                        <dt>{{daysOfWeek[i]}}</dt>
                                        <dd *ngIf="day.hour_start != null">
                                            {{day.hour_start.slice(0, 5) + ' - ' + day.hour_stop.slice(0, 5)}}</dd>
                                        <dd *ngIf="day.hour_start === null">{{'Profile.DoesNotWork' | translate}}</dd>
                                    </dl>
                                </div>
                            </div>

                            <app-alert #errorAlertHours title="ProfileHours"></app-alert>

                            <div *ngIf="editing.workingHours" class="row">
                                <div class="col-md-12">
                                    <dl class="col-dl"
                                        *ngFor="let day of physician_profile.working_hour_info.working_hours.working_hour_list">
                                        <dt style="margin-top: 28px;">{{daysOfWeek[day.day_of_week]}}</dt>
                                        <dd>
                                            <div class="row">
                                                <div style="padding: 0px; margin-bottom: 0px;">
                                                    <app-time-picker #startHour [inputTime]="day.hour_start"></app-time-picker>
                                                </div>
                                                <div class="col-2" style="display: flex; align-items: center; justify-content: center;">
                                                    <span style="text-align: center; display: inline-flex; align-items: center;">—</span>
                                                </div>
                                                <div style="padding: 0px; margin-bottom: 0px;">
                                                    <app-time-picker #stopHour [inputTime]="day.hour_stop"></app-time-picker>
                                                </div>
                                            </div>
                                        </dd>
                                        <div *ngIf="day.day_of_week == 6">
                                            <dt>{{'Profile.Info' | translate}}</dt>
                                            <br />
                                            <dd>{{'Profile.InfoText' | translate}}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div [hidden]="hiddenTimetable" class="card big-card" style="width: 49.5%; height: 100%; position: absolute; top: 0px; right: 0px;">
                        <h3 class="card-title" style="display: inline-block; padding: 20px 20px;">
                            {{'Profile.DoctorsProfile.Holiday' | translate}}
                            <div style="height: 40px; width: 100%; margin-top: 15px;">
                                <button *ngIf="editing.holidays" class="btn btn-danger float-left" style="margin-left: 0px; margin-right: 16px;"
                                    (click)="cancelEditing('holidays')">{{'Profile.CancelChangesButton' | translate}}
                                </button>
                                <button *ngIf="editPermission && !editing.holidays" class="btn btn-grey float-left"
                                    (click)="this.editing.holidays=true">{{'Profile.EditButton' | translate}}</button>
                                <button *ngIf="editing.holidays" class="btn btn-primary float-left"
                                    >{{'Profile.SaveChangesButton' | translate}}</button>
                            </div>
                        </h3>
                        <div class="card-body" style="padding: 20px 20px;">
                            <div class="col-md-12" style="padding: 0px;">
                                <div class="col-dl">
                                    <dd [innerHTML]="'Profile.DoctorsProfile.HolidayInfo' | translate"></dd>
                                    <dd *ngIf="physician_profile.working_hour_info.working_hours.vacation_list == null"><strong>{{'Profile.DoctorsProfile.NoHolidays' | translate}}</strong></dd>
                                    <dd
                                        *ngFor="let h of physician_profile.working_hour_info.working_hours.vacation_list">
                                        {{h.day_start + ' - ' + h.day_stop}}
                                    </dd>
                                </div>
                            </div>

                            <div *ngIf="false" class="col-md-12">
                                <div class="col-dl">
                                    <dd><strong>{{'Profile.DoctorsProfile.Holiday' | translate}}</strong></dd>
                                    <dd
                                        *ngFor="let h of physician_profile.working_hour_info.working_hours.vacation_list">
                                        {{h.day_start + ' - ' + h.day_stop}}
                                    </dd>
                                </div>
                                <div class="btn btn-grey">{{'Profile.DoctorsProfile.EditHolidaysButton' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div *ngIf="!publicProfile || physician_profile.about_me.about_me_enable" class="card big-card" id="section-o-mnie">
                    <h3 class="card-title">{{'Profile.DoctorsProfile.AboutMe' | translate}}
                        <button *ngIf="editing.aboutMe" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('aboutMe')">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !editing.aboutMe" class="btn btn-grey float-right"
                            (click)="this.editing.aboutMe=true">{{'Profile.EditButton' | translate}}</button>
                        <button *ngIf="editing.aboutMe" class="btn btn-primary float-right"
                            (click)="changePhysicianProfileSectionAboutMe()">{{'Profile.SaveChangesButton' | translate}}</button>
                        <span style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changePhysicianProfileSectionStatus(3, 'profile_about_me')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy3"
                                style="width: 120px; height: 10px;" [hidden]="!editPermission">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">

                        <div *ngIf="!editing.aboutMe" class="row">
                            <div class="col-md-12 column-text">
                                <p style="white-space: pre-wrap; column-count: 1;">
                                    {{physician_profile.about_me.about_me_info.about_me}}
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div *ngIf="editing.aboutMe" class="col-md-12">
                                <textarea name="" id="aboutMeInput" class="form-control" rows="5"
                                    placeholder="{{'Profile.DoctorsProfile.WriteAboutYourself' | translate}}"
                                    value="{{this.physician_profile.about_me.about_me_info.about_me}}"></textarea>
                            </div>
                        </div>

                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div *ngIf="!publicProfile || physician_profile.portfolio.portfolio_enable" class="card big-card" id="section-portfolio">
                    <h3 class="card-title">{{'Profile.Portfolio' | translate}}
                        <button *ngIf="editing.portfolio" class="btn btn-danger float-right ml-3"
                            (click)="cancelEditing('portfolio')">{{'Profile.CancelChangesButton' | translate}}
                        </button>
                        <button *ngIf="editPermission && !editing.portfolio" class="btn btn-grey float-right"
                            (click)="this.editing.portfolio=true">{{'Profile.EditButton' | translate}}</button>
                        <button *ngIf="editing.portfolio" class="btn btn-primary float-right"
                            (click)="changePhysicianProfileSectionPortfolio()">{{'Profile.SaveChangesButton' | translate}}</button>
                        <span style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changePhysicianProfileSectionStatus(5, 'profile_portfolio')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy5"
                                style="width: 120px; height: 10px;" [hidden]="!editPermission">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">

                        <div *ngIf="!editing.portfolio" class="row">
                            <div class="col-md-12 table-responsive">
                                <table width="100%" cellpadding="15" class="table-portfolio">
                                    <tr>
                                        <th></th>
                                        <th *ngFor="let mod of portfolioService.modalities">
                                            {{mod.value}}
                                        </th>
                                    </tr>
                                    <tr *ngFor="let name of portfolioService.anatomyParts; let i = index">
                                        <td>{{name.value}}</td>
                                        <td *ngFor="let modality of portfolioService.finalDoctorsPortfolio[i]">
                                            <div *ngIf="!modality.enable"></div>
                                            <div *ngIf="modality.enable">
                                                <svg-icon  alt="" class="svg" src="../../assets/images/icons/icon__check.svg"></svg-icon>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div *ngIf="editing.portfolio">
                            <div class="row">
                                <div class="col-md-12 table-fixed-wrapper" style="overflow: auto; position: relative;">
                                    <table width="100%" cellpadding="15" class="table-portfolio table-fixed">
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th *ngFor="let mod of portfolioService.modalities; let i = index">
                                                <div class="custom-control custom-checkbox">
                                                    <input *ngIf="!portfolioService.isColsChecked[i]" type="checkbox"
                                                        class="custom-control-input" id="customCheck{{i}}"
                                                        (click)="portfolioCheckColumn(i, true)">
                                                    <input *ngIf="portfolioService.isColsChecked[i]" type="checkbox"
                                                           class="custom-control-input"
                                                           checked id="customCheck{{i}}" checked
                                                           (click)="portfolioCheckColumn(i, false)">
                                                    <label class="custom-control-label"
                                                        for="customCheck{{i}}"><strong>{{mod.value}}</strong></label>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr *ngFor="let name of portfolioService.anatomyParts; let i = index">
                                            <td>
                                                <div class="custom-control custom-checkbox">
                                                    <input *ngIf="!portfolioService.isRowsChecked[i]" type="checkbox"
                                                        class="custom-control-input" id="nameCheck{{i}}"
                                                        (click)="portfolioCheckRow(i, true)">
                                                    <input *ngIf="portfolioService.isRowsChecked[i]" type="checkbox"
                                                           class="custom-control-input"
                                                           checked id="nameCheck{{i}}" checked
                                                           (click)="portfolioCheckRow(i, false)">
                                                    <label class="custom-control-label"
                                                        for="nameCheck{{i}}">{{name.value}}</label>
                                                </div>
                                            </td>
                                            <td
                                                *ngFor="let modality of portfolioService.finalDoctorsPortfolio[i]; let j = index">
                                                <div class="custom-control custom-checkbox">
                                                    <input *ngIf="modality.enable" type="checkbox"
                                                           class="custom-control-input"
                                                           id="modalityCheck{{i}}{{j}}" checked
                                                           (change)="onPortfolioCheckChange(i,j)">
                                                    <input *ngIf="!modality.enable" type="checkbox"
                                                           class="custom-control-input"
                                                           id="modalityCheck{{i}}{{j}}"
                                                           (change)="onPortfolioCheckChange(i,j)">
                                                    <label class="custom-control-label pl-0"
                                                        for="modalityCheck{{i}}{{j}}">&nbsp;</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!--/Card-->

                <!--Card-->
                <div *ngIf="!publicProfile || physician_profile.working_places.working_places_enable" class="card big-card" id="section-pracuje_w">
                    <h3 class="card-title">{{'Profile.DoctorsProfile.WorkingPlaces' | translate}}
                        <button *ngIf="editPermission && isEndCoopReady"
                            class="btn btn-grey float-right">{{'Profile.DoctorsProfile.EndCooperation' | translate}}</button>
                        <span style="display: inline-block; float: right; margin-right: 16px;">
                            <div (click)="changePhysicianProfileSectionStatus(6, 'profile_work_places')"
                                class="toggle btn btn-success" data-toggle="toggle" id="privacy6"
                                style="width: 120px; height: 10px;" [hidden]="!editPermission">

                                <input checked="" data-height="10" data-off="Prywatne" data-offstyle="danger"
                                    data-on="Publiczne" data-onstyle="success" data-toggle="toggle" data-width="120"
                                    type="checkbox">

                                <div class="toggle-group">
                                    <label class="btn btn-success toggle-on"
                                        style="line-height: 22px;">{{'Profile.SectionPublic' | translate}}
                                        ●</label>
                                    <label class="btn btn-danger toggle-off" style="line-height: 22px;">●
                                        {{'Profile.SectionPrivate' | translate}}</label>
                                    <span class="toggle-handle btn btn-light"></span>
                                </div>
                            </div>
                        </span>
                    </h3>
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-12 column-text">
                                <ul class="centresList">
                                    <li *ngFor="let place of physician_profile.working_places.working_places" style="position: relative;">
                                        <img *ngIf="place.logo_uuid == null" src="../../../assets/images/_dummy_data/logo-1.png" alt="">
                                        <img *ngIf="place.logo_uuid != null" [src]="env.coMiniLogoURL + place.logo_uuid" alt="" style="width: 50px; height: 50px;">
                                        <span style="display: inline-block;">{{place.describing_center_name}}</span>
                                        <button (click)="navigateToCenterProfile(place.id_describing_center)" class="btn btn-grey">{{'Profile.DoctorsProfile.COProfileButton' | translate}}</button>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <!--/Card-->

            </div>
        </div>
    </div>
</div>

<app-universal-toast #dataSavedToast></app-universal-toast>
<app-invite-to-cooperation-modal #inviteToCooperationModal (invitationSent)="afterInvite($event)"></app-invite-to-cooperation-modal>
<app-send-message-to-user-modal #sendMessage [actionToast]="dataSavedToast"></app-send-message-to-user-modal>
