import { ContextsService } from '@/services/contexts.service';
import { IAction } from './../../interfaces/Order';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
    DisplayService,
    OdataService,
    ProfileService,
    SessionService,
    UsersService,
    ValidationService,
    NavigationService
} from '@/services';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertComponent } from '@/utils';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioService } from '@/services/portfolio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '@/services/page.service';
import {
    aboutDCInit, changePrivacyInit,
    mainInit,
    priceListInit,
    specializationsInit,
    statisticsInit,
    workingHourInfoInit, workingHourListInit, IField
} from '@/interfaces';
import { TimePickerComponent } from '@/utils/time-picker/time-picker.component';
import { EnvService } from '@/services/env.service';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { SendApplicationToCoModalComponent } from '@/utils/send-application-to-co-modal/send-application-to-co-modal.component';

interface IPermission {
    id_context: number;
    id_permission: number;
    context_required: number[];
    permissions_name: string;
}

@Component({
    selector: 'app-own-user-profile',
    templateUrl: './co-profile.component.html',
    styleUrls: ['./co-profile.component.css']
})
export class CoProfileComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlertHours') errorAlertHours: AlertComponent;
    @ViewChild('errorAlertContact') errorAlertContact: AlertComponent;
    @ViewChild('errorAlertEmptyPriceList') errorAlertEmptyPriceList: AlertComponent;
    @ViewChild('errorAlertPrices') errorAlertPrices: AlertComponent;

    @ViewChildren('startHour') startHours: QueryList<TimePickerComponent>;
    @ViewChildren('stopHour') stopHours: QueryList<TimePickerComponent>;

    @ViewChild('dataSavedToast') dataSavedToast: UniversalToastComponent;

    @ViewChild('sendApplication') sendApplication: SendApplicationToCoModalComponent;

    coId: number;
    editPermission: boolean;
    permissions: IPermission[];
    editing: {
        workingHours: boolean,
        newPriceList: boolean,
        contact: boolean,
        aboutCo: boolean,
        specializations: boolean,
        pricesCurrent: boolean
    } = {
        workingHours: false,
        newPriceList: false,
        contact: false,
        aboutCo: false,
        specializations: false,
        pricesCurrent: false
    };

    hiddenTimetableAndPrice: boolean = true;

    main = mainInit;
    aboutDc = aboutDCInit;
    specializations = specializationsInit;
    priceListInfo = priceListInit;
    statistics = statisticsInit;
    workingHourInfo = workingHourInfoInit;
    actionsList: IAction[] = [];
    coLogoUuid = '';
    coLogoUrl = '';
    miniCOLogoUrl = '';
    imageNoCacheSuffix: string = '';

    prices: string[][] = [];
    isOtherPriceValid = true;
    ok = false;
    idPortfolioModality = 'all';
    allPortfolioModalities = '';
    idPortfolioPart = 'all';
    allPortfolioParts = '';
    _isEdit: boolean = false;
    applicationText: string = '';
    errorApplication: string = '';

    daysOfWeek: string[] = ['', '', '', '', '', '', ''];

    workingHoursForm = this.formBuilder.group({
        day1Start: ['', [ValidationService.workingHoursValidator]],
        day1Stop: ['', [ValidationService.workingHoursValidator]],
        day2Start: ['', [ValidationService.workingHoursValidator]],
        day2Stop: ['', [ValidationService.workingHoursValidator]],
        day3Start: ['', [ValidationService.workingHoursValidator]],
        day3Stop: ['', [ValidationService.workingHoursValidator]],
        day4Start: ['', [ValidationService.workingHoursValidator]],
        day4Stop: ['', [ValidationService.workingHoursValidator]],
        day5Start: ['', [ValidationService.workingHoursValidator]],
        day5Stop: ['', [ValidationService.workingHoursValidator]],
        day6Start: ['', [ValidationService.workingHoursValidator]],
        day6Stop: ['', [ValidationService.workingHoursValidator]],
        day7Start: ['', [ValidationService.workingHoursValidator]],
        day7Stop: ['', [ValidationService.workingHoursValidator]]
    });

    contactForm = this.formBuilder.group({
        email: ['', [ValidationService.mailValidator, Validators.required]],
        phone_number: ['', [ValidationService.phoneNumberValidator, Validators.required]],
        street: ['', [ValidationService.streetValidator, Validators.required]],
        local: ['', [ValidationService.localValidator, Validators.required]],
        city: ['', [ValidationService.nameValidator, Validators.required]],
        zip: ['', [ValidationService.zipCodeValidator, Validators.required]],
    });

    spec_count: number = 0;
    spec = new Map<number, boolean>();

    is_edit_price: boolean[] = [];

    changePrivacy = changePrivacyInit;
    isInvalidPrice: boolean[] = [];

    workingHourList = workingHourListInit;

    portfolioService: PortfolioService;

    stickySidebarFunction: any;

    idModality = 'all';
    allModalities = '';
    idPart = 'all';
    allParts = '';

    fields: IField[] = [];
    acitvateField = {
        'hours': true,
        'contact': false,
        'about': false,
        'specializations': false,
        'portfolio': false
    };
    activeFieldsFunction: any = null;
    timeout: any = null;

  base64textString = '';
  publicProfile: boolean = false;

  constructor(private formBuilder: FormBuilder, private odataService: OdataService, private userService: UsersService, public navigationService: NavigationService,
                private session: SessionService, private profileService: ProfileService, private translate: TranslateService,
                portfolioService: PortfolioService, private route: ActivatedRoute, private pageService: PageService,
                public env: EnvService, private router: Router, public contextService: ContextsService) {
        this.portfolioService = portfolioService;
        this.portfolioService.listCOPortfolios = [];
        this.portfolioService.priceLists = [];
    }

    ngOnInit() {
        this.profileService.translateDays(this.daysOfWeek);
        this.changeImageNoCacheSuffix();
        if (this.route.snapshot.routeConfig.path == 'coProfile') { // display myProfile
            this.publicProfile = false;
            this.getOwnDescribingCenterProfile();
            this.timeout = setTimeout(() => {
                //this.setFields(this.priceListInfo.price_list_info.id_price_list_list);
                this.timeout = null;
            }, 2000);
            this.editPermission = this.userService.checkDcPermission('DC_MODYFING_PROFILE');

            this.pageService.setTranslatedPageTitle('Profile.COProfile.YourPublicDCProfile', 0);

        } else { // display publicProfile
            this.publicProfile = true;
            this.route.paramMap.subscribe(params => {
                this.coId = parseInt(params.get('coId'));
            });
            this.getPublicDescribingCenterProfile(this.coId);
                        this.timeout = setTimeout(() => {
                            if (this.countKeys(this.priceListInfo) != 0) {
                                //this.setFields(this.priceListInfo.price_list_info.id_price_list_list);
                                //this.setSidebar(this.priceListInfo.price_list_info.id_price_list_list.length);
                            } else {
                                //this.setFields([]);
                                //this.setSidebar(0);
                            }
                            this.timeout = null;
                        }, 2000);
            this.pageService.setTranslatedPageTitle('Profile.COProfile.DescribingCentreProfile', 0);
        }
        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add('pp-portal');
        }
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.stickySidebarFunction);
        if (this.activeFieldsFunction != null) window.removeEventListener('scroll', this.activeFieldsFunction);
        if (this.timeout != null) clearTimeout(this.timeout);
        this.pageService.resetPageTitle();
        if (this.router.url != '/doctorsAndCoProfiles') {
            sessionStorage.removeItem('goToDcProfile');
            sessionStorage.removeItem('dcProfilesActivePage');
            sessionStorage.removeItem('dcProfilesItemsOnPage');
        }
        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');
    }

    setFields(priceList: any): void {
        let rect = document.getElementById('section-godziny-pracy');
        if (rect) this.fields.push({name: 'hours', topHeight: rect.getBoundingClientRect().top + document.documentElement.scrollTop - 1});

        rect = document.getElementById('section-kontakt');
        if (rect) this.fields.push({name: 'contact', topHeight: rect.getBoundingClientRect().top + document.documentElement.scrollTop - 1});

        rect = document.getElementById('section-o-mnie');
        if (rect) this.fields.push({name: 'about', topHeight: rect.getBoundingClientRect().top + document.documentElement.scrollTop - 1});

        rect = document.getElementById('section-specjalizacje');
        if (rect) this.fields.push({name: 'specializations', topHeight: rect.getBoundingClientRect().top + document.documentElement.scrollTop - 1});

        rect = document.getElementById('section-portfolio');
        if (rect) this.fields.push({name: 'portfolio', topHeight: rect.getBoundingClientRect().top + document.documentElement.scrollTop - 1});

        for (let i = 0; i < priceList.length; i++) {
            if (document.getElementById('portfolio' + i) != null) {
                let rect = document.getElementById('portfolio' + i).getBoundingClientRect();
                this.acitvateField['portfolio' + i] = false;
                this.fields.push({name: 'portfolio' + i, topHeight: rect.top + document.documentElement.scrollTop - 1});
            }
        }

        DisplayService.activateSidebarFieldsOnScroll(this.fields, this.acitvateField);
    }

    changeAll(part: string, index: number) {
        let price = '';
        if (part == 'modality') {
            price = this.allModalities;
        } else if (part == 'part') {
            price = this.allParts;
        }
        let portfolio = this.portfolioService.listCOPortfolios[index];
        for (let i = 0; i < portfolio.length; i++) {
            for (let j = 0; j < portfolio[i].length; j++) {
                portfolio[i][j].price = parseInt(price);
            }
        }
        this.portfolioService.listCOPortfolios[index] = portfolio;
    }

    changeAllModalities(index: number) {
        let portfolio = this.portfolioService.listCOPortfolios[index];
        if (this.idModality == 'all') {
            this.changeAll('modality', index);
        } else {
            for (let i = 0; i < portfolio.length; i++) {
                portfolio[i][this.idModality].price = parseInt(this.allModalities);
            }
            this.portfolioService.listCOPortfolios[index] = portfolio;
        }
    }

    changeAllParts(index: number) {
        let portfolio = this.portfolioService.listCOPortfolios[index];
        if (this.idPart == 'all') {
            this.changeAll('part', index);
        } else {
            for (let i = 0; i < portfolio[this.idPart].length; i++) {
                portfolio[this.idPart][i].price = parseInt(this.allParts);
            }
            this.portfolioService.listCOPortfolios[index] = portfolio;
        }
    }

    deleteDescribingCenterPriceList(list_id) {
        this.odataService.postPrivate('delete_describing_center_price_list',
            {
                token: this.session.getToken(),
                id_describing_center: this.userService.getCenterId(),
                id_price_list: parseInt(list_id),
                language: this.userService.iLanguage
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.portfolioService.priceLists = [];
                    this.portfolioService.listCOPortfolios = [];
                    this.portfolioService.finalDefaultCoPortfolio = [];
                    this.getOwnDescribingCenterProfile();
                    this.dataSavedToast.success('Saved');
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            }
        );
    }

    changeDescribingCenterProfileSectionContactInfo() {
        if (this.contactForm.valid) {
            this.errorAlertContact.clear();
            this.odataService.postPrivate('change_describing_center_profile_section_contact_info',
                {
                    token: this.session.getToken(),
                    language: this.userService.iLanguage,
                    id_describing_center: this.userService.getCenterId(),
                    email: this.contactForm.controls['email'].value,
                    phone_number: this.contactForm.controls['phone_number'].value,
                    address_street: this.contactForm.controls['street'].value,
                    address_local: this.contactForm.controls['local'].value,
                    address_city: this.contactForm.controls['city'].value,
                    address_zip_code: this.contactForm.controls['zip'].value
                },
                res => {
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    if (result.value == 'OK') {
                        this.getOwnDescribingCenterProfile();
                        this.dataSavedToast.success('Saved');
                        this.editing.contact = false;
                    } else {
                        this.dataSavedToast.error('NotSaved');
                    }
                    this.dataSavedToast.show();
                    setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
                });
        } else {
            this.showAlertContact();
        }
    }

    showAlertContact(): void {
        let displayed: boolean = false;
        if (this.contactForm.controls.email.status == 'INVALID') {
            this.errorAlertContact.displayError('email');
            document.getElementById('email').setAttribute('class', 'form-control is-error');
            displayed = true;
        } else {
            document.getElementById('email').setAttribute('class', 'form-control');
        }

        if (this.contactForm.controls.phone_number.status == 'INVALID') {
            this.errorAlertContact.displayError('phone_number');
            document.getElementById('phone_number').setAttribute('class', 'form-control is-error');
            displayed = true;
        } else {
            document.getElementById('phone_number').setAttribute('class', 'form-control');
        }

        if (this.contactForm.controls.city.status == 'INVALID') {
            this.errorAlertContact.displayError('city');
            document.getElementById('city').setAttribute('class', 'form-control is-error');
            displayed = true;
        } else {
            document.getElementById('city').setAttribute('class', 'form-control');
        }

        if (this.contactForm.controls.street.status == 'INVALID') {
            if (!displayed) {
                this.errorAlertContact.displayError('street');
                displayed = true;
            }
            document.getElementById('street').setAttribute('class', 'form-control is-error');
        } else {
            document.getElementById('street').setAttribute('class', 'form-control');
        }

        if (this.contactForm.controls.local.status == 'INVALID') {
            if (!displayed) {
                this.errorAlertContact.displayError('local');
                displayed = true;
            }
            document.getElementById('local').setAttribute('class', 'form-control is-error');
        } else {
            document.getElementById('local').setAttribute('class', 'form-control');
        }

        if (this.contactForm.controls.zip.status == 'INVALID') {
            if (!displayed) this.errorAlertContact.displayError('zip_code');
            document.getElementById('zip').setAttribute('class', 'form-control is-error');
        } else {
            document.getElementById('zip').setAttribute('class', 'form-control');
        }
    }

    changeDescribingCenterProfileSectionStatus(section_number: number, section_name: string) {
        this.changePrivacy.token = this.session.getToken();
        this.changePrivacy.language = this.userService.iLanguage;
        this.changePrivacy.section_name = section_name;
        this.changePrivacy.value = !this.getSectionPrivacy(section_number);
        this.changePrivacy.id_describing_center = this.userService.getCenterId();

        this.odataService.postPrivate('change_describing_center_profile_section_status', this.changePrivacy,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.changePrivacy = {
                        token: '',
                        language: '',
                        id_describing_center: this.userService.getCenterId(),
                        section_name: '',
                        value: false
                    };
                    this.dataSavedToast.success('Saved');
                    this.getOwnDescribingCenterProfile();
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            });
    }

    setSidebar(length: number) {
        let height: number = 0;
        if (!this.publicProfile) height = 933 + (length * 72);
        else height = 650 - (5 - this.fields.length) * 70;
        document.getElementById('navigation_sidebar').style.height = height.toString() + 'px';
        this.stickySidebarFunction = DisplayService.createStickySidebar('navigation_sidebar', document.getElementById('navigation_sidebar').clientHeight, 282, 120, '100%');

    }

    getOwnDescribingCenterProfile(): void {
        this.odataService.postPrivate('get_own_describing_center_profile',
            {
                token: this.session.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId()
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    this.portfolioService.priceLists = [];
                    this.is_edit_price = [];
                    this.isInvalidPrice = [];
                    let value = JSON.parse(result.Value);
                    this.main = value.main;
                    this.coLogoUuid = this.main.image_profile;
                    this.coLogoUrl = this.env.coLogoURL + this.main.image_profile + '?' + this.imageNoCacheSuffix;
                    this.miniCOLogoUrl = this.env.coMiniLogoURL + this.main.image_profile  + '?' + this.imageNoCacheSuffix;
                    this.main.contact_info_enable = value.contact_info.contact_info_enable;
                    this.aboutDc = value.about_dc;
                    this.specializations = value.specializations;
                    this.priceListInfo = value.price_list_info;
                    this.checkIfShowAlertEmptyPriceList();
                    if (this.priceListInfo.price_list_info.id_price_list_list == null) {
                        this.priceListInfo.price_list_info.id_price_list_list = [];
                    }
                    this.statistics = value.statistics;
                    this.workingHourInfo = value.working_hour_info;
                    for (let i = 0; i < this.priceListInfo.price_list_info.id_price_list_list.length; i++) {
                        this.is_edit_price.push(false);
                        this.isInvalidPrice.push(false);
                    }
                    //this.setSidebar(this.priceListInfo.price_list_info.id_price_list_list.length);
                    this.setPortfolio(this.priceListInfo.price_list_info.id_price_list_default,
                        this.priceListInfo.price_list_info.id_price_list_list);
                    this.setButtons();
                    this.setForms();
                    if (this.specializations.specialization != null) {
                        this.spec_count = this.specializations.specialization.length;
                        for (let i = 0; i < this.specializations.specialization.length; ++i) {
                            this.spec.set(this.specializations.specialization[i].id_specialization, false);
                        }
                    } else {
                        this.spec_count = 0;
                    }

                } else {
                    // TODO
                }
            });
    }

    getPublicDescribingCenterProfile(id: number): void {
        this.odataService.postPrivate('get_public_describing_center_profile',
            {
                token: this.session.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: id
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    this.portfolioService.priceLists = [];
                    let value = JSON.parse(result.Value);
                    this.main = value.main;
                    this.coLogoUuid = this.main.image_profile;
                    this.coLogoUrl = this.env.coLogoURL + this.main.image_profile  + '?' + this.imageNoCacheSuffix;
                    this.main.contact_info_enable = value.contact_info.contact_info_enable;
                    this.aboutDc = value.about_dc;
                    this.statistics = value.statistics;
                    this.specializations = value.specializations;
                    this.priceListInfo = value.price_list_info;
                    this.workingHourInfo = value.working_hour_info;
                    this.actionsList = value.action_list;
                    if (this.countKeys(this.priceListInfo) != 0) {
                        this.checkIfShowAlertEmptyPriceList();
                        this.priceListInfo.price_list_info.id_price_list_list = [];
                        this.setPortfolio(this.priceListInfo.price_list_info.id_price_list_default,
                            this.priceListInfo.price_list_info.id_price_list_list);
                    }
                    this.setButtons();
                    this.setForms();
                    if (this.specializations.specialization != null) {
                        this.spec_count = this.specializations.specialization.length;
                        for (let i = 0; i < this.specializations.specialization.length; ++i) {
                            this.spec.set(this.specializations.specialization[i].id_specialization, false);
                        }
                    } else {
                        this.spec_count = 0;
                    }
                } else {
                    // TODO
                }
            });
    }

    onImageSelected(event) {
        let files = event.target.files;
        let file = files[0];

        if (files && file) {
            let reader = new FileReader();

            reader.onload = this.handleFile.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleFile(event) {
        this.dataSavedToast.success('UploadLogo');
        this.dataSavedToast.isLoaderShown = true;
        this.dataSavedToast.show();
        let binaryString = event.target.result;
        this.base64textString = btoa(binaryString);
        this.base64textString = this.base64textString.replace(/\+/gi, '-');
        this.base64textString = this.base64textString.replace(/\//gi, '_');
        this.coUploadLogo();
    }

    coUploadLogo() {
        this.changeImageNoCacheSuffix();
        this.odataService.postPrivate('co_upload_logo',
            {
                token: this.session.getToken(),
                id_describing_center: this.main.id_describing_center,
                base64: this.base64textString
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value = 'OK') {
                    this.getOwnDescribingCenterProfile();
                    this.dataSavedToast.hide();
                } else {
                    this.dataSavedToast.error('UploadLogoError');
                    setTimeout(() => this.dataSavedToast.hide(), 3000);
                }
            });
    }

    setButtons(): void {
        for (let i = 1; i < 7; ++i) {
            let elem = document.getElementById('privacy' + i);
            if (elem) {
                if (this.getSectionPrivacy(i)) elem.setAttribute('class', 'toggle btn btn-success');
                else elem.setAttribute('class', 'toggle btn btn-danger off');
            }
        }
    }

    setForms(): void {
        this.contactForm.controls.email.setValue(this.main.email);
        this.contactForm.controls.phone_number.setValue(this.main.phone_number);
        this.contactForm.controls.street.setValue(this.main.address_street);
        this.contactForm.controls.local.setValue(this.main.address_local);
        this.contactForm.controls.city.setValue(this.main.address_city);
        this.contactForm.controls.zip.setValue(this.main.address_zip_code);
    }

    getSectionPrivacy(section_number: number): boolean {
        if (section_number == 1) return this.workingHourInfo.working_hours_enable;
        else if (section_number == 2) return this.main.contact_info_enable;
        else if (section_number == 3) return this.aboutDc.about_dc_enable;
        else if (section_number == 4) return this.specializations.specialization_enable;
        else if (section_number == 5) return this.priceListInfo.profile_price_list;
        return false; // Na razie brak zmiennych dla pozostałych sekcji.
    }

    setSectionPrivacy(section_number: number, value: boolean): boolean {
        if (section_number == 1) return this.workingHourInfo.working_hours_enable = value;
        else if (section_number == 2) return this.main.contact_info_enable = value;
        else if (section_number == 3) return this.aboutDc.about_dc_enable = value;
        else if (section_number == 4) return this.specializations.specialization_enable = value;
        // Na razie brak zmiennych dla pozostałych sekcji.
    }

    changeDescribingCenterProfileSectionWorkingHours() {
        let startHours = this.startHours.toArray();
        let stopHours = this.stopHours.toArray();

        this.workingHoursForm.patchValue({
          day1Start: startHours[0].GetTime(),
          day1Stop: stopHours[0].GetTime(),
          day2Start: startHours[1].GetTime(),
          day2Stop: stopHours[1].GetTime(),
          day3Start: startHours[2].GetTime(),
          day3Stop: stopHours[2].GetTime(),
          day4Start: startHours[3].GetTime(),
          day4Stop: stopHours[3].GetTime(),
          day5Start: startHours[4].GetTime(),
          day5Stop: stopHours[4].GetTime(),
          day6Start: startHours[5].GetTime(),
          day6Stop: stopHours[5].GetTime(),
          day7Start: startHours[6].GetTime(),
          day7Stop: stopHours[6].GetTime(),
        });

        let isValid = true;
        for (let i = 0; i < startHours.length; i++) {
          if (startHours[i].GetTime() == '' && stopHours[i].GetTime() != '') {
              isValid = false;
              break;
          } else if (startHours[i].GetTime() != '' && stopHours[i].GetTime() == '') {
            isValid = false;
            break;
          } else {
            let hour1 = new Date();
            hour1.setHours(parseInt(startHours[i].GetTime().split(':')[0]), parseInt(startHours[i].GetTime().split(':')[1]));
            let hour2 = new Date();
            hour2.setHours(parseInt(stopHours[i].GetTime().split(':')[0]), parseInt(stopHours[i].GetTime().split(':')[1]));
            if (hour1 > hour2) {
              isValid = false;
              break;
            }
          }
        }

        if (this.workingHoursForm.valid && isValid) {
            this.workingHourList.working_hours = this.profileService.addDays(this.workingHoursForm, 1);
            this.workingHourList.token = this.session.getToken();
            this.workingHourList.language = this.userService.iLanguage;
            this.workingHourList.id_describing_center = this.main.id_describing_center;
            this.odataService.postPrivate('change_describing_center_profile_section_working_hours', this.workingHourList,
                res => {
                    let returnedJson = JSON.stringify(res);
                    let result = JSON.parse(returnedJson);
                    this.workingHourList = {
                        token: '',
                        language: '',
                        id_describing_center: 0,
                        working_hours: ''
                    };
                    if (result.value == 'OK') {
                        this.errorAlertHours.clear();
                        this.editing.workingHours = false;
                        this.getOwnDescribingCenterProfile();
                        this.dataSavedToast.success('Saved');
                    } else if (result.value == 'OTHER_REASON') {
                        this.dataSavedToast.error('NotSaved');
                    }
                    this.dataSavedToast.show();
                    setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
                });
        } else {
            for (let i = 0; i < startHours.length; i++) {
                startHours[i].setHour();
                stopHours[i].setHour();
            }
            this.errorAlertHours.displayError('hours');
        }
    }

    changeDescribingCenterProfileSectionAboutDc(): void {
        let params = {
            token: this.session.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: this.main.id_describing_center,
            about_me: (<HTMLInputElement>document.getElementById('aboutCoInput')).value
        };
        this.odataService.postPrivate('change_describing_center_profile_section_about_dc', params,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.status == 'OK' || result.value == 'OK') {
                    this.editing.aboutCo = false;
                    this.getOwnDescribingCenterProfile();
                    this.dataSavedToast.success('Saved');
                } else if (result.status == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            });
    }

    addSpecialization(): void {
        let deleteButton: string;
        this.translate.get('Profile.DeleteSpecButton').subscribe((res: string) => {
            deleteButton = res;
        });
        let newElement = document.createElement('div');
        newElement.setAttribute('class', 'row');
        newElement.innerHTML =
            '<div class="col-md-11">\
                <div class="form-group">\
                    <select name="select_spec" id="select' + this.spec_count + '" class="form-control">\
                    <option value="1">Choroby wewnętrzne</option>\
                    <option value="2">Medycyna nuklearna</option>\
                </select>\
            </div>\
        </div>\
        <div class="col-md-1 mt-2">\
            <button class="btn btn-danger" type="button" onclick="let select = document.getElementById(\'select' + this.spec_count + '\'); select.parentElement.parentElement.parentElement.parentElement.removeChild(select.parentElement.parentElement.parentElement);">' + deleteButton + '</button>\
        </div>';
        let editContainer = document.getElementById('specEditContainer');
        let addSpecButton = document.getElementById('addSpecButton');
        editContainer.removeChild(addSpecButton);
        editContainer.appendChild(newElement);
        editContainer.appendChild(addSpecButton);
        this.spec_count++;
    }

    changeDescribingCenterProfileSectionPhysicianSpecializations() {
        this.odataService.postPrivate('change_describing_center_profile_section_physician_specializations',
            {
                token: this.session.getToken(),
                id_describing_center: this.main.id_describing_center,
                language: this.userService.iLanguage,
                dc_specializations: this.profileService.getSpecializations(this.spec)
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.editing.specializations = false;
                    this.getOwnDescribingCenterProfile();
                    this.dataSavedToast.success('Saved');
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            }
        );
    }

    changeAllPortfolio(part: string) {
        let price = '';
        if (part == 'modality') {
            price = this.allPortfolioModalities;
        } else if (part == 'part') {
            price = this.allPortfolioParts;
        }
        for (let i = 0; i < this.portfolioService.finalDefaultCoPortfolio.length; i++) {
            for (let j = 0; j < this.portfolioService.finalDefaultCoPortfolio[i].length; j++) {
                this.portfolioService.finalDefaultCoPortfolio[i][j].price = parseInt(price);
            }
        }
    }

    changeAllModalitiesPortfolio() {
        if (this.idPortfolioModality == 'all') {
            this.changeAllPortfolio('modality');
        } else {
            for (let i = 0; i < this.portfolioService.finalDefaultCoPortfolio.length; i++) {
                this.portfolioService.finalDefaultCoPortfolio[i][this.idPortfolioModality].price = parseInt(this.allPortfolioModalities);
            }
        }
    }

    changeAllPartsPortfolio() {
        if (this.idPortfolioPart == 'all') {
            this.changeAllPortfolio('part');
        } else {
            for (let i = 0; i < this.portfolioService.finalDefaultCoPortfolio[this.idPortfolioPart].length; i++) {
                this.portfolioService.finalDefaultCoPortfolio[this.idPortfolioPart][i].price = parseInt(this.allPortfolioParts);
            }
        }
    }

    setPortfolio(list, lists: number[]) {
        this.portfolioService.setDefaultCOPortfolio(this.main.id_describing_center, list);
        if (Array.isArray(lists) && lists.length) {
            this.portfolioService.setPortfolioList(this.main.id_describing_center, lists);
        }
    }

    update_describing_center_price_list(id, resolve, i_id?) {
        let priceList;

        if (i_id == null) {
            priceList = this.portfolioService.finalDefaultCoPortfolio;
            if (this.validatePriceList(priceList)) {
                const pricesJson = this.getPricesJson(priceList);
                this.errorAlertPrices.clear();
                this.postUpdatePriceList(id, pricesJson);
                resolve('OK');
            } else {
                this.showAlertPrices();
            }
        } else {
            priceList = this.portfolioService.listCOPortfolios[i_id];
            if (this.validatePriceList(priceList)) {
                const pricesJson = this.getPricesJson(priceList);
                this.isOtherPriceValid = true;
                this.postUpdatePriceList(id, pricesJson);
                resolve('OK');
            } else {
                this.isOtherPriceValid = false;
                resolve('OK');
            }
        }
    }

    showAlertPrices(): void {
        let displayed: boolean = false;
        if (!displayed) this.errorAlertPrices.displayError('prices');
    }

    validatePriceList(priceList): boolean {
        let isVal = true;

        for (let i = 0; i < priceList.length; i++) {
            for (let j = 0; j < priceList[i].length; j++) {
                if (!ValidationService.validatePrice(priceList[i][j].price.toString()) && priceList[i][j].price != 0) {
                    isVal = false;
                    break;
                } else if (priceList[i][j].price == 0) {
                    priceList[i][j].price = 0;
                }
            }
            if (!isVal) {
                break;
            }
        }

        return isVal;
    }

    getPricesJson(priceList): string {
        let pricesJson = '[';
        for (let i = 0; i < priceList.length; i++) {
            for (let j = 0; j < priceList[i].length; j++) {
                pricesJson += '{"price":';
                if (priceList[i][j].price.toString() != '') {
                    pricesJson += priceList[i][j].price + '.00'
                        + ', "id_part":' + (i + 1) + ', "id_group":' + (j + 1) + '}';
                } else {
                    pricesJson += '0.00'
                        + ', "id_part":' + (i + 1) + ', "id_group":' + (j + 1) + '}';
                }
                if (i != priceList.length - 1) {
                    pricesJson += ',';
                } else if (i == priceList.length - 1 && j != priceList[i].length - 1) {
                    pricesJson += ',';
                }
            }
        }
        pricesJson += ']';

        return pricesJson;
    }

    postUpdatePriceList(id, pricesJson): void {
        this.odataService.postPrivate('update_describing_center_price_list',
            this.odataService.paramsTokenAndLang({
                id_describing_center: this.userService.getCenterId(),
                id_price_list: id,
                prices: pricesJson
            }),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this._isEdit = false;
                    this.dataSavedToast.success('Saved');
                } else if (result.value == 'OTHER_REASON') {
                    this.dataSavedToast.error('NotSaved');
                }
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            });
    }

    changePrices(id, i) {
        let defaultId = this.priceListInfo.price_list_info.id_price_list_default;
        if (id == defaultId && this.editing.pricesCurrent) {
            new Promise((resolve) => { this.update_describing_center_price_list(id, resolve); })
            .then(() => { this.editing.pricesCurrent = false; });
        } else if (id == defaultId && !this.editing.pricesCurrent) {
            this.editing.pricesCurrent = true;
            this._isEdit = true;
        } else if (this.is_edit_price[i]) {
            new Promise((resolve) => { this.update_describing_center_price_list(id, resolve, i); })
            .then(() => {
              if (this.isOtherPriceValid) {
                this.isInvalidPrice[i] = false;
                this.is_edit_price[i] = false;
              } else {
                this.isInvalidPrice[i] = true;
              }
            });
        } else if (!this.is_edit_price[i]) {
            this.is_edit_price[i] = true;
        }
    }

    cancelEditing(section, id, i) {
        if (section == 'prices') {
            if (id == this.priceListInfo.price_list_info.id_price_list_default) {
                this.editing.pricesCurrent = false;
                this._isEdit = false;
            } else if (this.is_edit_price[i]) {
                this.isInvalidPrice[i] = false;
                this.is_edit_price[i] = false;
            }
        } else if (section == 'specializations') {
            this.editing.specializations = false;
        } else if (section == 'aboutCo') {
            this.editing.aboutCo = false;
        } else if (section == 'contact') {
            this.errorAlertContact.clear();
            this.editing.contact = false;
        } else if (section == 'workingHours') {
            this.errorAlertHours.clear();
            this.editing.workingHours = false;
        }

        this.getOwnDescribingCenterProfile();
    }

    scroll(id: string): void {
        DisplayService.scroll(id);
    }

    makeAction(id_action): void {
        if (id_action == 10008) {
            this.sendApplication.setCenter(this.main.id_describing_center, this.main.name);
            this.sendApplication.show();
        }
    }

    showApplicationModal() {
        this.applicationText = '';
        this.errorApplication = '';
        $('#sendApplicationModal').modal('show');
    }

    onApplicationSend() {
        return this.odataService.postPrivate('physician_co_application',
            this.odataService.paramsTokenAndLang({
                id_describing_center: this.main.id_describing_center,
                message: this.applicationText
            }),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    this.errorApplication = '';
                    $('#sendApplicationModal').modal('hide');
                    this.dataSavedToast.success('ApplicationSuccess');
                    this.dataSavedToast.show();
                    setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
                } else {
                    result = JSON.parse(result.Value);
                    if (!result.status) {
                        this.errorApplication = result.error_msg;
                    }
                }
            }
        );
    }

    navigateBack(): void {
        this.navigationService.navigateBack();
    }

    checkIfShowAlertEmptyPriceList(): void {
        this.priceListInfo.price_list_info.id_price_list_default == null ? this.errorAlertEmptyPriceList.displayError('emptyPriceList') : this.errorAlertEmptyPriceList.clear();
    }

    changeImageNoCacheSuffix(): void {
        this.imageNoCacheSuffix = new Date().getTime().toString();
    }

    countKeys(obj: any): number {
        return Object.keys(obj).length;
    }
}
