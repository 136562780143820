import { OdataService } from '@/services/odata.service';
import { UsersService } from '@/services/users.service';
import { SessionService } from '@/services/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "./users.service";
import * as i3 from "./session.service";
var PortfolioService = /** @class */ (function () {
    function PortfolioService(odataService, userService, session) {
        this.odataService = odataService;
        this.userService = userService;
        this.session = session;
        this.modalities = [];
        this.anatomyParts = [];
        this.doctorsPortfolio = [];
        this.finalDoctorsPortfolio = [];
        this.finalDefaultCoPortfolio = [];
        this.listCOPortfolios = [];
        this.priceList = {
            prices: [],
            price_list_name: '',
            id_price_list_main: 0
        };
        this.priceLists = [];
        this.id_default_portfolio = 0;
        this.isRowsChecked = [];
        this.isColsChecked = [];
        this.showEmptyPriceListAlert = false;
    }
    PortfolioService.prototype.setDoctorsPortfolio = function (portfolio) {
        var _this = this;
        Promise.all([new Promise(function (resolve) {
                _this.get_anatomy_parts(resolve);
            }),
            new Promise(function (resolve) {
                _this.get_modalities_groups(resolve);
            })])
            .then(function () {
            _this.doctorsPortfolio = [];
            _this.finalDoctorsPortfolio = [];
            var count = 0;
            _this.isColsChecked = new Array(_this.modalities.length).fill(true);
            _this.isRowsChecked = new Array(_this.anatomyParts.length).fill(true);
            for (var i = 0; i < _this.anatomyParts.length; i++) {
                _this.isRowsChecked.push(false);
                for (var j = 0; j < _this.modalities.length; j++) {
                    _this.doctorsPortfolio.push({
                        enable: false,
                        id_part: i + 1,
                        id_group: j + 1
                    });
                }
            }
            for (var i = 0; i < portfolio.length; i++) {
                var index = portfolio[i].id_part *
                    _this.modalities.length - _this.modalities.length +
                    portfolio[i].id_group;
                _this.doctorsPortfolio[index - 1].enable = portfolio[i].enable;
            }
            for (var i = 0; i < _this.anatomyParts.length; i++) {
                _this.finalDoctorsPortfolio.push([]);
                for (var j = 0; j < _this.modalities.length; j++) {
                    _this.finalDoctorsPortfolio[i].push({
                        enable: _this.doctorsPortfolio[count].enable,
                        id_part: _this.doctorsPortfolio[count].id_part,
                        id_group: _this.doctorsPortfolio[count].id_group
                    });
                    if (!_this.finalDoctorsPortfolio[i][j].enable) {
                        _this.isRowsChecked[i] = false;
                        _this.isColsChecked[j] = false;
                    }
                    count++;
                }
            }
        });
    };
    PortfolioService.prototype.setDefaultCOPortfolio = function (id_center, id_list) {
        var _this = this;
        Promise.all([new Promise(function (resolve) {
                _this.get_anatomy_parts(resolve);
            }),
            new Promise(function (resolve) {
                _this.get_modalities_groups(resolve);
                _this.id_default_portfolio = id_list;
            }),
            new Promise(function (resolve) {
                _this.get_describing_center_price_list(id_center, id_list, resolve);
            })])
            .then(function () {
            _this.finalDefaultCoPortfolio = [];
            var _loop_1 = function (i) {
                _this.finalDefaultCoPortfolio.push(new Array());
                var _loop_2 = function (j) {
                    if (_this.priceList.prices.find(function (x) { return x.id_part == i + 1 && x.id_group == j + 1; }) != null) {
                        _this.finalDefaultCoPortfolio[i].push({
                            price: _this.priceList.prices.find(function (x) { return x.id_part == i + 1 && x.id_group == j + 1; }).price,
                            id_part: _this.priceList.prices.find(function (x) { return x.id_part == i + 1 && x.id_group == j + 1; }).id_part,
                            id_group: _this.priceList.prices.find(function (x) { return x.id_part == i + 1 && x.id_group == j + 1; }).id_group
                        });
                    }
                    else {
                        _this.finalDefaultCoPortfolio[i].push({
                            price: 0,
                            id_part: i + 1,
                            id_group: j + 1
                        });
                    }
                };
                for (var j = 0; j < _this.modalities.length; j++) {
                    _loop_2(j);
                }
            };
            for (var i = 0; i < _this.anatomyParts.length; i++) {
                _loop_1(i);
            }
        });
    };
    PortfolioService.prototype.setPortfolioList = function (id_center, id_list) {
        var _this = this;
        Promise.all([new Promise(function (resolve) {
                _this.get_anatomy_parts(resolve);
            }),
            new Promise(function (resolve) {
                _this.get_modalities_groups(resolve);
            }),
            new Promise(function () {
                _this.listCOPortfolios = [];
                var _loop_3 = function (k) {
                    Promise.all([new Promise((function (resolve1) {
                            _this.get_describing_center_price_list(id_center, id_list[k], resolve1);
                        }))]).then(function () {
                        var portfolio = [];
                        var _loop_4 = function (i) {
                            portfolio.push([]);
                            var _loop_5 = function (j) {
                                if (_this.priceList.prices.find(function (x) { return x.id_group == j && x.id_part == i; }) != null) {
                                    portfolio[i - 1].push({
                                        price: _this.priceList.prices.find(function (x) { return x.id_group == j && x.id_part == i; }).price,
                                        id_group: j,
                                        id_part: i
                                    });
                                }
                                else
                                    portfolio[i - 1].push({
                                        price: 0,
                                        id_group: j,
                                        id_part: i
                                    });
                            };
                            for (var j = 1; j <= _this.modalities.length; j++) {
                                _loop_5(j);
                            }
                        };
                        for (var i = 1; i <= _this.anatomyParts.length; i++) {
                            _loop_4(i);
                        }
                        _this.listCOPortfolios.push(portfolio);
                    });
                };
                for (var k = 0; k < id_list.length; k++) {
                    _loop_3(k);
                }
            })]);
    };
    PortfolioService.prototype.get_modalities_groups = function (resolve) {
        var _this = this;
        this.odataService.postPrivate('get_modalities_groups', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson).Value;
            _this.modalities = JSON.parse(result);
            resolve('OK');
        });
    };
    PortfolioService.prototype.get_anatomy_parts = function (resolve) {
        var _this = this;
        this.odataService.postPrivate('get_anatomy_parts', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson).Value;
            _this.anatomyParts = JSON.parse(result);
            resolve('OK');
        });
    };
    PortfolioService.prototype.get_describing_center_price_list = function (id_center, id_list, resolve) {
        var _this = this;
        this.odataService.postPrivate('get_describing_center_price_list', {
            token: this.session.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: id_center,
            id_price_list: id_list
        }, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.priceList = JSON.parse(result.Value);
                if (id_list != _this.id_default_portfolio) {
                    _this.priceLists.push(_this.priceList);
                }
                _this.priceLists.length == 0 ? _this.showEmptyPriceListAlert = true : _this.showEmptyPriceListAlert = false;
                resolve('OK');
            }
        });
    };
    PortfolioService.prototype.clearPortfolioData = function () {
        this.modalities = [];
        this.anatomyParts = [];
        this.doctorsPortfolio = [];
        this.finalDoctorsPortfolio = [];
        this.finalDefaultCoPortfolio = [];
        this.listCOPortfolios = [];
        this.priceList = {
            prices: [],
            price_list_name: '',
            id_price_list_main: 0
        };
        this.priceLists = [];
        this.id_default_portfolio = 0;
        this.isRowsChecked = [];
        this.isColsChecked = [];
        this.showEmptyPriceListAlert = false;
    };
    PortfolioService.ngInjectableDef = i0.defineInjectable({ factory: function PortfolioService_Factory() { return new PortfolioService(i0.inject(i1.OdataService), i0.inject(i2.UsersService), i0.inject(i3.SessionService)); }, token: PortfolioService, providedIn: "root" });
    return PortfolioService;
}());
export { PortfolioService };
