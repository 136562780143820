<div class="form-row">
  <div class="col form-group">
    <label for="name">{{'DashboardScreen.Filters.Name' | translate}}</label>
    <input id="name"
           type="text"
           [ngModelOptions]="{standalone: true}"
           [(ngModel)]="chosenFilter.value.name">
  </div>
  <div class="col form-group">
    <label for="pesel">{{'DashboardScreen.Filters.Pesel' | translate}}</label>
    <input class="datepicker-placeholder"
           id="pesel"
           type="text"
           [ngModelOptions]="{standalone: true}"
           [(ngModel)]="chosenFilter.value.pesel">
  </div>
</div>
<div class="form-row">
  <div class="col form-group">
    <div>
      <label for="datepicker-range-date-patient">{{'DashboardScreen.Filters.BirthDate' | translate}}</label>
      <div>
        <input #placeholderInput class="datepicker-placeholder" id="placeholder-input-patient"
               type="text" name="placeholder_name" [(ngModel)]="currentDateValue"
               placeholder="{{'DashboardScreen.Filters.DateNotChosen' | translate}}"
               autocomplete="off">
      </div>
      <div id="ghost-div">
        <input #pickerDateRange id="datepicker-range-date-patient" type="text" name="daterangeInput"
               daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
               class="daterangeInputClass"/>
      </div>
    </div>
  </div>
  <div class="col form-group filter">
    <label for="status">{{'DashboardScreen.Filters.Status' | translate}}</label>
    <select id="status" class="custom-select" multiple>
      <option *ngFor="let status of statusTypes" value="{{status.value.id_status}}">
        {{status.value.status}}
      </option>
    </select>
  </div>
</div>
<div class="form-row">
  <div class="col form-group filter">
    <label for="doctors">{{'DashboardScreen.Filters.Physician' | translate}}</label>
    <select id="doctors" class="custom-select" multiple>
      <option *ngFor="let doctor of doctorsForPatientsList" value="{{doctor.value.id_physician}}">
        {{doctor.value.name}}
      </option>
    </select>
  </div>
  <div class="col form-group filter">
    <label for="sex">{{'DashboardScreen.Filters.Sex' | translate}}
    </label>
    <select id="sex" class="custom-select">
      <option value="A">{{'DashboardScreen.Filters.A' | translate}}</option>
      <option value="M">{{'DashboardScreen.Filters.M' | translate}}</option>
      <option value="F">{{'DashboardScreen.Filters.F' | translate}}</option>
    </select>
  </div>
</div>
<div class="form-row">
  <div class="col form-group filter">
    <label for="facility">{{'DashboardScreen.Filters.Facility' | translate}}</label>
    <select id="facility" class="custom-select" multiple>
      <option *ngFor="let facility of facilityTypes" value="{{facility.value.id_medical_facility}}">
        {{facility.value.medical_facility_name}}
      </option>
    </select>
  </div>
  <div class="col form-group">
    <label for="examId">{{'DashboardScreen.Filters.ExamId' | translate}}</label>
    <input class="datepicker-placeholder"
           id="examId"
           type="text"
           [ngModelOptions]="{standalone: true}"
           [(ngModel)]="chosenFilter.value.exam_number">
  </div>
</div>
