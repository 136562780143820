import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DATA_AGREEMENT_OK,
  DATA_AGREEMENT_SKIPPED,
  DATA_AGREEMENT_NO_OPTION,
  PatientDashboardExam
} from '@/interfaces/PatientsPortal';
import { FormBuilder, Validators } from '@angular/forms';
import { PatientPortalService } from '@/services/patient-portal.service';
import { Router } from '@angular/router';
import { OrdersService, UsersService } from '@/services';
import { EnvService } from '@/services/env.service';

@Component({
  selector: 'app-patient-agreement-card',
  templateUrl: './patient-agreement-card.component.html',
  styleUrls: ['./patient-agreement-card.component.scss']
})
export class PatientAgreementCardComponent implements OnInit {

  @Input() examListSize: number;

  @Output() startSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() agreementResult: EventEmitter<number> = new EventEmitter<number>();

  // constants for all search agreement options
  readonly NO_CHOSEN_OPTION: number = DATA_AGREEMENT_NO_OPTION;
  readonly AGREEMENT_SKIPPED: number = DATA_AGREEMENT_SKIPPED;
  readonly AGREED_NO_CLOUD: number = DATA_AGREEMENT_OK;

  // screen switches
  screenSwitch = {
      agreementScreen: true,
      searchScreen: false,
      noExamsScreen: false,
      foundExamsScreen: false
    };

  // progress bar data
  progress: number = 0;

  permissionCheckbox = this.formBuilder.group({
    userAgreement: [false, Validators.required]
  });

  constructor(private portalService: PatientPortalService, private router: Router, private userService: UsersService,
               private formBuilder: FormBuilder, public envService: EnvService, public orderService: OrdersService) { }

  ngOnInit() {
  }

  setAgreement(chosenOption: number) {
    this.userService.changePatientSearchDataAgreement(chosenOption).then(e => {
      let setOption = this.userService.getPatientSearchDataAgreement();
      if (setOption != DATA_AGREEMENT_NO_OPTION && setOption != DATA_AGREEMENT_SKIPPED) {
        this.startSearch.emit(true);
        this.startSearchScreen();
      } else {
        if (setOption == DATA_AGREEMENT_NO_OPTION) {
          this.screenSwitch = {
            agreementScreen: true,
            searchScreen: false,
            noExamsScreen: false,
            foundExamsScreen: false
          };
        }
        this.agreementResult.emit(setOption);
      }
    });
  }

  startSearchScreen() {
    this.progress = 0;
    this.screenSwitch.agreementScreen = false;
    this.screenSwitch.searchScreen = true;
    this.updateProgressBarAndNavigate();
  }

  updateProgressBarAndNavigate() {
    setTimeout(() => {
      this.progress += 10;
      if (this.progress < 100) {
        this.updateProgressBarAndNavigate();
      } else {
        this.screenSwitch.searchScreen = false;
        console.log(this.examListSize);
        if (this.examListSize != 0) this.foundExamsScreen();
        else this.noExamsScreen();
      }
    }, 1000);
  }

  private foundExamsScreen() {
    this.screenSwitch.foundExamsScreen = true;

  }

  private noExamsScreen() {
    this.screenSwitch.noExamsScreen = true;
  }

  resetAgreement() {
    this.setAgreement(DATA_AGREEMENT_NO_OPTION);
  }
}
