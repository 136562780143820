import { Component, OnInit, ViewChild } from '@angular/core';
import { OdataService, OrdersService, UsersService } from '@/services';
import { FormBuilder, Validators } from '@angular/forms';
import { EnvService } from '@/services/env.service';
import {
  DATA_AGREEMENT_OK,
  DATA_AGREEMENT_SKIPPED,
  DATA_AGREEMENT_NO_OPTION,
  PatientDashboardExam,
  IShareInfo,
} from '@/interfaces/PatientsPortal';
import { Router } from '@angular/router';
import { PatientPortalService } from '@/services/patient-portal.service';
import { PatientExamUploadModalComponent } from '@/utils/patient-exam-upload-modal/patient-exam-upload-modal.component';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { PatientShareExamModalComponent } from '@/utils/patient-share-exam-modal/patient-share-exam-modal.component';
import { PatientAgreementCardComponent } from '@/utils/patient-agreement-card/patient-agreement-card.component';

@Component({
  selector: 'app-patients-portal-dashboard',
  templateUrl: './patients-portal-dashboard.component.html',
  styleUrls: ['./patients-portal-dashboard.component.scss']
})
export class PatientsPortalDashboardComponent implements OnInit {

  @ViewChild('patientExamUploadModal') patientExamUploadModal: PatientExamUploadModalComponent;
  @ViewChild('shareExamModalComponent') shareExamModal: PatientShareExamModalComponent;
  @ViewChild('loader') loader: LoaderComponent;
  @ViewChild('patientAgreementCard') patientAgreementCard: PatientAgreementCardComponent;

  // constants for all search agreement options
  readonly NO_CHOSEN_OPTION: number = DATA_AGREEMENT_NO_OPTION;
  readonly AGREEMENT_SKIPPED: number = DATA_AGREEMENT_SKIPPED;
  examList: PatientDashboardExam[] = [];
  patientExamSearchOption: number = DATA_AGREEMENT_NO_OPTION;
  folderShareInfo: IShareInfo;
  constructor(private portalService: PatientPortalService, private router: Router, private userService: UsersService, private formBuilder: FormBuilder, public envService: EnvService, public orderService: OrdersService) { }

  ngOnInit() {

    this.refreshShareInfo();
    // Temporary fix for global classes
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('pp-portal');
    let menuActiveElement = document.getElementById('patientDashboardLink');
    menuActiveElement.classList.add('active');

    this.userService.userDataPromise.then(e => {
      this.patientExamSearchOption = this.userService.getPatientSearchDataAgreement();
      if (this.patientExamSearchOption != DATA_AGREEMENT_NO_OPTION) this.getExamList().then(res => this.loader.show = false);
      else {
        this.examList = [];
        this.loader.show = false;
      }
    });

    // Do testowania ekranu zgody i przejść - odkomentowac i odświeżyć stronę po załadowaniu
    // this.userService.changePatientSearchDataAgreement(this.NO_CHOSEN_OPTION);
  }

  getExamList(): Promise<any> {
    return this.portalService.getExamList(1000).then( res => this.examList = res);
  }

  isAgreementCard(): boolean {
    return this.patientExamSearchOption == DATA_AGREEMENT_NO_OPTION;
  }

  uploadExamFromDisc() {
    this.patientExamUploadModal.show();
  }

  seeExam(examId: number, examType: number) {
    this.router.navigate(['badania/szczegoly'], {queryParams: {id: examId, type: examType}});
  }

  resetAgreement() {
      this.userService.changePatientSearchDataAgreement(DATA_AGREEMENT_NO_OPTION).then(res => this.patientExamSearchOption = DATA_AGREEMENT_NO_OPTION);
  }

  refreshShareInfo() {
    this.portalService.getFolderShareStatus().then( e => this.folderShareInfo = this.portalService.folderShareInfo);
  }
}
