import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@/services/page.service';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-find-co',
    templateUrl: './find-co.component.html',
    styleUrls: ['./find-co.component.css']
})
export class FindCOComponent implements OnInit, OnDestroy {

    constructor(public envService: EnvService, private pageService: PageService) { }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('BeforeWorkDoctor.FindDCTitle', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

}
