/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./already-registered-screen.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "../alert/alert.component.ngfactory";
import * as i5 from "../alert/alert.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../services/session.service";
import * as i8 from "@angular/router";
import * as i9 from "../language-button/language-button.component.ngfactory";
import * as i10 from "../language-button/language-button.component";
import * as i11 from "../../services/users.service";
import * as i12 from "./already-registered-screen.component";
var styles_AlreadyRegisteredScreenComponent = [i0.styles];
var RenderType_AlreadyRegisteredScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlreadyRegisteredScreenComponent, data: {} });
export { RenderType_AlreadyRegisteredScreenComponent as RenderType_AlreadyRegisteredScreenComponent };
export function View_AlreadyRegisteredScreenComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { errorAlert: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "register-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "row vh-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "col col-sm-12 col-md-6 col-lg-5 p-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "svg-icon", [["alt", "Evolution"], ["class", "logo"], ["src", "../../../assets/images/logo.svg"], ["style", "display: block;"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(8, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-alert", [["title", "Registration"]], null, null, null, i4.View_AlertComponent_0, i4.RenderType_AlertComponent)), i1.ɵdid(11, 770048, [[1, 4], ["errorAlert", 4]], 0, i5.AlertComponent, [i6.TranslateService, i7.SessionService], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-lg btn-primary"], ["routerLink", "/loginFirst"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i8.RouterLink, [i8.Router, i8.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col col-sm-12 col-md-6 col-lg-7\n                  bg p-100 p-desktop align-items-center\n                  d-flex "]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-language-button", [], null, null, null, i9.View_LanguageButtonComponent_0, i9.RenderType_LanguageButtonComponent)), i1.ɵdid(19, 114688, null, 0, i10.LanguageButtonComponent, [i11.UsersService], null, null)], function (_ck, _v) { var currVal_0 = "../../../assets/images/logo.svg"; _ck(_v, 8, 0, currVal_0); var currVal_1 = "Registration"; _ck(_v, 11, 0, currVal_1); var currVal_2 = "/loginFirst"; _ck(_v, 14, 0, currVal_2); _ck(_v, 19, 0); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("AlreadyRegisteredScreen.ReturnToLoginFirst")); _ck(_v, 15, 0, currVal_3); }); }
export function View_AlreadyRegisteredScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-already-registered-screen", [], null, null, null, View_AlreadyRegisteredScreenComponent_0, RenderType_AlreadyRegisteredScreenComponent)), i1.ɵdid(1, 114688, null, 0, i12.AlreadyRegisteredScreenComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlreadyRegisteredScreenComponentNgFactory = i1.ɵccf("app-already-registered-screen", i12.AlreadyRegisteredScreenComponent, View_AlreadyRegisteredScreenComponent_Host_0, {}, {}, []);
export { AlreadyRegisteredScreenComponentNgFactory as AlreadyRegisteredScreenComponentNgFactory };
