import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';
import { NavigationService } from './navigation.service';
import { EnvService } from './env.service';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "./session.service";
import * as i3 from "./navigation.service";
import * as i4 from "./env.service";
var UsersService = /** @class */ (function () {
    function UsersService(odataService, sessionService, navigationService, env) {
        var _this = this;
        this.odataService = odataService;
        this.sessionService = sessionService;
        this.navigationService = navigationService;
        this.env = env;
        this.iClientIp = '';
        this.iLanguage = '';
        this.descCenter = null;
        this.unverifiedCenterExists = false;
        this.medFacility = null;
        this.username = '';
        this.profileImageUrl = '';
        this.miniProfileImageUrl = '';
        this.imageNoCacheSuffix = '';
        this.dcListPromise = null;
        this.mfListPromise = null;
        this.profileImageUuid = '';
        this.centerChanged = new EventEmitter();
        this.facilityChanged = new EventEmitter();
        this.descCenters = [];
        this.medFacilities = [];
        this.unverifiedDescCenters = [];
        this.userDcPermissions = [];
        this.patientSearchDataAgreement = 0;
        this.patientCloudAgreement = 0;
        this.getProfileImageUrl = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, this.profileImageUrl];
        }); }); };
        this.setLanguage();
        this.changeImageNoCacheSuffix();
        if (this.sessionService.tokenExist()) {
            this.getUserData();
        }
    }
    UsersService.prototype.changeLang = function () {
        this.sessionService.changeLang();
        this.iLanguage = this.sessionService.language;
    };
    UsersService.prototype.setLanguage = function () {
        this.sessionService.setLanguage();
        this.iLanguage = this.sessionService.language;
    };
    UsersService.prototype.getUserData = function () {
        var _this = this;
        this.userDataPromise = this.odataService.postPrivate('get_user_profile2', this.odataService.paramsTokenAndLang({}), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                sessionStorage.setItem('username', value.username);
                _this.username = value.username;
                _this.userId = value.id_user;
                _this.profileImageUuid = value.profile_image_uuid;
                _this.profileImageUrl = _this.env.profilePicURL + value.profile_image_uuid + '?' + _this.imageNoCacheSuffix;
                _this.miniProfileImageUrl = _this.env.miniProfilePicURL + value.profile_image_uuid + '?' + _this.imageNoCacheSuffix;
                _this.navigationService.setContexts(value.user_context);
                _this.patientSearchDataAgreement = value.user_personal_data_agreement_status;
                _this.patientCloudAgreement = value.user_cloud_agreement_status;
            }
            else {
                // TODO
            }
        });
        return this.userDataPromise;
    };
    UsersService.prototype.getPatientSearchDataAgreement = function () { return this.patientSearchDataAgreement; };
    UsersService.prototype.getPatientCloudAgreement = function () {
        return this.patientCloudAgreement;
    };
    UsersService.prototype.getDcList = function () {
        var _this = this;
        return this.odataService.postPrivate('get_user_co_list', this.odataService.paramsTokenAndLang({}), function (res) {
            var centers = _this.parseList(res);
            _this.descCenters = [];
            _this.unverifiedDescCenters = [];
            centers.forEach(function (e) {
                if (e.status)
                    _this.descCenters.push(e);
                else {
                    _this.unverifiedDescCenters.push(e);
                    _this.unverifiedCenterExists = true;
                }
            });
            return res;
        });
    };
    UsersService.prototype.getUserDcPermissions = function (id) {
        var _this = this;
        return this.odataService.postPrivate('get_co_permissions', this.odataService.paramsTokenAndLang({
            id_describing_center: id
        }), function (res) {
            _this.userDcPermissions = _this.parseList(res);
            return res;
        });
    };
    UsersService.prototype.getMfList = function () {
        var _this = this;
        return this.odataService.postPrivate('get_user_mf_list', this.odataService.paramsTokenAndLang({}), function (res) {
            _this.medFacilities = _this.parseList(res);
            return res;
        });
    };
    UsersService.prototype.getContactId = function () {
        var _this = this;
        var id = 0;
        var isVerified = false;
        this.descCenters.forEach(function (el) {
            if (el.idDescribingCenter == _this.getCenterId()) {
                id = el.contactId;
                isVerified = true;
            }
        });
        if (!isVerified) {
            this.unverifiedDescCenters.forEach(function (el) {
                if (el.idDescribingCenter == _this.getCenterId()) {
                    id = el.contactId;
                }
            });
        }
        return id;
    };
    UsersService.prototype.getContactName = function () {
        var _this = this;
        var name = '';
        var isVerified = false;
        this.descCenters.forEach(function (el) {
            if (el.idDescribingCenter == _this.getCenterId()) {
                name = el.contactName;
                isVerified = true;
            }
        });
        if (!isVerified) {
            this.unverifiedDescCenters.forEach(function (el) {
                if (el.idDescribingCenter == _this.getCenterId()) {
                    name = el.contactName;
                }
            });
        }
        return name;
    };
    UsersService.prototype.isSendingMessageEnable = function () {
        return this.getContactId() != this.userId;
    };
    UsersService.prototype.isCenterVerified = function () {
        var _this = this;
        var isVerified = false;
        this.descCenters.forEach(function (el) {
            if (el.idDescribingCenter == _this.getCenterId()) {
                isVerified = true;
            }
        });
        return isVerified;
    };
    UsersService.prototype.parseList = function (res) {
        var result = JSON.parse(JSON.stringify(res));
        var value = this.odataService.keysToCamel(JSON.parse(result.Value));
        return value;
    };
    UsersService.prototype.changeDescCenter = function (center) {
        this.centerChanged.emit(center.idDescribingCenter);
        this.descCenter = center;
        sessionStorage.setItem('currentCenter', center.idDescribingCenter.toString());
    };
    UsersService.prototype.changeMedFacility = function (facility) {
        this.medFacility = facility;
        sessionStorage.setItem('currentFacility', facility.idMedicalFacility.toString());
        this.facilityChanged.emit(facility.idMedicalFacility);
    };
    UsersService.prototype.getCenterId = function () {
        if (this.descCenter == null && sessionStorage.getItem('currentCenter'))
            return parseInt(sessionStorage.getItem('currentCenter'));
        else
            return this.descCenter && this.descCenter.idDescribingCenter;
    };
    UsersService.prototype.getFacilityId = function () {
        if (this.medFacility == null && sessionStorage.getItem('currentFacility'))
            return parseInt(sessionStorage.getItem('currentFacility'));
        else
            return this.medFacility && this.medFacility.idMedicalFacility;
    };
    UsersService.prototype.getCenterName = function () {
        return this.descCenter && this.descCenter.name;
    };
    UsersService.prototype.getCenterStatus = function () {
        return this.descCenter && this.descCenter.status;
    };
    UsersService.prototype.clearUserData = function () {
        this.iClientIp = '';
        this.descCenter = null;
        this.medFacility = null;
        this.descCenters = [];
        this.unverifiedDescCenters = [];
        this.medFacilities = [];
        this.username = '';
        this.profileImageUrl = '';
        this.miniProfileImageUrl = '';
        this.profileImageUuid = '';
        this.userId = -1;
        this.unverifiedCenterExists = false;
    };
    UsersService.prototype.changeImageNoCacheSuffix = function () {
        this.imageNoCacheSuffix = new Date().getTime().toString();
    };
    UsersService.prototype.setFacility = function (facility) {
        this.medFacility = facility;
    };
    UsersService.prototype.getFacilityName = function () {
        return this.medFacility && this.medFacility.medicalFacilityName;
    };
    UsersService.prototype.checkDcPermission = function (name) {
        return this.userDcPermissions.filter(function (e) { return e.permissionsName === name; }).length > 0;
    };
    UsersService.prototype.changePatientSearchDataAgreement = function (option) {
        var _this = this;
        return this.odataService.postPrivate('pp_change_user_personal_data_agreement_status', this.odataService.paramsTokenAndLang({
            status: option
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.value == 'OK')
                _this.patientSearchDataAgreement = option;
        });
    };
    UsersService.prototype.changePatientCloudAgreement = function (option) {
        var _this = this;
        return this.odataService.postPrivate('pp_change_user_cloud_agreement_status', this.odataService.paramsTokenAndLang({
            status: option
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.value == 'OK')
                _this.patientCloudAgreement = option;
        });
    };
    UsersService.ngInjectableDef = i0.defineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.inject(i1.OdataService), i0.inject(i2.SessionService), i0.inject(i3.NavigationService), i0.inject(i4.EnvService)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
