<div class="row">
    <div class="col-md-5">
        <div class="card big-card">
            <div class="card-body">
                <h2 class="light mb-5 mt-3">{{'OrderDescriptionScreens.OrderConsultation.ChooseDoctor' | translate}}</h2>

                <form class="search-form">

                    <div class="form-row d-none">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox ml-1">
                                <input class="custom-control-input" id="tylko-w-moim" type="checkbox">
                                <label class="custom-control-label" for="tylko-w-moim">
                                    {{'OrderDescriptionScreens.OrderConsultation.SearchOnlyMyCO' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <input class="search" id="searchInput" placeholder="{{'OrderDescriptionScreens.OrderConsultation.ChooseDoctor' | translate}}"
                           type="text">
                </form>


                <ul class="nav nav-tabs mt-5 mb-4">
                    <li class="nav-item">
                        <a class="nav-link active" routerLink="/siteUnderConstruction">
                            {{'OrderDescriptionScreens.OrderConsultation.RecentConsultations' | translate}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/siteUnderConstruction">
                            {{'OrderDescriptionScreens.OrderConsultation.CurrentCODoctors' | translate}}</a>
                    </li>
                </ul>

                <div class="row my-auto d-flex">
                    <div class="col-md-2"><img alt=""
                                               class="rounded img-fluid" src="../../../../../assets/images/_dummy_data/avatar_square.jpg"></div>
                    <div class="col-md-6 my-auto">
                        <p class="mb-0">dr Marek Kowalski<br/><strong>Lekarz</strong> Warszawa, Polska</p>
                    </div>
                    <div class="col-md-4 my-auto">
                        <a class="btn btn-lg btn-grey checkmark float-right mr-0" routerLink="/siteUnderConstruction"><svg-icon alt=""
                                                                                                class="svg"
                                                                                                src="../../../../../assets/images/icons/icon__checkmark.svg"></svg-icon>{{'OrderDescriptionScreens.ChooseButton' | translate}}
                        </a>
                    </div>
                </div>

                <br/>

                <div class="row my-auto d-flex">
                    <div class="col-md-2"><img alt=""
                                               class="rounded img-fluid" src="../../../../../assets/images/_dummy_data/avatar_big.jpg"></div>
                    <div class="col-md-6 my-auto">
                        <p class="mb-0">dr Barbara Kozłowska<br/><strong>Lekarz</strong> Warszawa, Polska</p>
                    </div>
                    <div class="col-md-4 my-auto">
                        <a class="btn btn-lg btn-grey checkmark float-right mr-0" routerLink="/siteUnderConstruction"><svg-icon alt=""
                                                                                                class="svg"
                                                                                                src="../../../../../assets/images/icons/icon__checkmark.svg"></svg-icon>{{'OrderDescriptionScreens.ChooseButton' | translate}}
                        </a>
                    </div>
                </div>

                <p [innerHTML]="'OrderDescriptionScreens.OrderConsultation.SearchInfo' | translate"
                   class="light mt-5"></p>

            </div>
        </div>
    </div>

    <div class="col-md-7">
        <div class="card">
            <div class="card-body">
                <div class="row p-3">
                    <div class="col-md-12">
                        <p><strong>{{'OrderDescriptionScreens.OrderConsultation.MessageTo' | translate}}</strong> <img
                                alt=""
                                class="ml-3 mr-2 d-inline img-fluid" src="../../../../../assets/images/_dummy_data/avatar_big.jpg"
                                style="width: 32px; position: relative; top: -2px;"> dr Barbara Kozłowska
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <app-text-editor #text_editor></app-text-editor>

                    </div>
                </div>
                <a class="btn btn-lg btn-success float-right mt-5 mb-4"
                   routerLink="/siteUnderConstruction">{{'OrderDescriptionScreens.OrderConsultation.AskForConsultation' | translate}}</a>
            </div>
        </div>

    </div>

</div>

<div class="row">
    <div class="col-md-12">
        <a routerLink="/consultations"
           class="btn btn-lg btn-white mt-4">{{'OrderDescriptionScreens.OrderConsultation.ConsultationsList' | translate}}</a>
    </div>
</div>