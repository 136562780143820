import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-authorize-description-module',
    templateUrl: './authorize-description-module.component.html',
    styleUrls: ['./authorize-description-module.component.css']
})
export class AuthorizeDescriptionModuleComponent implements OnInit {

    isAuthorize: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {

    }

    show() {
        this.isAuthorize.emit(false);
        $('#authorizeDescriptionModal').modal('show');
    }

    hide() {
        $('#authorizeDescriptionModal').modal('hide');
    }

    authorize() {
        this.isAuthorize.emit(true);
    }

}
