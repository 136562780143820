export interface ArchivedOrderHistory {
    serviceType: String;
    status: String;
    date: Date;
    examinationId: Number;
    cost: Number;
    currency: String;
}


export const dummyOrders: ArchivedOrderHistory[] = [
    {
        serviceType: 'Konsultacja',
        status: 'W opisie',
        date: new Date(),
        examinationId: 2481947,
        cost: 50,
        currency: 'PLN',
    },

    {
        serviceType: 'Płyta z badaniem',
        status: 'Wykonana',
        date: new Date(),
        examinationId: 2481947,
        cost: 50,
        currency: 'PLN',
    },
];
