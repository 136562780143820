import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var NavigationService = /** @class */ (function () {
    function NavigationService(router, _location) {
        this.router = router;
        this._location = _location;
    }
    NavigationService.prototype.setContexts = function (userContexts) {
        userContexts.forEach(function (element) {
            sessionStorage.setItem(element.context_name, element.id_context);
        });
        this.setCurrentContext();
    };
    NavigationService.prototype.navigateToDefault = function () {
        if (sessionStorage.getItem('currentContext') == 'MEDICAL_FACILITY') {
            this.router.navigate(['/medicalFacilityExams']);
        }
        else if (sessionStorage.getItem('currentContext') == 'DESCRIBING_CENTER') {
            this.router.navigate(['/dashboard']);
        }
        else if (sessionStorage.getItem('currentContext') == 'PATIENT' || sessionStorage.getItem('currentContext') == 'PHYSICIAN') {
            localStorage.setItem('darkMode', 'false');
            this.router.navigate(['/portal-pacjenta']);
        }
        else {
            this.router.navigate(['/accountProperties']);
        }
    };
    NavigationService.prototype.setCurrentContext = function (contextName) {
        if (contextName) {
            sessionStorage.setItem('currentContext', contextName);
        }
        else {
            if (this.getCurrentContext() == 'PATIENT' && this.hasContext('PHYSICIAN')) {
                sessionStorage.setItem('currentContext', 'PHYSICIAN');
            }
            else if (!sessionStorage.getItem('currentContext')) {
                if (sessionStorage.getItem('DESCRIBING_CENTER') != null) {
                    sessionStorage.setItem('currentContext', 'DESCRIBING_CENTER');
                }
                else if (sessionStorage.getItem('MEDICAL_FACILITY') != null) {
                    sessionStorage.setItem('currentContext', 'MEDICAL_FACILITY');
                }
                else if (sessionStorage.getItem('PHYSICIAN') != null) {
                    sessionStorage.setItem('currentContext', 'PHYSICIAN');
                }
                else if (sessionStorage.getItem('PATIENT') != null) {
                    sessionStorage.setItem('currentContext', 'PATIENT');
                }
                else {
                    sessionStorage.setItem('currentContext', '');
                }
            }
        }
    };
    NavigationService.prototype.hasContext = function (context) {
        return sessionStorage.getItem(context) != null;
    };
    NavigationService.prototype.getCurrentContext = function () {
        return sessionStorage.getItem('currentContext');
    };
    NavigationService.prototype.navigateBack = function () {
        this._location.back();
    };
    NavigationService.ngInjectableDef = i0.defineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.inject(i1.Router), i0.inject(i2.Location)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
