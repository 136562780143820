import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PeselInputComponent } from '@/utils';
import { AlertComponent } from 'src/app/utils/alert/alert.component';
import { UsersService, OdataService, SessionService, CookiesService } from '@/services';
import { RegistrationService } from '@/services/registration.service';
import { NavigationService } from '@/services/navigation.service';
import { PageService } from '@/services/page.service';

interface IPassword {
    uuid: string;
    login_name: string;
    password: string;
    filled_fields: string;
}

interface ILoginStatus {
    LoginStatus: string;
    Token: string;
    LoginFailedAddtionalInfo: string;
    BlockTimestamp: string;
}

@Component({
    selector: 'app-login-second',
    templateUrl: './login-second.component.html',
    styleUrls: ['./login-second.component.css']
})
export class LoginSecondComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('loginAlert') loginAlert: AlertComponent;
    @ViewChild('peselInput') peselInput: PeselInputComponent;

    isSuccess: boolean = false;
    isError: boolean = false;
    password: string = '';
    returnedJson: string;
    login = { value: '' };
    uuid: string = '';
    step3: IPassword;
    result: ILoginStatus;
    userService: UsersService;


    constructor(
        userService: UsersService,
        private odataService: OdataService,
        private sessionService: SessionService,
        private registrationService: RegistrationService,
        private navigationService: NavigationService,
        private pageService: PageService,
        private router: Router,
        private cookiesService: CookiesService) {

        this.userService = userService;

        this.login.value = this.registrationService.getLoginName();
        this.uuid = this.registrationService.getUuid();
    }

    public ngAfterViewInit(): void {
        document.getElementById('inputPassword').focus();
    }

    ngOnInit() {
        if (this.login.value === '' || this.uuid === '') {
            this.router.navigate(['loginFirst']);
        }

        if (this.registrationService.iloginDoc !== undefined) {
            this.peselInput.fillFields(this.registrationService.iloginDoc.FieldsToCheck);
        } else {
            this.login_attempt_step2();
        }

        this.pageService.setLogInPageTitle();
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    login_attempt_step3() {
        this.isError = false;

        this.step3 = {
            uuid: this.registrationService.getUuid(),
            login_name: this.registrationService.getLoginName(),
            password: this.password,
            filled_fields: this.peselInput.calculateFilledFieldsString()
        };

        this.odataService.postPublic('login_attempt_step3', this.step3,
            res => {
                this.returnedJson = JSON.stringify(res);
                this.result = JSON.parse(this.returnedJson);
                this.registrationService.iloginStatus = this.result;
                if (this.result.LoginStatus == 'SUCCESFULL') {
                    this.sessionService.startNewSession(this.result.Token);
                    this.cookiesService.createSessionCookie();
                    this.userService.getUserData().then(
                        () => {
                            this.navigationService.navigateToDefault();
                        }
                    );
                } else if (this.result.LoginStatus == 'FAILED') {
                    this.isError = true;
                    this.loginAlert.displayError('OTHER_REASON_FPF');
                }
            });
    }

    login_attempt_step2() {
        let step2 = { uuid: '', login_name: '' };

        if (this.uuid != null) {
            step2.uuid = this.uuid;
            step2.login_name = this.login.value;
            this.registrationService.setILogin(step2);
        }

        this.odataService.postPublic('login_attempt_step2', step2,
            res => {
                const newPost = JSON.stringify(res);
                let loginDoc = JSON.parse(newPost);
                this.registrationService.iloginDoc = loginDoc;
                this.registrationService.isLogout = false;
                this.registrationService.isPassChanged = false;
                this.registrationService.isLogoutAutomatically = false;

                if (loginDoc.Status !== 'ENABLED') {
                    this.router.navigate(['loginFirst']);
                    return;
                }

                this.peselInput.fillFields(this.registrationService.iloginDoc.FieldsToCheck);
            });
    }

}
