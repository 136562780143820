import {IFacilityOrdersFilter, IFilterFacilityExams} from './../interfaces/Filter';
import {
  IFacilityOrders,
  IFacilityExams,
  initFacilityOrder,
  IFacilityOrderDetails, initFacilityOrderDetails
} from './../interfaces/MedicalFacility';
import { OdataService } from './odata.service';
import {UsersService, OrderDescriptionService, NavigationService, SessionService} from '@/services';
import { Injectable, EventEmitter } from '@angular/core';
import { LoaderComponent } from '@/utils/loader/loader.component';
import moment from 'moment';
import { IAction } from '@/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FacilityOrdersService {

    orders: IFacilityOrders[] = [];
    exams: IFacilityExams[] = [];
    examsDates = [];
    examsAges = [];
    examsHours = [];
    newOrdersString = '';
    width = '20px';
    age: any[] = [];
    dates = [];
    hours = [];
    promSearch: Promise<String>;
    numberOfOrders: EventEmitter<number> = new EventEmitter<number>();
    numberOfExams: EventEmitter<number> = new EventEmitter<number>();
    dashboardLoader: LoaderComponent = null;
    examsLoader: LoaderComponent = null;
    examToOrdersId: number;
    orderDetails: IFacilityOrderDetails = initFacilityOrderDetails;

    constructor(private descriptionService: OrderDescriptionService, private userService: UsersService, private odataService: OdataService,
        private navigationService: NavigationService, private sessionService: SessionService) { }

    getMfExamOrders(filter: IFacilityOrdersFilter, limit: number, offset: number, filter_search?: string): Promise<string> {
        this.orders = [];
        if (this.dashboardLoader)
            this.dashboardLoader.show = true;

        return this.odataService.postPrivate('get_mf_exam_orders_ext',
            this.odataService.paramsTokenAndLang({
                id_medical_facility: this.userService.getFacilityId(),
                filtr: JSON.stringify(filter),
                limit: limit,
                offset: offset,
                filtr_search: filter_search ? filter_search : ''
            }),
            res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.orders = this.setOrders(result);
            }
            if (this.dashboardLoader)
                this.dashboardLoader.show = false;

            return result.Status;
        });
    }

    getMfExams(filter: IFilterFacilityExams, limit: number, offset: number, filter_search?: string, changedId?: number): Promise<string> {
        this.exams = [];
        if (this.examsLoader)
            this.examsLoader.show = true;

        let facilityId = changedId ? changedId : this.userService.getFacilityId(); // making sure we're picking id that's up-to-date if facility changed, or recovering one from storage

        return this.odataService.postPrivate('get_mf_exam_lists',
            this.odataService.paramsTokenAndLang({
                id_medical_facility: facilityId,
                filtr: JSON.stringify(filter),
                limit: limit,
                offset: offset,
                filtr_search: filter_search ? filter_search : ''
            }),
            res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.exams = this.setExams(result);
            }
            if (this.examsLoader)
                this.examsLoader.show = false;

            return result.Status;
        });
    }

    setOrders(result): IFacilityOrders[] {
        let value = JSON.parse(result.Value || '[]');
        this.clearOrdersData();
        this.numberOfOrders.emit(result.ValueCount);

        for (let i = 0; i < value.length; i++) {
            this.setOrderData(value[i]);
        }

        return value;
    }

    setExams(result): IFacilityExams[] {
        let value = JSON.parse(result.Value || '[]');
        this.clearExamsData();
        this.numberOfExams.emit(result.ValueCount);
        for (let i = 0; i < value.length; i++) {
            this.setExamData(value[i]);
        }

        return value;
    }


    clear_examToOrdersId()  {
      this.examToOrdersId = undefined;
    }

    set_examToOrdersId(value: number) {
      this.examToOrdersId = value;
    }

  clearOrdersData(): void {
        this.orders = [];
        this.age = [];
        this.hours = [];
        this.dates = [];
    }

    clearExamsData(): void {
        this.exams = [];
        this.examsAges = [];
        this.examsHours = [];
        this.examsDates = [];
    }

    setOrderData(order: IFacilityOrders): void {
        let orderData = this.getDateHourAge(order);

        this.age.push(orderData['age']);
        this.hours.push(orderData['orderHour']);
        this.dates.push(orderData['orderDate']);

        this.sortActions(order);
    }

    setExamData(exam: IFacilityExams): void {
        let orderData = this.getDateHourAge(exam);

        this.examsAges.push(orderData['age']);
        this.examsHours.push(orderData['hour']);
        this.examsDates.push(orderData['date']);

        this.sortExamActions(exam);
    }

    sortActions(info: any): any {
        info.action_list.sort((a1: IAction, a2: IAction) => {
            if (a1.action_importance > a2.action_importance) return -1;
            else if (a1.action_importance == a2.action_importance) return 0;
            else return 1;
        });
    }

    sortExamActions(info: any): any {
        info.exam_actions.sort((a1: IAction, a2: IAction) => {
            if (a1.action_importance > a2.action_importance) return -1;
            else if (a1.action_importance == a2.action_importance) return 0;
            else return 1;
        });
    }

    getDateHourAge(info) {
        moment.locale(this.userService.iLanguage);
        let date = this.getDateString(info.exam_date);
        let orderDate = this.getDateString(info.exam_order_date);
        let hour = moment(info.exam_date).fromNow();
        let orderHour = moment(info.exam_order_date).fromNow();
        let age = this.descriptionService.calculateAgeFromDates(new Date(info.birth_date), new Date());
        return { age: age, hour: hour, date: date, orderDate: orderDate, orderHour: orderHour };
    }

    getDateString(examDate: string): string {
        let orderMoment = moment(examDate);
        let today = moment(new Date()).hours(23).minutes(59);
        let daysDiff = moment(examDate).hours(0).minutes(0).diff(today, 'days');
        if (daysDiff === 0)
            return `Dziś, ${orderMoment.format('HH:mm')}`;
        else if (daysDiff === -1)
            return `Wczoraj, ${orderMoment.format('HH:mm')}`;

        return orderMoment.format('DD-MM-YYYY, HH:mm');
    }

    yearsDeclinatedString(age: number): string {
        let yrs = age % 10; // last digit of age
        if (age == 1) return 'OrderDescriptionScreens.Current.Years1';
        if (yrs > 1 && yrs < 5) return 'OrderDescriptionScreens.Current.Years2to4';
        if (yrs === 0 || yrs === 1 || yrs > 4) return 'OrderDescriptionScreens.Current.Years5to9';
    }

    getNumberOfOrders(): void {
        if (this.navigationService.getCurrentContext() == 'MEDICAL_FACILITY') {
            this.odataService.postPrivate('count_mf_exam_orders',
            this.odataService.paramsTokenAndLang({
                id_medical_facility: this.userService.getFacilityId(),
                filtr: '{"status": [1]}',
                filtr_search: ''
            }),
                res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    this.setOrderString(result);
                }
            });
        }
    }

    getMfOrderDetails(id: number): Promise<boolean> {
      return this.odataService.postPrivate('get_mf_exam_order_info',
        this.odataService.paramsTokenAndLang({
          token: this.sessionService.getToken(),
          language: this.userService.iLanguage,
          id_medical_facility: this.userService.getFacilityId(),
          id_exam_order: id
        }),
        res => {
          let returnedJSON = JSON.stringify(res);
          let result = JSON.parse(returnedJSON);
          if (result.Status === 'OK' && result.Value != '{}') {
            this.orderDetails = JSON.parse(result.Value);
            return true;
          } else {
            console.log('FAILED');
            this.orderDetails = initFacilityOrderDetails;
            return false;
          }
        });
    }

    setOrderString(result) {
        let value: number = parseInt(result.Value);
        if (value > 99) {
            this.newOrdersString = '99+';
            this.width = '30px';
        } else {
            this.newOrdersString = value + '';
            this.width = '20px';
        }
    }

}
