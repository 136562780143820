<div id="top-bar" style="z-index: 40;">
  <div class="row">
    <div class="col-md-12">
      <h3 class="page-name">{{'DashboardScreen.Orders' | translate}}</h3>

      <ul id="top-bar-nav">
        <li id="All" [ngClass]="{'active': currentId == 'All'}">
          <a (click)="getOrders('All')"
             style="cursor: pointer;">{{'DashboardScreen.All' | translate}}</a>
        </li>
        <li id="Completed" [ngClass]="{'active': currentId == 'Completed'}">
          <a (click)="getOrders('Completed')"
             style="cursor: pointer;">{{'DashboardScreen.Completed' | translate}}</a>
        </li>
        <li id="NeedAttention" [ngClass]="{'active': currentId == 'NeedAttention'}">
          <a (click)="getOrders('NeedAttention')"
             style="cursor: pointer;">{{'DashboardScreen.NeedAttention' | translate}}</a>
        </li>
      </ul>

      <form>
        <ul class="top-bar-nav">
          <li>
            <div class="custom-control custom-checkbox mr-4" style="position: relative;">
              <input class="custom-control-input" id="onlyCitoAndEmergency" type="checkbox"
                    (change)="getOrders('')" [(ngModel)]="isOnlyCitoAndEmergency" [ngModelOptions]="{standalone: true}">
              <label class="custom-control-label" for="onlyCitoAndEmergency"
                     style="font-weight: normal;">{{'DashboardScreen.OnlyCitoAndEmergency' | translate}}</label>
            </div>
          </li>
        </ul>
        <app-search #search placeholder="SearchPlaceholder"></app-search>
      </form>

    </div>
  </div>
</div>

<app-universal-toast #toast></app-universal-toast>
<app-loader #loader></app-loader>
<app-empty-orders-list [mode]="'MF'" [page]="'dashboard'" [ordersCount]="facilityOrdersService.orders.length || 0" [isLoaderShow]="loader.show"
                       [isSearched]="search.isSearch" [isFiltered]="ordersFilter == emptyFilter"></app-empty-orders-list>


<table *ngIf="facilityOrdersService.orders.length" class="tile-table" id="tile-table">
  <thead>
    <tr>
        <th class="pb-3">{{'DashboardScreen.Type' | translate}}</th>
        <th class="pb-3" colspan="2">{{'DashboardScreen.Status' | translate}}</th>
        <th class="pb-3">{{'DashboardScreen.AssignedDate' | translate}}</th>
        <th class="pb-3">{{'DashboardScreen.Patient' | translate}}</th>
        <th class="pb-3">{{'DashboardScreen.AnatomyPart' | translate}}</th>
        <th class="pb-3">{{'DashboardScreen.OrderInfo' | translate}}</th>
    </tr>
  </thead>
  <tr class="tile" *ngFor="let or of facilityOrdersService.orders; let j = index;">

    <td class="pr-1"><span class="modality"
          [ngClass]="{'green':( or.status_info.status_name === 'TO_APPOVE_BY_TIME'
                              || or.status_info.status_name === 'APPROVED'),
                      'red': or.priority.priority === 'EMERGENCY',
                      'orange': or.priority.priority === 'CITO'}">
        {{or.modality.modality || 'modality'}}
      </span>
    </td>
    <td>
      <span class="status-icon mr-1">

        <svg-icon *ngIf="or.status_info.status_name == 'NEW'
                                || or.status_info.status_name == 'TRANSFER_IN_PROGRES'
                                || or.status_info.status_name == 'TRANSFER_COMPLETED'"
          src="../../../../assets/images/icons/icon__exam_order_send.svg">
        </svg-icon>
        <svg-icon *ngIf="or.status_info.status_name == 'IN_DESCRIBING_CENTER'"
          src="../../../../assets/images/icons/icon__exam_order_assigned_to_physician.svg">
        </svg-icon>
        <svg-icon *ngIf="or.status_info.status_name == 'IN_DESCRIBING_CENTER_IN_PROGRESS'"
          src="../../../../assets/images/icons/icon__exam_order_reported.svg">
        </svg-icon>
        <svg-icon *ngIf="or.status_info.status_name == 'DESCRIBING_CENTER_DONE'"
          src="../../../../assets/images/icons/icon__exam_order_result.svg">
        </svg-icon>
        <svg-icon *ngIf="or.status_info.status_name == 'TRANSFER_FAILED'
                                || or.status_info.status_name == 'DESCRIBING_CENTER_REJECTED'
                                || or.status_info.status_name == 'CANCELED'"
          src="../../../../assets/images/icons/icon__exam_order_canceled.svg">
        </svg-icon>

        <svg-icon *ngIf="or.status_info.status_name === 'TO_APPOVE_BY_TIME'"
                  src="../../../../assets/images/icons/icon__order_send.svg"></svg-icon>
        <svg-icon *ngIf="or.status_info.status_name === 'REJECTED'"
                  src="../../../../assets/images/icons/icon__order_returned.svg"></svg-icon>
        <svg-icon *ngIf="or.status_info.status_name === 'APPROVED'"
                  src="../../../../assets/images/icons/icon__order_done.svg"></svg-icon>
        <svg-icon *ngIf="or.status_info.status_name === 'FAILED'"
                  src="../../../../assets/images/icons/icon__order_failed.svg"></svg-icon>
      </span>
    </td>
    <td>
      <strong class="red" *ngIf="or.priority.priority === 'EMERGENCY'">EMERGENCY<br></strong>
      <strong class="orange" *ngIf="or.priority.priority === 'CITO'">CITO<br></strong>
      <span>{{or.status_info.status}}</span>
    </td>
    <td>
      <strong>{{facilityOrdersService.dates[j] || 'date'}} </strong>
      <span class="light">({{facilityOrdersService.hours[j] || 'howlongago'}})</span><br/>
      <ng-container *ngIf="or.describing_facility_name">
        <ng-container *ngIf="or.describing_facility_name.length > 16">{{or.describing_facility_name | slice:0:16}}...</ng-container>
        <ng-container *ngIf="or.describing_facility_name.length <= 16">{{or.describing_facility_name}}</ng-container>
      </ng-container>
      <ng-container *ngIf="!or.describing_facility_name">
        <span class="light">Zbadani.pl</span>
      </ng-container>
    </td>
    <td>
      <ng-container *ngIf="or.describing_facility_name">
        <div class="tooltipContainer overTile" style="left: -20px; top: -30px;">
        <span class="tooltip">
          <strong>{{'DashboardScreen.OrderStatus' | translate}}</strong>: {{or.status_info.status}}<br>
          <p>
            <strong>{{'DashboardScreen.SendedTo' | translate}}</strong>: {{or.describing_facility_name}}<br>
            <strong>{{'DashboardScreen.OrderDate' | translate}}</strong>: {{facilityOrdersService.getDateString(or.exam_order_date)}}</p>
          <p>
            <strong> {{'DashboardScreen.ExamDate' | translate}}</strong>: {{facilityOrdersService.getDateString(or.exam_date)}}<br>
            {{or.order_exam_type}}<br>
            {{or.icd10}} - {{or.icd10_text}}<br>
            <strong>{{'DashboardScreen.Comments' | translate}}</strong>: {{or.exam_order_notes}}
          </p>
        </span>
        </div>
        <strong style="text-decoration: none;">{{or.patient_name || 'patientName'}}</strong><br/>
        <div class="copiedTooltip" style="display: inline-block;" [clipboard]="or.pesel" (copied)="copiedText = $event">
          {{or.pesel || 'pesel'}},
            <div class="tooltipContainer" style="top: -17px;">
              <span *ngIf="copiedText == or.pesel" class="tooltip copied">{{'Copied' | translate}}</span>
              <span *ngIf="copiedText != or.pesel" class="tooltip notCopied">{{'CopyText' | translate}}</span>
            </div>
        </div>{{or.birth_date || 'birthDate'}}
        <span class="light">({{facilityOrdersService.age[j] || 'age'}} {{facilityOrdersService.yearsDeclinatedString(facilityOrdersService.age[j]) | translate}})</span>
      </ng-container>
      <ng-container *ngIf="!or.describing_facility_name">
        <strong>{{facilityOrdersService.patientsInitials(or.patient_name)}}</strong><br/>
        •••••••••••, {{or.birth_date || 'birthDate'}}
        <span class="light">({{facilityOrdersService.age[j] || 'age'}} {{facilityOrdersService.yearsDeclinatedString(facilityOrdersService.age[j]) | translate}})</span>
      </ng-container>
    </td>
    <td>
      <strong>{{or.anatomy.part_name || 'examType'}}</strong><br/>
      <span>{{or.order_exam_type}}</span>
    </td>
    <td>
      <div class="copiedTooltip" style="display: inline-block" [clipboard]="or.his_exam_id" (copied)="copiedText = $event">
        <strong>HIS ID </strong>{{or.his_exam_id}}
        <div class="tooltipContainer" style="top: -17px;">
          <span *ngIf="copiedText == or.his_exam_id" class="tooltip copied">{{'Copied' | translate}}</span>
          <span *ngIf="copiedText != or.his_exam_id" class="tooltip notCopied">{{'CopyText' | translate}}</span>
        </div>
      </div>
      <br>
      <div class="copiedTooltip" style="display: inline-block" [clipboard]="or.pm_accession_number" (copied)="copiedText = $event">
        <strong>{{'DashboardScreen.Accn' | translate}} </strong> {{or.pm_accession_number}}
        <div class="tooltipContainer" style="top: -17px;">
          <span *ngIf="copiedText == or.pm_accession_number" class="tooltip copied">{{'Copied' | translate}}</span>
          <span *ngIf="copiedText != or.pm_accession_number" class="tooltip notCopied">{{'CopyText' | translate}}</span>
        </div>
      </div>
    </td>
    <td class="actions">
            <span *ngFor="let b of or.action_list; let i = index;">
                <a *ngIf="i < 2" class="btn btn-light btn-sm" style="padding: 10px;" (click)="makeAction(j, b.action_name, or.id_exam_order)">{{b.button_name}}</a>
            </span>
      <button *ngIf="or.action_list.length > 2" type="button" class="btn btn-light card-action"
              id="ddAction1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1"
           *ngIf="or.action_list.length > 2">
        <ng-container *ngFor="let b of or.action_list; let i = index;">
          <a class="dropdown-item" *ngIf="i >= 2"
             style="cursor: pointer;">{{b.button_name}}</a>
        </ng-container>
      </div>
    </td>
  </tr>
</table>

<app-pagination #pagination componentName="MedicalFacilityDashboard" [hidden]="!facilityOrdersService.orders.length"></app-pagination>
<app-order-info-modal #orderInfoModal></app-order-info-modal>
<app-desc-order-return-modal #returnDescOrder></app-desc-order-return-modal>
