import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var SessionService = /** @class */ (function () {
    function SessionService(translateService) {
        this.translateService = translateService;
        this.emitter = new EventEmitter();
        this.setLanguage();
    }
    SessionService.prototype.restoreToken = function () {
        var localToken = sessionStorage.getItem('token');
        this.token = localToken;
    };
    SessionService.prototype.restoreSessionId = function () {
        var localSessionId = sessionStorage.getItem('session_id');
        this.session_id = localSessionId;
    };
    SessionService.prototype.restoreAllSessionInfo = function () {
        this.restoreToken();
        this.restoreSessionId();
    };
    SessionService.prototype.getToken = function () {
        if (this.token) {
            return this.token;
        }
        else {
            this.restoreToken();
            return this.token || null;
        }
    };
    SessionService.prototype.isLocked = function () {
        if (localStorage.getItem('lockedSession' + this.getSessionId()) == 'true')
            return true;
        return false;
    };
    SessionService.prototype.lockSession = function () {
        localStorage.setItem('lockedSession' + this.getSessionId(), 'true');
    };
    SessionService.prototype.unlockSession = function () {
        localStorage.setItem('lockedSession' + this.getSessionId(), 'false');
    };
    SessionService.prototype.tokenExist = function () {
        return !!sessionStorage.getItem('token');
    };
    SessionService.prototype.getSessionId = function () {
        if (this.session_id) {
            return this.session_id;
        }
        else {
            this.restoreSessionId();
            return this.session_id || null;
        }
    };
    SessionService.prototype.setToken = function (token) {
        if (token) {
            sessionStorage.setItem('token', token);
            this.token = token;
        }
    };
    SessionService.prototype.clearToken = function () {
        this.token = null;
        sessionStorage.removeItem('token');
    };
    SessionService.prototype.setSessionId = function (sessionId) {
        sessionStorage.setItem('session_id', sessionId);
        this.session_id = sessionId;
    };
    SessionService.prototype.clearSessionId = function () {
        this.session_id = null;
        sessionStorage.removeItem('session_id');
    };
    SessionService.prototype.clearAllSessionInfo = function () {
        localStorage.removeItem('lockedSession' + this.getSessionId());
        this.clearToken();
        this.clearSessionId();
        sessionStorage.clear();
    };
    SessionService.prototype.setNewSessionId = function () {
        var session_id = '_' + Math.random().toString(36).substr(2, 9);
        this.setSessionId(session_id);
    };
    SessionService.prototype.startNewSession = function (token) {
        this.setToken(token);
        localStorage.removeItem('currentCenter');
        var locks = [];
        for (var key in localStorage) {
            if (key.includes('lockedSession')) {
                locks.push(key);
            }
        }
        locks.forEach(function (e) { return localStorage.removeItem(e); });
    };
    SessionService.prototype.changeLang = function () {
        if (this.language == 'pl') {
            this.language = 'en';
        }
        else if (this.language == 'en') {
            this.language = 'pl';
        }
        this.translateService.use(this.language);
        document.cookie = 'lang=' + this.language + ';max-age=' + 7 * 24 * 60 * 60 + ';';
        this.emitter.emit(this.language);
    };
    SessionService.prototype.setLanguage = function () {
        var langCookie = document.cookie.split(';').filter(function (cookie) { return cookie.includes('lang'); });
        this.language = langCookie.length ? langCookie[0].split('=')[1] : 'pl';
    };
    SessionService.prototype.closeSession = function (odataService) {
        odataService.postPrivate('close_session', { token: this.getToken() }, function (res) { });
    };
    SessionService.prototype.setCurrentPage = function (componentName, page) {
        localStorage.setItem('currentPageFor' + componentName, page.toString());
    };
    SessionService.prototype.getCurrentPage = function (componentName) {
        var currentPage = Number(localStorage.getItem('currentPageFor' + componentName));
        if (currentPage <= 0) {
            currentPage = 1;
            this.setCurrentPage(componentName, currentPage);
        }
        return currentPage;
    };
    SessionService.prototype.removeCurrentPage = function (componentName) {
        localStorage.removeItem('currentPageFor' + componentName);
    };
    SessionService.prototype.setItemsOnPage = function (componentName, numberOfItems) {
        localStorage.setItem('itemsOnPageFor' + componentName, numberOfItems);
    };
    SessionService.prototype.getItemsOnPage = function (componentName) {
        var itemsOnPage = Number(localStorage.getItem('itemsOnPageFor' + componentName));
        if (itemsOnPage < 10) {
            itemsOnPage = 10;
            this.setItemsOnPage(componentName, String(itemsOnPage));
        }
        else if (itemsOnPage > 100) {
            itemsOnPage = 100;
            this.setItemsOnPage(componentName, String(itemsOnPage));
        }
        return String(itemsOnPage);
    };
    SessionService.prototype.removeItemsOnPage = function (componentName) {
        localStorage.removeItem('itemsOnPageFor' + componentName);
    };
    SessionService.prototype.sendMessage = function (key, value) {
        localStorage.setItem(key, value);
        localStorage.removeItem(key);
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.inject(i1.TranslateService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
