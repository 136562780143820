import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PageService {

    constructor(private titleService: Title, private translate: TranslateService) { }

    setTranslatedPageTitle(paramName: string, alertsNumber: number) {
        this.getTranslation(paramName).then(res => {
            if (res != '') {
                this.setPageTitle(res, alertsNumber);
            } else {
                this.resetPageTitle();
            }
        });
    }

    async setPageTitle(title: string, alertsNumber: number) {
        let alertsString = '';
        if (alertsNumber > 0) {
            alertsString = ' (' + alertsNumber + ')';
        }
        this.titleService.setTitle(title + alertsString + ' | ' + await this.getDefaultPageTitle());
    }

    setSettingPageTranslatedTitle(paramName: string, alertsNumber: number) {
        let translations: Promise<string>[] = [this.getTranslation('DashboardScreen.Settings'), this.getTranslation(paramName)];
        Promise.all(translations).then(res => {
            this.setPageTitle(res[0] + ': ' + res[1], alertsNumber);
        });
    }

    setPatientsPortalPageTitle(alertsNumber: number) {
        this.setPageTitle('Portal Pacjenta', alertsNumber);
    }

    setLogInPageTitle() {
        this.setTranslatedPageTitle('LoginFirstScreen.PageTitle', 0);
    }

    setSignUpPageTitle() {
        this.setTranslatedPageTitle('RegistrationFirstScreen.PageTitle', 0);
    }

    resetPageTitle() {
        this.getDefaultPageTitle().then(res => {
            this.titleService.setTitle(res);
        });
    }

    getDefaultPageTitle() {
        return this.getTranslation('DefaultPageTitle');
    }

    getTranslation(paramName: string): Promise<string> {
        const returnPromise: Promise<string> = new Promise((resolve, reject) => {
            this.translate.get(paramName).subscribe((res: string) => {
                resolve(res);
            });
        });
        return returnPromise;
    }
}
