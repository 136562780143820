import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router, private _location: Location) {
    }

    setContexts(userContexts: any): void {
        userContexts.forEach(element => {
            sessionStorage.setItem(element.context_name, element.id_context);
        });
        this.setCurrentContext();
    }

    navigateToDefault(): void {
        if (sessionStorage.getItem('currentContext') == 'MEDICAL_FACILITY') {
            this.router.navigate(['/medicalFacilityExams']);
        } else if (sessionStorage.getItem('currentContext') == 'DESCRIBING_CENTER') {
            this.router.navigate(['/dashboard']);
        } else if (sessionStorage.getItem('currentContext') == 'PATIENT' || sessionStorage.getItem('currentContext') == 'PHYSICIAN') {
            localStorage.setItem('darkMode', 'false');
            this.router.navigate(['/portal-pacjenta']);
        } else {
            this.router.navigate(['/accountProperties']);
        }
    }

    setCurrentContext(contextName?: string) {
        if (contextName) {
            sessionStorage.setItem('currentContext', contextName);
        } else {
            if (this.getCurrentContext() == 'PATIENT' && this.hasContext('PHYSICIAN')) {
                sessionStorage.setItem('currentContext', 'PHYSICIAN');
            } else if (!sessionStorage.getItem('currentContext')) {
                if (sessionStorage.getItem('DESCRIBING_CENTER') != null) {
                    sessionStorage.setItem('currentContext', 'DESCRIBING_CENTER');
                } else if (sessionStorage.getItem('MEDICAL_FACILITY') != null) {
                    sessionStorage.setItem('currentContext', 'MEDICAL_FACILITY');
                } else if (sessionStorage.getItem('PHYSICIAN') != null) {
                    sessionStorage.setItem('currentContext', 'PHYSICIAN');
                } else if (sessionStorage.getItem('PATIENT') != null) {
                    sessionStorage.setItem('currentContext', 'PATIENT');
                } else {
                    sessionStorage.setItem('currentContext', '');
                }
            }
        }
    }

    hasContext(context: string): boolean {
        return sessionStorage.getItem(context) != null;
    }

    getCurrentContext(): string {
        return sessionStorage.getItem('currentContext');
    }

    navigateBack(): void {
        this._location.back();
    }
}
