<!-- start: sendApplicationModal -->
<div class="modal fade" id="sendApplicationModal" tabindex="-1" role="dialog"
    aria-labelledby="sendApplicationModalLabel" aria-hidden="true" data-dismiss="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-4" id="sendApplicationModalLabel">{{'Profile.COProfile.ApplicationTitle' | translate}}<br>{{centerProifileName}}</h2>
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p style="color: red;" *ngIf="errorApplication != ''">{{errorApplication}}</p>
                <textarea name="application_message_textarea" id="application_message_textarea"
                    class="form-control mt-2 mb-4"
                    placeholder="{{'DashboardScreen.ExtendedMessagePlaceholder' | translate}}"
                    rows="5"
                    [(ngModel)]="applicationText"></textarea>
            </div>
            <div class="modal-footer">
                <button (click)="onApplicationSend()" type="button"
                    class="btn btn-lg btn-success">{{'Profile.COProfile.ApplicationButton' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- end: sendApplicationModal -->
