import { IColumns } from '@/utils/datatable/Interfaces';

export const CO_STAFF_COLUMNS: IColumns[] = [
  {
    title: 'Datatable.COStaff.Username',
    data: 'user_info.user_info.username',
    defaultContent: ''
  },
  {
    title: 'Datatable.COStaff.UserId',
    data: 'idEmployee',
    defaultContent: ''
  },
  {
    title: 'Datatable.COStaff.Email',
    data: 'user_info.user_info.email',
    defaultContent: ''
  },
  {
    title: 'Datatable.COStaff.Roles',
    data: 'user_info.roleString',
    defaultContent: ''
  }
];

export const CO_TRANSFER_COLUMNS: IColumns[] = [
  {
    title: 'Datatable.COTransfers.Name',
    data: 'examInfo.patient_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.Type',
    data: 'examInfo.order_exam_type',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.Acc',
    data: 'examInfo.pm_accession_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.TransferStarted',
    data: 'transferStarted',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.TransferDate',
    data: 'transferStartTime',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.TransferProgress',
    data: 'transferProgress',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.TransferFinished',
    data: 'transferFinished',
    defaultContent: ''
  },
  {
    title: 'Datatable.COTransfers.TransferSucceeded',
    data: 'transferSucceeded',
    defaultContent: ''
  }

];

export let BALANCESCOLUMNS: IColumns[] = [
  {
    title: '#',
    data: 'index',
    defaultContent: ''
  },
  {
    title: 'Datatable.Balance.Date',
    data: 'date',
    defaultContent: ''
  },
  {
    title: 'Datatable.Balance.Amount',
    data: 'sum',
    defaultContent: ''
  }
];

export let INVOICESCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.Invoices.InvoiceId',
    data: 'id',
    defaultContent: ''
  },
  {
    title: 'Datatable.Invoices.DateOfIssue',
    data: 'dateOfIssue',
    defaultContent: ''
  },
  {
    title: 'Datatable.Invoices.DateOfSale',
    data: 'dateOfSale',
    defaultContent: ''
  },
  {
    title: 'Datatable.Invoices.Netto',
    data: 'netto',
    defaultContent: ''
  },
  {
    title: 'Datatable.Invoices.Brutto',
    data: 'brutto',
    defaultContent: ''
  },
  {
    title: 'Datatable.Invoices.Download',
    defaultContent: `<a class="btn btn-sm btn-white" href="/siteUnderConstruction">Faktura</a>&nbsp;<a class="btn btn-sm btn-white" href="/siteUnderConstruction">Billing</a>&nbsp;`
  }
];

export let HISTORYCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.History.Date',
    data: 'date',
    defaultContent: ''
  },
  {
    title: 'Datatable.History.Physician',
    data: 'physician',
    defaultContent: ''
  },
  {
    title: 'Datatable.History.Modality',
    data: 'modality',
    defaultContent: ''
  },
  {
    title: 'Datatable.History.Principal',
    data: 'principal',
    defaultContent: ''
  },
  {
    title: 'Datatable.History.Price',
    data: 'sum',
    defaultContent: ''
  },
  {
    title: 'Datatable.History.Actions',
    defaultContent: '<a class="btn btn-sm btn-white" href="/siteUnderConstruction">Przejdź do zlecenia</a>'
  }
];

export let FACILITIESCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.Facilities.FacilityId',
    data: 'id_medical_facility',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.Name',
    data: 'name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Country',
    data: 'address_country',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.City',
    data: 'address_city',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Street',
    data: 'address_street',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.LocalNumber',
    data: 'address_local',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.ZipCode',
    data: 'address_zip_code',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Nip',
    data: 'nip',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Krs',
    data: 'krs',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Regon',
    data: 'regon',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.AccountConfirmed',
    data: 'account_confirmed',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.AccountActive',
    data: 'active',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.CreationTime',
    data: 'creation_time',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.UpdateTime',
    data: 'update_time',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.AccountDeleted',
    data: 'medical_facility_deleted',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.Datahub',
    data: 'datahub_configuration',
    defaultContent: ''
  }
];

export let USERSCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.Users.Id',
    data: 'id_user',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.Name',
    data: 'first_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.Surname',
    data: 'last_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.Login',
    data: 'login_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.DocumentType',
    data: 'id_document_type',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.Document',
    data: 'id_value',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.AccountActive',
    data: 'active',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.Email',
    data: 'email',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.Citizenship',
    data: 'citizenship',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.PhoneNumber',
    data: 'phone_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.BirthDate',
    data: 'birthday',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.AccountVerified',
    data: 'account_verified',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.AccountPermanentlyBlocked',
    data: 'account_blocked_permamently',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.IncorrectLoginAttempts',
    data: 'login_failed_attemps',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.DateOfAccountBlocade',
    data: 'account_blocked_timestamp',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.PhoneNumberVerified',
    data: 'phone_number_verified',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.EmailVerified',
    data: 'email_verified',
    defaultContent: ''
  },
  {
    title: 'Datatable.Users.AccountDeleted',
    data: 'account_deleted',
    defaultContent: ''
  }
];

export let ORDERSCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.Orders.OrderId',
    data: 'id_exam_order',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.Priority',
    data: 'json_info.exam_info.priority.priority',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.MedicalFacility',
    data: 'json_info.exam_info.ordering_facility_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.AdditionalNote',
    data: 'json_info.exam_info.exam_order_notes',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.DateOfOrder',
    data: 'json_info.order_date',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.Status',
    data: 'json_info.status_info.status',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.Patient',
    data: 'json_info.exam_info.patient_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.PatientBirthDate',
    data: 'json_info.exam_info.birth_date',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.PatientPesel',
    data: 'json_info.exam_info.pesel',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.PatientAge',
    data: 'json_info.exam_info.patient_age',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.Assigned',
    data: 'json_info.assign_physician_info.physician_assign',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.AssignedId',
    data: 'json_info.assign_physician_info.id_physician_assign',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.HisId',
    data: 'json_info.exam_info.his_patient_id',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.HisExam',
    data: 'json_info.exam_info.his_exam_id',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.AnatomyPart',
    data: 'json_info.exam_info.anatomy.part_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.TypeOfExam',
    data: 'json_info.exam_info.order_exam_type',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.Modality',
    data: 'json_info.exam_info.modality.modality',
    defaultContent: ''
  }
];

export let CODETAILS: IColumns[] = [
  {
    title: 'Datatable.Centers.Id',
    data: 'id_describing_center',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Name',
    data: 'name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Nip',
    data: 'nip',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Krs',
    data: 'krs',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Regon',
    data: 'regon',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.FirmType',
    data: 'busines_type',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.CreatorId',
    data: 'creator_id_user',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Street',
    data: 'address_street',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.LocalNumber',
    data: 'address_local',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.ZipCode',
    data: 'address_zip_code',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.City',
    data: 'address_city',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Country',
    data: 'address_country',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.PhoneNumber',
    data: 'phone_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.AccountConfirmed',
    data: 'account_confirmed',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.AccountActive',
    data: 'active',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Statistics',
    data: 'profile_statistics',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.WorkingHours',
    data: 'profile_work_hours',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Contact',
    data: 'profile_contact_info',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.AboutDC',
    data: 'profile_about_dc',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Specializations',
    data: 'profile_specializations',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.PriceList',
    data: 'profile_price_list',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Created',
    data: 'creation_time',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.LastUpdate',
    data: 'update_time',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Email',
    data: 'email',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.AccountDeleted',
    data: 'deleted',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.FirmTypeId',
    data: 'id_business_type',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Agreements',
    data: 'id_term',
    defaultContent: ''
  }
];

export let COCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.Centers.Id',
    data: 'id_describing_center',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Name',
    data: 'name',
    defaultContent: ''
  },
  /*{
      title: 'Datatable.Centers.Nip',
      data: 'nip',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.Krs',
      data: 'krs',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.Regon',
      data: 'regon',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.FirmType',
      data: 'busines_type',
      defaultContent: ''
  },*/
  {
    title: 'Datatable.Centers.CreatorId',
    data: 'creator_id_user',
    defaultContent: ''
  },
  /*{
      title: 'Datatable.Centers.Street',
      data: 'address_street',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.LocalNumber',
      data: 'address_local',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.ZipCode',
      data: 'address_zip_code',
      defaultContent: ''
  },*/
  {
    title: 'Datatable.Centers.City',
    data: 'address_city',
    defaultContent: ''
  },
  /*{
      title: 'Datatable.Centers.Country',
      data: 'address_country',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.PhoneNumber',
      data: 'phone_number',
      defaultContent: ''
  },*/
  {
    title: 'Datatable.Centers.AccountConfirmed',
    data: 'account_confirmed',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.AccountActive',
    data: 'active',
    defaultContent: ''
  },
  /*{
      title: 'Datatable.Centers.Statistics',
      data: 'profile_statistics',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.WorkingHours',
      data: 'profile_work_hours',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.Contact',
      data: 'profile_contact_info',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.AboutDC',
      data: 'profile_about_dc',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.Specializations',
      data: 'profile_specializations',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.PriceList',
      data: 'profile_price_list',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.Created',
      data: 'creation_time',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.LastUpdate',
      data: 'update_time',
      defaultContent: ''
  },*/
  {
    title: 'Datatable.Centers.Email',
    data: 'email',
    defaultContent: ''
  },
  /*{
      title: 'Datatable.Centers.AccountDeleted',
      data: 'deleted',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.FirmTypeId',
      data: 'id_business_type',
      defaultContent: ''
  },
  {
      title: 'Datatable.Centers.Agreements',
      data: 'id_term',
      defaultContent: ''
  }*/
];

export let EXAMSCOLUMNS: IColumns[] = [
  {
    title: 'Datatable.Exams.IdExam',
    data: 'id_exam',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.IdPatient',
    data: 'id_patient',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.Name',
    data: 'first_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.SecondName',
    data: 'second_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.LastName',
    data: 'last_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.PatientPesel',
    data: 'pesel',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.Sex',
    data: 'sex',
    defaultContent: ''
  },
  {
    title: 'Datatable.',
    data: 'birth_date',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.DateOfOrder',
    data: 'order_date',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.EndDate',
    data: 'order_end_date',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.OrderIdentifier',
    data: 'order_exam_identifier',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.Modality',
    data: 'order_exam_modality',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.AccessionNumber',
    data: 'exam_accession_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.StudyInstance',
    data: 'study_instance_uid',
    defaultContent: ''
  },
  {
    title: 'Datatable.Orders.HisExam',
    data: 'his_exam_id',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.HisPatient',
    data: 'his_patient_id',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.ExternalId',
    data: 'external_id_exam',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.EvoAccession',
    data: 'evo_accession_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.Facilities.FacilityId',
    data: 'id_medical_facility',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.OrderingFacility',
    data: 'ordering_facility_name',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.City',
    data: 'address_city',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.Street',
    data: 'address_street',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.StreetNumber',
    data: 'address_street_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.Exams.AptNumber',
    data: 'address_apt_number',
    defaultContent: ''
  },
  {
    title: 'Datatable.Centers.ZipCode',
    data: 'address_zip_code',
    defaultContent: ''
  }
];
