import { PageService } from '../../../../services/page.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-dcprofiles',
  templateUrl: './dcprofiles.component.html',
  styleUrls: ['./dcprofiles.component.scss']
})
export class DCProfilesComponent implements OnInit, OnDestroy {

  constructor(private pageService: PageService) { }

  ngOnInit() {
    this.pageService.setPatientsPortalPageTitle(0);
    document.getElementById('getAccessForDcLink').setAttribute('class', 'active');
  }

  ngOnDestroy() {
    this.pageService.resetPageTitle();
    document.getElementById('getAccessForDcLink').setAttribute('class', '');
  }
 }
