<ng-template #customItemTemplate let-index="index" let-model="item" let-query="query">
  <div class="tooltip-srac">
    <img [src]="model['profile_image']" class="image-srac">
    <div [caseSensitive]="false" [content]="model.username" [searchTerm]="query" class="d-item-srac" ngxTextHighlight>
      {{model.username}}
    </div>
    <span class="tooltiptext-srac">
            <strong>{{'SettingsScreens.UsersSettings.Login' | translate}}</strong>{{model.loginname}}
      <strong>{{'SettingsScreens.UsersSettings.PwzNr' | translate}}</strong>{{model.pwz}}
        </span>
  </div>
</ng-template>

<div id="top-bar">
  <div class="row">

    <div class="col-md-12">

      <h3 class="page-name">{{'SettingsScreens.Title' | translate}}</h3>
      <ul id="top-bar-nav">
        <li class="active" id="SettingsScreensUsers">
          <a routerLink="/medicalFacilityUsersSettings" style="cursor: pointer;">{{'SettingsScreens.Users' | translate}}</a>
        </li>
        <li id="SettingsScreensTeams">
          <a routerLink="/medicalFacilityTeamSettings" style="cursor: pointer;">{{'SettingsScreens.Teams' | translate}}</a>
        </li>
        <li id="SettingsScreensPermissions">
          <a routerLink="/medicalFacilityPermissionSettings" style="cursor: pointer;">{{'SettingsScreens.Permissions' | translate}}</a>
        </li>
        <li id="SettingsScreensRoles">
          <a routerLink="/medicalFacilityRoleSettings" style="cursor: pointer;">{{'SettingsScreens.Roles' | translate}}</a>
        </li>
      </ul>

      <form>
        <app-search #search placeholder="SettingsScreens.SearchPlaceholder"></app-search>
      </form>
    </div>

  </div>
</div>

<div class="container-settings">
  <div class="row">
    <div class="col-md-12">

      <div class="card users-card">
        <div class="d-flex my-auto">
          <div style="min-width: 640px" class="mr-3">

            <form>
              <div class="form-row">
                <div class="col form-group" style="margin-bottom: 0; width: 100%;">
                  <input class="typeahead-custom" [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="asyncSelected" [typeaheadAsync]="true" [typeahead]="dataSource"
                         (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                         [typeaheadOptionsLimit]="10" typeaheadOptionField="username"
                         placeholder="{{'SettingsScreens.UsersSettings.SearchForPlaceholder' | translate}}" adaptivePosition="true"
                         [typeaheadMinLength]="3" [typeaheadItemTemplate]="customItemTemplate" [typeaheadWaitMs]="100"
                         (input)="onValueChange()"
                         [ngClass]="{'form-control': true, 'type_text': true, 'invalid': !valid_search}">
                  <!-- <img [src]="typeaheadIcon" class="select-async-img"> -->
                  <div *ngIf="typeaheadLoading">{{'DashboardScreen.Loading' | translate}}</div>
                </div>
              </div>
            </form>

          </div>
          <div>
            <button (click)="onCooperationInvite ()"
                    [disabled]="search_value == undefined"
                    class="btn btn-lg btn-success float-right"
                    style="margin-right: 0;">{{'SettingsScreens.UsersSettings.CooperationInvite' | translate}}</button>
          </div>

        </div>
      </div>

    </div>

  </div>

  <div class="row mt-4">
    <div *ngFor="let item of settings_users" class="col-md-6">
      <div class="card users-card" style="padding: 25px;">
        <div class="row d-flex">
          <div>
            <img src="{{item.profile_image}}"
                 style="width: 120px !important; height: 120px !important; margin: 0 25px;" alt="">
          </div>
          <div class="my-auto">
                        <span [ngClass]="{'badge': true,
                            'badge-custom': true,
                            'badge-warning': item.status_name == 'WAITING',
                            'badge-success': item.status_name == 'ACTIVE',
                            'badge-danger': item.status_name == 'BLOCKED',
                            'badge-applies': item.status_name == 'APPLYING',
                            'badge-removed': item.status_name == 'DELETED'}">
                            {{item.status_text}}</span>
            <p class="name">{{item.username}}</p>
            <p>
              <strong>{{'SettingsScreens.UsersSettings.Login' | translate}}</strong>{{item.loginname}}<br />
              <strong>{{'SettingsScreens.UsersSettings.PwzNr' | translate}}</strong>{{item.pwz}}
            </p>
          </div>

          <div class="col-md-2 d-flex ml-auto my-auto justify-content-center">
            <button type="button" class="btn btn-light card-action" id="ddAction3"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction3">
              <a *ngIf="item.status_name == 'ACTIVE' || item.status_name == 'WAITING'"
                 (click)="onEditRole (item)"
                 class="dropdown-item">{{'SettingsScreens.UsersSettings.EditRoleButton' | translate}}</a>
              <a *ngIf="item.status_name == 'ACTIVE'"
                 (click)="onBlock (item)"
                 class="dropdown-item danger">{{'SettingsScreens.UsersSettings.Block' | translate}}</a>
              <a *ngIf="item.status_name == 'BLOCKED'"
                 (click)="onUnBlock (item)"
                 class="dropdown-item danger">{{'SettingsScreens.UsersSettings.UnBlock' | translate}}</a>
              <a *ngIf="item.status_name == 'ACTIVE' || item.status_name == 'BLOCKED' || item.status_name == 'APPLYING'"
                 (click)="onDelete (item)"
                 class="dropdown-item danger">{{'SettingsScreens.Delete' | translate}}</a>
              <a *ngIf="item.status_name == 'DELETED'"
                 (click)="onInviteAgain (item)"
                 class="dropdown-item">{{'SettingsScreens.UsersSettings.InviteAgain' | translate}}</a>
              <a *ngIf="item.status_name == 'WAITING'"
                 (click)="onCancelInvitation (item)"
                 class="dropdown-item">{{'SettingsScreens.UsersSettings.CancelInvitation' | translate}}</a>
              <a *ngIf="item.status_name == 'APPLYING'"
                 (click)="onAcceptApplication (item)"
                 class="dropdown-item">{{'SettingsScreens.UsersSettings.AcceptApplication' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-double-select-modal (select)="onSelect($event)" #editRole
                         title="{{'SettingsScreens.UsersSettings.EditRole' | translate}}"
                         left_title="{{'SettingsScreens.UsersSettings.AvailableRole' | translate}}"
                         right_title="{{'SettingsScreens.UsersSettings.AssignedRole' | translate}}"></app-double-select-modal>

<!-- start: wyslanieZaproszeniaDoWspolpracyModal -->
<div class="modal fade" id="wyslanieZaproszeniaDoWspolpracyModal" tabindex="-1" role="dialog"
     aria-labelledby="wyslanieZaproszeniaDoWspolpracyModalLabel" aria-hidden="true" data-dismiss="modal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="wyslanieZaproszeniaDoWspolpracyModalLabel">{{'SettingsScreens.UsersSettings.SendInvitationQuestion' | translate}}</h2>
        <button (click)="onCloseInvitation ()" type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p>{{'SettingsScreens.UsersSettings.InvitationRecipient' | translate}} <a><u>{{user_name_to_invite}}</u></a></p>
        <textarea name="invitation_message_textarea" id="invitation_message_textarea"
                  class="form-control mt-2 mb-4"
                  placeholder="{{'SettingsScreens.UsersSettings.MessageContent' | translate}}"
                  rows="5"></textarea>

      </div>
      <div class="modal-footer">
        <button (click)="onInvitationSend ()" type="button"
                class="btn btn-lg btn-success">{{'SettingsScreens.UsersSettings.InviteButton' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<!-- end: wyslanieZaproszeniaDoWspolpracyModal -->
