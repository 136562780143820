import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CookiesService {

    constructor() { }

    getCookie(cname: string) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    createSessionCookie(): void {
        let date = new Date();
        const minutes = 15;
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        document.cookie = 'sessionControl=true; expires=' + date + '; path=/';
    }
}
