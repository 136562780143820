<div class="d-flex" id="threads">
    <div id="threads__nav" class="card" style="width: 480px; min-width: 480px; margin-right: 50px;">
        <h3 class="page-name">{{'Messages.Messages' | translate}} ({{messagesService.messagesCount}})</h3>
        <ul class="threads__nav--buttons">
            <a (click)="onMarkAllAsRead ()" class="btn btn-grey"  [ngClass]="{'float-left' : !is_new_mes_btn}" >{{'Messages.MarkAllAsRead' | translate}}</a>
            <a *ngIf="is_new_mes_btn" (click)="onNewMessage ()" class="btn btn-primary">{{'Messages.NewMessage' | translate}}</a>
        </ul>

        <cdk-virtual-scroll-viewport id="threads__messages" itemSize="100" (scrolledIndexChange)="nextBatch($event, senders)">
            <div *cdkVirtualFor="let mes of sortSendersBy('last_message_date'); trackBy: trackByFn"
                [ngClass]="{'threads_messages--item': true, 'new': mes.message_unread > 0, 'active': active_sender_id == mes.id_user}"
                id="section-{{ mes.id_user }}"
                (click)="onClickSenders(mes)">
                <div class="row" >
                    <div class="col-md-2 threads__messages--avatar">
                        <img alt="" class="avatar" [src]="mes['image_user_from_uuid']">
                        <span class="mesMessageAccountType">{{mes.message_unread}}</span>
                    </div>
                    <div class="col-md-10">
                        <p class="mesMessageFrom">{{mes.user_name}}</p>
                        <p class="mesMessageExcerpt">{{mes.message}}</p>
                        <p class="mesMessageLastDate">{{mes.last_message_time}}</p>
                    </div>
                </div>
            </div>
            <p *ngIf="theEnd" style="text-align: center; color: #666; padding: 35px 25px 25px 25px;">{{'Messages.ThisIsTheEnd'| translate}}</p>
        </cdk-virtual-scroll-viewport>

    </div>

    <div id="threads__comm" class="card flex-grow-1">
        <div class="threads__comm--header d-flex">
            <p *ngIf="currentInterlocutor != ''">{{'Messages.ConversationWith'| translate}}<strong>{{currentInterlocutor}}</strong></p>
            <p *ngIf="currentInterlocutor == ''">{{'Messages.SelectInterlocutor'| translate}}</p>
            <button *ngIf="currentInterlocutor != '' && is_current_interlocutor_physician" class="btn btn-grey ml-auto" (click)="viewProfile(active_sender_id)">{{'Messages.SeeProfile'| translate}}</button>
        </div>

        <cdk-virtual-scroll-viewport
            #messagesViewport
            id="threads__comm--content"
            itemSize="100"
            (scrolledIndexChange)="nextBatchConversation($event, senders)">
            <div *cdkVirtualFor="let message of messages_list; trackBy: trackByConversationFn"
                [ngClass]="{'bubble': true, 'bubble--interlocutor': active_sender_id == message.id_user_from, 'bubble--me': active_sender_id != message.id_user_from}"
                id="section_conversation-{{ message.id_message }}">
                <span style="white-space:pre-wrap;">{{message.message_content}}</span>
                <div *ngFor="let attachment of message.attachments">
                    <a class="attachment" target="_blank" href="{{ envService.attachmentFileUrl + attachment.attachment_uuid }}"
                        download="{{ attachment.attachment_name }}"> {{ attachment.attachment_name }} </a>
                </div>
                <div style="font-size: 10px;">
                    {{message.creation_time}}
                </div>
            </div>
            <!-- <span class="dateLast dateLast--me">09:46</span> -->
        </cdk-virtual-scroll-viewport>

        <form #meinMessageForm="ngForm" *ngIf="active_sender_id != undefined" [ngClass]="{'new-message-form': true}">
            <div id="threads__comm--userInput" style="padding-top: 25px" class="d-flex">
              <textarea
                cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="7"
                #autosize="cdkTextareaAutosize"
                name="message_text_main" id="message_text_main" #message_text_main
                ngModel placeholder="{{'Messages.EnterMessage'| translate}}">
              </textarea>
            </div>
            <div id="threads__comm--footer" class="d-flex">
                <ul class="threads__comm--userAttachments">
                    <li *ngFor="let attachment of messageSender.c_attachments; let idx = index;" >{{ attachment.name }}
                        <a class="action--remove" (click)="messageSender.onRemoveFile (idx, undefined, messageSender.c_attachments)">{{'Messages.Remove'| translate}}</a></li>
                </ul>

                <label class="ml-auto btn btn-grey" style="margin-right: 10px;" for="file_2_uploads">
                    <input (click)="fileInputClick($event)" type="file" id="file_2_uploads" name="file_2_uploads"
                        data-multiple-caption="{count} files selected" (change)="messageSender.onFileSelected($event, messageSender.c_attachments)"
                        style="visibility:hidden; width: 0;">
                    {{'Messages.Attachment' | translate}}
                </label>

                <button [disabled]="(!meinMessageForm.value.message_text_main && this.messageSender.c_attachments.length == 0 || message_text_main.value =='' || message_text_main.value == undefined)" (click)="onMessageSendClick(meinMessageForm)"
                        class="btn btn-success">
                  {{'Messages.Send' | translate}}</button>
            </div>
        </form>
    </div>
</div>

<!-- should be removed -->
<!-- start: wiadomosciSzczegolyModal -->
<div class="modal fade" id="wiadomosciSzczegolyModal" tabindex="-1" role="dialog"
     aria-labelledby="wiadomosciSzczegolyModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close"
                        (click)="onClose ()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <br/><br/>

                <form #messageForm="ngForm" [ngClass]="{'new-message-form': true}">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-11">
                            <app-select-recipient-async #id_user name="id_user" id="id_user" ngModel
                                                        [valid]="messageSender.validUser">
                            </app-select-recipient-async>
                            <div *ngIf="!messageSender.validUser">
                                <p class="warning">{{'Messages.SelectUser' | translate}}</p>
                            </div>
                            <br/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-1">
                            <img src="{{userService.miniProfileImageUrl}}" alt="" class="avatar rounded">
                        </div>
                        <div class="col-md-11">
                            <div class="form-group" style="padding-bottom: 0; margin-bottom: 10px;">
                            <textarea name="message_text" id="message_text" ngModel
                                    [ngClass]="{'form-control': true, 'invalid': !messageSender.validText}" rows="6" autofocus
                                    placeholder="{{'Messages.MessagePlaceholder' | translate}}">
                            </textarea>
                                <div *ngIf="!messageSender.validText">
                                    <p class="warning">{{'Messages.EnterMessage' | translate}}</p>
                                </div>
                                <br/>

                                <div *ngFor="let attachment of messageSender.attachments; let idx = index;">
                                    <label [ngClass]="{'valid_file': attachment.status == 'ok', 'invalid_file' : attachment.status == 'invalid', 'upload_file': attachment.status == 'upload' }">

                                        <svg class="inputfile_svg" width="20" height="24" aria-hidden="true"
                                             focusable="false" data-prefix="fas" data-icon="paperclip" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor"
                                                  d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z">
                                            </path>
                                        </svg>

                                        <span>{{ attachment.name }}</span>

                                        <div class="position-absolute-attached">
                                            <a class="message-remove-attached" data-placement="top"
                                               data-toggle="tooltip" (click)="messageSender.onRemoveFile (idx)"
                                               title="{{'Messages.Remove' | translate}}"></a>
                                        </div>
                                    </label>
                                </div>

                                <div *ngIf="messageSender.attachments?.length < 10" class="actions clearfix">
                                    <input (change)="messageSender.onFileSelected($event)"
                                           type="file" name="file-1[]" id="file-1" class="inputfile inputfile-1"
                                           data-multiple-caption="{count} files selected" multiple/>
                                    <label for="file-1">
                                        <svg class="inputfile_svg" width="20" height="24" viewBox="0 0 20 24"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.00024 23H3.00024C1.89624 23 1.00024 22.104 1.00024 21V3C1.00024 1.896 1.89624 1 3.00024 1H13.0002L19.0002 7V21C19.0002 22.104 18.1042 23 17.0002 23H15.0002"
                                                    stroke="#0848FF" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                            <path d="M10.0002 13L14.0002 17" stroke="#0848FF" stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                            <path d="M6.00024 17L10.0002 13" stroke="#0848FF" stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                            <path d="M10.0002 13L10.0002 23" stroke="#0848FF" stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                        </svg>
                                        <span>{{'Messages.AttachFile' | translate}}&hellip;</span>
                                    </label>
                                </div>

                                <div *ngIf="!messageSender.validAttachments">
                                    <p class="warning">{{'Messages.InvalidAttachments' | translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-lg btn-success"
                        (click)="onSubmit (messageForm)">{{'Messages.Send' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- end: wiadomosciSzczegolyModal -->
