<div id="top-bar" class="col col-md-12" style="z-index: 50;">
  <div class="row">
    <div class="col-md-12">
      <h3 class="page-name">{{'PatientsScreen.Title' | translate}}</h3>

      <form>
        <ul class="top-bar-nav">
          <li class="filter_dropdown" (click)="dropdown()" style="cursor: pointer;">
            <a>{{'DashboardScreen.Filters.FilterSort' | translate}}
              <svg-icon class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </a>
          </li>
        </ul>
        <app-search #search placeholder="PatientsScreen.SearchPatient"></app-search>
      </form>

    </div>
  </div>

</div>

<app-filters #filters (filter)="filterPatients($event)" type="PATIENTS"></app-filters>

<app-loader #loader></app-loader>

<table class="tile-table classic-table alt-table" id="dataTableExample">

  <thead>
  <tr>
    <th>{{'PatientsScreen.Surname' | translate}}
      <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'surname' && sortType == 'DESC'}"
            (click)="sortColumn = 'surname'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
      <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'surname' && sortType == 'ASC'}"
            (click)="sortColumn = 'surname'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
    </th>
    <th>{{'PatientsScreen.Name' | translate}}
      <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'name' && sortType == 'DESC'}"
            (click)="sortColumn = 'name'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
      <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'name' && sortType == 'ASC'}"
            (click)="sortColumn = 'name'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
    </th>
    <th>{{'PatientsScreen.Sex' | translate}}
      <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'sex' && sortType == 'DESC'}"
            (click)="sortColumn = 'sex'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
      <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'sex' && sortType == 'ASC'}"
            (click)="sortColumn = 'sex'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
    </th>
    <th>{{'PatientsScreen.Pesel' | translate}}
      <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'pesel' && sortType == 'DESC'}"
            (click)="sortColumn = 'pesel'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
      <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'pesel' && sortType == 'ASC'}"
            (click)="sortColumn = 'pesel'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
    </th>
    <th>{{'PatientsScreen.Age' | translate}}
      <span class="data-sort data-sort-desc" [ngClass]="{'active': sortColumn == 'age' && sortType == 'DESC'}"
            (click)="sortColumn = 'age'; sortType = 'DESC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
      <span class="data-sort data-sort-asc" [ngClass]="{'active': sortColumn == 'age' && sortType == 'ASC'}"
            (click)="sortColumn = 'age'; sortType = 'ASC'; filterPatients(patientsFilter);">
                <svg-icon class="svg" src="../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
            </span>
    </th>
    <th>&nbsp;</th>
  </tr>
  </thead>

  <tbody>
  <ng-container *ngFor="let patient of patients; let i = index">
    <tr class="tile">
      <td>{{patient.last_name}}</td>
      <td>{{patient.first_name}}</td>
      <td [ngSwitch]="patient.sex">
        <ng-container *ngSwitchCase="'M'">{{'PatientsScreen.Male' | translate}}</ng-container>
        <ng-container *ngSwitchCase="'F'">{{'PatientsScreen.Female' | translate}}</ng-container>
        <ng-container *ngSwitchDefault>{{'PatientsScreen.Unknow' | translate}}</ng-container>
      </td>
      <td>{{patient.pesel}}</td>
      <td>{{patient.age}} lata</td>
      <td class="actions">
        <a (click)="showOrders(i)" [ngClass]="{'disabled' : patient.patients_orders.length === 0, 'buttonShowOrdersActive': isShown[i]}"
           class="btn btn-light mr-3">{{'PatientsScreen.ShowOrders' | translate}}
          ({{patient.patients_orders.length}}
          )</a>
      </td>

    </tr>

    <tr *ngIf="isShown[i]" class="tile-children">
      <td colspan="6">
        <table class="tile-table patient-table">
          <tr class="tile" *ngFor="let or of patient.patients_orders; let j = index;">

            <td class="color-status" [ngClass]="{'red': or.exam_info.priority.priority === 'EMERGENCY',
                                             'orange': or.exam_info.priority.priority === 'CITO',
                                             'green': or.exam_info.priority.priority === 'TO_APPROVE_BY_TIME'
                                              || or.exam_info.priority.priority === 'APPROVED'}"></td>

            <td><span class="modality">{{or.exam_info.modality.modality || 'modality'}}</span></td>
            <td><span class="dot"
                      *ngIf="or.status_info.status=='Nowe'"></span>{{or.status_info.status || 'status'}}
            </td>
            <td>
              <strong>{{patient.dates[j] || 'date'}} </strong>
              <span class="light">({{patient.hours[j] || 'howlongago'}})</span><br/>
              {{or.exam_info.ordering_facility_name || 'faciityName'}}
            </td>
            <td>
              <strong>{{or.exam_info.patient_name || 'patientName'}}</strong><br/>
              {{or.exam_info.pesel || 'pesel'}}, {{or.exam_info.birth_date || 'birthDate'}}
              <span class="light">({{patient.age || 'age'}} lat)</span>
            </td>
            <td>
              <strong>{{or.exam_info.order_exam_type || 'examType'}}</strong><br/>
              <span>{{or.exam_info.pm_accession_number || 'accession_number'}}, {{or.exam_info.icd10 || 'icd10'}}</span>
            </td>
            <td class="right">
              <strong>{{or.assign_physician_info.physician_assign || 'nieprzypisane'}}</strong><br/>
              <span class="light"
                    *ngIf="userService.userId==or.assign_physician_info.id_physician_assign">({{'DashboardScreen.You' | translate}}
                )</span>
            </td>
            <td class="actions">
                                <span *ngFor="let b of or.actions_info; let i = index;" style="margin-right: 10px;">
                                    <a *ngIf="i < 2" class="btn btn-light"
                                       (click)="makeAction(b.action_name, or.id_exam_order, patient)">{{b.button_name}}</a>
                                </span>
              <button *ngIf="or.actions_info.length > 2" type="button"
                      style="margin-right: 10px; width: 31px;"
                      class="btn btn-light card-action" id="ddAction1" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1"
                   *ngIf="or.actions_info.length > 2">
                <ng-container *ngFor="let b of or.actions_info; let i = index;">
                  <a class="dropdown-item" *ngIf="i >= 2"
                     (click)="makeAction(b.action_name, or.id_exam_order, patient)"
                     style="cursor: pointer;">{{b.button_name}}</a>
                </ng-container>
              </div>
            </td>
          </tr>
        </table>
        <div class="row mt-1 mr-3" *ngIf="patient.patients_orders.length != 0">
          <div class="col-1">
            <select (change)="changeNumberOfOrdersPages(i)" [(ngModel)]="ordersOnPage"
                    [value]="ordersOnPage" class="custom-select">
              <option [value]="10" selected>10</option>
              <option [value]="25">25</option>
              <option [value]="50">50</option>
              <option [value]="100">100</option>
            </select>
          </div>
          <div class="col-7"></div>
          <div class="col-3">
            <nav aria-label="...">
              <ul class="pagination justify-content-end">
                <li *ngIf="activeOrderPage != 1" class="page-item">
                  <a class="page-link" (click)="changeOrdersPage(i, activeOrderPage-1)">{{'Pages.Previous' | translate}}</a>
                </li>
                <li *ngIf="activeOrderPage == 1" class="page-item disabled">
                  <a class="page-link">{{'Pages.Previous' | translate}}</a>
                </li>
                <li *ngFor="let page of orderPages[i]; let k = index" class="page-item"
                    [ngClass]="{'active': k==activeOrderPage-1}">
                  <div *ngIf="orderPages[k].length >= 5">
                    <a *ngIf="page == activeOrderPage || page == activeOrderPage-1 || page == activeOrderPage+1 || page == pages.length || page == 1"
                       class="page-link" (click)="changeOrdersPage(i, page)">{{page}}</a>
                    <a *ngIf="(page == activeOrderPage-2 || page == activeOrderPage+2) && page != 1 && page != orderPages[k].length"
                       class="page-link">...</a>
                  </div>
                  <div *ngIf="orderPages[k].length < 5">
                    <a class="page-link" (click)="changeOrdersPage(i, page)">{{page}}</a>
                  </div>
                </li>
                <li *ngIf="activeOrderPage != orderPages[i].length" class="page-item">
                  <a class="page-link" (click)="changeOrdersPage(i, activeOrderPage+1)">{{'Pages.Next' | translate}}</a>
                </li>
                <li *ngIf="activeOrderPage == orderPages[i].length" class="page-item disabled">
                  <a class="page-link">{{'Pages.Next' | translate}}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-1">
            <input [ngClass]="{'errorBorder': inputPageError}"
                   class="form-control text-center" id="inputPage" type="text"
                   placeholder="{{'Pages.Page' | translate}}"
                   [(ngModel)]="inputPage" (keydown.enter)="goToPageOrders(i, inputPage)">
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>

<div class="row mt-1" *ngIf="patients.length != 0">
  <div class="col-1">
    <select (change)="changeNumberOfPages()" [(ngModel)]="patientsOnPage" [value]="patientsOnPage"
            class="custom-select">
      <option [value]="10" selected>10</option>
      <option [value]="25">25</option>
      <option [value]="50">50</option>
      <option [value]="100">100</option>
    </select>
  </div>
  <div class="col-7"></div>
  <div class="col-3">
    <nav aria-label="...">
      <ul class="pagination justify-content-end">
        <li *ngIf="activePage != 1" class="page-item">
          <a class="page-link" (click)="changePage(activePage-1)">{{'Pages.Previous' | translate}}</a>
        </li>
        <li *ngIf="activePage == 1" class="page-item disabled">
          <a class="page-link">{{'Pages.Previous' | translate}}</a>
        </li>
        <li *ngFor="let page of pages; let i = index" class="page-item" [ngClass]="{'active': i==activePage-1}">
          <div *ngIf="pages.length >= 5">
            <a *ngIf="page == activePage || page == activePage-1 || page == activePage+1 || page == pages.length || page == 1" class="page-link"
               (click)="changePage(page)">{{page}}</a>
            <a *ngIf="(page == activePage-2 || page == activePage+2) && page != 1 && page != pages.length" class="page-link">...</a>
          </div>
          <div *ngIf="pages.length < 5">
            <a class="page-link" (click)="changePage(page)">{{page}}</a>
          </div>
        </li>
        <li *ngIf="activePage != pages.length" class="page-item">
          <a class="page-link" (click)="changePage(activePage+1)">{{'Pages.Next' | translate}}</a>
        </li>
        <li *ngIf="activePage == pages.length" class="page-item disabled">
          <a class="page-link">{{'Pages.Next' | translate}}</a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="col-1">
    <input [ngClass]="{'errorBorder': inputPagePatientsError}" class="form-control text-center"
           id="inputPage" type="text" placeholder="{{'Pages.Page' | translate}}"
           [(ngModel)]="inputPatientsPage" (keydown.enter)="goToPagePatients(inputPatientsPage)">
  </div>
</div>

<app-assign-physician-modal #assignPhysicianModal></app-assign-physician-modal>
<app-reject-modal #rejectModal></app-reject-modal>
