import { NavigationService } from './navigation.service';
import { EnvService } from '@/services/env.service';
import { EventEmitter, Injectable } from '@angular/core';
import { OdataService } from './odata.service';
import { SessionService } from './session.service';
import { UsersService } from './users.service';
import moment from 'moment';
import { IAction, IFiltrDashboard, IOrder, IOrdersRefreshStatus, initRefreshOrdersStatus } from '@/interfaces';
import { LoaderComponent } from '@/utils/loader/loader.component';
import { OrderDescriptionService } from './order-description.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {
    orders: IOrder[] = [];
    sendingExamTimeout;
    sendingExamId = 0;
    secondsLeft: number = 0;
    refreshOrdersStatus: IOrdersRefreshStatus = initRefreshOrdersStatus;
    isSendEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    ordersArchive: IOrder[] = [];
    age: any[] = [];
    ageArchive: any[] = [];
    dates = [];
    datesArchive = [];
    hours = [];
    hoursArchive = [];
    id: number = null;
    facilityId: number = null; // used for switching facilities
    promSearch: Promise<String>;
    numberOfOrders: EventEmitter<number> = new EventEmitter<number>();
    numberOfArchiveOrders: EventEmitter<number> = new EventEmitter<number>();
    dashboardLoader: LoaderComponent = null;
    archiveLoader: LoaderComponent = null;
    examDescriptionUuid: string = '';

    constructor(private odataService: OdataService, private sessionService: SessionService,
        private userService: UsersService, private descriptionService: OrderDescriptionService,
        private http: HttpClient, private env: EnvService, private navigationService: NavigationService) {
        this.numberOfOrders.emit(0);
        this.numberOfArchiveOrders.emit(0);
    }

    getCoExamOrders(filter: IFiltrDashboard, offset: number, limit: number, filtrSearch?: string): Promise<String> {
        if (this.dashboardLoader)
            this.dashboardLoader.show = true;
        this.orders = [];

        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: this.id,
            filtr: JSON.stringify(filter),
            offset: offset,
            limit: limit,
            filtr_search: filtrSearch ? filtrSearch : ''
        };

        let prom = this.odataService.postPrivate('get_co_exam_orders_ext', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);

            if (result.Status == 'OK')
                this.orders = this.setOrders(result, filter, filtrSearch);

            if (this.dashboardLoader)
                this.dashboardLoader.show = false;

            return result.Status;
        });

        return prom;
    }

    getExamOrdersRefreshStatus() {
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            this.odataService.postPrivate('get_exam_orders_refresh_status', this.odataService.paramsTokenAndLang({
                id_describing_center: this.userService.getCenterId()
            }), res => {
                let returnedJSON = JSON.stringify(res);
                let result = JSON.parse(returnedJSON);
                if (result.Status == 'OK') {
                    this.refreshOrdersStatus = JSON.parse(result.Value);
                }
            });
        }
    }

    getExamOrdersArchive(filter: IFiltrDashboard, offset: number, limit: number, filtrSearch?: string): Promise<string> {
        if (this.archiveLoader)
            this.archiveLoader.show = true;
        this.ordersArchive = [];

        let params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.id,
            filtr: JSON.stringify(filter),
            offset: offset,
            limit: limit,
            filtr_search: filtrSearch ? filtrSearch : ''
        });

        let prom = this.odataService.postPrivate('get_exam_orders_archive_ext', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);

            if (result.Status == 'OK')
                this.ordersArchive = this.setOrdersArchive(result, filter, filtrSearch);

            if (this.archiveLoader)
                this.archiveLoader.show = false;

            return result.Status;
        });
        return prom;
    }

    getExamDescriptionPdf(id: number): Promise<any> {
        return this.odataService.postPrivate('get_exam_description_pdf',
            this.odataService.paramsTokenAndLang({agreement_value: id}), res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.examDescriptionUuid = JSON.parse(result.Value).exam_description_uuid;
            }

            return result.Status;
        });
    }

    downloadExamFile() {
        let headers = new HttpHeaders(
            {
                'Content-Type': 'application/pdf',
                'Authorization': 'Bearer ' + this.sessionService.getToken()
            }
        );
        this.http.get(this.env.examDescPdfUrl + this.examDescriptionUuid,
            {responseType: 'blob', headers: headers}).subscribe(response => {
            let blob = new Blob([response], { type: 'application/pdf'});
            let url = window.URL.createObjectURL(blob);
            window.open(url, '_blank', 'location=0');
        });
    }

    setOrders(result, filter: IFiltrDashboard, filtrSearch?: string): IOrder[] {
        let value = JSON.parse(result.Value || '[]');
        let orders = value.map(val => val.json_info);
        this.clearOrdersData();
        this.numberOfOrders.emit(result.ValueCount);

        for (let i = 0; i < orders.length; i++) {
            this.checkSendingExams(orders[i]);
            this.setOrderData(orders[i]);
        }

        return orders;
    }

    setOrdersArchive(result, filter: IFiltrDashboard, filtrSearch?: string): IOrder[] {
        let value = JSON.parse(result.Value || '[]');
        let ordersArchive = value.map(val => val.json_info);
        this.clearOrdersArchiveData();
        this.getNumberOfOrdersArchive(filter, filtrSearch);

        for (let i = 0; i < ordersArchive.length; i++) {
            this.setOrderArchiveData(ordersArchive[i]);
            if (ordersArchive[i].description_date != null)
                ordersArchive[i].description_date = this.formatDate(ordersArchive[i].description_date);
            if (ordersArchive[i].reject_info.reject_time != null)
                ordersArchive[i].reject_info.reject_time = this.formatDate(ordersArchive[i].reject_info.reject_time);
        }

        return ordersArchive;
    }

    sortOrderActions(order: IOrder): any {
        order.actions_info.sort((a1: IAction, a2: IAction) => {
            if (a1.action_importance > a2.action_importance) return -1;
            else if (a1.action_importance == a2.action_importance) return 0;
            else return 1;
        });
    }

    clearOrdersData(): void {
        this.orders = [];
        this.age = [];
        this.hours = [];
        this.dates = [];
    }

    clearOrdersArchiveData(): void {
        this.ordersArchive = [];
        this.ageArchive = [];
        this.hoursArchive = [];
        this.datesArchive = [];
    }

    setOrderData(order: IOrder): void {
        let orderData = this.getOrderDateHourAge(order);

        this.age.push(orderData['age']);
        this.hours.push(orderData['hour']);
        this.dates.push(orderData['date']);

        this.sortOrderActions(order);
    }

    setOrderArchiveData(orderArchive: IOrder): void {
        let orderData = this.getOrderDateHourAge(orderArchive);

        this.ageArchive.push(orderData['age']);
        this.hoursArchive.push(orderData['hour']);
        this.datesArchive.push(orderData['date']);

        this.sortOrderActions(orderArchive);
    }

    formatDate(date: string) {
        return moment(date).format('YYYY-MM-DD');
    }

    checkSendingExams(order) {
        if (order.status_info.status_name == 'TO_APPOVE_BY_TIME') {
            this.descriptionService.examId = order.id_exam_order;
            this.descriptionService.getExamInfo().then(() => {
                let seconds = (600 - moment(moment()).diff(this.descriptionService.orderDescription.descriptionDate, 'seconds')) * 1000;
                if (!this.sendingExamTimeout) {
                    this.sendingExamId = order.id_exam_order;
                    this.secondsLeft = seconds;
                    this.sendingExamTimeout = setTimeout(() => {
                        this.checkIfStillSending();
                    }, this.secondsLeft);
                } else if (this.secondsLeft > seconds) {
                    clearTimeout(this.sendingExamTimeout);
                    this.sendingExamId = order.id_exam_order;
                    this.secondsLeft = seconds;
                    this.sendingExamTimeout = setTimeout(() => {
                        this.checkIfStillSending();
                    }, this.secondsLeft);
                }
            });
        }
    }

    checkIfStillSending() {
        this.descriptionService.examId = this.sendingExamId;
        this.descriptionService.getExamInfo().then(() => {
            if (this.descriptionService.orderDescription.status.codename == 'TO_APPOVE_BY_TIME') {
                this.setIntervalForRefresh();
            } else this.isSendEmitter.emit(true);
        });
    }

    setIntervalForRefresh() {
        let interval = setInterval(() => {
            this.descriptionService.getExamInfo().then(() => {
                if (this.descriptionService.orderDescription.status.codename != 'TO_APPOVE_BY_TIME') {
                    this.isSendEmitter.emit(true);
                    clearInterval(interval);
                }
            });
        }, 10000);
    }

    getOrderDateHourAge(order) {
        moment.locale(this.userService.iLanguage);
        let date = this.getDateString(order.order_date);
        let hour = moment(order.order_date).fromNow();
        let age = this.descriptionService.calculateAgeFromDates(new Date(order.exam_info.birth_date), new Date());
        return { age: age, hour: hour, date: date };
    }

    getDateString(orderDate: string): string {
        let orderMoment = moment(orderDate);
        let today = moment(new Date()).hours(23).minutes(59);
        let daysDiff = moment(orderDate).hours(0).minutes(0).diff(today, 'days');
        if (daysDiff === 0)
            return `Dziś, ${orderMoment.format('HH:mm')}`;
        else if (daysDiff === -1)
            return `Wczoraj, ${orderMoment.format('HH:mm')}`;

        return orderMoment.format('DD-MM-YYYY, HH:mm');
    }

    setId(newId: number): void {
        this.id = newId;
    }

    setFacilityId(newId: number): void {
        this.facilityId = newId;
    }

    patientsInitials(patientName: string): string {
        if (!patientName) {
            return '•••• ••••';
        }
        let spaceIdx = patientName.indexOf(' ');
        if (!spaceIdx || spaceIdx >= patientName.length)
            return patientName[0] + '••••';

        return `${patientName[0]}•••• ${patientName[spaceIdx + 1]}••••`;
    }

    tooltipInfo(or: IOrder): string {
        let tooltip = ``;
        // let tooltip = `${or.exam_info.modality.modality} ${or.exam_info.anatomy.part_name}`;
        if (or.exam_info.ordering_facility_name) tooltip += `<strong>Zlecający:</strong> ${or.exam_info.ordering_facility_name}`;
        if (or.exam_info.order_exam_type) tooltip += `<br /><strong>Nazwa badania:</strong> ${or.exam_info.order_exam_type}`;
        // if (or.status_info.status) tooltip += `<br/>Status: ${or.status_info.status}`;
        if (or.exam_info.exam_date) tooltip += `<br/><strong>Data badania:</strong> ${moment(or.exam_info.exam_date).format('DD-MM-YYYY HH:mm')}`;
        // if (or.exam_info.evo_accession_number) tooltip += `<br/>Accn: ${or.exam_info.evo_accession_number}`;
        if (or.exam_info.exam_order_notes) tooltip += `<br/><br/><strong>Uwagi:</strong> ${or.exam_info.exam_order_notes}`;

        return tooltip;
    }

    yearsDeclinatedString(age: number): string {
        let yrs = age % 10; // last digit of age
        if (age == 1) return 'OrderDescriptionScreens.Current.Years1';
        if (yrs > 1 && yrs < 5) return 'OrderDescriptionScreens.Current.Years2to4';
        if (yrs === 0 || yrs === 1 || yrs > 4) return 'OrderDescriptionScreens.Current.Years5to9';
    }

    getNumberOfOrdersArchive(filter: IFiltrDashboard, filtrSearch?: string): void {
        let params = this.odataService.paramsTokenAndLang({
            id_describing_center: this.id,
            filtr: JSON.stringify(filter),
            filtr_search: filtrSearch ? filtrSearch : ''
        });

        this.odataService.postPrivate('count_co_archive_exam', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.numberOfArchiveOrders.emit(parseInt(result.Value));
            }
        });
    }

    clearAllOrdersData() {
        this.orders = [];
        this.refreshOrdersStatus = initRefreshOrdersStatus;
        this.ordersArchive = [];
        this.age = [];
        this.ageArchive = [];
        this.dates = [];
        this.datesArchive = [];
        this.hours = [];
        this.hoursArchive = [];
        this.id = null;
    }
}
