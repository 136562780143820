<div aria-hidden="true" aria-labelledby="insuffCharsModalLabel" class="modal fade" id="insuffCharsModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex flex-column">
        <h2 class="modal-title">Nie można wysłać opisu</h2>
        <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-3">
        <p>Opis badania musi zawierać przynajmniej 100 znaków. <br />Brakuje: <strong>{{100 - no}}</strong> znaków.</p>
      </div>
    </div>
  </div>
</div>