<a (notificationInsideClick)="notificationInsideClick($event)"
   [ngClass]="notification.is_read==false ? 'notification new' : extended==false ? 'notification' : 'notification extended'"
   appNotificationInsideClick>

    <div class="title">{{notification.notification_title}}</div>
    <p [innerHTML]="notification.notification_text | safeHtml"
       [ngClass]="{'description': true, 'notification-short': !extended}"></p>

    <div *ngIf="extended">
        <div *ngIf="notification.notification_params?.actions_info != undefined" class="actions clearfix">
            <span *ngFor="let item of notification.notification_params?.actions_info; let i = index;">
                <a *ngIf="i < 2" class="btn btn-grey float-right" style="padding: 5px;"
                    (click)="makeAction(notification, item)">{{item.button_name}}</a>
            </span>
            <button *ngIf="notification.notification_params?.actions_info.length > 2" type="button"
                class="btn btn-grey float-right card-action" id="ddAction1" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1"
                *ngIf="notification.notification_params?.actions_info.length > 2">
                <ng-container *ngFor="let item of notification.notification_params?.actions_info; let i = index;">
                    <a class="dropdown-item" *ngIf="i >= 2" (click)="makeAction(notification, item)">{{item.button_name}}</a>
                </ng-container>
            </div>
        </div>
    </div>
</a>
