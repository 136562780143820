<div aria-hidden="true" aria-labelledby="pdfNotAvailableModalLabel" class="modal fade" id="pdfNotAvailableModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex flex-column">
        <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-3">
        <h3>Nie można pobrać opisu</h3>
      </div>
    </div>
  </div>
</div>