<table class="tile-table classic-table">
  <thead>
  <tr>
    <th>{{'OrderDescriptionScreens.Comparasion.DateOfOrder' | translate}}</th>
    <th>{{'OrderDescriptionScreens.Comparasion.Source' | translate}}</th>
    <th>{{'OrderDescriptionScreens.Comparasion.Type' | translate}}</th>
    <th>{{'OrderDescriptionScreens.Comparasion.AnatomyPart' | translate}}</th>
    <th>{{'OrderDescriptionScreens.Comparasion.ExamData' | translate}}</th>
    <th>&nbsp;</th>
  </tr>
  </thead>

  <tbody>
  <ng-container *ngIf="otherExams!=[]" >
    <ng-container *ngFor="let exam of otherExams; let j = index">
      <tr style="cursor: pointer;" class="tile">
        <td class="py-3">{{exam.exam_date}}</td>
        <td>{{exam.first_name}} {{exam.last_name}}, {{exam.pesel}}<br>
          {{exam.pm_info}}</td>
        <td>{{exam.order_exam_modality}}</td>
        <td>{{exam.anatomy_part.anatomy_part_text}}</td>
        <td><div class="copiedTooltip" [clipboard]="exam.evo_accession_number" (copied)="copiedText = $event">
          {{exam.evo_accession_number}}
          <div class="tooltipContainer" style="top: -17px;">
            <span *ngIf="copiedText == exam.evo_accession_number" class="tooltip copied">{{'Copied' | translate}}</span>
            <span *ngIf="copiedText != exam.evo_accession_number" class="tooltip notCopied">{{'CopyText' | translate}}</span>
          </div>
        </div>
          <span>{{exam.order_exam_name}}</span>
        </td>
        <td class="actions">
          <button [attr.aria-pressed]="(currentlyChosenExam == j)" (click)="showExamOrders(j)"
                  class="btn btn-light btn-show-down" id="exam{{j}}" data-toggle="button" type="button">
            <svg-icon class="svg" src="../../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="currentlyChosenExam == j" class="tile-children" id="mainTable{{j}}">
        <td colspan="9">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                  <h3><strong>{{'OrderDescriptionScreens.Comparasion.OrdersForThisExam' | translate}}</strong></h3>
                </div>
                <div class="col-md-4">
                  <div class="float-right">
                    <button *ngIf="[0, 2, 3].indexOf(exam.other_exam_transfer_status.transfer_status) >= 0" class="btn btn-light" (click)="downloadExamToDC(j)">
                      <ng-container *ngIf="exam.other_exam_transfer_status.transfer_status == 0">{{'OrderDescriptionScreens.Comparasion.DownloadExamBtn' | translate}}</ng-container>
                      <ng-container *ngIf="exam.other_exam_transfer_status.transfer_status != 0">{{'OrderDescriptionScreens.Comparasion.DownloadExamAgainBtn' | translate}}</ng-container>
                    </button>
                    <span *ngIf="[-1, 1, 4].indexOf(exam.other_exam_transfer_status.transfer_status) >= 0" class="badge badge-custom" [ngClass]="{
                            'badge-success': exam.other_exam_transfer_status.transfer_status == 1,
                            'badge-warning': exam.other_exam_transfer_status.transfer_status == -1 || 4
                          }">
                            <ng-container *ngIf="exam.other_exam_transfer_status.transfer_status == -1">{{'OrderDescriptionScreens.Comparasion.DownloadExamError-1' | translate}}</ng-container>
                            <ng-container *ngIf="exam.other_exam_transfer_status.transfer_status == 1">{{'OrderDescriptionScreens.Comparasion.DownloadExamInProgress' | translate}}</ng-container>
                            <ng-container *ngIf="exam.other_exam_transfer_status.transfer_status == 4" (click)="downloadExamToDC(j)">{{'OrderDescriptionScreens.Comparasion.DownloadExamError4' | translate}}</ng-container>
                          </span>

                  </div>
                </div>
              </div>
              <span *ngIf="!exam.exam_orders[0]">{{'OrderDescriptionScreens.Comparasion.NoOrders' | translate}}</span>
              <table class="tile-table classic-table">
                <tbody>
                <tr *ngFor="let order of exam.exam_orders, let i = index">
                  <td>
                    <strong>{{'OrderDescriptionScreens.Comparasion.Ordered' | translate}} {{ordersService.getDateString(order.exam_order_date)}}</strong>
                    <br><span class="node-text">{{order.ordering_facility_name}}</span>
                  </td>
                  <td>
                    <strong>{{'OrderDescriptionScreens.Comparasion.Described' | translate}} {{ordersService.getDateString(order.exam_description_info.exam_description_date)}}</strong>
                    <br>{{'OrderDescriptionScreens.Comparasion.By' | translate}} {{order.exam_description_info.physician_name}}
                  </td>
                  <td>
                    <strong>{{order.modality.modality}}</strong>
                    <br>{{order.anatomy.part_name}}
                  </td>
                  <td>
                    <strong>ICD.10:</strong> {{order.icd10}}
                    <br><span class="node-text">{{order.icd10_text}}</span>
                  </td>
                  <td class="actions">
                    <button type="button"
                            class="btn btn-light card-action" id="ddAction1" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                      <svg-icon class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
                      <a class="dropdown-item" (click)="makeAction('VIEW_EXAM_ORDER', j, i)">
                        {{'OrderDescriptionScreens.Comparasion.View' | translate}}
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>


        </td>
      </tr>
    </ng-container>
  </ng-container>
  </tbody>
</table>

<app-pdf-not-available-modal #pdfModal></app-pdf-not-available-modal>
<app-send-attention-modal #saveAndSendAttentionExamModal></app-send-attention-modal>
<app-reject-modal #rejectModal></app-reject-modal>
<app-assign-physician-modal #assignPhysicianModal></app-assign-physician-modal>
<app-universal-toast #savedToast></app-universal-toast>
<app-reopen-exam-toast #reopenToast></app-reopen-exam-toast>
<app-authorize-description-module #authorizeDescriptionModal></app-authorize-description-module>
<app-taking-description #takeoverModal></app-taking-description>
<app-order-to-correct-modal #toCorrectModal></app-order-to-correct-modal>
<app-attachment-view-modal #attachmentViewModalComponent></app-attachment-view-modal>
<app-order-info-modal #orderInfoModal></app-order-info-modal>

