<div class="row mt-1" [ngClass]="{'mr-3' : componentName == 'PatientOrders'}">
  <div class="col-1">
    <select (change)="setItemsOnPage()" [(ngModel)]="itemsOnPage" [value]="itemsOnPage" class="custom-select">
      <option [value]="10" selected>10</option>
      <option [value]="25">25</option>
      <option [value]="50">50</option>
      <option [value]="100">100</option>
    </select>
  </div>
  <div class="col-7"></div>
  <div class="col-3">
    <nav aria-label="...">
      <ul class="pagination justify-content-end">
        <li *ngIf="activePage != 1" class="page-item">
          <a class="page-link" (click)="changePage(activePage-1)">{{'Pages.Previous' | translate}}</a>
        </li>
        <li *ngIf="activePage == 1" class="page-item disabled">
          <a class="page-link">{{'Pages.Previous' | translate}}</a>
        </li>
        <li *ngFor="let page of pages; let i = index" class="page-item" [ngClass]="{'active': i==activePage-1}">
          <div *ngIf="pages.length >= 5">
            <a *ngIf="page == activePage || page == activePage-1 || page == activePage+1 || page == pages.length || page == 1" class="page-link"
               (click)="changePage(page)">{{page}}</a>
            <a *ngIf="(page == activePage-2 || page == activePage+2) && page != 1 && page != pages.length" class="page-link" style="cursor: auto;">...</a>
          </div>
          <div *ngIf="pages.length < 5">
            <a class="page-link" (click)="changePage(page)">{{page}}</a>
          </div>
        </li>
        <li *ngIf="activePage != pages.length" class="page-item">
          <a class="page-link" (click)="changePage(activePage+1)">{{'Pages.Next' | translate}}</a>
        </li>
        <li *ngIf="activePage == pages.length" class="page-item disabled">
          <a class="page-link">{{'Pages.Next' | translate}}</a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="col-1">
    <input [ngClass]="{'errorBorder': inputPageError}" class="form-control text-center" id="inputPage" type="text"
           placeholder="{{'Pages.Page' | translate}}" [(ngModel)]="inputPage"
           (keydown.enter)="goToPage(inputPage)">
  </div>
</div>
