<div class="row">

    <div class="col-md-12 page-back" style="max-width: 1300px; margin: 0 auto">

        <div *ngIf="!contextService.checkPermission('activateAccount') && currentPageRoute === '/accountProperties'">
            <a routerLink="/portal-pacjenta" class="z-logo">
                <svg-icon alt="" class="svg" src="../../assets/images/logo.svg" style="display: inline;"></svg-icon>
            </a>
        </div>

        <h3>
            <a [hidden]="!contextService.checkPermission('goBackFromProperties')" style="cursor:pointer;" (click)="navigateBack()"></a>
            {{'AccountPropertiesLink' | translate}}
        </h3>
    </div>
</div>

<div class="row" id="main_content" style="min-width: 1140px; max-width: 1300px; margin: 0 auto;">
    <div class="col col-md-3" style="max-width: 285px;">
        <div id="sticky_sidebar" style="height: 950px; width: 100%;">
            <!-- start: user has profie picture -->
            <ng-container *ngIf="userService.profileImageUuid != null">
                <div class="card user-card" id="photoDiv" style="position: relative;">
                    <img id="profileImg" alt="" class="img-fluid user-img-loaded"
                         src="{{userService.profileImageUrl}}" style="padding: 25px; height: auto; max-width: 100%;">
                    <input [disabled]="!contextService.checkPermission('uploadProfileImage')" class="btn btn-primary" onClick="document.getElementById('upload').click()"
                       type="button" style="position: absolute; right: 10px; bottom: 10px;"
                       value="{{'AccountProperties.ChangePhoto' | translate}}">
                </div>
                <input (change)="onImageSelected($event)" [disabled]="!contextService.checkPermission('uploadProfileImage')" id="upload" accept="image/*" type="file">
            </ng-container>
            <!-- end: user has profile picture -->

            <!-- start: user doesn't have profile pic -->
            <ng-container *ngIf="userService.profileImageUuid === null && contextService.checkPermission('activateAccount')">
                <div class="card user-card" style="position: relative;" id="photoDiv">
                    <img id="profileImg" alt="" class="img-fluid user-img-loaded"
                         src="../../assets/images/avatar/user_empty_avatar.svg" style="padding: 25px; height: auto; max-width: 100%;">
                    <input [disabled]="!contextService.checkPermission('uploadProfileImage')" class="btn btn-grey p-absolute" onClick="document.getElementById('upload').click()"
                           type="button" style="position: absolute; right: 10px; bottom: 10px; opacity: 1;"
                           value="{{'AccountProperties.AddPhoto' | translate}}">
                </div>
                <input (change)="onImageSelected($event)" [disabled]="!contextService.checkPermission('uploadProfileImage')" id="upload" accept="image/*" type="file">
            </ng-container>
            <!-- end: user doesn't have profile pic -->

            <nav class="sidebar-nav card mb-25 {{!contextService.checkPermission('activateAccount') ? 'mt0' : 'mt25'}}">
                <ul>
                    <li><a *ngIf="!contextService.checkPermission('activateAccount')"
                           [innerHTML]="'AccountProperties.Activate' | translate"
                           routerLink="/registrationFourth" style="margin-top: 5px; color: var(--red);"></a></li>
                    <li [ngClass]="{'active': activateField['data']}"><a (click)="scroll('section-dane')"
                                                                    [innerHTML]="'AccountProperties.YourData' | translate"></a>
                    </li>
                    <li [ngClass]="{'active': activateField['email']}"><a (click)="scroll('section-email')"
                                                                    [innerHTML]="'AccountProperties.ChangeEmail' | translate"></a>
                    </li>
                    <li [ngClass]="{'active': activateField['phone']}"><a (click)="scroll('section-phone')"
                                                                    [innerHTML]="'AccountProperties.ChangePhone' | translate"></a>
                    </li>
                    <li [ngClass]="{'active': activateField['password']}"><a (click)="scroll('section-password')"
                                                                    [innerHTML]="'AccountProperties.ChangePassword' | translate"></a>
                    </li>
                    <li [ngClass]="{'active': activateField['agreements']}"><a (click)="scroll('section-aggrements')"
                                                                    [innerHTML]="'AccountProperties.ChangeAgreements' | translate"></a>
                    </li>
                    <li [ngClass]="{'active': activateField['delete']}"><a (click)="scroll('section-delete')"
                                                                    [innerHTML]="'AccountProperties.DeleteAccount' | translate"></a>
                    </li>
                </ul>
            </nav>

            <a *ngIf="navigationService.hasContext('PHYSICIAN')"
               class="btn btn-block btn-lg btn-white" routerLink="/myProfile">{{'YourProfileLink' | translate}} ↗</a>
            <a *ngIf="contextService.checkPermission('firmLink')"
               class="btn btn-block btn-lg btn-white" routerLink="/coProfile">{{'FirmLink' | translate}} ↗</a>

        </div>
    </div>
    <div class="col col-md-9 pl35" style="max-width: 1000px;">
        <div class="row">
            <div class="col">
                <div class="card big-card" id="section-dane">
                    <h3 [innerHTML]="'AccountProperties.YourData' | translate"
                        class="card-title"></h3>
                    <div class="card-body">
                        <app-alert #saveYourDataAlert></app-alert>
                        <form [formGroup]="accountDataForm">
                            <div class="form-row">
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.Name' | translate"
                                           for="imie"></label>
                                    <input class="form-control" formControlName="imie"
                                           id="imie"
                                           placeholder="{{'AccountProperties.Name' | translate}}"
                                           type="text"
                                           value="{{accountSettings.first_name}}">
                                </div>
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.Surname' | translate"
                                           for="nazwisko"></label>
                                    <input class="form-control" formControlName="nazwisko"
                                           id="nazwisko"
                                           placeholder="{{'AccountProperties.Surname' | translate}}"
                                           type="text"
                                           value="{{accountSettings.last_name}}">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="obywatelstwo">{{'AccountProperties.Citizenship' | translate }}</label>
                                    <input class="form-control" disabled
                                           id="obywatelstwo"
                                           placeholder="{{'AccountProperties.Citizenship' | translate}}"
                                           type="text" value="{{accountSettings.citizenship}}">
                                </div>
                                <div class="col form-group">
                                    <label for="pesel">{{accountSettings.id_document_type | titlecase}}</label>
                                    <input class="form-control" disabled id="pesel"
                                           placeholder="{{accountSettings.id_document_type | titlecase}}"
                                           type="text" value="{{accountSettings.id_document_value}}">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.Login' | translate"
                                           for="login"></label>
                                    <input class="form-control" disabled id="login"
                                           placeholder="{{'AccountProperties.Login' | translate}}"
                                           type="text" value="{{accountSettings.login_name}}">
                                </div>
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.BirthDate' | translate"
                                           for="dataurodzenia"></label>
                                    <input class="form-control"
                                           formControlName="dataurodzenia"
                                           id="dataurodzenia"
                                           placeholder="{{'AccountProperties.BirthDate' | translate}}"
                                           type="text"
                                           value="{{accountSettings.birthday}}">
                                </div>
                            </div>
                            <div class="form-row" id="physician-pwz-div">
                                <ng-container *ngIf="accountSettings.pwz_status == 'verified'">
                                    <div class="col form-group">
                                        <label for="nrpwz">{{'AccountProperties.NrPwzShort' | translate}}</label>
                                        <input class="form-control" formControlName="pwz"
                                            id="nrpwz" placeholder="{{pwzPlaceholder}}"
                                            type="text" value="{{accountSettings.pwz}}">
                                        <!-- <span class="form-text text-muted">{{'AccountProperties.OnlyForDoctors' | translate}}</span> -->
                                    </div>
                                    <div class="col form-group">
                                        <span>{{'AccountProperties.PermissionsStatus' | translate}}</span><br/><br/>
                                        <div style="width: 15%; float: left;">
                                            <svg-icon  alt="" class="svg" src="../../assets/images/icons/symbolicons_pro_line.svg"></svg-icon>
                                        </div>
                                        <div style="width: 85%; float: left;">
                                            <span style="color: #1DD96D; font-weight: 600;">{{'AccountProperties.VerificationSuccessful' | translate}}</span>
                                            <br/>
                                            <span>{{'AccountProperties.PermissionsTypes' | translate}}: <strong>Diagnosta</strong></span>&nbsp;
                                            <span style="display: inline-block; background-color: #E3E4E8; border-radius: 50%; width: 18px; height: 18px;">
                                                <span style="display: inline-block; position: relative; top: -2px; left: 5px;">?</span>
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!(accountSettings.pwz_status == 'verified')">
                                    <div class="col form-group" style="padding-left: 5px; padding-bottom: 0; margin-bottom: 0;">
                                        <ng-container *ngIf="accountSettings.pwz_status == 'under_verification'; else showVerifyPhysicianAccountButton">
                                          <div class="row">
                                            <div class="col">
                                              <strong>{{'AccountProperties.PWZLabel' | translate}}</strong><br/>
                                              <strong>{{'AccountProperties.PWZUnderVerification' | translate}}</strong><br/>
                                            </div>
                                            <div class="col">
                                              <button class="btn btn-lg btn-light" routerLink="/registrationCo1">{{'DashboardScreen.CreateOwnCO' | translate}}</button>
                                            </div>
                                          </div>
                                        </ng-container>
                                        <ng-template #showVerifyPhysicianAccountButton>
                                            <strong>{{'AccountProperties.PWZLabel' | translate}}</strong><br/>
                                            {{'AccountProperties.VerifyPhysicianAccount' | translate}}<br/><br/>
                                            <button class="btn btn-primary"
                                                (click)="openVerificationModal()"
                                                [disabled]="!contextService.checkPermission('submitPWZ')"
                                                id="submitPWZ"
                                                type="button">{{'AccountProperties.ActivatePhysicianAccount' | translate}}</button>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </div>
                            <button (click)="getPassword('personalData')"
                                    *ngIf="false"
                                    [innerHTML]="'AccountProperties.SaveChanges' | translate"
                                    class="btn btn-lg btn-success"
                                    id="personalData"
                                    type="button">
                            </button>
                        </form>
                    </div>
                </div>

                <div class="card big-card" id="section-email">
                    <h3 [innerHTML]="'AccountProperties.ChangeEmail' | translate"
                        class="card-title"></h3>
                    <div class="card-body">
                        <app-alert #changeEmailAlert></app-alert>

                        <form [formGroup]="emailForm">
                            <div class="form-row">
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.CurrentEmail' | translate"
                                           for="obecny-email"></label>
                                    <input class="form-control" disabled
                                           id="obecny-email" placeholder=""
                                           type="text"
                                           value="{{accountSettings.email}}">
                                </div>
                                <div class="col form-group">
                                    &nbsp;
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.NewEmail' | translate"
                                           for="nowy-email"></label>
                                    <input class="form-control"
                                           formControlName="nowy_email"
                                           id="nowy-email"
                                           placeholder=""
                                           type="text">
                                </div>
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.RepeatEmail' | translate"
                                           for="powtorz-nowy-email"></label>
                                    <input class="form-control"
                                           formControlName="powtorz_nowy_email"
                                           id="powtorz-nowy-email" placeholder=""
                                           type="text">
                                </div>
                            </div>
                            <button (click)="getPassword('email')"
                                    [innerHTML]="'AccountProperties.SaveChanges' | translate"
                                    class="btn btn-lg btn-success"
                                    id="changeEmailButton"
                                    [disabled]="!contextService.checkPermission('changeEmailButton')
                                                || !isSectionChanged('email')"
                                    type="button">
                            </button>
                            <button *ngIf="accountSettings.email_verified || accountSettings.email==null"
                                    [innerHTML]="'AccountProperties.AlreadyConfirmedEmail' | translate"
                                    class="btn btn-lg btn-light btn-greyed">
                            </button>
                            <button (click)="confirmEmail()"
                                    *ngIf="!accountSettings.email_verified && accountSettings.email!=null"
                                    [innerHTML]="'AccountProperties.ConfirmEmail' | translate"
                                    class="btn btn-lg btn-success">
                            </button>
                        </form>
                    </div>
                </div>

                <div class="card big-card" id="section-phone">
                    <h3 [innerHTML]="'AccountProperties.ChangePhone' | translate"
                        class="card-title"></h3>
                    <div class="card-body">
                        <app-alert #changePhoneAlert></app-alert>
                        <form [formGroup]="phoneForm">
                            <div class="form-row">
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.CurrentPhone' | translate"
                                           for="obecny-nr-tel"></label>
                                    <input class="form-control" disabled
                                           id="obecny-nr-tel" placeholder=""
                                           type="text" value="{{accountSettings.phone_number}}">
                                </div>
                                <div class="col form-group">
                                    <label [innerHTML]="'AccountProperties.NewPhone' | translate"
                                           for="nowy-nr-tel"></label>
                                    <input class="form-control" formControlName="nowy_nr_tel"
                                           id="nowy-nr-tel" placeholder=""
                                           type="text"
                                           value="">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input formControlName="phoneAgreement"
                                           class="custom-control-input"
                                           id="phoneAgreement"
                                           type="checkbox">
                                    <label class="custom-control-label"
                                           for="phoneAgreement"
                                           [innerHTML]="accountSettings.phone_agreement_info.phone_agreement_content">
                                    </label>
                                </div>
                            </div>
                            <button (click)="getPassword('phone')"
                                    [innerHTML]="'AccountProperties.SaveChanges' | translate"
                                    class="btn btn-lg btn-success"
                                    id="phoneNum"
                                    [disabled]="!contextService.checkPermission('changePhoneButton')
                                                || !isSectionChanged('phone')"
                                    type="button">
                            </button>
                            <button *ngIf="accountSettings.phone_number_verified || accountSettings.phone_number==null"
                                    [innerHTML]="'AccountProperties.AlreadyConfirmedPhone' | translate"
                                    class="btn btn-lg btn-light btn-greyed">
                            </button>
                            <button (click)="confirmationModal()"
                                    *ngIf="!accountSettings.phone_number_verified && accountSettings.phone_number!=null"
                                    [innerHTML]="'AccountProperties.ConfirmPhone' | translate"
                                    class="btn btn-lg btn-success">
                            </button>
                        </form>
                    </div>
                </div>

                <div class="card big-card" id="section-password">
                    <h3 [innerHTML]="'AccountProperties.ChangePassword' | translate"
                        class="card-title"></h3>
                    <div class="card-body">
                        <app-alert #changePasswordAlert></app-alert>
                        <form [formGroup]="passwordForm" autocomplete="off">
                            <input readonly style="display:none;" type="password">
                            <!-- Niezbędne do wyłączenia autocomplete -->
                            <div class="form-row">
                                <div class="col form-group input-group pass">
                                    <label [innerHTML]="'AccountProperties.NewPassword' | translate"
                                           for="nowe-haslo"></label>
                                    <input autocomplete="off"
                                           class="form-control" formControlName="nowe_haslo"
                                           id="nowe-haslo" type="password">

                                    <div class="input-group-append" style="right: 44px; z-index: 10;">
                                        <button (click)="showPassRetry('nowe-haslo')"
                                                style="background: none; border: none">
                                            <i [ngClass]="{'fa-eye-slash': showNewPassword, 'fa-eye': !showNewPassword}"
                                               class="fa positionPlay"></i>
                                        </button>

                                    </div>
                                </div>
                                <div class="col form-group input-group pass">
                                    <label [innerHTML]="'AccountProperties.NewPasswordRepeat' | translate"
                                           for="powtorz-nowe-haslo"></label>
                                    <input autocomplete="off"
                                           class="form-control" formControlName="powtorz_nowe_haslo"
                                           id="powtorz-nowe-haslo" type="password">

                                    <div class="input-group-append" style="z-index: 10;">
                                        <button (click)="showPassRetry('powtorz-nowe-haslo')"
                                                style="background: none; border: none">
                                            <i [ngClass]="{'fa-eye-slash': showRepeatPassword, 'fa-eye': !showRepeatPassword}"
                                               class="fa positionPlay"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button (click)="getPassword('password')"
                                    [innerHTML]="'AccountProperties.ChangePasswordButton' | translate"
                                    class="btn btn-lg btn-success"
                                    id="changePassButton"
                                    [disabled]="!contextService.checkPermission('changePasswordButton')
                                                || !isSectionChanged('password')"
                                    type="button">
                            </button>
                        </form>
                    </div>
                </div>

                <div class="card big-card" id="section-aggrements">
                    <h3 [innerHTML]="'AccountProperties.ChangeAgreements' | translate"
                        class="card-title"></h3>
                    <div class="card-body">
                        <form>
                            <div *ngFor="let a of accountSettings.agreements" class="form-row">
                                <div class="form-group">
                                    <h4>{{a.agreement_group}}</h4>
                                    <br/>
                                    <div class="custom-control custom-checkbox">
                                        <input [checked]="a.agreement_value"
                                               [disabled]="!contextService.checkPermission('activateAccount')"
                                               class="custom-control-input"
                                               id="zgoda-{{a.id_agreement}}"
                                               type="checkbox">
                                        <label class="custom-control-label"
                                               for="zgoda-{{a.id_agreement}}"
                                               [innerHTML]="a.agreement_contents">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button (click)="validateAgreements()"
                                    [innerHTML]="'AccountProperties.SaveChanges' | translate"
                                    class="btn btn-lg btn-success"
                                    id="changeAgreementsButton"
                                    [disabled]="!contextService.checkPermission('changeAgreementsButton')
                                                || !isSectionChanged('agreements')"
                                    type="button">
                            </button>
                        </form>
                    </div>
                </div>
                <div class="card big-card" id="section-delete"
                     style="margin-bottom: 50px;">
                    <h3 [innerHTML]="'AccountProperties.DeleteAccount' | translate"
                        class="card-title"></h3>
                    <div class="card-body">
                        <p [innerHTML]="'AccountProperties.DeleteAccountDescription' | translate"></p>
                        <button [innerHTML]="'AccountProperties.DeleteAccountButton' | translate"
                                class="btn btn-lg btn-danger"
                                data-target="#exampleModal"
                                data-toggle="modal"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div aria-hidden="true" aria-labelledby="modal__removeAccount" class="modal fade" id="exampleModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 [innerHTML]="'AccountProperties.DeleteAccountConfirmation' | translate" class="modal-title"
                    id="modal__removeAccount"></h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p [innerHTML]="'AccountProperties.DeleteAccountDescription' | translate"></p>
            </div>
            <div class="modal-footer">
                <button [innerHTML]="'AccountProperties.DeleteAccountConfirmationButton' | translate"
                        class="btn btn-danger"
                        type="button"></button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div aria-hidden="true" aria-labelledby="setAgreementModal" class="modal fade" id="setAgreementModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="currentAgreementForModal == 'phone'" [innerHTML]="'AccountProperties.RemovePhoneAgreement' | translate" class="modal-title"
                    id="setAgreementModal"></h5>
                    <h5 *ngIf="currentAgreementForModal == 'medicalDocumentation'" [innerHTML]="'AccountProperties.RemoveMedicalDocumentation' | translate" class="modal-title"
                    id="setAgreementModal"></h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body my-3">
                <p [innerHTML]="'AccountProperties.AreYouSure' | translate"></p>
            </div>
            <div class="modal-footer">
                <button [innerHTML]="'AccountProperties.Cancel' | translate"
                        class="btn btn-danger"
                        aria-label="Close" data-dismiss="modal"
                        (click)="getAccountSettingsInfo()"
                        type="button">
                </button>
                <button [innerHTML]="'AccountProperties.Yes' | translate"
                        class="btn btn-success"
                        aria-label="Close" data-dismiss="modal"
                        type="button" (click)="removeAgreement()">
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" id="getPasswordModal" role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 [innerHTML]="'AccountProperties.GetPassword' | translate" class="modal-title"
                    id="exampleModalLabel"></h2>
                <button aria-label="Close" class="btn btn-light close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p [innerHTML]="'AccountProperties.GetPasswordInfo' | translate"></p>

                <form autocomplete="off">
                    <input type="password" style="display:none;" readonly> <!-- Niezbędne do wyłączenia autocomplete -->
                    <div class="form-group" style="padding-bottom: 0; margin-bottom: 10px;">
                        <label [innerHTML]="'AccountProperties.GetPasswordLabel' | translate" class="bigLabel"
                               for="login"></label>
                        <input autocomplete="off" class="form-control" id="passwordInput"
                               placeholder="{{'AccountProperties.CurrentPassword' | translate}}"
                               type="password" value="">
                    </div>

                    <p [innerHTML]="'Errors.InvalidPass' | translate" id="passwordInputError"
                       style="display: none;"></p>

                </form>

            </div>
            <div class="modal-footer" style="height: 70px;">
                <button (click)="continueWithPassword()"
                        [innerHTML]="'AccountProperties.GetPasswordButton' | translate"
                        class="btn btn btn-light btn-greyed"
                        id="continue"
                        style="width: 100%" type="button">
                </button>
            </div>
        </div>
    </div>
</div>

<!-- start: verificationSendModal -->
<div aria-hidden="true" class="modal fade" role="dialog" tabindex="-1" id="verificationSendModal"
    aria-labelledby="verificationSendModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-4">
                    <div class="modal-header mb-4">
                        <h3 class="modal-title" id="verificationSendModalLabel">
                            {{'GetDCAccessScreen.ActivatePwz' | translate}}
                        </h3>
                        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body mb-5">
                        <h2 class="mb-4" [innerHTML]="'GetDCAccessScreen.ThanksForSending' | translate"></h2>
                        <p class="mb-3">{{'GetDCAccessScreen.IfSuccessVerification' | translate}}</p>
                        <p>
                            {{'GetDCAccessScreen.VerificationTime' | translate}}
                            <a routerLink="/siteUnderConstruction" (click)="contact()">
                                {{'GetDCAccessScreen.ContactUs' | translate}}
                            </a>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button data-dismiss="modal" type="button" class="btn btn-primary btn-lg float-right">
                            {{'GetDCAccessScreen.CloseWindow' | translate}}
                        </button>
                    </div>
        </div>
    </div>
</div>
<!-- end: verificationSendModal -->

<app-universal-toast #saveAccountSettingsToast></app-universal-toast>
<app-pwz-verification-modal #pwzModal></app-pwz-verification-modal>
<app-sms-modal #smsModal phoneNumber="{{accountSettings.phone_number}}"></app-sms-modal>
