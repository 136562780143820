import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@/core/components/base-component/base.component';
import { Toast, ToastType } from '@/core/components/toast/toast.model';
import { ToastService } from '@/core/components/toast/toast.service';
import { takeUntil } from 'rxjs/operators';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent extends BaseComponent implements OnInit {
  toasts: Toast[] = [];
  faClose = faTimesCircle;

  constructor(private toastService: ToastService) { super(); }

  ngOnInit() {
    this.toastService.onToast().pipe(
      takeUntil(this.destroy$))
      .subscribe(toast => {
        const toastToIncrement = this.toasts.find(t => t.message === toast.message && !toast.autoClose && !t.autoClose);
        toastToIncrement ? this.incrementToast(toastToIncrement) : this.toasts = this.toasts.concat(toast);
        if (toast.autoClose) {
          setTimeout(() => this.removeToast(toast), 5000);
      }
    });
  }

  incrementToast(toast: Toast) {
    this.toasts.find(t => t === toast).sameToastCount += 1;
  }

  removeToast(toast: Toast) {
    this.toasts.find(t => t == toast).fade = true;
    setTimeout(() => {
      this.toasts = this.toasts.filter(t => t !== toast);
    }, 500);
  }

  faColor(alert: Toast) {
    if (!alert) return;
    const faClass = {
      [ToastType.Success]: 'alert-success',
      [ToastType.Error]: 'alert-danger',
      [ToastType.Info]: 'alert-info',
      [ToastType.Warning]: 'alert-warning'
    };
    return faClass[alert.type];
  }

  cssClass(alert: Toast) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [ToastType.Success]: 'alert alert-success',
      [ToastType.Error]: 'alert alert-danger',
      [ToastType.Info]: 'alert alert-info',
      [ToastType.Warning]: 'alert alert-warning'
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }
}
