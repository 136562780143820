/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@angular/common";
import * as i5 from "./language-button.component";
import * as i6 from "../../services/users.service";
var styles_LanguageButtonComponent = [i0.styles];
var RenderType_LanguageButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageButtonComponent, data: {} });
export { RenderType_LanguageButtonComponent as RenderType_LanguageButtonComponent };
function View_LanguageButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Polski"]))], null, null); }
function View_LanguageButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Polski"]))], null, null); }
function View_LanguageButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["English"]))], null, null); }
function View_LanguageButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["English"]))], null, null); }
export function View_LanguageButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "lr-language-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mode"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "dark-mode"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["alt", ""], ["class", "svg"], ["src", "../../../assets/images/icons/infobar/icon__dark_mode.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(4, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "lang"], ["style", "display: none;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageButtonComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageButtonComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageButtonComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageButtonComponent_4)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "../../../assets/images/icons/infobar/icon__dark_mode.svg"; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.language === "pl"); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.language === "en"); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.language === "en"); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.language === "pl"); _ck(_v, 13, 0, currVal_4); }, null); }
export function View_LanguageButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-language-button", [], null, null, null, View_LanguageButtonComponent_0, RenderType_LanguageButtonComponent)), i1.ɵdid(1, 114688, null, 0, i5.LanguageButtonComponent, [i6.UsersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguageButtonComponentNgFactory = i1.ɵccf("app-language-button", i5.LanguageButtonComponent, View_LanguageButtonComponent_Host_0, {}, {}, []);
export { LanguageButtonComponentNgFactory as LanguageButtonComponentNgFactory };
