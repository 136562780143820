<div class="stepper-container">

    <ul class="stepper">

        <div *ngFor="let step of steps; let i = index">
            <li [class.active]="stepNum == (i + 1)" [class.finished]="(stepNum > i + 1)"> <!-- TODO image -->
                <span *ngIf="stepNum > i + 1" class="circle"><svg-icon alt=""
                                                                  src="../../../assets/images/icons/icon__finished.svg"></svg-icon></span>
                <span *ngIf="!(stepNum > i + 1)" class="circle">{{i + 1}}</span>
                <span [innerHTML]="('Stepper.' + step) | translate" class="label"></span>
            </li>
        </div>

    </ul>

</div>
