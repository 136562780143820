import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersService, OdataService, SessionService } from '@/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from '@/utils';
import { RegistrationService } from '@/services/registration.service';
import { PageService } from '@/services/page.service';

interface IRegisterStep1CheckPin {
    uuid: string;
    pin: string;
}

@Component({
    selector: 'app-registration-second',
    templateUrl: './registration-second.component.html',
    styleUrls: ['./registration-second.component.css']
})
export class RegistrationSecondComponent implements OnInit, OnDestroy {

    @ViewChild('errorAlert') errorAlert: AlertComponent;

    userService: UsersService;
    registerStep1Pin: IRegisterStep1CheckPin = { uuid: '', pin: '' };
    alertText: string = '';
    mail = { value: '' };
    isExpired = false;
    private sub: any;

    constructor(userService: UsersService, private router: Router,
        private route: ActivatedRoute, private odataService: OdataService, private session: SessionService,
        private registrationService: RegistrationService, private pageService: PageService) {
        this.userService = userService;
        this.isExpired = registrationService.isExpired;
    }

    ngOnInit() {
        this.mail.value = sessionStorage.getItem('register_mail');
        this.sub = this.route.params.subscribe(params => {
            this.registerStep1Pin.uuid = params['uuid'];
        });
        this.pageService.setSignUpPageTitle();
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    regenerate_registraction_link() {
        this.odataService.postPublic('regenerate_registraction_link',
            {
                registration_uuid: this.registerStep1Pin.uuid,
                session_id: this.session.getSessionId(),
                client_ip: this.userService.iClientIp
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let link = JSON.parse(returnedJson);
                console.log(link);
                if (link.value == 'REGISTRATION_OK') {
                    this.registrationService.isExpired = false;
                    this.isExpired = false;
                }
            }
        );
    }

    register_user_step1_check_pin() {
        this.odataService.postPublic('register_user_step1_check_pin', this.registerStep1Pin,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'PIN_OK') {
                    this.router.navigate(['/registrationThird', this.registerStep1Pin.uuid]);
                } else {
                    this.errorAlert.displayError(result.value);
                }
            }
        );
    }

}
