import { OrderToCorrectModalComponent } from '@/utils/order-to-correct-modal/order-to-correct-modal.component';
import { EnvService } from '@/services/env.service';
import { ContextsService } from '@/services/contexts.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OdataService, OrderActionsService, OrdersService, SessionService, UsersService } from '@/services';
import { AssignPhysicianModalComponent } from '@/utils/assign-physician-modal/assign-physician-modal.component';
import { RejectModalComponent } from '@/utils/reject-modal/reject-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersComponent } from '@/utils/filters/filters.component';
import { IFiltrDashboard, IOrder } from '@/interfaces';
import { Subscription, fromEvent, merge } from 'rxjs';
import { LoaderComponent } from '@/utils/loader/loader.component';
import moment from 'moment';
import { PageService } from '@/services/page.service';
import { FiltersService } from '@/services/filters.service';
import { TranslateService } from '@ngx-translate/core';
import { PaginationComponent } from '@/utils/pagination/pagination.component';
import { OrdersListComponent } from '@/utils/orders-list/orders-list.component';
import { SearchComponent } from '@/utils/search/search.component';
import { PdfNotAvailableModalComponent } from '@/utils/pdf-not-available-modal/pdf-not-available-modal.component';
import { ConsultationViewModalComponent } from '@/utils/consultation/consultation-view-modal/consultation-view-modal.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('assignPhysicianModal') assignPhysicianModal: AssignPhysicianModalComponent;
    @ViewChild('rejectModal') rejectModal: RejectModalComponent;
    @ViewChild('toCorrectModal') toCorrectModal: OrderToCorrectModalComponent;
    @ViewChild('filters') filters: FiltersComponent;
    @ViewChild('search') search: SearchComponent;
    @ViewChild('loader') loader: LoaderComponent;
    @ViewChild('pagination') pagination: PaginationComponent;
    @ViewChild('ordersList') ordersList: OrdersListComponent;
    @ViewChild('pdfModal') pdfModal: PdfNotAvailableModalComponent;
    @ViewChild('consultationViewModal') consultationViewModal: ConsultationViewModalComponent;
    currentId: string = '';
    timeout;
    userId: string;
    ordersService: OrdersService;
    userService: UsersService;
    searchSubscription: Subscription;
    pagesSubscription: Subscription;
    pageChangeSubscription: Subscription;
    itemsOnPageChangeSubscription: Subscription;
    centerSubscription: Subscription;
    refreshSubscription: Subscription;
    actionsSubscription: Subscription;
    timeoutSubscription: Subscription;
    filtrDashboard: IFiltrDashboard;
    filterService: FiltersService;
    moreFilters: string = '';
    isActiveOtherFilter = false;
    isEmptyDashboard = false;
    interval: any;

    constructor(userService: UsersService, private odataService: OdataService,
                private sessionService: SessionService, ordersService: OrdersService, private router: Router,
                private orderActions: OrderActionsService, private route: ActivatedRoute, private pageService: PageService,
                filterService: FiltersService, private translate: TranslateService, public contextService: ContextsService,
                private env: EnvService) {
        this.ordersService = ordersService;
        this.filterService = filterService;
        this.userService = userService;
    }

    getCoDescribingPhysiciansByCenter(): void {
        this.odataService.postPrivate('get_co_describing_physicians_by_center', this.odataService.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                this.assignPhysicianModal.physicians = value;
            }
        });
    }

    coExamActionAssignToMe: Function = (examId: number) => {
        this.orderActions.coExamActionAssignToMe(examId).then(
            res => {
                if (res) {
                    this.refreshOrders();
                }
            }
        );
    }

    refreshOrders: Function = () => {
        this.ordersService.getCoExamOrders(this.filtrDashboard,
            this.pagination.activePage * parseInt(this.pagination.itemsOnPage) - parseInt(this.pagination.itemsOnPage),
            parseInt(this.pagination.itemsOnPage), this.search.filterSearch);
    }

    coExamActionAssignToPhysian: Function = (examId: number) => {
        let order = this.ordersService.orders.find(x => x.id_exam_order == examId);
        this.assignPhysicianModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionAssignToPhysian(examId, value).then(
                    res => {
                        if (res) {
                            this.refreshOrders();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamActionReject = (examId: number) => {
        let order = this.ordersService.orders.find(x => x.id_exam_order == examId);
        this.rejectModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionReject(examId, value).then(
                    res => {
                        if (res) {
                            this.refreshOrders();
                            this.rejectModal.hide();
                        } else {
                            this.rejectModal.alert.active = true;
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamActionRejectByPhysician: Function = (examId: number) => {
        let order = this.ordersService.orders.find(x => x.id_exam_order == examId);
        this.rejectModal.show(order, undefined, true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionRejectByPhysician(examId, value).then(
                    res => {
                        if (res) {
                            this.refreshOrders();
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamCancelAssigment: Function = (examId: number) => {
        let order = this.ordersService.orders.find(x => x.id_exam_order == examId);
        this.rejectModal.show(order, true);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.rejectModal.setPromiseResolve(resolve);
            this.rejectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionCancelAssigment(examId, value).then(
                    res => {
                        if (res) {
                            this.refreshOrders();
                            this.rejectModal.hide();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    coExamChangeAssigment: Function = (examId: number) => {
        let order = this.ordersService.orders.find(x => x.id_exam_order == examId);
        this.assignPhysicianModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.assignPhysicianModal.setPromiseResolve(resolve);
            this.assignPhysicianModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionChangeAssigment(examId, value).then(
                    res => {
                        if (res) {
                            this.refreshOrders();
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    getExamDescriptionPdf: Function = (examId: number) => {
        this.ordersService.getExamDescriptionPdf(examId).then(
            (res) => {
                if (res == 'OK') {
                    if (this.ordersService.examDescriptionUuid == null) {
                        this.pdfModal.show();
                    } else {
                        this.ordersService.downloadExamFile();
                    }
                }
            }
        );
    }

    coExamDescriptionToCorrect: Function = (examId: number) => {
        let order = this.ordersService.orders.find(x => x.id_exam_order == examId);
        this.toCorrectModal.show(order);
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            this.toCorrectModal.setPromiseResolve(resolve);
            this.toCorrectModal.setPromiseReject(reject);
        });

        promise.then(
            (value) => {
                this.orderActions.coExamActionOrderToCorrect(examId, value).then(
                    res => {
                        if (res) {
                            this.refreshOrders();
                            this.toCorrectModal.hide();
                        } else {
                            this.toCorrectModal.alert.displayError('TooLate');
                        }
                    }
                );
            }, (reason) => {
                // TODO
            });
    }

    actionsList = {
        'ASSIGN_MYSELF': this.coExamActionAssignToMe,
        'START_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'dashboard/' + JSON.stringify(this.filtrDashboard);
            this.filterService.searchInputDashboard = this.search.filterSearch;
            return this.orderActions.coExamActionStartDescrption(examId);
        },
        'ASSIGN_PHYSICIAN': this.coExamActionAssignToPhysian,
        'REJECT': this.coExamActionReject,
        'ASSIGN_AND_START_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'dashboard/' + JSON.stringify(this.filtrDashboard);
            this.filterService.searchInputDashboard = this.search.filterSearch;
            return this.orderActions.coExamActionAssignAndStartDescription(examId);
        },
        'VIEW_EXAM_ORDER': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'dashboard/' + JSON.stringify(this.filtrDashboard);
            this.filterService.searchInputDashboard = this.search.filterSearch;
            return this.orderActions.viewExamOrder(examId);
        },
        'REJECT_BY_PHYSICIAN': this.coExamActionRejectByPhysician,
        'REOPEN_DESCRIPTION': (examId: number): Promise<boolean> => {
            this.orderActions.cameFrom = 'dashboard/' + JSON.stringify(this.filtrDashboard);
            this.filterService.searchInputDashboard = this.search.filterSearch;
            return this.orderActions.coExamReopenDescription(examId);
        },
        'CANCEL_ASSIGMENT': this.coExamCancelAssigment,
        'CHANGE_ASSIGMENT': this.coExamChangeAssigment,
        'VIEW_DESCRIPTION_PDF': this.getExamDescriptionPdf,
        'CORRECT_DESCRIPTION': this.coExamDescriptionToCorrect,
        'START_DESCRIPTION_CORRECTION': (examId: number): Promise<boolean> => {
          this.orderActions.cameFrom = 'dashboard/' + JSON.stringify(this.filtrDashboard);
          this.filterService.searchInputDashboard = this.search.filterSearch;
          return this.orderActions.coExamActionStartDescriptionCorrection(examId);
        },
        'VIEW_AI_CONSULTATION': (examId: number) => this.consultationViewModal.show(undefined, this.ordersService.orders.find(x => x.id_exam_order == examId))
    };

    setNumberOfPages() {
        this.pagesSubscription = this.ordersService.numberOfOrders.subscribe(
            res => {
                this.pagination.pages = [];
                this.pagination.numberOfPages = res / parseInt(this.pagination.itemsOnPage);
                Math.floor(this.pagination.numberOfPages);
                for (let i = 0; i < this.pagination.numberOfPages; i++) {
                    this.pagination.pages.push(i + 1);
                }
            }
        );

    }

    ngOnInit() {
        if (sessionStorage.getItem('DESCRIBING_CENTER') != null) {
            this.route.paramMap.subscribe(params => {
                if (sessionStorage.getItem('centerChanged') != null) {
                    sessionStorage.removeItem('centerChanged');
                    this.filterService.removeSessionFilters();
                    this.filtrDashboard = FiltersComponent.createInitialIFiltrDashboard();
                } else if (sessionStorage.getItem('filtersDashboard') != null) {
                    this.filtrDashboard = JSON.parse(sessionStorage.getItem('filtersDashboard'));
                    window.history.pushState({}, '', '/dashboard/' + JSON.stringify(this.filtrDashboard));
                } else if (params.get('filter') == null || params.get('filter') === JSON.stringify(FiltersComponent.createInitialIFiltrDashboard())) {
                    this.filtrDashboard = FiltersComponent.createInitialIFiltrDashboard();
                    this.currentId = 'Wszystkie';
                } else if (params.get('filter') != null) {
                    this.filtrDashboard = JSON.parse(decodeURI(params.get('filter')));
                }
                this.filters.filtrDashboard = this.filtrDashboard;
            });
            this.translate.get('DashboardScreen.More')
                .subscribe((res: string) => {
                    this.moreFilters = res;
                });

            if (this.filterService.searchInputDashboard != '') {
                this.search.filterSearch = this.filterService.searchInputDashboard;
                document.getElementById('clearSearchButton').style.display = 'block';
            }

            this.checkCurrentPage();
            this.makeActionEvent();

            this.searchEvent();
            this.changePageEvent();
            this.itemsOnPageChangeEvent();
            this.setNumberOfPages();

            let offset = (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage);

            this.centerSubscription = this.userService.centerChanged.subscribe((e) => {
                if (this.ordersService.sendingExamTimeout)
                    clearTimeout(this.ordersService.sendingExamTimeout);
                this.ordersService.getCoExamOrders(this.filtrDashboard, offset, parseInt(this.pagination.itemsOnPage), this.search.filterSearch).then(() => {
                    if (JSON.stringify(this.filtrDashboard) === JSON.stringify(FiltersComponent.createInitialIFiltrDashboard())) {
                        if (this.ordersService.orders.length == 0) {
                            this.isEmptyDashboard = true;
                            this.currentId = '';
                        } else {
                            this.isEmptyDashboard = false;
                            this.currentId = 'Wszystkie';
                        }
                    }
                    if (e != null) {
                        this.filterService.getFilters('dashboard').then(() => {
                          let myFilterId = sessionStorage.getItem('dashboardMyFilter');
                          if (myFilterId && myFilterId != '' && myFilterId != 'defaultFilter') {
                            this.currentId = myFilterId;
                            this.checkMyFiltersForFavorite(myFilterId);
                          }
                        });
                    }
                    let min = moment();
                    for (let i = 0; i < this.ordersService.orders.length; i++) {
                        if (min > moment(this.ordersService.orders[i].order_date)) {
                            min = moment(this.ordersService.orders[i].order_date);
                        }
                    }
                    this.filters.daterange.start = min;
                    this.filters.plOptions.startDate = min;
                    this.filters.enOptions.startDate = min;
                });
            });

            this.refreshSubscription = this.ordersService.isSendEmitter.subscribe((e) => {
                if (e) {
                    this.refreshOrders();
                }
            });

            this.ordersService.dashboardLoader = this.loader;
            this.userService.dcListPromise.then(res => {
                this.getCoDescribingPhysiciansByCenter();
            });
            this.getAccountSettingsInfo();

            /*
             * Temporary fix: CU Refresh Order List
             */

            clearInterval(this.interval);
            this.interval = setInterval(() => { /**/ }, 120000);

            const scrollEvent = fromEvent(document, 'scroll');
            const whellEvent = fromEvent(document, 'whell');
            const keypressEvent = fromEvent(document, 'keypress');
            const mousemoveEvent = fromEvent(document, 'mousemove');
            const clickEvent = fromEvent(document, 'click');

            const allEvents = merge(
                scrollEvent,
                whellEvent,
                keypressEvent,
                mousemoveEvent,
                clickEvent
            );

            this.timeoutSubscription = allEvents.subscribe(e => {
                clearInterval(this.interval);
                this.interval = setInterval(() => {
                    this.refreshOrders();
                }, 120000);
            });
            /**/

        } else {
            this.loader.show = false;
        }
        this.pageService.setTranslatedPageTitle('DashboardScreen.Orders', 0);
        document.getElementById('dashboardLink').setAttribute('class', 'active');
    }

    ngOnDestroy() {
        document.getElementById('dashboardLink').setAttribute('class', '');
        if (sessionStorage.getItem('DESCRIBING_CENTER') != null) {
            clearInterval(this.interval);
            if (JSON.stringify(this.filtrDashboard) != JSON.stringify(FiltersComponent.createInitialIFiltrDashboard())) {
                sessionStorage.setItem('filtersDashboard', JSON.stringify(this.filtrDashboard));
            } else sessionStorage.removeItem('filtersDashboard');
            if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
            this.searchSubscription.unsubscribe();
            this.pagesSubscription.unsubscribe();
            this.pageChangeSubscription.unsubscribe();
            this.actionsSubscription.unsubscribe();
            this.itemsOnPageChangeSubscription.unsubscribe();
            this.centerSubscription.unsubscribe();
            this.timeoutSubscription.unsubscribe();
            this.pageService.resetPageTitle();
        }
    }

    getAccountSettingsInfo() {
        this.odataService.postPrivate('get_account_settings_info',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson).AccountSettings;
                this.userId = JSON.parse(result).id_user;
            });
    }

    dropdown() {
        this.filters.dropdown();
    }

    searchEvent() {
        this.searchSubscription = this.search.searchEvent.subscribe((page) => {
          this.pagination.changePage(page);
          this.currentId = '';
        });
    }

    checkCurrentPage() {
        if (this.sessionService.getCurrentPage('Dashboard'))
            this.pagination.activePage = this.sessionService.getCurrentPage('Dashboard');
        if (this.sessionService.getItemsOnPage('Dashboard'))
            this.pagination.itemsOnPage = this.sessionService.getItemsOnPage('Dashboard');
    }

    updateOrdersList() {
        this.filterService.searchInputDashboard = this.search.filterSearch;
        if (!this.search.isSearch) {
            this.ordersService.getCoExamOrders(
                this.filtrDashboard,
                (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                parseInt(this.pagination.itemsOnPage)).then(
                () => {
                    this.pagination.inputPage = this.pagination.activePage + '';
                }
            );
        } else {
            this.ordersService.getCoExamOrders(
                this.filtrDashboard,
                (this.pagination.activePage * parseInt(this.pagination.itemsOnPage)) - parseInt(this.pagination.itemsOnPage),
                parseInt(this.pagination.itemsOnPage), this.search.filterSearch).then(
                () => {
                    this.pagination.inputPage = this.pagination.activePage + '';
                }
            );
        }
    }

    changePageEvent() {
        this.pageChangeSubscription = this.pagination.pageChangeEmitter.subscribe(
            () => {
                this.updateOrdersList();
            }
        );
    }

    itemsOnPageChangeEvent() {
        this.itemsOnPageChangeSubscription = this.pagination.changeItemsOnPageEmitter.subscribe(
            () => {
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
                this.updateOrdersList();
            }
        );
    }

    hideFilter() {
        sessionStorage.setItem('activeFilter', 'true');
        this.dropdown();
    }

    getOrders(id: string): void {
        let isOtherFilter = false;
        this.hideFilter();
        this.clearFilter();
        this.search.filterSearch = '';
        this.search.isSearch = false;
        $('#searchInput').val('');
        document.getElementById('clearSearchButton').style.display = 'none';
        sessionStorage.removeItem('dashboardMyFilter');

        if (id == 'Wszystkie') {
            this.filtrDashboard = FiltersComponent.createInitialIFiltrDashboard();
            this.setDefaultOtherFilter();
        } else if (id == 'Pilne') {
            this.filtrDashboard.priority = [2, 3];
            this.setDefaultOtherFilter();
        } else if (id == 'Moje zlecenia') {
            this.filtrDashboard.assign_to = [this.userService.userId];
            this.setDefaultOtherFilter();
        } else {
            isOtherFilter = true;
            this.checkMyFiltersForFavorite(id);
            sessionStorage.setItem('dashboardMyFilter', id);
            this.filters.filtrDashboard = this.filtrDashboard;
            this.filters.setFilterDashboard();
        }
        this.currentId = id;
        sessionStorage.removeItem('filtersDashboard');
        this.sessionService.removeCurrentPage('Dashboard');
        window.history.pushState({}, '', '/dashboard/' + JSON.stringify(this.filtrDashboard));
        this.ordersService.getCoExamOrders(this.filtrDashboard, 0, parseInt(this.pagination.itemsOnPage)).then(
            () => {
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
            }
        );
        if (!isOtherFilter) {
            this.filters.deleteAllFiltersDashboard(false);
        }
    }

    setDefaultOtherFilter() {
        this.translate.get('DashboardScreen.More')
            .subscribe((res: string) => {
                this.moreFilters = res;
            });
        this.isActiveOtherFilter = false;
        this.filterService.getFilters('dashboard');
    }

    makeActionEvent(): void {
        this.actionsSubscription = this.ordersList.actionEmitter.subscribe(
            (res) => {
                if (res.action == 'START_DESCRIPTION' || res.action == 'VIEW_EXAM_ORDER' ||
                    res.action == 'ASSIGN_AND_START_DESCRIPTION' || res.action == 'REOPEN_DESCRIPTION' || res.action == 'START_DESCRIPTION_CORRECTION') {
                    this.actionsList[res.action](this.ordersService.orders[res.index].id_exam_order).then(result => {
                        if (result) {
                            if (res.action == 'VIEW_EXAM_ORDER') {
                                sessionStorage.setItem('mode', 'view');
                                this.router.navigate(['/orderDescription/' + this.ordersService.orders[res.index].id_exam_order + '/currentDescription'], { queryParams: { mode: 'view' } });
                            } else {
                                sessionStorage.setItem('mode', 'description');
                                this.router.navigate(['/orderDescription/' + this.ordersService.orders[res.index].id_exam_order + '/currentDescription'], { queryParams: { mode: 'description' } });
                            }
                        }
                    });
                } else {
                    this.actionsList[res.action](this.ordersService.orders[res.index].id_exam_order);
                }
            });
    }

    clearFilter(): void {
        this.filtrDashboard = FiltersComponent.createInitialIFiltrDashboard();
        this.filterService.clearSavedSearchAndFilters('dashboard');
    }

    filterOrders(filter: IFiltrDashboard): void {
        this.filtrDashboard = filter;
        sessionStorage.removeItem('filtersDashboard');
        sessionStorage.removeItem('dashboardMyFilter');
        this.sessionService.removeCurrentPage('Dashboard');
        window.history.pushState({}, '', '/dashboard/' + JSON.stringify(this.filtrDashboard));
        this.ordersService.getCoExamOrders(filter, 0, parseInt(this.pagination.itemsOnPage), this.search.filterSearch).then(
            () => {
                this.pagination.activePage = 1;
                this.pagination.inputPage = '1';
            }
        );
        this.currentId = '';
        this.translate.get('DashboardScreen.More')
            .subscribe((res: string) => {
                this.moreFilters = res;
            });
        this.isActiveOtherFilter = false;
    }

    lockScreen(): void {
        this.odataService.clearRequests();
        sessionStorage.setItem('currentPage', decodeURI(this.router.url.toString()));
        let modal = document.getElementsByClassName('modal-backdrop fade show');
        if (modal.item(0) != null) {
            modal.item(0).setAttribute('class', '');
        }
        this.sessionService.lockSession();
        this.router.navigate(['unlockScreen']);
    }

  checkMyFiltersForFavorite(id: string) {
    this.filtrDashboard = this.filterService.getMyFilters('dashboard')[parseInt(id)].value;
    if (!this.filterService.getMyFilters('dashboard')[parseInt(id)].isFavourite) {
      this.moreFilters = this.filterService.getMyFilters('dashboard')[parseInt(id)].name;
      this.isActiveOtherFilter = true;
    } else {
      this.setDefaultOtherFilter();
    }
  }
}
