export enum dateFilterTypes {
    last30 = 'Last30',
    last7 = 'Last7',
    today = 'Today',
    yesterday = 'Yesterday',
    thisMonth = 'ThisMonth',
    lastMonth = 'LastMonth',
    ownRange = 'OwnRange',
    all = 'All',
    selectDateRange = 'SelectDateRange'
}
