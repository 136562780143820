import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyClipboardButtonDirective } from './copy-clipboard-button.directive';

@NgModule({
  exports: [CopyClipboardButtonDirective],
  declarations: [CopyClipboardButtonDirective],
  imports: []
})
export class CopyClipboardButtonModule { }
