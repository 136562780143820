interface IWorkingHours {
    hour_stop: string;
    hour_start: string;
    day_of_week: string;
}

interface ISpecialization {
    id_specialization: number;
    specialization_name: string;
}

interface IVacation {
    day_stop: string;
    day_start: string;
}

export interface Specialization {
    specialization: ISpecialization[];
    specialization_enable: boolean;
}

export interface AboutDC {
    about_dc_enable: boolean;
    about_dc_info: {
        about_dc: string
    };
}

export interface Main {
    address_city: string;
    address_local: string;
    address_street: string;
    address_zip_code: string;
    busines_type: string;
    email: string;
    id_describing_center: number;
    image_profile: string;
    krs: string;
    name: string;
    nip: string;
    phone_number: string;
    regon: string;
    contact_info_enable: boolean;
}

export interface PriceList {
    price_list_info: {
        id_price_list_list: number[],
        id_price_list_default: number
    };
    profile_price_list: boolean;
}

export interface Statistics {
    statistics_enable: boolean;
    statistics_info: {
        avarage_reaction_time: string,
        avarage_realization_time: string,
        orders_completed: string,
        rating: string
    };
}

export interface WorkingHourInfo {
    working_hours: {
        non_working_days: IVacation[],
        working_hour_list: IWorkingHours[]
    };
    working_hours_enable: boolean;
}

export interface IChangePrivacy {
    token: string;
    language: string;
    id_describing_center: number;
    section_name: string;
    value: boolean;
}

export interface IWorkingHourList {
    token: string;
    language: string;
    id_describing_center: number;
    working_hours: string;
}


interface IPrice {
    price: number;
    id_part: number;
    id_group: number;
}

interface IPriceList {
    prices: IPrice[];
    price_list_name: string;
    id_price_list_main: number;
}

export const mainInit: Main = {
    address_city: '',
    address_local: '',
    address_street: '',
    address_zip_code: '',
    busines_type: '',
    email: '',
    id_describing_center: 0,
    image_profile: '',
    krs: '',
    name: '',
    nip: '',
    phone_number: '',
    regon: '',
    contact_info_enable: false
};

export const aboutDCInit: AboutDC = {
    about_dc_enable: false,
    about_dc_info: {
        about_dc: ''
    }
};

export const specializationsInit: Specialization = {
    specialization: null,
    specialization_enable: false
};

export const priceListInit: PriceList = {
    price_list_info: {
        id_price_list_list: [],
        id_price_list_default: null
    },
    profile_price_list: false
};

export const statisticsInit: Statistics = {
    statistics_enable: false,
    statistics_info: {
        avarage_reaction_time: '',
        avarage_realization_time: '',
        orders_completed: '',
        rating: ''
    }
};

export const workingHourInfoInit: WorkingHourInfo = {
    working_hours: {
        non_working_days: null,
        working_hour_list: null
    },
    working_hours_enable: false
};

export const changePrivacyInit: IChangePrivacy = {
    token: '',
    language: '',
    id_describing_center: 0,
    section_name: '',
    value: false
};

export const workingHourListInit: IWorkingHourList = {
    token: '',
    language: '',
    id_describing_center: 0,
    working_hours: ''
};
