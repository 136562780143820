<div id="top-bar" style="z-index: 50;">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'ArchiveScreen.Title' | translate}}</h3>

            <ul id="top-bar-nav">
                <li class="selectRangeFont">{{'ArchiveScreen.SelectDateRange' | translate}}</li>
                <li>
                    <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" id="top-bar-wiecej" (click)="hideFilter()"
                        [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'">
                        {{dateFilter}}
                        <svg-icon alt="" class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg">
                        </svg-icon>
                    </a>
                    <div aria-labelledby="top-bar-wiecej" class="dropdown-menu">
                        <a id="today" class="dropdown-item"
                            (click)="filterOrdersByTime('today');">{{'ArchiveScreen.Today' | translate}}</a>
                        <a id="yesterday" class="dropdown-item"
                            (click)="filterOrdersByTime('yesterday');">{{'ArchiveScreen.Yesterday' | translate}}</a>
                        <a id="last7" class="dropdown-item"
                            (click)="filterOrdersByTime('last7');">{{'ArchiveScreen.Last7' | translate}}</a>
                        <a id="last30" class="dropdown-item"
                            (click)="filterOrdersByTime('last30');">{{'ArchiveScreen.Last30' | translate}}</a>
                        <a id="thisMonth" class="dropdown-item"
                            (click)="filterOrdersByTime('thisMonth');">{{'ArchiveScreen.ThisMonth' | translate}}</a>
                        <a id="lastMonth" class="dropdown-item"
                            (click)="filterOrdersByTime('lastMonth');">{{'ArchiveScreen.LastMonth' | translate}}</a>
                        <a id="all" class="dropdown-item"
                            (click)="filterOrdersByTime('all');">{{'ArchiveScreen.All' | translate}}</a>
                    </div>
                </li>
                <ng-container *ngFor="let filter of filterService.getMyFilters('archive'); let i = index">
                    <li *ngIf="filter.isFavourite"  id="myFilter{{i}}" [ngClass]="{'active': currentId == i.toString()}">
                        <a (click)="getOrders(i.toString())" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                           style="cursor: pointer;">{{filter.name}}</a>
                    </li>
                </ng-container>
                <li class="dropdown">
                    <a style="cursor: pointer;" id="otherFilters" data-toggle="dropdown" aria-haspopup="true"
                       aria-expanded="false" [style.background]="isActiveOtherFilter ? 'rgba(8, 72, 255, 0.1)' : 'none'"
                       [style.color]="isActiveOtherFilter ? '#0848FF' : '#444444'" (click)="hideFilter()">
                      {{moreFilters}}
                        <svg-icon alt="" class="svg" src="../../../../assets/images/icons/more-dots.svg"></svg-icon>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="otherFilters">
                        <ng-container *ngFor="let filter of filterService.getMyFilters('archive'); let i = index">
                            <a *ngIf="!filter.isFavourite" class="dropdown-item" id="myFilter{{i}}" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'"
                               (click)="getOrders(i.toString())" style="cursor: pointer">{{filter.name}}</a>
                        </ng-container>
                        <a routerLink="/manageMyFilters/archive" class="dropdown-item" style="cursor: pointer">
                          {{'DashboardScreen.Filters.ManageYourFilters' | translate}}
                        </a>
                    </div>
                </li>
            </ul>

            <form>
                <ul class="top-bar-nav">
                    <li (click)="dropdown()" class="filter_dropdown" [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'">
                        <a><span *ngIf="filters.filtersActiveDashboard" class="dot"></span>
                          {{'DashboardScreen.Filters.FilterSort' | translate}}
                            <svg-icon alt="" class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg">
                            </svg-icon>
                        </a>
                    </li>
                </ul>
                <app-search [style.pointer-events]="isEmptyDashboard ? 'none' : 'all'" #search placeholder="SearchPlaceholder"></app-search>
            </form>

        </div>
    </div>

</div>

<app-filters (filter)="filterOrders($event);" #filters type="ARCHIVE"></app-filters>

<app-loader #loader></app-loader>

<app-orders-list #ordersList [orders]="ordersService.ordersArchive" [hours]="ordersService.hoursArchive" [dates]="ordersService.datesArchive" [age]="ordersService.ageArchive" type="Archive"></app-orders-list>

<app-pagination #pagination componentName="Archive" [hidden]="ordersService.ordersArchive.length == 0"></app-pagination>

<app-empty-orders-list [mode]="'DC'" [page]="'archive'" [ordersCount]="ordersService.ordersArchive.length" [isLoaderShow]="loader.show" [isSearched]="search.isSearch" [isFiltered]="filters.filtersActiveDashboard || currentId != ''"></app-empty-orders-list>

<app-pdf-not-available-modal #pdfModal></app-pdf-not-available-modal>
