import { Component, EventEmitter, OnInit } from '@angular/core';
import { IFacilityExams, initFacilityExam, initOrder, IOrder } from '@/interfaces';
import { FacilityOrdersService } from '@/services/facility-orders.service';
import { CovidAIStatistics, initCovidStats, setCovidStatistics } from '@/interfaces/AIConsultation';

@Component({
  selector: 'app-consultation-view-modal',
  templateUrl: './consultation-view-modal.component.html',
  styleUrls: ['./consultation-view-modal.component.scss']
})
export class ConsultationViewModalComponent implements OnInit {

  createOrderEvent: EventEmitter<any> = new EventEmitter<any>();
  exam: IFacilityExams = initFacilityExam;
  order: IOrder = initOrder;
  stats: CovidAIStatistics = initCovidStats;
  orderInput: boolean = false;

  constructor(public facilityOrdersService: FacilityOrdersService) { }

  ngOnInit() {
  }

  show(input?: IFacilityExams, orderInput?: IOrder) {
    if (input) {
      this.exam = input;
      this.stats = setCovidStatistics(input);
      this.orderInput = false;
    } else {
      this.order = orderInput;
      this.stats = setCovidStatistics(undefined, undefined, orderInput);
      this.orderInput = true;
    }
    $('#consultationViewModal').modal('show');
  }

  onCreateOrder() {
    this.createOrderEvent.emit(this.exam.id_exam);
    $('#consultationViewModal').modal('hide');
  }
}
