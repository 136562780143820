import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UsersService, OdataService, SessionService } from '@/services';
import { Router } from '@angular/router';
import { RegistrationService } from '@/services/registration.service';
import { AlertComponent } from '@/utils';
import { PageService } from '@/services/page.service';

interface IForgotLoginStep1 {
    value: string;
    session_id: string;
    language: string;
}

@Component({
    selector: 'app-forgot-login-first',
    templateUrl: './forgot-login-first.component.html',
    styleUrls: ['./forgot-login-first.component.css']
})
export class ForgotLoginFirstComponent implements OnInit, OnDestroy {

    @ViewChild('alert') alert: AlertComponent;

    params: IForgotLoginStep1 = {
        value: '',
        session_id: '',
        language: ''
    };
    result: {
        'Status': string,
        'ErrorStatus': string
    };

    isError: boolean = false;
    alertText: string;
    userService: UsersService;

    constructor(
        private odataService: OdataService,
        userService: UsersService,
        private router: Router,
        private session: SessionService,
        private registrationService: RegistrationService,
        private pageService: PageService) {
        this.userService = userService;
    }

    ngOnInit() {
        this.params.session_id = this.session.getSessionId();
        this.params.language = this.userService.iLanguage;
        this.registrationService.isLogout = false;
        this.pageService.setTranslatedPageTitle('ForgotLoginScreen.Title', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

    forgoten_login_step1() {
        this.odataService.postPublic('forgoten_password_step1', this.params,
            res => {
                let returnedJson = JSON.stringify(res);
                this.result = JSON.parse(returnedJson);
                if (this.result.Status == 'OK') {
                    this.router.navigate(['/confirmMail']);
                } else {
                    this.handleServerError();
                }
            });
    }

    handleServerError() {
        const errors = {
            'PASSWORD_LINK_EXPIRED': 'PASSWORD_LINK_EXPIRED',
            'OTHER_REASON': 'OTHER_REASON_FPF',
            'ACCOUNT_DISABLED': 'ACCOUNT_DISABLED'
        };

        let error: string = this.result.Status;
        if (error != 'OTHER_REASON') {
            error = this.result.ErrorStatus;
        }

        this.alert.displayError(errors[error]);
    }

}
