<div aria-hidden="true" aria-labelledby="patientExamUploadModalLabel" class="modal fade" id="patientExamUploadModal" role="dialog"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h2 class="modal-title mb-4" id="patientExamUploadModalLabel" style="margin-bottom: 0;">
          {{'UploadExamModal.Title'|translate}}
        </h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <input [hidden]="uploadCompleted || uploadStarted" accept=".zip,.rar,.7zip" name="file" type="file" #file id="fileInput">
        <div *ngIf="!uploadCompleted">
        <br class="hideOnProgress" />
        <div *ngFor="let fileStatus of uploadProgress | async">
          <progress [value]="fileStatus.progress" max="100" id="tus-progress"></progress>
          <p style="text-align: center; margin-bottom: 60px;">{{'UploadExamModal.Progress'|translate}}: {{fileStatus.progress}}%</p>

        </div>
        </div>
        <div [hidden]="!uploadCompleted">

          <div class="pp--columns vertical-center">
            <div class="pp--column" style="min-width: 280px;">
              <img src="../../../../assets/images/pp/transfer-ok.png" alt="">
            </div>
            <div class="pp--column">
              <h3>{{'UploadExamModal.SuccessSubtitle'|translate}}</h3>
              <p>{{'UploadExamModal.SuccessText'|translate}}</p>
            </div>
          </div>

        </div>

      </div>

      <div class="modal-footer" style="padding-top: 30px;">
        <button [disabled]="file.value === null" *ngIf="!uploadCompleted && !uploadStarted" type="button" (click)="upload(file.files);" class="btn btn-primary btn-lg" style="margin-bottom: 30px;">
          {{'UploadExamModal.StartTransfer'|translate}}
        </button>
      </div>

    </div>
  </div>
</div>
<app-alert-modal #alertModalComponent [modalConfig]="alertModalConfig" (alertClosed)="hide()"></app-alert-modal>
