import { element } from 'protractor';
import { MessageSenderService } from './../../services/message-sender.service';
import { EnvService } from '@/services/env.service';
import { Profile } from '@/interfaces';
import { Component, OnInit, OnDestroy, ViewChild, Input, ElementRef } from '@angular/core';
import { UsersService, OdataService } from '@/services';
import { NgForm } from '@angular/forms';
import { UniversalToastComponent } from '../universal-toast/universal-toast.component';

@Component({
  selector: 'app-send-message-to-user-modal',
  templateUrl: './send-message-to-user-modal.component.html',
  styleUrls: ['./send-message-to-user-modal.component.scss']
})
export class SendMessageToUserModalComponent implements OnInit, OnDestroy {

    @Input() actionToast: UniversalToastComponent;
    @ViewChild('messageForm', { read: ElementRef }) messageForm: ElementRef;
    user: Profile;

    constructor(public userService: UsersService, public odataService: OdataService,
                public envService: EnvService, public messageSender: MessageSenderService) {
    }

    ngOnInit() {
    }

    clearData() {
      this.messageSender.setDefaultData();
      this.messageForm.nativeElement.elements[0].value = '';
    }

    ngOnDestroy() {
    }

    show(): void {
        this.clearData();
        $('#sendMessageModal').modal('show');
    }

    hide(): void {
        $('#sendMessageModal').modal('hide');
    }

    setUser(profile: Profile) {
      this.user = profile;
    }

    onSubmit (form: NgForm) {
        this.messageSender.onSubmit(this.user.id_physician, form.value.message_text).then((res) => {
            if (res) {
                this.hide();
            } else {
                this.actionToast.error('OperationFailed');
                this.actionToast.show();
                setTimeout(() => this.actionToast.hide(), 1000);
            }
        });
    }
}
