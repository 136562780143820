import { EventEmitter, OnInit } from '@angular/core';
import * as i0 from "@angular/core";
var TimerLabelService = /** @class */ (function () {
    function TimerLabelService() {
        this.timeLabel = '';
        this.doneLabel = '';
        this.timeOutEmitter = new EventEmitter();
    }
    TimerLabelService.prototype.ngOnInit = function () {
    };
    TimerLabelService.prototype.startCountdown = function (minutes, seconds) {
        clearTimeout(this.timeout);
        this.minutes = minutes;
        this.seconds = seconds;
        this.countTime();
    };
    TimerLabelService.prototype.countTime = function () {
        var _this = this;
        if (this.minutes >= 0 && this.seconds >= 0) {
            this.timeout = setTimeout(function () {
                _this.countTime();
            }, 1000);
            this.upadteTimeLabel();
            this.seconds--;
            if (this.seconds === -1) {
                this.seconds = 59;
                this.minutes--;
            }
            if (this.minutes <= 0 && this.seconds <= 0) {
                this.endCountdown();
                this.timeOutEmitter.emit(true);
            }
        }
    };
    TimerLabelService.prototype.upadteTimeLabel = function () {
        if (this.minutes < 10) {
            this.timeLabel = '0' + this.minutes;
        }
        else {
            this.timeLabel = '' + this.minutes;
        }
        this.timeLabel += ':';
        if (this.seconds < 10) {
            this.timeLabel += '0' + this.seconds;
        }
        else {
            this.timeLabel += this.seconds;
        }
    };
    TimerLabelService.prototype.endCountdown = function () {
        clearTimeout(this.timeout);
        this.timeLabel = this.doneLabel;
    };
    TimerLabelService.prototype.setTime = function (minutes, seconds) {
        this.minutes = minutes;
        this.seconds = seconds;
    };
    TimerLabelService.ngInjectableDef = i0.defineInjectable({ factory: function TimerLabelService_Factory() { return new TimerLabelService(); }, token: TimerLabelService, providedIn: "root" });
    return TimerLabelService;
}());
export { TimerLabelService };
