import { UsersService, OdataService, SessionService } from '.';
import { EnvService } from './env.service';
import * as i0 from "@angular/core";
import * as i1 from "./users.service";
import * as i2 from "./odata.service";
import * as i3 from "./session.service";
import * as i4 from "./env.service";
var MessageSenderService = /** @class */ (function () {
    function MessageSenderService(userService, odataService, sessionService, envService) {
        this.userService = userService;
        this.odataService = odataService;
        this.sessionService = sessionService;
        this.envService = envService;
        this.validUser = true;
        this.validText = true;
        this.validAttachments = true;
        this.attachments = [];
        this.c_attachments = [];
    }
    MessageSenderService.prototype.setDefaultData = function () {
        this.validUser = true;
        this.validText = true;
        this.validAttachments = true;
        this.attachments = [];
    };
    MessageSenderService.prototype.onSubmit = function (userId, messageText, attachments, idReply) {
        if (userId) {
            this.validUser = true;
        }
        else {
            this.validUser = false;
        }
        if (messageText && messageText !== '') {
            this.validText = true;
        }
        else {
            this.validText = false;
        }
        if (attachments) {
            this.c_attachments = attachments;
            if (attachments.length > 0 && !this.validText) {
                this.validText = true;
                messageText = ' ';
            }
        }
        var valid = true;
        var attachment_list = [];
        var attachmentTmp = attachments ? this.c_attachments : this.attachments;
        for (var i = 0; i < attachmentTmp.length; i++) {
            if (attachmentTmp[i].status != 'ok') {
                valid = false;
                break;
            }
            attachment_list.push(attachmentTmp[i].id);
        }
        this.validAttachments = valid;
        if (this.validUser && this.validText && this.validAttachments) {
            return this.odataService.postPrivate('create_message', this.odataService.paramsTokenAndLang({
                message: messageText,
                recipient_user_id: userId,
                id_reply: idReply ? idReply : null,
                attachment_list: attachment_list.length === 0 ? null : JSON.stringify(attachment_list)
            }), function (res) {
                var returnedJson = JSON.stringify(res);
                var result = JSON.parse(returnedJson);
                if (result.value === 'OK') {
                    return true;
                }
                else
                    return false;
            });
        }
        return Promise.resolve();
    };
    MessageSenderService.prototype.onFileSelected = function (event, attachments) {
        var files = event.target.files;
        var attachmentTmp = attachments ? this.c_attachments : this.attachments;
        var lengthTmp = attachments ? 3 : 10;
        if (files) {
            for (var i = 0; i < files.length; i++) {
                if (attachmentTmp.length > lengthTmp) {
                    return;
                }
                var attched = {
                    id: '',
                    name: files[i].name,
                    status: 'upload',
                    type: files[i].type
                };
                if (attachments) {
                    this.c_attachments.push(attched);
                }
                else
                    this.attachments.push(attched);
                if (files[i].size > 10485760) {
                    attched.status = 'invalid';
                    continue;
                }
                var reader = new FileReader();
                reader.onload = this.handleFile.bind(this, attched);
                reader.readAsBinaryString(files[i]);
            }
        }
    };
    MessageSenderService.prototype.handleFile = function (attched, event) {
        var binaryString = event.target.result;
        var base64textString = btoa(binaryString);
        base64textString = base64textString.replace(/\+/gi, '-');
        base64textString = base64textString.replace(/\//gi, '_');
        this.uploadAttachmentFile(base64textString, attched);
    };
    MessageSenderService.prototype.uploadAttachmentFile = function (base64, attched) {
        this.odataService.postPrivate('upload_message_attachment', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            base64: base64,
            filename: attched.name,
            content_type: attched.type
        }, function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status === 'OK') {
                attched.id = result.Value;
                attched.status = 'ok';
            }
            else {
                attched.status = 'invalid';
                // TODO:
                console.log('upload_message_attachment', result.Status);
            }
        });
    };
    MessageSenderService.prototype.onRemoveFile = function (index, event, attachments) {
        if (event)
            event.stopPropagation();
        if (attachments) {
            this.c_attachments.splice(index, 1);
        }
        else
            this.attachments.splice(index, 1);
    };
    MessageSenderService.ngInjectableDef = i0.defineInjectable({ factory: function MessageSenderService_Factory() { return new MessageSenderService(i0.inject(i1.UsersService), i0.inject(i2.OdataService), i0.inject(i3.SessionService), i0.inject(i4.EnvService)); }, token: MessageSenderService, providedIn: "root" });
    return MessageSenderService;
}());
export { MessageSenderService };
