<div>
    <div class="col-col-md-12" id="top-bar">
        <div class="row w-100">
            <div class="col-md-12 pl-0 pr-0 d-flex align-items-center my-auto w-100">
                <a class="back" routerLink="/dashboard/">
                    <svg-icon alt="" class="svg" src="../../../../assets/images/icons/topbar/icon__arrow_left.svg">
                    </svg-icon>
                </a>

                <h3 class="page-name">
                    {{'ExaminationScreen.ExaminationId' | translate}} {{examination.examinationId}}
                </h3>

                <ul class="ml-auto d-flex my-auto align-items-center" id="top-bar-nav">
                    <li class="dropdown">
                        <button aria-expanded="false" aria-haspopup="true"
                            class="btn btn-wybierz-opis  dropdown-toggle dropdown-toggle-arrow" data-toggle="dropdown"
                            id="ddWybierzOpis" type="button"
                            style="border: none !important; box-shadow: none !important;">
                            <span class="blue">{{'ExaminationScreen.ChooseDescription' | translate}}</span><br />
                            {{'ExaminationScreen.DescriptionFromDay' | translate}}
                            {{examination.descriptions[chosenDescription].date}}
                            <span class="light">({{examination.descriptions[chosenDescription].physicianName}})</span>

                        </button>
                        <div aria-labelledby="ddWybierzOpis" class="dropdown-menu dropdown-menu-left"
                            x-placement="bottom-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(25px, 77px, 0px);">
                            <ng-container *ngFor="let desc of examination.descriptions; let i = index;">
                                <a (click)="changeChosen(i)"
                                    *ngIf="i != chosenDescription && desc.status == 'Dostępny'">
                                    {{'ExaminationScreen.DescriptionFromDay' | translate}} {{desc.date}}<span
                                        class="light">({{desc.physicianName}})</span>
                                </a>
                            </ng-container>
                        </div>
                    </li>
                </ul>

            </div>
        </div>

    </div>

    <div class="row mt-5">
        <div class="col-md-3">
            <div class="card">
                <img alt="" class="rounded-top" src="../../../../../assets/images/_dummy_data/dummy_rtg-big.png">

                <div class="card-body">

                    <div class="clearfix mt-4 mb-4">
                        <div class="modality float-left mr-3" id="modalityDiv">{{examination.modality}}</div>
                        <div>
                            <strong style="position: relative; top: 8px;">{{examination.examinationName}}</strong>
                        </div>
                    </div>

                    <p><span
                            class="light">{{'ExaminationScreen.ExaminationDate' | translate}}</span><br /><strong>{{examination.date}}</strong>
                    </p>
                    <hr />
                    <p><span
                            class="light">{{'ExaminationScreen.Address' | translate}}</span><br /><strong>{{examination.centre}}</strong><br />
                    </p>
                    {{examination.address}}
                    <hr />
                    <p><span
                            class="light">{{'ExaminationScreen.Status' | translate}}</span><br /><strong>{{examination.status}}</strong>
                    </p>
                    <hr />
                    <p><strong>{{'ExaminationScreen.ShortDescription' | translate}}</strong><br />
                        {{examination.shortDescription}}
                    </p>
                    <hr />
                    <p><strong>{{examination.icd}}</strong><br />
                        {{examination.icdDescription}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="card big-card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">
                            <h2 class="mb-4 mt-4">{{'ExaminationScreen.DescriptionOfExaminationDay' | translate}}
                                {{examination.descriptions[chosenDescription].date}}</h2>
                            <p [innerHTML]="examination.descriptions[chosenDescription].mainDescription"></p>

                            <div class="actions mt-5">
                                <a class="btn btn-lg btn-grey"
                                    style="margin-top: 15px;">{{'ExaminationScreen.DownloadOrPrint' | translate}}</a>
                                <a class="btn btn-lg btn-grey"
                                    style="margin-top: 15px;">{{'ExaminationScreen.Save' | translate}}</a>
                                <a class="btn btn-lg btn-grey"
                                    style="margin-top: 15px;">{{'ExaminationScreen.OrderCD' | translate}}</a>
                                <a class="btn btn-lg btn-success" routerLink="/dashboard"
                                    style="color: white; margin-top: 15px;">{{'ExaminationScreen.NextDescription' | translate}}</a>
                            </div>

                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3">
                            <img alt="" class="rounded mt-4" src="../../../../../assets/images/_dummy_data/avatar_big.jpg">

                            <p class="mt-4 text-center">{{'ExaminationScreen.DescribingPhysician' | translate}}<br />
                                <strong>{{examination.descriptions[chosenDescription].physicianName}}</strong><br />
                                <span
                                    class="light">{{examination.descriptions[chosenDescription].physicianSpecialization}}</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="card big-card no-bg p-0 mt-4">
                <div class="card-body">
                    <h3 class="pl-4">{{'ExaminationScreen.OtherDescriptions' | translate}}</h3>
                    <p class="pl-4">{{'ExaminationScreen.NotificationInfo' | translate}}</p>
                    <table class="tile-table classic-table alt-table p-0">
                        <thead>
                            <tr>
                                <th>{{'ExaminationScreen.DateOfOrder' | translate}}</th>
                                <th>{{'ExaminationScreen.DescribingPhysician' | translate}}</th>
                                <th>{{'ExaminationScreen.Status' | translate}}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let desc of examination.descriptions; let i = index;" class="tile">
                                <ng-container *ngIf="chosenDescription != i">
                                    <td>{{desc.dateOfOrder}}</td>
                                    <td><img alt=""
                                            src="../../../../../assets/images/_dummy_data/avatar_big.jpg">{{desc.physicianName}}
                                    </td>
                                    <td>
                                        <span *ngIf="desc.status == 'Dostępny'" class="dot green mr-2"></span>
                                        <span *ngIf="desc.status == 'W trakcie opisu'" class="dot orange mr-2"></span>
                                        {{desc.status}}
                                    </td>
                                    <td>
                                        <a *ngIf="desc.status == 'Dostępny'"
                                            class="btn btn-grey float-right mr-4">{{'ExaminationScreen.More' | translate}}</a>
                                        <a (click)="changeChosen(i)" *ngIf="desc.status == 'Dostępny'"
                                            class="btn btn-primary float-right mr-4" style="color: white;">
                                            {{'ExaminationScreen.SeeOtherDescription' | translate}}
                                        </a>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    </div>
</div>
