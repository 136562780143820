import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DoubleSelectModalComponent } from '@/utils/double-select-modal/double-select-modal.component';
import { OdataService, SessionService, UsersService } from '@/services';
import { Observable, of } from 'rxjs';
import { EnvService } from '@/services/env.service';
import { PageService } from '@/services/page.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TypeaheadMatch } from 'ngx-bootstrap';

@Component({
    selector: 'app-medical-facility-users-settings',
    templateUrl: './medical-facility-users-settings.component.html',
    styleUrls: ['./medical-facility-users-settings.component.scss']
})
export class MedicalFacilityUsersSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('editRole') editRole: DoubleSelectModalComponent;

    odataService: OdataService;
    userService: UsersService;

    settings_users: any[];

    // Search
    // typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
    valid_search = true;
    asyncSelected: string;
    dataSource: Observable<any>;
    typeaheadLoading: boolean;
    user_name_to_invite: string = '';
    search_value: number = undefined;
    private search_text: string = '';
    private user_to_invite: number = undefined;
    private from_search: boolean = false;

    // timer
    timeout: any;

    constructor(userService: UsersService, odataService: OdataService, private sessionService: SessionService,
        private env: EnvService, private pageService: PageService) {
        this.odataService = odataService;
        this.userService = userService;

        this.dataSource = new Observable((observer: any) => {
            // Runs on every search
            observer.next(this.asyncSelected);
        })
            .pipe(
                mergeMap((token: string) => this.getDataAsObservable(token)),
                catchError(this.handleError)
            );
    }

    ngOnInit() {
        this.pageService.setSettingPageTranslatedTitle('SettingsScreens.Users', 0);
        document.getElementById('facilitySettings').setAttribute('class', 'active');
        this.get_co_staffs();
        this.starTimer();
    }

    ngOnDestroy() {
        this.stopTimer();
        this.pageService.resetPageTitle();
        document.getElementById('facilitySettings').setAttribute('class', '');
    }

    // Search
    // fired every time search string is changed
    getDataAsObservable(search: string): Observable<any> {
        if (search.length < 3) {
            return of<any>([]);
        }

        let result = {
            Status: 'OK',
            Value: '[]'
        };

        this.dataMap(result);
    }

    dataMap(result) {
        let to_return = [];

        if (result.Status === 'OK') {
            let users: any[] = JSON.parse(result.Value);

            for (let i = 0; i < users.length; i++) {
                if ('profile_image_uuid' in users[i] && users[i].profile_image_uuid !== null && users[i].profile_image_uuid !== '') {
                    users[i]['profile_image'] = this.env.profilePicURL + users[i].profile_image_uuid;
                } else {
                    users[i]['profile_image'] = '../../../../../assets/images/icons/user.svg';
                }
            }

            to_return = users;
        } else {
            console.log('REASON:', result.Status);
        }

        return to_return;
    }

    handleError(error: HttpErrorResponse) {
        console.log(error);
        return of([]);
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        // this.typeaheadIcon = e.item.profile_image;
        this.search_value = e.item.id_user;
        this.search_text = e.item.username;
    }

    onValueChange() {
        if (this.search_value !== undefined) {
            // this.typeaheadIcon = '../../assets/images/icons/topbar/icon__search.svg';
            this.search_value = undefined;
            this.search_text = '';
        }
    }

    // Search END

    get_co_staffs() {
        this.settings_users = [
            {
                id_status: 3,
                id_user: 574,
                loginname: 'zak+test27@impicode.pl',
                profile_image: 'https://files.emkaweb.pl/profile/d7d2e68b-f639-45ce-aa78-156fbe3b40e8',
                profile_image_uuid: 'd7d2e68b-f639-45ce-aa78-156fbe3b40e8',
                pwz: '12121212',
                status_name: 'WAITING',
                status_text: 'Oczekujący',
                username: 'Zachariasz Karpowicz'
            },
            {
                id_status: 1,
                id_user: 521,
                loginname: 'p.filipczuk@filsoft.pl',
                profile_image: 'https://files.emkaweb.pl/profile/a54f739e-225d-4c9a-9492-1e29569e893b',
                profile_image_uuid: 'a54f739e-225d-4c9a-9492-1e29569e893b',
                pwz: '12121212',
                status_name: 'ACTIVE',
                status_text: 'Aktywny',
                username: 'Piotr Filipczuk'
            },
            {
                id_status: 1,
                id_user: 523,
                loginname: 'tys@impicode.pl',
                profile_image: 'https://files.emkaweb.pl/profile/f8c06aa4-e973-473c-8540-712c15e059fd',
                profile_image_uuid: 'f8c06aa4-e973-473c-8540-712c15e059fd',
                pwz: '12121212',
                status_name: 'ACTIVE',
                status_text: 'Aktywny',
                username: 'Jan Kowalski'
            }
        ];
    }

    co_save_user_role_assign(id_user: number, roless: number[]) {
        this.editRole.hide();
    }

    onCooperationInvite() {
        if (this.search_value !== undefined) {
            this.from_search = true;
            this.user_to_invite = this.search_value;
            this.user_name_to_invite = this.search_text;
            $('#wyslanieZaproszeniaDoWspolpracyModal').modal('show');
        }
    }

    onCloseInvitation() {
        this.user_to_invite = undefined;
        this.user_name_to_invite = '';
        (<HTMLInputElement>document.getElementById('invitation_message_textarea')).value = '';
    }

    onEditRole(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.editRole.setId('' + user.id_user);
        this.editRole.setSubtitle(user.username);

        let not_granted: any[] = [
            { id: 548, text: 'Robert Frankowski', img_src: '../../assets/images/icons/user.svg' },
            { id: 585, text: 'Zachariasz Karpowicz', img_src: 'https://files.emkaweb.pl/miniprofile/651a1c72-120e-46a9-9019-d6a913805dcc' },
            { id: 574, text: 'Zachariasz Karpowicz', img_src: 'https://files.emkaweb.pl/miniprofile/d7d2e68b-f639-45ce-aa78-156fbe3b40e8' },
            { id: 643, text: 'Marko Polio', img_src: '../../assets/images/icons/user.svg' },
            { id: 674, text: 'Jan Kowalski', img_src: '../../assets/images/icons/user.svg' }
        ];
        let granted: any[] = [
            { id: 577, text: 'Marek Targonski', img_src: 'https://files.emkaweb.pl/miniprofile/af2b2d58-885d-4fe0-99c0-65f1b02a856b' },
            { id: 551, text: 'Paweł Kowalski', img_src: '../../assets/images/icons/user.svg' },
            { id: 575, text: 'Zachariasz Karpowicz', img_src: '../../assets/images/icons/user.svg' },
            { id: 584, text: 'Zachariasz Karpowicz', img_src: '../../assets/images/icons/user.svg' },
            { id: 586, text: 'Zachariasz Karpowicz', img_src: '../../assets/images/icons/user.svg' },
            { id: 521, text: 'Piotr Filipczuk', img_src: 'https://files.emkaweb.pl/miniprofile/a54f739e-225d-4c9a-9492-1e29569e893b' },
            { id: 523, text: 'Jan Kowalski', img_src: 'https://files.emkaweb.pl/miniprofile/f8c06aa4-e973-473c-8540-712c15e059fd' }
        ];

        this.editRole.setContent(not_granted, granted);
        this.editRole.show();
    }

    onSelect(event: any) {
        if (event === undefined) {
            return;
        }

        if (!('id' in event) || !('selected' in event)) {
            return; // nie powinno mieć miejsca ale się zabezpieczamy.
        }

        const id_role = +event.id;
        let users: number[] = [];

        for (let i = 0; i < event.selected.length; i++) {
            if ('id' in event.selected[i]) {
                users.push(+event.selected[i].id);
            }
        }

        this.co_save_user_role_assign(id_role, users);
    }

    onBlock(user: any) {
        if (user.id_user === undefined) {
            return;
        }
        this.realoadList();
    }

    onUnBlock(user: any) {
        if (user.id_user === undefined) {
            return;
        }
        this.realoadList();
    }

    onDelete(user: any) {
        if (user.id_user === undefined) {
            return;
        }
        this.realoadList();
    }

    onInviteAgain(user: any) {
        if (user.id_user !== undefined) {
            this.user_to_invite = user.id_user;
            this.user_name_to_invite = user.username;
            $('#wyslanieZaproszeniaDoWspolpracyModal').modal('show');
        }
    }

    onCancelInvitation(user: any) {
        if (user.id_user === undefined) {
            return;
        }
        this.realoadList();
    }

    onAcceptApplication(user: any) {
        if (user.id_user === undefined) {
            return;
        }
        this.realoadList();
    }

    onInvitationSend() {
        const message_text: string = (<HTMLInputElement>document.getElementById('invitation_message_textarea')).value;
        $('#wyslanieZaproszeniaDoWspolpracyModal').modal('hide');
        this.clearAfterInvite();
        this.realoadList();
    }

    clearAfterInvite() {
        this.user_to_invite = undefined;
        this.user_name_to_invite = '';

        if (this.from_search) {
            this.from_search = false;
            this.asyncSelected = '';
            this.onValueChange();
        }
    }

    // timer
    realoadList() {
        this.restarTimer();
        this.get_co_staffs();
    }

    starTimer() {
        this.restarTimer();
    }

    stopTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    private restarTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.realoadList();
        }, 60000);
    }
}
