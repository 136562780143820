import { EventEmitter, Injectable, OnInit } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimerLabelService implements OnInit {
    minutes: number;
    seconds: number;
    timeout;
    timeLabel: string = '';
    doneLabel: string = '';
    timeOutEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
    }

    startCountdown(minutes: number, seconds: number): void {
        clearTimeout(this.timeout);
        this.minutes = minutes;
        this.seconds = seconds;
        this.countTime();
    }

    countTime(): void {
        if (this.minutes >= 0 && this.seconds >= 0) {
            this.timeout = setTimeout(() => {
                this.countTime();
            }, 1000);
            this.upadteTimeLabel();
            this.seconds--;
            if (this.seconds === -1) {
                this.seconds = 59;
                this.minutes--;
            }
            if (this.minutes <= 0 && this.seconds <= 0) {
                this.endCountdown();
                this.timeOutEmitter.emit(true);
            }
        }
    }

    upadteTimeLabel() {
        if (this.minutes < 10) {
            this.timeLabel = '0' + this.minutes;
        } else {
            this.timeLabel = '' + this.minutes;
        }
        this.timeLabel += ':';
        if (this.seconds < 10) {
            this.timeLabel += '0' + this.seconds;
        } else {
            this.timeLabel += this.seconds;
        }
    }

    endCountdown() {
        clearTimeout(this.timeout);
        this.timeLabel = this.doneLabel;
    }

    setTime(minutes: number, seconds: number) {
        this.minutes = minutes;
        this.seconds = seconds;
    }
}
