/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reopen-exam-toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./reopen-exam-toast.component";
var styles_ReopenExamToastComponent = [i0.styles];
var RenderType_ReopenExamToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReopenExamToastComponent, data: {} });
export { RenderType_ReopenExamToastComponent as RenderType_ReopenExamToastComponent };
export function View_ReopenExamToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["aria-atomic", "true"], ["aria-live", "assertive"], ["class", "toast fade success hide"], ["data-autohide", "false"], ["id", "reopen-exam-toast"], ["role", "alert"], ["style", "background-color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "toast-body text-center pt-4 pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "info"], ["style", "color: var(--blue);"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("OrderDescriptionScreens.ExamReopenTitle")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("OrderDescriptionScreens.ExamReopenMessage")); _ck(_v, 5, 0, currVal_1); }); }
export function View_ReopenExamToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reopen-exam-toast", [], null, null, null, View_ReopenExamToastComponent_0, RenderType_ReopenExamToastComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReopenExamToastComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReopenExamToastComponentNgFactory = i1.ɵccf("app-reopen-exam-toast", i3.ReopenExamToastComponent, View_ReopenExamToastComponent_Host_0, {}, {}, []);
export { ReopenExamToastComponentNgFactory as ReopenExamToastComponentNgFactory };
