import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DoubleSelectModalComponent } from 'src/app/utils/double-select-modal/double-select-modal.component';
import { NameAndDescriptionModalComponent } from 'src/app/utils/name-and-description-modal/name-and-description-modal.component';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-team-settings',
    templateUrl: './team-settings.component.html',
    styleUrls: ['./team-settings.component.css']
})
export class TeamSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('editMembers') editMembers: DoubleSelectModalComponent;
    @ViewChild('editRoles') editRoles: DoubleSelectModalComponent;
    @ViewChild('editTeam') editTeam: NameAndDescriptionModalComponent;

    constructor(private pageService: PageService) {
    }

    ngOnInit() {
        this.editMembers.setContent(
            [{ id: 1, text: 'Dorsz' },
            { id: 2, text: 'Halibut' },
            { id: 3, text: 'Morszczuk' },
            { id: 4, text: 'Dorada' },
            { id: 5, text: 'Mintaj' },
            { id: 6, text: 'Łosoś' },
            { id: 7, text: 'Pstrąg' },
            { id: 8, text: 'Labraks' },
            { id: 9, text: 'Szprot' },
            { id: 10, text: 'Węgorz' },
            { id: 11, text: 'Kergulena' },
            { id: 12, text: 'Płoć' },
            { id: 13, text: 'Dorada' },
            { id: 14, text: 'Flądra' }],
            [{ id: 2, text: 'Sardynka' },
            { id: 2, text: 'Lin' },
            { id: 2, text: 'Sola' }]);
        this.editMembers.setId('members');

        this.editRoles.setContent(
            [{ id: 1, text: 'Dorsz' },
            { id: 2, text: 'Halibut' },
            { id: 3, text: 'Morszczuk' },
            { id: 4, text: 'Dorada' },
            { id: 5, text: 'Mintaj' },
            { id: 6, text: 'Łosoś' },
            { id: 7, text: 'Pstrąg' },
            { id: 8, text: 'Labraks' },
            { id: 9, text: 'Szprot' },
            { id: 10, text: 'Węgorz' },
            { id: 11, text: 'Kergulena' },
            { id: 12, text: 'Płoć' },
            { id: 13, text: 'Dorada' },
            { id: 14, text: 'Flądra' }],
            [{ id: 2, text: 'Sardynka' },
            { id: 2, text: 'Lin' },
            { id: 2, text: 'Sola' }]);
        this.editRoles.setId('roles');

        this.editTeam.setContent('Edytuj nazwę zespołu lub opis', 'Nazwa zespołu', 'Opis zespołu');

        this.pageService.setSettingPageTranslatedTitle('SettingsScreens.Teams', 0);
        document.getElementById('settings').setAttribute('class', 'active');
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
        document.getElementById('settings').setAttribute('class', '');
    }

}
