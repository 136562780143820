/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./taking-description.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../alert/alert.component.ngfactory";
import * as i5 from "../alert/alert.component";
import * as i6 from "../../services/session.service";
import * as i7 from "@angular/common";
import * as i8 from "./taking-description.component";
import * as i9 from "../../services/order-description.service";
var styles_TakingDescriptionComponent = [i0.styles];
var RenderType_TakingDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TakingDescriptionComponent, data: {} });
export { RenderType_TakingDescriptionComponent as RenderType_TakingDescriptionComponent };
function View_TakingDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "form", [["autocomplete", "off"], ["class", "mt-4"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "input", [["readonly", ""], ["style", "display:none;"], ["type", "password"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "form-group"], ["style", "padding-bottom: 0; margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "bigLabel"], ["for", "passwordInput"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 6, "input", [["autocomplete", "off"], ["id", "passwordInput"], ["name", "passInput"], ["type", "password"], ["value", ""]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.onKeyUp($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(12, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [["id", "passwordInputError"], ["style", "display: none;"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-success btn-lg"], ["id", "ok-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takeover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_16 = "passInput"; var currVal_17 = _co.password; _ck(_v, 12, 0, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("AccountProperties.GetPasswordLabel")); _ck(_v, 7, 0, currVal_7); var currVal_8 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 15).transform("AccountProperties.Password")), ""); var currVal_9 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 14).ngClassValid; var currVal_14 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_18 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Errors.InvalidPass")); _ck(_v, 16, 0, currVal_18); }); }
export function View_TakingDescriptionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { alert: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["aria-hidden", "true"], ["aria-labelledby", "takeDescriptionModalLabel"], ["class", "modal fade"], ["id", "takeDescriptionModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h2", [["class", "modal-title mb-4"], ["id", "takeDescriptionModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["style", "margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-light btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-light btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isTaking = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-alert", [["title", "Takeover"]], null, null, null, i4.View_AlertComponent_0, i4.RenderType_AlertComponent)), i1.ɵdid(20, 770048, [[1, 4], ["alert", 4]], 0, i5.AlertComponent, [i3.TranslateService, i6.SessionService], { title: [0, "title"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TakingDescriptionComponent_1)), i1.ɵdid(22, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "Takeover"; _ck(_v, 20, 0, currVal_3); var currVal_4 = _co.isTaking; _ck(_v, 22, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("TakeoverModal.Takeover")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("TakeoverModal.GoToView")); _ck(_v, 14, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("TakeoverModal.Takeover")); _ck(_v, 17, 0, currVal_2); }); }
export function View_TakingDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-taking-description", [], null, null, null, View_TakingDescriptionComponent_0, RenderType_TakingDescriptionComponent)), i1.ɵdid(1, 114688, null, 0, i8.TakingDescriptionComponent, [i9.OrderDescriptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TakingDescriptionComponentNgFactory = i1.ɵccf("app-taking-description", i8.TakingDescriptionComponent, View_TakingDescriptionComponent_Host_0, {}, {}, []);
export { TakingDescriptionComponentNgFactory as TakingDescriptionComponentNgFactory };
