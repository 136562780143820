import { UniversalToastComponent } from './../../../utils/universal-toast/universal-toast.component';
import { EnvService } from './../../../services/env.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { ValidationService, OdataService, UsersService, SessionService, NavigationService } from '@/services';
import { Router } from '@angular/router';
import { AlertComponent } from '@/utils';
import { IAgreement, IregisterCo } from '@/interfaces';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-co-step1',
    templateUrl: './co-step1.component.html',
    styleUrls: ['./co-step1.component.css']
})
export class CoStep1Component implements OnInit, OnDestroy {
    @ViewChild('alert') alert: AlertComponent;
    @ViewChild('universalToast') universalToast: UniversalToastComponent;

    registerCoForm = this.formBuilder.group({
        firm: this.formBuilder.group({
            type: [0, Validators.min(1)],
            nip: ['', [Validators.required, this.validation.nipValidator]],
            name: ['', [Validators.required]],
            krs: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
            regon: ['', [Validators.required, this.validation.regonValidator]]
        }),
        firstName: [{value: '', disabled: true}],
        lastName: [{value: '', disabled: true}],
        address: this.formBuilder.group({
            postCode: ['', [Validators.required, Validators.pattern('[0-9]{2}-[0-9]{3}')]],
            city: ['', [Validators.required]],
            street: ['', [Validators.required]],
            number: ['', [Validators.required, Validators.pattern('[0-9]+[A-Za-z]?((\\s)?\/(\\s)?[0-9]+[A-Za-z]?)?')]]
        }),
        agreements: this.formBuilder.array([])
    });

    inputs = {
        // @ts-ignore
        firmType: this.registerCoForm.controls.firm.controls.type,
        // @ts-ignore
        nip: this.registerCoForm.controls.firm.controls.nip,
        // @ts-ignore
        firmName: this.registerCoForm.controls.firm.controls.name,
        // @ts-ignore
        krs: this.registerCoForm.controls.firm.controls.krs,
        // @ts-ignore
        regon: this.registerCoForm.controls.firm.controls.regon,
        firstName: this.registerCoForm.controls.firstName,
        lastName: this.registerCoForm.controls.lastName,
        // @ts-ignore
        postCode: this.registerCoForm.controls.address.controls.postCode,
        // @ts-ignore
        city: this.registerCoForm.controls.address.controls.city,
        // @ts-ignore
        street: this.registerCoForm.controls.address.controls.street,
        // @ts-ignore
        addressNumber: this.registerCoForm.controls.address.controls.number
    };

    showAlert = false;
    alertText = '';
    businessTypes: String[] = [];

    registerCo: IregisterCo = {
        token: '', language: '', id_busines_type: 0,
        address_street: '', address_city: '', address_zip_code: '', address_local: '', address_country: 'pl',
        id_agreement_list: [], nip: '', regon: '', krs: '', describing_name: ''
    };

    agreementsList: IAgreement[] = [];

    navigationService: NavigationService;

    constructor(private formBuilder: FormBuilder, private validation: ValidationService,
                private router: Router, private odataService: OdataService,
                private sessionService: SessionService, public env: EnvService,
                private userService: UsersService, private pageService: PageService, navigationService: NavigationService) {
        this.navigationService = navigationService;
    }

    get agreements() {
        return this.registerCoForm.get('agreements') as FormArray;
    }

    ngOnInit() {
        this.getBusinessTypes();
        this.alert.clear();
        this.getAgreementsForCORegistration();
        this.pageService.setTranslatedPageTitle('BeforeWorkDoctor.RegisterDCTitle', 0);
        this.fillUserName();
        setTimeout(() => this.onBusinessTypeChange());
        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN') {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add('pp-portal');
        }
    }

    onBusinessTypeChange() {
        this.registerCoForm.controls.firm.get('type').valueChanges.subscribe(
            businessType => {
                if (businessType == 1 || businessType == 2) {
                    this.registerCoForm.controls.firm.get('krs').reset();
                    this.registerCoForm.controls.firm.get('krs').disable();
                    document.getElementById('krs').setAttribute('class', 'form-control');
                    this.registerCoForm.controls.firm.get('krs').setValue('');
                } else {
                    this.registerCoForm.controls.firm.get('krs').setValidators([Validators.required, Validators.pattern('[0-9]{10}')]);
                    this.registerCoForm.controls.firm.get('krs').enable();
                }
                this.registerCoForm.controls.firm.get('krs').updateValueAndValidity();
            }
        );
    }

    fillUserName(): void {
        this.userService.userDataPromise.then(() => {
            let array = this.userService.username.split(' ');

            let name: string = '';
            let lastName: string = array[array.length - 1];

            for (let i = 0; i < array.length - 1; ++i) {
                name += array[i];
                if (i < array.length - 2) name += ' ';
            }

            this.registerCoForm.controls.firstName.setValue(name);
            this.registerCoForm.controls.lastName.setValue(lastName);
        });
    }

    addAgreement(agreement: IAgreement) {
        if (agreement.is_required) {
            this.agreements.push(this.formBuilder.control(false, Validators.requiredTrue));
        } else {
            this.agreements.push(this.formBuilder.control(false));
        }
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();

        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');
    }

    getBusinessTypes() {
        this.odataService.postPrivate('get_business_types',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                const result = JSON.parse(JSON.stringify(res));
                const status = result.Status;
                this.businessTypes = JSON.parse(result.Value);
                this.registerCoForm.controls.firm.get('type').setValue(1);
            });
    }

    showErrors(): void {
        if (!this.inputs.firmType.valid) {
            document.getElementById('type').setAttribute('class', 'form-control is-error');
            document.getElementById('nip').setAttribute('class', 'form-control');
            document.getElementById('name').setAttribute('class', 'form-control');
            document.getElementById('krs').setAttribute('class', 'form-control');
            document.getElementById('regon').setAttribute('class', 'form-control');
            document.getElementById('postCode').setAttribute('class', 'form-control');
            document.getElementById('city').setAttribute('class', 'form-control');
            document.getElementById('street').setAttribute('class', 'form-control');
            document.getElementById('addressNumber').setAttribute('class', 'form-control');
        } else {
            document.getElementById('type').setAttribute('class', 'form-control');
            if (!this.inputs.nip.valid) {
                document.getElementById('nip').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('nip').setAttribute('class', 'form-control');
            }
            if (!this.inputs.firmName.valid) {
                document.getElementById('name').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('name').setAttribute('class', 'form-control');
            }
            if (this.inputs.krs.invalid && this.inputs.krs.enabled) {
                document.getElementById('krs').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('krs').setAttribute('class', 'form-control');
            }
            if (!this.inputs.regon.valid) {
                document.getElementById('regon').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('regon').setAttribute('class', 'form-control');
            }
            if (!this.inputs.postCode.valid) {
                document.getElementById('postCode').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('postCode').setAttribute('class', 'form-control');
            }
            if (!this.inputs.city.valid) {
                document.getElementById('city').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('city').setAttribute('class', 'form-control');
            }
            if (!this.inputs.street.valid) {
                document.getElementById('street').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('street').setAttribute('class', 'form-control');
            }
            if (!this.inputs.addressNumber.valid) {
                document.getElementById('addressNumber').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('addressNumber').setAttribute('class', 'form-control');
            }
        }
    }

    onSubmit() {
        if (this.checkData()) {
            this.showErrors();
            this.insertData();
            this.universalToast.isLoaderShown = true;
            this.universalToast.success('UploadData');
            this.universalToast.show();
            this.odataService.postPrivate('register_describing_center_step1',
                this.registerCo,
                res => {
                    const returnedJson = JSON.stringify(res);
                    const result = JSON.parse(returnedJson);
                    if (result.Status === 'OK') {
                        this.userService.getUserData();
                        this.userService.getDcList();
                        sessionStorage.setItem('newDcId', result.Value);
                        this.universalToast.hide();
                        this.router.navigate(['/registrationCo2']);
                    } else {
                        this.universalToast.isLoaderShown = false;
                        this.universalToast.error('CenterExists');
                        setTimeout(() => {
                            this.universalToast.hide();
                        }, 3000);
                    }
                });
        }
    }

    getAgreementsForCORegistration(): void {
        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        };

        this.odataService.postPrivate('get_user_agreement_for_co_registration', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.agreementsList = JSON.parse(result.Value);
                this.agreementsList.forEach((agreement) => {
                    if (agreement.is_required) {
                        agreement.agreement_contents = '* ' + agreement.agreement_contents;
                    }
                    this.addAgreement(agreement);
                });
            }
        });
    }

    checkData(): boolean {
        this.alert.clear();
        this.alertText = '';
        if (this.registerCoForm.valid) {
            return true;
        } else {
            let countOfErrors = 0;
            this.showErrors();
            let errorMessage: string;
            if (!this.inputs.firmType.valid) {
                errorMessage = 'WRONG_FIRM_TYPE';
            } else {
                if (!this.inputs.nip.valid) {
                    errorMessage = 'nip';
                    countOfErrors++;
                }
                if (!this.inputs.firmName.valid) {
                    errorMessage = 'firmName';
                    countOfErrors++;
                }
                if (this.inputs.krs.invalid && this.inputs.krs.enabled) {
                    errorMessage = 'krs';
                    countOfErrors++;
                }
                if (!this.inputs.regon.valid) {
                    errorMessage = 'regon';
                    countOfErrors++;
                }
                if (!this.inputs.postCode.valid) {
                    errorMessage = 'zip_code';
                    countOfErrors++;
                }
                if (!this.inputs.city.valid) {
                    errorMessage = 'city';
                    countOfErrors++;
                }
                if (!this.inputs.street.valid) {
                    errorMessage = 'street';
                    countOfErrors++;
                }
                if (!this.inputs.addressNumber.valid) {
                    errorMessage = 'addressNumber';
                    countOfErrors++;
                }
                if (!this.agreements.valid) {
                    errorMessage = 'agreements';
                    countOfErrors++;
                }
                if (countOfErrors > 1) {
                    errorMessage = 'manyErrors';
                }
            }
            this.alertText = errorMessage;
            this.alert.displayError(errorMessage);
        }
    }

    insertData(): void {
        this.registerCo.describing_name = this.inputs.firmName.value;
        this.registerCo.token = this.sessionService.getToken();
        this.registerCo.id_busines_type = +this.inputs.firmType.value;
        this.registerCo.nip = this.inputs.nip.value;
        this.registerCo.krs = this.inputs.krs.value;
        this.registerCo.regon = this.inputs.regon.value;
        this.registerCo.address_zip_code = this.inputs.postCode.value;
        this.registerCo.address_city = this.inputs.city.value;
        this.registerCo.address_street = this.inputs.street.value;
        this.registerCo.address_local = this.inputs.addressNumber.value;
        this.registerCo.language = this.userService.iLanguage;
        this.registerCo.id_agreement_list = [];
        this.agreementsList.forEach((agreement) => {
            this.registerCo.id_agreement_list.push(agreement.id_agreement);
        });
    }

}
