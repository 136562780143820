import { MessageSenderService } from './../../../../services/message-sender.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IMessage } from '@/dm/messages/imessage';
import { UsersService, OdataService, MessagesService } from '@/services';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-short-message',
    templateUrl: './short-message.component.html',
    styleUrls: ['./short-message.component.css']
})
export class ShortMessageComponent implements OnInit {
    @Input() message: IMessage;
    @Output() remove = new EventEmitter();
    @Output() read = new EventEmitter();
    @Output() close = new EventEmitter();
    @Output() extend = new EventEmitter<boolean>();
    text: string;
    extended = false;
    editReply = false;

    constructor(public userService: UsersService, public odataService: OdataService,
        public messageSender: MessageSenderService, private messagesService: MessagesService,
        public envService: EnvService) {
    }

    static shortText(text: string): string {
        let ret_string: string;

        if (text.length < 106) {
            ret_string = text;
        } else {
            let index: number = text.indexOf(' ', 100);

            if (index < 1) {
                index = text.length;
            }

            if (index < 150) {
                ret_string = text.slice(0, index);
            } else {
                ret_string = text.slice(0, 125);
            }

            if (ret_string.length < text.length) {
                ret_string.concat(' (...)');
            }
        }

        return ret_string;
    }

    ngOnInit() {
        this.text = ShortMessageComponent.shortText(this.message.message);
    }

    messageInsideClick(clicked) {
        if (this.extended !== clicked.clickInside) {
            this.extended = clicked.clickInside;

            let is_extend = false;

            if (this.extended) {
                is_extend = true;
                this.text = this.message.message;

                if (!this.message.message_read) {
                    this.odataService.postPrivate('message_read',
                        this.odataService.paramsTokenAndLang({
                            readed: true,
                            id_message: this.message.id_message
                        }),
                        res => {
                            const returnedJson = JSON.stringify(res);
                            const result = JSON.parse(returnedJson);

                            if (result.Status === 'OK') {
                                this.message.message_read = (result.Value === 'true');

                                if (this.message.message_read) {
                                    this.read.emit(this.message.id_message);
                                }
                            } else {
                                // TODO:
                                console.log('REASON:', result.Status);
                            }
                        }
                    );
                }
            } else {
                this.text = ShortMessageComponent.shortText(this.message.message);
                this.editReply = false;
            }

            this.extend.emit(is_extend);
        }
    }

    onCancel(event: any) {
        event.stopPropagation();

        if (this.extended) {
            this.editReply = false;
        }

        this.messageSender.setDefaultData();
    }

    onReply(event: any) {
        event.stopPropagation();

        if (this.extended) {
            this.editReply = true;
        }
    }

    onSubmit(event, form: NgForm) {
        event.stopPropagation();
        this.messageSender.onSubmit(this.message.id_user_from, form.value.message_text, null, this.message.id_message).then((res) => {
            if (res) {
                this.editReply = false;
                this.messageSender.setDefaultData();
            } else {
                // TODO:
                console.log('FAILED');
            }
        });
    }

    onRemove(event: any) {
        event.stopPropagation();

        if (this.editReply) {
            this.editReply = false;
            this.messageSender.setDefaultData();
            return;
        }

        this.odataService.postPrivate('message_delete',
            this.odataService.paramsTokenAndLang({id_message: this.message.id_message}),
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);
                if (result.value === 'OK') {
                    this.remove.emit(this.message.id_message);
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            }
        );
    }

    onReadMessage(event: any) {
        this.messagesService.showMessage(this.message.id_message);
        this.close.emit();
    }
}
