import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContextsService {

    components: any[] = [
        {name: 'sidebar', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {name: 'switchMode', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {name: 'dcList', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PHYSICIAN', 'PATIENT']},
        {name: 'dcProfile', contexts: ['DESCRIBING_CENTER']},
        {name: 'normalMessageButton', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {name: 'ordersTopbarNav', contexts: ['DESCRIBING_CENTER']},
        {name: 'dashboardSidebar', contexts: ['DESCRIBING_CENTER']},
        {name: 'dashboardActions', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {name: 'ordersList', contexts: ['DESCRIBING_CENTER']},
        {name: 'firmLink', contexts: ['DESCRIBING_CENTER']},
        {name: 'sendMessage', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY']},
        {name: 'filters', contexts: ['DESCRIBING_CENTER', 'MEDICAL_FACILITY']},
        {name: 'notificationsButton', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT', 'ADMINISTRATOR']},
        {name: 'verifiedPhysician', contexts: ['PHYSICIAN']},
        {name: 'goBackFromProperties', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'uploadProfileImage', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'activateAccount', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'changeEmailButton', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'changePhoneButton', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'changePasswordButton', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'changeAgreementsButton', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'submitPWZ', contexts: ['ACCOUNT_ACTIVE', 'PATIENT', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY']},
        {name: 'messageButton', contexts: ['ACCOUNT_ACTIVE', 'PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'ADMINISTRATOR']},
        {name: 'getToHome', contexts: ['PHYSICIAN', 'DESCRIBING_CENTER', 'MEDICAL_FACILITY', 'PATIENT']},
        {name: 'patientActions', contexts: ['PATIENT', 'PHYSICIAN']},
        {name: 'patientSidebar', contexts: ['PATIENT', 'PHYSICIAN']},
        {name: 'facilitySidebar', contexts: ['MEDICAL_FACILITY']},
        {name: 'adminSidebar', contexts: ['ADMINISTRATOR']},
        {name: 'doctorsAndCoProfiles', contexts: ['PATIENT', 'PHYSICIAN']}
    ];

    constructor() { }

    checkPermission(component): boolean {
        let comp = this.components.find(x => x.name == component);
        let res = false;

        comp.contexts.forEach((cont) => {
            if (sessionStorage.getItem('currentContext') == cont) {
                res = true;
            }
        });
        return res;
    }
}
