import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  DisplayService,
  NavigationService,
  OdataService,
  SessionService,
  UsersService,
  ValidationService
} from '@/services';
import { AlertComponent, SmsModalComponent } from '@/utils/';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from '@/services/page.service';
import { IAccountSettings, IChangeEmail, IChangePassword, IChangePhone, ISaveData, IUserProfile, IField } from '@/interfaces';
import { ContextsService } from '@/services/contexts.service';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import * as uuid from 'uuid';
import { PwzVerificationModalComponent } from '@/utils/pwz-verification-modal/pwz-verification-modal.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-account-properties',
    templateUrl: './account-properties.component.html',
    styleUrls: ['./account-properties.component.css']
})
export class AccountPropertiesComponent implements OnInit, OnDestroy {

    @ViewChild('smsModal') smsModal: SmsModalComponent;
    @ViewChild('saveYourDataAlert') saveYourDataAlert: AlertComponent;
    @ViewChild('changeEmailAlert') changeEmailAlert: AlertComponent;
    @ViewChild('changePhoneAlert') changePhoneAlert: AlertComponent;
    @ViewChild('changePasswordAlert') changePasswordAlert: AlertComponent;
    @ViewChild('pwzModal') pwzModal: PwzVerificationModalComponent;
    @ViewChild('saveAccountSettingsToast') saveAccountSettingsToast: UniversalToastComponent;

    faEye = faEye;
    faEyeSlash = faEyeSlash;

    saveData: ISaveData = {token: '', imie: '', nazwisko: '', data_urodzenia: '', password: ''};
    changePass: IChangePassword = {token: '', stare_haslo: '', nowe_hasło: ''};
    changePhone: IChangePhone = {token: '', numer_telefonu: '', password: ''};
    changeEmail: IChangeEmail = {token: '', nowy_email: '', password: ''};
    userProfile: IUserProfile = {
        imie: '',
        nazwisko: '',
        email: '',
        telefon: '',
        czy_lekarz: false
    };
    accountSettings: IAccountSettings = {
        id_user: 0,
        login_name: '',
        first_name: '',
        last_name: '',
        id_document_type: '',
        id_document_value: '',
        email: '',
        citizenship: '',
        phone_number: '',
        birthday: '',
        phone_number_verified: false,
        email_verified: false,
        pwz: '',
        pwz_status: '',
        agreements: [],
        phone_agreement_info: {
            phone_id_agreement: 0,
            phone_agreement_value: false,
            phone_agreement_content: '',
            phone_number_verified: false
        }
    };
    changedSection: string = '';
    isPesel = false;
    base64textString = '';

    showNewPassword = false;
    showRepeatPassword = false;

    currentPageRoute: string;

    accountDataForm = this.formBuilder.group({
        imie: [{
            value: '',
            disabled: true
        }, [Validators.required, ValidationService.nameValidator, Validators.maxLength(200)]],
        nazwisko: [{
            value: '',
            disabled: true
        }, [Validators.required, ValidationService.nameValidator, Validators.maxLength(200)]],
        dataurodzenia: [{value: '', disabled: true}, [Validators.required, ValidationService.dateValidator]],
        pwz: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(7), Validators.minLength(7)]],
    });

    emailForm = this.formBuilder.group({
        nowy_email: [{value: '', disabled: this.contextService.checkPermission('activateAccount') ? false : true},
        [Validators.email, Validators.required, Validators.maxLength(200)]],
        powtorz_nowy_email: [{value: '', disabled: this.contextService.checkPermission('activateAccount') ? false : true},
        [Validators.email, Validators.required, Validators.maxLength(200)]]
    });

    phoneForm = this.formBuilder.group({
        nowy_nr_tel: [{value: '', disabled: this.contextService.checkPermission('activateAccount') ? false : true},
        [ValidationService.phoneNumberValidator, Validators.required]],
        phoneAgreement: [false]
    });

    passwordForm = this.formBuilder.group({
        nowe_haslo: [{value: '', disabled: this.contextService.checkPermission('activateAccount') ? false : true},
        [ValidationService.passwordValidator, Validators.required, Validators.maxLength(64)]],
        powtorz_nowe_haslo: [{value: '', disabled: this.contextService.checkPermission('activateAccount') ? false : true},
        [ValidationService.passwordValidator, Validators.required, Validators.maxLength(64)]]
    });

    navigationService: NavigationService;
    userService: UsersService;
    stickySidebarFunction: any;

    fields: IField[] = [
        {name: 'data', topHeight: 0},
        {name: 'email', topHeight: 0},
        {name: 'phone', topHeight: 0},
        {name: 'password', topHeight: 0},
        {name: 'agreements', topHeight: 0},
        {name: 'delete', topHeight: 0}
    ];
    activateField = {
        'data': true,
        'email': false,
        'phone': false,
        'password': false,
        'agreements': false,
        'delete': false
    };
    activeFieldsFunction: any = null;

    timeout: any = null;
    pwzButtonStatus: string = '';
    pwzSubscription: Subscription;
    currentAgreementForModal: string = '';

    constructor(userService: UsersService, private router: Router, private odataService: OdataService,
                private formBuilder: FormBuilder, private translate: TranslateService,
                private sessionService: SessionService, navigationService: NavigationService,
                private pageService: PageService, public contextService: ContextsService) {
        this.navigationService = navigationService;
        this.userService = userService;
    }

    ngOnInit() {

        sessionStorage.setItem('currentPage', decodeURI(this.router.url.toString()));
        this.currentPageRoute = sessionStorage.getItem('currentPage');

        if (this.navigationService.getCurrentContext() === 'PATIENT' || this.navigationService.getCurrentContext() === 'PHYSICIAN' || this.navigationService.getCurrentContext() === '' || this.navigationService.getCurrentContext() === undefined) {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add('pp-portal');
        }

        this.getAccountSettingsInfo();
        this.clearPasswordInputOnModalClose();
        this.timeout = setTimeout(() => {
            this.setFields();
            this.timeout = null;
        }, 100);
        this.sendPWZEvent();

        this.countStickySidebarHeight().then((stickySidebarHeight) => {
            this.stickySidebarFunction = DisplayService.createStickySidebar('sticky_sidebar', stickySidebarHeight, 160, 200, '100%');
        });

        this.pageService.setTranslatedPageTitle('AccountProperties.Title', 0);
    }

    ngOnDestroy() {

        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('pp-portal');

        window.removeEventListener('scroll', this.stickySidebarFunction);
        if (this.activeFieldsFunction != null) window.removeEventListener('scroll', this.activeFieldsFunction);
        if (this.timeout != null) clearTimeout(this.timeout);
        this.pageService.resetPageTitle();
        this.pwzSubscription.unsubscribe();
    }

    setFields(): void {
        let rect = document.getElementById('section-dane').getBoundingClientRect();
        this.fields[0].topHeight = rect.top + document.documentElement.scrollTop - 1;

        rect = document.getElementById('section-email').getBoundingClientRect();
        this.fields[1].topHeight = rect.top + document.documentElement.scrollTop - 1;

        rect = document.getElementById('section-phone').getBoundingClientRect();
        this.fields[2].topHeight = rect.top + document.documentElement.scrollTop - 1;

        rect = document.getElementById('section-password').getBoundingClientRect();
        this.fields[3].topHeight = rect.top + document.documentElement.scrollTop - 1;

        rect = document.getElementById('section-aggrements').getBoundingClientRect();
        this.fields[4].topHeight = rect.top + document.documentElement.scrollTop - 1;

        rect = document.getElementById('section-delete').getBoundingClientRect();
        this.fields[5].topHeight = rect.top + document.documentElement.scrollTop - 1;

        DisplayService.activateSidebarFieldsOnScroll(this.fields, this.activateField);
    }

    clearPasswordInputOnModalClose(): void {
        $('#getPasswordModal').on('hidden.bs.modal', function () {
            let input: HTMLInputElement = <HTMLInputElement>document.getElementById('passwordInput');
            input.value = '';
            input.setAttribute('class', 'form-control');
            document.getElementById('passwordInputError').style.display = 'none';
        });
    }

    saveAccountData() {
        this.odataService.postPrivate('save_account_data', this.saveData,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.resultCorrect();
                    (<HTMLInputElement>document.getElementById('imie')).value = this.accountSettings.first_name;
                    (<HTMLInputElement>document.getElementById('nazwisko')).value = this.accountSettings.last_name;
                    this.saveData = {token: '', imie: '', nazwisko: '', data_urodzenia: '', password: ''};
                } else if (result.value == 'PASSWORD_FAILED') {
                    this.wrongPass();
                } else if (result.value == 'OTHER_REASON') {
                    // TODO
                }
            }
        );
    }

    onImageSelected(event) {
        let files = event.target.files;
        let file = files[0];

        if (files && file) {
            let reader = new FileReader();
            this.userService.changeImageNoCacheSuffix();

            reader.onload = this.handleFile.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleFile(event) {
        this.saveAccountSettingsToast.success('UploadImage');
        this.saveAccountSettingsToast.isLoaderShown = true;
        this.saveAccountSettingsToast.show();
        let binaryString = event.target.result;
        this.base64textString = btoa(binaryString);
        this.base64textString = this.base64textString.replace(/\+/gi, '-');
        this.base64textString = this.base64textString.replace(/\//gi, '_');
        this.accountUploadProfileImage();
    }

    accountUploadProfileImage() {
        this.odataService.postPrivate('account_upload_profile_image',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                base64: this.base64textString
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.userService.getUserData();
                    this.saveAccountSettingsToast.hide();
                } else {
                    this.saveAccountSettingsToast.error('UploadFailed');
                    setTimeout(() => this.saveAccountSettingsToast.hide(), 3000);
                }
            });
    }

    getAccountSettingsInfo() {
        this.odataService.postPrivate('get_account_settings_info',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage
            },
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson).AccountSettings;
                let status = JSON.parse(returnedJson).Status;
                this.accountSettings = JSON.parse(result);
                if (status == 'OK') {
                    this.phoneForm.get('phoneAgreement').setValue(this.accountSettings.phone_agreement_info.phone_agreement_value);
                    if (this.accountSettings.phone_number == null) {
                        this.phoneForm.get('phoneAgreement').setValidators([Validators.requiredTrue]);
                    } else {
                        this.phoneForm.get('phoneAgreement').clearValidators();
                    }
                    this.phoneForm.get('phoneAgreement').updateValueAndValidity();
                    if (this.accountSettings.id_document_type == 'pesel') {
                        this.isPesel = true;
                    }
                    this.accountSettings.agreements.sort((a, b) => (a.id_agreement > b.id_agreement) ? 1 : -1);
                    this.accountDataForm.get('imie').setValue(this.accountSettings.first_name);
                    this.accountDataForm.get('nazwisko').setValue(this.accountSettings.last_name);
                    this.accountDataForm.get('dataurodzenia').setValue(this.accountSettings.birthday);
                    if (this.accountSettings.pwz_status == 'verified'
                        || this.accountSettings.pwz_status == 'under_verification'
                        || !this.contextService.checkPermission('submitPWZ')) {
                        this.accountDataForm.get('pwz').reset({value: this.accountSettings.pwz, disabled: true});
                    } else this.accountDataForm.get('pwz').reset({value: this.accountSettings.pwz, disabled: false});
                    let pwzStatus = this.accountSettings.pwz_status.toUpperCase();
                    this.translate.get('AccountProperties.PWZButton.' + pwzStatus)
                        .subscribe((res: string) => {
                            this.pwzButtonStatus = res;
                        });
                }
            });
    }

    showPassRetry(id: string) {
        if (id === 'nowe-haslo') {
            this.showNewPassword = !this.showNewPassword;

            const input = <HTMLInputElement>document.getElementById(id);
            input.setAttribute('type', this.showNewPassword ? 'text' : 'password');

        } else if (id === 'powtorz-nowe-haslo') {
            this.showRepeatPassword = !this.showRepeatPassword;

            const input = <HTMLInputElement>document.getElementById(id);
            input.setAttribute('type', this.showRepeatPassword ? 'text' : 'password');
        }
    }

    getUserProfile() {
        this.odataService.postPrivate('get_user_profile',
            {token: this.sessionService.getToken()},
            res => {
                let returnedJson = JSON.stringify(res);
                this.userProfile = JSON.parse(returnedJson);
            });
    }

    openVerificationModal() {
        this.pwzModal.show();
    }

    sendPWZEvent() {
        this.pwzSubscription = this.pwzModal.pwzSending.subscribe(
            res => {
                if (res == 'OK') {
                    $('#verificationSendModal').modal('show');
                    this.getAccountSettingsInfo();
                } else {
                    this.pwzModal.hide();
                    this.saveAccountSettingsToast.error('OperationFailed');
                    this.saveAccountSettingsToast.show();
                    setTimeout(() => {
                        this.saveAccountSettingsToast.hide();
                    }, 3000);
                }
            }
        );
    }

    contact() {
        $('#verificationSendModal').modal('hide');
    }

    saveAccountDataChangePassword() {
        this.odataService.postPrivate('save_account_data_change_password', this.changePass,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.resultCorrect();
                    this.saveAccountSettingsToast.success('Saved');
                    this.passwordForm.controls['nowe_haslo'].setValue('');
                    this.passwordForm.controls['powtorz_nowe_haslo'].setValue('');
                    this.changePass = {token: '', stare_haslo: '', nowe_hasło: ''};
                } else if (result.value == 'PASSWORD_FAILED') {
                    this.wrongPass();
                } else if (result.value == 'OTHER_REASON') {
                    $('#getPasswordModal').modal('hide');
                    this.saveAccountSettingsToast.error('NotSaved');
                }
                this.saveAccountSettingsToast.show();
                setTimeout(() => this.saveAccountSettingsToast.hide(), 1500);
            }
        );
    }

    saveAccountDataChangePhoneNumber() {
        this.odataService.postPrivate('save_account_data_change_phone_number', this.changePhone,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.togglePhoneAgreementConsent(false).then(() => {
                        this.resultCorrect();
                    });
                    this.saveAccountSettingsToast.success('Saved');
                    this.changePhone = {token: '', numer_telefonu: '', password: ''};
                    this.phoneForm.controls['nowy_nr_tel'].setValue('');
                    this.smsModal.phoneNumber = this.changePhone.numer_telefonu;
                    this.smsModal.sendSms(this.saveAccountSettingsToast);
                } else if (result.value == 'PASSWORD_FAILED') {
                    this.wrongPass();
                } else if (result.value == 'OTHER_REASON') {
                    $('#getPasswordModal').modal('hide');
                    this.saveAccountSettingsToast.error('NotSaved');
                } else if (result.value == 'INCORRECT_DATA') {
                    $('#getPasswordModal').modal('hide');
                    document.getElementById('nowy-nr-tel').setAttribute('class', 'form-control is-error');
                    this.saveAccountSettingsToast.error('InvalidPhoneNumber');
                }
                this.saveAccountSettingsToast.show();
                setTimeout(() => this.saveAccountSettingsToast.hide(), 1500);
            }
        );
    }

    togglePhoneAgreementConsent(isOnlyAgreement: boolean): Promise<any> {
        return this.odataService.postPrivate('toggle_phone_agreement_consent',
            this.odataService.paramsTokenAndLang({agreement_value: this.phoneForm.get('phoneAgreement').value}),
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    if (isOnlyAgreement) {
                        this.saveAccountSettingsToast.success('Saved');
                        this.getAccountSettingsInfo();
                    }
                    this.phoneForm.controls['phoneAgreement'].setValue('');
                } else if (result.value == 'OTHER_REASON') {
                    this.saveAccountSettingsToast.error('NotSaved');
                }
                if (isOnlyAgreement) {
                    this.saveAccountSettingsToast.show();
                    setTimeout(() => this.saveAccountSettingsToast.hide(), 1500);
                }
                return result.value;
            }
        );
    }

    removeAgreement() {
        if (this.currentAgreementForModal == 'phone') {
            this.togglePhoneAgreementConsent(true);
        } else if (this.currentAgreementForModal == 'medicalDocumentation') {
            this.saveAccountDataChangeAgreementList();
        }
    }

    saveAccountDataChangeEmail() {
        this.odataService.postPrivate('save_account_data_change_email', this.changeEmail,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.resultCorrect();
                    this.saveAccountSettingsToast.success('Saved');
                    this.changeEmail = {token: '', nowy_email: '', password: ''};
                    this.emailForm.controls['nowy_email'].setValue('');
                    this.emailForm.controls['powtorz_nowy_email'].setValue('');
                } else if (result.value == 'PASSWORD_FAILED') {
                    this.wrongPass();
                } else if (result.value == 'OTHER_REASON') {
                    $('#getPasswordModal').modal('hide');
                    this.saveAccountSettingsToast.error('NotSaved');
                }
                this.saveAccountSettingsToast.show();
                setTimeout(() => this.saveAccountSettingsToast.hide(), 1500);
            }
        );
    }

    confirmEmail(): void {
        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            confirmation_uuid: uuid.v4()
        };

        this.odataService.postPrivate('account_email_confirmation', params,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.saveAccountSettingsToast.success('OperationSuccess');
                } else {
                    this.saveAccountSettingsToast.error('OperationFailed');
                }
                this.saveAccountSettingsToast.show();
                setTimeout(() => this.saveAccountSettingsToast.hide(), 1500);
            }
        );
    }

    saveAccountDataChangeAgreementList() {
        let changesCount: number = 0;
        let checkbox;
        let changePass: {
            token: string,
            lista_zgód: string
        } = {
            token: this.sessionService.getToken(),
            lista_zgód: ''
        };
        let dataForServer: string = '{ \"agreements\" : [';
        for (let i: number = 0; i < this.accountSettings.agreements.length; ++i) {
            checkbox = <HTMLInputElement>document.getElementById('zgoda-' + this.accountSettings.agreements[i].id_agreement);
            if (checkbox.checked != this.accountSettings.agreements[i].agreement_value) {
                if (changesCount != 0) dataForServer += ', ';
                dataForServer += '{\"id_agreement\": ' + this.accountSettings.agreements[i].id_agreement +
                    ', \"agreement_value\": ' + checkbox.checked.toString() + '}';
                ++changesCount;
            }
        }
        dataForServer += ']}';
        changePass.lista_zgód = dataForServer;
        this.odataService.postPrivate('save_account_data_change_agreement_list', changePass,
            res => {
                let returnedJson = JSON.stringify(res);
                let result = JSON.parse(returnedJson);
                if (result.value == 'OK') {
                    this.saveAccountSettingsToast.success('Saved');
                    this.getAccountSettingsInfo();
                } else if (result.value == 'OTHER_REASON') {
                    this.saveAccountSettingsToast.error('NotSaved');
                }
                this.saveAccountSettingsToast.show();
                setTimeout(() => this.saveAccountSettingsToast.hide(), 1000);
            }
        );
    }

    validatePersonalData(): void {
        this.saveData.imie = this.accountDataForm.get('imie').value;
        this.saveData.nazwisko = this.accountDataForm.get('nazwisko').value;
        this.saveData.data_urodzenia = this.accountDataForm.get('dataurodzenia').value;

        let validateDate: boolean;
        if (this.accountSettings.id_document_type == 'pesel') validateDate = true;
        else validateDate = this.accountDataForm.get('dataurodzenia').valid;

        let validateName: boolean = this.accountDataForm.get('imie').valid;
        let validateSurname: boolean = this.accountDataForm.get('nazwisko').valid;
        if (validateDate && validateName && validateSurname) {
            this.saveYourDataAlert.active = false;
            this.setInputToCorrect(['nazwisko', 'imie', 'dataurodzenia']);
            $('#getPasswordModal').modal('show');
            this.changedSection = 'personalData';
            this.saveData.token = this.sessionService.getToken();
        } else {
            this.saveYourDataAlert.active = true;
            if (this.accountDataForm.get('imie').value.length > 200 || this.accountDataForm.get('nazwisko').value.length > 200) {
                this.translate.get('Errors.TOO_LONG_DATA')
                    .subscribe((res: string) => {
                        this.saveYourDataAlert.titleTranslated = res;
                    });
            } else {
                this.translate.get('Errors.INCORRECT_DATA')
                    .subscribe((res: string) => {
                      this.saveYourDataAlert.titleTranslated = res;
                    });
            }

            if (!validateSurname) {
                document.getElementById('nazwisko').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('nazwisko').setAttribute('class', 'form-control');
            }
            if (!validateName) {
                document.getElementById('imie').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('imie').setAttribute('class', 'form-control');
            }
            if (!validateDate) {
                document.getElementById('dataurodzenia').setAttribute('class', 'form-control is-error');
            } else {
                document.getElementById('dataurodzenia').setAttribute('class', 'form-control');
            }
        }
    }

    validatePhoneNumber(): void {
        if (this.phoneForm.get('phoneAgreement').valid) {
            if (this.phoneForm.get('nowy_nr_tel').value == '' &&
                this.accountSettings.phone_number != null) {
                    if (!this.phoneForm.get('phoneAgreement').value) {
                        this.currentAgreementForModal = 'phone';
                        $('#setAgreementModal').modal('show');
                    } else {
                        this.togglePhoneAgreementConsent(true);
                    }
            } else if (this.phoneForm.get('nowy_nr_tel').valid) {
                this.changePhoneAlert.active = false;
                this.changePhone.numer_telefonu = this.phoneForm.get('nowy_nr_tel').value;
                document.getElementById('nowy-nr-tel').setAttribute('class', 'form-control');
                $('#getPasswordModal').modal('show');
                this.changedSection = 'phone';
                this.changePhone.token = this.sessionService.getToken();
            } else {
                this.showPhoneAlert('phone');
            }
        } else {
            this.showPhoneAlert('agreement');
        }
    }

    showPhoneAlert(errorType: string) {
        this.changePhoneAlert.active = true;
        if (errorType == 'phone') {
            this.translate.get('AccountProperties.ErrorPhone')
                .subscribe((res: string) => {
                    this.changePhoneAlert.titleTranslated = res;
              });
            document.getElementById('nowy-nr-tel').setAttribute('class', 'form-control is-error');
        } else if (errorType == 'agreement') {
            this.translate.get('AccountProperties.ErrorPhoneAgreement')
                .subscribe((res: string) => {
                    this.changePhoneAlert.titleTranslated = res;
              });
            document.getElementById('nowy-nr-tel').setAttribute('class', 'form-control');
        }
    }

    validateEmail(): void {
        if (this.emailForm.get('nowy_email').valid &&
            this.emailForm.get('nowy_email').value == this.emailForm.get('powtorz_nowy_email').value) {
            this.changeEmailAlert.active = false;
            this.changeEmail.nowy_email = this.emailForm.get('nowy_email').value;
            this.setInputToCorrect(['nowy-email', 'powtorz-nowy-email']);
            $('#getPasswordModal').modal('show');
            this.changedSection = 'email';
            this.changeEmail.token = this.sessionService.getToken();
        } else {
            this.changeEmailAlert.active = true;
            if (!this.emailForm.get('nowy_email').valid && this.emailForm.get('nowy_email').value.length <= 200) {
                this.translate.get('AccountProperties.ErrorEmail')
                    .subscribe((res: string) => {
                        this.changeEmailAlert.titleTranslated = res;
                    });
            } else if (this.emailForm.get('nowy_email').value.length > 200) {
                this.translate.get('Errors.TOO_LONG_EMAIL')
                    .subscribe((res: string) => {
                      this.changeEmailAlert.titleTranslated = res;
                    });
            } else {
                this.translate.get('AccountProperties.ErrorDifferentFields')
                    .subscribe((res: string) => {
                      this.changeEmailAlert.titleTranslated = res;
                    });
            }
            this.setInputToError(['nowy-email', 'powtorz-nowy-email']);
        }
    }

    validatePassword(): void {
        if (this.passwordForm.get('nowe_haslo').valid &&
            this.passwordForm.get('nowe_haslo').value == this.passwordForm.get('powtorz_nowe_haslo').value) {
            this.changePasswordAlert.active = false;
            this.changePass.nowe_hasło = this.passwordForm.get('nowe_haslo').value;
            this.setInputToCorrect(['nowe-haslo', 'powtorz-nowe-haslo']);
            $('#getPasswordModal').modal('show');
            this.changedSection = 'password';
            this.changePass.token = this.sessionService.getToken();
        } else {
            this.changePasswordAlert.active = true;
            if (!this.passwordForm.get('nowe_haslo').valid && this.passwordForm.get('nowe_haslo').value.length <= 64) {
                this.translate.get('AccountProperties.ErrorPassword')
                    .subscribe((res: string) => {
                      this.changePasswordAlert.titleTranslated = res;
                    });
            } else if (this.passwordForm.get('nowe_haslo').value.length > 64) {
                this.translate.get('Errors.TOO_LONG_PASS')
                    .subscribe((res: string) => {
                        this.changePasswordAlert.titleTranslated = res;
                    });
            } else {
                this.translate.get('AccountProperties.ErrorDifferentFields')
                    .subscribe((res: string) => {
                        this.changePasswordAlert.titleTranslated = res;
                    });
            }
            this.setInputToError(['nowe-haslo', 'powtorz-nowe-haslo']);
        }
    }

    validateAgreements(): void {
        if (this.accountSettings.agreements.find(x => x.id_agreement == 4)) {
            let checkbox = <HTMLInputElement>document.getElementById('zgoda-4');
            if (checkbox.checked != this.accountSettings.agreements.find(x => x.id_agreement == 4).agreement_value &&
                !checkbox.checked) {
                this.currentAgreementForModal = 'medicalDocumentation';
                $('#setAgreementModal').modal('show');
            } else this.saveAccountDataChangeAgreementList();
        } else this.saveAccountDataChangeAgreementList();
    }

    getPassword(section: string): void {
        if (section == 'phone') {
            this.validatePhoneNumber();
        } else if (section == 'personalData') {
            this.validatePersonalData();
        } else if (section == 'email') {
            this.validateEmail();
        } else if (section == 'password') {
            this.validatePassword();
        }
    }

    continueWithPassword(): void {
        if (this.changedSection == 'phone') {
            this.changePhone.password = (<HTMLInputElement>document.getElementById('passwordInput')).value;
            this.saveAccountDataChangePhoneNumber();
        } else if (this.changedSection == 'personalData') {
            this.saveData.password = (<HTMLInputElement>document.getElementById('passwordInput')).value;
            this.saveAccountData();
        } else if (this.changedSection == 'email') {
            this.changeEmail.password = (<HTMLInputElement>document.getElementById('passwordInput')).value;
            this.saveAccountDataChangeEmail();
        } else if (this.changedSection == 'password') {
            this.changePass.stare_haslo = (<HTMLInputElement>document.getElementById('passwordInput')).value;
            this.saveAccountDataChangePassword();
        }
        (<HTMLInputElement>document.getElementById('passwordInput')).value = '';
    }

    wrongPass(): void {
        document.getElementById('passwordInputError').style.display = 'block';
        document.getElementById('passwordInput').setAttribute('class', 'form-control is-error');
    }

    resultCorrect(): void {
        this.getAccountSettingsInfo();
        $('#getPasswordModal').modal('hide');
    }

    setInputToCorrect(inputs: string[]): void {
        for (let i = 0; i < inputs.length; ++i) {
            document.getElementById(inputs[i]).setAttribute('class', 'form-control');
        }
    }

    setInputToError(inputs: string[]): void {
        for (let i = 0; i < inputs.length; ++i) {
            document.getElementById(inputs[i]).setAttribute('class', 'form-control is-error');
        }
    }

    scroll(id: string): void {
        DisplayService.scroll(id);
    }

    confirmationModal(): void {
        this.smsModal.phoneNumber = this.changePhone.numer_telefonu;
        this.smsModal.sendSms(this.saveAccountSettingsToast);
    }

    navigateBack(): void {
        this.navigationService.navigateBack();
    }

    isSectionChanged = (section: string) => {
        let isChanged: boolean = false;
        if (section == 'agreements') {
            let checkbox;
            for (let i: number = 0; i < this.accountSettings.agreements.length; ++i) {
                checkbox = <HTMLInputElement>document.getElementById('zgoda-' + this.accountSettings.agreements[i].id_agreement);
                if (checkbox.checked != this.accountSettings.agreements[i].agreement_value) {
                    isChanged = true;
                }
            }
        } else if (section == 'password') {
            if (this.passwordForm.get('nowe_haslo').value != '' && this.passwordForm.get('powtorz_nowe_haslo').value != '') {
                isChanged = true;
            }
        } else if (section == 'phone') {
            if (this.phoneForm.get('nowy_nr_tel').value != '' ||
                this.phoneForm.get('phoneAgreement').value != this.accountSettings.phone_agreement_info.phone_agreement_value) {
                isChanged = true;
            }
        } else if (section == 'email') {
            if (this.emailForm.get('nowy_email').value != '' && this.emailForm.get('powtorz_nowy_email').value != '') {
                isChanged = true;
            }
        }

        return isChanged;
    }

    countStickySidebarHeight(): Promise<any> {
        return this.userService.userDataPromise.then(() => {
            let height: number = 1050;

            if (this.userService.profileImageUuid == null) height = height - 105;
            if (!this.contextService.checkPermission('firmLink')) height = height - 60;
            if (!this.navigationService.hasContext('ACCOUNT_ACTIVE')) height = height - 60;

            document.getElementById('sticky_sidebar').style.height = height + 'px';
            return height;
        });
    }
}
