import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@/services/page.service';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-list-of-medical-facilities',
    templateUrl: './list-of-medical-facilities.component.html',
    styleUrls: ['./list-of-medical-facilities.component.css']
})
export class ListOfMedicalFacilitiesComponent implements OnInit, OnDestroy {

    constructor(public envService: EnvService, private pageService: PageService) { }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('BeforeWorkDoctor.FindMedicalCenter', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

}
