import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-account',
  templateUrl: './patient-account.component.html',
  styleUrls: ['./patient-account.component.scss']
})
export class PatientAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
