import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Upload } from 'tus-js-client';
import { OdataService } from '@/services';
import { EnvService } from '@/services/env.service';

export interface FileStatus {
  filename: string;
  progress: number;
  hash: string;
  uuid: string;
}

@Injectable({
  providedIn: 'root'
})

export class UploadService {

  constructor(private odataService: OdataService, public envService: EnvService) { }

  private uploadStatus = new Subject<FileStatus[]>();
  uploadProgress = this.uploadStatus.asObservable();
  uploadSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  fileStatusArr: FileStatus[] = [];

  uploadFile(file: File, filename: string) {

    let endpointByService = this.envService.patientExamUploadTus;
    let userSessionToken = this.odataService.paramsTokenAndLang({});
    let uploadToken = userSessionToken['token'];

    const fileStatus: FileStatus = {filename, progress: 0, hash: '', uuid: ''};
    this.fileStatusArr.push(fileStatus);

    this.uploadStatus.next(this.fileStatusArr);

    const upload = new Upload(file, {
      endpoint: endpointByService,
      retryDelays: [0, 3000, 6000, 12000, 24000],
      chunkSize: Infinity,
      metadata: {
        filename,
        filetype: file.type,
        filesize: file.size.toString(),
        uploadToken
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        // let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
        this.fileStatusArr.forEach(value => {
            value.progress = Math.floor(bytesUploaded / bytesTotal * 100);
        });
        // console.log(bytesUploaded, bytesTotal, percentage + '%');
      },
      onError: async (error) => {
        console.log(error);
        this.uploadSuccess.emit(false);
        return false;
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        this.fileStatusArr.forEach(value => {
          if (value.filename === filename) {
            value.progress = Math.floor(bytesAccepted / bytesTotal * 100);
            value.uuid = upload.url.split('/').slice(-1)[0];
          }
        });
        this.uploadStatus.next(this.fileStatusArr);
      },
      onSuccess: async () => {
        this.fileStatusArr.forEach(value => {
          if (value.filename === filename) {
            value.progress = 100;
          }
        });
        this.uploadStatus.next(this.fileStatusArr);
        this.fileStatusArr = [];
        this.uploadSuccess.emit(true);
        return true;
      }
    });
    upload.start();
  }
}
