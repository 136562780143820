export interface IInvoice {
    id: string;
    dateOfIssue: string;
    dateOfSale: string;
    netto: number;
    brutto: number;
}

export interface IBalance {
    index: number;
    date: string;
    sum: number;
}

export interface IOrder {
    date: string;
    physician: string;
    modality: string;
    principal: string;
    sum: number;
}

export interface IBalance {
    date: string;
    sum: number;
}

export let INVOICES: IInvoice[] = [
    {
        id: 'FV 1/04/2020',
        dateOfIssue: '03.04.2020',
        dateOfSale: '01.04.2020',
        netto: 1000,
        brutto: 1023
    },
    {
        id: 'FV 2/04/2020',
        dateOfIssue: '06.04.2020',
        dateOfSale: '05.04.2020',
        netto: 2000,
        brutto: 2046
    }
];

export let BALANCES: IBalance[] = [
    {index: 1, date: '30.03.2020', sum: 2000},
    {index: 2, date: '23.04.2020', sum: 5000},
    {index: 3, date: '11.05.2020', sum: 3000}
];

export let HISTORY: IOrder[] = [
    {
        date: '29.01.2020',
        physician: 'Mirosław Okoń',
        modality: 'TK',
        principal: 'Centrum Zdrowia Szczupak',
        sum: 9600
    },
    {
        date: '13.03.2020',
        physician: 'Jan Baklawa',
        modality: 'RTG',
        principal: 'Centrum Zdrowia Szczupak',
        sum: 6400
    },
    {
        date: '19.04.2020',
        physician: 'Agata Stępień',
        modality: 'USG',
        principal: 'Centrum Zdrowia Mors',
        sum: 11400
    },
    {
        date: '22.01.2020',
        physician: 'Andrzej Rybak',
        modality: 'TK',
        principal: 'Centrum Zdrowia Sum',
        sum: 3200
    },
    {
        date: '22.11.2020',
        physician: 'Janusz Karaś',
        modality: 'RTG',
        principal: 'Centrum Zdrowia Albatros',
        sum: 1500
    }
];
