import { IConsultationAI, initConsultation } from '@/interfaces/AIConsultation';

export interface IAction {
    id_action: number;
    action_name: string;
    button_name: string;
    action_importance: number;
}

export interface IAnatomyPart {
    id_part: number;
    part_name: string;
    anatomy_part_text: string;
}

export interface IIcd10 {
  icd10_code: string;
  icd10_text: string;
  display_name: string;
}

export interface IAttachment {
    filename: string;
    content_type: string;
    attachment_type: {
      id_attachment_type: number;
      attachment_type_text: string;
    };
    attachment_uuid: string;
}

export const emptyAttachment: IAttachment = {
  attachment_type: {attachment_type_text: '', id_attachment_type: 0},
  attachment_uuid: '',
  content_type: '',
  filename: ''
};

export interface IModality {
    id_group: number;
    modality: string;
}

export interface IPriority {
    id_priority: number;
    priority: string;
    priority_text: string;
    priority_value: number;
}

export interface IOrder {
    actions_info: IAction[];
    assign_physician_info: {
        physician_assign: string
    };
    exam_info: {
        sex?: string;
        anatomy: IAnatomyPart,
        birth_date: string,
        evo_accession_number: string,
        icd10: string,
        exam_date: string,
        his_exam_id: string,
        his_patient_id: string,
        modality: IModality,
        order_exam_type: string,
        exam_order_notes: string,
        ordering_facility_name: string,
        describing_facility_name: string,
        patient_name: string,
        pesel: string,
        priority: IPriority,
        transfer_progress: number,
        pm_accession_number: string;
        consultation: IConsultationAI
    };
    order_date: string;
    description_date?: string;
    status_info: {
        id_status: number,
        status: string,
        status_name: string
    };
    id_exam_order: number;
    exam_reject_reason: string;
    reject_info: {
        reject_time: string;
        reject_reason: string;
    };
}

export interface ISavedResult {
    success: boolean;
    exam_id: number;
}

export interface IOrdersRefreshStatus {
    exam_all_count: number;
    exam_cito_count: number;
    exam_normal_count: number;
    is_refresh_needed: boolean;
    exam_emergency_count: number;
}

export const initRefreshOrdersStatus: IOrdersRefreshStatus = {
    exam_all_count: 0,
    exam_cito_count: 0,
    exam_normal_count: 0,
    is_refresh_needed: false,
    exam_emergency_count: 0
};

export const initOrder: IOrder = {
  actions_info: [],
    assign_physician_info: {
        physician_assign: ''
    },
    exam_info: {
        anatomy: {
          id_part: 0,
          part_name: '',
          anatomy_part_text: ''
        },
        birth_date: '',
        evo_accession_number: '',
        icd10: '',
        exam_date: '',
        his_exam_id: '',
        his_patient_id: '',
        modality: {
          id_group: 0,
          modality: ''
        },
        sex: '',
        order_exam_type: '',
        exam_order_notes: '',
        ordering_facility_name: '',
        describing_facility_name: '',
        patient_name: '',
        pesel: '',
        priority: {
          id_priority: 0,
          priority: '',
          priority_text: '',
          priority_value: 0
        },
        transfer_progress: 100,
        pm_accession_number: '',
        consultation: initConsultation
    },
    order_date: '',
    status_info: {
        id_status: 0,
        status: '',
        status_name: ''
    },
    id_exam_order: 0,
    exam_reject_reason: '',
    reject_info: {
        reject_time: '',
        reject_reason: '',
    }
};
