import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, EventEmitter, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { FiltersService } from '@/services/filters.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

    @Input() placeholder: any;
    searchPlaceholder: string = '';
    filterSearch: string = '';
    isSearch: boolean = false;
    searchEvent: EventEmitter<number> = new EventEmitter<number>();
    clearFromFiltersSubscription: Subscription;

    constructor(private translate: TranslateService, private filtersService: FiltersService) { }

    ngOnInit() {
        this.translate.get(this.placeholder)
          .subscribe((res: string) => {
            this.searchPlaceholder = res;
          });
        this.clearFromFiltersSubscription = this.filtersService.clearSearchFromFilterTileEvent.subscribe((res) => this.clearSearchInput(res));
    }

    ngOnDestroy(): void {
      this.clearFromFiltersSubscription.unsubscribe();
    }

    search(event) {
        if (this.filterSearch.length >= 1) document.getElementById('clearSearchButton').style.display = 'block';
        else if (this.filterSearch.length == 0) document.getElementById('clearSearchButton').style.display = 'none';

        if (event.keyCode == 13) {
          this.isSearch = true;
          this.searchEvent.emit(1);
        }
    }

    clearSearchInput(ifEmit: boolean): void {
        this.filterSearch = '';
        this.isSearch = false;
        $('#searchInput').val('');
        if (ifEmit) this.searchEvent.emit(1);
        document.getElementById('clearSearchButton').style.display = 'none';
    }

    setPlaceholder(text) {
      this.translate.get(text)
          .subscribe((res: string) => {
            this.searchPlaceholder = res;
          });
    }

}
