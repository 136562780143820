import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Upload } from 'tus-js-client';
import { OdataService } from '@/services';
import { EnvService } from '@/services/env.service';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "./env.service";
var UploadService = /** @class */ (function () {
    function UploadService(odataService, envService) {
        this.odataService = odataService;
        this.envService = envService;
        this.uploadStatus = new Subject();
        this.uploadProgress = this.uploadStatus.asObservable();
        this.uploadSuccess = new EventEmitter();
        this.fileStatusArr = [];
    }
    UploadService.prototype.uploadFile = function (file, filename) {
        var _this = this;
        var endpointByService = this.envService.patientExamUploadTus;
        var userSessionToken = this.odataService.paramsTokenAndLang({});
        var uploadToken = userSessionToken['token'];
        var fileStatus = { filename: filename, progress: 0, hash: '', uuid: '' };
        this.fileStatusArr.push(fileStatus);
        this.uploadStatus.next(this.fileStatusArr);
        var upload = new Upload(file, {
            endpoint: endpointByService,
            retryDelays: [0, 3000, 6000, 12000, 24000],
            chunkSize: Infinity,
            metadata: {
                filename: filename,
                filetype: file.type,
                filesize: file.size.toString(),
                uploadToken: uploadToken
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                // let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                _this.fileStatusArr.forEach(function (value) {
                    value.progress = Math.floor(bytesUploaded / bytesTotal * 100);
                });
                // console.log(bytesUploaded, bytesTotal, percentage + '%');
            },
            onError: function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    console.log(error);
                    this.uploadSuccess.emit(false);
                    return [2 /*return*/, false];
                });
            }); },
            onChunkComplete: function (chunkSize, bytesAccepted, bytesTotal) {
                _this.fileStatusArr.forEach(function (value) {
                    if (value.filename === filename) {
                        value.progress = Math.floor(bytesAccepted / bytesTotal * 100);
                        value.uuid = upload.url.split('/').slice(-1)[0];
                    }
                });
                _this.uploadStatus.next(_this.fileStatusArr);
            },
            onSuccess: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.fileStatusArr.forEach(function (value) {
                        if (value.filename === filename) {
                            value.progress = 100;
                        }
                    });
                    this.uploadStatus.next(this.fileStatusArr);
                    this.fileStatusArr = [];
                    this.uploadSuccess.emit(true);
                    return [2 /*return*/, true];
                });
            }); }
        });
        upload.start();
    };
    UploadService.ngInjectableDef = i0.defineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.inject(i1.OdataService), i0.inject(i2.EnvService)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
