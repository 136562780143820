import { IAction, IAnatomyPart, IAttachment, IModality, IPriority } from './Order';
import { IConsultationAI, initConsultation } from '@/interfaces/AIConsultation';
export interface IMedFacility {
    imageUuid: string;
    idMedicalFacility: number;
    medicalFacilityName: string;
    medicalFacilityUuid: string;
}

export interface IToastInfo {
  message: string;
  timeout: number;
  type: string;
  refresh: boolean;
}

export interface IMFExamStatus {
    id_status: number;
    status_text: string;
    status_name: string;
}

export interface IExamDescInfo {
  pwz: string;
  image_uuid: string;
  id_physician: number;
  physician_name: string;
  exam_description: string;
  image_uuid_serial: string;
  attention_required: boolean;
  exam_description_date: string;
}

export interface IOrderInfo {
    status: string;
    id_status: number;
    order_date: string;
    status_name: string;
    order_exam_name: string;
    dc_id_exam_order: number;
    mf_id_exam_order: number;
    order_action_list: IAction[];
    describing_center_name: string;
    icd10: string;
    icd10_text: string;
}

export interface IFacilityOrders {
    id_exam_order: number;
    icd10: string;
    pesel: string;
    anatomy: {
        id_part: number;
        part_name: string;
    };
    modality: {
        id_group: number;
        modality: string;
    };
    priority: {
        id_priority: number;
        priority: string;
    };
    exam_date: string;
    exam_order_date: string;
    birth_date: string;
    icd10_text: string;
    action_list: IAction[];
    his_exam_id: string;
    status_info: {
        id_status: number;
        status: string;
        status_name: string;
    };
    patient_name: string;
    order_exam_type: string;
    exam_order_notes: string;
    evo_accession_number: string;
    ordering_facility_name: string;
    describing_facility_name: string;
    transfer_progress: number;
    pm_accession_number: string;
}

export interface IFacilityExams {
  sex: string;
  pesel: string;
  id_exam: number;
  pm_info: string;
  exam_date: string;
  last_name: string;
  birth_date: string;
  first_name: string;
  id_patient: number;
  his_exam_id: string;
  orders_info: {
    exam_order_info: IOrderInfo[];
    exam_order_count: number;
  };
  exam_actions: IAction[];
  his_patient_id: string;
  mf_exam_status: IMFExamStatus;
  anatomy_part: {
    id_part: number;
    anatomy_part_text: string;
  };
  order_exam_name: string;
  id_medical_facility: number;
  order_exam_modality: string;
  evo_accession_number: string;
  exam_accession_number: string;
  order_exam_identifier: string;
  consultation: IConsultationAI;
}

export const initFacilityOrder: IFacilityOrders = {
    id_exam_order: 0,
    icd10: '',
    pesel: '',
    anatomy: {
        id_part: 0,
        part_name: '',
    },
    modality: {
        id_group: 0,
        modality: '',
    },
    priority: {
        id_priority: 0,
        priority: '',
    },
    exam_date: '',
    exam_order_date: '',
    birth_date: '',
    icd10_text: '',
    action_list: [],
    his_exam_id: '',
    status_info: {
        id_status: 0,
        status: '',
        status_name: '',
    },
    patient_name: '',
    order_exam_type: '',
    exam_order_notes: '',
    evo_accession_number: '',
    ordering_facility_name: '',
    describing_facility_name: '',
    transfer_progress: 0,
    pm_accession_number: ''
};

export interface IFacilityOrderDetails {
  icd10: string;
  pesel: string;
  anatomy: IAnatomyPart;
  modality: IModality;
  priority: IPriority;
  exam_date: string;
  exam_order_date: string;
  birth_date: string;
  icd10_text: string;
  his_exam_id: string;
  status_info: {
    id_status: number;
    status: string;
    status_name: string;
  };
  exam_description_history: IExamDescInfo[];
  exam_description_info: IExamDescInfo;
  patient_name: string;
  order_exam_type: string;
  exam_order_notes: string;
  evo_accession_number: string;
  ordering_facility_name: string;
  describing_facility_name: string;
  transfer_progress: number;
  pm_accession_number: string;
  attachment_list: IAttachment[];
  exam_order_notes_array: string[];
  his_patient_id: number;
  id_exam_order: number;
  id_patient: number;
  patient_age: string;
}

export const initFacilityOrderDetails: IFacilityOrderDetails = {
  exam_description_history: [],
  exam_description_info: {
    attention_required: false,
    exam_description: '',
    exam_description_date: '',
    id_physician: 0,
    image_uuid: '',
    image_uuid_serial: '',
    physician_name: '',
    pwz: ''
  },
  exam_order_notes_array: [],
  his_patient_id: 0,
  id_exam_order: 0,
  id_patient: 0,
  patient_age: '',
  anatomy: {id_part: 0, part_name: '', anatomy_part_text: ''},
  attachment_list: [],
  birth_date: '',
  describing_facility_name: '',
  evo_accession_number: '',
  exam_date: '',
  exam_order_date: '',
  exam_order_notes: '',
  his_exam_id: '',
  icd10: '',
  icd10_text: '',
  modality: {id_group: 0, modality: ''},
  order_exam_type: '',
  ordering_facility_name: '',
  patient_name: '',
  pesel: '',
  pm_accession_number: '',
  priority: {id_priority: 0, priority: '', priority_text: '', priority_value: 0},
  status_info: {id_status: 0, status: '', status_name: ''},
  transfer_progress: 0
};



export const initFacilityExam: IFacilityExams = {
  anatomy_part: {anatomy_part_text: '', id_part: 0},
  birth_date: '',
  consultation: initConsultation,
  evo_accession_number: '',
  exam_accession_number: '',
  exam_actions: [],
  exam_date: '',
  first_name: '',
  his_exam_id: '',
  his_patient_id: '',
  id_exam: 0,
  id_medical_facility: 0,
  id_patient: 0,
  last_name: '',
  mf_exam_status: {id_status: 0, status_name: '', status_text: ''},
  order_exam_identifier: '',
  order_exam_modality: '',
  order_exam_name: '',
  orders_info: {exam_order_count: 0, exam_order_info: []},
  pesel: '',
  pm_info: '',
  sex: ''
};

export const initExamDescInfo: IExamDescInfo = {
  attention_required: false,
  exam_description: '',
  exam_description_date: '',
  id_physician: 0,
  image_uuid: '',
  image_uuid_serial: '',
  physician_name: '',
  pwz: ''
};
