<div class="foot static" id="pp-footer">

    <div class="pp-footer-inner">
        <div class="footer-left">
            <a target="_blank" href="{{envService.mainPage}}" class="z-logo-small-white">
                <svg-icon alt="" class="svg" src="../../assets/images/logo-z-white.svg"></svg-icon>
            </a>
            <span>Zbadani.pl &copy; {{ currentYear }}</span>
        </div>
        
        <div class="footer-right">
            <a target="_blank" href="{{envService.regulationsPath}}">{{'Footer.Regulations' | translate}}</a>
            <a target="_blank" href="{{envService.privacyPolicyPath}}">{{'Footer.PrivacyPolicy' | translate}}</a>
            <span>{{'Footer.Version' | translate}}{{': '+version}}</span>
        </div>
    </div>

</div>