import { ActionReducer, Action, MetaReducer } from '@ngrx/store';

function setSavedState(state: any, localStorageKey: string) {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
}
function getSavedState(localStorageKey: string): any {
  return JSON.parse(localStorage.getItem(localStorageKey));
}

function clearAppState(localStorageKey: string): any {
  localStorage.removeItem(localStorageKey);
}

const localStorageKey = 'app_state';
const persistentState: boolean = true;

export function storageMetaReducer<S, A extends Action = Action> (reducer: ActionReducer<S, A>) {
  let onInit = true;
  return (state: S, action: A): S => {
    const appState = reducer(state, action);
    if (onInit) {
      onInit = false;
      const rehydratedState = getSavedState(localStorageKey);
      clearAppState(localStorageKey);
      return {...appState, ...rehydratedState};
    }
    setSavedState(appState, localStorageKey);
    return appState;
  };
}

export const metaReducers: MetaReducer<any>[] = persistentState ? [storageMetaReducer] : [];
