import { AfterViewInit, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import moment from 'moment';
import { FiltersService } from '@/services/filters.service';
import { SessionService } from '@/services';
import { FiltersComponent } from '@/utils/filters/filters.component';
var PatientFiltersComponent = /** @class */ (function () {
    function PatientFiltersComponent(filterService, sessionService, renderer) {
        this.filterService = filterService;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.chosenFilter = {
            id: 0,
            value: FiltersComponent.createInitialFiltrPatients(),
            name: '',
            type: 'patients',
            isFavourite: false
        };
        this.statusTypes = [];
        this.facilityTypes = [];
        this.doctorsForPatientsList = [];
        this.selectpickers = {
            sexPicker: null,
            facilityPicker: null,
            doctorsPicker: null,
            statusPicker: null
        };
        this.daterange = {};
        this.isActiveDateSort = false;
        this.enOptions = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Current Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: [
                    'Mo',
                    'Tu',
                    'We',
                    'Th',
                    'Fr',
                    'Sa',
                    'Su'
                ],
                monthNames: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                'firstDay': 0
            },
            alwaysShowCalendars: false,
        };
        this.plOptions = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment()],
                'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment()],
                'Ten Miesiąc': [moment().startOf('month'), moment().endOf('month')],
                'Ostatni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD',
                applyLabel: 'Zatwierdź',
                cancelLabel: 'Anuluj',
                fromLabel: 'Z',
                toLabel: 'Do',
                customRangeLabel: 'Własny przedział',
                daysOfWeek: [
                    'Po',
                    'Wt',
                    'Śr',
                    'Cz',
                    'P',
                    'So',
                    'N'
                ],
                'monthNames': [
                    'Styczeń',
                    'Luty',
                    'Marzec',
                    'Kwiecień',
                    'Maj',
                    'Czerwiec',
                    'Lipiec',
                    'Sierpień',
                    'Wrzesień',
                    'Październik',
                    'Listopad',
                    'Grudzień'
                ],
                'firstDay': 0
            },
            alwaysShowCalendars: false,
        };
        this.isSelects = false;
        this.currentDateValue = '';
        this.options = this.setLangForDatepicker(this.sessionService.language);
    }
    Object.defineProperty(PatientFiltersComponent.prototype, "PickerSetter", {
        set: function (content) {
            this.picker = content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientFiltersComponent.prototype, "pickerPlaceholderSetter", {
        set: function (content) {
            this.picker_placeholder = content;
        },
        enumerable: true,
        configurable: true
    });
    PatientFiltersComponent.prototype.ngOnInit = function () {
    };
    PatientFiltersComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', function () {
            _this.activateDatepicker();
        });
    };
    PatientFiltersComponent.prototype.ngOnDestroy = function () {
        this.globalPickerListener();
    };
    PatientFiltersComponent.prototype.activateDatepicker = function () {
        this.picker.nativeElement.click();
    };
    PatientFiltersComponent.prototype.setCurrentFilter = function (filter) {
        this.daterange.start = moment().subtract(29, 'days');
        this.daterange.end = moment();
        this.chosenFilter = filter;
        if (this.chosenFilter.value.birth_date.birth_date_from != '') {
            if (!this.isActiveDateSort) {
                this.isActiveDateSort = true;
            }
            this.daterange.start = this.chosenFilter.value.birth_date.birth_date_from;
            this.daterange.end = this.chosenFilter.value.birth_date.birth_date_to;
            this.updatePickerPlaceholder(new Date(this.chosenFilter.value.birth_date.birth_date_from), new Date(this.chosenFilter.value.birth_date.birth_date_to));
        }
        else
            this.clearDaterangeFilter();
    };
    PatientFiltersComponent.prototype.saveChanges = function (name) {
        var _this = this;
        this.chosenFilter.value = FiltersComponent.createInitialFiltrPatients();
        this.selectpickers.statusPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.status.push(parseInt(value));
        });
        this.chosenFilter.value.sex = this.selectpickers.sexPicker.selectpicker('val');
        this.selectpickers.facilityPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.facility.push(parseInt(value));
        });
        this.selectpickers.doctorsPicker.selectpicker('val').forEach(function (value) {
            _this.chosenFilter.value.physician.push(parseInt(value));
        });
        if (this.getDate()) {
            return this.filterService.saveFilter(this.chosenFilter.value, this.chosenFilter.type, name, this.chosenFilter.isFavourite);
        }
    };
    PatientFiltersComponent.prototype.setFilterData = function () {
        var _this = this;
        this.isSelects = true;
        this.selectpickers.sexPicker = $('#sex')['selectpicker']();
        var promises = [];
        promises.push(this.getStatusTypes());
        promises.push(this.getFacilityTypes());
        promises.push(this.getDoctorsForPatients());
        Promise.all(promises).then(function () { return _this.setFilter(); });
    };
    PatientFiltersComponent.prototype.getStatusTypes = function () {
        var _this = this;
        return this.filterService.getStatusTypesForPatients().then(function (res) {
            _this.statusTypes = [];
            res.forEach(function (v) {
                _this.statusTypes.push({
                    value: v,
                    selected: false
                });
            });
            _this.selectpickers.statusPicker = $('#status')['selectpicker']();
        });
    };
    PatientFiltersComponent.prototype.getFacilityTypes = function () {
        var _this = this;
        return this.filterService.getFacilityTypes().then(function (res) {
            res.forEach(function (v) {
                _this.facilityTypes.push({ value: v, selected: false });
            });
            _this.selectpickers.facilityPicker = $('#facility')['selectpicker']();
        });
    };
    PatientFiltersComponent.prototype.getDoctorsForPatients = function () {
        var _this = this;
        return this.filterService.getDoctorsForPatients().then(function (res) {
            res.forEach(function (v) {
                _this.doctorsForPatientsList.push({
                    value: {
                        name: v.physician_name,
                        id_physician: v.id_physician,
                        profile_image_uuid: v.profile_image_uuid
                    }, selected: false
                });
            });
            _this.selectpickers.doctorsPicker = $('#doctors')['selectpicker']();
        });
    };
    PatientFiltersComponent.prototype.setFilter = function () {
        var _this = this;
        // Status
        this.statusTypes.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.status.forEach(function (status) {
            _this.statusTypes.forEach(function (o) {
                if (status == o.value.id_status) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.statusPicker.selectpicker('val', _this.chosenFilter.value.status); }, 0);
        // Facility
        this.facilityTypes.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.facility.forEach(function (facility) {
            _this.facilityTypes.forEach(function (o) {
                if (facility == o.value.id_facility) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.facilityPicker.selectpicker('val', _this.chosenFilter.value.facility); }, 0);
        // Doctors
        this.doctorsForPatientsList.forEach(function (o) {
            o.selected = false;
        });
        this.chosenFilter.value.physician.forEach(function (physician) {
            _this.doctorsForPatientsList.forEach(function (o) {
                if (physician == o.value.id_physician) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () { return _this.selectpickers.doctorsPicker.selectpicker('val', _this.chosenFilter.value.physician); }, 0);
        // Sex
        setTimeout(function () { return _this.selectpickers.sexPicker.selectpicker('val', _this.chosenFilter.value.sex); }, 0);
    };
    PatientFiltersComponent.prototype.setLangForDatepicker = function (lang) {
        if (lang == 'en') {
            return this.enOptions;
        }
        else
            return this.plOptions;
    };
    PatientFiltersComponent.prototype.selectedDate = function (value, datepicker) {
        // this is the date the user selected
        if (!this.isActiveDateSort) {
            this.isActiveDateSort = true;
        }
        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;
        // or manupulat your own internal property
        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
        this.updatePickerPlaceholder(value.start._d, value.end._d);
    };
    PatientFiltersComponent.prototype.updatePickerPlaceholder = function (start_date, end_date) {
        var start_date_str = moment(start_date).format('YYYY-MM-DD').toString();
        var end_date_str = moment(end_date).format('YYYY-MM-DD').toString();
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content);
        }
    };
    PatientFiltersComponent.prototype.updatePickerPlaceholderContent = function (content) {
        this.currentDateValue = content;
    };
    PatientFiltersComponent.prototype.getDateRange = function () {
        return {
            birth_date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
            birth_date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
        };
    };
    PatientFiltersComponent.prototype.getDate = function () {
        var tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input-patient').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            }
            else if (this.currentDateValue == '') {
                this.clearDaterangeFilter();
            }
            else {
                this.chosenFilter.value.birth_date = this.getDateRange();
            }
        }
        else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        this.chosenFilter.value.birth_date.birth_date_from = this.currentDateValue.substring(0, 10);
                        this.chosenFilter.value.birth_date.birth_date_to = this.currentDateValue.substring(13, 23);
                    }
                    else {
                        this.chosenFilter.value.birth_date.birth_date_from = this.currentDateValue;
                        this.chosenFilter.value.birth_date.birth_date_to = this.currentDateValue;
                    }
                    this.daterange.start = this.chosenFilter.value.birth_date.birth_date_from;
                    this.daterange.end = this.chosenFilter.value.dbirth_date.birth_date_to;
                }
                else {
                    document.getElementById('placeholder-input-patient').setAttribute('class', 'error');
                    tmp = false;
                }
            }
            else {
                this.clearDaterangeFilter();
            }
        }
        return tmp;
    };
    PatientFiltersComponent.prototype.clearDaterangeFilter = function () {
        this.isActiveDateSort = false;
        this.updatePickerPlaceholderContent('');
        this.chosenFilter.value.birth_date.birth_date_from = '';
        this.chosenFilter.value.birth_date.birth_date_to = '';
    };
    PatientFiltersComponent.prototype.isValidDateRange = function (dateRange) {
        if (dateRange.length != 23 && dateRange.length != 10) {
            return false;
        }
        else {
            if (dateRange.length == 23) {
                var from = dateRange.substring(0, 10);
                var to = dateRange.substring(13, 23);
                if (!moment(from, 'YYYY-MM-DD').isValid() || !moment(to, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
            else {
                if (!moment(dateRange, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
        }
        return true;
    };
    return PatientFiltersComponent;
}());
export { PatientFiltersComponent };
