import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { UsersService, OdataService, SessionService } from '@/services';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '../alert/alert.component';
import * as $AB from 'jquery';
import * as bootstrap from 'bootstrap';
import { UniversalToastComponent } from '../universal-toast/universal-toast.component';

@Component({
    selector: 'app-sms-modal',
    templateUrl: './sms-modal.component.html',
    styleUrls: ['./sms-modal.component.css']
})
export class SmsModalComponent implements OnInit, OnChanges {

    @ViewChild('errorAlert') errorAlert: AlertComponent;
    @Input() phoneNumber;

    numberSentInfo: string;
    pin: string = '';

    smsToken: {
        Status: string,
        SmsUuid: string,
        SmsDate: Date,
        SmsOperation: Number
    };

    constructor(private userService: UsersService, private odataService: OdataService,
        private translate: TranslateService, private sessionService: SessionService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.translate.get('PhoneModal.NumberSentInfo', { value: this.phoneNumber })
            .subscribe((res: string) => {
                this.numberSentInfo = res;
            });
    }

    sendSms(toast: UniversalToastComponent) {
        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage
        };

        this.odataService.postPrivate('create_sms_token', params, (res) => {
            let string = JSON.stringify(res);
            let result = JSON.parse(string);
            if (result.Status == 'OK') {
                this.smsToken = result;
                $('#smsModal').modal('show');
            } else {
                toast.error('OperationFailed');
                toast.show();
                setTimeout(() => toast.hide(), 1000);
            }
        });

    }

    confirmPin() {
        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            sms_uuid: this.smsToken.SmsUuid,
            sms_pin: this.pin
        };

        this.odataService.postPrivate('check_sms_token', params, (res) => {
            let string = JSON.stringify(res);
            let result = JSON.parse(string);
            if (result.Status == 'OK' && result.SmsToken == 'TOKEN_OK') {
                this.smsToken = result;
                $('#smsModal').modal('hide');
            } else if (result.SmsToken == 'TOKEN_INVALID') {
                this.errorAlert.displayError('SMS_TOKEN_INVALID');
            } else {
                console.log('TODO other errors');
                // TODO other errors
            }

        });
    }

}
