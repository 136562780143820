<div id="top-bar">
  <div class="row">

    <div class="col-md-12">
      <h3 class="page-name">{{'SettingsScreens.Title' | translate}}</h3>
      <ul id="top-bar-nav">
        <li id="SettingsScreensUsers">
          <a routerLink="/medicalFacilityUsersSettings" style="cursor: pointer;">{{'SettingsScreens.Users' | translate}}</a>
        </li>
        <li id="SettingsScreensTeams">
          <a routerLink="/medicalFacilityTeamSettings" style="cursor: pointer;">{{'SettingsScreens.Teams' | translate}}</a>
        </li>
        <li class="active" id="SettingsScreensPermissions">
          <a routerLink="/medicalFacilityPermissionSettings" style="cursor: pointer;">{{'SettingsScreens.Permissions' | translate}}</a>
        </li>
        <li id="SettingsScreensRoles">
          <a routerLink="/medicalFacilityRoleSettings" style="cursor: pointer;">{{'SettingsScreens.Roles' | translate}}</a>
        </li>
      </ul>

      <form>
        <app-search #search placeholder="SettingsScreens.SearchPlaceholder"></app-search>
      </form>
    </div>

  </div>
</div>

<div class="container-settings">

  <div class="row">
    <div class="col-md-12">
      <h6 class="pb-2">{{'SettingsScreens.PermissionsSettings.Orders' | translate}}</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="card tiny-card"><span>{{'SettingsScreens.PermissionsSettings.AddOrders' | translate}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction5"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction5">
          <a class="dropdown-item"
             (click)="this.editPermission.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card">
        <span>{{'SettingsScreens.PermissionsSettings.OrderProperties' | translate}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction6"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction6">
          <a class="dropdown-item"
             (click)="this.editPermission.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card">
        <span>{{'SettingsScreens.PermissionsSettings.ChangeOrderStatus' | translate}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction7"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction7">
          <a class="dropdown-item"
             (click)="this.editPermission.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card tiny-card">
        <span>{{'SettingsScreens.PermissionsSettings.RewritingOrder' | translate}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction7"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction7">
          <a class="dropdown-item"
             (click)="this.editPermission.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <h6 class="pt-5 pb-2">{{'SettingsScreens.PermissionsSettings.Descriptions' | translate}}</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="card tiny-card"><span>{{'SettingsScreens.PermissionsSettings.NextOrders' | translate}}</span>
        <button type="button" class="btn btn-light card-action" id="ddAction5"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg-icon class="svg" src="../../../../../assets/images/icons/more-dots.svg" alt=""></svg-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction5">
          <a class="dropdown-item"
             (click)="this.editPermission.show();">{{'SettingsScreens.EditRoles' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

</div>

<app-double-select-modal #editPermission
                         title="Edycja ról"
                         subtitle="{Nazwa uprawnienia}"
                         left_title="Dostępne role"
                         right_title="Przypisane role"></app-double-select-modal>
