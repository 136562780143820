<div class="col-col-md-12" id="top-bar">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'DashboardScreen.Invoices' | translate}}</h3>

            <ul id="top-bar-nav">
                <li>
                    <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" href="#" id="top-bar-wiecej">
                        {{'Datatable.Invoices.Last30' | translate}}
                        <svg-icon alt=""class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg"></svg-icon>
                    </a>
                    <div aria-labelledby="top-bar-wiecej" class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.Today' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.Yesterday' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.Last7' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.Last30' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.ThisMonth' | translate}}</a>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.LastMonth' | translate}}</a>
                        <hr>
                        <a class="dropdown-item" routerLink="/siteUnderConstruction">{{'Datatable.Invoices.OwnRange' | translate}}</a>
                    </div>
                </li>
            </ul>

            <form>
                <app-search #search placeholder="Datatable.Invoices.SearchPlaceholder"></app-search>
            </form>

        </div>
    </div>

</div>

<app-datatable #datatable type="INVOICES"></app-datatable>