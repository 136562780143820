/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pdf-not-available-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pdf-not-available-modal.component";
var styles_PdfNotAvailableModalComponent = [i0.styles];
var RenderType_PdfNotAvailableModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PdfNotAvailableModalComponent, data: {} });
export { RenderType_PdfNotAvailableModalComponent as RenderType_PdfNotAvailableModalComponent };
export function View_PdfNotAvailableModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["aria-hidden", "true"], ["aria-labelledby", "pdfNotAvailableModalLabel"], ["class", "modal fade"], ["id", "pdfNotAvailableModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "modal-header d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nie mo\u017Cna pobra\u0107 opisu"]))], null, null); }
export function View_PdfNotAvailableModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-not-available-modal", [], null, null, null, View_PdfNotAvailableModalComponent_0, RenderType_PdfNotAvailableModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.PdfNotAvailableModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PdfNotAvailableModalComponentNgFactory = i1.ɵccf("app-pdf-not-available-modal", i2.PdfNotAvailableModalComponent, View_PdfNotAvailableModalComponent_Host_0, {}, {}, []);
export { PdfNotAvailableModalComponentNgFactory as PdfNotAvailableModalComponentNgFactory };
