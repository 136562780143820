import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UsersService, OdataService, SessionService } from '@/services';
import { DoubleSelectModalComponent } from 'src/app/utils/double-select-modal/double-select-modal.component';
import { EnvService } from '@/services/env.service';
import { PageService } from '@/services/page.service';
import { InviteToCooperationModalComponent } from '@/utils/invite-to-cooperation-modal/invite-to-cooperation-modal.component';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-users-settings',
    templateUrl: './users-settings.component.html',
    styleUrls: ['./users-settings.component.css']
})
export class UsersSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('editRole') editRole: DoubleSelectModalComponent;
    @ViewChild('inviteToCooperationModal') inviteToCooperationModal: InviteToCooperationModalComponent;
    @ViewChild('actionToast') actionToast: UniversalToastComponent;

    odataService: OdataService;
    userService: UsersService;

    settings_users: any[];
    valid_search = true;
    asyncSelected: string;
    dataSource: Observable<any>;
    typeaheadLoading: boolean;
    user_name_to_invite: string = '';
    search_value: number = undefined;
    private search_text: string = '';
    private user_to_invite: number = undefined;
    private from_search: boolean = false;
    timeout: any;

    constructor(userService: UsersService, odataService: OdataService, private sessionService: SessionService,
        private env: EnvService, private pageService: PageService, private router: Router) {
        this.odataService = odataService;
        this.userService = userService;

        this.dataSource = new Observable((observer: any) => {
            observer.next(this.asyncSelected);
        })
            .pipe(
                mergeMap((token: string) => this.getDataAsObservable(token)),
                catchError(this.handleError)
            );
    }

    ngOnInit() {
        if (this.userService.checkDcPermission('DC_MODYFING_PROFILE')) document.getElementById('settings').setAttribute('class', 'active');
        else this.router.navigate(['/']);
        this.pageService.setSettingPageTranslatedTitle('SettingsScreens.Users', 0);
        this.getCoStaffs();
        this.starTimer();
    }

    ngOnDestroy() {
        this.stopTimer();
        this.pageService.resetPageTitle();
        if (this.userService.checkDcPermission('DC_MODYFING_PROFILE')) document.getElementById('settings').setAttribute('class', '');
    }

    getDataAsObservable(search: string): Observable<any> {
        if (search.length < 3) {
            return of<any>([]);
        }

        return this.odataService.postPrivateObservable('get_co_search_available_staff', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: this.userService.getCenterId(),
            filtr: search,
            limit: 10
        }).pipe(
            map(data => this.dataMap(data)),
        );
    }

    dataMap(result) {
        let to_return = [];

        if (result.Status === 'OK') {
            let users: any[] = JSON.parse(result.Value);

            for (let i = 0; i < users.length; i++) {
                if ('profile_image_uuid' in users[i] && users[i].profile_image_uuid !== null && users[i].profile_image_uuid !== '') {
                    users[i]['profile_image'] = this.env.profilePicURL + users[i].profile_image_uuid;
                } else {
                    users[i]['profile_image'] = '../../../../../assets/images/icons/user.svg';
                    users[i]['class'] = 'svg';
                }
            }
            to_return = users;
        } else {
            console.log('REASON:', result.Status);
        }
        return to_return;
    }

    handleError(error: HttpErrorResponse) {
        console.log(error);
        return of([]);
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        this.search_value = e.item.id_user;
        this.search_text = e.item.username;
    }

    onValueChange() {
        if (this.search_value !== undefined) {
            this.search_value = undefined;
            this.search_text = '';
        }
    }

    getCoStaffs() {
        this.odataService.postPrivate('get_co_staffs',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let users: any[] = JSON.parse(result.Value);

                    for (let i = 0; i < users.length; i++) {
                        if ('profile_image_uuid' in users[i] && users[i].profile_image_uuid !== null && users[i].profile_image_uuid !== '') {
                            users[i]['profile_image'] = this.env.profilePicURL + users[i].profile_image_uuid;
                        } else {
                            users[i]['profile_image'] = '../../../../../assets/images/icons/user.svg';
                            users[i]['class'] = 'svg';
                        }
                    }

                    this.settings_users = users;
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    coSaveUserRoleAssign(id_user: number, roless: number[]) {
        this.odataService.postPrivate('co_save_user_role_assign',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_modify: id_user,
                role_assign: JSON.stringify(roless)
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    this.editRole.hide();
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    onCooperationInvite() {
        if (this.search_value !== undefined) {
            this.from_search = true;
            this.inviteToCooperationModal.show(this.search_text, this.search_value, this.userService.getCenterName());
        }
    }

    onEditRole(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.editRole.setId('' + user.id_user);
        this.editRole.setSubtitle(user.username);

        this.odataService.postPrivate('get_co_staff_role_assign',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_check: user.id_user
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let roles: any[] = JSON.parse(result.Value);
                    let granted: any[] = [];
                    let not_granted: any[] = [];

                    for (let i = 0; i < roles.length; i++) {
                        if ('id_role' in roles[i] && roles[i].id_role) {
                            let item = {
                                id: roles[i].id_role,
                                text: roles[i].role_name,
                            };

                            if ('role_granted' in roles[i] && roles[i].role_granted) {
                                granted.push(item);
                            } else {
                                not_granted.push(item);
                            }
                        }
                    }

                    this.editRole.setContent(not_granted, granted);
                    this.editRole.show();

                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    onSelect(event: any) {
        if (event === undefined) {
            return;
        }

        if (!('id' in event) || !('selected' in event)) {
            return;
        }

        const id_role = +event.id;
        let users: number[] = [];

        for (let i = 0; i < event.selected.length; i++) {
            if ('id' in event.selected[i]) {
                users.push(+event.selected[i].id);
            }
        }

        this.coSaveUserRoleAssign(id_role, users);
    }

    onBlock(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.odataService.postPrivate('co_disable_staff',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_block: user.id_user
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let respons = JSON.parse(result.Value);

                    if (!respons.status) {
                        // TODO: pokazać okno modelne z informacją o błędzie.
                        console.log(respons.error_msg);
                    }

                    this.realoadList();

                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    onUnBlock(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.odataService.postPrivate('co_enable_staff',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_unblock: user.id_user
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let respons = JSON.parse(result.Value);

                    if (!respons.status) {
                        // TODO: pokazać okno modelne z informacją o błędzie.
                        console.log(respons.error_msg);
                    }

                    this.realoadList();

                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    onDelete(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.odataService.postPrivate('co_delete_staff',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_delete: user.id_user
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let respons = JSON.parse(result.Value);

                    if (!respons.status) {
                        // TODO: pokazać okno modelne z informacją o błąędzie.
                        console.log(respons.error_msg);
                    }

                    this.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    onInviteAgain(user: any) {
        if (user.id_user !== undefined) {
            this.inviteToCooperationModal.show(user.username, user.id_user, this.userService.getCenterName());
        }
    }

    onCancelInvitation(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.odataService.postPrivate('co_cancel_invitation',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_cancel_invitation: user.id_user
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let respons = JSON.parse(result.Value);

                    if (!respons.status) {
                        // TODO: pokazać okno modelne z informacją o błąędzie.
                        console.log(respons.error_msg);
                    }

                    this.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    onAcceptApplication(user: any) {
        if (user.id_user === undefined) {
            return;
        }

        this.odataService.postPrivate('co_accept_user_application',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_describing_center: this.userService.getCenterId(),
                id_user_to_accept: user.id_user
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.Status === 'OK') {
                    let respons = JSON.parse(result.Value);

                    if (!respons.status) {
                        // TODO: pokazać okno modelne z informacją o błąędzie.
                        console.log(respons.error_msg);
                    }

                    this.realoadList();
                } else {
                    // TODO:
                    console.log('REASON:', result.Status);
                }
            });
    }

    afterInvite(sent: boolean) {
        if (this.from_search) {
            this.from_search = false;
            this.asyncSelected = '';
            this.onValueChange();
        }
        this.realoadList();

        if (sent) this.actionToast.success('OperationSuccess');
        else this.actionToast.error('OperationFailed');
        this.actionToast.show();
        setTimeout(() => this.actionToast.hide(), 1500);
    }

    realoadList() {
        this.restarTimer();
        this.getCoStaffs();
    }

    starTimer() {
        this.restarTimer();
    }

    stopTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    private restarTimer() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.realoadList();
        }, 60000);
    }
}
