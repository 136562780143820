<div class="register-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>

                    <app-alert #errorAlert title="Registration"></app-alert>

                    <form [formGroup]="registerForm" autocomplete="off">
                        <div class="form-group">
                            <label [innerHTML]="'RegistrationFirstScreen.MailLabel' | translate" class="bigLabel"
                                    for="inputEmail"></label>
                            <input [class.is-error]="errorField == 'email'"
                                    autofocus
                                    class="form-control"
                                    formControlName="email"
                                    id="inputEmail"
                                    placeholder="{{'RegistrationFirstScreen.MailPlaceholder' | translate}}"
                                    type="email"
                                    autocomplete="new-password">
                            <span [innerHTML]="'RegistrationFirstScreen.MailSpan' | translate"
                                    class="form-text text-muted"
                                    id="emailHelp"></span>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationFirstScreen.PassLabel' | translate"
                                            class="bigLabel" for="inputPass1"
                                            id="password-method"></label>
                                    <input [class.is-error]="errorField == 'pass' || errorField == 'confirmPass'"
                                            class="form-control"
                                            formControlName="pass"
                                            id="inputPass1"
                                            name="pass1"
                                            placeholder="{{'RegistrationFirstScreen.PassPlaceholder' | translate}}"
                                            type="password"
                                            autocomplete="new-password">
                                            
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <label [innerHTML]="'RegistrationFirstScreen.PassRetryLabel' | translate"
                                            class="bigLabel"
                                            for="inputPass2"></label>
                                    <input [class.is-error]="errorField == 'confirmPass'"
                                            class="form-control"
                                            formControlName="retryPass"
                                            id="inputPass2"
                                            name="pass3"
                                            placeholder="{{'RegistrationFirstScreen.RetryPassPlaceholder' | translate}}"
                                            type="password"
                                            autocomplete="new-password">
                                </div>
                            </div>
                            <span [innerHTML]="'RegistrationFirstScreen.PassRetrySpan' | translate"
                                    class="form-text text-muted"
                                    id="passHelp"></span>
                        </div>


                        <div class="form-group">
                            <div class="row">
                                <div class="col" formArrayName="agreements">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input class="custom-control-input"
                                                id="regulations"
                                                type="checkbox">
                                        <label class="custom-control-label"
                                                for="regulations" [innerHTML]="portalTerms && portalTerms.TermsContents"></label>
                                    </div>
                                    <div *ngFor="let term of agreements.controls; let i = index"
                                            class="custom-control custom-checkbox" [ngClass]="{'mb-2': i != agreements.controls.length-1}">
                                        <input [formControlName]="i" class="custom-control-input"
                                                id="inlineCheckbox{{i}}"
                                                type="checkbox" value="{{term.AgreementId}}">
                                        <div *ngIf="term.IsRequired">*</div>
                                        <label class="custom-control-label"
                                                for="inlineCheckbox{{i}}" [innerHTML]="agreementList && agreementList[i].AgreementContents"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"><p>* - {{'RegistrationFirstScreen.RequiredAgreement' | translate}}</p>
                        </div>
                        <button
                                (click)="register_user_step1()"
                                [disabled]="false"
                                [innerHTML]="'RegistrationFirstScreen.NextStepBtn' | translate"
                                class="btn btn-lg btn-primary"></button>
                        <a [innerHTML]="'RegistrationFirstScreen.LoginBtn' | translate" class="btn btn-lg"
                            routerLink="/loginFirst"></a>
                    </form>

                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>
                
            </div>
            <div class="col-sm-12 col-md-6 bg p-50 p-desktop align-items-center d-flex">
    
                <app-stepper stepNum=1 stepperType="user"></app-stepper>
    
            </div>
        </div>
    </div>
</div>