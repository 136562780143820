import { NavigationService } from './../../services/navigation.service';
import { IFilterFacilityExams } from './../../interfaces/Filter';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { ContextsService } from '@/services/contexts.service';
import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    Renderer2,
    QueryList,
    OnDestroy
} from '@angular/core';
import moment from 'moment';
import { OdataService, UsersService, SessionService } from '@/services';
import { IFiltrDashboard, IFiltrPatients } from '@/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AddFilterModalComponent } from '@/utils/add-filter-modal/add-filter-modal.component';
import { Subscription } from 'rxjs';
import { FiltersService } from '@/services/filters.service';

interface IOption {
    value: any;
    selected: boolean;
}

interface IStatus {
    status: string;
    id_status: string;
    status_name: string;
}

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type: string;
    @Output() filter: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('filterSavedToast') filterSavedToast: UniversalToastComponent;

    private picker: ElementRef;
    @ViewChild('pickerDateRange') set PickerSetter(content: ElementRef) {
        this.picker = content;
    }

    private picker_placeholder: ElementRef;
    @ViewChild('placeholderInput') set pickerPlaceholderSetter(content: ElementRef) {
        this.picker_placeholder = content;
    }

    private pickerDesc: ElementRef;
    @ViewChild('pickerDateRangeDesc') set PickerSetterDesc(content: ElementRef) {
        this.pickerDesc = content;
    }

    private picker_placeholder_desc: ElementRef;
    @ViewChild('placeholderInputDesc') set pickerPlaceholderSetterDesc(content: ElementRef) {
        this.picker_placeholder_desc = content;
    }

    @ViewChild('addFilterModal') addFilterModal: AddFilterModalComponent;

    globalPickerListener: any;
    globalDescPickerListener: any;
    statusFilter: string = '';
    daterangeFilter: string = '';
    modalityFilter: string = '';
    anatomyFilter: string = '';
    assignedFilter: string = '';
    priorityFilter: string = '';
    facilityArchiveFilter: string = '';
    daterangeDescFilter: string = '';

    priceFilter: string = '';
    physicianFilter: string = '';
    principalFilter: string = '';
    sexFilter: string = '';
    isActiveDateSort = false;
    isActiveDescDateSort = false;
    isActiveExamDateSort = false;

    nameFilter: string = '';
    peselFilter: string = '';
    facilityFilter: string = '';
    examIdFilter: string = '';

    examTypeFilter: string = '';
    examAnatomyFilter: string = '';
    examNameFilter: string = '';
    examRisNumberFilter: string = '';
    examSourceFilter: string = '';
    examPatientNameFilter: string = '';
    examSexFilter: string = '';
    examPatientIdFilter: string = '';
    examAccessibilityFilter: string = '';
    daterangeExamFilter: string = '';

    statusOptions: IOption[] = [];

    modalityOptions: IOption[] = [];

    anatomyOptions: IOption[] = [];

    assignedOptions: IOption[] = [];

    priorityOptions: IOption[] = [];

    facilityOptions: IOption[] = [];

    priceOptions: IOption[] = [
        { value: { name: 'powyżej 100' }, selected: false },
        { value: { name: 'powyżej 500' }, selected: false },
        { value: { name: 'powyżej 1000' }, selected: false }
    ];

    physicianOptions: IOption[] = [
        { value: { name: 'Ja' }, selected: false },
        { value: { name: 'Jan Kowalski' }, selected: false },
        { value: { name: 'Jan Testowy' }, selected: false },
        { value: { name: 'Doctor Who' }, selected: false }
    ];

    principalOptions: IOption[] = [
        { value: { name: 'Misie' }, selected: false },
        { value: { name: 'Tygrysy' }, selected: false },
        { value: { name: 'Inny' }, selected: false },
        { value: { name: 'Jeszcze inny' }, selected: false }
    ];

    examAccessibilityOptions: IOption[] = [];

    sexOptions: string[] = ['Wszystkie', 'Mężczyzna', 'Kobieta'];
    statusOptionsForPatient: IOption[] = [];
    physiciansListForPatient: IOption[] = [];
    facilitiesListForPatient: IOption[] = [];

    filtrDashboard: IFiltrDashboard = null;
    patientsFilter: IFiltrPatients = null;
    facilityExamsFilter: IFilterFacilityExams = null;

    filtersActiveDashboard: boolean = false;
    filtersActivePatients: boolean = false;
    filtersActiveFacilityExams: boolean = false;

    public daterange: any = {};
    public daterangeDesc: any = {};
    enOptions: any = {
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Current Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
            format: 'YYYY-MM-DD',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            fromLabel: 'From',
            toLabel: 'To',
            customRangeLabel: 'Custom',
            daysOfWeek: [
                'Mo',
                'Tu',
                'We',
                'Th',
                'Fr',
                'Sa',
                'Su'
            ],
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            'firstDay': 0
        },
        alwaysShowCalendars: false,
    };
    plOptions: any = {
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        ranges: {
            'Dzisiaj': [moment(), moment()],
            'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment()],
            'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment()],
            'Ten Miesiąc': [moment().startOf('month'), moment().endOf('month')],
            'Ostatni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
            format: 'YYYY-MM-DD',
            applyLabel: 'Zatwierdź',
            cancelLabel: 'Anuluj',
            fromLabel: 'Z',
            toLabel: 'Do',
            customRangeLabel: 'Własny przedział',
            daysOfWeek: [
                'Po',
                'Wt',
                'Śr',
                'Cz',
                'P',
                'So',
                'N'
            ],
            'monthNames': [
                'Styczeń',
                'Luty',
                'Marzec',
                'Kwiecień',
                'Maj',
                'Czerwiec',
                'Lipiec',
                'Sierpień',
                'Wrzesień',
                'Październik',
                'Listopad',
                'Grudzień'
            ],
            'firstDay': 0
        },
        alwaysShowCalendars: false,
    };
    public options: any = this.setLangForDatepicker(this.sessionService.language);
    currentDateValue: string = '';
    currentDescDateValue: string = '';
    optionsPromise: Promise<any>;

    statusSelectpicker: any = null;
    modalitySelectpicker: any = null;
    anatomySelectpicker: any = null;
    assignedSelectpicker: any = null;
    prioritySelectpicker: any = null;
    facilitySelectpicker: any = null;

    statusForPatientSelectpicker: any = null;
    physiciansListForPatientSelectpicker: any = null;
    facilitiesListForPatientSelectpicker: any = null;
    sexSelectpicker: any = null;

    physicianSelectpicker: any = null;
    principalSelectpicker: any = null;
    priceSelectpicker: any = null;

    examSexSelectpicker: any = null;
    examSourceSelectpicker: any = null;
    examAccessibilitySelectpicker: any = null;

    sort_default: string = 'date_range_ASC';

    sort: string = this.sort_default;

    column_type_default: string = 'date_range';
    sort_type_default: string = 'ASC';

    saveFilterSubscription: Subscription;

    constructor(private renderer: Renderer2, private odata: OdataService, private sessionService: SessionService, private userService: UsersService,
                private translate: TranslateService, public filterService: FiltersService, public contextService: ContextsService,
                private navigationService: NavigationService) {
        this.changeLang();
    }

    changeLang() {
        this.sessionService.emitter.subscribe((res) => {
            this.options = this.setLangForDatepicker(res);
        });
    }

    setLangForDatepicker(lang: string): any {
        if (lang == 'en') {
            return this.enOptions;
        } else return this.plOptions;
    }

    ngOnInit() {
        if (this.navigationService.hasContext('DESCRIBING_CENTER') || this.navigationService.hasContext('MEDICAL_FACILITY')) {
            sessionStorage.setItem('activeFilter', 'false');
            let optionsPromises: Promise<any>[] = [];

            this.daterange.start = moment().subtract(29, 'days');
            this.daterange.end = moment();

            if (this.type == 'DASHBOARD') {
                optionsPromises.push(this.getStatusTypesForExamOrders());

            } else if (this.type == 'ARCHIVE') {
                this.sort = 'date_range_DESC';
                optionsPromises.push(this.getStatusTypesForArchive());
                optionsPromises.push(this.getMedicalFacilities());
                this.daterangeDesc.start = moment().subtract(29, 'days');
                this.daterangeDesc.end = moment();
            }

            if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE') {
                optionsPromises.push(this.get_modalities_groups());
                optionsPromises.push(this.get_anatomy_parts());
                optionsPromises.push(this.get_priority_types());
                this.userService.dcListPromise.then(() => {
                    if (this.type == 'DASHBOARD') optionsPromises.push(this.getCoDescribingPhysiciansByCenter());
                    else if (this.type == 'ARCHIVE') optionsPromises.push(this.getCoArchiveDescribingCenterPhysicianByCenter());
                    this.optionsPromise = Promise.all(optionsPromises);
                    this.optionsPromise.then(() => {
                        this.setFilterDashboard();
                    });
                });
            } else if (this.type == 'HISTORY') {
                // TODO
                this.getCoDescribingPhysiciansByCenter();
                setTimeout(() => {
                    this.physicianSelectpicker = $('#physician')['selectpicker']();
                    this.priceSelectpicker = $('#price')['selectpicker']();
                    this.principalSelectpicker = $('#principal')['selectpicker']();
                }, 100);
            } else if (this.type == 'PATIENTS') {
                optionsPromises.push(this.getStatusTypesForPatientView());
                optionsPromises.push(this.getPhysiciansListForPatientView());
                optionsPromises.push(this.getFacilitiesListForPatientView());
                Promise.all(optionsPromises).then(() => {
                this.sexSelectpicker = $('#sex')['selectpicker']();
                this.setFilterPatients();
                });
            } else if (this.type == 'FACILITY_EXAMS') {
                this.sort = 'date_range_DESC';
                this.daterangeDesc.start = moment().subtract(29, 'days');
                this.daterangeDesc.end = moment();
                optionsPromises.push(this.get_modalities_groups());
                optionsPromises.push(this.get_anatomy_parts());
                optionsPromises.push(this.get_accessibility_options_for_exams());
                Promise.all(optionsPromises).then(() => {
                    this.examSexSelectpicker = $('#examSex')['selectpicker']();
                    this.examSourceSelectpicker = $('#examSource')['selectpicker']();
                    this.setFilterFacilityExams();
                });
            }
        }
    }

    public ngAfterViewInit(): void {
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE' || this.type == 'PATIENTS') {
                this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', () => {
                    this.activateDatepicker();
                });
                if (this.type == 'ARCHIVE') {
                    this.globalDescPickerListener = this.renderer.listen(this.picker_placeholder_desc.nativeElement, 'click', () => {
                        this.activateDescDatepicker();
                    });
                }
            }
        } else if (this.navigationService.getCurrentContext() == 'MEDICAL_FACILITY') {
            if (this.type == 'FACILITY_EXAMS') {
                this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', () => {
                    this.activateDatepicker();
                });
                this.globalDescPickerListener = this.renderer.listen(this.picker_placeholder_desc.nativeElement, 'click', () => {
                    this.activateDescDatepicker();
                });
            }
        }
    }

    public ngOnDestroy() {
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE' || this.type == 'PATIENTS') {
                this.globalPickerListener();
            }
        } else if (this.navigationService.getCurrentContext() == 'MEDICAL_FACILITY') {
            if (this.type == 'FACILITY_EXAMS') {
                this.globalPickerListener();
            }
        }
    }

    private activateDatepicker() {
        this.picker.nativeElement.click();
    }

    private activateDescDatepicker() {
        this.pickerDesc.nativeElement.click();
    }

    getCoDescribingPhysiciansByCenter(): Promise<any> {
        return this.odata.postPrivate('get_co_describing_physicians_by_center', this.odata.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                this.setAssignedOptions(value);
                this.assignedSelectpicker = $('#assignedTo')['selectpicker']();
            }
        });
    }

    getCoArchiveDescribingCenterPhysicianByCenter(): Promise<any> {
        return this.odata.postPrivate('get_co_archive_describing_center_physician_by_center', this.odata.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), res => {
            let returnedJSON = JSON.stringify(res);
            let result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                let value: any[] = JSON.parse(result.Value);
                this.setAssignedOptions(value);
                this.assignedSelectpicker = $('#assignedTo')['selectpicker']();
            }
        });
    }

    setAssignedOptions(physicians): void {
        physicians.forEach((v) => {
            this.assignedOptions.push({
                value: {
                    name: v.physician_name,
                    id_physician: v.id_physician,
                    profile_image_uuid: v.profile_image_uuid
                }, selected: false
            });
        });
    }

    dropdown() {
        if (sessionStorage.getItem('activeFilter') == 'true') {
            sessionStorage.setItem('activeFilter', 'false');
            document.getElementById('filter_wrapper').style.overflow = 'hidden';
            $('.filter_dropdown a').removeClass('active');
            $('.filter_component_bg').removeClass('active');
            $('.filter_component_wrapper').removeClass('active');
        } else {
            sessionStorage.setItem('activeFilter', 'true');
            setTimeout(() => {
                let elem = document.getElementById('filter_wrapper');
                if (elem) {
                    elem.style.overflow = 'visible';
                }
            }, 500);
            $('.filter_dropdown a').addClass('active');
            $('.filter_component_bg').addClass('active');
            $('.filter_component_wrapper').addClass('active');
        }
    }

    public selectedDate(value: any, datepicker: any) {
        if (datepicker == this.daterange) {
            // this is the date the user selected
            if (!this.isActiveDateSort) {
                this.isActiveDateSort = true;
            }

            // or manupulat your own internal property
            this.daterange.start = value.start;
            this.daterange.end = value.end;
            this.daterange.label = value.label;
        } else if (datepicker == this.daterangeDesc) {
            // this is the date the user selected
            if (!this.isActiveDescDateSort) {
                this.isActiveDescDateSort = true;
            }

            // or manupulat your own internal property
            this.daterangeDesc.start = value.start;
            this.daterangeDesc.end = value.end;
            this.daterangeDesc.label = value.label;
        }

        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;

        this.updatePickerPlaceholder(value.start._d, value.end._d, datepicker);
    }

    private updatePickerPlaceholder(start_date: Date, end_date: Date, datepicker: any) {
        let start_date_str = moment(start_date).format('YYYY-MM-DD').toString();
        let end_date_str = moment(end_date).format('YYYY-MM-DD').toString();
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, datepicker);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, datepicker);
        }
    }

    public updatePickerPlaceholderContent(content: string, datepicker: any) {
        if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE' || this.type == 'PATIENTS' || this.type == 'FACILITY_EXAMS') {
            if (datepicker == this.daterange) this.currentDateValue = content;
            else if (datepicker == this.daterangeDesc) this.currentDescDateValue = content;
        }
    }

    getStatusTypesForPatientView(): Promise<any> {
        return this.filterService.getStatusTypesForPatients().then(
            (value) => {
                value.forEach((v) => {
                    this.statusOptionsForPatient.push({value: v, selected: false});
                });
                this.statusForPatientSelectpicker = $('#statusForPatient')['selectpicker']();
            });
    }

    getPhysiciansListForPatientView(): Promise<any> {
        return this.filterService.getDoctorsForPatients().then(
            (value) => {
                value.forEach((v) => {
                    this.physiciansListForPatient.push({value: v, selected: false});
                });
                this.physiciansListForPatientSelectpicker = $('#physiciansListForPatient')['selectpicker']();
            }
        );
    }

    getFacilitiesListForPatientView(): Promise<any> {
        return this.filterService.getFacilityTypes().then(
            (value) => {
                value.forEach((v) => {
                    this.facilitiesListForPatient.push({value: v, selected: false});
                });
                this.facilitiesListForPatientSelectpicker = $('#facilitiesListForPatient')['selectpicker']();
            }
        );
    }

    getStatusTypesForExamOrders(): Promise<any> {
        return this.filterService.getStatusTypesForExamOrders().then(
            (value) => {
                value.forEach((v) => {
                    this.statusOptions.push({ value: v, selected: false });
                });
                this.statusSelectpicker = $('#status')['selectpicker']();
            }
        );
    }

    getStatusTypesForArchive(): Promise<any> {
        return this.filterService.getStatusTypesForArchive().then(
            (value) => {
                value.forEach((v) => {
                    this.statusOptions.push({ value: v, selected: false });
                });
                this.statusSelectpicker = $('#status')['selectpicker']();
            }
        );
    }

    get_modalities_groups(): Promise<any> {
        return this.filterService.getModalitiesGroups().then(
            (value) => {
                value.forEach((v) => {
                    this.modalityOptions.push({ value: v, selected: false });
                });
                this.modalitySelectpicker = $('#modality')['selectpicker']();
            }
        );
    }

    get_anatomy_parts(): Promise<any> {
        return this.filterService.getAnatomyParts().then(
            (value) => {
                value.forEach((v) => {
                    this.anatomyOptions.push({ value: v, selected: false });
                });
                this.anatomySelectpicker = $('#anatomy')['selectpicker']();
            }
        );
    }

    get_accessibility_options_for_exams(): Promise<any> {
        return this.filterService.getAccessibilityOptionsForExams().then(
            (value) => {
                value.forEach((v) => {
                    this.examAccessibilityOptions.push({ value: v, selected: false });
                });
                this.examAccessibilitySelectpicker = $('#accessibility')['selectpicker']();
            }
        );
    }

    get_priority_types(): Promise<any> {
        return this.filterService.getPriorityTypes().then(
            (value) => {
                value.forEach((v) => {
                    this.priorityOptions.push({
                        value: {
                            priority_text: v.priority_text,
                            id_priority: v.id_priority,
                            priority_value: v.priority_value
                        }, selected: false
                    });
                });
                this.prioritySelectpicker = $('#priority')['selectpicker']();
            }
        );
    }

    getMedicalFacilities(): Promise<any> {
        return this.filterService.getMedicalFacilities().then(
            (value) => {
                value.forEach((v) => {
                    this.facilityOptions.push({value: v, selected: false});
                });
                this.facilitySelectpicker = $('#facility')['selectpicker']();
            }
        );
    }

    generateFilter(type: string, options: IOption[]): void {
        let filter: string = '';
        let selected: number = 0;
        options.forEach((option) => {
            if (option.selected) {
                ++selected;
            }
        });

        if (selected == 0) filter = '';
        else {
            filter = '';
            options.forEach((option) => {
                if (option.selected) {
                    --selected;
                    if (type == 'assigned' || type == 'physician' || type == 'principal') {
                        if (selected != 0) filter += option.value.name + ', ';
                        else filter += option.value.name;
                    } else if (type == 'modality' || type == 'anatomy') {
                        if (selected != 0) filter += option.value.value + ', ';
                        else filter += option.value.value;
                    } else if (type == 'status') {
                        if (selected != 0) filter += option.value.status + ', ';
                        else filter += option.value.status;
                    } else if (type == 'priority') {
                        if (selected != 0) filter += option.value.priority_text + ', ';
                        else filter += option.value.priority_text;
                    } else if (type == 'facility') {
                        if (selected != 0) filter += option.value.medical_facility.name + ', ';
                        else filter += option.value.medical_facility.name;
                    }
                }
            });
        }

        if (type == 'status') this.statusFilter = filter;
        else if (type == 'modality') this.modalityFilter = filter;
        else if (type == 'anatomy') this.anatomyFilter = filter;
        else if (type == 'priority') this.priorityFilter = filter;
        else if (type == 'assigned') this.assignedFilter = filter;
        else if (type == 'physician') this.physicianFilter = filter;
        else if (type == 'facility') this.facilityArchiveFilter = filter;
        else if (type == 'principal') this.principalFilter = filter;
    }

    static createInitialIFiltrDashboard(): IFiltrDashboard {
        let result: IFiltrDashboard = {
            status: [],
            sort: {
                sort_column: 'date_range',
                sort_type: 'ASC',
            },
            date_range: {
                date_from: '',
                date_to: ''
            },
            modality: [],
            anatomy: [],
            assign_to: [],
            priority: []
        };
        return result;
    }

    static createInitialIFiltrArchive(): IFiltrDashboard {
        let result: IFiltrDashboard = {
            status: [],
            sort: {
                sort_column: 'date_range',
                sort_type: 'DESC',
            },
            date_range: {
                date_from: '',
                date_to: ''
            },
            description_date_range: {
                date_from: '',
                date_to: ''
            },
            modality: [],
            anatomy: [],
            assign_to: [],
            priority: [],
            facility: []
        };
        return result;
    }

    static createInitialFiltrPatients(): IFiltrPatients {
        let result: IFiltrPatients = {
            name: '',
            sex: 'A',
            pesel: '',
            birth_date: {
                birth_date_from: '',
                birth_date_to: ''
            },
            sort: {
                sort_column: 'surname',
                sort_type: 'ASC'
            },
            status: [],
            physician: [],
            facility: [],
            exam_number: ''
        };

        return result;
    }

    static createInitialFiltrFacilityExams(): IFilterFacilityExams {
        let result: IFilterFacilityExams = {
            exam_date_range: {
                date_from: '',
                date_to: '',
            },
            sort: {
                sort_column: 'date_range',
                sort_type: 'DESC',
            },
            modality: [],
            anatomy: [],
            with_orders: 0, // 1 for exams without orders, 2 for exams with orders
            exam_name: '',
            ris_number: '',
            source: '',
            patient_name: '',
            sex: 'A',
            patient_id: '',
            birth_date: {
                birth_date_from: '',
                birth_date_to: '',
            },
            accessibility: []
        };

        return result;
    }

    getSortColumn(): string {
        let result = '';
        if (this.sort.endsWith('_ASC')) {
            result = this.sort.substring(0, this.sort.length - 4);
        } else if (this.sort.endsWith('_DESC')) {
            result = this.sort.substring(0, this.sort.length - 5);
        } else {
            result = this.column_type_default;
        }
        return result;
    }

    getSortType(): string {
        let result = '';
        if (this.sort.endsWith('_ASC')) {
            result = 'ASC';
        } else if (this.sort.endsWith('_DESC')) {
            result = 'DESC';
        } else {
            result = this.sort_type_default;
        }
        return result;
    }

    getSort(): void {
        this.filtrDashboard.sort = {
            sort_column: this.getSortColumn(),
            sort_type: this.getSortType(),
        };
    }

    getDateRange(): any {
        return {
            date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
            date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
        };
    }

    getDescDateRange(): any {
        return {
            date_from: moment(this.daterangeDesc.start).format('YYYY-MM-DD').toString(),
            date_to: moment(this.daterangeDesc.end).format('YYYY-MM-DD').toString()
        };
    }

    getSelectedOptions(filterName: string): number[] {
        let result: number[] = [];
        let options: HTMLCollectionOf<HTMLOptionElement> = (<HTMLSelectElement>document.getElementById(filterName)).selectedOptions;
        for (let i: number = 0; i < options.length; ++i) {
            result.push(parseInt(options[i].value));
        }

        return result;
    }

    getDate(): boolean {
        let tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            } else if (this.currentDateValue == '') {
                this.clearDaterangeFilter();
            } else {
                if (this.type == 'FACILITY_EXAMS') {
                    this.facilityExamsFilter.birth_date = {
                        birth_date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
                        birth_date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
                    };
                } else this.filtrDashboard.date_range = this.getDateRange();
            }
        } else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.birth_date.birth_date_from = this.currentDateValue.substring(0, 10);
                            this.facilityExamsFilter.birth_date.birth_date_to = this.currentDateValue.substring(13, 23);
                        } else {
                            this.filtrDashboard.date_range.date_from = this.currentDateValue.substring(0, 10);
                            this.filtrDashboard.date_range.date_to = this.currentDateValue.substring(13, 23);
                        }
                    } else {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.birth_date.birth_date_from = this.currentDateValue;
                            this.facilityExamsFilter.birth_date.birth_date_to = this.currentDateValue;
                        } else {
                            this.filtrDashboard.date_range.date_from = this.currentDateValue;
                            this.filtrDashboard.date_range.date_to = this.currentDateValue;
                        }
                    }
                    this.daterange.start = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_from : this.filtrDashboard.date_range.date_from;
                    this.daterange.end = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_to : this.filtrDashboard.date_range.date_to;
                } else {
                    document.getElementById('placeholder-input').setAttribute('class', 'error');
                    tmp = false;
                }
            } else {
                this.clearDaterangeFilter();
            }
        }

        return tmp;
    }

    getDescDate(): boolean {
        let tmp = true;
        if (this.isActiveDescDateSort) {
            if (!this.isValidDateRange(this.currentDescDateValue) && this.currentDescDateValue != '') {
                document.getElementById('placeholder-input_desc').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDescDateSort = false;
            } else if (this.currentDescDateValue == '') {
                this.clearDescDaterangeFilter();
            } else {
                if (this.type == 'FACILITY_EXAMS') {
                    this.facilityExamsFilter.exam_date_range = this.getDescDateRange();
                } else this.filtrDashboard.description_date_range = this.getDescDateRange();
            }
        } else if (!this.isActiveDescDateSort) {
            if (this.currentDescDateValue != '') {
                if (this.isValidDateRange(this.currentDescDateValue)) {
                    if (this.currentDescDateValue.length == 23) {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.exam_date_range.date_from = this.currentDescDateValue.substring(0, 10);
                            this.facilityExamsFilter.exam_date_range.date_to = this.currentDescDateValue.substring(13, 23);
                        } else {
                            this.filtrDashboard.description_date_range.date_from = this.currentDescDateValue.substring(0, 10);
                            this.filtrDashboard.description_date_range.date_to = this.currentDescDateValue.substring(13, 23);
                        }
                    } else {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.exam_date_range.date_from = this.currentDescDateValue;
                            this.facilityExamsFilter.exam_date_range.date_to = this.currentDescDateValue;
                        } else {
                            this.filtrDashboard.description_date_range.date_from = this.currentDescDateValue;
                            this.filtrDashboard.description_date_range.date_to = this.currentDescDateValue;
                        }
                    }
                    this.daterangeDesc.start = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.exam_date_range.date_from : this.filtrDashboard.description_date_range.date_from;
                    this.daterangeDesc.end = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.exam_date_range.date_to : this.filtrDashboard.description_date_range.date_to;
                } else {
                    document.getElementById('placeholder-input_desc').setAttribute('class', 'error');
                    tmp = false;
                }
            } else {
                this.clearDescDaterangeFilter();
            }
        }

        return tmp;
    }

    clearDaterangeFilter(): void {
        this.isActiveDateSort = false;
        this.daterangeFilter = '';
        this.updatePickerPlaceholderContent('', this.daterange);
        if (this.type == 'FACILITY_EXAMS') this.clearExamFilter(null, 'date_range');
        else this.clearFilter(null, 'date_range');
    }

    clearDescDaterangeFilter(): void {
        this.isActiveDescDateSort = false;
        this.daterangeDescFilter = '';
        this.updatePickerPlaceholderContent('', this.daterangeDesc);
        if (this.type == 'FACILITY_EXAMS') this.clearExamFilter(null, 'exam_date_range');
        else this.clearFilter(null, 'description_date_range');
    }

    filtrOrders(): void {
        document.getElementById('placeholder-input').removeAttribute('class');
        document.getElementById('placeholder-input').setAttribute('class', 'datepicker-placeholder');
        this.filtrDashboard.status = this.getSelectedOptions('status');
        this.filtrDashboard.anatomy = this.getSelectedOptions('anatomy');
        this.filtrDashboard.assign_to = this.getSelectedOptions('assignedTo');
        this.filtrDashboard.modality = this.getSelectedOptions('modality');
        this.filtrDashboard.priority = this.getSelectedOptions('priority');
        if (this.type == 'ARCHIVE') {
            document.getElementById('placeholder-input_desc').removeAttribute('class');
            document.getElementById('placeholder-input_desc').setAttribute('class', 'datepicker-placeholder');
            this.filtrDashboard.facility = this.getSelectedOptions('facility');
        }
        this.getSort();

        let temp1 = this.getDate();
        let temp2 = true;
        if (this.filtrDashboard.description_date_range) {
            temp2 = this.getDescDate();
        }
        if (temp1 && temp2) {
            this.setFilterDashboard();
            this.filter.emit(this.filtrDashboard);
            this.dropdown();
        }
    }

    isValidDateRange(dateRange: string): boolean {
        if (dateRange.length != 23 && dateRange.length != 10) {
            return false;
        } else {
            if (dateRange.length == 23) {
                let from = dateRange.substring(0, 10);
                let to = dateRange.substring(13, 23);
                if (!moment(from, 'YYYY-MM-DD').isValid() || !moment(to, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            } else {
                if (!moment(dateRange, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
        }

        return true;
    }

    getDatePatients(): boolean {
        let tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            } else if (this.currentDateValue == '') {
                this.daterangeFilter = '';
                this.updatePickerPlaceholderContent('', this.daterange);
                this.patientsFilter.birth_date.birth_date_from = '';
                this.patientsFilter.birth_date.birth_date_to = '';
            } else {
                let temp: any = this.getDateRange();
                this.patientsFilter.birth_date.birth_date_to = temp.date_to;
                this.patientsFilter.birth_date.birth_date_from = temp.date_from;
            }
        } else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        this.patientsFilter.birth_date.birth_date_from = this.currentDateValue.substring(0, 10);
                        this.patientsFilter.birth_date.birth_date_to = this.currentDateValue.substring(13, 23);
                    } else {
                        this.patientsFilter.birth_date.birth_date_from = this.currentDateValue;
                        this.patientsFilter.birth_date.birth_date_to = this.currentDateValue;
                    }
                    this.daterange.start = this.patientsFilter.birth_date.birth_date_from;
                    this.daterange.end = this.patientsFilter.birth_date.birth_date_to;
                } else {
                    document.getElementById('placeholder-input').setAttribute('class', 'error');
                    tmp = false;
                }
            } else {
                this.daterangeFilter = '';
                this.updatePickerPlaceholderContent('', this.daterange);
                this.patientsFilter.birth_date.birth_date_from = '';
                this.patientsFilter.birth_date.birth_date_to = '';
            }
        }

        return tmp;
    }

    getNamePatient(): string {
        return (<HTMLInputElement>document.getElementById('name')).value;
    }

    getSexPatient(): string {
        return this.sexSelectpicker.selectpicker('val');
    }

    getPeselPatient(): string {
        return (<HTMLInputElement>document.getElementById('pesel')).value;
    }

    getStatusPatient(): number[] {
        let statusList: number[] = [];
        this.statusForPatientSelectpicker.selectpicker('val').forEach((el) => statusList.push(parseInt(el)));
        return statusList;
    }

    getPhysicianPatient(): number[] {
      let physicianList: number[] = [];
      this.physiciansListForPatientSelectpicker.selectpicker('val').forEach((el) => physicianList.push(parseInt(el)));
      return physicianList;
    }

    getFacilityPatient(): number[] {
      let facilityList: number[] = [];
      this.facilitiesListForPatientSelectpicker.selectpicker('val').forEach((el) => facilityList.push(parseInt(el)));
      return facilityList;
    }

    getExamIdPatient(): string {
        return (<HTMLInputElement>document.getElementById('examId')).value;
    }

    filtrPatients(): void {
        let temp: boolean = this.getDatePatients();

        if (temp) {
            document.getElementById('placeholder-input').removeAttribute('class');
            document.getElementById('placeholder-input').setAttribute('class', 'datepicker-placeholder');

            this.patientsFilter.name = this.getNamePatient();
            this.patientsFilter.sex = this.getSexPatient();
            this.patientsFilter.pesel = this.getPeselPatient();
            this.patientsFilter.status = this.getStatusPatient();
            this.patientsFilter.physician = this.getPhysicianPatient();
            this.patientsFilter.facility = this.getFacilityPatient();
            this.patientsFilter.exam_number = this.getExamIdPatient();

            this.setFilterPatients();
            this.filter.emit(this.patientsFilter);
            document.getElementById('filtrButton').blur();
            this.dropdown();
        }
    }

    filterFacilityExams(): void {
        document.getElementById('placeholder-input').removeAttribute('class');
        document.getElementById('placeholder-input').setAttribute('class', 'datepicker-placeholder');
        document.getElementById('placeholder-input_desc').removeAttribute('class');
        document.getElementById('placeholder-input_desc').setAttribute('class', 'datepicker-placeholder');
        this.facilityExamsFilter.modality = this.getSelectedOptions('modality');
        this.facilityExamsFilter.anatomy = this.getSelectedOptions('anatomy');
        this.facilityExamsFilter.exam_name = (<HTMLInputElement>document.getElementById('examName')).value;
        this.facilityExamsFilter.ris_number = (<HTMLInputElement>document.getElementById('risNumber')).value;
        this.facilityExamsFilter.source = this.examSourceSelectpicker.selectpicker('val');
        this.facilityExamsFilter.patient_name = (<HTMLInputElement>document.getElementById('patientName')).value;
        this.facilityExamsFilter.sex = this.examSexSelectpicker.selectpicker('val');
        this.facilityExamsFilter.patient_id = (<HTMLInputElement>document.getElementById('patientId')).value;
        this.facilityExamsFilter.accessibility = this.getSelectedOptions('accessibility');
        this.facilityExamsFilter.sort = {
            sort_column: this.getSortColumn(),
            sort_type: this.getSortType(),
        };

        let temp1 = this.getDescDate();
        let temp2 = true;
        if (this.facilityExamsFilter.birth_date) {
            temp2 = this.getDate();
        }
        if (temp1 && temp2) {
            this.setFilterFacilityExams();
            this.filter.emit(this.facilityExamsFilter);
            this.dropdown();
        }
    }

    setActiveFiltersDashboard(setDaterange: boolean, setDaterangeDesc: boolean): void {
        if (setDaterange) this.daterangeFilter = moment(this.daterange.start).format('YYYY-MM-DD').toString()
            + ' - '
            + moment(this.daterange.end).format('YYYY-MM-DD').toString();
        if (setDaterangeDesc) this.daterangeDescFilter = moment(this.daterangeDesc.start).format('YYYY-MM-DD').toString()
            + ' - '
            + moment(this.daterangeDesc.end).format('YYYY-MM-DD').toString();

        this.filtersActiveDashboard = (this.statusFilter != '' || this.daterangeFilter != '' || this.daterangeDescFilter != '' ||
            this.modalityFilter != '' || this.anatomyFilter != '' ||
            this.assignedFilter != '' || this.priorityFilter != '' || this.facilityArchiveFilter != '');
    }

    setActiveFiltersFacilityExams(setDaterange: boolean, setDaterangeDesc: boolean): void {
        if (setDaterange) this.daterangeFilter = moment(this.daterange.start).format('YYYY-MM-DD').toString()
            + ' - '
            + moment(this.daterange.end).format('YYYY-MM-DD').toString();
        if (setDaterangeDesc) this.daterangeDescFilter = moment(this.daterangeDesc.start).format('YYYY-MM-DD').toString()
            + ' - '
            + moment(this.daterangeDesc.end).format('YYYY-MM-DD').toString();

        this.filtersActiveFacilityExams = (this.daterangeDescFilter != '' || this.examTypeFilter != '' ||
            this.examAnatomyFilter != '' || this.examNameFilter != '' || this.examRisNumberFilter != '' ||
            this.examSourceFilter != '' || this.examPatientNameFilter != '' || this.examSexFilter != '' ||
            this.examPatientIdFilter != '' || this.daterangeFilter != '' || this.examAccessibilityFilter != '');
    }

    saveFilter(type: string) {
        let val = '';
        let lovercaseType = type.toLowerCase();
        if (lovercaseType == 'dashboard') {
          val = this.filterService.typeOptions[0];
        } else if (lovercaseType == 'archive') {
          val = this.filterService.typeOptions[1];
        } else if (lovercaseType == 'patients') {
          val = this.filterService.typeOptions[2];
        }
        this.addFilterModal.show(val);
        let filtr = (lovercaseType == 'dashboard' || lovercaseType == 'archive') ? this.filtrDashboard : this.patientsFilter;
        this.saveFilterSubscription = this.addFilterModal.addFilterEmitter.subscribe(
            (res) => {
                if (res) {
                    this.filterService.saveFilter(filtr, lovercaseType, this.addFilterModal.filterName, this.addFilterModal.isFavourite).then(
                      (res) => {
                        if (res) {
                          this.filterSavedToast.success('FilterSaved');
                          this.filterService.getFilters(lovercaseType);
                          this.addFilterModal.hide();
                        } else {
                          this.filterSavedToast.error('FilterNotSaved');
                        }
                        this.filterSavedToast.show();
                        setTimeout(() => this.filterSavedToast.hide(), 1500);
                      }
                    );
                  }
            }
        );
    }

    deleteAllFiltersDashboard(emit: boolean): void {
        this.filtersActiveDashboard = false;

        this.statusFilter = '';
        if (this.statusSelectpicker != null) {
            this.statusSelectpicker.selectpicker('deselectAll');
        }
        this.clearFilter(this.statusOptions, 'status');
        this.daterangeFilter = '';
        this.isActiveDateSort = false;
        this.updatePickerPlaceholderContent('', this.daterange);
        this.clearFilter(null, 'date_range');
        this.modalityFilter = '';
        this.modalitySelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.modalityOptions, 'modality');
        this.anatomyFilter = '';
        this.anatomySelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.anatomyOptions, 'anatomy');
        this.assignedFilter = '';
        this.assignedSelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.assignedOptions, 'assign_to');
        this.priorityFilter = '';
        this.prioritySelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.priorityOptions, 'priority');
        if (this.type == 'ARCHIVE') {
            this.daterangeDescFilter = '';
            this.isActiveDescDateSort = false;
            this.updatePickerPlaceholderContent('', this.daterangeDesc);
            this.clearFilter(null, 'description_date_range');
            this.facilityArchiveFilter = '';
            this.facilitySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.facilityOptions, 'facility');
        }
        if (this.type == 'DASHBOARD') {
            this.filtrDashboard = FiltersComponent.createInitialIFiltrDashboard();
        } else if (this.type == 'ARCHIVE') {
            this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
        }

        this.sort = this.sort_default;

        if (emit) {
          this.filterService.clearSearch(false, this.type);
          this.filter.emit(this.filtrDashboard);
        }
    }

    deleteFilterDashboard(filter: string): void {
        if (filter == 'statusFilter') {
            this.statusFilter = '';
            if (this.statusSelectpicker != null) {
                this.statusSelectpicker.selectpicker('deselectAll');
            }
            this.clearFilter(this.statusOptions, 'status');
        } else if (filter == 'daterangeFilter') {
            this.isActiveDateSort = false;
            this.daterangeFilter = '';
            this.updatePickerPlaceholderContent('', this.daterange);
            this.clearFilter(null, 'date_range');
        } else if (filter == 'daterangeDescFilter') {
            this.isActiveDescDateSort = false;
            this.daterangeDescFilter = '';
            this.updatePickerPlaceholderContent('', this.daterangeDesc);
            this.clearFilter(null, 'description_date_range');
        } else if (filter == 'modalityFilter') {
            this.modalityFilter = '';
            this.modalitySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.modalityOptions, 'modality');
        } else if (filter == 'anatomyFilter') {
            this.anatomyFilter = '';
            this.anatomySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.anatomyOptions, 'anatomy');
        } else if (filter == 'assignedFilter') {
            this.assignedFilter = '';
            this.assignedSelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.assignedOptions, 'assign_to');
        } else if (filter == 'priorityFilter') {
            this.priorityFilter = '';
            this.prioritySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.priorityOptions, 'priority');
        } else if (filter == 'facilityFilter') {
          this.facilityArchiveFilter = '';
          this.facilitySelectpicker.selectpicker('deselectAll');
          this.clearFilter(this.facilityOptions, 'facility');
        } else if (filter === 'searchFilter') {
          this.filterService.clearSearch(true, this.type);
        }

        this.filtersActiveDashboard = (this.statusFilter != '' || this.daterangeFilter != '' ||
            this.daterangeDescFilter != '' || this.modalityFilter != '' || this.anatomyFilter != '' ||
            this.assignedFilter != '' || this.priorityFilter != '' || this.facilityArchiveFilter != '');

        this.filter.emit(this.filtrDashboard);
    }

    deleteAllFiltersFacilityExams(emit: boolean): void {
        this.filtersActiveFacilityExams = false;

        this.isActiveDescDateSort = false;
        this.daterangeDescFilter = '';
        this.updatePickerPlaceholderContent('', this.daterangeDesc);
        this.clearExamFilter(null, 'exam_date_range');
        this.examTypeFilter = '';
        this.modalitySelectpicker.selectpicker('deselectAll');
        this.clearExamFilter(this.modalityOptions, 'modality');
        this.examAnatomyFilter = '';
        this.anatomySelectpicker.selectpicker('deselectAll');
        this.clearExamFilter(this.anatomyOptions, 'anatomy');
        this.examNameFilter = '';
        (<HTMLInputElement>document.getElementById('examName')).value = '';
        this.facilityExamsFilter.exam_name = '';
        this.examRisNumberFilter = '';
        (<HTMLInputElement>document.getElementById('risNumber')).value = '';
        this.facilityExamsFilter.ris_number = '';
        this.examSourceFilter = '';
        this.examSourceSelectpicker.selectpicker('val', '');
        this.facilityExamsFilter.source = '';
        this.examPatientNameFilter = '';
        (<HTMLInputElement>document.getElementById('patientName')).value = '';
        this.facilityExamsFilter.patient_name = '';
        this.examSexFilter = '';
        this.examSexSelectpicker.selectpicker('val', 'A');
        this.facilityExamsFilter.sex = 'A';
        this.examPatientIdFilter = '';
        (<HTMLInputElement>document.getElementById('patientId')).value = '';
        this.facilityExamsFilter.patient_id = '';
        this.isActiveDateSort = false;
        this.daterangeFilter = '';
        this.updatePickerPlaceholderContent('', this.daterange);
        this.clearExamFilter(null, 'date_range');
        this.examAccessibilityFilter = '';
        this.examAccessibilitySelectpicker.selectpicker('deselectAll');
        this.clearExamFilter(this.examAccessibilityOptions, 'accessibility');

        this.facilityExamsFilter = FiltersComponent.createInitialFiltrFacilityExams();

        this.sort = this.sort_default;

        if (emit) this.filter.emit(this.facilityExamsFilter);
    }

    deleteFilterFacilityExams(filter: string): void {
        if (filter == 'daterangeExamFilter') {
            this.isActiveDescDateSort = false;
            this.daterangeDescFilter = '';
            this.updatePickerPlaceholderContent('', this.daterangeDesc);
            this.clearExamFilter(null, 'exam_date_range');
        } else if (filter == 'examTypeFilter') {
            this.examTypeFilter = '';
            this.modalitySelectpicker.selectpicker('deselectAll');
            this.clearExamFilter(this.modalityOptions, 'modality');
        } else if (filter == 'examAnatomyFilter') {
            this.examAnatomyFilter = '';
            this.anatomySelectpicker.selectpicker('deselectAll');
            this.clearExamFilter(this.anatomyOptions, 'anatomy');
        } else if (filter == 'examNameFilter') {
            this.examNameFilter = '';
            (<HTMLInputElement>document.getElementById('examName')).value = '';
            this.facilityExamsFilter.exam_name = '';
        } else if (filter == 'examRisNumberFilter') {
            this.examRisNumberFilter = '';
            (<HTMLInputElement>document.getElementById('risNumber')).value = '';
            this.facilityExamsFilter.ris_number = '';
        } else if (filter == 'examSourceFilter') {
            this.examSourceFilter = '';
            this.examSourceSelectpicker.selectpicker('val', '');
            this.facilityExamsFilter.source = '';
        } else if (filter == 'examPatientNameFilter') {
            this.examPatientNameFilter = '';
            (<HTMLInputElement>document.getElementById('patientName')).value = '';
            this.facilityExamsFilter.patient_name = '';
        } else if (filter == 'examSexFilter') {
            this.examSexFilter = '';
            this.examSexSelectpicker.selectpicker('val', 'A');
            this.facilityExamsFilter.sex = 'A';
        } else if (filter == 'examPatientIdFilter') {
            this.examPatientIdFilter = '';
            (<HTMLInputElement>document.getElementById('patientId')).value = '';
            this.facilityExamsFilter.patient_id = '';
        } else if (filter == 'daterangeFilter') {
            this.isActiveDateSort = false;
            this.daterangeFilter = '';
            this.updatePickerPlaceholderContent('', this.daterange);
            this.clearExamFilter(null, 'date_range');
        } else if (filter == 'examAccessibilityFilter') {
            this.examAccessibilityFilter = '';
            this.examAccessibilitySelectpicker.selectpicker('deselectAll');
            this.clearExamFilter(this.examAccessibilityOptions, 'accessibility');
        }

        this.filtersActiveFacilityExams = (this.daterangeDescFilter != '' || this.examTypeFilter != '' ||
            this.examAnatomyFilter != '' || this.examNameFilter != '' || this.examRisNumberFilter != '' ||
            this.examSourceFilter != '' || this.examPatientNameFilter != '' || this.examSexFilter != '' ||
            this.examPatientIdFilter != '' || this.daterangeFilter != '' || this.examAccessibilityFilter != '');

        this.filter.emit(this.facilityExamsFilter);
    }

    clearExamFilter(options: IOption[], filterName: string): void {
        if (filterName != 'date_range' && filterName != 'exam_date_range') {
            this.facilityExamsFilter[filterName] = [];
            options.forEach((option) => {
                option.selected = false;
            });
        } else if (filterName == 'date_range') {
            this.facilityExamsFilter.birth_date.birth_date_from = '';
            this.facilityExamsFilter.birth_date.birth_date_to = '';
        } else if (filterName == 'exam_date_range') {
            this.facilityExamsFilter.exam_date_range.date_from = '';
            this.facilityExamsFilter.exam_date_range.date_to = '';
        }
    }

    clearFilter(options: IOption[], filterName: string): void {
        if (filterName != 'date_range' && filterName != 'description_date_range') {
            this.filtrDashboard[filterName] = [];
            options.forEach((option) => {
                option.selected = false;
            });
        } else if (filterName == 'date_range') {
            this.filtrDashboard.date_range.date_from = '';
            this.filtrDashboard.date_range.date_to = '';
        } else if (filterName == 'description_date_range') {
            this.filtrDashboard.description_date_range.date_from = '';
            this.filtrDashboard.description_date_range.date_to = '';
        }
    }

    clearPatientsFilter(options: IOption[], filterName: string) {
      this.patientsFilter[filterName] = [];
      options.forEach((option) => {
        option.selected = false;
      });
    }

    setOptionsStatus(): void {
        this.statusOptions.forEach((o) => {
            o.selected = false;
        });

        this.filtrDashboard.status.forEach((status) => {
            this.statusOptions.forEach((o) => {
                if (status == o.value.id_status) {
                    o.selected = true;
                }
            });
        });

        this.statusSelectpicker.selectpicker('val', this.filtrDashboard.status);
        this.generateFilter('status', this.statusOptions);
    }

    setOptionsFacility(): void {
        this.facilityOptions.forEach((o) => {
            o.selected = false;
        });

        this.filtrDashboard.facility.forEach((facility) => {
            this.facilityOptions.forEach((o) => {
                if (facility == o.value.medical_facility.id_medical_facility) {
                    o.selected = true;
                }
            });
        });

        this.facilitySelectpicker.selectpicker('val', this.filtrDashboard.facility);
        this.generateFilter('facility', this.facilityOptions);
    }

    setOptionsModality(): void {
        this.modalityOptions.forEach((o) => {
            o.selected = false;
        });

        this.filtrDashboard.modality.forEach((modality) => {
            this.modalityOptions.forEach((o) => {
                if (modality == o.value.id_group) {
                    o.selected = true;
                }
            });
        });

        this.modalitySelectpicker.selectpicker('val', this.filtrDashboard.modality);
        this.generateFilter('modality', this.modalityOptions);
    }

    setOptionsAnatomy(): void {
        this.anatomyOptions.forEach((o) => {
            o.selected = false;
        });

        this.filtrDashboard.anatomy.forEach((anatomy) => {
            this.anatomyOptions.forEach((o) => {
                if (anatomy == o.value.id_part) {
                    o.selected = true;
                }
            });
        });

        this.anatomySelectpicker.selectpicker('val', this.filtrDashboard.anatomy);
        this.generateFilter('anatomy', this.anatomyOptions);
    }

    setOptionsPriority(): void {
        this.priorityOptions.forEach((o) => {
            o.selected = false;
        });

        this.filtrDashboard.priority.forEach((priority) => {
            this.priorityOptions.forEach((o) => {
                if (priority == o.value.id_priority) {
                    o.selected = true;
                }
            });
        });

        this.prioritySelectpicker.selectpicker('val', this.filtrDashboard.priority);
        this.generateFilter('priority', this.priorityOptions);
    }

    setOptionsAssigned(): void {
        this.assignedOptions.forEach((o) => {
            o.selected = false;
        });

        this.filtrDashboard.assign_to.forEach((assigned) => {
            this.assignedOptions.forEach((o) => {
                if (assigned == o.value.id_physician) {
                    o.selected = true;
                }
            });
        });

        setTimeout(() => {
            if (this.assignedSelectpicker) this.assignedSelectpicker.selectpicker('val', this.filtrDashboard.assign_to);
        }, 5); // TODO naprawić to tymczasowe rozwiązanie
        this.generateFilter('assigned', this.assignedOptions);
    }

    setDaterangeFilter(): void {
        if (this.type == 'FACILITY_EXAMS') {
            this.daterangeFilter = this.facilityExamsFilter.birth_date.birth_date_from + ' - ' + this.facilityExamsFilter.birth_date.birth_date_to;
            this.daterange.start = this.facilityExamsFilter.birth_date.birth_date_from;
            this.daterange.end = this.facilityExamsFilter.birth_date.birth_date_to;
        } else {
            this.daterangeFilter = this.filtrDashboard.date_range.date_from + ' - ' + this.filtrDashboard.date_range.date_to;
            this.daterange.start = this.filtrDashboard.date_range.date_from;
            this.daterange.end = this.filtrDashboard.date_range.date_to;
        }

        let start_date_str = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_from : this.filtrDashboard.date_range.date_from;
        let end_date_str = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_to : this.filtrDashboard.date_range.date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterange);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterange);
        }
    }

    setDescDaterangeFilter(): void {
        let date = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.exam_date_range : this.filtrDashboard.description_date_range;
        this.daterangeDescFilter = date.date_from + ' - ' + date.date_to;
        this.daterangeDesc.start = date.date_from;
        this.daterangeDesc.end = date.date_to;

        let start_date_str = date.date_from;
        let end_date_str = date.date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterangeDesc);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterangeDesc);
        }
    }

    setSort(): void {
        this.sort = this.filtrDashboard.sort.sort_column + '_' + this.filtrDashboard.sort.sort_type;
    }

    setFilterDashboard(): void {
        if (this.type == 'ARCHIVE') {
            this.setOptionsFacility();
            if (this.filtrDashboard.description_date_range.date_from != '' && this.filtrDashboard.description_date_range.date_to != '') {
                this.setDescDaterangeFilter();
            }
        }
        this.setOptionsStatus();
        this.setOptionsAnatomy();
        this.setOptionsAssigned();
        this.setOptionsPriority();
        this.setOptionsModality();
        this.setSort();
        if (this.filtrDashboard.date_range.date_from != '' && this.filtrDashboard.date_range.date_to != '') {
            this.setDaterangeFilter();
        }

        this.setActiveFiltersDashboard(false, false);
    }

    deleteFilterPatients(filter: string): void {
        if (filter == 'nameFilter') {
            this.nameFilter = '';
            (<HTMLInputElement>document.getElementById('name')).value = '';
            this.patientsFilter.name = '';
        } else if (filter == 'sexFilter') {
            this.sexFilter = '';
            this.sexSelectpicker.selectpicker('val', 'A');
            this.patientsFilter.sex = 'A';
        } else if (filter == 'peselFilter') {
            this.peselFilter = '';
            (<HTMLInputElement>document.getElementById('pesel')).value = '';
            this.patientsFilter.pesel = '';
        } else if (filter == 'daterangeFilter') {
            this.daterangeFilter = '';
            this.updatePickerPlaceholderContent('', this.daterange);
            this.patientsFilter.birth_date.birth_date_from = '';
            this.patientsFilter.birth_date.birth_date_to = '';
        } else if (filter == 'statusFilter') {
            this.statusFilter = '';
            this.statusForPatientSelectpicker.selectpicker('deselectAll');
            this.clearPatientsFilter(this.statusOptionsForPatient, 'status');
        } else if (filter == 'physicianFilter') {
            this.physicianFilter = '';
            this.physiciansListForPatientSelectpicker.selectpicker('deselectAll');
            this.clearPatientsFilter(this.physiciansListForPatient, 'physician');
        } else if (filter == 'facilityFilter') {
            this.facilityFilter = '';
            this.facilitiesListForPatientSelectpicker.selectpicker('deselectAll');
            this.clearPatientsFilter(this.facilitiesListForPatient, 'facility');
        } else if (filter == 'examIdFilter') {
            this.examIdFilter = '';
            (<HTMLInputElement>document.getElementById('examId')).value = '';
            this.patientsFilter.exam_number = '';
        } else if (filter == 'searchFilter') {
          this.filterService.clearSearch(true, this.type);
        }

        this.filtersActivePatients = (this.statusFilter != '' || this.daterangeFilter != '' ||
            this.sexFilter != '' || this.physicianFilter != '' || this.nameFilter != '' ||
            this.facilityFilter != '' || this.peselFilter != '' || this.examIdFilter != '');

        this.filter.emit(this.patientsFilter);
    }

    deleteAllFiltersPatients(emit: boolean): void {
        this.filtersActivePatients = false;

        this.patientsFilter = FiltersComponent.createInitialFiltrPatients();

        this.nameFilter = '';
        (<HTMLInputElement>document.getElementById('name')).value = '';
        this.sexFilter = '';
        this.sexSelectpicker.selectpicker('val', 'A');
        this.peselFilter = '';
        (<HTMLInputElement>document.getElementById('pesel')).value = '';
        this.daterangeFilter = '';
        this.updatePickerPlaceholderContent('', this.daterange);
        this.statusFilter = '';
        this.statusForPatientSelectpicker.selectpicker('deselectAll');
        this.clearPatientsFilter(this.statusOptionsForPatient, 'status');
        this.physicianFilter = '';
        this.physiciansListForPatientSelectpicker.selectpicker('deselectAll');
        this.clearPatientsFilter(this.physiciansListForPatient, 'physician');
        this.facilityFilter = '';
        this.facilitiesListForPatientSelectpicker.selectpicker('deselectAll');
        this.clearPatientsFilter(this.facilitiesListForPatient, 'facility');
        this.examIdFilter = '';
        (<HTMLInputElement>document.getElementById('examId')).value = '';

        if (emit) {
          this.filterService.clearSearch(false, this.type);
          this.filter.emit(this.patientsFilter);
        }
    }

    setExamSexFilter(): void {
        this.examSexSelectpicker.selectpicker('val', this.facilityExamsFilter.sex);
        if (this.facilityExamsFilter.sex != 'A') {
            this.translate.get('DashboardScreen.Filters.' + this.facilityExamsFilter.sex)
                .subscribe((res: string) => {
                    this.examSexFilter = res;
                });
        } else {
            this.examSexFilter = '';
        }
    }

    setExamModalities() {
        let result = '';
        let length = this.facilityExamsFilter.modality.length;
        this.facilityExamsFilter.modality.forEach((el) => {
          length--;
          let tmp = this.modalityOptions.find(x => x.value.id_group == el);
          if (length > 0) {
            result += tmp.value.value + ', ';
          } else {
            result += tmp.value.value;
          }
        });
        this.examTypeFilter = result;
    }

    setExamAnatomies() {
        let result = '';
        let length = this.facilityExamsFilter.anatomy.length;
        this.facilityExamsFilter.anatomy.forEach((el) => {
          length--;
          let tmp = this.anatomyOptions.find(x => x.value.id_part == el);
          if (length > 0) {
            result += tmp.value.value + ', ';
          } else {
            result += tmp.value.value;
          }
        });
        this.examAnatomyFilter = result;
    }

    setExamSource() {
        this.examSourceSelectpicker.selectpicker('val', this.facilityExamsFilter.source);
        if (this.facilityExamsFilter.source != '') {
            this.examSourceFilter = this.facilityExamsFilter.source;
        } else {
            this.examSourceFilter = '';
        }
    }

    setExamAccessibilities() {
        let result = '';
        let length = this.facilityExamsFilter.accessibility.length;
        this.facilityExamsFilter.accessibility.forEach((el) => {
          length--;
          let tmp = this.examAccessibilityOptions.find(x => x.value.id_status == el);
          if (length > 0) {
            result += tmp.value.status_text + ', ';
          } else {
            result += tmp.value.status_text;
          }
        });
        this.examAccessibilityFilter = result;
    }

    setSexFilter(): void {
        this.sexSelectpicker.selectpicker('val', this.patientsFilter.sex);
        if (this.patientsFilter.sex != 'A') {
            this.translate.get('DashboardScreen.Filters.' + this.patientsFilter.sex)
                .subscribe((res: string) => {
                    this.sexFilter = res;
                });
        } else {
            this.sexFilter = '';
        }
    }

    setDaterangeFilterPatients(): void {
        if (this.patientsFilter.birth_date.birth_date_from != '' && this.patientsFilter.birth_date.birth_date_to != '') {
            this.daterangeFilter = this.patientsFilter.birth_date.birth_date_from + ' - ' + this.patientsFilter.birth_date.birth_date_to;
        }
        this.daterange.start = this.patientsFilter.birth_date.birth_date_from;
        this.daterange.end = this.patientsFilter.birth_date.birth_date_to;

        let start_date_str = this.patientsFilter.birth_date.birth_date_from;
        let end_date_str = this.patientsFilter.birth_date.birth_date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterange);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterange);
        }
    }

    setBirthdateFilterFacilityExams(): void {
        if (this.facilityExamsFilter.birth_date.birth_date_from != '' && this.facilityExamsFilter.birth_date.birth_date_to != '') {
            this.daterangeFilter = this.facilityExamsFilter.birth_date.birth_date_from + ' - ' + this.facilityExamsFilter.birth_date.birth_date_to;
        }
        this.daterange.start = this.facilityExamsFilter.birth_date.birth_date_from;
        this.daterange.end = this.facilityExamsFilter.birth_date.birth_date_to;

        let start_date_str = this.facilityExamsFilter.birth_date.birth_date_from;
        let end_date_str = this.facilityExamsFilter.birth_date.birth_date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterange);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterange);
        }
    }

    setExamDaterangeFilter(): void {
        this.daterangeDescFilter = this.facilityExamsFilter.exam_date_range.date_from + ' - ' + this.facilityExamsFilter.exam_date_range.date_to;
        this.daterangeDesc.start = this.facilityExamsFilter.exam_date_range.date_from;
        this.daterangeDesc.end = this.facilityExamsFilter.exam_date_range.date_to;

        let start_date_str = this.facilityExamsFilter.exam_date_range.date_from;
        let end_date_str = this.facilityExamsFilter.exam_date_range.date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterangeDesc);
        } else {
            let new_content: string = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterangeDesc);
        }
    }

    setStatusPatients() {
      let result = '';
      let length = this.patientsFilter.status.length;
      this.patientsFilter.status.forEach((el) => {
        length--;
        let tmp = this.statusOptionsForPatient.find(x => x.value.id_status == el);
        if (length > 0) {
          result += tmp.value.status + ', ';
        } else {
          result += tmp.value.status;
        }
      });
      this.statusFilter = result;
    }

    setPhysiciansPatients() {
      let result = '';
      let length = this.patientsFilter.physician.length;
      this.patientsFilter.physician.forEach((el) => {
        length--;
        let tmp = this.physiciansListForPatient.find(x => x.value.id_physician == el);
        if (length > 0) {
          result += tmp.value.physician_name + ', ';
        } else {
          result += tmp.value.physician_name;
        }
      });
      this.physicianFilter = result;
    }

    setFacilitiesPatients() {
      let result = '';
      let length = this.patientsFilter.facility.length;
      this.patientsFilter.facility.forEach((el) => {
        length--;
        let tmp = this.facilitiesListForPatient.find(x => x.value.id_medical_facility == el);
        if (length > 0) {
          result += tmp.value.medical_facility_name + ', ';
        } else {
          result += tmp.value.medical_facility_name;
        }
      });
      this.facilityFilter = result;
    }

    setFilterPatients(): void {
        if ( !this.patientsFilter ) this.patientsFilter = FiltersComponent.createInitialFiltrPatients();
        this.nameFilter = this.patientsFilter.name;
        (<HTMLInputElement>document.getElementById('name')).value = this.patientsFilter.name;
        this.setSexFilter();
        this.peselFilter = this.patientsFilter.pesel;
        (<HTMLInputElement>document.getElementById('pesel')).value = this.patientsFilter.pesel;
        this.setDaterangeFilterPatients();
        this.statusForPatientSelectpicker.selectpicker('val', this.patientsFilter.status);
        this.setStatusPatients();
        this.physiciansListForPatientSelectpicker.selectpicker('val', this.patientsFilter.physician);
        this.setPhysiciansPatients();
        this.facilitiesListForPatientSelectpicker.selectpicker('val', this.patientsFilter.facility);
        this.setFacilitiesPatients();
        this.examIdFilter = this.patientsFilter.exam_number;
          (<HTMLInputElement>document.getElementById('examId')).value = this.patientsFilter.exam_number;

        this.filtersActivePatients = (this.statusFilter != '' || this.daterangeFilter != '' ||
            this.sexFilter != '' || this.physicianFilter != '' || this.nameFilter != '' ||
            this.facilityFilter != '' || this.peselFilter != '' || this.examIdFilter != '');
    }

    setFilterFacilityExams(): void {
        this.examNameFilter = this.facilityExamsFilter.exam_name;
        (<HTMLInputElement>document.getElementById('examName')).value = this.facilityExamsFilter.exam_name;
        this.examRisNumberFilter = this.facilityExamsFilter.ris_number;
        (<HTMLInputElement>document.getElementById('risNumber')).value = this.facilityExamsFilter.ris_number;
        this.examPatientNameFilter = this.facilityExamsFilter.patient_name;
        (<HTMLInputElement>document.getElementById('patientName')).value = this.facilityExamsFilter.patient_name;
        this.examPatientIdFilter = this.facilityExamsFilter.patient_id;
        (<HTMLInputElement>document.getElementById('patientId')).value = this.facilityExamsFilter.patient_id;
        this.modalitySelectpicker.selectpicker('val', this.facilityExamsFilter.modality);
        this.setExamModalities();
        this.anatomySelectpicker.selectpicker('val', this.facilityExamsFilter.anatomy);
        this.setExamAnatomies();
        this.setExamSource();
        this.setExamSexFilter();
        this.examAccessibilitySelectpicker.selectpicker('val', this.facilityExamsFilter.accessibility);
        this.setExamAccessibilities();
        if (this.facilityExamsFilter.birth_date.birth_date_from != '' && this.facilityExamsFilter.birth_date.birth_date_to != '') {
            this.setDaterangeFilter();
        }
        if (this.facilityExamsFilter.exam_date_range.date_from != '' && this.facilityExamsFilter.exam_date_range.date_to != '') {
            this.setDescDaterangeFilter();
        }

        this.setActiveFiltersFacilityExams(false, false);
    }
}
