import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@/utils/datatable/datatable.component';
import { PageService } from '@/services/page.service';

@Component({
    selector: 'app-balance',
    templateUrl: './balance.component.html',
    styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit, OnDestroy {
    @ViewChild('datatable') datatable: DatatableComponent;

    constructor(private pageService: PageService) {
    }

    ngOnInit() {
        this.pageService.setTranslatedPageTitle('Datatable.Balance.Title', 0);
    }

    ngOnDestroy() {
        this.pageService.resetPageTitle();
    }

}
