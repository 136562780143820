import { Component, OnInit } from '@angular/core';
import { OrderDescriptionService, OrderActionsService } from '@/services';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-save-exam-modal',
    templateUrl: './save-exam-modal.component.html',
    styleUrls: ['./save-exam-modal.component.css']
})
export class SaveExamModalComponent implements OnInit {

    navigateAwaySelection: Subject<boolean> = new Subject<boolean>();

    constructor(private descriptionService: OrderDescriptionService, private router: Router,
                private orderActions: OrderActionsService) {
    }

    ngOnInit() {
    }

    show(): void {
        $('#saveExamModal').modal('show');
    }

    hide(): void {
        $('#saveExamModal').modal('hide');
    }

    isSave(value: boolean) {
        if (value) {
            this.descriptionService.saveExamDraft(this.descriptionService.text);
        }
        this.navigateAwaySelection.next(true);
    }

}
