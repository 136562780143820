<div class="foot static">
    <p>
        <a href="{{envService.mainPage}}"><svg-icon alt="" class="svg" src="../../assets/images/logo.svg" style="display: inline;">
        </svg-icon></a>&copy; {{currentYear}}&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="{{envService.regulationsPath}}">{{'Footer.Regulations' | translate}}</a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a href="{{envService.privacyPolicyPath}}">{{'Footer.PrivacyPolicy' | translate}}</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {{'Footer.Version' | translate}}{{': '+version}}
    </p>

</div>
