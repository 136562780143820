import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { OdataService } from '@/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-send-application-to-co-modal',
  templateUrl: './send-application-to-co-modal.component.html',
  styleUrls: ['./send-application-to-co-modal.component.scss']
})
export class SendApplicationToCoModalComponent implements OnInit {

  @Input() dataSavedToast: UniversalToastComponent;

  applicationText: string = '';
  errorApplication: string = '';
  centerProfileID: number;
  centerProifileName: string;

  constructor(private odataService: OdataService, private translate: TranslateService) { }

  ngOnInit() {
  }

  show(): void {
    this.applicationText = '';
    this.errorApplication = '';
    $('#sendApplicationModal').modal('show');
  }

  hide(): void {
    this.errorApplication = '';
    $('#sendApplicationModal').modal('hide');
  }

  setCenter(centerProfileID: number, centerProifileName: string) {
    this.centerProfileID = centerProfileID;
    this.centerProifileName = centerProifileName;
  }

  onApplicationSend() {
    return this.odataService.postPrivate('physician_co_application',
        this.odataService.paramsTokenAndLang({
            id_describing_center: this.centerProfileID,
            message: this.applicationText
        }),
        res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.hide();
                  this.dataSavedToast.success('ApplicationSuccess');
                this.dataSavedToast.show();
                setTimeout(() => { this.dataSavedToast.hide(); }, 1500);
            } else {
                result = JSON.parse(result.Value);
                if (!result.status) {
                    this.errorApplication = result.error_msg;
                }
            }
        }
     );
  }

}
