import { DoctorsAndCoProfilesComponent } from './dm/patients-portal/archive-components/doctors-and-co-profiles/doctors-and-co-profiles.component';
import { PublicDCProfileComponent } from './dm/patients-portal/archive-components/public-dcprofile/public-dcprofile.component';
import { DCProfilesComponent } from './dm/patients-portal/archive-components/dcprofiles/dcprofiles.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPassFirstComponent } from './user-registration/forgot-pass-first/forgot-pass-first.component';
import { LoginFirstComponent } from './user-registration/login-first/login-first.component';
import { LoginSecondComponent } from './user-registration/login-second/login-second.component';
import { RegistrationFirstComponent } from './user-registration/registration-first/registration-first.component';
import { RegistrationSecondComponent } from './user-registration/registration-second/registration-second.component';
import { RegistrationThirdComponent } from './user-registration/registration-third/registration-third.component';
import { RegistrationFourthComponent } from './user-registration/registration-fourth/registration-fourth.component';
import { ArchiveComponent } from './dm/describing-center/archive/archive.component';
import { ForgotPassSecondComponent } from './user-registration/forgot-pass-second/forgot-pass-second.component';
import { ConfirmMailComponent } from './user-registration/confirm-mail/confirm-mail.component';
import { DashboardComponent } from './dm/describing-center/dashboard/dashboard.component';
import { UnlockScreenComponent } from '@/utils';
import { AccountPropertiesComponent } from './dm/account-properties/account-properties.component';
import { DoctorsProfileComponent } from './dm/doctors-profile/doctors-profile.component';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { DMComponent } from './dm/dm.component';
import { CoStep1Component } from './dm/registration-co/co-step1/co-step1.component';
import { MessagesComponent } from './dm/messages/main-page/messages.component';
import { NotificationsComponent } from './dm/notifications/main-page/notifications.component';
import { RoleSettingsComponent } from './dm/describing-center/roles/role-settings/role-settings.component';
import { PermissionSettingsComponent } from './dm/describing-center/roles/permission-settings/permission-settings.component';
import { UsersSettingsComponent } from './dm/describing-center/roles/users-settings/users-settings.component';
import { TeamSettingsComponent } from './dm/describing-center/roles/team-settings/team-settings.component';
import { BeforeStartingWorkComponent } from './dm/before-starting-work/before-starting-work.component';
import { UnauthGuardService } from './core/auth/unauth-guard.service';
import { CoStep2Component } from './dm/registration-co/co-step2/co-step2.component';
import { HelpComponent } from './help/help.component';
import { ForgotLoginFirstComponent } from './user-registration/forgot-login-first/forgot-login-first.component';
import { OrderDescriptionComponent } from '@/dm/describing-center/order-description/order-description.component';
import { CoProfileComponent } from '@/dm/co-profile/co-profile.component';
// tslint:disable-next-line: max-line-length
import { OrderDescriptionComparisonComponent } from '@/dm/describing-center/order-description/order-description-comparison/order-description-comparison.component';
import { ConsultationComponent } from '@/dm/describing-center/order-description/consultation/consultation.component';
import { CurrentDescriptionComponent } from '@/dm/describing-center/order-description/current-description/current-description.component';
import { PatientsComponent } from '@/dm/describing-center/patients/patients.component';
import { ConsultationsComponent } from '@/dm/describing-center/consultations/consultations.component';
import { ExaminationDescriptionComponent } from './dm/patients-portal/archive-components/examination-description/examination-description.component';
import { AlreadyRegisteredScreenComponent } from './utils/already-registered-screen/already-registered-screen.component';
import { ProfilesComponent } from './dm/describing-center/profiles/profiles.component';
import { ContextGuardService } from './core/auth/context-guard.service';
import { InvoicesComponent } from './dm/describing-center/invoices/invoices.component';
import { StatisticsComponent } from '@/dm/statistics/statistics.component';
import { OrderHistoryForCenterComponent } from './dm/describing-center/order-history-for-center/order-history-for-center.component';
import { BalanceComponent } from './dm/describing-center/balance/balance.component';
import { FindCOComponent } from '@/dm/find-co/find-co.component';
import { ListOfMedicalFacilitiesComponent } from '@/dm/list-of-medical-facilities/list-of-medical-facilities.component';
import { SiteUnderConstructionComponent } from './utils/site-under-construction/site-under-construction.component';
import { DeactivateGuardService } from '@/core/auth/deactivate-guard.service';
import { LockGuardService } from '@/core/auth/lock-guard.service';
import { ManageMyFiltersComponent } from '@/utils/manage-my-filters/manage-my-filters.component';
import { MedicalFacilityExamsComponent } from '@/dm/medical-facility/medical-facility-exams/medical-facility-exams.component';
import { MedicalFacilityArchiveComponent } from '@/dm/medical-facility/medical-facility-archive/medical-facility-archive.component';
import { MedicalFacilityDashboardComponent } from '@/dm/medical-facility/medical-facility-dashboard/medical-facility-dashboard.component';
import { MedicalFacilityPatientsComponent } from '@/dm/medical-facility/medical-facility-patients/medical-facility-patients.component';
import { MedicalFacilityProfilesComponent } from '@/dm/medical-facility/medical-facility-profiles/medical-facility-profiles.component';
import { MedicalFacilityRoleSettingsComponent } from '@/dm/medical-facility/settings/medical-facility-role-settings/medical-facility-role-settings.component';
import { MedicalFacilityPermissionSettingsComponent } from '@/dm/medical-facility/settings/medical-facility-permission-settings/medical-facility-permission-settings.component';
import { MedicalFacilityUsersSettingsComponent } from '@/dm/medical-facility/settings/medical-facility-users-settings/medical-facility-users-settings.component';
import { MedicalFacilityTeamSettingsComponent } from '@/dm/medical-facility/settings/medical-facility-team-settings/medical-facility-team-settings.component';
import { MedicalFacilityInvoicesComponent } from '@/dm/medical-facility/medical-facility-invoices/medical-facility-invoices.component';
import { PatientsPortalDashboardComponent } from '@/dm/patients-portal/patient-portal-dashboard/patients-portal-dashboard.component';
import { PatientsExamsComponent } from '@/dm/patients-portal/patient-exams/patients-exams.component';
import { PatientAppointmentsComponent } from '@/dm/patients-portal/patient-appointments/patient-appointments.component';
import { AvailableServicesComponent } from '@/dm/patients-portal/available-services/available-services.component';
import { FindDoctorsComponent } from '@/dm/patients-portal/find-doctors/find-doctors.component';
import { PatientAccountComponent } from '@/dm/patients-portal/patient-account/patient-account.component';
import { PatientExamViewComponent } from '@/dm/patients-portal/patient-exams/patient-exam-view/patient-exam-view.component';
import { PatientExamListComponent } from '@/dm/patients-portal/patient-exams/patient-exam-list/patient-exam-list.component';

const routes: Routes = [
  {path: 'help', component: HelpComponent},
  {path: 'alreadyRegisteredScreen', component: AlreadyRegisteredScreenComponent},
  {path: 'forgotPassFirst', component: ForgotPassFirstComponent, canActivate: [UnauthGuardService]},
  {path: 'forgotLoginFirst', component: ForgotLoginFirstComponent, canActivate: [UnauthGuardService]},
  {path: 'forgotPassSecond/:uuid', component: ForgotPassSecondComponent, canActivate: [UnauthGuardService]},
  {path: 'confirmMail', component: ConfirmMailComponent, canActivate: [UnauthGuardService]},
  {path: 'loginFirst', component: LoginFirstComponent, canActivate: [UnauthGuardService]},
  {path: 'loginSecond', component: LoginSecondComponent, canActivate: [UnauthGuardService]},
  {path: 'registrationFirst', component: RegistrationFirstComponent, canActivate: [UnauthGuardService]},
  {path: 'registrationSecond/:uuid', component: RegistrationSecondComponent, canActivate: [UnauthGuardService]},
  {path: 'registrationThird/:uuid', component: RegistrationThirdComponent},
  {path: 'registrationFourth', component: RegistrationFourthComponent},
  {path: 'unlockScreen', component: UnlockScreenComponent, canActivate: [LockGuardService]},
  {path: 'underConstruction', component: SiteUnderConstructionComponent},
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: '',
    component: DMComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard/:filter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: DashboardComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardService, ContextGuardService],
        component: DashboardComponent
      },
      {
        path: 'manageMyFilters/:type',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ManageMyFiltersComponent
      },
      {
        path: 'portal-pacjenta',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PatientsPortalDashboardComponent
      },
      {
        path: 'badania',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PatientsExamsComponent,
        children: [
          {
            path: 'szczegoly',
            canActivate: [AuthGuardService, ContextGuardService],
            component: PatientExamViewComponent
          },
          {
            path: 'lista-badan',
            canActivate: [AuthGuardService, ContextGuardService],
            component: PatientExamListComponent
          },
        ]
      },

      {
        path: 'wizyty',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PatientAppointmentsComponent
      },
      {
        path: 'uslugi',
        canActivate: [AuthGuardService, ContextGuardService],
        component: AvailableServicesComponent
      },
      {
        path: 'lekarze',
        canActivate: [AuthGuardService, ContextGuardService],
        component: FindDoctorsComponent
      },
      {
        path: 'twoje-konto',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PatientAccountComponent
      },
      {
        path: 'findCO',
        canActivate: [AuthGuardService, ContextGuardService],
        component: FindCOComponent
      },
      {
        path: 'listOfMedicalFacilities',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ListOfMedicalFacilitiesComponent
      },
      {
        path: 'examinationDescription',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ExaminationDescriptionComponent
      },
      {
        path: 'archive',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ArchiveComponent
      },
      {
        path: 'archive/:filter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ArchiveComponent
      },
      {
        path: 'accountProperties',
        canActivate: [AuthGuardService],
        component: AccountPropertiesComponent
      },
      {
        path: 'myProfile',
        canActivate: [AuthGuardService, ContextGuardService],
        component: DoctorsProfileComponent
      },
      {
        path: 'profiles',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ProfilesComponent
      },
      {
        path: 'doctorsProfile/:doctor_id',
        canActivate: [AuthGuardService, ContextGuardService],
        component: DoctorsProfileComponent
      },
      {
        path: 'registrationCo1',
        canActivate: [AuthGuardService, ContextGuardService],
        component: CoStep1Component
      },
      {
        path: 'registrationCo2',
        canActivate: [AuthGuardService, ContextGuardService],
        component: CoStep2Component
      },
      /*{
        path: 'serviceCatalog',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ServiceCatalogComponent
      },*/
      {
        path: 'publicDcProfile/:profile_id',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PublicDCProfileComponent
      },
      /*{
        path: 'getAccessForDc',
        canActivate: [AuthGuardService, ContextGuardService],
        component: GetAccessForDCComponent
      },*/
      {
        path: 'dcProfiles',
        canActivate: [AuthGuardService, ContextGuardService],
        component: DCProfilesComponent
      },
      {
        path: 'doctorsAndCoProfiles',
        canActivate: [AuthGuardService, ContextGuardService],
        component: DoctorsAndCoProfilesComponent
      },
      {
        path: 'patients',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PatientsComponent
      },
      {
        path: 'patients/:filter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PatientsComponent
      },
      {
        path: 'consultations',
        canActivate: [AuthGuardService, ContextGuardService],
        component: ConsultationsComponent
      },
      {
        path: 'invoices',
        canActivate: [AuthGuardService, ContextGuardService],
        component: InvoicesComponent
      },
      {
        path: 'orderHistoryForCenter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: OrderHistoryForCenterComponent
      },
      {
        path: 'balance',
        canActivate: [AuthGuardService, ContextGuardService],
        component: BalanceComponent
      },
      {
        path: 'statistics',
        canActivate: [AuthGuardService, ContextGuardService],
        component: StatisticsComponent
      },
      {
        path: 'orderDescription/:orderId',
        canActivate: [AuthGuardService, ContextGuardService],
        canDeactivate: [DeactivateGuardService],
        component: OrderDescriptionComponent,
        children: [
          {
            path: 'orderDescriptionComparison',
            canActivate: [AuthGuardService, ContextGuardService],
            component: OrderDescriptionComparisonComponent
          },
          {
            path: 'consultation',
            canActivate: [AuthGuardService, ContextGuardService],
            component: ConsultationComponent
          },
          {
            path: 'currentDescription',
            canActivate: [AuthGuardService, ContextGuardService],
            component: CurrentDescriptionComponent
          },
        ]
      },
      {
        path: 'publicCOProfile/:coId',
        canActivate: [AuthGuardService, ContextGuardService],
        component: CoProfileComponent
      },
      {
        path: 'coProfile',
        canActivate: [AuthGuardService, ContextGuardService],
        component: CoProfileComponent
      },
      {
        path: 'messages',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MessagesComponent
      },
      {
        path: 'notifications',
        canActivate: [AuthGuardService, ContextGuardService],
        component: NotificationsComponent
      },
      {
        path: 'beforeStartingWork',
        canActivate: [AuthGuardService, ContextGuardService],
        component: BeforeStartingWorkComponent
      },
      {
        path: 'roleSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: RoleSettingsComponent
      },
      {
        path: 'permissionSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: PermissionSettingsComponent
      },
      {
        path: 'usersSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: UsersSettingsComponent
      },
      {
        path: 'teamSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: TeamSettingsComponent
      },
      {
        path: 'siteUnderConstruction',
        canActivate: [AuthGuardService, ContextGuardService],
        component: SiteUnderConstructionComponent
      },
      {
        path: 'medicalFacilityArchive/:filter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityArchiveComponent
      },
      {
        path: 'medicalFacilityArchive',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityArchiveComponent
      },
      {
        path: 'medicalFacilityExams',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityExamsComponent
      },
      {
        path: 'medicalFacilityExams/:filter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityExamsComponent
      },
      {
        path: 'medicalFacilityDashboard/:filter',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityDashboardComponent
      },
      {
        path: 'medicalFacilityDashboard',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityDashboardComponent
      },
      {
        path: 'medicalFacilityPatients',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityPatientsComponent
      },
      {
        path: 'medicalFacilityProfiles',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityProfilesComponent
      },
      {
        path: 'medicalFacilityRoleSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityRoleSettingsComponent
      },
      {
        path: 'medicalFacilityPermissionSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityPermissionSettingsComponent
      },
      {
        path: 'medicalFacilityUsersSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityUsersSettingsComponent
      },
      {
        path: 'medicalFacilityTeamSettings',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityTeamSettingsComponent
      },
      {
        path: 'medicalFacilityInvoices',
        canActivate: [AuthGuardService, ContextGuardService],
        component: MedicalFacilityInvoicesComponent
      },
    ]
  },

  {path: '**', redirectTo: 'siteUnderConstruction'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
