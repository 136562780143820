import { EventEmitter } from '@angular/core';
import { OdataService, SessionService, UsersService } from '@/services';
import * as i0 from "@angular/core";
import * as i1 from "./odata.service";
import * as i2 from "./users.service";
import * as i3 from "./session.service";
var FiltersService = /** @class */ (function () {
    function FiltersService(odata, userService, sessionService) {
        this.odata = odata;
        this.userService = userService;
        this.sessionService = sessionService;
        this.myFilters = [
            { type: 'dashboard', filters: [] },
            { type: 'archive', filters: [] },
            { type: 'patients', filters: [] }
        ];
        this.typeOptions = ['Zlecenia', 'Archiwum zleceń', 'Pacjenci'];
        this.currentChosenType = '';
        this.currentChosenPatientId = '';
        this.countOfFavourites = 0;
        // saved search inputs
        this.searchInputArchive = '';
        this.searchInputDashboard = '';
        this.searchInputPatients = '';
        this.clearSearchFromFilterTileEvent = new EventEmitter();
    }
    FiltersService.prototype.saveFilter = function (filter, type, name, isFavourite) {
        var params = {
            id_describing_center: this.userService.getCenterId(),
            filter_name: name,
            filter_type: type,
            filter_value: JSON.stringify(filter),
            is_favourite: isFavourite
        };
        return this.odata.postPrivate('co_save_user_filter', this.odata.paramsTokenAndLang(params), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            return result.value == 'OK';
        });
    };
    FiltersService.prototype.clearSavedSearchAndFilters = function (type) {
        if (!type) {
            this.searchInputPatients = '';
            this.searchInputDashboard = '';
            this.searchInputArchive = '';
            sessionStorage.removeItem('timeIsChanged');
            sessionStorage.removeItem('dashboardMyFilter');
            sessionStorage.removeItem('archiveMyFilter');
            sessionStorage.removeItem('patientsMyFilter');
        }
        else if (type == 'dashboard') {
            this.searchInputDashboard = '';
            sessionStorage.removeItem('dashboardMyFilter');
        }
        else if (type === 'patients') {
            this.searchInputPatients = '';
            sessionStorage.removeItem('patientsMyFilter');
        }
        else if (type === 'archive') {
            this.searchInputArchive = '';
            sessionStorage.removeItem('timeIsChanged');
            sessionStorage.removeItem('archiveMyFilter');
        }
    };
    FiltersService.prototype.clearSearch = function (emit, type) {
        this.clearSearchFromFilterTileEvent.emit(emit);
        if (type === 'ARCHIVE')
            this.searchInputArchive = '';
        else if (type === 'DASHBOARD')
            this.searchInputDashboard = '';
        else if (type === 'PATIENTS')
            this.searchInputPatients = '';
    };
    FiltersService.prototype.getFilters = function (type) {
        var _this = this;
        var params = {
            id_describing_center: this.userService.getCenterId(),
            filter_type: type
        };
        this.countOfFavourites = 0;
        return this.odata.postPrivate('co_get_user_filters', this.odata.paramsTokenAndLang(params), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                result = JSON.parse(result.Value);
                _this.myFilters.find(function (x) { return x.type == type; }).filters = [];
                result.forEach(function (filter) {
                    _this.myFilters.find(function (x) { return x.type == type; }).filters.push({
                        id: filter.id_filter,
                        name: filter.filter_name,
                        type: filter.filter_type,
                        value: filter.filter_value,
                        isFavourite: filter.is_favourite
                    });
                    if (filter.is_favourite) {
                        _this.countOfFavourites++;
                    }
                });
                return true;
            }
            else
                return false;
        });
    };
    FiltersService.prototype.getMyFilters = function (type) {
        return this.myFilters.find(function (x) { return x.type == type; }).filters;
    };
    FiltersService.prototype.deleteFilter = function (id) {
        var params = {
            id_describing_center: this.userService.getCenterId(),
            filter_id: id
        };
        return this.odata.postPrivate('co_delete_user_filter', this.odata.paramsTokenAndLang(params), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            return result.value == 'OK';
        });
    };
    FiltersService.prototype.getStatusTypesForExamOrders = function () {
        return this.odata.postPrivate('get_status_types_for_exam_orders', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getStatusTypesForArchive = function () {
        return this.odata.postPrivate('get_status_types_for_archive', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getStatusTypesForPatients = function () {
        return this.odata.postPrivate('co_get_status_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getModalitiesGroups = function () {
        return this.odata.postPrivate('get_modalities_groups', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getAnatomyParts = function () {
        return this.odata.postPrivate('get_anatomy_parts', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getAccessibilityOptionsForExams = function () {
        return this.odata.postPrivate('get_accessibility_options_for_exams', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getPriorityTypes = function () {
        return this.odata.postPrivate('get_priority_types', this.odata.paramsTokenAndLang({}), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getPhysiciansByCenter = function (type) {
        var funcName = '';
        if (type == 'dashboard') {
            funcName = 'get_co_describing_physicians_by_center';
        }
        else if (type == 'archive') {
            funcName = 'get_co_archive_describing_center_physician_by_center';
        }
        return this.odata.postPrivate(funcName, this.odata.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getFacilityTypes = function () {
        return this.odata.postPrivate('co_get_medical_facility_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getDoctorsForPatients = function () {
        return this.odata.postPrivate('co_get_phycisian_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getMedicalFacilities = function () {
        return this.odata.postPrivate('co_get_medical_facility_list_for_patient_list', this.odata.paramsTokenAndLang({
            id_describing_center: this.userService.getCenterId()
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                return value;
            }
            else
                return [];
        });
    };
    FiltersService.prototype.getAvailableInviteRoles = function () {
        return this.odata.postPrivate('get_dc_availble_invite_roles', {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: this.userService.getCenterId()
        }, function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status === 'OK') {
                var value = JSON.parse(result.Value).json_info;
                return value;
            }
            else {
                console.log('REASON', result.Status);
                return [];
            }
        });
    };
    FiltersService.prototype.removeSessionFilters = function () {
        sessionStorage.removeItem('filtersDashboard');
        sessionStorage.removeItem('filtersArchive');
        sessionStorage.removeItem('filtersPatients');
    };
    FiltersService.ngInjectableDef = i0.defineInjectable({ factory: function FiltersService_Factory() { return new FiltersService(i0.inject(i1.OdataService), i0.inject(i2.UsersService), i0.inject(i3.SessionService)); }, token: FiltersService, providedIn: "root" });
    return FiltersService;
}());
export { FiltersService };
