import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OdataService } from '@/services';
import { Observable } from 'rxjs';
import { FileStatus, UploadService } from '@/services/tusUpload.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AlertModalComponent } from '@/utils/alert-modal/alert-modal.component';
import { AlertModalConfig } from '@/utils/alert-modal/alert-modal.config';

@Component({
  selector: 'app-patient-exam-upload-modal',
  templateUrl: './patient-exam-upload-modal.component.html',
  styleUrls: ['./patient-exam-upload-modal.component.scss']
})
export class PatientExamUploadModalComponent implements OnInit, OnDestroy {

  @ViewChild('alertModalComponent') alertModal: AlertModalComponent;

  uploadProgress: Observable<FileStatus[]>;
  uploadSuccessEvent: Subscription;
  uploadCompleted: boolean;
  filenames: string[] = [];
  uploadStarted: boolean;
  alertModalConfig: AlertModalConfig = {
    closeButtonLabel: '', modalText: '', modalTitle: ''
  };

  constructor(private odataService: OdataService, private uploadService: UploadService) { }

  ngOnInit() {
    this.uploadProgress = this.uploadService.uploadProgress;
    this.uploadCompleted = false;
    this.uploadStarted = false;
    this.uploadSuccessEvent = this.uploadService.uploadSuccess.subscribe(uploadSuccess => {
      if (uploadSuccess) { this.uploadCompleted = true; } else {
        this.alertModalConfig = {closeButtonLabel: 'PPUploadError.Button', modalText: 'PPUploadError.Message', modalTitle: 'PPUploadError.Title'};
        this.alertModal.show();
      }
    });
  }

  ngOnDestroy() {
    this.uploadSuccessEvent.unsubscribe();
  }

  show(): void {
    this.uploadStarted = false;
    this.uploadCompleted = false;
    (<HTMLInputElement>document.getElementById('fileInput')).value = null;
    $('#patientExamUploadModal').modal('show');
  }

  hide(): void {
    $('#patientExamUploadModal').modal('hide');
  }

  upload(files: FileList) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      this.filenames.push(files[i].name);
      // console.log(`Uploading ${files[i].name} with size ${files[i].size} and type ${files[i].type}`);
      this.uploadStarted = true;
      this.uploadService.uploadFile(files[i], files[i].name);
    }
  }

}
