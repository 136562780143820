<div aria-hidden="true" aria-labelledby="saveAndSendAttentionExamModalLabel" class="modal fade" id="saveAndSendAttentionExamModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="saveAndSendAttentionExamModalLabel">{{'SendExamModal.Title' | translate}}</h2>
        <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="isSendAndSave(true, true)" class="btn btn-lg btn-success">{{'SendExamModal.SendAlert' | translate}}</button>
        <button type="button" (click)="hide()" class="btn btn-lg btn-danger">{{'SendExamModal.Cancel' | translate}}</button>
        <button type="button" (click)="isSendAndSave(false, true)" class="btn btn-lg btn-danger">{{'SendExamModal.SendWithoutAlert' | translate}}</button>
      </div>
    </div>
  </div>
</div>
