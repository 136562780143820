<div class="login-screen">
    <div class="container-fluid">

        <app-language-button></app-language-button>

        <div class="row vh-100">
            <div class="col-sm-12 col-md-6 p-50 d-flex flex-column">

                <div>
                    <svg-icon style="display: block;" alt="Evolution" class="logo" src="../../../assets/images/logo.svg"></svg-icon>
                </div>

                <div>
                    <app-alert #loginAlert title="{{alertTitle}}"></app-alert>

                    <form>
                        <div class="form-group">
                            <label id="labelWithTooltip" class="bigLabel" for="login" style="height: 39.5px;">
                                <span style="display: block; font-size: 21px; font-weight: 400; color: #464646; position: relative; top: 1px;">
                                    {{'LoginFirstScreen.LoginLabel' | translate}}
                                    <fa-icon [icon]="faQuestionCircle"></fa-icon>
                                </span>
                                <div class="tooltipContainer" style="left: 134px; top: -120px; height: 0px; width: 0px;">
                                    <span class="tooltip" [innerHTML]="'LoginFirstScreen.LoginTooltip' | translate"></span>
                                </div>
                            </label>
                            <a [innerHTML]="'LoginFirstScreen.ForgotLoginBtn' | translate"
                                class="btn btn-small aside"
                                routerLink="/forgotLoginFirst">
                            </a>
                            <input [(ngModel)]="login" autofocus class="form-control" id="login" name="login"
                                    placeholder="{{'LoginFirstScreen.LoginPlaceholder' | translate}}"
                                    type="text" value=""/>
                        </div>
                        <a *ngIf="!this.envService.registrationDisabled" [innerHTML]="'LoginFirstScreen.RegisterBtn' | translate" class="btn btn-lg" routerLink="/registrationFirst"></a>
                        <button (click)="login_attempt_step2()" [disabled]="login === ''"
                                [innerHTML]="'LoginFirstScreen.NextBtn' | translate" class="btn btn-lg btn-primary">
                            Dalej
                        </button>
                    </form>
                </div>

                <div class="w-100 mt-auto">
                    <app-footer></app-footer>
                </div>

            </div>

            <div class="col-sm-12 col-md-6 bg p-50 p-desktop">
                <app-news></app-news>
            </div>

        </div>
    </div>
</div>
