import { Subject } from 'rxjs';
import { Toast, ToastType, ToastTypeStringTable } from '@/core/components/toast/toast.model';
import * as i0 from "@angular/core";
var ToastService = /** @class */ (function () {
    function ToastService() {
        this.toastSubject = new Subject();
    }
    // for subscribing to toasts
    ToastService.prototype.onToast = function () {
        return this.toastSubject.asObservable();
    };
    // new Toast
    ToastService.prototype.toast = function (message, type, autoClose) {
        if (type === void 0) { type = 'info'; }
        if (autoClose === void 0) { autoClose = true; }
        var toast = new Toast({ type: ToastTypeStringTable[type] || ToastType.Info, message: message, autoClose: autoClose });
        this.toastSubject.next(toast);
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
