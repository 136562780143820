<div aria-hidden="true" class="modal fade"
     role="dialog"
     tabindex="-1" id="insufficientRightsModal"
     aria-labelledby="insufficientRightsModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-4" id="insufficientRightsModalLabel">Brak uprawnień</h2>
                <button type="button" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>