<!--<button type="button" class="btn btn-white mb-4" data-toggle="modal" data-target="#takeDescriptionModal">
  Przejmij opis
</button>
<br/>-->


<!-- start: przypiszZlecenieModal -->
<div aria-hidden="true" class="modal fade" role="dialog" tabindex="-1" id="takeDescriptionModal"
    aria-labelledby="takeDescriptionModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-4" id="takeDescriptionModalLabel">{{'TakeoverModal.Takeover' | translate}}</h2>
                <button type="button" (click)="hide()" class="btn btn-light close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="margin-bottom: 20px;">
                    <button type="button" class="btn btn-light btn-lg" (click)='hide()'>{{'TakeoverModal.GoToView' | translate}}</button>
                    <button type="button" (click)="isTaking = true" class="btn btn-light btn-lg">{{'TakeoverModal.Takeover' | translate}}</button>
                </div>

                <app-alert #alert title="Takeover"></app-alert>

                <form *ngIf="isTaking" class="mt-4" autocomplete="off">
                    <input type="password" style="display:none;" readonly> <!-- Niezbędne do wyłączenia autocomplete -->
                    <div class="form-group" style="padding-bottom: 0; margin-bottom: 10px;">
                        <label [innerHTML]="'AccountProperties.GetPasswordLabel' | translate" class="bigLabel"
                            for="passwordInput"></label>
                        <input autocomplete="off" name="passInput" id="passwordInput" [(ngModel)]="password"
                            placeholder="{{'AccountProperties.Password' | translate}}" type="password" value=""
                            (keyup)="onKeyUp($event)">
                    </div>

                    <p [innerHTML]="'Errors.InvalidPass' | translate" id="passwordInputError" style="display: none;">
                    </p>
                    <button id="ok-button" type="button" class="btn btn-success btn-lg" (click)="takeover()">OK</button>
                </form>

            </div>
        </div>
    </div>
</div>
<!-- end: przypiszZlecenieModal -->