import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INotification } from '@/dm/notifications/inotification';
import { UsersService, OdataService, SessionService } from '@/services';

@Component({
    selector: 'app-short-notification',
    templateUrl: './short-notification.component.html',
    styleUrls: ['./short-notification.component.css']
})

export class ShortNotificationComponent implements OnInit {
    @Input() notification: INotification;
    @Output() remove = new EventEmitter();
    @Output() read = new EventEmitter();
    @Output() close = new EventEmitter();
    @Output() extend = new EventEmitter<boolean>();
    userService: UsersService;
    odataService: OdataService;
    extended = false;

    constructor(userService: UsersService, odataService: OdataService,
        private sessionService: SessionService, private router: Router) {
        this.odataService = odataService;
        this.userService = userService;
    }

    ngOnInit() {
    }

    notificationInsideClick(clicked) {
        if (this.extended !== clicked.clickInside) {
            this.extended = clicked.clickInside;

            let is_extend = false;

            if (this.extended) {
                is_extend = true;

                if (!this.notification.is_read) {
                    this.odataService.postPrivate('notification_read',
                        {
                            token: this.sessionService.getToken(),
                            language: this.userService.iLanguage,
                            readed: true,
                            id_notification: this.notification.id_notification
                        },
                        res => {
                            const returnedJson = JSON.stringify(res);
                            const result = JSON.parse(returnedJson);

                            if (result.value === 'OK') {
                                this.notification.is_read = true;
                                this.read.emit(this.notification.id_notification);
                            } else {
                                // TODO:
                                console.log('REASON:', result.value);
                            }
                        }
                    );
                }
            }

            this.extend.emit(is_extend);
        }
    }

    onReply(event: any) {
        event.stopPropagation();
    }

    onRemove(event: any) {
        event.stopPropagation();

        this.odataService.postPrivate('notification_delete',
            {
                token: this.sessionService.getToken(),
                language: this.userService.iLanguage,
                id_notification: this.notification.id_notification
            },
            res => {
                const returnedJson = JSON.stringify(res);
                const result = JSON.parse(returnedJson);

                if (result.value === 'OK') {
                    this.remove.emit(this.notification.id_notification);
                } else {
                    // TODO:
                    console.log('REASON:', result.value);
                }
            }
        );
    }

    onReadNotification(event: any) {
        this.close.emit();
    }

    coExamActionStartDescrption = (describing_center: number, exam_order: number) => {
        if (describing_center !== this.userService.getCenterId()) {
            // TODO: Dodać komunikat o tym że wybraliśmy badanie które nie jest z tego centrum opisowego.
            console.log('Pruba opisu badania z niewłaściwego centrum opisowego');
            return;
        }

        let params = {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_describing_center: describing_center,
            id_exam_order: exam_order
        };

        this.odataService.postPrivate('co_exam_action_start_descrption', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                this.router.navigate(['/orderDescription/currentDescription']);
            } else {
                // TODO:
            }
        });
    }

    makeAction(n_object: INotification, item: any) {
        const action_name: string = item.action_name;

        switch (action_name) {
            case 'START_DESCRIPTION':
                this.close.emit();
                const describing_center: number = n_object.notification_params.id_describing_center;
                const exam_order: number = n_object.notification_params.id_exam_order;
                this.coExamActionStartDescrption(describing_center, exam_order);
                break;
            default:
                console.log('Sorry, no ' + action_name + ' on list.');
            // TODO:
        }
    }
}
