import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertComponent } from '..';

@Component({
    selector: 'app-already-registered-screen',
    templateUrl: './already-registered-screen.component.html',
    styleUrls: ['./already-registered-screen.component.css']
})
export class AlreadyRegisteredScreenComponent implements OnInit {
    @ViewChild('errorAlert') errorAlert: AlertComponent;

    constructor() {
    }

    ngOnInit() {
        this.errorAlert.displayError('ALREADY_REGISTERED');
    }

}
