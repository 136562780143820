/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../../utils/datatable/datatable.component.ngfactory";
import * as i6 from "../../../utils/datatable/datatable.component";
import * as i7 from "../../../services/env.service";
import * as i8 from "../../../services/odata.service";
import * as i9 from "../../../services/admin.service";
import * as i10 from "../../../services/session.service";
import * as i11 from "./balance.component";
import * as i12 from "../../../services/page.service";
var styles_BalanceComponent = [i0.styles];
var RenderType_BalanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BalanceComponent, data: {} });
export { RenderType_BalanceComponent as RenderType_BalanceComponent };
export function View_BalanceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { datatable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "col-md-12 mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "card-body p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h4", [["class", "strong pt-2 m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ":"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "h4", [["class", "strong pb-0 m-0 blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["2 439,69 PLN ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["class", "btn btn-lg btn-primary float-right mr-0"], ["routerLink", "/siteUnderConstruction"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-datatable", [["type", "BALANCE"]], null, null, null, i5.View_DatatableComponent_0, i5.RenderType_DatatableComponent)), i1.ɵdid(24, 114688, [[1, 4], ["datatable", 4]], 0, i6.DatatableComponent, [i7.EnvService, i8.OdataService, i9.AdminService, i3.Router, i2.TranslateService, i10.SessionService, i3.ActivatedRoute], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_4 = "/siteUnderConstruction"; _ck(_v, 15, 0, currVal_4); var currVal_7 = "BALANCE"; _ck(_v, 24, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("Datatable.Balance.CurrentBalance")); _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("Datatable.Balance.Netto")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 15).target; var currVal_3 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Datatable.Balance.BoostAccount")); _ck(_v, 16, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("Datatable.Balance.History")); _ck(_v, 21, 0, currVal_6); }); }
export function View_BalanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-balance", [], null, null, null, View_BalanceComponent_0, RenderType_BalanceComponent)), i1.ɵdid(1, 245760, null, 0, i11.BalanceComponent, [i12.PageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BalanceComponentNgFactory = i1.ɵccf("app-balance", i11.BalanceComponent, View_BalanceComponent_Host_0, {}, {}, []);
export { BalanceComponentNgFactory as BalanceComponentNgFactory };
