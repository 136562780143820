import { IFacilityOrders, IFacilityExams, IFacilityOrderDetails, initFacilityOrderDetails } from './../interfaces/MedicalFacility';
import { OdataService } from './odata.service';
import { UsersService, OrderDescriptionService, NavigationService, SessionService } from '@/services';
import { EventEmitter } from '@angular/core';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "./order-description.service";
import * as i2 from "./users.service";
import * as i3 from "./odata.service";
import * as i4 from "./navigation.service";
import * as i5 from "./session.service";
var FacilityOrdersService = /** @class */ (function () {
    function FacilityOrdersService(descriptionService, userService, odataService, navigationService, sessionService) {
        this.descriptionService = descriptionService;
        this.userService = userService;
        this.odataService = odataService;
        this.navigationService = navigationService;
        this.sessionService = sessionService;
        this.orders = [];
        this.exams = [];
        this.examsDates = [];
        this.examsAges = [];
        this.examsHours = [];
        this.newOrdersString = '';
        this.width = '20px';
        this.age = [];
        this.dates = [];
        this.hours = [];
        this.numberOfOrders = new EventEmitter();
        this.numberOfExams = new EventEmitter();
        this.dashboardLoader = null;
        this.examsLoader = null;
        this.orderDetails = initFacilityOrderDetails;
    }
    FacilityOrdersService.prototype.getMfExamOrders = function (filter, limit, offset, filter_search) {
        var _this = this;
        this.orders = [];
        if (this.dashboardLoader)
            this.dashboardLoader.show = true;
        return this.odataService.postPrivate('get_mf_exam_orders_ext', this.odataService.paramsTokenAndLang({
            id_medical_facility: this.userService.getFacilityId(),
            filtr: JSON.stringify(filter),
            limit: limit,
            offset: offset,
            filtr_search: filter_search ? filter_search : ''
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.orders = _this.setOrders(result);
            }
            if (_this.dashboardLoader)
                _this.dashboardLoader.show = false;
            return result.Status;
        });
    };
    FacilityOrdersService.prototype.getMfExams = function (filter, limit, offset, filter_search, changedId) {
        var _this = this;
        this.exams = [];
        if (this.examsLoader)
            this.examsLoader.show = true;
        var facilityId = changedId ? changedId : this.userService.getFacilityId(); // making sure we're picking id that's up-to-date if facility changed, or recovering one from storage
        return this.odataService.postPrivate('get_mf_exam_lists', this.odataService.paramsTokenAndLang({
            id_medical_facility: facilityId,
            filtr: JSON.stringify(filter),
            limit: limit,
            offset: offset,
            filtr_search: filter_search ? filter_search : ''
        }), function (res) {
            var returnedJson = JSON.stringify(res);
            var result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                _this.exams = _this.setExams(result);
            }
            if (_this.examsLoader)
                _this.examsLoader.show = false;
            return result.Status;
        });
    };
    FacilityOrdersService.prototype.setOrders = function (result) {
        var value = JSON.parse(result.Value || '[]');
        this.clearOrdersData();
        this.numberOfOrders.emit(result.ValueCount);
        for (var i = 0; i < value.length; i++) {
            this.setOrderData(value[i]);
        }
        return value;
    };
    FacilityOrdersService.prototype.setExams = function (result) {
        var value = JSON.parse(result.Value || '[]');
        this.clearExamsData();
        this.numberOfExams.emit(result.ValueCount);
        for (var i = 0; i < value.length; i++) {
            this.setExamData(value[i]);
        }
        return value;
    };
    FacilityOrdersService.prototype.clear_examToOrdersId = function () {
        this.examToOrdersId = undefined;
    };
    FacilityOrdersService.prototype.set_examToOrdersId = function (value) {
        this.examToOrdersId = value;
    };
    FacilityOrdersService.prototype.clearOrdersData = function () {
        this.orders = [];
        this.age = [];
        this.hours = [];
        this.dates = [];
    };
    FacilityOrdersService.prototype.clearExamsData = function () {
        this.exams = [];
        this.examsAges = [];
        this.examsHours = [];
        this.examsDates = [];
    };
    FacilityOrdersService.prototype.setOrderData = function (order) {
        var orderData = this.getDateHourAge(order);
        this.age.push(orderData['age']);
        this.hours.push(orderData['orderHour']);
        this.dates.push(orderData['orderDate']);
        this.sortActions(order);
    };
    FacilityOrdersService.prototype.setExamData = function (exam) {
        var orderData = this.getDateHourAge(exam);
        this.examsAges.push(orderData['age']);
        this.examsHours.push(orderData['hour']);
        this.examsDates.push(orderData['date']);
        this.sortExamActions(exam);
    };
    FacilityOrdersService.prototype.sortActions = function (info) {
        info.action_list.sort(function (a1, a2) {
            if (a1.action_importance > a2.action_importance)
                return -1;
            else if (a1.action_importance == a2.action_importance)
                return 0;
            else
                return 1;
        });
    };
    FacilityOrdersService.prototype.sortExamActions = function (info) {
        info.exam_actions.sort(function (a1, a2) {
            if (a1.action_importance > a2.action_importance)
                return -1;
            else if (a1.action_importance == a2.action_importance)
                return 0;
            else
                return 1;
        });
    };
    FacilityOrdersService.prototype.getDateHourAge = function (info) {
        moment.locale(this.userService.iLanguage);
        var date = this.getDateString(info.exam_date);
        var orderDate = this.getDateString(info.exam_order_date);
        var hour = moment(info.exam_date).fromNow();
        var orderHour = moment(info.exam_order_date).fromNow();
        var age = this.descriptionService.calculateAgeFromDates(new Date(info.birth_date), new Date());
        return { age: age, hour: hour, date: date, orderDate: orderDate, orderHour: orderHour };
    };
    FacilityOrdersService.prototype.getDateString = function (examDate) {
        var orderMoment = moment(examDate);
        var today = moment(new Date()).hours(23).minutes(59);
        var daysDiff = moment(examDate).hours(0).minutes(0).diff(today, 'days');
        if (daysDiff === 0)
            return "Dzi\u015B, " + orderMoment.format('HH:mm');
        else if (daysDiff === -1)
            return "Wczoraj, " + orderMoment.format('HH:mm');
        return orderMoment.format('DD-MM-YYYY, HH:mm');
    };
    FacilityOrdersService.prototype.yearsDeclinatedString = function (age) {
        var yrs = age % 10; // last digit of age
        if (age == 1)
            return 'OrderDescriptionScreens.Current.Years1';
        if (yrs > 1 && yrs < 5)
            return 'OrderDescriptionScreens.Current.Years2to4';
        if (yrs === 0 || yrs === 1 || yrs > 4)
            return 'OrderDescriptionScreens.Current.Years5to9';
    };
    FacilityOrdersService.prototype.getNumberOfOrders = function () {
        var _this = this;
        if (this.navigationService.getCurrentContext() == 'MEDICAL_FACILITY') {
            this.odataService.postPrivate('count_mf_exam_orders', this.odataService.paramsTokenAndLang({
                id_medical_facility: this.userService.getFacilityId(),
                filtr: '{"status": [1]}',
                filtr_search: ''
            }), function (res) {
                var returnedJson = JSON.stringify(res);
                var result = JSON.parse(returnedJson);
                if (result.Status == 'OK') {
                    _this.setOrderString(result);
                }
            });
        }
    };
    FacilityOrdersService.prototype.getMfOrderDetails = function (id) {
        var _this = this;
        return this.odataService.postPrivate('get_mf_exam_order_info', this.odataService.paramsTokenAndLang({
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            id_medical_facility: this.userService.getFacilityId(),
            id_exam_order: id
        }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status === 'OK' && result.Value != '{}') {
                _this.orderDetails = JSON.parse(result.Value);
                return true;
            }
            else {
                console.log('FAILED');
                _this.orderDetails = initFacilityOrderDetails;
                return false;
            }
        });
    };
    FacilityOrdersService.prototype.setOrderString = function (result) {
        var value = parseInt(result.Value);
        if (value > 99) {
            this.newOrdersString = '99+';
            this.width = '30px';
        }
        else {
            this.newOrdersString = value + '';
            this.width = '20px';
        }
    };
    FacilityOrdersService.ngInjectableDef = i0.defineInjectable({ factory: function FacilityOrdersService_Factory() { return new FacilityOrdersService(i0.inject(i1.OrderDescriptionService), i0.inject(i2.UsersService), i0.inject(i3.OdataService), i0.inject(i4.NavigationService), i0.inject(i5.SessionService)); }, token: FacilityOrdersService, providedIn: "root" });
    return FacilityOrdersService;
}());
export { FacilityOrdersService };
