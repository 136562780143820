import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OdataService, OrdersService, UsersService } from '@/services';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  IExamDescInfo,
  IFacilityOrderDetails,
  initExamDescInfo,
  initFacilityOrderDetails,
  IToastInfo
} from '@/interfaces';
import { AttachmentViewModalComponent } from '@/utils/attachment-view-modal/attachment-view-modal.component';

@Component({
  selector: 'app-desc-order-return-modal',
  templateUrl: './desc-order-return-modal.component.html',
  styleUrls: ['./desc-order-return-modal.component.scss']
})
export class DescOrderReturnModalComponent implements OnInit {

  showToastEvent: EventEmitter<IToastInfo> = new EventEmitter<IToastInfo>();
  @ViewChild('attachmentViewModalComponent') attachmentView: AttachmentViewModalComponent;
  currentCard: string = 'preview';
  minCommentLength = 10;
  currentDesc: IExamDescInfo = initExamDescInfo;
  returnOrderForm = this.formBuilder.group({
    is_complaint: [true, [Validators.required]],
    comment: ['', [Validators.required, Validators.minLength(this.minCommentLength)]]
  });
  attachmentsList: any[] = [];
  attachmentOptions: any[] = [];
  loadedFile: any = {type: '', name: ''};
  orderDetails: IFacilityOrderDetails = initFacilityOrderDetails;

  constructor(private formBuilder: FormBuilder, private odataService: OdataService, public ordersService: OrdersService, private usersService: UsersService) { }

  ngOnInit() {
  }

  getExamOrderAttachmentTypeList(): Promise<any> {
    return this.odataService.postPrivate('get_exam_order_attachment_type_list',
      this.odataService.paramsTokenAndLang({}),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          this.attachmentOptions = JSON.parse(result.Value);
        }
        return result;
      });
  }

  addExamOrderAttachment(base64: string) {
    this.odataService.postPrivate('add_exam_order_attachment',
      this.odataService.paramsTokenAndLang({
        id_exam_order: this.orderDetails.id_exam_order,
        base64: base64,
        filename: this.loadedFile.name,
        content_type: this.loadedFile.type,
        id_attachment_type: 2
      }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          result = JSON.parse(result.Value);
          result = JSON.parse(result);
          if (result.status) {
            this.attachmentsList.push({
              filename: this.loadedFile.name,
              id_attachment_type: 2,
              attachment_uuid: result.attachment_uuid.attachment_uuid
            });
            this.showToastEvent.emit(this.createToastToEmit('AddFileSuccess', 'success'));
          } else this.showToastEvent.emit(this.createToastToEmit('AddFileError', 'error'));
        } else this.showToastEvent.emit(this.createToastToEmit('AddFileError', 'error'));
      });
  }

  delExamOrderAttachment(index: number) {
    this.odataService.postPrivate('del_exam_order_attachment',
      this.odataService.paramsTokenAndLang({
        id_exam_order: this.orderDetails.id_exam_order,
        attachment_uuid: this.attachmentsList[index].attachment_uuid
      }),
      res => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          result = JSON.parse(result.Value);
          result = JSON.parse(result);
          if (result.status) {
            this.showToastEvent.emit(this.createToastToEmit('RemoveFileSuccess', 'success'));
            this.attachmentsList = this.attachmentsList.filter(x => x.attachment_uuid != this.attachmentsList[index].attachment_uuid);
          } else this.showToastEvent.emit(this.createToastToEmit('RemoveFileError', 'error'));
        } else this.showToastEvent.emit(this.createToastToEmit('RemoveFileError', 'error'));
      });
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    return of([]);
  }

  handleFile(event) {
    let binaryString = event.target.result;
    let base64 = btoa(binaryString);
    base64 = base64.replace(/\+/gi, '-');
    base64 = base64.replace(/\//gi, '_');
    this.addExamOrderAttachment(base64);
  }

  onFileSelected(event: any) {
    let files = event.target.files;
    let file = files[0];
    if (file.size > 5242880) {
      this.showToastEvent.emit(this.createToastToEmit('BigFile', 'error'));
    } else {
      this.loadedFile = {
        type: file.name.split('.').pop(),
        name: file.name
      };

      if (files && file) {
        let reader = new FileReader();
        reader.onload = this.handleFile.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }

  show(orderDetails: IFacilityOrderDetails) {
    this.returnOrderForm.setValue({
      is_complaint: false,
      comment: ''
    });
    this.orderDetails = orderDetails;
    this.currentDesc = orderDetails.exam_description_info;
    $('#descOrderReturn').modal('show');
  }

  switchView(newView: string) {
    this.currentCard = newView;
  }

  submit() {
    this.odataService.postPrivate('mf_exam_order_correct_description',
      this.odataService.paramsTokenAndLang({
        id_exam_order: this.orderDetails.id_exam_order,
        id_medical_facility: this.usersService.getFacilityId(),
        is_complaint: this.returnOrderForm.controls.is_complaint.value,
        notes: this.returnOrderForm.controls.comment.value
      }),
      (res) => {
        let returnedJson = JSON.stringify(res);
        let result = JSON.parse(returnedJson);
        if (result.Status == 'OK') {
          $('#descOrderReturn').modal('hide');
          result = JSON.parse(result.Value);
          if (result.status) this.showToastEvent.emit(this.createToastToEmit('ReturnSuccess', 'success', undefined, true));
          else this.showToastEvent.emit(this.createToastToEmit('ReturnFail', 'error'));
        } else this.showToastEvent.emit(this.createToastToEmit('ReturnFail', 'error'));
      });
  }

  changeDesc(index: number) {
    this.currentDesc = this.orderDetails.exam_description_history[index];
  }

  createToastToEmit(message: string, type: string, timeout?: number, refresh?: boolean): IToastInfo {
    return {
      message: message,
      type: type,
      timeout: timeout ? timeout : 3000,
      refresh: refresh ? refresh : false
    };
  }
}
