import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    emitter: EventEmitter<string> = new EventEmitter<string>();

    token: string;
    session_id: string;
    language: string;

    constructor(private translateService: TranslateService) {
        this.setLanguage();
    }

    restoreToken(): void {
        let localToken: string = sessionStorage.getItem('token');
        this.token = localToken;
    }

    restoreSessionId(): void {
        let localSessionId: string = sessionStorage.getItem('session_id');
        this.session_id = localSessionId;
    }

    restoreAllSessionInfo(): void {
        this.restoreToken();
        this.restoreSessionId();
    }

    getToken(): string {
        if (this.token) {
            return this.token;
        } else {
            this.restoreToken();
            return this.token || null;
        }
    }

    isLocked(): boolean {
        if (localStorage.getItem('lockedSession' + this.getSessionId()) == 'true') return true;
        return false;
    }

    lockSession(): void {
        localStorage.setItem('lockedSession' + this.getSessionId(), 'true');
    }

    unlockSession(): void {
        localStorage.setItem('lockedSession' + this.getSessionId(), 'false');
    }

    tokenExist(): boolean {
        return !!sessionStorage.getItem('token');
    }

    getSessionId(): string {
        if (this.session_id) {
            return this.session_id;
        } else {
            this.restoreSessionId();
            return this.session_id || null;
        }
    }

    setToken(token: string): void {
        if (token) {
            sessionStorage.setItem('token', token);
            this.token = token;
        }
    }

    clearToken(): void {
        this.token = null;
        sessionStorage.removeItem('token');
    }

    setSessionId(sessionId: string): void {
        sessionStorage.setItem('session_id', sessionId);
        this.session_id = sessionId;
    }

    clearSessionId(): void {
        this.session_id = null;
        sessionStorage.removeItem('session_id');
    }

    clearAllSessionInfo(): void {
        localStorage.removeItem('lockedSession' + this.getSessionId());
        this.clearToken();
        this.clearSessionId();
        sessionStorage.clear();
    }

    setNewSessionId(): void {
        let session_id = '_' + Math.random().toString(36).substr(2, 9);
        this.setSessionId(session_id);
    }

    startNewSession(token: string) {
        this.setToken(token);
        localStorage.removeItem('currentCenter');

        let locks: string[] = [];
        for (let key in localStorage) {
            if (key.includes('lockedSession')) {
                locks.push(key);
            }
        }

        locks.forEach( e => localStorage.removeItem(e));
    }

    changeLang() {
        if (this.language == 'pl') {
            this.language = 'en';
        } else if (this.language == 'en') {
            this.language = 'pl';
        }
        this.translateService.use(this.language);
        document.cookie = 'lang=' + this.language + ';max-age=' + 7 * 24 * 60 * 60 + ';';
        this.emitter.emit(this.language);
    }

    setLanguage() {
        let langCookie = document.cookie.split(';').filter(cookie => cookie.includes('lang'));
        this.language = langCookie.length ? langCookie[0].split('=')[1] : 'pl';
    }

    closeSession(odataService: any): void {
        odataService.postPrivate('close_session', {token: this.getToken()}, res => {});
    }

    setCurrentPage(componentName: string, page: number): void {
        localStorage.setItem('currentPageFor' + componentName, page.toString());
    }

    getCurrentPage(componentName: string): number {
        let currentPage = Number(localStorage.getItem('currentPageFor' + componentName));

        if (currentPage <= 0) {
            currentPage = 1;
            this.setCurrentPage(componentName, currentPage);
        }

        return currentPage;
    }

    removeCurrentPage(componentName: string): void {
        localStorage.removeItem('currentPageFor' + componentName);
    }

    setItemsOnPage(componentName: string, numberOfItems: string): void {
        localStorage.setItem('itemsOnPageFor' + componentName, numberOfItems);
    }

    getItemsOnPage(componentName: string): string {
        let itemsOnPage = Number(localStorage.getItem('itemsOnPageFor' + componentName));

        if (itemsOnPage < 10) {
            itemsOnPage = 10;
            this.setItemsOnPage(componentName, String(itemsOnPage));
        } else if (itemsOnPage > 100) {
            itemsOnPage = 100;
            this.setItemsOnPage(componentName, String(itemsOnPage));
        }

        return String(itemsOnPage);
    }

    removeItemsOnPage(componentName: string): void {
        localStorage.removeItem('itemsOnPageFor' + componentName);
    }

    sendMessage(key: string, value: string): void {
        localStorage.setItem(key, value);
        localStorage.removeItem(key);
    }
}
