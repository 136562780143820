import { Injectable } from '@angular/core';
import { UsersService, OdataService, SessionService } from '.';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class MessageSenderService {

    validUser = true;
    validText = true;
    validAttachments = true;
    attachments = [];
    c_attachments = [];

    constructor(public userService: UsersService, public odataService: OdataService,
      private sessionService: SessionService, public envService: EnvService) { }

    setDefaultData() {
        this.validUser = true;
        this.validText = true;
        this.validAttachments = true;
        this.attachments = [];
    }

    onSubmit (userId: number, messageText: string, attachments?: any[], idReply?: number): Promise<any> {
        if (userId) {
            this.validUser = true;
        } else {
            this.validUser = false;
        }

        if (messageText && messageText !== '') {
            this.validText = true;
        } else {
            this.validText = false;
        }

        if (attachments) {
            this.c_attachments = attachments;
            if (attachments.length > 0 && !this.validText) {
              this.validText = true;
              messageText = ' ';
            }
        }

        let valid = true;
        const attachment_list = [];
        let attachmentTmp = attachments ? this.c_attachments : this.attachments;

        for (let i = 0; i < attachmentTmp.length; i++) {
            if (attachmentTmp[i].status != 'ok') {
                valid = false;
                break;
            }
            attachment_list.push(attachmentTmp[i].id);
        }

        this.validAttachments = valid;

        if (this.validUser && this.validText && this.validAttachments) {
            return this.odataService.postPrivate('create_message', this.odataService.paramsTokenAndLang(
                {
                message: messageText,
                recipient_user_id: userId,
                id_reply: idReply ? idReply : null,
                attachment_list: attachment_list.length === 0 ? null : JSON.stringify(attachment_list)
                }),
                res => {
                    const returnedJson = JSON.stringify(res);
                    const result = JSON.parse(returnedJson);
                    if (result.value === 'OK') {
                        return true;
                    } else return false;
                }
            );
        }
        return Promise.resolve();
    }

    onFileSelected(event: any, attachments?: any[]) {
        const files = event.target.files;
        let attachmentTmp = attachments ? this.c_attachments : this.attachments;
        let lengthTmp = attachments ? 3 : 10;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                if (attachmentTmp.length > lengthTmp) {
                    return;
                }

                const attched = {
                    id: '',
                    name: files[i].name,
                    status: 'upload',
                    type: files[i].type
                };

                if (attachments) {
                    this.c_attachments.push(attched);
                } else this.attachments.push(attched);

                if (files[i].size > 10485760) {
                    attched.status = 'invalid';
                    continue;
                }

                const reader = new FileReader();
                reader.onload = this.handleFile.bind(this, attched);
                reader.readAsBinaryString(files[i]);
            }
        }
    }

    handleFile(attched: any, event: any) {
        const binaryString = event.target.result;
        let base64textString = btoa(binaryString);
        base64textString = base64textString.replace(/\+/gi, '-');
        base64textString = base64textString.replace(/\//gi, '_');
        this.uploadAttachmentFile(base64textString, attched);
    }

    uploadAttachmentFile(base64: string, attched: any) {
        this.odataService.postPrivate('upload_message_attachment',
        {
            token: this.sessionService.getToken(),
            language: this.userService.iLanguage,
            base64: base64,
            filename: attched.name,
            content_type: attched.type
        },
        res => {
            const returnedJson = JSON.stringify(res);
            const result = JSON.parse(returnedJson);

            if (result.Status === 'OK') {
                attched.id = result.Value;
                attched.status = 'ok';
            } else {
                attched.status = 'invalid';
                // TODO:
                console.log('upload_message_attachment', result.Status);
            }
        });
    }

    onRemoveFile(index: number, event?: any, attachments?: any) {
        if (event) event.stopPropagation();
        if (attachments) {
            this.c_attachments.splice(index, 1);
        } else this.attachments.splice(index, 1);
    }
}
