import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmOperationModalComponent } from '@/utils/confirm-operation-modal/confirm-operation-modal.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PatientPortalService } from '@/services/patient-portal.service';
import { IShareInfo } from '@/interfaces/PatientsPortal';
import { EnvService } from '@/services/env.service';
import { OrdersService } from '@/services';
import { AlertComponent } from '@/utils';

@Component({
  selector: 'app-patient-share-exam-modal',
  templateUrl: './patient-share-exam-modal.component.html',
  styleUrls: ['./patient-share-exam-modal.component.scss']
})
export class PatientShareExamModalComponent implements OnInit, OnDestroy {

  @Input() isFolderSharing: boolean;
  @Input() shareInfo?: IShareInfo;
  @Input() examId?: number;

  @ViewChild('confirmOperationModal') confirmOperationModal: ConfirmOperationModalComponent;
  @ViewChild('loginAlert') loginAlert: AlertComponent;

  @Output() refreshShareInfo: EventEmitter<any> = new EventEmitter<any>();

  endShareDate: string = moment().add(120, 'day').format('DD-MM-YYYY');

  afterSubmitScreen: boolean = false;
  copiedText: string;
  confirmOperationSubscription: Subscription;
  isSuccess: boolean = false;
  isError: boolean = false;
  password: string = '';

  constructor(private formBuilder: FormBuilder, private translate: TranslateService, private patientPortalService: PatientPortalService,
              public env: EnvService, public orderService: OrdersService) { }

  ngOnInit() {
    this.confirmOperationEvent();
  }

  ngOnDestroy() {
    this.confirmOperationSubscription.unsubscribe();
  }

  confirmOperationEvent() {
    this.confirmOperationSubscription = this.confirmOperationModal.confirmed.subscribe(e => {
      if (e.isConfirmed)
        this.stopAccess();
    });
  }

  show(isShared: boolean, isFolderSharing?: boolean): void {
    this.afterSubmitScreen = isShared;
    if (isFolderSharing != undefined) this.isFolderSharing = isFolderSharing;
    $('#shareExamModal').modal('show');
  }

  share() {
    if (!this.isFolderSharing) {
      this.patientPortalService.shareExam(this.examId).then( res => {
        if (res) {
          this.refreshShareInfo.emit();
          this.afterSubmitScreen = true;
      }});
    } else {
      this.isError = false;
      this.loginAlert.clear();
      this.patientPortalService.checkPasswordFromToken(this.password).then( res => {
        if (res) {
          this.patientPortalService.shareFolder().then(res => {
            if (res) {
              this.refreshShareInfo.emit({folderSharing: true});
              this.afterSubmitScreen = true;
            }
          });
        } else {
          this.isError = true;
          this.loginAlert.displayError('WRONG_PASSWORD');
        }
      });
    }
  }

  stopAccess() {
    if (this.isFolderSharing) this.patientPortalService.cancelSharingFolder().then( res => this.refreshShareInfo.emit());
    else {
      this.patientPortalService.cancelSharingExam(this.examId).then( res => this.refreshShareInfo.emit());
    }
    $('#shareExamModal').modal('hide');
  }

  showConfirmModal() {
    this.confirmOperationModal.show(
      this.translate.instant('PatientsPortal.ShareExamModal.ConfirmModalTitle'),
      this.translate.instant('PatientsPortal.ShareExamModal.ConfirmBtnTxt'),
      this.translate.instant('PatientsPortal.ShareExamModal.CancelBtnTxt'),
      this.isFolderSharing ? 'cancelAll' : 'cancelSingular',
      this.translate.instant('PatientsPortal.ShareExamModal.ConfirmModalContent')
    );
  }
}
