import {Injectable} from '@angular/core';
import {UsersService} from '@/services/users.service';
import {OdataService} from '@/services/odata.service';

@Injectable({
    providedIn: 'root'
})
export class OrderActionsService {
    cameFrom: string = '';

    constructor(private userService: UsersService, private odataService: OdataService) { }

    viewExamOrder(examId: number): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: examId
            }
        );

        let result = this.odataService.postPrivate('get_co_exam_action_view_exam', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });

        return result;
    }

    coExamActionStartDescrption(examId: number): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId
            }
        );

        let result = this.odataService.postPrivate('co_exam_action_start_descrption', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

  coExamActionStartDescriptionCorrection(examId: number): Promise<boolean> {
    let params = this.odataService.paramsTokenAndLang(
      {
        id_describing_center: this.userService.getCenterId(),
        id_exam_order: examId
      }
    );

    return this.odataService.postPrivate('co_exam_action_start_description_correction', params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      return result.Status == 'OK';
    });
  }

    coExamReopenDescription(examId: number): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: examId,
                reason: 'Test'
            }
        );

        let result = this.odataService.postPrivate('reopen_exam_description', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.ActionStatus) {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

    coExamActionAssignAndStartDescription(examId: number): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId
            }
        );

        let result = this.odataService.postPrivate('co_exam_action_assign_and_start_description', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

    coExamActionReject(examId: number, value): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId,
                reject_reason: value
            }
        );

        let result = this.odataService.postPrivate('co_exam_action_reject', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

    coExamActionAssignToPhysian(examId: number, value) {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId,
                id_physician_to_assign: value
            }
        );

        let result = this.odataService.postPrivate('co_exam_action_assign_to_physian', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

    coExamActionAssignToMe(examId: number): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId
            }
        );

        let result = this.odataService.postPrivate('co_exam_action_assign_to_me', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
                // TODO
            }
        });
        return result;
    }

    coExamActionRejectByPhysician(examId: number, value): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId,
                reject_reason: value
            }
        );

        let result = this.odataService.postPrivate('co_exam_action_reject_by_physician', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

    coExamActionOrderToCorrect(examId: number, value): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_exam_order: examId,
                reason: value
            }
        );

        return this.odataService.postPrivate('exam_description_to_correct', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return result.ActionStatus;
            } else {
                return false;
            }
        });
    }

    coExamActionCancelAssigment(examId: number, value): Promise<boolean> {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId,
                reject_reason: value
            }
        );

        let result = this.odataService.postPrivate('co_exam_cancel_assigment_action', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

    coExamActionChangeAssigment(examId: number, value) {
        let params = this.odataService.paramsTokenAndLang(
            {
                id_describing_center: this.userService.getCenterId(),
                id_exam_order: examId,
                id_physician_to_assign: value
            }
        );

        let result = this.odataService.postPrivate('co_exam_change_assigment_action', params, res => {
            let returnedJson = JSON.stringify(res);
            let result = JSON.parse(returnedJson);
            if (result.Status == 'OK') {
                return true;
            } else {
                return false;
            }
        });
        return result;
    }

}
