import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-name-and-description-modal',
    templateUrl: './name-and-description-modal.component.html',
    styleUrls: ['./name-and-description-modal.component.css']
})
export class NameAndDescriptionModalComponent implements OnInit {
    title: string;
    label1: string;
    label2: string;

    constructor() {
    }

    ngOnInit() {
    }

    setContent(title: string, l1: string, l2: string): void {
        this.title = title;
        this.label1 = l1;
        this.label2 = l2;
    }

    show(): void {
        $('#doubleInputModal').modal('show');
    }

    hide(): void {
        $('#doubleInputModal').modal('hide');
    }

}
