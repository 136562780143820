/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dd-notifications-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "../../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i5 from "angular-svg-icon";
import * as i6 from "../short-notification/short-notification.component.ngfactory";
import * as i7 from "../short-notification/short-notification.component";
import * as i8 from "../../../../services/users.service";
import * as i9 from "../../../../services/odata.service";
import * as i10 from "../../../../services/session.service";
import * as i11 from "@angular/router";
import * as i12 from "@ngx-translate/core";
import * as i13 from "ngx-bootstrap/component-loader";
import * as i14 from "./dd-notifications-button.component";
import * as i15 from "../../../../services/notifications.service";
var styles_DdNotificationsButtonComponent = [i0.styles];
var RenderType_DdNotificationsButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DdNotificationsButtonComponent, data: {} });
export { RenderType_DdNotificationsButtonComponent as RenderType_DdNotificationsButtonComponent };
function View_DdNotificationsButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_DdNotificationsButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brak powiadomie\u0144"]))], null, null); }
function View_DdNotificationsButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " powiadomie\u0144"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_DdNotificationsButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notificationsCount == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.notificationsCount > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DdNotificationsButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-secondary dropdown-toggle e-notifications-dropdown"], ["dropdownToggle", ""], ["id", "ddNotifications"], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], ["document", "click"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onEsc() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i3.BsDropdownToggleDirective, [i3.BsDropdownState, i1.ElementRef, i3.BsDropdownDirective], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svg-icon", [["class", "svg"], ["src", "../../../../../assets/images/icons/infobar/icon__notification.svg"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(3, 1032192, null, 0, i5.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i5.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "../../../../../assets/images/icons/infobar/icon__notification.svg"; var currVal_4 = true; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = (_co.type == "normal"); _ck(_v, 5, 0, currVal_5); var currVal_6 = (_co.type == "full"); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).isDisabled; var currVal_2 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DdNotificationsButtonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brak powiadomie\u0144"]))], null, null); }
function View_DdNotificationsButtonComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " powiadomie\u0144"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_DdNotificationsButtonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-secondary dropdown-toggle e-notifications-dropdown"], ["dropdownToggle", ""], ["id", "ddNotifications"], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], ["document", "click"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onEsc() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i3.BsDropdownToggleDirective, [i3.BsDropdownState, i1.ElementRef, i3.BsDropdownDirective], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svg-icon", [["class", "svg"], ["src", "../../../../../assets/images/icons/infobar/icon__notification.svg"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(7, 1032192, null, 0, i5.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i5.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.notificationsCount == 0); _ck(_v, 3, 0, currVal_3); var currVal_4 = (_co.notificationsCount > 0); _ck(_v, 5, 0, currVal_4); var currVal_5 = "../../../../../assets/images/icons/infobar/icon__notification.svg"; var currVal_6 = true; _ck(_v, 7, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).isDisabled; var currVal_2 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DdNotificationsButtonComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-short-notification", [], null, [[null, "close"], [null, "extend"], [null, "read"], [null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 4).hide() !== false);
        ad = (pd_0 && ad);
    } if (("extend" === en)) {
        var pd_1 = (_co.onExtend($event) !== false);
        ad = (pd_1 && ad);
    } if (("read" === en)) {
        var pd_2 = (_co.onRead($event) !== false);
        ad = (pd_2 && ad);
    } if (("remove" === en)) {
        var pd_3 = (_co.onRemove($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i6.View_ShortNotificationComponent_0, i6.RenderType_ShortNotificationComponent)), i1.ɵdid(2, 114688, null, 0, i7.ShortNotificationComponent, [i8.UsersService, i9.OdataService, i10.SessionService, i11.Router], { notification: [0, "notification"] }, { remove: "remove", read: "read", close: "close", extend: "extend" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DdNotificationsButtonComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "ul", [["aria-labelledby", "ddNotifications"], ["class", "dropdown-menu dropdown-menu-right notifications-list-container"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " (", ")"])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-light float-right"], ["style", "position: relative; top: -4px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClear($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_10)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["class", "btn btn-link"], ["routerLink", "/notifications"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v.parent, 4).hide() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.notifications; _ck(_v, 12, 0, currVal_3); var currVal_6 = "/notifications"; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("DashboardScreen.Notifications")); var currVal_1 = _co.notificationsCount; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("DashboardScreen.Clear")); _ck(_v, 9, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 15).target; var currVal_5 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("DashboardScreen.All")); _ck(_v, 16, 0, currVal_7); }); }
export function View_DdNotificationsButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 10, "div", [["class", "dropdown dropdown-notifications"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "onHidden"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHidden" === en)) {
        var pd_0 = (_co.onHidden() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "unread": 0 }), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(4, 212992, [["dropdown", 4]], 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i13.ComponentLoaderFactory, i3.BsDropdownConfig, i3.BsDropdownState], { insideClick: [0, "insideClick"] }, { onHidden: "onHidden" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DdNotificationsButtonComponent_9)), i1.ɵdid(10, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "dropdown dropdown-notifications"; var currVal_4 = _ck(_v, 2, 0, (_co.notificationsCount != 0)); _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = true; _ck(_v, 4, 0, currVal_5); var currVal_6 = (_co.type != "pp"); _ck(_v, 6, 0, currVal_6); var currVal_7 = (_co.type == "pp"); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).dropup; var currVal_1 = i1.ɵnov(_v, 4).isOpen; var currVal_2 = (i1.ɵnov(_v, 4).isOpen && i1.ɵnov(_v, 4).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DdNotificationsButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dd-notifications-button", [], null, null, null, View_DdNotificationsButtonComponent_0, RenderType_DdNotificationsButtonComponent)), i1.ɵdid(1, 245760, null, 0, i14.DdNotificationsButtonComponent, [i8.UsersService, i9.OdataService, i10.SessionService, i15.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DdNotificationsButtonComponentNgFactory = i1.ɵccf("app-dd-notifications-button", i14.DdNotificationsButtonComponent, View_DdNotificationsButtonComponent_Host_0, { type: "type" }, {}, []);
export { DdNotificationsButtonComponentNgFactory as DdNotificationsButtonComponentNgFactory };
