<div id="top-bar" class="col-col-md-12" style="z-index: 50;">
    <div class="row">
        <div class="col-md-12">
            <h3 class="page-name">{{'DashboardScreen.OrderHistory' | translate}}</h3>

            <ul *ngIf="!envService.hideUnderConstruction" id="top-bar-nav">
                <li>
                    <a>{{'Datatable.History.CurrentMonth' | translate}}</a>
                </li>
                <li>
                    <a>{{'Datatable.History.LastMonth' | translate}}</a>
                </li>
                <li>
                    <a>{{'Datatable.History.CurrentYear' | translate}}</a>
                </li>
                <li>
                    <a>{{'Datatable.History.LastYear' | translate}}</a>
                </li>
            </ul>

            <form *ngIf="!envService.hideUnderConstruction">
                <ul class="top-bar-nav">
                    <li class="filter_dropdown" (click)="dropdown()">
                        <a>{{'Datatable.History.Filter' | translate}}
                            <svg-icon class="svg" src="../../../../assets/images/icons/icon__arrow_down.svg" alt=""></svg-icon>
                        </a>
                    </li>
                </ul>
                <app-search #search placeholder="Datatable.History.SearchPlaceholder"></app-search>
            </form>

        </div>
    </div>

</div>

<app-filters #filters type="HISTORY"></app-filters>

<app-datatable #datatable type="HISTORY"></app-datatable>

<div class="row">
    <div class="col-md-12 mt-5">
        <div class="card">
            <div class="card-body p-4">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="strong pt-2 m-0">{{'Datatable.History.Orders' | translate}}: {{datatable.rows.length}}</h4>
                        <h4 class="strong pb-0 m-0 blue">{{'Datatable.History.Sum' | translate}}: {{datatable.sum}} PLN {{'Datatable.History.Netto' | translate}}</h4>
                    </div>
                    <div *ngIf="!envService.hideUnderConstruction" class="col-md-6">
                        <a routerLink="/siteUnderConstruction" class="btn btn-lg btn-primary float-right mr-0">{{'Datatable.History.Export' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>