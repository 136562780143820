import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-universal-toast',
    templateUrl: './universal-toast.component.html',
    styleUrls: ['./universal-toast.component.scss']
})
export class UniversalToastComponent implements OnInit {

    isLoaderShown: boolean = false;
    isSaveExam: boolean = false;
    info: string = '';
    sendingExamInfo: string = '';

    constructor(private translate: TranslateService) {
    }

    ngOnInit() {
    }

    infoMessage(message: string): void {
        this.info = message;
    }

    success(message: string, sendingInfo?: string): void {
        this.translate.get('ToastMessage.' + message).subscribe((res) => {
            this.info = res;
        });
        document.getElementById('infoMessage').style.color = '#0848FF';

        if (sendingInfo) {
            this.isLoaderShown = true;
            this.isSaveExam = true;
            this.translate.get('ToastMessage.' + sendingInfo).subscribe((res) => {
                this.sendingExamInfo = res;
            });
        }
    }

    error(message: string): void {
        this.isLoaderShown = false;
        this.isSaveExam = false;
        this.translate.get('ToastMessage.' + message).subscribe((res) => {
            this.info = res;
        });
        document.getElementById('infoMessage').style.color = '#D91657';
    }

    hide(): void {
        $('#data-saved-toast').toast('hide');
        this.isLoaderShown = false;
        this.isSaveExam = false;
    }

    show(): void {
        $('#data-saved-toast').toast('show');
    }

}
