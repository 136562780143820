import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { INVOICES, BALANCES, HISTORY } from './dummyData';
import { OdataService, AdminService, SessionService } from '@/services';
import './date-sort-script.js';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  HISTORYCOLUMNS,
  BALANCESCOLUMNS,
  INVOICESCOLUMNS,
  FACILITIESCOLUMNS,
  USERSCOLUMNS,
  ORDERSCOLUMNS,
  COCOLUMNS,
  EXAMSCOLUMNS,
  CODETAILS, CO_TRANSFER_COLUMNS, CO_STAFF_COLUMNS
} from './columns';
import { EnvService } from '@/services/env.service';
import { AlertModalConfig } from '@/utils/alert-modal/alert-modal.config';
import { AlertModalComponent } from '@/utils/alert-modal/alert-modal.component';
import { ICenterInfo, IColumns, IShortCenterInfo } from '@/utils/datatable/Interfaces';


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit {
  @Input() type: string;
  @ViewChild('infoModal') infoModal: AlertModalComponent;

  rowsNumberEmitter: EventEmitter<number> = new EventEmitter<number>();

  dtOptions: any;
  columnDefs: any[] = [];
  searching: boolean = false;
  datatable: any;
  rows: any[] = [];
  order: any[] = [];
  sum: number = 0;
  paging: boolean = false;
  info: boolean = false;
  columns: IColumns[] = [];
  centers: ICenterInfo[] = [];
  alertModalConfig: AlertModalConfig = {
    closeButtonLabel: '', modalText: '', modalTitle: ''
  };

  lastCOColumn: IColumns = {
    title: 'Datatable.Centers.Actions',
    render: (data: any, type: any, full: any) => {
      let retVal: string = '';

      retVal += '<a id="seeDetails" class="btn btn-sm btn-white">';
      this.translate.get('Datatable.Centers.Details').subscribe((res) => {
        retVal += res + '</a>';
      });

      retVal += `<button type="button" class="btn btn-light card-action"
                style="width: 115px; margin-top: 10px;" id="ddAction1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <img class="svg" src="../../../../assets/images/icons/more-dots.svg" alt="" style="margin-left: 15px;">
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
                <a id="seeOrders" class="dropdown-item"" style="cursor: pointer;">`;
      this.translate.get('Datatable.Centers.SeeOrders').subscribe((res) => {
        retVal += res + '</a>';
      });
      retVal += `<a id="editCenter" class="dropdown-item"" style="cursor: pointer;">`;

      this.translate.get('Datatable.Centers.EditCenter').subscribe((res) => {
        retVal += res + '</a>';
      });

      retVal += `<a id="seeTransfers" class="dropdown-item"" style="cursor: pointer;">`;

      this.translate.get('Datatable.Centers.SeeTransfers').subscribe((res) => {
        retVal += res + '</a>';
      });

      retVal += `<a id="seeEmployees" class="dropdown-item"" style="cursor: pointer;">`;

      this.translate.get('Datatable.Centers.SeeEmployees').subscribe((res) => {
        retVal += res + '</a>';
      });

      /*retVal += `<a id="seeDoctorsProfile" class="dropdown-item" style="margin-right: 15px; cursor:pointer;">`;

      this.translate.get('Datatable.Centers.SeeOwnerProfile').subscribe((res) => {
          retVal += res + '</a></div>';
      });*/

      return retVal;
    }
  };

  lastOrdersColumn: IColumns = {
    title: 'Datatable.Orders.Actions',
    render: (data: any, type: any, full: any) => {
      let retVal: string = '';

      retVal += '<a id="seeHistory" class="btn btn-sm btn-white">';
      this.translate.get('Datatable.Orders.SeeActionHistory').subscribe((res) => {
        retVal += res + '</a>';
      });

      retVal += `<button type="button" class="btn btn-light card-action"
                style="width: 146px; margin-top: 10px;" id="ddAction1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <img class="svg" src="../../../../assets/images/icons/more-dots.svg" alt="" style="margin-left: 20px;">
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
                <a id="editOrder" class="dropdown-item"" style="cursor: pointer;">`;

      this.translate.get('Datatable.Orders.EditOrder').subscribe((res) => {
        retVal += res + '</a></div>';
      });

      return retVal;
    }
  };

  lastUsersColumn: IColumns = {
    title: 'Datatable.Users.Actions',
    render: (data: any, type: any, full: any) => {
      let retVal: string = '';

      retVal += '<a id="seeProfile" class="btn btn-sm btn-white">';
      this.translate.get('Datatable.Users.SeeProfile').subscribe((res) => {
        retVal += res + '</a>';
      });

      retVal += `<button type="button" class="btn btn-light card-action"
                style="width: 115px; margin-top: 10px;" id="ddAction1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <img class="svg" src="../../../../assets/images/icons/more-dots.svg" alt="" style="margin-left: 15px;">
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
                <a id="editUser" class="dropdown-item"" style="cursor: pointer;">`;

      this.translate.get('Datatable.Users.EditUser').subscribe((res) => {
        retVal += res + '</a></div>';
      });

      return retVal;
    }
  };

  lastFacilitisColumn: IColumns = {
    title: 'Datatable.Users.Actions',
    render: (data: any, type: any, full: any) => {
      let retVal: string = '';

      retVal += '<a id="seeOrders" class="btn btn-sm btn-white">';
      this.translate.get('Datatable.Facilities.SeeOrders').subscribe((res) => {
        retVal += res + '</a>';
      });

      retVal += `<button type="button" class="btn btn-light card-action"
                style="width: 115px; margin-top: 10px;" id="ddAction1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <img class="svg" src="../../../../assets/images/icons/more-dots.svg" alt="" style="margin-left: 15px;">
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="ddAction1">
                <a id="seeExams" class="dropdown-item"" style="cursor: pointer;">`;

      this.translate.get('Datatable.Facilities.SeeExams').subscribe((res) => {
        retVal += res + '</a></div>';
      });

      return retVal;
    }
  };

  lastExamsColumn: IColumns = {
    title: 'Datatable.Users.Actions',
    render: (data: any, type: any, full: any) => {
      let retVal: string = '';

      retVal += '<a id="createOrder" class="btn btn-sm btn-white">';
      this.translate.get('Datatable.Exams.CreateOrder').subscribe((res) => {
        retVal += res + '</a>';
      });

      return retVal;
    }
  };
  lastCoStaffCol: IColumns = {
    title: 'Datatable.Users.Actions',
    render: (data: any, type: any, full: any) => {
      let retVal: string = '';
      retVal += '<a id="goToUser" class="btn btn-sm btn-light">' + this.translateText('Datatable.COStaff.GoToUser') + '</a>';
      return retVal;
    }
  };

  constructor(public envService: EnvService, private odataService: OdataService, private adminService: AdminService, private router: Router,
              private translate: TranslateService, private sessionService: SessionService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    Promise.all([this.getRows(), this.translateCol()]).then(() => {
      this.chooseParameters();

      this.dtOptions = {
        searching: this.searching,
        paging: this.paging,
        info: this.info,
        data: this.rows,
        order: this.order,
        'language': {
          'processing': 'Przetwarzanie...',
          'search': 'Szukaj:',
          'lengthMenu': 'Pokaż _MENU_ pozycji',
          'info': 'Pozycje od _START_ do _END_ z _TOTAL_ łącznie',
          'infoEmpty': 'Pozycji 0 z 0 dostępnych',
          'infoFiltered': '(filtrowanie spośród _MAX_ dostępnych pozycji)',
          'infoPostFix': '',
          'loadingRecords': 'Wczytywanie...',
          'zeroRecords': 'Nie znaleziono pasujących pozycji',
          'emptyTable': 'Brak danych',
          'paginate': {
            'first': 'Pierwsza',
            'previous': 'Poprzednia',
            'next': 'Następna',
            'last': 'Ostatnia'
          },
          'aria': {
            'sortAscending': ': aktywuj, by posortować kolumnę rosnąco',
            'sortDescending': ': aktywuj, by posortować kolumnę malejąco'
          }
        },
        columnDefs: this.columnDefs,
        columns: this.columns,
        rowCallback: (row: Node, data: any, index: number) => {
          return this.rowCallback(row, data, index);
        }

      };

      if (this.type == 'BALANCE' || this.type == 'HISTORY') {
        this.sum = this.countSum();
      }
      if (this.type == 'HISTORY' && this.envService.hideUnderConstruction) this.columns[5].defaultContent = '';
      let locale = this.sessionService.language;
      let order = $.fn.dataTable.ext.type.order;
      delete order['string-pre'];
      order['string-asc'] = function(a, b) {return a.localeCompare(b, locale); };
      order['string-desc'] = function(a, b) {return b.localeCompare(a, locale); };

      $.fn.dataTable['moment']('DD.MM.YYYY');

      this.datatable = $('#dataTable').DataTable(this.dtOptions);
    });
  }

  getRows(): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      if (this.type == 'INVOICES') {
        this.getInvoices(resolve, reject);
      } else if (this.type == 'BALANCE') {
        this.getBalances(resolve, reject);
      } else if (this.type == 'HISTORY') {
        this.getHistory(resolve, reject);
      } else if (this.type == 'USERS') {
        this.getUsers(resolve, reject, 10, 0);
      } else if (this.type == 'COLIST') {
        this.getCOList(resolve, reject, 10, 0);
      } else if (this.type == 'ADMINORDERSVIEW') {
        this.getOrdersForAdmin(resolve, reject, 10, 0);
      } else if (this.type == 'FACILITIES') {
        this.getFacilitiesForAdmin(resolve, reject, 10, 0);
      } else if (this.type == 'EXAMS') {
        this.getExams(resolve, reject, 10, 0);
      } else if (this.type == 'CO_TRANSFERS') {
        this.getCOTransfers(resolve, reject, 200, 0);
      } else if (this.type == 'CO_STAFF') {
        this.getCOStaff(resolve, reject, 200, 0);
      }
    });

    return promise;
  }

  getInvoices(resolve: Function, reject: Function): void {
    this.rows = INVOICES;
    resolve('OK');
  }

  getBalances(resolve: Function, reject: Function): void {
    this.rows = BALANCES;
    resolve('OK');
  }

  getHistory(resolve: Function, reject: Function): void {
    this.rows = HISTORY;
    resolve('OK');
  }

  getExams(resolve: Function, reject: Function, limit: number, offset: number): void {
    let params: any = this.odataService.paramsTokenAndLang({
      id_medical_facility: parseInt(this.route.snapshot.queryParamMap.get('idMedicalFacility')),
      offset: offset,
      limit: limit
    });

    this.adminService.getExams(params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        this.rows = JSON.parse(result.Value);
        this.rowsNumberEmitter.emit(result.ValueCount);
        resolve('OK');
      } else {
        // TODO
      }
    });
  }

  getUsers(resolve: Function, reject: Function, limit: number, offset: number): void {
    let params: any = this.odataService.paramsTokenAndLang({
      skip: offset,
      top: limit
    });

    this.adminService.getUsers(params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);

      if ('value' in result) {
        this.rows = result.value;
      } else {
        this.rows = [];
      }

      resolve('OK');
    });
  }

  getCOList(resolve: Function, reject: Function, limit: number, offset: number): void {
    let params: any = this.odataService.paramsTokenAndLang({
      offset: offset,
      limit: limit
    });
    this.rows = [];
    this.adminService.getCenters(params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        this.centers = JSON.parse(result.Value);
        this.centers.forEach(center => {
          let shortCenter: IShortCenterInfo = {
            account_confirmed: center.account_confirmed,
            active: center.active,
            address_city: center.address_city,
            creator_id_user: center.creator_id_user,
            email: center.email,
            id_describing_center: center.id_describing_center,
            name: center.name
          };
          this.rows.push(shortCenter);
        });
        resolve('OK');
      } else {
        // TODO
      }
    });
  }

  getOrdersForAdmin(resolve: Function, reject: Function, limit: number, offset: number): void {
    let params: any = this.odataService.paramsTokenAndLang({
      id_describing_center: parseInt(this.route.snapshot.queryParamMap.get('idCenter')),
      filtr: '{}',
      offset: offset,
      limit: limit,
      filtr_search: ''
    });

    this.adminService.getOrdersForAdmin(params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        this.rows = JSON.parse(result.Value);
        this.rowsNumberEmitter.emit(result.ValueCount);
        resolve('OK');
      } else {
        // TODO
      }
    });
  }

  getFacilitiesForAdmin(resolve: Function, reject: Function, limit: number, offset: number): void {
    let params: any = this.odataService.paramsTokenAndLang({
      limit: limit,
      offset: offset
    });

    this.adminService.getFacilities(params, res => {
      let returnedJson = JSON.stringify(res);
      let result = JSON.parse(returnedJson);
      if (result.Status == 'OK') {
        this.rows = JSON.parse(result.Value);
        this.rowsNumberEmitter.emit(result.ValueCount);
        resolve('OK');
      } else {
        // TODO
      }
    });
  }

  countSum(): number {
    let result = 0;
    this.rows.forEach((row) => {
      result += row.sum;
    });
    return result;
  }

  search(input: string): void {
    this.datatable.search(input).draw();
  }

  editCenter(center: any): void {
    this.router.navigate(['editCO'], {queryParams: {idCenter: center.id_describing_center}});
  }

  navigateToOrders(id_describing_center: number, center_name: string): void {
    this.router.navigate(['adminOrdersView'], {queryParams: {idCenter: id_describing_center, centerName: center_name}});
  }

  navigateToOrderActionHistory(id_order: number): void {
    this.router.navigate(['adminOrderActionHistory'], {queryParams: {idOrderActionHistory: id_order}});
  }

  navigateToDoctorsProfile(id: number): void {
    this.router.navigate(['doctorsProfile/' + id]);
  }

  navigateToFacilitiesOrders(id: number): void {
    this.router.navigate(['siteUnderConstruction']);
  }

  navigateToFacilitiesExams(id: number): void {
    this.router.navigate(['adminFacilitiesExamsList'], {queryParams: {idMedicalFacility: id}});
  }

  reloadRows(limit: number, offset: number): void {
    let promise = new Promise<any>((resolve, reject) => {
      if (this.type == 'INVOICES') {
        this.getInvoices(resolve, reject);
      } else if (this.type == 'BALANCE') {
        this.getBalances(resolve, reject);
      } else if (this.type == 'HISTORY') {
        this.getHistory(resolve, reject);
      } else if (this.type == 'COLIST') {
        this.getCOList(resolve, reject, limit, offset);
      } else if (this.type == 'ADMINORDERSVIEW') {
        this.getOrdersForAdmin(resolve, reject, limit, offset);
      } else if (this.type == 'FACILITIES') {
        this.getFacilitiesForAdmin(resolve, reject, limit, offset);
      } else if (this.type == 'EXAMS') {
        this.getExams(resolve, reject, limit, offset);
      } else if (this.type == 'CO_TRANSFERS') {
        this.getCOTransfers(resolve, reject, limit, offset);
      }
    });

    promise.then(() => {
      this.datatable.clear().draw();
      this.datatable.rows.add(this.rows);
      this.datatable.columns.adjust().draw();
    });
  }

  translateCol(): Promise<any> {
    this.columns = [];
    let promise: Promise<any> = new Promise((resolve, reject) => {
      if (this.type == 'COLIST') {
        if (COCOLUMNS.length < 8) COCOLUMNS.push(this.lastCOColumn);
        this.prepareColumns(COCOLUMNS, resolve);
      } else if (this.type == 'ADMINORDERSVIEW') {
        if (ORDERSCOLUMNS.length < 18) ORDERSCOLUMNS.push(this.lastOrdersColumn);
        this.prepareColumns(ORDERSCOLUMNS, resolve);
      } else if (this.type == 'BALANCE') {
        this.prepareColumns(BALANCESCOLUMNS, resolve);
      } else if (this.type == 'HISTORY') {
        this.prepareColumns(HISTORYCOLUMNS, resolve);
      } else if (this.type == 'INVOICES') {
        this.prepareColumns(INVOICESCOLUMNS, resolve);
      } else if (this.type == 'FACILITIES') {
        if (FACILITIESCOLUMNS.length < 17) FACILITIESCOLUMNS.push(this.lastFacilitisColumn);
        this.prepareColumns(FACILITIESCOLUMNS, resolve);
      } else if (this.type == 'EXAMS') {
        if (EXAMSCOLUMNS.length < 26) EXAMSCOLUMNS.push(this.lastExamsColumn);
        this.prepareColumns(EXAMSCOLUMNS, resolve);
      } else if (this.type == 'CO_TRANSFERS') {
        this.prepareColumns(CO_TRANSFER_COLUMNS, resolve);
      } else if (this.type == 'CO_STAFF') {
        if (CO_STAFF_COLUMNS.length < 5) CO_STAFF_COLUMNS.push(this.lastCoStaffCol);
        this.prepareColumns(CO_STAFF_COLUMNS, resolve);
      } else {
        resolve('OK');
      }
    });

    return promise;
  }

  prepareColumns(COLS: IColumns[], resolve: Function): void {
    COLS.forEach((e) => {
      // this.translate.get(e.title).subscribe(res => {
      //     this.columns.push({ title: res, data: e.data, defaultContent: e.defaultContent, render: e.render });
      //     if (this.columns.length == COLS.length) resolve('OK');
      // });

      let clone = Object.assign({}, e);

      if ('title' in clone) {
        this.translate.get(clone.title).subscribe(res => {
          clone.title = res;
          this.columns.push(clone);
          if (this.columns.length == COLS.length) resolve('OK');

        });
      } else {
        this.columns.push(clone);
        if (this.columns.length == COLS.length) resolve('OK');
      }
    });
  }

  chooseParameters(): void {
    if (this.type == 'INVOICES' || this.type == 'HISTORY') {
      this.columnDefs = [{ 'orderable': false, 'targets': 5, 'class': 'smallCol' }];
      this.paging = true;
      this.info = true;
    } else if (this.type == 'BALANCES') {
      this.paging = true;
      this.info = true;
    } else if (this.type == 'COLIST') {
      this.columnDefs = [{ 'orderable': false, 'targets': 7, 'class': 'smallCol' }];
      this.searching = true;
    } else if (this.type == 'ADMINORDERSVIEW') {
      this.columnDefs = [{ 'orderable': false, 'targets': 17, 'class': 'smallCol' }];
      this.searching = true;
    } else if (this.type == 'FACILITIES') {
      this.columnDefs = [{ 'orderable': false, 'targets': 16, 'class': 'smallCol' }];
      this.searching = true;
    } else if (this.type == 'EXAMS') {
      this.columnDefs = [{ 'orderable': false, 'targets': 25, 'class': 'smallCol' }];
      this.searching = true;
    } else if (this.type == 'CO_TRANSFERS') {
      this.searching = true;
      this.paging = true;
      this.order = [[4, 'desc']];
    } else if (this.type == 'CO_STAFF') {
      this.paging = true;
      this.order = [[0, 'asc']];
      this.columnDefs = [{'orderable': false, 'targets': 4, 'class': 'smallCol'}];
    }
  }

  rowCallback(row: Node, data: any, index: number): Node {
    if (this.type == 'COLIST') {
      $('#seeOrders', row).off('click');
      $('#seeOrders', row).on('click', () => {
        this.navigateToOrders(data.id_describing_center, data.name);
      });
      $('#seeDetails', row).off('click');
      $('#seeDetails', row).on('click', () => {
        this.displayCenterDetails(index);
      });
      $('#editCenter', row).off('click');
      $('#editCenter', row).on('click', () => {
        this.editCenter(data);
      });
      $('#seeTransfers', row).off('click');
      $('#seeTransfers', row).on('click', () => {
        this.navigateToTransfers(data);
      });
      $('#seeEmployees', row).off('click');
      $('#seeEmployees', row).on('click', () => {
        this.navigateToEmployees(data);
      });
      /*$('#seeDoctorsProfile', row).off('click');
      $('#seeDoctorsProfile', row).on('click', () => {
          this.navigateToDoctorsProfile(data.creator_id_user);
      });*/
    } else if (this.type == 'ADMINORDERSVIEW') {
      $('#seeHistory', row).off('click');
      $('#seeHistory', row).on('click', () => {
        this.navigateToOrderActionHistory(data.id_order);
      });
      $('#editOrder', row).off('click');
      $('#editOrder', row).on('click', () => {
        console.log('Edit order');
      });
    } else if (this.type == 'FACILITIES') {
      $('#seeOrders', row).off('click');
      $('#seeOrders', row).on('click', () => {
        this.navigateToFacilitiesOrders(data.id_medical_facility);
      });
      $('#seeExams', row).off('click');
      $('#seeExams', row).on('click', () => {
        this.navigateToFacilitiesExams(data.id_medical_facility);
      });
    } else if (this.type == 'EXAMS') {
      $('#createOrder', row).off('click');
      $('#createOrder', row).on('click', () => {
        this.router.navigate(['siteUnderConstruction']);
      });
    } else if (this.type == 'CO_STAFF') {
      $('#goToUser', row).off('click');
      $('#goToUser', row).on('click', () => {
        this.router.navigate(['doctorsProfile/' + data.idEmployee]);
      });
    }

    return row;
  }

  displayCenterDetails(index: number) {
    const centerToDisplay: ICenterInfo = this.centers[index];
    let modalText: string = '';
    modalText += `
        <table class="table-striped tile-table">`;
    let i = 0;
    for (const [, value] of Object.entries(centerToDisplay)) {
      modalText += `<tr><td>` + this.translateText(CODETAILS[i].title) + `</td><td>` + value + `</td></tr>`; i++;
    }
    modalText += ` </table>`;

    this.alertModalConfig = {
      separateModalText: true,
      closeButtonLabel: '',
      modalText: modalText,
      modalTitle: 'CenterDetails'
    };
    this.infoModal.show();
  }

  translateText(text: string): string {
    let translated = '';
    this.translate.get(text).subscribe((res) => {
      translated = res;
    });
    return translated;
  }

  private navigateToTransfers(data: any) {
    this.router.navigate(['adminCoTransfers'],
      {queryParams: {idCenter: data.id_describing_center, centerName: data.name}});

  }

  private navigateToEmployees(data: any) {
    this.router.navigate(['adminCoEmployees'],
      {queryParams: {idCenter: data.id_describing_center, centerName: data.name}});

  }

  private getCOTransfers(resolve: Function, reject: Function, limit: number, offset: number) {
    let params: any = this.odataService.paramsTokenAndLang({
      id_describing_center: parseInt(this.route.snapshot.queryParamMap.get('idCenter')),
      offset: offset,
      limit: limit
    });

    this.adminService.getCOTransfers(params, res => {
      const result = this.odataService.parseResponse(res);
      if (result.Status == 'OK') {
        this.rows = JSON.parse(result.Value);
        resolve('OK');
      } else {
        // TODO
      }
    });
  }

  private getCOStaff(resolve: Function, reject: Function, limit: number, offset: number) {
    let params: any = this.odataService.paramsTokenAndLang({
      id_describing_center: parseInt(this.route.snapshot.queryParamMap.get('idCenter')),
      offset: offset,
      limit: limit
    });

    this.adminService.getCOStaff(params, res => {
      const result = this.odataService.parseResponse(res);
      if (result.Status == 'OK') {
        this.rows = JSON.parse(result.Value);
        this.rows.forEach(emp => {
          let roleString = '';
          emp.user_info.roles.forEach(role => roleString += role.role_description += ', ');
          emp.user_info.roleString = roleString.slice(0, -2);
        });
        resolve('OK');
      } else {
        // TODO
      }
    });
  }
}
