<div id="lock-screen">
    <div class="container-fluid h-100">
        <div class="row h-100">

            <main class="main-content col">
                <div class="main-content-container container-fluid px-4 my-auto h-100">

                    <svg-icon alt="Evolution" class="logoImg" src="../../../assets/images/logo.svg"></svg-icon>

                    <div>
                        <app-language-button></app-language-button>
                        <div class="dropdown dropdown-calendar timerDiv">
                            <button
                                aria-expanded="false"
                                aria-haspopup="true"
                                id="ddCalendar"
                                class="btn btn-secondary timerButton"
                                (click)="dp.toggle()"
                                type="button">
                                <svg-icon class="svg" src="../../assets/images/icons/infobar/icon__time.svg" alt=""></svg-icon>
                                {{timer.time}}
                            </button>
                            <span
                                id="datepickerCalendar"
                                type="text"
                                style="visibility: hidden; position: relative; top: 15px"
                                aria-labelledby="ddCalendar"
                                placeholder=""
                                #dp="bsDatepicker"
                                bsDatepicker
                                [isDisabled]=false
                                [daysDisabled]="[0,1,2,3,4,5,6]"
                                [bsValue]="date"
                                [bsConfig]="dp_config">
                            </span>
                        </div>
                    </div>

                    <div class="row no-gutters vh-100">
                        <div class="auth-form unlockDiv">

                            <div class="card" [ngClass]="{'mb25' : isError}">
                                <div *ngIf="isValidAvatar" class="avatarDiv">
                                    <img (error)="hideAvatar()" [src]="imageUrl">
                                </div>

                                <div class="card-body">
                                    <h2 style="text-align: center;">{{username}}</h2>
                                    <p class="text-center">{{'UnlockScreen.Text' | translate}}
                                        <span class="blue"><strong>{{remainingTime}}</strong></span>
                                    </p>

                                    <form>
                                        <div class="form-group">
                                            <app-alert #errorAlert></app-alert>
                                            <div class="input-group mt25">
                                                <input (keydown.enter)="unlock_session()" *ngIf="!isError"
                                                       [(ngModel)]="unlockSession.password"
                                                       [ngModelOptions]="{standalone: true}" autofocus
                                                       class="form-control inputPassword"
                                                       placeholder="{{'UnlockScreen.Placeholder' | translate}}"
                                                       type="password">
                                                <input (keydown.enter)="unlock_session()" *ngIf="isError"
                                                       [(ngModel)]="unlockSession.password"
                                                       [ngModelOptions]="{standalone: true}" autofocus
                                                       class="form-control is-error inputPassword"
                                                       placeholder="{{'UnlockScreen.Placeholder' | translate}}" type="password">
                                                <div class="input-group-append unlockButtonDiv">
                                                    <button (click)="unlock_session()" class="btn btn-lg btn-primary unlockButton"
                                                            type="button">
                                                        <svg-icon alt="" src="../../../assets/images/icons/icon__padlock.svg"
                                                             style="position: relative; top: -2px; left: -2px; margin-right: 8px;"></svg-icon>
                                                        {{'UnlockScreen.Unlock' | translate}}</button>
                                                </div>
                                            </div>


                                        </div>
                                    </form>

                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <p [innerHTML]="'UnlockScreen.EndWork' | translate" class="mb-2 mt-4" style="display: inline;"></p>
                                <a (click)="logOut(true)" [innerHTML]="'UnlockScreen.LogOut' | translate"
                                   class="btn btn-white ml-2" style="display: inline; color: var(--blue)"></a>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
