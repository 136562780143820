import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { VERSION } from '../../../environments/version';
import { EnvService } from '@/services/env.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    version = VERSION.date; // Data jako numer wersji tymczasowo zamiast hashu.
    currentYear: number = new Date().getFullYear();
    constructor(public envService: EnvService, private router: Router) {
        // override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                window.scrollTo(0, 0);
            }
        });
    }


}
