import { NavigationService } from './../../services/navigation.service';
import { UniversalToastComponent } from '@/utils/universal-toast/universal-toast.component';
import { ContextsService } from '@/services/contexts.service';
import { OnInit, AfterViewInit, EventEmitter, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import moment from 'moment';
import { OdataService, UsersService, SessionService } from '@/services';
import { TranslateService } from '@ngx-translate/core';
import { AddFilterModalComponent } from '@/utils/add-filter-modal/add-filter-modal.component';
import { FiltersService } from '@/services/filters.service';
var FiltersComponent = /** @class */ (function () {
    function FiltersComponent(renderer, odata, sessionService, userService, translate, filterService, contextService, navigationService) {
        this.renderer = renderer;
        this.odata = odata;
        this.sessionService = sessionService;
        this.userService = userService;
        this.translate = translate;
        this.filterService = filterService;
        this.contextService = contextService;
        this.navigationService = navigationService;
        this.filter = new EventEmitter();
        this.statusFilter = '';
        this.daterangeFilter = '';
        this.modalityFilter = '';
        this.anatomyFilter = '';
        this.assignedFilter = '';
        this.priorityFilter = '';
        this.facilityArchiveFilter = '';
        this.daterangeDescFilter = '';
        this.priceFilter = '';
        this.physicianFilter = '';
        this.principalFilter = '';
        this.sexFilter = '';
        this.isActiveDateSort = false;
        this.isActiveDescDateSort = false;
        this.isActiveExamDateSort = false;
        this.nameFilter = '';
        this.peselFilter = '';
        this.facilityFilter = '';
        this.examIdFilter = '';
        this.examTypeFilter = '';
        this.examAnatomyFilter = '';
        this.examNameFilter = '';
        this.examRisNumberFilter = '';
        this.examSourceFilter = '';
        this.examPatientNameFilter = '';
        this.examSexFilter = '';
        this.examPatientIdFilter = '';
        this.examAccessibilityFilter = '';
        this.daterangeExamFilter = '';
        this.statusOptions = [];
        this.modalityOptions = [];
        this.anatomyOptions = [];
        this.assignedOptions = [];
        this.priorityOptions = [];
        this.facilityOptions = [];
        this.priceOptions = [
            { value: { name: 'powyżej 100' }, selected: false },
            { value: { name: 'powyżej 500' }, selected: false },
            { value: { name: 'powyżej 1000' }, selected: false }
        ];
        this.physicianOptions = [
            { value: { name: 'Ja' }, selected: false },
            { value: { name: 'Jan Kowalski' }, selected: false },
            { value: { name: 'Jan Testowy' }, selected: false },
            { value: { name: 'Doctor Who' }, selected: false }
        ];
        this.principalOptions = [
            { value: { name: 'Misie' }, selected: false },
            { value: { name: 'Tygrysy' }, selected: false },
            { value: { name: 'Inny' }, selected: false },
            { value: { name: 'Jeszcze inny' }, selected: false }
        ];
        this.examAccessibilityOptions = [];
        this.sexOptions = ['Wszystkie', 'Mężczyzna', 'Kobieta'];
        this.statusOptionsForPatient = [];
        this.physiciansListForPatient = [];
        this.facilitiesListForPatient = [];
        this.filtrDashboard = null;
        this.patientsFilter = null;
        this.facilityExamsFilter = null;
        this.filtersActiveDashboard = false;
        this.filtersActivePatients = false;
        this.filtersActiveFacilityExams = false;
        this.daterange = {};
        this.daterangeDesc = {};
        this.enOptions = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Current Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: [
                    'Mo',
                    'Tu',
                    'We',
                    'Th',
                    'Fr',
                    'Sa',
                    'Su'
                ],
                monthNames: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                'firstDay': 0
            },
            alwaysShowCalendars: false,
        };
        this.plOptions = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment()],
                'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment()],
                'Ten Miesiąc': [moment().startOf('month'), moment().endOf('month')],
                'Ostatni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'YYYY-MM-DD',
                applyLabel: 'Zatwierdź',
                cancelLabel: 'Anuluj',
                fromLabel: 'Z',
                toLabel: 'Do',
                customRangeLabel: 'Własny przedział',
                daysOfWeek: [
                    'Po',
                    'Wt',
                    'Śr',
                    'Cz',
                    'P',
                    'So',
                    'N'
                ],
                'monthNames': [
                    'Styczeń',
                    'Luty',
                    'Marzec',
                    'Kwiecień',
                    'Maj',
                    'Czerwiec',
                    'Lipiec',
                    'Sierpień',
                    'Wrzesień',
                    'Październik',
                    'Listopad',
                    'Grudzień'
                ],
                'firstDay': 0
            },
            alwaysShowCalendars: false,
        };
        this.options = this.setLangForDatepicker(this.sessionService.language);
        this.currentDateValue = '';
        this.currentDescDateValue = '';
        this.statusSelectpicker = null;
        this.modalitySelectpicker = null;
        this.anatomySelectpicker = null;
        this.assignedSelectpicker = null;
        this.prioritySelectpicker = null;
        this.facilitySelectpicker = null;
        this.statusForPatientSelectpicker = null;
        this.physiciansListForPatientSelectpicker = null;
        this.facilitiesListForPatientSelectpicker = null;
        this.sexSelectpicker = null;
        this.physicianSelectpicker = null;
        this.principalSelectpicker = null;
        this.priceSelectpicker = null;
        this.examSexSelectpicker = null;
        this.examSourceSelectpicker = null;
        this.examAccessibilitySelectpicker = null;
        this.sort_default = 'date_range_ASC';
        this.sort = this.sort_default;
        this.column_type_default = 'date_range';
        this.sort_type_default = 'ASC';
        this.changeLang();
    }
    Object.defineProperty(FiltersComponent.prototype, "PickerSetter", {
        set: function (content) {
            this.picker = content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersComponent.prototype, "pickerPlaceholderSetter", {
        set: function (content) {
            this.picker_placeholder = content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersComponent.prototype, "PickerSetterDesc", {
        set: function (content) {
            this.pickerDesc = content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersComponent.prototype, "pickerPlaceholderSetterDesc", {
        set: function (content) {
            this.picker_placeholder_desc = content;
        },
        enumerable: true,
        configurable: true
    });
    FiltersComponent.prototype.changeLang = function () {
        var _this = this;
        this.sessionService.emitter.subscribe(function (res) {
            _this.options = _this.setLangForDatepicker(res);
        });
    };
    FiltersComponent.prototype.setLangForDatepicker = function (lang) {
        if (lang == 'en') {
            return this.enOptions;
        }
        else
            return this.plOptions;
    };
    FiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.navigationService.hasContext('DESCRIBING_CENTER') || this.navigationService.hasContext('MEDICAL_FACILITY')) {
            sessionStorage.setItem('activeFilter', 'false');
            var optionsPromises_1 = [];
            this.daterange.start = moment().subtract(29, 'days');
            this.daterange.end = moment();
            if (this.type == 'DASHBOARD') {
                optionsPromises_1.push(this.getStatusTypesForExamOrders());
            }
            else if (this.type == 'ARCHIVE') {
                this.sort = 'date_range_DESC';
                optionsPromises_1.push(this.getStatusTypesForArchive());
                optionsPromises_1.push(this.getMedicalFacilities());
                this.daterangeDesc.start = moment().subtract(29, 'days');
                this.daterangeDesc.end = moment();
            }
            if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE') {
                optionsPromises_1.push(this.get_modalities_groups());
                optionsPromises_1.push(this.get_anatomy_parts());
                optionsPromises_1.push(this.get_priority_types());
                this.userService.dcListPromise.then(function () {
                    if (_this.type == 'DASHBOARD')
                        optionsPromises_1.push(_this.getCoDescribingPhysiciansByCenter());
                    else if (_this.type == 'ARCHIVE')
                        optionsPromises_1.push(_this.getCoArchiveDescribingCenterPhysicianByCenter());
                    _this.optionsPromise = Promise.all(optionsPromises_1);
                    _this.optionsPromise.then(function () {
                        _this.setFilterDashboard();
                    });
                });
            }
            else if (this.type == 'HISTORY') {
                // TODO
                this.getCoDescribingPhysiciansByCenter();
                setTimeout(function () {
                    _this.physicianSelectpicker = $('#physician')['selectpicker']();
                    _this.priceSelectpicker = $('#price')['selectpicker']();
                    _this.principalSelectpicker = $('#principal')['selectpicker']();
                }, 100);
            }
            else if (this.type == 'PATIENTS') {
                optionsPromises_1.push(this.getStatusTypesForPatientView());
                optionsPromises_1.push(this.getPhysiciansListForPatientView());
                optionsPromises_1.push(this.getFacilitiesListForPatientView());
                Promise.all(optionsPromises_1).then(function () {
                    _this.sexSelectpicker = $('#sex')['selectpicker']();
                    _this.setFilterPatients();
                });
            }
            else if (this.type == 'FACILITY_EXAMS') {
                this.sort = 'date_range_DESC';
                this.daterangeDesc.start = moment().subtract(29, 'days');
                this.daterangeDesc.end = moment();
                optionsPromises_1.push(this.get_modalities_groups());
                optionsPromises_1.push(this.get_anatomy_parts());
                optionsPromises_1.push(this.get_accessibility_options_for_exams());
                Promise.all(optionsPromises_1).then(function () {
                    _this.examSexSelectpicker = $('#examSex')['selectpicker']();
                    _this.examSourceSelectpicker = $('#examSource')['selectpicker']();
                    _this.setFilterFacilityExams();
                });
            }
        }
    };
    FiltersComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE' || this.type == 'PATIENTS') {
                this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', function () {
                    _this.activateDatepicker();
                });
                if (this.type == 'ARCHIVE') {
                    this.globalDescPickerListener = this.renderer.listen(this.picker_placeholder_desc.nativeElement, 'click', function () {
                        _this.activateDescDatepicker();
                    });
                }
            }
        }
        else if (this.navigationService.getCurrentContext() == 'MEDICAL_FACILITY') {
            if (this.type == 'FACILITY_EXAMS') {
                this.globalPickerListener = this.renderer.listen(this.picker_placeholder.nativeElement, 'click', function () {
                    _this.activateDatepicker();
                });
                this.globalDescPickerListener = this.renderer.listen(this.picker_placeholder_desc.nativeElement, 'click', function () {
                    _this.activateDescDatepicker();
                });
            }
        }
    };
    FiltersComponent.prototype.ngOnDestroy = function () {
        if (this.navigationService.getCurrentContext() == 'DESCRIBING_CENTER') {
            if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE' || this.type == 'PATIENTS') {
                this.globalPickerListener();
            }
        }
        else if (this.navigationService.getCurrentContext() == 'MEDICAL_FACILITY') {
            if (this.type == 'FACILITY_EXAMS') {
                this.globalPickerListener();
            }
        }
    };
    FiltersComponent.prototype.activateDatepicker = function () {
        this.picker.nativeElement.click();
    };
    FiltersComponent.prototype.activateDescDatepicker = function () {
        this.pickerDesc.nativeElement.click();
    };
    FiltersComponent.prototype.getCoDescribingPhysiciansByCenter = function () {
        var _this = this;
        return this.odata.postPrivate('get_co_describing_physicians_by_center', this.odata.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                _this.setAssignedOptions(value);
                _this.assignedSelectpicker = $('#assignedTo')['selectpicker']();
            }
        });
    };
    FiltersComponent.prototype.getCoArchiveDescribingCenterPhysicianByCenter = function () {
        var _this = this;
        return this.odata.postPrivate('get_co_archive_describing_center_physician_by_center', this.odata.paramsTokenAndLang({ id_describing_center: this.userService.getCenterId() }), function (res) {
            var returnedJSON = JSON.stringify(res);
            var result = JSON.parse(returnedJSON);
            if (result.Status == 'OK') {
                var value = JSON.parse(result.Value);
                _this.setAssignedOptions(value);
                _this.assignedSelectpicker = $('#assignedTo')['selectpicker']();
            }
        });
    };
    FiltersComponent.prototype.setAssignedOptions = function (physicians) {
        var _this = this;
        physicians.forEach(function (v) {
            _this.assignedOptions.push({
                value: {
                    name: v.physician_name,
                    id_physician: v.id_physician,
                    profile_image_uuid: v.profile_image_uuid
                }, selected: false
            });
        });
    };
    FiltersComponent.prototype.dropdown = function () {
        if (sessionStorage.getItem('activeFilter') == 'true') {
            sessionStorage.setItem('activeFilter', 'false');
            document.getElementById('filter_wrapper').style.overflow = 'hidden';
            $('.filter_dropdown a').removeClass('active');
            $('.filter_component_bg').removeClass('active');
            $('.filter_component_wrapper').removeClass('active');
        }
        else {
            sessionStorage.setItem('activeFilter', 'true');
            setTimeout(function () {
                var elem = document.getElementById('filter_wrapper');
                if (elem) {
                    elem.style.overflow = 'visible';
                }
            }, 500);
            $('.filter_dropdown a').addClass('active');
            $('.filter_component_bg').addClass('active');
            $('.filter_component_wrapper').addClass('active');
        }
    };
    FiltersComponent.prototype.selectedDate = function (value, datepicker) {
        if (datepicker == this.daterange) {
            // this is the date the user selected
            if (!this.isActiveDateSort) {
                this.isActiveDateSort = true;
            }
            // or manupulat your own internal property
            this.daterange.start = value.start;
            this.daterange.end = value.end;
            this.daterange.label = value.label;
        }
        else if (datepicker == this.daterangeDesc) {
            // this is the date the user selected
            if (!this.isActiveDescDateSort) {
                this.isActiveDescDateSort = true;
            }
            // or manupulat your own internal property
            this.daterangeDesc.start = value.start;
            this.daterangeDesc.end = value.end;
            this.daterangeDesc.label = value.label;
        }
        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;
        this.updatePickerPlaceholder(value.start._d, value.end._d, datepicker);
    };
    FiltersComponent.prototype.updatePickerPlaceholder = function (start_date, end_date, datepicker) {
        var start_date_str = moment(start_date).format('YYYY-MM-DD').toString();
        var end_date_str = moment(end_date).format('YYYY-MM-DD').toString();
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, datepicker);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, datepicker);
        }
    };
    FiltersComponent.prototype.updatePickerPlaceholderContent = function (content, datepicker) {
        if (this.type == 'DASHBOARD' || this.type == 'ARCHIVE' || this.type == 'PATIENTS' || this.type == 'FACILITY_EXAMS') {
            if (datepicker == this.daterange)
                this.currentDateValue = content;
            else if (datepicker == this.daterangeDesc)
                this.currentDescDateValue = content;
        }
    };
    FiltersComponent.prototype.getStatusTypesForPatientView = function () {
        var _this = this;
        return this.filterService.getStatusTypesForPatients().then(function (value) {
            value.forEach(function (v) {
                _this.statusOptionsForPatient.push({ value: v, selected: false });
            });
            _this.statusForPatientSelectpicker = $('#statusForPatient')['selectpicker']();
        });
    };
    FiltersComponent.prototype.getPhysiciansListForPatientView = function () {
        var _this = this;
        return this.filterService.getDoctorsForPatients().then(function (value) {
            value.forEach(function (v) {
                _this.physiciansListForPatient.push({ value: v, selected: false });
            });
            _this.physiciansListForPatientSelectpicker = $('#physiciansListForPatient')['selectpicker']();
        });
    };
    FiltersComponent.prototype.getFacilitiesListForPatientView = function () {
        var _this = this;
        return this.filterService.getFacilityTypes().then(function (value) {
            value.forEach(function (v) {
                _this.facilitiesListForPatient.push({ value: v, selected: false });
            });
            _this.facilitiesListForPatientSelectpicker = $('#facilitiesListForPatient')['selectpicker']();
        });
    };
    FiltersComponent.prototype.getStatusTypesForExamOrders = function () {
        var _this = this;
        return this.filterService.getStatusTypesForExamOrders().then(function (value) {
            value.forEach(function (v) {
                _this.statusOptions.push({ value: v, selected: false });
            });
            _this.statusSelectpicker = $('#status')['selectpicker']();
        });
    };
    FiltersComponent.prototype.getStatusTypesForArchive = function () {
        var _this = this;
        return this.filterService.getStatusTypesForArchive().then(function (value) {
            value.forEach(function (v) {
                _this.statusOptions.push({ value: v, selected: false });
            });
            _this.statusSelectpicker = $('#status')['selectpicker']();
        });
    };
    FiltersComponent.prototype.get_modalities_groups = function () {
        var _this = this;
        return this.filterService.getModalitiesGroups().then(function (value) {
            value.forEach(function (v) {
                _this.modalityOptions.push({ value: v, selected: false });
            });
            _this.modalitySelectpicker = $('#modality')['selectpicker']();
        });
    };
    FiltersComponent.prototype.get_anatomy_parts = function () {
        var _this = this;
        return this.filterService.getAnatomyParts().then(function (value) {
            value.forEach(function (v) {
                _this.anatomyOptions.push({ value: v, selected: false });
            });
            _this.anatomySelectpicker = $('#anatomy')['selectpicker']();
        });
    };
    FiltersComponent.prototype.get_accessibility_options_for_exams = function () {
        var _this = this;
        return this.filterService.getAccessibilityOptionsForExams().then(function (value) {
            value.forEach(function (v) {
                _this.examAccessibilityOptions.push({ value: v, selected: false });
            });
            _this.examAccessibilitySelectpicker = $('#accessibility')['selectpicker']();
        });
    };
    FiltersComponent.prototype.get_priority_types = function () {
        var _this = this;
        return this.filterService.getPriorityTypes().then(function (value) {
            value.forEach(function (v) {
                _this.priorityOptions.push({
                    value: {
                        priority_text: v.priority_text,
                        id_priority: v.id_priority,
                        priority_value: v.priority_value
                    }, selected: false
                });
            });
            _this.prioritySelectpicker = $('#priority')['selectpicker']();
        });
    };
    FiltersComponent.prototype.getMedicalFacilities = function () {
        var _this = this;
        return this.filterService.getMedicalFacilities().then(function (value) {
            value.forEach(function (v) {
                _this.facilityOptions.push({ value: v, selected: false });
            });
            _this.facilitySelectpicker = $('#facility')['selectpicker']();
        });
    };
    FiltersComponent.prototype.generateFilter = function (type, options) {
        var filter = '';
        var selected = 0;
        options.forEach(function (option) {
            if (option.selected) {
                ++selected;
            }
        });
        if (selected == 0)
            filter = '';
        else {
            filter = '';
            options.forEach(function (option) {
                if (option.selected) {
                    --selected;
                    if (type == 'assigned' || type == 'physician' || type == 'principal') {
                        if (selected != 0)
                            filter += option.value.name + ', ';
                        else
                            filter += option.value.name;
                    }
                    else if (type == 'modality' || type == 'anatomy') {
                        if (selected != 0)
                            filter += option.value.value + ', ';
                        else
                            filter += option.value.value;
                    }
                    else if (type == 'status') {
                        if (selected != 0)
                            filter += option.value.status + ', ';
                        else
                            filter += option.value.status;
                    }
                    else if (type == 'priority') {
                        if (selected != 0)
                            filter += option.value.priority_text + ', ';
                        else
                            filter += option.value.priority_text;
                    }
                    else if (type == 'facility') {
                        if (selected != 0)
                            filter += option.value.medical_facility.name + ', ';
                        else
                            filter += option.value.medical_facility.name;
                    }
                }
            });
        }
        if (type == 'status')
            this.statusFilter = filter;
        else if (type == 'modality')
            this.modalityFilter = filter;
        else if (type == 'anatomy')
            this.anatomyFilter = filter;
        else if (type == 'priority')
            this.priorityFilter = filter;
        else if (type == 'assigned')
            this.assignedFilter = filter;
        else if (type == 'physician')
            this.physicianFilter = filter;
        else if (type == 'facility')
            this.facilityArchiveFilter = filter;
        else if (type == 'principal')
            this.principalFilter = filter;
    };
    FiltersComponent.createInitialIFiltrDashboard = function () {
        var result = {
            status: [],
            sort: {
                sort_column: 'date_range',
                sort_type: 'ASC',
            },
            date_range: {
                date_from: '',
                date_to: ''
            },
            modality: [],
            anatomy: [],
            assign_to: [],
            priority: []
        };
        return result;
    };
    FiltersComponent.createInitialIFiltrArchive = function () {
        var result = {
            status: [],
            sort: {
                sort_column: 'date_range',
                sort_type: 'DESC',
            },
            date_range: {
                date_from: '',
                date_to: ''
            },
            description_date_range: {
                date_from: '',
                date_to: ''
            },
            modality: [],
            anatomy: [],
            assign_to: [],
            priority: [],
            facility: []
        };
        return result;
    };
    FiltersComponent.createInitialFiltrPatients = function () {
        var result = {
            name: '',
            sex: 'A',
            pesel: '',
            birth_date: {
                birth_date_from: '',
                birth_date_to: ''
            },
            sort: {
                sort_column: 'surname',
                sort_type: 'ASC'
            },
            status: [],
            physician: [],
            facility: [],
            exam_number: ''
        };
        return result;
    };
    FiltersComponent.createInitialFiltrFacilityExams = function () {
        var result = {
            exam_date_range: {
                date_from: '',
                date_to: '',
            },
            sort: {
                sort_column: 'date_range',
                sort_type: 'DESC',
            },
            modality: [],
            anatomy: [],
            with_orders: 0,
            exam_name: '',
            ris_number: '',
            source: '',
            patient_name: '',
            sex: 'A',
            patient_id: '',
            birth_date: {
                birth_date_from: '',
                birth_date_to: '',
            },
            accessibility: []
        };
        return result;
    };
    FiltersComponent.prototype.getSortColumn = function () {
        var result = '';
        if (this.sort.endsWith('_ASC')) {
            result = this.sort.substring(0, this.sort.length - 4);
        }
        else if (this.sort.endsWith('_DESC')) {
            result = this.sort.substring(0, this.sort.length - 5);
        }
        else {
            result = this.column_type_default;
        }
        return result;
    };
    FiltersComponent.prototype.getSortType = function () {
        var result = '';
        if (this.sort.endsWith('_ASC')) {
            result = 'ASC';
        }
        else if (this.sort.endsWith('_DESC')) {
            result = 'DESC';
        }
        else {
            result = this.sort_type_default;
        }
        return result;
    };
    FiltersComponent.prototype.getSort = function () {
        this.filtrDashboard.sort = {
            sort_column: this.getSortColumn(),
            sort_type: this.getSortType(),
        };
    };
    FiltersComponent.prototype.getDateRange = function () {
        return {
            date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
            date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
        };
    };
    FiltersComponent.prototype.getDescDateRange = function () {
        return {
            date_from: moment(this.daterangeDesc.start).format('YYYY-MM-DD').toString(),
            date_to: moment(this.daterangeDesc.end).format('YYYY-MM-DD').toString()
        };
    };
    FiltersComponent.prototype.getSelectedOptions = function (filterName) {
        var result = [];
        var options = document.getElementById(filterName).selectedOptions;
        for (var i = 0; i < options.length; ++i) {
            result.push(parseInt(options[i].value));
        }
        return result;
    };
    FiltersComponent.prototype.getDate = function () {
        var tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            }
            else if (this.currentDateValue == '') {
                this.clearDaterangeFilter();
            }
            else {
                if (this.type == 'FACILITY_EXAMS') {
                    this.facilityExamsFilter.birth_date = {
                        birth_date_from: moment(this.daterange.start).format('YYYY-MM-DD').toString(),
                        birth_date_to: moment(this.daterange.end).format('YYYY-MM-DD').toString()
                    };
                }
                else
                    this.filtrDashboard.date_range = this.getDateRange();
            }
        }
        else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.birth_date.birth_date_from = this.currentDateValue.substring(0, 10);
                            this.facilityExamsFilter.birth_date.birth_date_to = this.currentDateValue.substring(13, 23);
                        }
                        else {
                            this.filtrDashboard.date_range.date_from = this.currentDateValue.substring(0, 10);
                            this.filtrDashboard.date_range.date_to = this.currentDateValue.substring(13, 23);
                        }
                    }
                    else {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.birth_date.birth_date_from = this.currentDateValue;
                            this.facilityExamsFilter.birth_date.birth_date_to = this.currentDateValue;
                        }
                        else {
                            this.filtrDashboard.date_range.date_from = this.currentDateValue;
                            this.filtrDashboard.date_range.date_to = this.currentDateValue;
                        }
                    }
                    this.daterange.start = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_from : this.filtrDashboard.date_range.date_from;
                    this.daterange.end = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_to : this.filtrDashboard.date_range.date_to;
                }
                else {
                    document.getElementById('placeholder-input').setAttribute('class', 'error');
                    tmp = false;
                }
            }
            else {
                this.clearDaterangeFilter();
            }
        }
        return tmp;
    };
    FiltersComponent.prototype.getDescDate = function () {
        var tmp = true;
        if (this.isActiveDescDateSort) {
            if (!this.isValidDateRange(this.currentDescDateValue) && this.currentDescDateValue != '') {
                document.getElementById('placeholder-input_desc').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDescDateSort = false;
            }
            else if (this.currentDescDateValue == '') {
                this.clearDescDaterangeFilter();
            }
            else {
                if (this.type == 'FACILITY_EXAMS') {
                    this.facilityExamsFilter.exam_date_range = this.getDescDateRange();
                }
                else
                    this.filtrDashboard.description_date_range = this.getDescDateRange();
            }
        }
        else if (!this.isActiveDescDateSort) {
            if (this.currentDescDateValue != '') {
                if (this.isValidDateRange(this.currentDescDateValue)) {
                    if (this.currentDescDateValue.length == 23) {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.exam_date_range.date_from = this.currentDescDateValue.substring(0, 10);
                            this.facilityExamsFilter.exam_date_range.date_to = this.currentDescDateValue.substring(13, 23);
                        }
                        else {
                            this.filtrDashboard.description_date_range.date_from = this.currentDescDateValue.substring(0, 10);
                            this.filtrDashboard.description_date_range.date_to = this.currentDescDateValue.substring(13, 23);
                        }
                    }
                    else {
                        if (this.type == 'FACILITY_EXAMS') {
                            this.facilityExamsFilter.exam_date_range.date_from = this.currentDescDateValue;
                            this.facilityExamsFilter.exam_date_range.date_to = this.currentDescDateValue;
                        }
                        else {
                            this.filtrDashboard.description_date_range.date_from = this.currentDescDateValue;
                            this.filtrDashboard.description_date_range.date_to = this.currentDescDateValue;
                        }
                    }
                    this.daterangeDesc.start = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.exam_date_range.date_from : this.filtrDashboard.description_date_range.date_from;
                    this.daterangeDesc.end = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.exam_date_range.date_to : this.filtrDashboard.description_date_range.date_to;
                }
                else {
                    document.getElementById('placeholder-input_desc').setAttribute('class', 'error');
                    tmp = false;
                }
            }
            else {
                this.clearDescDaterangeFilter();
            }
        }
        return tmp;
    };
    FiltersComponent.prototype.clearDaterangeFilter = function () {
        this.isActiveDateSort = false;
        this.daterangeFilter = '';
        this.updatePickerPlaceholderContent('', this.daterange);
        if (this.type == 'FACILITY_EXAMS')
            this.clearExamFilter(null, 'date_range');
        else
            this.clearFilter(null, 'date_range');
    };
    FiltersComponent.prototype.clearDescDaterangeFilter = function () {
        this.isActiveDescDateSort = false;
        this.daterangeDescFilter = '';
        this.updatePickerPlaceholderContent('', this.daterangeDesc);
        if (this.type == 'FACILITY_EXAMS')
            this.clearExamFilter(null, 'exam_date_range');
        else
            this.clearFilter(null, 'description_date_range');
    };
    FiltersComponent.prototype.filtrOrders = function () {
        document.getElementById('placeholder-input').removeAttribute('class');
        document.getElementById('placeholder-input').setAttribute('class', 'datepicker-placeholder');
        this.filtrDashboard.status = this.getSelectedOptions('status');
        this.filtrDashboard.anatomy = this.getSelectedOptions('anatomy');
        this.filtrDashboard.assign_to = this.getSelectedOptions('assignedTo');
        this.filtrDashboard.modality = this.getSelectedOptions('modality');
        this.filtrDashboard.priority = this.getSelectedOptions('priority');
        if (this.type == 'ARCHIVE') {
            document.getElementById('placeholder-input_desc').removeAttribute('class');
            document.getElementById('placeholder-input_desc').setAttribute('class', 'datepicker-placeholder');
            this.filtrDashboard.facility = this.getSelectedOptions('facility');
        }
        this.getSort();
        var temp1 = this.getDate();
        var temp2 = true;
        if (this.filtrDashboard.description_date_range) {
            temp2 = this.getDescDate();
        }
        if (temp1 && temp2) {
            this.setFilterDashboard();
            this.filter.emit(this.filtrDashboard);
            this.dropdown();
        }
    };
    FiltersComponent.prototype.isValidDateRange = function (dateRange) {
        if (dateRange.length != 23 && dateRange.length != 10) {
            return false;
        }
        else {
            if (dateRange.length == 23) {
                var from = dateRange.substring(0, 10);
                var to = dateRange.substring(13, 23);
                if (!moment(from, 'YYYY-MM-DD').isValid() || !moment(to, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
            else {
                if (!moment(dateRange, 'YYYY-MM-DD').isValid()) {
                    return false;
                }
            }
        }
        return true;
    };
    FiltersComponent.prototype.getDatePatients = function () {
        var tmp = true;
        if (this.isActiveDateSort) {
            if (!this.isValidDateRange(this.currentDateValue) && this.currentDateValue != '') {
                document.getElementById('placeholder-input').setAttribute('class', 'error');
                tmp = false;
                this.isActiveDateSort = false;
            }
            else if (this.currentDateValue == '') {
                this.daterangeFilter = '';
                this.updatePickerPlaceholderContent('', this.daterange);
                this.patientsFilter.birth_date.birth_date_from = '';
                this.patientsFilter.birth_date.birth_date_to = '';
            }
            else {
                var temp = this.getDateRange();
                this.patientsFilter.birth_date.birth_date_to = temp.date_to;
                this.patientsFilter.birth_date.birth_date_from = temp.date_from;
            }
        }
        else if (!this.isActiveDateSort) {
            if (this.currentDateValue != '') {
                if (this.isValidDateRange(this.currentDateValue)) {
                    if (this.currentDateValue.length == 23) {
                        this.patientsFilter.birth_date.birth_date_from = this.currentDateValue.substring(0, 10);
                        this.patientsFilter.birth_date.birth_date_to = this.currentDateValue.substring(13, 23);
                    }
                    else {
                        this.patientsFilter.birth_date.birth_date_from = this.currentDateValue;
                        this.patientsFilter.birth_date.birth_date_to = this.currentDateValue;
                    }
                    this.daterange.start = this.patientsFilter.birth_date.birth_date_from;
                    this.daterange.end = this.patientsFilter.birth_date.birth_date_to;
                }
                else {
                    document.getElementById('placeholder-input').setAttribute('class', 'error');
                    tmp = false;
                }
            }
            else {
                this.daterangeFilter = '';
                this.updatePickerPlaceholderContent('', this.daterange);
                this.patientsFilter.birth_date.birth_date_from = '';
                this.patientsFilter.birth_date.birth_date_to = '';
            }
        }
        return tmp;
    };
    FiltersComponent.prototype.getNamePatient = function () {
        return document.getElementById('name').value;
    };
    FiltersComponent.prototype.getSexPatient = function () {
        return this.sexSelectpicker.selectpicker('val');
    };
    FiltersComponent.prototype.getPeselPatient = function () {
        return document.getElementById('pesel').value;
    };
    FiltersComponent.prototype.getStatusPatient = function () {
        var statusList = [];
        this.statusForPatientSelectpicker.selectpicker('val').forEach(function (el) { return statusList.push(parseInt(el)); });
        return statusList;
    };
    FiltersComponent.prototype.getPhysicianPatient = function () {
        var physicianList = [];
        this.physiciansListForPatientSelectpicker.selectpicker('val').forEach(function (el) { return physicianList.push(parseInt(el)); });
        return physicianList;
    };
    FiltersComponent.prototype.getFacilityPatient = function () {
        var facilityList = [];
        this.facilitiesListForPatientSelectpicker.selectpicker('val').forEach(function (el) { return facilityList.push(parseInt(el)); });
        return facilityList;
    };
    FiltersComponent.prototype.getExamIdPatient = function () {
        return document.getElementById('examId').value;
    };
    FiltersComponent.prototype.filtrPatients = function () {
        var temp = this.getDatePatients();
        if (temp) {
            document.getElementById('placeholder-input').removeAttribute('class');
            document.getElementById('placeholder-input').setAttribute('class', 'datepicker-placeholder');
            this.patientsFilter.name = this.getNamePatient();
            this.patientsFilter.sex = this.getSexPatient();
            this.patientsFilter.pesel = this.getPeselPatient();
            this.patientsFilter.status = this.getStatusPatient();
            this.patientsFilter.physician = this.getPhysicianPatient();
            this.patientsFilter.facility = this.getFacilityPatient();
            this.patientsFilter.exam_number = this.getExamIdPatient();
            this.setFilterPatients();
            this.filter.emit(this.patientsFilter);
            document.getElementById('filtrButton').blur();
            this.dropdown();
        }
    };
    FiltersComponent.prototype.filterFacilityExams = function () {
        document.getElementById('placeholder-input').removeAttribute('class');
        document.getElementById('placeholder-input').setAttribute('class', 'datepicker-placeholder');
        document.getElementById('placeholder-input_desc').removeAttribute('class');
        document.getElementById('placeholder-input_desc').setAttribute('class', 'datepicker-placeholder');
        this.facilityExamsFilter.modality = this.getSelectedOptions('modality');
        this.facilityExamsFilter.anatomy = this.getSelectedOptions('anatomy');
        this.facilityExamsFilter.exam_name = document.getElementById('examName').value;
        this.facilityExamsFilter.ris_number = document.getElementById('risNumber').value;
        this.facilityExamsFilter.source = this.examSourceSelectpicker.selectpicker('val');
        this.facilityExamsFilter.patient_name = document.getElementById('patientName').value;
        this.facilityExamsFilter.sex = this.examSexSelectpicker.selectpicker('val');
        this.facilityExamsFilter.patient_id = document.getElementById('patientId').value;
        this.facilityExamsFilter.accessibility = this.getSelectedOptions('accessibility');
        this.facilityExamsFilter.sort = {
            sort_column: this.getSortColumn(),
            sort_type: this.getSortType(),
        };
        var temp1 = this.getDescDate();
        var temp2 = true;
        if (this.facilityExamsFilter.birth_date) {
            temp2 = this.getDate();
        }
        if (temp1 && temp2) {
            this.setFilterFacilityExams();
            this.filter.emit(this.facilityExamsFilter);
            this.dropdown();
        }
    };
    FiltersComponent.prototype.setActiveFiltersDashboard = function (setDaterange, setDaterangeDesc) {
        if (setDaterange)
            this.daterangeFilter = moment(this.daterange.start).format('YYYY-MM-DD').toString()
                + ' - '
                + moment(this.daterange.end).format('YYYY-MM-DD').toString();
        if (setDaterangeDesc)
            this.daterangeDescFilter = moment(this.daterangeDesc.start).format('YYYY-MM-DD').toString()
                + ' - '
                + moment(this.daterangeDesc.end).format('YYYY-MM-DD').toString();
        this.filtersActiveDashboard = (this.statusFilter != '' || this.daterangeFilter != '' || this.daterangeDescFilter != '' ||
            this.modalityFilter != '' || this.anatomyFilter != '' ||
            this.assignedFilter != '' || this.priorityFilter != '' || this.facilityArchiveFilter != '');
    };
    FiltersComponent.prototype.setActiveFiltersFacilityExams = function (setDaterange, setDaterangeDesc) {
        if (setDaterange)
            this.daterangeFilter = moment(this.daterange.start).format('YYYY-MM-DD').toString()
                + ' - '
                + moment(this.daterange.end).format('YYYY-MM-DD').toString();
        if (setDaterangeDesc)
            this.daterangeDescFilter = moment(this.daterangeDesc.start).format('YYYY-MM-DD').toString()
                + ' - '
                + moment(this.daterangeDesc.end).format('YYYY-MM-DD').toString();
        this.filtersActiveFacilityExams = (this.daterangeDescFilter != '' || this.examTypeFilter != '' ||
            this.examAnatomyFilter != '' || this.examNameFilter != '' || this.examRisNumberFilter != '' ||
            this.examSourceFilter != '' || this.examPatientNameFilter != '' || this.examSexFilter != '' ||
            this.examPatientIdFilter != '' || this.daterangeFilter != '' || this.examAccessibilityFilter != '');
    };
    FiltersComponent.prototype.saveFilter = function (type) {
        var _this = this;
        var val = '';
        var lovercaseType = type.toLowerCase();
        if (lovercaseType == 'dashboard') {
            val = this.filterService.typeOptions[0];
        }
        else if (lovercaseType == 'archive') {
            val = this.filterService.typeOptions[1];
        }
        else if (lovercaseType == 'patients') {
            val = this.filterService.typeOptions[2];
        }
        this.addFilterModal.show(val);
        var filtr = (lovercaseType == 'dashboard' || lovercaseType == 'archive') ? this.filtrDashboard : this.patientsFilter;
        this.saveFilterSubscription = this.addFilterModal.addFilterEmitter.subscribe(function (res) {
            if (res) {
                _this.filterService.saveFilter(filtr, lovercaseType, _this.addFilterModal.filterName, _this.addFilterModal.isFavourite).then(function (res) {
                    if (res) {
                        _this.filterSavedToast.success('FilterSaved');
                        _this.filterService.getFilters(lovercaseType);
                        _this.addFilterModal.hide();
                    }
                    else {
                        _this.filterSavedToast.error('FilterNotSaved');
                    }
                    _this.filterSavedToast.show();
                    setTimeout(function () { return _this.filterSavedToast.hide(); }, 1500);
                });
            }
        });
    };
    FiltersComponent.prototype.deleteAllFiltersDashboard = function (emit) {
        this.filtersActiveDashboard = false;
        this.statusFilter = '';
        if (this.statusSelectpicker != null) {
            this.statusSelectpicker.selectpicker('deselectAll');
        }
        this.clearFilter(this.statusOptions, 'status');
        this.daterangeFilter = '';
        this.isActiveDateSort = false;
        this.updatePickerPlaceholderContent('', this.daterange);
        this.clearFilter(null, 'date_range');
        this.modalityFilter = '';
        this.modalitySelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.modalityOptions, 'modality');
        this.anatomyFilter = '';
        this.anatomySelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.anatomyOptions, 'anatomy');
        this.assignedFilter = '';
        this.assignedSelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.assignedOptions, 'assign_to');
        this.priorityFilter = '';
        this.prioritySelectpicker.selectpicker('deselectAll');
        this.clearFilter(this.priorityOptions, 'priority');
        if (this.type == 'ARCHIVE') {
            this.daterangeDescFilter = '';
            this.isActiveDescDateSort = false;
            this.updatePickerPlaceholderContent('', this.daterangeDesc);
            this.clearFilter(null, 'description_date_range');
            this.facilityArchiveFilter = '';
            this.facilitySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.facilityOptions, 'facility');
        }
        if (this.type == 'DASHBOARD') {
            this.filtrDashboard = FiltersComponent.createInitialIFiltrDashboard();
        }
        else if (this.type == 'ARCHIVE') {
            this.filtrDashboard = FiltersComponent.createInitialIFiltrArchive();
        }
        this.sort = this.sort_default;
        if (emit) {
            this.filterService.clearSearch(false, this.type);
            this.filter.emit(this.filtrDashboard);
        }
    };
    FiltersComponent.prototype.deleteFilterDashboard = function (filter) {
        if (filter == 'statusFilter') {
            this.statusFilter = '';
            if (this.statusSelectpicker != null) {
                this.statusSelectpicker.selectpicker('deselectAll');
            }
            this.clearFilter(this.statusOptions, 'status');
        }
        else if (filter == 'daterangeFilter') {
            this.isActiveDateSort = false;
            this.daterangeFilter = '';
            this.updatePickerPlaceholderContent('', this.daterange);
            this.clearFilter(null, 'date_range');
        }
        else if (filter == 'daterangeDescFilter') {
            this.isActiveDescDateSort = false;
            this.daterangeDescFilter = '';
            this.updatePickerPlaceholderContent('', this.daterangeDesc);
            this.clearFilter(null, 'description_date_range');
        }
        else if (filter == 'modalityFilter') {
            this.modalityFilter = '';
            this.modalitySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.modalityOptions, 'modality');
        }
        else if (filter == 'anatomyFilter') {
            this.anatomyFilter = '';
            this.anatomySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.anatomyOptions, 'anatomy');
        }
        else if (filter == 'assignedFilter') {
            this.assignedFilter = '';
            this.assignedSelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.assignedOptions, 'assign_to');
        }
        else if (filter == 'priorityFilter') {
            this.priorityFilter = '';
            this.prioritySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.priorityOptions, 'priority');
        }
        else if (filter == 'facilityFilter') {
            this.facilityArchiveFilter = '';
            this.facilitySelectpicker.selectpicker('deselectAll');
            this.clearFilter(this.facilityOptions, 'facility');
        }
        else if (filter === 'searchFilter') {
            this.filterService.clearSearch(true, this.type);
        }
        this.filtersActiveDashboard = (this.statusFilter != '' || this.daterangeFilter != '' ||
            this.daterangeDescFilter != '' || this.modalityFilter != '' || this.anatomyFilter != '' ||
            this.assignedFilter != '' || this.priorityFilter != '' || this.facilityArchiveFilter != '');
        this.filter.emit(this.filtrDashboard);
    };
    FiltersComponent.prototype.deleteAllFiltersFacilityExams = function (emit) {
        this.filtersActiveFacilityExams = false;
        this.isActiveDescDateSort = false;
        this.daterangeDescFilter = '';
        this.updatePickerPlaceholderContent('', this.daterangeDesc);
        this.clearExamFilter(null, 'exam_date_range');
        this.examTypeFilter = '';
        this.modalitySelectpicker.selectpicker('deselectAll');
        this.clearExamFilter(this.modalityOptions, 'modality');
        this.examAnatomyFilter = '';
        this.anatomySelectpicker.selectpicker('deselectAll');
        this.clearExamFilter(this.anatomyOptions, 'anatomy');
        this.examNameFilter = '';
        document.getElementById('examName').value = '';
        this.facilityExamsFilter.exam_name = '';
        this.examRisNumberFilter = '';
        document.getElementById('risNumber').value = '';
        this.facilityExamsFilter.ris_number = '';
        this.examSourceFilter = '';
        this.examSourceSelectpicker.selectpicker('val', '');
        this.facilityExamsFilter.source = '';
        this.examPatientNameFilter = '';
        document.getElementById('patientName').value = '';
        this.facilityExamsFilter.patient_name = '';
        this.examSexFilter = '';
        this.examSexSelectpicker.selectpicker('val', 'A');
        this.facilityExamsFilter.sex = 'A';
        this.examPatientIdFilter = '';
        document.getElementById('patientId').value = '';
        this.facilityExamsFilter.patient_id = '';
        this.isActiveDateSort = false;
        this.daterangeFilter = '';
        this.updatePickerPlaceholderContent('', this.daterange);
        this.clearExamFilter(null, 'date_range');
        this.examAccessibilityFilter = '';
        this.examAccessibilitySelectpicker.selectpicker('deselectAll');
        this.clearExamFilter(this.examAccessibilityOptions, 'accessibility');
        this.facilityExamsFilter = FiltersComponent.createInitialFiltrFacilityExams();
        this.sort = this.sort_default;
        if (emit)
            this.filter.emit(this.facilityExamsFilter);
    };
    FiltersComponent.prototype.deleteFilterFacilityExams = function (filter) {
        if (filter == 'daterangeExamFilter') {
            this.isActiveDescDateSort = false;
            this.daterangeDescFilter = '';
            this.updatePickerPlaceholderContent('', this.daterangeDesc);
            this.clearExamFilter(null, 'exam_date_range');
        }
        else if (filter == 'examTypeFilter') {
            this.examTypeFilter = '';
            this.modalitySelectpicker.selectpicker('deselectAll');
            this.clearExamFilter(this.modalityOptions, 'modality');
        }
        else if (filter == 'examAnatomyFilter') {
            this.examAnatomyFilter = '';
            this.anatomySelectpicker.selectpicker('deselectAll');
            this.clearExamFilter(this.anatomyOptions, 'anatomy');
        }
        else if (filter == 'examNameFilter') {
            this.examNameFilter = '';
            document.getElementById('examName').value = '';
            this.facilityExamsFilter.exam_name = '';
        }
        else if (filter == 'examRisNumberFilter') {
            this.examRisNumberFilter = '';
            document.getElementById('risNumber').value = '';
            this.facilityExamsFilter.ris_number = '';
        }
        else if (filter == 'examSourceFilter') {
            this.examSourceFilter = '';
            this.examSourceSelectpicker.selectpicker('val', '');
            this.facilityExamsFilter.source = '';
        }
        else if (filter == 'examPatientNameFilter') {
            this.examPatientNameFilter = '';
            document.getElementById('patientName').value = '';
            this.facilityExamsFilter.patient_name = '';
        }
        else if (filter == 'examSexFilter') {
            this.examSexFilter = '';
            this.examSexSelectpicker.selectpicker('val', 'A');
            this.facilityExamsFilter.sex = 'A';
        }
        else if (filter == 'examPatientIdFilter') {
            this.examPatientIdFilter = '';
            document.getElementById('patientId').value = '';
            this.facilityExamsFilter.patient_id = '';
        }
        else if (filter == 'daterangeFilter') {
            this.isActiveDateSort = false;
            this.daterangeFilter = '';
            this.updatePickerPlaceholderContent('', this.daterange);
            this.clearExamFilter(null, 'date_range');
        }
        else if (filter == 'examAccessibilityFilter') {
            this.examAccessibilityFilter = '';
            this.examAccessibilitySelectpicker.selectpicker('deselectAll');
            this.clearExamFilter(this.examAccessibilityOptions, 'accessibility');
        }
        this.filtersActiveFacilityExams = (this.daterangeDescFilter != '' || this.examTypeFilter != '' ||
            this.examAnatomyFilter != '' || this.examNameFilter != '' || this.examRisNumberFilter != '' ||
            this.examSourceFilter != '' || this.examPatientNameFilter != '' || this.examSexFilter != '' ||
            this.examPatientIdFilter != '' || this.daterangeFilter != '' || this.examAccessibilityFilter != '');
        this.filter.emit(this.facilityExamsFilter);
    };
    FiltersComponent.prototype.clearExamFilter = function (options, filterName) {
        if (filterName != 'date_range' && filterName != 'exam_date_range') {
            this.facilityExamsFilter[filterName] = [];
            options.forEach(function (option) {
                option.selected = false;
            });
        }
        else if (filterName == 'date_range') {
            this.facilityExamsFilter.birth_date.birth_date_from = '';
            this.facilityExamsFilter.birth_date.birth_date_to = '';
        }
        else if (filterName == 'exam_date_range') {
            this.facilityExamsFilter.exam_date_range.date_from = '';
            this.facilityExamsFilter.exam_date_range.date_to = '';
        }
    };
    FiltersComponent.prototype.clearFilter = function (options, filterName) {
        if (filterName != 'date_range' && filterName != 'description_date_range') {
            this.filtrDashboard[filterName] = [];
            options.forEach(function (option) {
                option.selected = false;
            });
        }
        else if (filterName == 'date_range') {
            this.filtrDashboard.date_range.date_from = '';
            this.filtrDashboard.date_range.date_to = '';
        }
        else if (filterName == 'description_date_range') {
            this.filtrDashboard.description_date_range.date_from = '';
            this.filtrDashboard.description_date_range.date_to = '';
        }
    };
    FiltersComponent.prototype.clearPatientsFilter = function (options, filterName) {
        this.patientsFilter[filterName] = [];
        options.forEach(function (option) {
            option.selected = false;
        });
    };
    FiltersComponent.prototype.setOptionsStatus = function () {
        var _this = this;
        this.statusOptions.forEach(function (o) {
            o.selected = false;
        });
        this.filtrDashboard.status.forEach(function (status) {
            _this.statusOptions.forEach(function (o) {
                if (status == o.value.id_status) {
                    o.selected = true;
                }
            });
        });
        this.statusSelectpicker.selectpicker('val', this.filtrDashboard.status);
        this.generateFilter('status', this.statusOptions);
    };
    FiltersComponent.prototype.setOptionsFacility = function () {
        var _this = this;
        this.facilityOptions.forEach(function (o) {
            o.selected = false;
        });
        this.filtrDashboard.facility.forEach(function (facility) {
            _this.facilityOptions.forEach(function (o) {
                if (facility == o.value.medical_facility.id_medical_facility) {
                    o.selected = true;
                }
            });
        });
        this.facilitySelectpicker.selectpicker('val', this.filtrDashboard.facility);
        this.generateFilter('facility', this.facilityOptions);
    };
    FiltersComponent.prototype.setOptionsModality = function () {
        var _this = this;
        this.modalityOptions.forEach(function (o) {
            o.selected = false;
        });
        this.filtrDashboard.modality.forEach(function (modality) {
            _this.modalityOptions.forEach(function (o) {
                if (modality == o.value.id_group) {
                    o.selected = true;
                }
            });
        });
        this.modalitySelectpicker.selectpicker('val', this.filtrDashboard.modality);
        this.generateFilter('modality', this.modalityOptions);
    };
    FiltersComponent.prototype.setOptionsAnatomy = function () {
        var _this = this;
        this.anatomyOptions.forEach(function (o) {
            o.selected = false;
        });
        this.filtrDashboard.anatomy.forEach(function (anatomy) {
            _this.anatomyOptions.forEach(function (o) {
                if (anatomy == o.value.id_part) {
                    o.selected = true;
                }
            });
        });
        this.anatomySelectpicker.selectpicker('val', this.filtrDashboard.anatomy);
        this.generateFilter('anatomy', this.anatomyOptions);
    };
    FiltersComponent.prototype.setOptionsPriority = function () {
        var _this = this;
        this.priorityOptions.forEach(function (o) {
            o.selected = false;
        });
        this.filtrDashboard.priority.forEach(function (priority) {
            _this.priorityOptions.forEach(function (o) {
                if (priority == o.value.id_priority) {
                    o.selected = true;
                }
            });
        });
        this.prioritySelectpicker.selectpicker('val', this.filtrDashboard.priority);
        this.generateFilter('priority', this.priorityOptions);
    };
    FiltersComponent.prototype.setOptionsAssigned = function () {
        var _this = this;
        this.assignedOptions.forEach(function (o) {
            o.selected = false;
        });
        this.filtrDashboard.assign_to.forEach(function (assigned) {
            _this.assignedOptions.forEach(function (o) {
                if (assigned == o.value.id_physician) {
                    o.selected = true;
                }
            });
        });
        setTimeout(function () {
            if (_this.assignedSelectpicker)
                _this.assignedSelectpicker.selectpicker('val', _this.filtrDashboard.assign_to);
        }, 5); // TODO naprawić to tymczasowe rozwiązanie
        this.generateFilter('assigned', this.assignedOptions);
    };
    FiltersComponent.prototype.setDaterangeFilter = function () {
        if (this.type == 'FACILITY_EXAMS') {
            this.daterangeFilter = this.facilityExamsFilter.birth_date.birth_date_from + ' - ' + this.facilityExamsFilter.birth_date.birth_date_to;
            this.daterange.start = this.facilityExamsFilter.birth_date.birth_date_from;
            this.daterange.end = this.facilityExamsFilter.birth_date.birth_date_to;
        }
        else {
            this.daterangeFilter = this.filtrDashboard.date_range.date_from + ' - ' + this.filtrDashboard.date_range.date_to;
            this.daterange.start = this.filtrDashboard.date_range.date_from;
            this.daterange.end = this.filtrDashboard.date_range.date_to;
        }
        var start_date_str = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_from : this.filtrDashboard.date_range.date_from;
        var end_date_str = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.birth_date.birth_date_to : this.filtrDashboard.date_range.date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterange);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterange);
        }
    };
    FiltersComponent.prototype.setDescDaterangeFilter = function () {
        var date = (this.type == 'FACILITY_EXAMS') ? this.facilityExamsFilter.exam_date_range : this.filtrDashboard.description_date_range;
        this.daterangeDescFilter = date.date_from + ' - ' + date.date_to;
        this.daterangeDesc.start = date.date_from;
        this.daterangeDesc.end = date.date_to;
        var start_date_str = date.date_from;
        var end_date_str = date.date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterangeDesc);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterangeDesc);
        }
    };
    FiltersComponent.prototype.setSort = function () {
        this.sort = this.filtrDashboard.sort.sort_column + '_' + this.filtrDashboard.sort.sort_type;
    };
    FiltersComponent.prototype.setFilterDashboard = function () {
        if (this.type == 'ARCHIVE') {
            this.setOptionsFacility();
            if (this.filtrDashboard.description_date_range.date_from != '' && this.filtrDashboard.description_date_range.date_to != '') {
                this.setDescDaterangeFilter();
            }
        }
        this.setOptionsStatus();
        this.setOptionsAnatomy();
        this.setOptionsAssigned();
        this.setOptionsPriority();
        this.setOptionsModality();
        this.setSort();
        if (this.filtrDashboard.date_range.date_from != '' && this.filtrDashboard.date_range.date_to != '') {
            this.setDaterangeFilter();
        }
        this.setActiveFiltersDashboard(false, false);
    };
    FiltersComponent.prototype.deleteFilterPatients = function (filter) {
        if (filter == 'nameFilter') {
            this.nameFilter = '';
            document.getElementById('name').value = '';
            this.patientsFilter.name = '';
        }
        else if (filter == 'sexFilter') {
            this.sexFilter = '';
            this.sexSelectpicker.selectpicker('val', 'A');
            this.patientsFilter.sex = 'A';
        }
        else if (filter == 'peselFilter') {
            this.peselFilter = '';
            document.getElementById('pesel').value = '';
            this.patientsFilter.pesel = '';
        }
        else if (filter == 'daterangeFilter') {
            this.daterangeFilter = '';
            this.updatePickerPlaceholderContent('', this.daterange);
            this.patientsFilter.birth_date.birth_date_from = '';
            this.patientsFilter.birth_date.birth_date_to = '';
        }
        else if (filter == 'statusFilter') {
            this.statusFilter = '';
            this.statusForPatientSelectpicker.selectpicker('deselectAll');
            this.clearPatientsFilter(this.statusOptionsForPatient, 'status');
        }
        else if (filter == 'physicianFilter') {
            this.physicianFilter = '';
            this.physiciansListForPatientSelectpicker.selectpicker('deselectAll');
            this.clearPatientsFilter(this.physiciansListForPatient, 'physician');
        }
        else if (filter == 'facilityFilter') {
            this.facilityFilter = '';
            this.facilitiesListForPatientSelectpicker.selectpicker('deselectAll');
            this.clearPatientsFilter(this.facilitiesListForPatient, 'facility');
        }
        else if (filter == 'examIdFilter') {
            this.examIdFilter = '';
            document.getElementById('examId').value = '';
            this.patientsFilter.exam_number = '';
        }
        else if (filter == 'searchFilter') {
            this.filterService.clearSearch(true, this.type);
        }
        this.filtersActivePatients = (this.statusFilter != '' || this.daterangeFilter != '' ||
            this.sexFilter != '' || this.physicianFilter != '' || this.nameFilter != '' ||
            this.facilityFilter != '' || this.peselFilter != '' || this.examIdFilter != '');
        this.filter.emit(this.patientsFilter);
    };
    FiltersComponent.prototype.deleteAllFiltersPatients = function (emit) {
        this.filtersActivePatients = false;
        this.patientsFilter = FiltersComponent.createInitialFiltrPatients();
        this.nameFilter = '';
        document.getElementById('name').value = '';
        this.sexFilter = '';
        this.sexSelectpicker.selectpicker('val', 'A');
        this.peselFilter = '';
        document.getElementById('pesel').value = '';
        this.daterangeFilter = '';
        this.updatePickerPlaceholderContent('', this.daterange);
        this.statusFilter = '';
        this.statusForPatientSelectpicker.selectpicker('deselectAll');
        this.clearPatientsFilter(this.statusOptionsForPatient, 'status');
        this.physicianFilter = '';
        this.physiciansListForPatientSelectpicker.selectpicker('deselectAll');
        this.clearPatientsFilter(this.physiciansListForPatient, 'physician');
        this.facilityFilter = '';
        this.facilitiesListForPatientSelectpicker.selectpicker('deselectAll');
        this.clearPatientsFilter(this.facilitiesListForPatient, 'facility');
        this.examIdFilter = '';
        document.getElementById('examId').value = '';
        if (emit) {
            this.filterService.clearSearch(false, this.type);
            this.filter.emit(this.patientsFilter);
        }
    };
    FiltersComponent.prototype.setExamSexFilter = function () {
        var _this = this;
        this.examSexSelectpicker.selectpicker('val', this.facilityExamsFilter.sex);
        if (this.facilityExamsFilter.sex != 'A') {
            this.translate.get('DashboardScreen.Filters.' + this.facilityExamsFilter.sex)
                .subscribe(function (res) {
                _this.examSexFilter = res;
            });
        }
        else {
            this.examSexFilter = '';
        }
    };
    FiltersComponent.prototype.setExamModalities = function () {
        var _this = this;
        var result = '';
        var length = this.facilityExamsFilter.modality.length;
        this.facilityExamsFilter.modality.forEach(function (el) {
            length--;
            var tmp = _this.modalityOptions.find(function (x) { return x.value.id_group == el; });
            if (length > 0) {
                result += tmp.value.value + ', ';
            }
            else {
                result += tmp.value.value;
            }
        });
        this.examTypeFilter = result;
    };
    FiltersComponent.prototype.setExamAnatomies = function () {
        var _this = this;
        var result = '';
        var length = this.facilityExamsFilter.anatomy.length;
        this.facilityExamsFilter.anatomy.forEach(function (el) {
            length--;
            var tmp = _this.anatomyOptions.find(function (x) { return x.value.id_part == el; });
            if (length > 0) {
                result += tmp.value.value + ', ';
            }
            else {
                result += tmp.value.value;
            }
        });
        this.examAnatomyFilter = result;
    };
    FiltersComponent.prototype.setExamSource = function () {
        this.examSourceSelectpicker.selectpicker('val', this.facilityExamsFilter.source);
        if (this.facilityExamsFilter.source != '') {
            this.examSourceFilter = this.facilityExamsFilter.source;
        }
        else {
            this.examSourceFilter = '';
        }
    };
    FiltersComponent.prototype.setExamAccessibilities = function () {
        var _this = this;
        var result = '';
        var length = this.facilityExamsFilter.accessibility.length;
        this.facilityExamsFilter.accessibility.forEach(function (el) {
            length--;
            var tmp = _this.examAccessibilityOptions.find(function (x) { return x.value.id_status == el; });
            if (length > 0) {
                result += tmp.value.status_text + ', ';
            }
            else {
                result += tmp.value.status_text;
            }
        });
        this.examAccessibilityFilter = result;
    };
    FiltersComponent.prototype.setSexFilter = function () {
        var _this = this;
        this.sexSelectpicker.selectpicker('val', this.patientsFilter.sex);
        if (this.patientsFilter.sex != 'A') {
            this.translate.get('DashboardScreen.Filters.' + this.patientsFilter.sex)
                .subscribe(function (res) {
                _this.sexFilter = res;
            });
        }
        else {
            this.sexFilter = '';
        }
    };
    FiltersComponent.prototype.setDaterangeFilterPatients = function () {
        if (this.patientsFilter.birth_date.birth_date_from != '' && this.patientsFilter.birth_date.birth_date_to != '') {
            this.daterangeFilter = this.patientsFilter.birth_date.birth_date_from + ' - ' + this.patientsFilter.birth_date.birth_date_to;
        }
        this.daterange.start = this.patientsFilter.birth_date.birth_date_from;
        this.daterange.end = this.patientsFilter.birth_date.birth_date_to;
        var start_date_str = this.patientsFilter.birth_date.birth_date_from;
        var end_date_str = this.patientsFilter.birth_date.birth_date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterange);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterange);
        }
    };
    FiltersComponent.prototype.setBirthdateFilterFacilityExams = function () {
        if (this.facilityExamsFilter.birth_date.birth_date_from != '' && this.facilityExamsFilter.birth_date.birth_date_to != '') {
            this.daterangeFilter = this.facilityExamsFilter.birth_date.birth_date_from + ' - ' + this.facilityExamsFilter.birth_date.birth_date_to;
        }
        this.daterange.start = this.facilityExamsFilter.birth_date.birth_date_from;
        this.daterange.end = this.facilityExamsFilter.birth_date.birth_date_to;
        var start_date_str = this.facilityExamsFilter.birth_date.birth_date_from;
        var end_date_str = this.facilityExamsFilter.birth_date.birth_date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterange);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterange);
        }
    };
    FiltersComponent.prototype.setExamDaterangeFilter = function () {
        this.daterangeDescFilter = this.facilityExamsFilter.exam_date_range.date_from + ' - ' + this.facilityExamsFilter.exam_date_range.date_to;
        this.daterangeDesc.start = this.facilityExamsFilter.exam_date_range.date_from;
        this.daterangeDesc.end = this.facilityExamsFilter.exam_date_range.date_to;
        var start_date_str = this.facilityExamsFilter.exam_date_range.date_from;
        var end_date_str = this.facilityExamsFilter.exam_date_range.date_to;
        if (start_date_str == end_date_str) {
            this.updatePickerPlaceholderContent(start_date_str, this.daterangeDesc);
        }
        else {
            var new_content = start_date_str + ' - ' + end_date_str;
            this.updatePickerPlaceholderContent(new_content, this.daterangeDesc);
        }
    };
    FiltersComponent.prototype.setStatusPatients = function () {
        var _this = this;
        var result = '';
        var length = this.patientsFilter.status.length;
        this.patientsFilter.status.forEach(function (el) {
            length--;
            var tmp = _this.statusOptionsForPatient.find(function (x) { return x.value.id_status == el; });
            if (length > 0) {
                result += tmp.value.status + ', ';
            }
            else {
                result += tmp.value.status;
            }
        });
        this.statusFilter = result;
    };
    FiltersComponent.prototype.setPhysiciansPatients = function () {
        var _this = this;
        var result = '';
        var length = this.patientsFilter.physician.length;
        this.patientsFilter.physician.forEach(function (el) {
            length--;
            var tmp = _this.physiciansListForPatient.find(function (x) { return x.value.id_physician == el; });
            if (length > 0) {
                result += tmp.value.physician_name + ', ';
            }
            else {
                result += tmp.value.physician_name;
            }
        });
        this.physicianFilter = result;
    };
    FiltersComponent.prototype.setFacilitiesPatients = function () {
        var _this = this;
        var result = '';
        var length = this.patientsFilter.facility.length;
        this.patientsFilter.facility.forEach(function (el) {
            length--;
            var tmp = _this.facilitiesListForPatient.find(function (x) { return x.value.id_medical_facility == el; });
            if (length > 0) {
                result += tmp.value.medical_facility_name + ', ';
            }
            else {
                result += tmp.value.medical_facility_name;
            }
        });
        this.facilityFilter = result;
    };
    FiltersComponent.prototype.setFilterPatients = function () {
        if (!this.patientsFilter)
            this.patientsFilter = FiltersComponent.createInitialFiltrPatients();
        this.nameFilter = this.patientsFilter.name;
        document.getElementById('name').value = this.patientsFilter.name;
        this.setSexFilter();
        this.peselFilter = this.patientsFilter.pesel;
        document.getElementById('pesel').value = this.patientsFilter.pesel;
        this.setDaterangeFilterPatients();
        this.statusForPatientSelectpicker.selectpicker('val', this.patientsFilter.status);
        this.setStatusPatients();
        this.physiciansListForPatientSelectpicker.selectpicker('val', this.patientsFilter.physician);
        this.setPhysiciansPatients();
        this.facilitiesListForPatientSelectpicker.selectpicker('val', this.patientsFilter.facility);
        this.setFacilitiesPatients();
        this.examIdFilter = this.patientsFilter.exam_number;
        document.getElementById('examId').value = this.patientsFilter.exam_number;
        this.filtersActivePatients = (this.statusFilter != '' || this.daterangeFilter != '' ||
            this.sexFilter != '' || this.physicianFilter != '' || this.nameFilter != '' ||
            this.facilityFilter != '' || this.peselFilter != '' || this.examIdFilter != '');
    };
    FiltersComponent.prototype.setFilterFacilityExams = function () {
        this.examNameFilter = this.facilityExamsFilter.exam_name;
        document.getElementById('examName').value = this.facilityExamsFilter.exam_name;
        this.examRisNumberFilter = this.facilityExamsFilter.ris_number;
        document.getElementById('risNumber').value = this.facilityExamsFilter.ris_number;
        this.examPatientNameFilter = this.facilityExamsFilter.patient_name;
        document.getElementById('patientName').value = this.facilityExamsFilter.patient_name;
        this.examPatientIdFilter = this.facilityExamsFilter.patient_id;
        document.getElementById('patientId').value = this.facilityExamsFilter.patient_id;
        this.modalitySelectpicker.selectpicker('val', this.facilityExamsFilter.modality);
        this.setExamModalities();
        this.anatomySelectpicker.selectpicker('val', this.facilityExamsFilter.anatomy);
        this.setExamAnatomies();
        this.setExamSource();
        this.setExamSexFilter();
        this.examAccessibilitySelectpicker.selectpicker('val', this.facilityExamsFilter.accessibility);
        this.setExamAccessibilities();
        if (this.facilityExamsFilter.birth_date.birth_date_from != '' && this.facilityExamsFilter.birth_date.birth_date_to != '') {
            this.setDaterangeFilter();
        }
        if (this.facilityExamsFilter.exam_date_range.date_from != '' && this.facilityExamsFilter.exam_date_range.date_to != '') {
            this.setDescDaterangeFilter();
        }
        this.setActiveFiltersFacilityExams(false, false);
    };
    return FiltersComponent;
}());
export { FiltersComponent };
