import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnChanges, OnDestroy {

    @Input() title: string;

    titleTranslated: string = '';
    message: string = '';
    active: boolean = false;
    alertRole: string = 'danger';
    messageDest: string = '';
    translateDest: string = '';
    subscription: Subscription;

    constructor(private translate: TranslateService, private sessionService: SessionService) {
    }

    ngOnChanges() {
        if (this.title) {
            this.translate.get('AlertTitle.' + this.title)
                .subscribe((res: string) => {
                    this.titleTranslated = res;
                });
        }
    }

    displayError(errorMessage: string): void {
        this.alertRole = 'danger';
        this.displayMessage(errorMessage, 'Errors', true);
    }

    displaySuccess(successMessage: string): void {
        this.alertRole = 'success';
        this.displayMessage(successMessage, 'Successes', true);
    }

    displayInfo(infoMessage: string): void {
        this.alertRole = 'info';
        this.displayMessage(infoMessage, 'Info', true);
    }

    displayMessage(message: string, translateDest: string, setActive: boolean): void {
        if (message) {
            this.messageDest = message;
            this.translateDest = translateDest;
            this.translate.get(translateDest + '.' + message)
                .subscribe((res: string) => {
                    this.message = res;
                    if (setActive) this.active = true;
                });
        } else {
            this.message = '';
            if (setActive) this.active = true;
        }
    }

    clear(): void {
        this.active = false;
    }

    ngOnInit() {
        this.subscription = this.sessionService.emitter.subscribe((event) => {
            if (this.title) {
                this.translate.get('AlertTitle.' + this.title)
                    .subscribe((res: string) => {
                        this.titleTranslated = res;
                    });
            }
            this.displayMessage(this.messageDest, this.translateDest, false);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
