<!-- start: autoryzujZlecenieModal -->
<div aria-hidden="true"class="modal fade"
     role="dialog"
     tabindex="-1" id="authorizeDescriptionModal"
     aria-labelledby="authorizeDescriptionModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-4" id="authorizeDescriptionModalLabel">Czy na pewno chcesz autoryzować?</h2>
        <button type="button" (click)="hide()" class="btn btn-light close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <button type="button" class="btn btn-light btn-lg" (click)='hide()'>Anuluj</button>
        <button type="button" (click)="authorize()" class="btn btn-light btn-lg">Autoryzuj</button>

      </div>
    </div>
  </div>
</div>
<!-- end: autoryzujZlecenieModal -->