/*
 * (c) ImpiCode Sp. z o.o.
 * Stamp: PLE 2018-10-24
*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './app.component';
import { ForgotPassFirstComponent } from './user-registration/forgot-pass-first/forgot-pass-first.component';
import { LoginFirstComponent } from './user-registration/login-first/login-first.component';
import { LoginSecondComponent } from './user-registration/login-second/login-second.component';
import { RegistrationFirstComponent } from './user-registration/registration-first/registration-first.component';
import { RegistrationSecondComponent } from './user-registration/registration-second/registration-second.component';
import { RegistrationThirdComponent } from './user-registration/registration-third/registration-third.component';
import { RegistrationFourthComponent } from './user-registration/registration-fourth/registration-fourth.component';
import { UsersService } from '@/services';
import { ODataConfiguration, ODataServiceFactory } from 'angular-odata-es5';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ArchiveComponent } from './dm/describing-center/archive/archive.component';
import { ForgotPassSecondComponent } from './user-registration/forgot-pass-second/forgot-pass-second.component';
import { ConfirmMailComponent } from './user-registration/confirm-mail/confirm-mail.component';
import { DashboardComponent } from './dm/describing-center/dashboard/dashboard.component';
import { UnlockScreenComponent } from './utils/unlock-screen/unlock-screen.component';
import { AccountPropertiesComponent } from './dm/account-properties/account-properties.component';
import { DoctorsProfileComponent } from './dm/doctors-profile/doctors-profile.component';
import { FooterComponent } from './utils/footer/footer.component';
import { PpFooterComponent } from './utils/footer/pp-footer.component';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { ValidationService } from './services/validation.service';
import { RegistrationService } from '@/services/registration.service';
import { DMComponent } from './dm/dm.component';
import { PeselInputComponent } from './utils/pesel-input/pesel-input.component';
import { PatientsPortalComponent } from './dm/patients-portal/archive-components/patients-portal/patients-portal.component';
import { CoStep1Component } from './dm/registration-co/co-step1/co-step1.component';
import { StepperComponent } from './utils/stepper/stepper.component';
import { MessagesComponent } from './dm/messages/main-page/messages.component';
import { NotificationsComponent } from './dm/notifications/main-page/notifications.component';
import { RoleSettingsComponent } from './dm/describing-center/roles/role-settings/role-settings.component';
import { BeforeStartingWorkComponent } from './dm/before-starting-work/before-starting-work.component';
import { LanguageButtonComponent } from './utils/language-button/language-button.component';
import { NewsComponent } from './utils/news/news.component';
import { TestScreensComponent } from './dm/test-screens/test-screens.component';
import { UnauthGuardService } from './core/auth/unauth-guard.service';
import { SmsModalComponent } from './utils/sms-modal/sms-modal.component';
import { CoStep2Component } from './dm/registration-co/co-step2/co-step2.component';
import { AlertComponent } from './utils/alert/alert.component';
import { HelpComponent } from './help/help.component';
import { DdMessageButtonComponent } from './dm/messages/info-bar/dd-message-button/dd-message-button.component';
import { ShortMessageComponent } from './dm/messages/info-bar/short-message/short-message.component';
import { MessageInsideClickDirective } from './dm/messages/info-bar/message-inside-click.directive';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SelectRecipientAsyncComponent } from './dm/messages/select-recipient-async/select-recipient-async.component';
import { NgHighlightModule } from 'ngx-text-highlight';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PermissionSettingsComponent } from './dm/describing-center/roles/permission-settings/permission-settings.component';
import { UsersSettingsComponent } from './dm/describing-center/roles/users-settings/users-settings.component';
import { TeamSettingsComponent } from './dm/describing-center/roles/team-settings/team-settings.component';
import { NameAndDescriptionModalComponent } from './utils/name-and-description-modal/name-and-description-modal.component';
import { DoubleSelectModalComponent } from './utils/double-select-modal/double-select-modal.component';
import { ForgotLoginFirstComponent } from './user-registration/forgot-login-first/forgot-login-first.component';
import { OrderDescriptionComponent } from './dm/describing-center/order-description/order-description.component';
import { CoProfileComponent } from './dm/co-profile/co-profile.component';
import { MessagesService } from './services/messages.service';
import { OrderDescriptionComparisonComponent } from './dm/describing-center/order-description/order-description-comparison/order-description-comparison.component';
import { ConsultationComponent } from './dm/describing-center/order-description/consultation/consultation.component';
import { CurrentDescriptionComponent } from './dm/describing-center/order-description/current-description/current-description.component';
import { ServiceCatalogComponent } from './dm/patients-portal/archive-components/service-catalog/service-catalog.component';
import { OrderHistoryComponent } from './dm/patients-portal/archive-components/order-history/order-history.component';
import { PatientsComponent } from './dm/describing-center/patients/patients.component';
import { ConsultationsComponent } from './dm/describing-center/consultations/consultations.component';
import { TextEditorComponent } from './utils/text-editor/text-editor.component';
import { InvoicesComponent } from './dm/describing-center/invoices/invoices.component';
import { ExaminationDescriptionComponent } from './dm/patients-portal/archive-components/examination-description/examination-description.component';
import { AlreadyRegisteredScreenComponent } from './utils/already-registered-screen/already-registered-screen.component';
import { ContextGuardService } from './core/auth/context-guard.service';
import { ProfilesComponent } from './dm/describing-center/profiles/profiles.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AddListModalComponent } from './dm/co-profile/add-list-modal/add-list-modal.component';
import { NotificationsService } from './services/notifications.service';
import { DdNotificationsButtonComponent } from './dm/notifications/info-bar/dd-notifications-button/dd-notifications-button.component';
import { ShortNotificationComponent } from './dm/notifications/info-bar/short-notification/short-notification.component';
import { NotificationInsideClickDirective } from './dm/notifications/info-bar/notification-inside-click.directive';
import { SafeHtmlPipe } from './dm/notifications/pipe/safe-html.pipe';
import { AssignPhysicianModalComponent } from './utils/assign-physician-modal/assign-physician-modal.component';
import { RejectModalComponent } from './utils/reject-modal/reject-modal.component';
import { DataTablesModule } from 'angular-datatables';
import { DatatableComponent } from './utils/datatable/datatable.component';
import { StatisticsComponent } from './dm/statistics/statistics.component';
import { OrderHistoryForCenterComponent } from './dm/describing-center/order-history-for-center/order-history-for-center.component';
import { BalanceComponent } from './dm/describing-center/balance/balance.component';
import { FiltersComponent } from './utils/filters/filters.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { ChartsModule } from 'ng2-charts';
import { TakingDescriptionComponent } from './utils/taking-description/taking-description.component';
import { InsufficientRightsModalComponent } from './utils/insufficient-rights-modal/insufficient-rights-modal.component';
import { EnvServiceProvider } from './services/env.service.provider';
import { SaveExamModalComponent } from './utils/save-exam-modal/save-exam-modal.component';
import { AuthorizeDescriptionModuleComponent } from './utils/authorize-description-module/authorize-description-module.component';
import { LoaderComponent } from './utils/loader/loader.component';
import { FindCOComponent } from './dm/find-co/find-co.component';
import { ListOfMedicalFacilitiesComponent } from './dm/list-of-medical-facilities/list-of-medical-facilities.component';
import { SiteUnderConstructionComponent } from './utils/site-under-construction/site-under-construction.component';
import { DeactivateGuardService } from '@/core/auth/deactivate-guard.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimePickerComponent } from './utils/time-picker/time-picker.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DatePipe } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { plLocale } from 'ngx-bootstrap/locale';
import { ReopenExamToastComponent } from './utils/reopen-exam-toast/reopen-exam-toast.component';
import { LockGuardService } from '@/core/auth/lock-guard.service';
import { AddFilterModalComponent } from './utils/add-filter-modal/add-filter-modal.component';
import { ManageMyFiltersComponent } from './utils/manage-my-filters/manage-my-filters.component';
import { MedicalFacilityPatientsComponent } from './dm/medical-facility/medical-facility-patients/medical-facility-patients.component';
import { MedicalFacilityDashboardComponent } from './dm/medical-facility/medical-facility-dashboard/medical-facility-dashboard.component';
import { MedicalFacilityArchiveComponent } from './dm/medical-facility/medical-facility-archive/medical-facility-archive.component';
import { MedicalFacilityProfilesComponent } from './dm/medical-facility/medical-facility-profiles/medical-facility-profiles.component';
import { MedicalFacilityPermissionSettingsComponent } from './dm/medical-facility/settings/medical-facility-permission-settings/medical-facility-permission-settings.component';
import { MedicalFacilityRoleSettingsComponent } from './dm/medical-facility/settings/medical-facility-role-settings/medical-facility-role-settings.component';
import { MedicalFacilityTeamSettingsComponent } from './dm/medical-facility/settings/medical-facility-team-settings/medical-facility-team-settings.component';
import { MedicalFacilityUsersSettingsComponent } from './dm/medical-facility/settings/medical-facility-users-settings/medical-facility-users-settings.component';
import { DashboardFiltersComponent } from './utils/manage-my-filters/dashboard-filters/dashboard-filters.component';
import { PatientFiltersComponent } from './utils/manage-my-filters/patient-filters/patient-filters.component';
import { SendAttentionModalComponent } from '@/utils/send-attention-modal/send-attention-modal.component';
import { ArchiveFiltersComponent } from './utils/manage-my-filters/archive-filters/archive-filters.component';
import { HorizontalStepperComponent } from './utils/horizontal-stepper/horizontal-stepper.component';
import { PaginationComponent } from './utils/pagination/pagination.component';
import { OrdersListComponent } from './utils/orders-list/orders-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProgressBarComponent } from './utils/progress-bar/progress-bar.component';
import { SearchComponent } from './utils/search/search.component';
import { EmptyExamsComponent } from './dm/patients-portal/archive-components/empty-exams/empty-exams.component';
import { UniversalToastComponent } from './utils/universal-toast/universal-toast.component';
import { EmptyOrdersListComponent } from './utils/empty-orders-list/empty-orders-list.component';
import { InviteToCooperationModalComponent } from './utils/invite-to-cooperation-modal/invite-to-cooperation-modal.component';
import { SendMessageToUserModalComponent } from './utils/send-message-to-user-modal/send-message-to-user-modal.component';
import { MedicalFacilityExamsComponent } from './dm/medical-facility/medical-facility-exams/medical-facility-exams.component';
import { MedicalFacilityInvoicesComponent } from './dm/medical-facility/medical-facility-invoices/medical-facility-invoices.component';
import { GetAccessForDCComponent } from './dm/patients-portal/archive-components/get-access-for-dc/get-access-for-dc.component';
import { DCProfilesComponent } from './dm/patients-portal/archive-components/dcprofiles/dcprofiles.component';
import { PublicDCProfileComponent } from './dm/patients-portal/archive-components/public-dcprofile/public-dcprofile.component';
import { DoctorsAndCoProfilesComponent } from './dm/patients-portal/archive-components/doctors-and-co-profiles/doctors-and-co-profiles.component';
import { PdfNotAvailableModalComponent } from './utils/pdf-not-available-modal/pdf-not-available-modal.component';
import { InsuffCharsModalComponent } from './utils/insuff-chars-modal/insuff-chars-modal.component';
import { SendApplicationToCoModalComponent } from './utils/send-application-to-co-modal/send-application-to-co-modal.component';
import { PwzVerificationModalComponent } from './utils/pwz-verification-modal/pwz-verification-modal.component';
import { OrderToCorrectModalComponent } from '@/utils/order-to-correct-modal/order-to-correct-modal.component';
import { OrderInfoModalComponent } from '@/utils/order-info-modal/order-info-modal.component';
import { CopyClipboardModule } from './utils/copy-clipboard-module/copy-clipboard.module';
import { MatInputModule } from '@angular/material';
import { EnvService } from '@/services/env.service';
import { AttachmentViewModalComponent } from './utils/attachment-view-modal/attachment-view-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConsultationModalComponent } from './utils/consultation/consultation-modal/consultation-modal.component';
import { ConsultationViewModalComponent } from './utils/consultation/consultation-view-modal/consultation-view-modal.component';
import { CopyClipboardButtonModule } from '@/utils/copy-clipboard-button/copy-clipboard-button.module';
import { OrderDescriptionTemplatesModalComponent } from './utils/order-description-templates-modal/order-description-templates-modal.component';
import { CreateOrderModalComponent } from './utils/create-order-modal/create-order-modal.component';
import { DescOrderReturnModalComponent } from './utils/desc-order-return-modal/desc-order-return-modal.component';
import { PatientsPortalDashboardComponent } from './dm/patients-portal/patient-portal-dashboard/patients-portal-dashboard.component';
import { PatientsExamsComponent } from './dm/patients-portal/patient-exams/patients-exams.component';
import { PatientAppointmentsComponent } from './dm/patients-portal/patient-appointments/patient-appointments.component';
import { FindDoctorsComponent } from './dm/patients-portal/find-doctors/find-doctors.component';
import { AvailableServicesComponent } from './dm/patients-portal/available-services/available-services.component';
import { PatientAccountComponent } from './dm/patients-portal/patient-account/patient-account.component';
import { PatientExamViewComponent } from './dm/patients-portal/patient-exams/patient-exam-view/patient-exam-view.component';
import { PatientExamListComponent } from './dm/patients-portal/patient-exams/patient-exam-list/patient-exam-list.component';
import { PatientExamUploadModalComponent } from './utils/patient-exam-upload-modal/patient-exam-upload-modal.component';
import { PatientShareExamModalComponent } from './utils/patient-share-exam-modal/patient-share-exam-modal.component';
import { DoctorsListComponent } from './dm/patients-portal/find-doctors/doctors-list/doctors-list.component';
import { DoctorProfileComponent } from './dm/patients-portal/find-doctors/doctor-profile/doctor-profile.component';

import { UploadService } from './services/tusUpload.service';
import { MomentPipe } from './utils/pipes/momentPipe';
import { ConfirmOperationModalComponent } from './utils/confirm-operation-modal/confirm-operation-modal.component';
import { GenerateResultPairModalComponent } from './utils/generate-result-pair-modal/generate-result-pair-modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PatientAgreementCardComponent } from './utils/patient-agreement-card/patient-agreement-card.component';
import { CookieModule } from 'ngx-cookie';
import { CloudAgreementModalComponent } from './utils/cloud-agreement-modal/cloud-agreement-modal.component';
import { AlertModalComponent } from './utils/alert-modal/alert-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastModule } from '@/core/components/toast/toast.module';
import { ErrorHandlerModule } from '@/core/errors/error-handler.module';
import { ApiModule } from '@/core/api/api.module';
import { CardAsideComponent } from './dm/describing-center/order-description/components/card-aside/card-aside.component';
import { CovidCardContentComponent } from './dm/describing-center/order-description/components/card-aside/card-contents/covid-card-content/covid-card-content.component';
import { OrderInfoCardContentComponent } from './dm/describing-center/order-description/components/card-aside/card-contents/order-info-card-content/order-info-card-content.component';
import { AppStoreModule } from '@/core/store/store.module';

defineLocale('pl', plLocale);

export class MainODataConfigurationFactory {
  constructor() {
    const config = new ODataConfiguration();
    config.baseUrl = 'https://odata.emkaweb.pl:8586/evolution.svc/';
    config.customRequestOptions.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer'
    });

    return config;
  }
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ForgotPassFirstComponent,
    LoginFirstComponent,
    LoginSecondComponent,
    RegistrationFirstComponent,
    RegistrationSecondComponent,
    RegistrationThirdComponent,
    RegistrationFourthComponent,
    ArchiveComponent,
    ForgotPassSecondComponent,
    ConfirmMailComponent,
    DashboardComponent,
    UnlockScreenComponent,
    AccountPropertiesComponent,
    DoctorsProfileComponent,
    FooterComponent,
    PpFooterComponent,
    DMComponent,
    PeselInputComponent,
    PatientsPortalComponent,
    CoStep1Component,
    StepperComponent,
    MessagesComponent,
    NotificationsComponent,
    RoleSettingsComponent,
    BeforeStartingWorkComponent,
    TestScreensComponent,
    LanguageButtonComponent,
    NewsComponent,
    SmsModalComponent,
    AlertComponent,
    CoStep2Component,
    HelpComponent,
    DdMessageButtonComponent,
    ShortMessageComponent,
    MessageInsideClickDirective,
    SelectRecipientAsyncComponent,
    PermissionSettingsComponent,
    UsersSettingsComponent,
    TeamSettingsComponent,
    NameAndDescriptionModalComponent,
    DoubleSelectModalComponent,
    ForgotLoginFirstComponent,
    OrderDescriptionComponent,
    CoProfileComponent,
    OrderDescriptionComparisonComponent,
    ConsultationComponent,
    CurrentDescriptionComponent,
    ServiceCatalogComponent,
    OrderHistoryComponent,
    PatientsComponent,
    ConsultationsComponent,
    TextEditorComponent,
    InvoicesComponent,
    ExaminationDescriptionComponent,
    AlreadyRegisteredScreenComponent,
    ProfilesComponent,
    AddListModalComponent,
    DdNotificationsButtonComponent,
    ShortNotificationComponent,
    NotificationInsideClickDirective,
    SafeHtmlPipe,
    AssignPhysicianModalComponent,
    RejectModalComponent,
    DatatableComponent,
    StatisticsComponent,
    OrderHistoryForCenterComponent,
    BalanceComponent,
    FiltersComponent,
    TakingDescriptionComponent,
    InsufficientRightsModalComponent,
    SaveExamModalComponent,
    AuthorizeDescriptionModuleComponent,
    LoaderComponent,
    FindCOComponent,
    ListOfMedicalFacilitiesComponent,
    SiteUnderConstructionComponent,
    TimePickerComponent,
    ReopenExamToastComponent,
    AddFilterModalComponent,
    ManageMyFiltersComponent,
    MedicalFacilityPatientsComponent,
    MedicalFacilityDashboardComponent,
    MedicalFacilityArchiveComponent,
    MedicalFacilityProfilesComponent,
    MedicalFacilityPermissionSettingsComponent,
    MedicalFacilityRoleSettingsComponent,
    MedicalFacilityTeamSettingsComponent,
    MedicalFacilityUsersSettingsComponent,
    DashboardFiltersComponent,
    PatientFiltersComponent,
    SendAttentionModalComponent,
    ArchiveFiltersComponent,
    HorizontalStepperComponent,
    PaginationComponent,
    OrdersListComponent,
    ProgressBarComponent,
    SearchComponent,
    EmptyExamsComponent,
    UniversalToastComponent,
    EmptyOrdersListComponent,
    InviteToCooperationModalComponent,
    SendMessageToUserModalComponent,
    MedicalFacilityExamsComponent,
    MedicalFacilityInvoicesComponent,
    GetAccessForDCComponent,
    DCProfilesComponent,
    PublicDCProfileComponent,
    DoctorsAndCoProfilesComponent,
    PdfNotAvailableModalComponent,
    InsuffCharsModalComponent,
    SendApplicationToCoModalComponent,
    PwzVerificationModalComponent,
    OrderToCorrectModalComponent,
    OrderInfoModalComponent,
    AttachmentViewModalComponent,
    OrderDescriptionTemplatesModalComponent,
    AttachmentViewModalComponent,
    ConsultationModalComponent,
    ConsultationViewModalComponent,
    CreateOrderModalComponent,
    DescOrderReturnModalComponent,
    PatientsPortalDashboardComponent,
    PatientsExamsComponent,
    PatientAppointmentsComponent,
    FindDoctorsComponent,
    AvailableServicesComponent,
    PatientAccountComponent,
    PatientExamViewComponent,
    PatientExamListComponent,
    PatientExamUploadModalComponent,
    PatientShareExamModalComponent,
    DoctorsListComponent,
    DoctorProfileComponent,
    MomentPipe,
    ConfirmOperationModalComponent,
    GenerateResultPairModalComponent,
    PatientAgreementCardComponent,
    CloudAgreementModalComponent,
    AlertModalComponent,
    CardAsideComponent,
    CovidCardContentComponent,
    OrderInfoCardContentComponent
  ],
  imports: [
    PdfViewerModule,
    BrowserAnimationsModule,
    Daterangepicker,
    ChartsModule,
    CookieModule.forRoot(),
    DataTablesModule,
    EditorModule,
    BrowserModule,
    AppStoreModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    NgHighlightModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NoopAnimationsModule,
    MatTooltipModule,
    TimepickerModule.forRoot(),
    ScrollingModule,
    CopyClipboardModule,
    CopyClipboardButtonModule,
    MatInputModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    ToastModule,
    ErrorHandlerModule,
    ApiModule
  ],
  providers: [
    {
      provide: ODataConfiguration,
      useFactory: MainODataConfigurationFactory
    },
    /*{
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },*/
    ODataServiceFactory,
    UsersService,
    RegistrationService,
    AuthGuardService,
    UnauthGuardService,
    ValidationService,
    MessagesService,
    NotificationsService,
    ContextGuardService,
    EnvServiceProvider,
    DeactivateGuardService,
    LockGuardService,
    DatePipe,
    UploadService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private envService: EnvService) {
    /*if (this.envService.bugSnagEnabled === true) {
      Bugsnag.start({ apiKey: '3298a4a20b99e9a69590661a8cae2abe' });
    }*/
  }
}
