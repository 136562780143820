import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@/services';
import { CLOUD_AGREED } from '@/interfaces/PatientsPortal';

@Component({
  selector: 'app-cloud-agreement-modal',
  templateUrl: './cloud-agreement-modal.component.html',
  styleUrls: ['./cloud-agreement-modal.component.scss']
})
export class CloudAgreementModalComponent implements OnInit {
  @Output() cloudAgreementChanged: EventEmitter<number> = new EventEmitter<number>();

  listOptions = [];

  constructor(private translate: TranslateService, private userService: UsersService) { }

  ngOnInit() {
    this.translate.get('PatientsPortal.CloudAgreement.Options').subscribe(res => this.listOptions = res);
  }

  show() {
    $('#cloudAgreementModal').modal('show');
  }

  hide() {
    $('#cloudAgreementModal').modal('hide');
  }

  activateCloud() {
    this.userService.changePatientCloudAgreement(CLOUD_AGREED).then(res => this.cloudAgreementChanged.emit(CLOUD_AGREED));
    this.hide();
  }
}
