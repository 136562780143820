/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./send-attention-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./send-attention-modal.component";
var styles_SendAttentionModalComponent = [i0.styles];
var RenderType_SendAttentionModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SendAttentionModalComponent, data: {} });
export { RenderType_SendAttentionModalComponent as RenderType_SendAttentionModalComponent };
export function View_SendAttentionModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["aria-hidden", "true"], ["aria-labelledby", "saveAndSendAttentionExamModalLabel"], ["class", "modal fade"], ["id", "saveAndSendAttentionExamModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "modal-dialog modal-dialog-centered modal-md"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["class", "modal-title mb-4"], ["id", "saveAndSendAttentionExamModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "btn btn-light close"], ["data-dismiss", "modal"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(10, 0, null, null, 9, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-lg btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.isSendAndSave(true, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-lg btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-lg btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.isSendAndSave(false, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("SendExamModal.Title")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("SendExamModal.SendAlert")); _ck(_v, 12, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("SendExamModal.Cancel")); _ck(_v, 15, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("SendExamModal.SendWithoutAlert")); _ck(_v, 18, 0, currVal_3); }); }
export function View_SendAttentionModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-send-attention-modal", [], null, null, null, View_SendAttentionModalComponent_0, RenderType_SendAttentionModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.SendAttentionModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SendAttentionModalComponentNgFactory = i1.ɵccf("app-send-attention-modal", i3.SendAttentionModalComponent, View_SendAttentionModalComponent_Host_0, {}, {}, []);
export { SendAttentionModalComponentNgFactory as SendAttentionModalComponentNgFactory };
